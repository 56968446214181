import React from 'react'
import './Analytics.scss'

function Analytics() {
    return (
        <div>
            <p className='analyticsHead'>Analytics</p>
            <div style={{ justifyContent: "center" }} className='flex'>
                <p className='analyticsSubHead'>Let the numbers speak for themselves, placing trust in our capabilities</p>
            </div>
            <div style={{ justifyContent: "center" }} className='flex'>
                <div className='row analyticsContainer1'>
                    <div className='col-md-3 col-sm-12 analyticsdiv'>
                        <div style={{ marginTop: "35px" }}>
                            <p className='counts'>5000+</p>
                            <p className='countText'>Top notch Resource Pool</p>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12 analyticsdiv'>
                        <div style={{ marginTop: "35px" }}>
                            <p className='counts'>50+</p>
                            <p className='countText'>Daily Active Interviews</p>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12  analyticsdiv'>
                        <div style={{ marginTop: "35px" }}>
                            <p className='counts'>100+</p>
                            <p className='countText'>Top Rated Interviewer</p>
                        </div>
                    </div>
                    <div  className='col-md-3 col-sm-12 analyticsdiv1'>
                        <div style={{ marginTop: "35px" }}>
                            <p className='counts'>500+</p>
                            <p className='countText'>Global Clients</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Analytics