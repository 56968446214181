import { Routes, Route } from "react-router-dom";
import BreadCrumbs from "../../common/BreadCrumbs";
import PageHeader from "./InterViewCoordinatorHeader";
import PageLeftMenu from "./InterViewCoordinatorLeftMenu";
import InterviewerList from "../interviewer-list/InterviewerList";
import InterviewCandidate from "../InterviewCandidate";
import SharedInterViewCoordinateList from "./SharedInterViewCoordinateList";
import CoordinatorJobPost from "../interview-coordinator-jobpost/CoordinatorJobPost";
import CoordinatorShareProfile from "../interview-coordinator-jobpost/CoordinatorShareProfile";

function InterViewCoordinatorHome() {
  return (
    <div>
      {/* <ProgressContainer> */}

      <div className="">
        <PageHeader />
      </div>
      <div className="flex">
        <div>
          <PageLeftMenu />
        </div>
        <div className="w-full home-component-display overflow-auto">
          <BreadCrumbs />
          <Routes>
          <Route
              path="/"
              element={<InterviewerList tabletitleRow={true} />}
            />
            <Route
              path="/interviewer"
              element={<InterviewerList tabletitleRow={true} />}
            />
            <Route
              path="/candidates"
              element={<InterviewCandidate tabletitleRow={true} />}
            />
             <Route 
              path="/job-post" 
              element={<CoordinatorJobPost />} />
            <Route
              path="/job-post/share-profile"
              element={<CoordinatorShareProfile />}
            />
             <Route
              path="/interviewer/requestedprofile"
              element={<SharedInterViewCoordinateList tabletitleRow={true} />}
            />


          </Routes>
        </div>
      </div>
      {/* </ProgressContainer> */}
    </div>
  );
}

export default InterViewCoordinatorHome;
