import { useFormik } from "formik";
import React, { useState } from "react";
import Select, { components } from 'react-select';
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import cx from "classnames";
import { TextField } from "@mui/material";
import $ from "jquery";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./BdJobPostModal.scss";
import { useEffect } from "react";
import dayjs from "dayjs";
import { jwtDecode } from 'jwt-decode'
import CreatableSelect from "react-select/creatable";


import {
    editJobReq,
    getIndustriesListReq,
    getroleListReq,
    getskillsListReq,
    postJobReq,
    getTotalCitiesReq,
    getAllTitlesReq,
    getAllStreamsReq,
    getAllCompany,
    getAllClintsByCompany,
} from "../../api/api-services";

import {
    stringToDropdownValue,
    dropdownLabelFinder,
} from "../../util/common-utils";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { SelectDropdown } from "../landing-page/common/select-dropdown";
import ReactSelectDropdown from "../landing-page/common/react-select-dropdown";


const BdJobPostModal = (props) => {
    const InputOption = ({
        getStyles,
        Icon,
        isDisabled,
        isFocused,
        isSelected,
        children,
        innerProps,
        disableOther,
        ...rest
    }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);

        // styles
        let bg = "transparent";
        if (isFocused) bg = "#eee";
        if (isActive) bg = "#B2D4FF";

        const style = {
            alignItems: "center",
            backgroundColor: bg,
            color: "inherit",
            display: "flex ",
        };

        // prop assignment
        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            style,
        };
        const hasChildren = !!children;
        const isSpecialOption =
            children === "Pan India" || children === "Any Location";
        const textClass = isSpecialOption
            ? "text-black opacity-100 cursor-pointer"
            : disableOther
                ? "text-gray-600 opacity-50"
                : "text-black opacity-100 cursor-pointer";

        return (
            <components.Option
                {...rest}
                isDisabled={isDisabled}
                isFocused={isFocused}
                isSelected={isSelected}
                getStyles={getStyles}
                innerProps={props}
            >
                <div>
                    <span>
                        {" "}
                        <input
                            type="checkbox"
                            className="mx-2 cursor-pointer"
                            checked={isSelected}
                        />
                    </span>
                    <span className={textClass}>{children}</span>
                </div>
            </components.Option>
        );
    };


    const navigate = useNavigate();
    const { state } = useLocation()
    const inputBorder = "1px solid #ccc";
    const inputBorderErr = "1px solid #ea0322";
    const [roleData, setRoleData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [clintNames, setClintNames] = useState([]);
    const [clientId, setClientId] = useState("")
    const [clientObj, setClientObj] = useState([]);
    // const [selectedOption, setSelectedRoleData] = useState([]);
    const [selectedRoleData, setSelectedRoleData] = useState();
    const [skillsData, setSkillsData] = useState([]);
    // const [isSpecialOptionSelected, setisSpecialOptionSelected]= useState('')
    const [selectedSkillsData, setSelectedSkillsData] = useState([]);
    const [locationData, setLocationsData] = useState([]);
    const [selectedLocationData, setSelectedLocationData] = useState([]);
    const [selectedGenderData, setSelectedGenderData] = useState([]);
    const [dateValue, setDateValue] = useState("");
    const [courseError, setCourseError] = useState(null);
    const [specializationError, setSpecializationError] = useState(null);
    const [validationiOnCourse, setValidationOnCourse] = useState(true)
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [inputValues, setInputValues] = useState('');
    const [disableOther, setDisableOthers] = useState('');
    const [locationRequired, setLocationRequired] = useState(false)
    const [locationErrer, setLocationErrer] = useState(null)
    const today = dayjs();
    let role = JSON.parse(localStorage.getItem("across-geo-user")).role;
    let user = JSON.parse(localStorage.getItem("across-geo-user"));
    let token = JSON.parse(localStorage.getItem("across-geo-user")).access_token;
    const mailId = jwtDecode(token).email;
    let initialvalidation = {
        name: yup
            .string()
            .trim()
            .min(3, "Minimum 3 characters")
            .max(150, "Maximum 150 characters")
            .matches(/.*[a-zA-Z].*/, "Job Headline should be alphabetic")
            .required("Job Headline is required"),
        roles: yup.string().required("Role is required"),
        skills: yup.array().min(1, "Skills is required"),
        employmentType: yup.string().required("Employment Type is required"),
        workMode: yup.string().required("Work Mode is required"),
        compName: yup.string().required("Company Name is required"),
        spocName: yup.string().required("Spoc Name is required"),
        expMin: yup
            .number("only numbers are allowed")
            .required("Work Experience is required")
            .typeError("Work Experience should be numerical & no space allowed")
            .min(0, "Work Experience cannot be negative")
            .max(99, "Invalid Work Experience")
            .transform((value, originalValue) =>
                /\s/.test(originalValue) ? NaN : value
            ),

        expMax: yup
            .number("only numbers are allowed")
            .typeError("Work Experience should be numerical & no space allowed")
            .transform((value, originalValue) =>
                /\s/.test(originalValue) ? NaN : value
            )
            .required("Work Experience is required")
            .min(0, "Work Experience cannot be negative")
            .max(99, "Invalid Work Experience")
            .test(
                "max-geater-than-min",
                "Max should be greater than min experience",
                function (value) {
                    const maxExp = parseFloat(this.resolve(yup.ref("expMin"), this));
                    const minExp = parseFloat(value);
                    return isNaN(maxExp) || isNaN(minExp) || minExp > maxExp;
                }
            )
            .test(
                "min-less-than-max",
                "Min should be less than min experience",
                function (value) {
                    const maxExp = parseFloat(this.resolve(yup.ref("expMin"), this));
                    const minExp = parseFloat(value);
                    return isNaN(maxExp) || isNaN(minExp) || maxExp < minExp;
                }
            ),
        slryMin: yup
            .number("Enter proper value")
            .typeError("Salary range should be numerical & no space allowed")
            .min(0, "Salary range cannot be negative")
            .required("Salary range is required")
            .transform((value, originalValue) =>
                /\s/.test(originalValue) ? NaN : value
            )
            .typeError("Salary range should be numerical & no space allowed"),
        slryMax: yup
            .number("Enter proper value")
            .typeError("Salary range should be numerical")
            .min(0, "Salary range cannot be negative")
            .typeError("Salary range should be numerical & no space allowed")
            .transform((value, originalValue) =>
                /\s/.test(originalValue) ? NaN : value
            )
            .required("Salary range is required")
            .test(
                "max-geater-than-min",
                "Max should be greater than min salary",
                function (value) {
                    const maxSalary = parseFloat(this.resolve(yup.ref("slryMin"), this));
                    const minSalary = parseFloat(value);
                    return isNaN(maxSalary) || isNaN(minSalary) || minSalary > maxSalary;
                }
            )
            .test(
                "min-less-than-max",
                "Min should be less than min salary",
                function (value) {
                    const maxSalary = parseFloat(this.resolve(yup.ref("slryMin"), this));
                    const minSalary = parseFloat(value);
                    return isNaN(maxSalary) || isNaN(minSalary) || maxSalary < minSalary;
                }
            ),
        location: yup.array().min(1, "Location is required"),
        // gender: yup.array().optional(),
        industry: yup.string().required("Industry is required"),
        functionalArea: yup.string().required("Functional Area is required"),
        vacancyCount: yup
            .string()
            .required("Vacancy count is required")
            .matches(/^[0-9]*$/, "Vacancy count should be numeric")
            .test("vacancy-count", "Invalid vacancy count", function (value) {
                return isNaN(parseInt(value)) || parseInt(value) > 0;
            }),
        percentage: yup.number()
            .typeError('Please enter a valid number')
            .min(0, 'Percentage must be between 0 and 100')
            .max(100, 'Percentage must be between 0 and 100')
            .test({
                name: 'is-decimal',
                message: 'Only two decimal places are allowed',
                test: (value) => value === undefined || /^\d+(\.\d{1,2})?$/.test(value),
            }),
        course: validationiOnCourse ? yup.array().min(1, "course is required") : yup.array().optional(),
        qualification: yup.string().required("Highest Qualification is required"),
        specialization: validationiOnCourse ? yup.array().min(1, "Specialization is required") : yup.array().optional(),
        date: yup.string().required("Date is required"),
        description: yup
            .string()
            .trim("The contact name cannot include leading and trailing spaces")
            .required("Job description is required"),
    };
    const [dropDownData, setDropDownData] = useState({
        status: [{ label: "CANCEL", value: "CANCEL" }],
        employmentType: [
            { label: "Full Time", value: "FULL_TIME" },
            { label: "Internship", value: "INTERNSHIP" },
            { label: "PartTime", value: "PART_TIME" },
            { label: "Contract Type", value: "CONTRACT_TYPE" },
        ],
        workMode: [
            { label: "In-Office", value: "INOFFICE" },
            { label: "Hybrid", value: "HYBRID" },
            { label: "Remote", value: "REMOTE" },
        ],

        industry: [],
        functionalArea: [
            {
                label: "IT Software - Client/Server Programming",
                value: "IT Software - Client/Server Programming",
            },
            {
                label: "IT Software - eCommerce, Internet Technologies",
                value: "IT Software - eCommerce, Internet Technologies",
            },
            { label: "IT Software - Mainframe", value: "IT Software - Mainframe" },
            { label: "IT Software - Middleware", value: "IT Software - Middleware" },
            { label: "IT Software - Mobile", value: "IT Software - Mobile" },
            { label: "IT Software - Other", value: "IT Software - Other" },
            {
                label: "IT Software - System Programming",
                value: "IT Software - System Programming",
            },
            {
                label: "IT Software - Telecom Software",
                value: "IT Software - Telecom Software",
            },
            {
                label: "IT Software - DBA, Datawarehousing",
                value: "IT Software - DBA, Datawarehousing",
            },
            {
                label: "IT Software - Embedded, EDA, VLSI, ASIC, Chip Design",
                value: "IT Software - Embedded, EDA, VLSI, ASIC, Chip Design",
            },
            { label: "IT Software - ERP, CRM", value: "IT Software - ERP, CRM" },
            {
                label: "IT Software - Network Administration, Security",
                value: "IT Software - Network Administration, Security",
            },
            {
                label: "IT Software - QA & Testing",
                value: "IT Software - QA & Testing",
            },
            {
                label: "IT Software - Systems, EDP, MIS",
                value: "IT Software - Systems, EDP, MIS",
            },
            {
                label: "Non IT- HR",
                value: "Non IT- HR",
              }
              ,
              {
                label: "Non IT- Marketing",
                value: "Non IT- Marketing",
              }
              ,
              {
                label: "Non IT- Sales",
                value: "Non IT- Sales",
              }
              ,
              {
                label: "Non IT- Digital Marketing",
                value: "Non IT- Digital Marketing",
              }
              ,
              {
                label: "Non IT- Finance",
                value: "Non IT- Finance",
              }
              ,
              {
                label: "Non-IT - Banking",
                value: "Non-IT - Banking",
              }
        ],
        qualification: [
            { label: "Master's Degree", value: "MASTERS_DEGREE" },
            { label: "Degree", value: "DEGREE" },
            { label: "Diploma", value: "DIPLOMA" },
            { label: "PUC", value: "PUC" },
            { label: "ITI", value: "ITI" },
            { label: "SSLC (10th)", value: "SSLC" },
        ],
        gender: [
            { label: "Male", value: "MALE" },
            { label: "Female", value: "FEMALE" },
            { label: "Others", value: "OTHERS" }
        ],
        course: [],
        specialization: [],
    });
    const [selectedDropdownData, setSelectedDropdownData] = useState({
        employmentType: [],
        workMode: [],
        compName: [],
        spocName: [],
        industry: [],
        compName: [],
        functionalArea: [],
        qualification: [],
        gender: [],
        course: [],
        location: [],
        specialization: [],
        status: [],
    });
    let initialValues = {
        name: "",
        description: "",
        roles: "",
        skills: [],
        percentage: '',
        employmentType: "",
        workMode: "",
        expMin: "",
        expMax: "",
        slryMin: "",
        gender: [],
        slryMax: "",
        location: [],
        industry: "",
        functionalArea: "",
        vacancyCount: "",
        qualification: "",
        course: [],
        specialization: [],
        date: "",
        compName: "",
        gender: [],
        spocName: ""
    };
    if (props.mode === "edit" && props.selectedJob) {
        console.log(props.selectedJob, "props.seledJob")
        initialValues = {
            name: props.selectedJob.jobHeadlines,
            description: props.selectedJob.jobDescription,
            roles: props.selectedJob.roles,
            skills: props.selectedJob.skills,
            employmentType: props.selectedJob.employmentType,
            workMode: props.selectedJob.workMode,
            expMin: props.selectedJob.workExperience.min,
            expMax: props.selectedJob.workExperience.max,
            slryMin: props.selectedJob.salaryRange.min,
            slryMax: props.selectedJob.salaryRange.max,
            location: props.selectedJob.location,
            industry: props.selectedJob.industry,
            functionalArea: props.selectedJob.functionalArea,
            vacancyCount: props.selectedJob.vacancyCount,
            compName: props.selectedJob.compName,
            gender: props.selectedJob.gender,
            spocName: props.selectedJob.clientName,
            percentage: props.selectedJob.percentage,
            qualification: props.selectedJob.highestQualification,
            course: props.selectedJob.course,
            specialization: props.selectedJob.specialization,
            gender: props.selectedJob.gender,
            date: props.selectedJob.dateForSharingProfiles,
            status:
                props.selectedJob.status === "CREATE"
                    ? "UPDATE"
                    : props.selectedJob.status,
        };
    }
    // console.log(useLocation(), "useLocation()")
    const onSubmit = async (values) => {
        console.log(values, "values,values")
        const isValidLength = /^.{1,20}$/.test(values.roles);
        if (!isValidLength) {
            console.log("getting in ")
            formikDetails.setFieldError("roles", "value must be greater than 1 and less than 20 characters")
        }
        if (props.mode === "edit") {
            console.log(values, "values from edit")
            console.log(props)
            let displayToast = true;
            let payload = {
                ...props.selectedJob,
                clientId: role === "BD" ? clientId : user.uid,
                companyName: role === "BD" ? values.compName : user.others.companyName,
                jobHeadlines: values.name,
                roles: values.roles,
                keySkills: values.skills,
                location: values.workMode === "REMOTE" && values.location.length === 0 ? ['Remote'] : values.location,
                highestQualification: values.qualification,
                dateForSharingProfiles: values.date,
                employmentType: values.employmentType,
                functionalArea: values.functionalArea,
                industry: values.industry,
                jobDescription: values.description,
                salaryRange: { min: values.slryMin, max: values.slryMax },
                workExperience: { min: values.expMin, max: values.expMax },
                course: values.course,
                percentage: values.percentage,
                gender: values.gender,
                specialization: values.specialization,
                vacancyCount: values.vacancyCount,
                workMode: values.workMode,
                jobPostId: props.selectedJob.id,
                status: values.status,
            };
            console.log(payload, values, "edit");
            const isDataChanged = !isEqual(
                omit(payload, ["jobPostId"]),
                omit(props.selectedJob, ["jobPostId"])
            );
            // if(!isDataChanged){

            // }
            console.log(payload, "payloadpayloadpayload")
            try {
                let response = await editJobReq(payload, mailId);
                if (response && response?.status === 200 && displayToast) {
                    displayToast = false;
                    props.toast.success(
                        <>
                            <div className="toast-heading">Success</div>
                            <div>Job Post edited successfully</div>
                        </>
                    );
                    props.getJobList(0);
                    if (props.from === "sharedProfile") {
                        props.setEditJobPost(false)
                    }
                    if (role === "BD") {
                        navigate("/bd/job-post");
                        props.setAddJobPost && props.setAddJobPost(false)
                        props.setEdirJobPost && props.setEdirJobPost(false)
                        props.getJobListById(0)
                    } else {
                        props.closeModal(true);
                        navigate("/bd/job-post");
                    }
                }
                if (props.from === "sharedProfile") {
                    props.setEditJobPost(false)
                }
            } catch (err) {
                console.error(err, "edit job api error");
                if (props.from === "sharedProfile") {
                    props.setEditJobPost(false)
                }
                navigate("/bd/job-post");
            }
        } else if (props.mode !== "edit") {
            console.log(values.specialization, "values.specialization")
            let displayToast = true;
            let payload = {
                clientId: role === "BD" ? clientId : user.uid,
                companyName: role === "BD" ? values.compName : user.others.companyName,
                jobHeadlines: values.name,
                roles: values.roles,
                keySkills: values.skills, //-----------------------------------
                location: values.workMode === "REMOTE" && values.location.length === 0 ? ['Remote'] : values.location,
                percentage: values.percentage,
                highestQualification: values.qualification,
                dateForSharingProfiles: values.date,
                employmentType: values.employmentType,
                functionalArea: values.functionalArea,
                industry: values.industry,
                jobDescription: values.description,
                salaryRange: { max: values.slryMax, min: values.slryMin },
                workExperience: { max: values.expMax, min: values.expMin },
                course: values.course,
                gender: values.gender,
                specialization: values.specialization,
                vacancyCount: values.vacancyCount,
                workMode: values.workMode,
                status: values.status ? values.status : "CREATE",
            };
            try {
                let response = await postJobReq(payload, mailId);
                if (response.status === 200 && displayToast) {
                    console.log(state, "state")
                    displayToast = false;

                    if (role == "BD") {
                        setTimeout(() => {
                            props.toast.success(
                                <>
                                    <div className="toast-heading">Success</div>
                                    <div>Job Posted successfully</div>
                                </>
                            );
                        }, 200);
                        if (role === "BD") {
                            navigate("/bd/job-post");
                            props.setAddJobPost(false)
                            props.getJobListById(0)
                        }
                    } else {
                        props.toast.success(
                            <>
                                <div className="toast-heading">Success</div>
                                <div>Job Posted successfully</div>
                            </>
                        );
                        props.getJobList(0);
                        if (role === "BD") {
                            navigate("/bd/job-post");
                            props.setAddJobPost && props.setAddJobPost(false)
                            props.setEdirJobPost && props.setEdirJobPost(false)
                            props.getJobListById(0)
                        } else {
                            props.closeModal(true);
                            navigate("/bd/job-post");
                        }
                        if (props.from === "sharedProfile") {
                            props.setEditJobPost(false)
                        }
                    }

                }
                console.log(payload);
            } catch (err) {
                console.error(err, "create job api error");
                navigate("/bd/job-post");
            }
        }
    };
    const validationSchema = yup.object({ ...initialvalidation });
    const formikDetails = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    const handleInputChange = (newValue) => {
        setInputValue(newValue);

    };
    const handleRoleChange = async (data, workMode, industry) => {
        console.log(data, workMode, industry, "data in handleRoleChange")
        setSelectedRoleData([...data]);
        setSelectedDropdownData((prevData) => ({
            ...prevData,
            jobTitle: data?.[0]
                ? [{ label: data[0].label, value: data[0].value }]
                : [], // Empty array if data is falsy
        }));
        if (!workMode) {
            formikDetails.setFieldValue("roles", data?.[0]?.value);
        }
        if (data?.[0]?.value && selectedOption?.value !== data?.[0]?.value) {
            try {
                let response = await getskillsListReq(
                    selectedDropdownData.industry[0]?.value
                        ? selectedDropdownData.industry[0]?.value
                        : industry,
                    data?.[0]?.value
                );
                let skillslistData = [];
                if (response.data) {
                    response.data?.response.map((data) => {
                        skillslistData.push({ label: data, value: data });
                    });
                }
                setSkillsData([...skillslistData]);
            } catch (err) {
                console.error(err, "skills list api error");
            }
            if (data?.[0].value !== selectedOption?.value && !workMode) {
                formikDetails.setFieldValue("skills", []);
                setSelectedSkillsData([]);
            }
        }
    };
    const handleMenuClose = async (industry) => {
        console.log(industry, "industry")
        const isValueNotInOptions = !roleData.some((option) => option.label === inputValue);
        const newOption = { value: inputValue, label: inputValue };
        if (isValueNotInOptions && inputValue.trim() !== '') {
            setSelectedOption(newOption);
            formikDetails.values.roles = newOption?.value
        }

        console.log(formikDetails.values, "formikDetails.valuesx")

        if (newOption?.value || selectedOption?.value) {
            console.log(newOption.value, "selectedOption")
            try {
                let response = await getskillsListReq(
                    industry,
                    selectedOption?.value
                );
                let skillslistData = [];
                if (response.data) {
                    response.data?.response.map((data) => {
                        skillslistData.push({ label: data, value: data });
                    });
                }
                setSkillsData([...skillslistData]);
            } catch (err) {
                console.error(err, "skills list api error");
            }
            if (newOption?.value) {
                formikDetails.setFieldValue("skills", []);
                setSelectedSkillsData([]);
            }
        }
    };

    const handleSkillsChange = (data) => {
        let selecData = [];
        data.map((skill) => {
            selecData.push(skill.value);
        });
        formikDetails.setFieldValue("skills", [...selecData]);
        setSelectedSkillsData([...data]);
    };

    // const handleLocationChange = (data) => {
    //     let selecData = [];
    //     data.map((loc) => {
    //         selecData.push(loc.value);
    //     });
    //     formikDetails.setFieldValue("location", [...selecData]);
    //     setSelectedLocationData([...data]);
    // };

    const handleLocationChange = (data) => {
        const allSelectedData = { ...selectedDropdownData }
        console.log(data, "data in the location fn")
        let selecData = [];
        data.map((loc) => {
            selecData.push(loc.value);
        });
        formikDetails.setFieldValue("location", [...selecData]);
        setSelectedLocationData([...data]);
        allSelectedData.location = [...selecData]
        setSelectedDropdownData({ ...allSelectedData })

    };

    const handleGenderChange = (data) => {
        console.log(data, "data in the gender fn")
        let selecData = [];
        data.map((loc) => {
            selecData.push(loc.value);
        });
        formikDetails.setFieldValue("gender", [...selecData]);
        setSelectedGenderData([...data]);
    };

    const getTitles = async (qualification) => {
        try {
            const res = await getAllTitlesReq(qualification);
            console.log("All titles Data", res);
            if (res?.data) {
                let tempTitle = res.data.response.map((val) => {
                    return stringToDropdownValue(val);
                });
                tempTitle.push({ label: "Any", value: "ANY" })
                setDropDownData({ ...dropDownData, course: [...tempTitle] });
            } else {
                setDropDownData({ ...dropDownData, course: [] });
            }
        } catch (e) {
            console.log("titles error", e);
        }
    };
    const getStreams = async (title) => {
        try {
            const res = await getAllStreamsReq(
                title?.value,
                (title?.label).toLowerCase()
            );
            console.log("All specialization Data", res);
            if (res?.data) {
                let tempStream = res.data.response.map((val) => {
                    return stringToDropdownValue(val);
                });
                let courseDropdown = { ...dropDownData }
                tempStream.push({ label: "Any", value: "ANY" })
                setDropDownData({ ...dropDownData, specialization: [...tempStream] });
                if (formikDetails.values.course[0] === "ANY") {
                    console.log(formikDetails.values.course, "formikDetails.values.course")
                    setDropDownData({ ...dropDownData, specialization: [{ label: "Any", value: "ANY" }] });
                }
            } else {
                setDropDownData({ ...dropDownData, specialization: [] });
            }
        } catch (e) {
            console.log("specialization error", e);
        }
    };
    const handleSpacilizationData = async () => {
        if (formikDetails.values.course.length > 0) {
            try {
                const res = await getAllStreamsReq(
                    formikDetails.values.qualification,
                    formikDetails.values.course
                );
                console.log("All specialization Data", res);
                if (res?.data) {
                    let tempStream = res.data.response.map((val) => {
                        return stringToDropdownValue(val);
                    });
                    tempStream.push({ value: "ANY", label: "Any" })
                    if (formikDetails.values.course[0] === "ANY") {
                        setDropDownData({ ...dropDownData, specialization: [{ value: "ANY", label: "Any" }] });
                    } else {
                        setDropDownData({ ...dropDownData, specialization: tempStream });
                    }
                } else {
                    setDropDownData({ ...dropDownData, specialization: [] });
                }
            } catch (e) {
                console.log("specialization error", e);
            }
        }
    }
    const handleDropdownChange = async (data, type, editType, formikBag) => {
        if (type === "status") {
            let allSelectedData = { ...selectedDropdownData };
            console.log(data, "data in ststus")
            allSelectedData.status = data
            formikDetails.values.status = data[0].value;
            setSelectedDropdownData({ ...allSelectedData })
        }

        if (type === "course") {
            console.log(data, "type and data in course")
            let allSelectedData = { ...selectedDropdownData };
            let selecData = [];
            const isSpecialOptionSelected = data.some(
                (option) => option?.value === "ANY"
            );
            console.log(editType, disableOther, data, "any type")

            if (isSpecialOptionSelected) {
                data = [{ label: 'Any', value: 'ANY' }]
                console.log("getting in any", data)
                formikDetails.setFieldValue("course", ['ANY']);
                formikDetails.values.course = [{ label: 'Any', value: 'ANY' }]
                allSelectedData.course = [{ label: 'Any', value: 'ANY' }];
                setSelectedDropdownData({ ...allSelectedData })
            } else if (!isSpecialOptionSelected) {
                data.map((loc) => {
                    selecData.push(loc.value);
                });
                formikDetails.setFieldValue("course", [...selecData]);
                formikDetails.values.course = [...selecData]
                allSelectedData.course = [...selecData];
                setSelectedDropdownData({ ...allSelectedData })
            }

        }
        //this code is commented bexause it impacts the onChange on location while editing the job post.

        // if (type === "workMode") {
        //     let loc = []
        //     formikDetails.values?.location?.map((locations) => {
        //         loc.push({ valie: locations, label: locations })
        //     })
        //     console.log(formikDetails.values.location, "formikDetails.values.location")
        //     setSelectedLocationData(loc)
        //     if (data?.[0]?.value === "REMOTE") {
        //         setLocationRequired(true)
        //         console.log(formikDetails.errors, "formikDetails.errors")
        //         formikDetails.errors?.location && delete formikDetails.errors['location'];
        //     }
        // }

        if (type === "specialization") {
            let allSelectedData = { ...selectedDropdownData };
            let selecData = [];
            const isSpecialOptionSelected = data.some(
                (option) => option?.value === "ANY"
            );

            if (isSpecialOptionSelected) {
                data = [{ label: 'Any', value: 'ANY' }]
                console.log("getting in any", data)
                formikDetails.setFieldValue("specialization", ['ANY']);
                formikDetails.values.specialization = [{ label: 'Any', value: 'ANY' }]
                allSelectedData.specialization = [{ label: 'Any', value: 'ANY' }];
                setSelectedDropdownData({ ...allSelectedData })
            } else if (!isSpecialOptionSelected) {
                data.map((loc) => {
                    selecData.push(loc.value);
                });
                formikDetails.setFieldValue("specialization", [...selecData]);
                formikDetails.values.specialization = [...selecData]
                allSelectedData.specialization = [...selecData];
                setSelectedDropdownData({ ...allSelectedData })
            }
        }

        if (type === "compName") {
            let allSelectedData = { ...selectedDropdownData };
            console.log(allSelectedData, formikDetails.values,"i am inside this fn----")
            fetchClints(data[0].value)
            let selecData = [];
            data.map((loc) => {
                selecData.push({ value: loc.value, label: loc.value });
            });
            formikDetails.setFieldValue("compName", data[0].value);
            formikDetails.values.compName = data[0].value
            allSelectedData.compName = [...selecData];
            console.log((allSelectedData.spocName, formikDetails.values.spocName,"from dropdown") && props.mode !== "edit")
            if (selectedDropdownData?.[type]?.[0]?.value !== data?.[0]?.value && allSelectedData.spocName.length > 0) {
                formikDetails.values.spocName = ''
                allSelectedData.spocName = []
            }
            setSelectedDropdownData({ ...{ allSelectedData } })
        }

        if (type === "spocName") {
            console.log(clientObj, "clientObj", data[0].value)
            clientObj.map((client) => {
                if (client.value === data[0].value) {
                    setClientId(client.id)
                }
            })
        }
        if (
            type === "industry" &&
            data?.[0]?.value &&
            selectedDropdownData?.[type]?.[0]?.value !== data?.[0]?.value
        ) {
            try {
                let encodedValue = encodeURIComponent(data?.[0]?.value);
                let response = await getroleListReq(encodedValue);
                let rolelistData = [];
                try {
                    if (response.data) {
                        response.data?.response?.map((data) => {
                            rolelistData.push({ label: data, value: data });
                        });
                    }
                } catch {
                    console.log(response.error)
                }
                setRoleData([...rolelistData]);
            } catch (err) {
                console.error(err, "role api error");
            }
            if (selectedDropdownData?.[type]?.[0]?.value !== data?.[0]?.value && !editType) {
                let clear = document.getElementsByClassName("css-1dimb5e-singleValue")
                setSelectedRoleData('')
                let allSelectedData = { ...selectedDropdownData };
                allSelectedData["jobTitle"] = []
                // formikDetails.setFieldValue("roles", "");
                formikDetails.values.roles = "";
                formikDetails.setFieldValue("jobTitle", "");
                setSelectedDropdownData({ ...allSelectedData });
                formikDetails.setFieldValue("skills", []);
                setSelectedSkillsData([]);
            }
        }


        if (
            type === "qualification" &&
            data?.[0]?.value &&
            (data[0]?.value === "MASTERS_DEGREE" || data[0].value === "DEGREE") &&
            selectedDropdownData?.[type]?.[0]?.value !== data?.[0]?.value
        ) {
            getTitles(data[0]?.value);
            formikDetails.values.course = [];
            formikDetails.values.specialization = [];
            selectedDropdownData.course = [];
            selectedDropdownData.specialization = [];
        }

        if (
            type === "qualification" &&
            data?.[0]?.value &&
            (data[0]?.value === "DIPLOMA" || data[0].value === "ITI") &&
            selectedDropdownData?.[type]?.[0]?.value !== data?.[0]?.value
        ) {
            getStreams(data?.[0]);
        }

        console.log(type, "typetypetype")


        if (!editType) {
            let allSelectedData = { ...selectedDropdownData };
            allSelectedData[type] = [...data];

            if (type === "qualification") {
                if (data[0].value === "ITI" && selectedDropdownData?.[type]?.[0]?.value !== data?.[0]?.value) {
                    if (formikDetails.values.specialization === "none") {
                        formikDetails.values.specialization = [];
                        allSelectedData.specialization = [];
                    }
                    formikDetails.values.course = [data[0].value];
                    formikDetails.values.specialization = [];
                    allSelectedData.course = data;
                    allSelectedData.specialization = [];
                } else if (data[0].value === "DIPLOMA" && selectedDropdownData?.[type]?.[0]?.value !== data?.[0]?.value) {
                    if (formikDetails.values.specialization === "none") {
                        formikDetails.values.specialization = [];
                        allSelectedData.specialization = [];
                    }
                    formikDetails.values.course = [data[0].value];
                    formikDetails.values.specialization = [];
                    allSelectedData.course = data;
                    allSelectedData.specialization = [];
                } else {
                    if (data[0].value === "PUC" || data[0].value === "SSLC") {
                        formikDetails.values.course = ["none"];
                        formikDetails.values.specialization = ["none"];
                        allSelectedData.course = [{ label: "none", value: "none" }];
                        allSelectedData.specialization = [{ label: "none", value: "none" }];
                    } else if (props.mode !== "edit") {
                        formikDetails.values.course = [];
                        formikDetails.values.specialization = [];
                        allSelectedData.course = [];
                        allSelectedData.specialization = [];
                    }
                }
            }
            if (type === "course") {
                formikDetails.values.specialization = [];
                allSelectedData.specialization = [];
            }


            setSelectedDropdownData({ ...allSelectedData });
            if (type != "course" && type != "specialization") {
                formikDetails.setFieldValue(type, data?.[0]?.value);
            }
        }
    };
    console.log(selectedDropdownData, "selectedDropdownData after onchange")
    const handleCount = (value) => {
        let charCount = value.length,
            current = $("#current");
        current.text(charCount);
    };



    const getDropdownData = async () => {
        let allData = { ...dropDownData };
        try {
            let response = await getIndustriesListReq();
            let indusData = [];
            if (response.data) {
                response.data?.response?.forEach((data) => {
                    indusData.push({ label: data, value: data });
                });
            }
            allData.industry = [...indusData];
        } catch (err) {
            console.error(err, "industries list api error");
        }
        try {
            let response = await getTotalCitiesReq();
            let cityData = [];
            if (response?.data?.response?.length > 0) {
                response?.data?.response.forEach((data) => {
                    cityData.push({ label: data, value: data });
                });
            }
            cityData.push({ label: "Pan India", value: "Pan India" }, {label: "Remote", value: "Remote"});
            setLocationsData([...cityData]);
        } catch (err) {
            console.error(err, "All cities api error");
        }
        if (props.mode === "edit") {
            if (
                props.selectedJob.highestQualification === "MASTERS_DEGREE" ||
                props.selectedJob.highestQualification === "DEGREE"
            ) {
                try {
                    const res = await getAllTitlesReq(
                        props.selectedJob.highestQualification
                    );
                    console.log("All titles Data", res);
                    if (res?.data) {
                        let arr = []
                        let tempTitle = res.data.response.map((val) => {
                            arr.push({ value: val, label: val })
                        });
                        arr.push({ label: "Any", value: "ANY" })
                        allData.course = [...arr]
                    } else {
                        allData.course = [];
                    }
                } catch (e) {
                    console.log("titles error", e);
                }
            }
            try {
                let res = {};
                if (
                    props.selectedJob.highestQualification === "MASTERS_DEGREE" ||
                    props.selectedJob.highestQualification === "DEGREE"
                ) {
                    res = await getAllStreamsReq(
                        props.selectedJob.highestQualification,
                        props.selectedJob.course
                    );
                }
                if (
                    props.selectedJob.highestQualification === "DIPLOMA" ||
                    props.selectedJob.highestQualification === "ITI"
                ) {
                    { console.log(props.selectedJob.course, "props.selectedJob.course") }
                    let course = props.selectedJob.course[0] === "ITI" ? "iti" : "diploma"
                    res = await getAllStreamsReq(
                        props.selectedJob.course,
                        course
                    );
                }
                console.log("All specialization Data", res);
                if (res?.data) {
                    let tempStream = res.data.response.map((val) => {
                        return stringToDropdownValue(val);
                    });
                    tempStream.push({ label: "Any", value: "ANY" })
                    allData.specialization = [...tempStream];

                } else {
                    allData.specialization = [];
                }
            } catch (e) {
                console.log("specialization error", e);
            }
        }
        setDropDownData({ ...allData });
    };

    const CustomNoOption = () => (
        <div
            className={`noData pt-5 pb-5 flex justify-center
          }`}
        >
            {/* {noOptionMsg ? noOptionMsg : "No options"} */}
        </div>
    );


    useEffect(() => {
        getDropdownData();
        if (props.mode === "edit" && props.selectedJob) {
            let allSelectedData = { ...selectedDropdownData };
            allSelectedData.location = stringToDropdownValue(
                props.selectedJob.location
            );
            console.log(props.selectedJob?.location, "props.selectedJob?.location")
            if (props.selectedJob?.roles) {
                handleRoleChange(
                    [stringToDropdownValue(props.selectedJob.roles)],
                    true,
                    props.selectedJob.industry
                );
            }
            if (props.selectedJob.companyName) {
                fetchClints(props.selectedJob.companyName, "fromEdit");
            }
            if (props.selectedJob?.keySkills?.length > 0) {
                let skillset = [];
                let skillsData = [];
                props.selectedJob?.keySkills?.map((data) => {
                    skillsData.push(data);
                    skillset.push(stringToDropdownValue(data));
                });
                formikDetails.setFieldValue("skills", [...skillsData]);
                setSelectedSkillsData([...skillset]);
            }
            if (props.selectedJob?.vacancyCount) {
                allSelectedData.vacancyCount = [
                    stringToDropdownValue(props.selectedJob.vacancyCount),
                ];
            }
            if (props.selectedJob?.functionalArea) {
                allSelectedData.functionalArea = [
                    stringToDropdownValue(props.selectedJob.functionalArea),
                ];
            }
            if (props.selectedJob?.industry) {
                let indust = stringToDropdownValue(props.selectedJob.industry);
                allSelectedData.industry = [{ ...indust }];
                handleDropdownChange([indust], "industry", true);
            }
            if (props.selectedJob?.companyName) {
                allSelectedData.compName = [{ label: props.selectedJob?.companyName, value: props.selectedJob?.companyName }]
                setSelectedDropdownData({ ...allSelectedData })
            }

            if (props.selectedJob?.clientName) {
                allSelectedData.spocName = [{ label: props.selectedJob?.clientName, value: props.selectedJob?.clientName }]
                setSelectedDropdownData({ ...allSelectedData })
            }
            if (props.selectedJob?.gender?.length > 0) {
                let gender = []
                props.selectedJob.gender.map((ele) => {
                    gender.push({ label: ele, value: ele })
                })
                setSelectedGenderData(gender);
            }

            if (props.selectedJob?.location?.length > 0) {
                let locationSet = [];
                let locationData = [];
                props.selectedJob?.location?.map((data) => {
                    locationData.push(data);
                    locationSet.push(stringToDropdownValue(data));
                });
                formikDetails.setFieldValue("location", [...locationData]);
                setSelectedLocationData([...locationSet]);
            }

            // if (props.selectedJob?.location?.length > 0) {
            //     let locationSet = [];
            //     let locationData = [];
            //     props.selectedJob?.location?.map((data) => {
            //         locationData.push(data);
            //         locationSet.push(stringToDropdownValue(data));
            //     });
            //     formikDetails.setFieldValue("location", [...locationData]);
            //     setSelectedLocationData([...locationSet]);
            // }
            if (props.selectedJob?.employmentType) {
                allSelectedData.employmentType = [
                    dropdownLabelFinder(
                        props.selectedJob.employmentType,
                        dropDownData.employmentType
                    ),
                ];
            }
            if (props.selectedJob?.workMode) {
                console.log(formikDetails.values.workMode, "props.selectedJob?.workMode[0].value ")
                if (formikDetails.values.workMode || props.selectedJob?.workMode === "REMOTE") {
                    setLocationRequired(true)
                } else {
                    setLocationRequired(false)
                }
                allSelectedData.workMode = [
                    dropdownLabelFinder(
                        props.selectedJob.workMode,
                        dropDownData.workMode
                    ),
                ];
            }
            if (props.selectedJob?.specialization?.length > 0) {
                let specialization = []
                props.selectedJob.specialization.map((ele) => {
                    specialization.push({ label: ele, value: ele })
                })
                allSelectedData.specialization = specialization;
            }
            if (props.selectedJob?.gender?.length > 0) {
                let gender = []
                props.selectedJob.gender.map((ele) => {
                    gender.push({ label: ele, value: ele })
                })
                setSelectedGenderData(gender);
            }
            if (props.selectedJob?.course?.length > 0) {
                let course = []
                props.selectedJob.course.map((ele) => {
                    course.push({ label: ele, value: ele })
                })
                allSelectedData.course = course;
            }
            if (props.selectedJob?.highestQualification) {
                allSelectedData.qualification = [
                    dropdownLabelFinder(
                        props.selectedJob.highestQualification,
                        dropDownData.qualification
                    ),
                ];
            }
            if (props.selectedJob.status) {
                let status = "";
                if (props.selectedJob.status === "CREATE") {
                    status = "UPDATE";
                } else {
                    status = props.selectedJob.status;
                }
                allSelectedData.status = [
                    {
                        value: status,
                        label: status,
                    },
                ];
            }
            let date = "";
            if (props.selectedJob?.dateForSharingProfiles) {
                let dateArray = props.selectedJob.dateForSharingProfiles.split("/");
                date = `${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`;
            }
            setDateValue(dayjs(date));
            setSelectedDropdownData({ ...allSelectedData });
        }
        fetchCompanies();
    }, []);
    const fetchCompanies = async () => {
        try {
            const response = await getAllCompany();
            const companies = response.data.response.map((company) => ({
                label: company,
                value: company,
            }));
            setCompanyData(companies);
        } catch (error) {
            console.error("Error fetching companies:", error);
        }
    }
    const fetchClints = async (companyName, mode) => {
        try {
            const response = await getAllClintsByCompany(companyName);
            const clientsObj = response.data.response.map((client) => ({
                label: client.name,
                value: client.name,
                id: client.id
            }));
            setClintNames(clientsObj);
            setClientObj(clientsObj)
        } catch (error) {
            console.error("Error fetching clints:", error);
        }

        if (mode === "fromEdit" && clientId === '') {
            setClientId(props.selectedJob.clientId)
        }
    }
    useEffect(() => {
        // Check if the form is in "edit" mode
        if (props.mode === "edit") {
            const isDiplomaOrITI =
                formikDetails.values.qualification === "DIPLOMA" ||
                formikDetails.values.qualification === "ITI";
            setCourseError(
                isDiplomaOrITI
                    ? ""
                    : formikDetails.values.course
                        ? ""
                        : "Course is required"
            );
            setSpecializationError(
                isDiplomaOrITI
                    ? !formikDetails.values.specialization
                        ? "Specialization is required"
                        : ""
                    : formikDetails.values.specialization
                        ? ""
                        : "Specialization is required"
            );
        } else {
            // Update validation errors based on Formik validation
            setCourseError(
                formikDetails.values.qualification && formikDetails.errors.course
            ); // Clear the error when not present
            setSpecializationError(
                formikDetails.values.qualification &&
                formikDetails.errors.specialization
            ); // Clear the error when not present
            setLocationErrer(
                formikDetails.values.location && formikDetails.errors.location
            );
        }

    }, [
        props.mode,
        formikDetails.values.qualification,
        formikDetails.values.course,
        formikDetails.values.specialization,
        formikDetails.errors.course,
        formikDetails.errors.specialization,
        formikDetails.values.workMode,
        formikDetails.errors.workMode
    ]);
    return (
        <div className="post-job-modal">
            <form className="job-form-tag" onSubmit={formikDetails.handleSubmit}>
                <div className="job-modal-body">
                    {props.mode === "edit" ? (
                        <div className="job-modal-header job-edit-header">
                            <div
                                className="max-w-[50%] inline-block datatrimmer"
                                title={props.selectedJob.jobHeadlines}
                            >
                                Edit Post Job - {props.selectedJob.jobHeadlines}
                            </div>
                            <div className="status-field-edit mt-2 individual-field">
                                {/* <div className="mb-2 label">
                  <span className="create-labels">Roles</span>
                </div> */}
                                <div className="input-ele text-base">
                                    <SelectDropdown
                                        data={dropDownData.status}
                                        onChange={(data) => {
                                            handleDropdownChange(data, "status");
                                        }}
                                        selectedData={selectedDropdownData.status}
                                        deselection={true}
                                        searchable={true}
                                    />
                                </div>
                                {formikDetails.errors.status &&
                                    formikDetails.touched.status &&
                                    !formikDetails.values.status ? (
                                    <div className="text-red-500 error_text md:mt-1">
                                        {formikDetails.errors.status}
                                    </div>
                                ) : null}
                            </div>

                        </div>
                    ) : (
                        <div className="job-modal-header">Post Job</div>
                    )}
                    <div className="job-form grid grid-cols-3">
                        <div className="mt-2 individual-field">
                            <div className="mb-2 label">
                                <span className="create-labels"><span className="text-danger">*</span>Job Headline</span>
                            </div>
                            <div className="popins-medium input-ele">
                                <TextField
                                    error={
                                        formikDetails.errors.name && formikDetails.touched.name
                                    }
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onClick={() => { }}
                                    type="text"
                                    name="name"
                                    id="name"
                                    inputProps={{
                                        className: cx(
                                            `!text-xs open-sans-regular boxedInput popins-medium`
                                        ),
                                        style: {
                                            padding: "9.5px 14px",
                                            height: "0.43em !important",
                                            fontSize: "12px",
                                            fontFamily: "Poppins-Medium",
                                            color: "#525252",
                                        },
                                    }}
                                    autoComplete="off"
                                    placeholder="Enter Job Headline"
                                    onBlur={formikDetails.handleBlur}
                                    onChange={formikDetails.handleChange}
                                    value={formikDetails.values.name}
                                />
                            </div>
                            {formikDetails.errors.name && formikDetails.touched.name ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.name}
                                </div>
                            ) : null}
                        </div>
                        <div className="msg-field col-span-3">
                            <div className="form-group text-left mb-0">
                                <div className="mb-2 label">
                                    <span className="create-labels"><span className="text-danger">*</span>Job Description</span>
                                </div>
                                <textarea
                                    className="form-control shadow-none input-ele textarea_contact"
                                    name="description"
                                    cols="40"
                                    resize="none"
                                    rows="5"
                                    value={formikDetails.values.description}
                                    onChange={formikDetails.handleChange}
                                    style={{ border: inputBorder }}
                                    //   onKeyDown={handleEnter}
                                    onKeyUp={(e) => handleCount(e.target.value)}
                                ></textarea>
                            </div>
                            <div className="d-flex  mb-1 labelTitleDemo">
                                {formikDetails.errors.description &&
                                    formikDetails.touched.description ? (
                                    <div className="text-red-500 error_text md:mt-1">
                                        {formikDetails.errors.description}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="field-wrapper mt-2 individual-field">
                            <div className="mb-2 label">
                                <span className="create-labels"><span className="text-danger">*</span>Industry</span>
                            </div>
                            <div className="input-ele">
                                <SelectDropdown
                                    data={dropDownData.industry}
                                    onChange={(data) => {
                                        handleDropdownChange(data, "industry");
                                    }}
                                    selectedData={selectedDropdownData.industry}
                                    deselection={true}
                                    searchable={true}
                                />
                            </div>
                            {formikDetails.errors.industry &&
                                formikDetails.touched.industry ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.industry}
                                </div>
                            ) : null}
                        </div>
                        <div className="field-wrapper mt-2 individual-field">
                            <div className="mb-2 label">
                                <span className="create-labels"><span className="text-danger">*</span>Functional Area</span>
                            </div>
                            <div className="input-ele">
                                <SelectDropdown
                                    data={dropDownData.functionalArea}
                                    onChange={(data) => {
                                        handleDropdownChange(data, "functionalArea");
                                    }}
                                    selectedData={selectedDropdownData.functionalArea}
                                    deselection={true}
                                    searchable={true}
                                />
                            </div>
                            {formikDetails.errors.functionalArea &&
                                formikDetails.touched.functionalArea ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.functionalArea}
                                </div>
                            ) : null}
                        </div>
                        {console.log(selectedOption, "selectedOption")}
                        <div className="field-wrapper mt-2 individual-field">
                            <div className="mb-2 label">
                                <span className="create-labels"><span className="text-danger">*</span>Role</span>
                            </div>
                            <div className="input-ele">
                                {/* <SelectDropdown
                                    noOptionMsg={
                                        selectedDropdownData.industry[0]?.value
                                            ? ""
                                            : "please select industry"
                                    }
                                    data={roleData}
                                    onChange={handleRoleChange}
                                    selectedData={selectedOption}
                                    deselection={true}
                                    searchable={true}
                                /> */}
                                <CreatableSelect
                                    options={roleData}
                                    // value={
                                    //     selectedDropdownData?.jobTitle?.value === ""
                                    //         ? null
                                    //         : selectedDropdownData?.jobTitle
                                    // }
                                    value={
                                        selectedRoleData
                                    }
                                    isSearchable={true}
                                    placeholder="Select or create your role"
                                    isClearable={false}
                                    onChange={(data) => {
                                        handleRoleChange(data ? [data] : [], "", selectedDropdownData?.jobTitle?.value)
                                        handleDropdownChange(data ? [data] : [], "jobTitle", ["skills"]);
                                    }}
                                // onMenuOpen={getRolesHandler}
                                // isDisabled={formikDetails.values.roles !== "" ? false : true}
                                // ... other props
                                />
                            </div>
                            {console.log(formikDetails, "formikDetails")}
                            {formikDetails.errors.roles &&
                                formikDetails.touched.roles &&
                                !formikDetails.values.roles ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.roles}
                                </div>
                            ) : null}
                        </div>
                        {console.log(selectedOption, "selectedOption")}
                        <div className="field-wrapper mt-2 individual-field">
                            <div className="mb-2 label">
                                <span className="create-labels"><span className="text-danger">*</span>Key Skills</span>
                            </div>
                            <div className="input-ele">
                                {/* <ReactSelectDropdown
                                    noOptionMsg={
                                        selectedOption?.value
                                            ? "no key skills found"
                                            : "please select role"
                                    }
                                    className="my-select-container-postJob cursor-pointer"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={handleSkillsChange}
                                    options={skillsData}
                                    value={selectedSkillsData}
                                /> */}
                                {/* <CreatableSelect
                                    options={skillsData}
                                    value={selectedDropdownData?.skills}
                                    isMulti
                                    isSearchable={true}
                                    placeholder="Select or create your skills"
                                    isClearable={false}
                                    className="my-select-container"
                                    onChange={(data) => {
                                        handleSkillsChange(data);
                                    }}
                                // onMenuOpen={getSkillsHandler}
                                // isDisabled={
                                //   formik.values.domain !== "" && formik.values.jobTitle !== ""
                                //     ? false
                                //     : true
                                // }
                                /> */}
                                <CreatableSelect
                                    className="my-select-container-postJob cursor-pointer"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={(data) => {
                                        handleSkillsChange(data);
                                    }}
                                    options={skillsData}
                                    value={selectedSkillsData}
                                />
                            </div>
                            {formikDetails.errors.skills && formikDetails.touched.skills ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.skills}
                                </div>
                            ) : null}
                        </div>
                        <div className="field-wrapper mt-2 individual-field">
                            <div className="mb-2 label">
                                <span className="create-labels"><span className="text-danger">*</span>Employment Type</span>
                            </div>
                            <div className="input-ele">
                                <SelectDropdown
                                    data={dropDownData.employmentType}
                                    onChange={(data) => {
                                        handleDropdownChange(data, "employmentType");
                                    }}
                                    selectedData={selectedDropdownData.employmentType}
                                    deselection={true}
                                    searchable={true}
                                />
                            </div>
                            {formikDetails.errors.employmentType &&
                                formikDetails.touched.employmentType ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.employmentType}
                                </div>
                            ) : null}
                        </div>
                        <div className="field-wrapper mt-2 individual-field">
                            <div className="mb-2 label">
                                <span className="create-labels"><span className="text-danger">*</span>Work Mode</span>
                            </div>
                            <div className="input-ele">
                                <SelectDropdown
                                    data={dropDownData.workMode}
                                    onChange={(data) => {
                                        handleDropdownChange(data, "workMode");
                                    }}
                                    selectedData={selectedDropdownData.workMode}
                                    deselection={true}
                                    searchable={true}
                                />
                            </div>
                            {formikDetails.errors.workMode &&
                                formikDetails.touched.workMode ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.workMode}
                                </div>
                            ) : null}
                        </div>
                        <div className="field-wrapper mt-2 individual-field">
                            <div className="mb-2 label">
                                <span className="create-labels"><span className="text-danger">*</span>Work Experience</span>
                            </div>
                            <div className="multi-input-eles flex">
                                <div className="multi-input-1">
                                    <TextField
                                        error={
                                            formikDetails.errors.expMin &&
                                            formikDetails.touched.expMin
                                        }
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onClick={() => { }}
                                        type="text"
                                        name="expMin"
                                        id="expMin"
                                        inputProps={{
                                            className: cx(
                                                `!text-xs open-sans-regular boxedInput popins-medium`
                                            ),
                                            style: {
                                                padding: "9.5px 14px",
                                                height: "0.43em !important",
                                                fontSize: "12px",
                                                fontFamily: "Poppins-Medium",
                                                color: "#525252",
                                            },
                                        }}
                                        autoComplete="off"
                                        placeholder="Min"
                                        onBlur={formikDetails.handleBlur}
                                        onChange={formikDetails.handleChange}
                                        value={formikDetails.values.expMin}
                                    />
                                </div>
                                <div className="multi-input-2">
                                    <TextField
                                        error={
                                            formikDetails.errors.expMax &&
                                            formikDetails.touched.expMax
                                        }
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onClick={() => { }}
                                        type="text"
                                        name="expMax"
                                        id="expMax"
                                        inputProps={{
                                            className: cx(
                                                `!text-xs open-sans-regular boxedInput popins-medium`
                                            ),
                                            style: {
                                                padding: "9.5px 14px",
                                                height: "0.43em !important",
                                                fontSize: "12px",
                                                fontFamily: "Poppins-Medium",
                                                color: "#525252",
                                            },
                                        }}
                                        autoComplete="off"
                                        placeholder="Max"
                                        onBlur={formikDetails.handleBlur}
                                        onChange={formikDetails.handleChange}
                                        value={formikDetails.values.expMax}
                                    />
                                </div>
                            </div>
                            {(formikDetails.errors.expMin && formikDetails.touched.expMin) ||
                                (formikDetails.errors.expMax && formikDetails.touched.expMax) ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.expMin
                                        ? formikDetails.errors.expMin
                                        : formikDetails.errors.expMax}
                                </div>
                            ) : null}
                        </div>
                        <div className="field-wrapper mt-2 individual-field">
                            <div className="mb-2 label">
                                <span className="create-labels"><span className="text-danger">*</span>Salary Range / Year</span>
                            </div>
                            <div className="multi-input-eles flex">
                                <div className="multi-input-1">
                                    <TextField
                                        error={
                                            formikDetails.errors.slryMin &&
                                            formikDetails.touched.slryMin
                                        }
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onClick={() => { }}
                                        type="text"
                                        name="slryMin"
                                        id="slryMin"
                                        inputProps={{
                                            className: cx(
                                                `!text-xs open-sans-regular boxedInput popins-medium`
                                            ),
                                            style: {
                                                padding: "9.5px 14px",
                                                height: "0.43em !important",
                                                fontSize: "12px",
                                                fontFamily: "Poppins-Medium",
                                                color: "#525252",
                                            },
                                        }}
                                        autoComplete="off"
                                        placeholder="Min"
                                        onBlur={formikDetails.handleBlur}
                                        onChange={formikDetails.handleChange}
                                        value={formikDetails.values.slryMin}
                                    />
                                </div>
                                <div className="multi-input-2">
                                    <TextField
                                        error={
                                            formikDetails.errors.slryMax &&
                                            formikDetails.touched.slryMax
                                        }
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onClick={() => { }}
                                        type="text"
                                        name="slryMax"
                                        id="slryMax"
                                        inputProps={{
                                            className: cx(
                                                `!text-xs open-sans-regular boxedInput popins-medium`
                                            ),
                                            style: {
                                                padding: "9.5px 14px",
                                                height: "0.43em !important",
                                                fontSize: "12px",
                                                fontFamily: "Poppins-Medium",
                                                color: "#525252",
                                            },
                                        }}
                                        autoComplete="off"
                                        placeholder="Max"
                                        onBlur={formikDetails.handleBlur}
                                        onChange={formikDetails.handleChange}
                                        value={formikDetails.values.slryMax}
                                    />
                                </div>
                            </div>
                            {(formikDetails.errors.slryMin &&
                                formikDetails.touched.slryMin) ||
                                (formikDetails.errors.slryMax &&
                                    formikDetails.touched.slryMax) ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.slryMin
                                        ? formikDetails.errors.slryMin
                                        : formikDetails.errors.slryMax}
                                </div>
                            ) : null}
                        </div>
                        {console.log(selectedDropdownData.workMode, formikDetails.values, "selectedDropdownData.workMode")}
                        <div className="field-wrapper mt-2 individual-field cursor-pointer">
                            <div className="mb-2 label">
                                <span className="create-labels"><span className="text-danger">*</span>Location</span>
                            </div>
                            <div className="input-ele cursor-pointer">
                                {/* <SelectDropdown
                                    data={dropDownData.location}
                                    onChange={(data) => {
                                        handleDropdownChange(data, "location");
                                    }}
                                    selectedData={selectedDropdownData.location}
                                    backspaceDelete={true}
                                    deselection={true}
                                    searchable={true}
                                />
                                <MultiSelectDropdown

                                    data={locationData}
                                    handleSelectChange={handleLocationChange}
                                    selectedData={selectedLocationData}

                                /> */}
                                {console.log(selectedLocationData, "selectedLocationDataselectedLocationData")}
                                <ReactSelectDropdown
                                    className="my-select-container-postJob cursor-pointer"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={(data) => { handleLocationChange(data) }}
                                    options={locationData}
                                    value={selectedLocationData}
                                    specialOption={{ value: "Pan India", label: "Pan India" }}
                                />
                            </div>
                            {formikDetails.errors.location &&
                                formikDetails.touched.location ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.location}
                                </div>
                            ) : null}
                        </div>
                        <div className="field-wrapper mt-2 individual-field">
                            <div className="mb-2 label">
                                <span className="create-labels"><span className="text-danger">*</span>Vacancy Count</span>
                            </div>
                            <div className="input-ele">
                                <TextField
                                    error={
                                        formikDetails.errors.vacancyCount &&
                                        formikDetails.touched.vacancyCount
                                    }
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onClick={() => { }}
                                    type="text"
                                    name="vacancyCount"
                                    id="vacancyCount"
                                    inputProps={{
                                        className: cx(
                                            `!text-xs open-sans-regular boxedInput popins-medium`
                                        ),
                                        style: {
                                            padding: "9.5px 14px",
                                            height: "0.43em !important",
                                            fontSize: "12px",
                                            fontFamily: "Poppins-Medium",
                                            color: "#525252",
                                        },
                                    }}
                                    autoComplete="off"
                                    placeholder="Enter vacancy count"
                                    onBlur={formikDetails.handleBlur}
                                    onChange={formikDetails.handleChange}
                                    value={formikDetails.values.vacancyCount}
                                />
                            </div>
                            {formikDetails.errors.vacancyCount &&
                                formikDetails.touched.vacancyCount ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.vacancyCount}
                                </div>
                            ) : null}
                        </div>
                        <div className="field-wrapper mt-2 individual-field">
                            <div className="mb-2 label">
                                <span className="create-labels"><span className="text-danger">*</span>Highest Qualification</span>
                            </div>
                            <div className="input-ele">
                                <SelectDropdown
                                    data={dropDownData.qualification}
                                    onChange={(data) => {
                                        console.log("qualification", data[0].value);
                                        // let allData = { ...selectedDropdownData };
                                        // if (
                                        //   data[0].value === "DIPLOMA" ||
                                        //   data[0].value === "ITI"
                                        // ) {
                                        //   formikDetails.values.course = data[0].value;
                                        //   formikDetails.values.specialization = "";
                                        //   allData.course = data;
                                        //   allData.specialization = [];
                                        // } else {
                                        //   formikDetails.values.course = "";
                                        //   formikDetails.values.specialization = "";
                                        //   allData.course = [];
                                        //   allData.specialization = [];
                                        // }
                                        // setSelectedDropdownData({ ...allData });
                                        handleDropdownChange(data, "qualification");
                                    }}
                                    selectedData={selectedDropdownData.qualification}
                                    deselection={true}
                                    dropdownPosition={"auto"}
                                    searchable={true}
                                    components={{
                                        Option: (props) => (
                                            <InputOption
                                                {...props}
                                                disableOther={disableOther} // Pass the disableOther state
                                            />
                                        ),
                                        NoOptionsMessage: CustomNoOption,
                                    }}
                                />
                            </div>
                            {formikDetails.errors.qualification &&
                                formikDetails.touched.qualification ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.qualification}
                                </div>
                            ) : null}
                        </div>
                        <div
                            className={`${formikDetails.values.qualification === "PUC" ||
                                formikDetails.values.qualification === "SSLC"
                                ? "hidden"
                                : ""
                                } field-wrapper mt-2 individual-field`}
                        >
                            <div className="mb-2 label">
                                <span className="create-labels"><span className="text-danger">*</span>Course</span>
                            </div>
                            <div className="input-ele">
                                {/* <SelectDropdown
                                    data={dropDownData.course}
                                    disabled={
                                        formikDetails.values.qualification === "DIPLOMA" ||
                                        formikDetails.values.qualification === "ITI"
                                    }
                                    onChange={(data) => {
                                        handleDropdownChange(data, "course");
                                    }}
                                    selectedData={selectedDropdownData.course}
                                    deselection={true}
                                    searchable={true}
                                    dropdownPosition={"auto"}
                                /> */}
                                {console.log(selectedDropdownData.course, "selectedDropdownData.course")}
                                <CreatableSelect
                                    className="my-select-container-postJob cursor-pointer"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={(data) => {
                                        handleDropdownChange(data, "course");
                                    }}
                                    options={dropDownData.course}
                                    value={selectedDropdownData.course}
                                    isDisabled={selectedDropdownData.course[0]?.value === "DIPLOMA" || selectedDropdownData.course[0]?.value === "ITI"}
                                />
                            </div>
                            {formikDetails.errors.course && formikDetails.touched.course ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.course}
                                </div>
                            ) : null}
                            {courseError &&
                                !(
                                    formikDetails.errors.course && formikDetails.touched.course
                                ) && (
                                    <div className="text-red-500 error_text md:mt-1">
                                        {courseError}
                                    </div>
                                )}
                        </div>
                        <div
                            className={`${formikDetails.values.qualification === "PUC" ||
                                formikDetails.values.qualification === "SSLC"
                                ? "hidden"
                                : ""
                                } field-wrapper mt-2 individual-field`}
                        >
                            <div className="mb-2 label">
                                <span className="create-labels"><span className="text-danger">*</span>Specialization</span>
                            </div>
                            <div className="input-ele">
                                {/* <SelectDropdown
                                    data={dropDownData.specialization}
                                    onChange={(data) => {
                                        handleDropdownChange(data, "specialization");
                                    }}
                                    selectedData={selectedDropdownData.specialization}
                                    deselection={true}
                                    searchable={true}
                                    dropdownPosition={"auto"}
                                /> */}
                                <CreatableSelect
                                    className="my-select-container-postJob cursor-pointer"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={(data) => {
                                        handleDropdownChange(data, "specialization");
                                    }}
                                    options={dropDownData.specialization}
                                    onMenuOpen={() => {
                                        if (formikDetails.values.qualification !== "ITI" && formikDetails.values.qualification !== "DIPLOMA") {
                                            handleSpacilizationData()
                                        }
                                    }}
                                    value={selectedDropdownData.specialization}
                                // components={{
                                //     Option: (props) => (
                                //         <InputOption
                                //             {...props}
                                //             disableOther={disableOther} // Pass the disableOther state
                                //         />
                                //     ),
                                //     NoOptionsMessage: CustomNoOption,
                                // }}
                                />
                            </div>
                            {formikDetails.errors.specialization &&
                                formikDetails.touched.specialization ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.specialization}
                                </div>
                            ) : null}
                            {specializationError &&
                                !(
                                    formikDetails.errors.specialization &&
                                    formikDetails.touched.specialization
                                ) && (
                                    <div className="text-red-500 error_text md:mt-1">
                                        {specializationError}
                                    </div>
                                )}
                        </div>
                        <div className="field-wrapper mt-2 individual-field">
                            <div className="mb-2 label">
                                <span className="create-labels">
                                    <span className="text-danger">*</span> Last date for sharing profiles
                                </span>
                            </div>
                            <div className="input-ele">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        minDate={today}
                                        value={dateValue}
                                        id="date"
                                        name="date"
                                        // onChange={(newValue) => {
                                        //   // formikDetails.handleChange(newValue)
                                        //   let date = "";
                                        //   date = `${
                                        //     newValue.$D.toString().length === 1
                                        //       ? "0" + newValue.$D.toString()
                                        //       : newValue.$D.toString()
                                        //   }/${
                                        //     newValue.$M.toString().length === 1
                                        //       ? "0" + (newValue.$M + 1).toString()
                                        //       : newValue.$M.toString()
                                        //   }/${newValue.$y.toString()}`;
                                        //   formikDetails.setFieldValue("date", date);
                                        //   setDateValue(newValue);
                                        // }}
                                        onChange={(newValue) => {
                                            const day = newValue.$D.toString().padStart(2, "0");
                                            const month = (newValue.$M + 1)
                                                .toString()
                                                .padStart(2, "0");
                                            const year = newValue.$y.toString();

                                            const formattedDate = `${day}/${month}/${year}`;

                                            formikDetails.setFieldValue("date", formattedDate);
                                            setDateValue(newValue);
                                        }}
                                        format="DD-MM-YYYY"
                                        // placeholder={<span className="date-placeholder">Select...</span>}
                                        className="date-picker"
                                        slotProps={{
                                            textField: {
                                                readOnly: true,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                            {formikDetails.errors.date && formikDetails.touched.date ? (
                                <div className="text-red-500 error_text md:mt-1">
                                    {formikDetails.errors.date}
                                </div>
                            ) : null}
                        </div>
                        {role === "BD" && (
                            <>
                                <div className="field-wrapper mt-2 individual-field">
                                    <div className="mb-2 label">
                                        <span className="create-labels"><span className="text-danger">*</span>Company</span>
                                    </div>
                                    <div className="input-ele">
                                        <SelectDropdown
                                            data={companyData}
                                            onChange={(data) => {
                                                handleDropdownChange(data, "compName");
                                            }}
                                            selectedData={selectedDropdownData.compName}
                                            deselection={true}
                                            searchable={true}
                                        />
                                    </div>
                                    {formikDetails.errors.compName &&
                                        formikDetails.touched.compName ? (
                                        <div className="text-red-500 error_text md:mt-1">
                                            {formikDetails.errors.compName}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="field-wrapper mt-2 individual-field">
                                    <div className="mb-2 label">
                                        <span className="create-labels"><span className="text-danger">*</span>Client</span>
                                    </div>
                                    <div className="input-ele">
                                        <SelectDropdown
                                            data={clintNames}
                                            onChange={(data) => {
                                                handleDropdownChange(data, "spocName");
                                            }}
                                            selectedData={[{value:formikDetails.values.spocName, label:formikDetails.values.spocName}]}
                                            deselection={true}
                                            searchable={true}
                                        />
                                    </div>
                                    {formikDetails.errors.spocName &&
                                        formikDetails.touched.spocName ? (
                                        <div className="text-red-500 error_text md:mt-1">
                                            {formikDetails.errors.spocName}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="field-wrapper mt-2 individual-field">
                                    <div className="mb-2 label">
                                        <span className="create-labels">Percentage</span>
                                    </div>
                                    <div className="input-ele">
                                        <TextField
                                            error={
                                                formikDetails.errors.percentage &&
                                                formikDetails.touched.percentage
                                            }
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onClick={() => { }}
                                            type="text"
                                            name="percentage"
                                            id="percentage"
                                            inputProps={{
                                                className: cx(
                                                    `!text-xs open-sans-regular boxedInput popins-medium`
                                                ),
                                                style: {
                                                    padding: "9.5px 14px",
                                                    height: "0.43em !important",
                                                    fontSize: "12px",
                                                    fontFamily: "Poppins-Medium",
                                                    color: "#525252",
                                                },
                                            }}
                                            autoComplete="off"
                                            placeholder="Enter the required percentage"
                                            onBlur={formikDetails.handleBlur}
                                            onChange={formikDetails.handleChange}
                                            value={formikDetails.values.percentage}
                                        />
                                    </div>
                                    {formikDetails.errors.percentage &&
                                        formikDetails.touched.percentage ? (
                                        <div className="text-red-500 error_text md:mt-1">
                                            {formikDetails.errors.percentage}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="field-wrapper mt-2 individual-field">
                                    <div className="mb-2 label">
                                        <span className="create-labels">Gender</span>
                                    </div>
                                    <div className="input-ele">
                                        <ReactSelectDropdown
                                            className="my-select-container-postJob cursor-pointer"
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            onChange={handleGenderChange}
                                            options={dropDownData.gender}
                                            value={selectedGenderData}
                                        />
                                    </div>
                                    {formikDetails.errors.gender &&
                                        formikDetails.touched.gender ? (
                                        <div className="text-red-500 error_text md:mt-1">
                                            {formikDetails.errors.gender}
                                        </div>
                                    ) : null}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="job-submit-btn items-center ml-auto">
                    <button
                        type="submit"
                        className="datatrimmer submity-btn"
                        title={""}
                    >
                        Submit
                    </button>

                    <button
                        className="datatrimmer cancel-btn mr-4"
                        onClick={() => {
                            if (props.from === "sharedProfile") {
                                props.setEditJobPost(false)
                            }
                            console.log(window.location.pathname, "window.location.pathname")
                            if (role === "BD") {
                                navigate("/bd/job-post");
                                props.setAddJobPost && props.setAddJobPost(false)
                                props.setEdirJobPost && props.setEdirJobPost(false)

                            } else {
                                navigate("/bd/job-post");
                                props.closeModal(true);
                            }
                        }}
                        title={""}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default BdJobPostModal;
