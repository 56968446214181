import React, { useEffect } from "react";
import { SelectDropdown } from "../../../landing-page/common/select-dropdown";
import cx from "classnames";
import { TextField } from "@mui/material";
import Select from "react-select";

const MastersDegree = (props) => {
  useEffect(() => {
    //future
  }, [props]);
  return (
    <div className="masterDegree col-span-3 grid grid-cols-3">
      <div className="col-span-3 heading">Master's Degree</div>
      <div className="col-span-3 body grid grid-cols-3">
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">University</span>
          </div>
          <div className="input-ele">
            <Select
              dpName="mdUniversity"
              options={props.dropDownData.mdUniversity}
              onChange={(data) => {
                props.handleDropdownChange([data], "mdUniversity", [
                  "mdCollegeName",
                ]);
                if (data.value === "Others") {
                  props.mastersDropdownChange(data, "mdUniversity");
                }
              }}
              placeholder="Select a University"
              onMenuOpen={props.universityDataHandler}
              // value={props?.selectedDropdownData?.mdUniversity}
              value={Array.isArray(props?.selectedDropdownData?.mdUniversity) ? (props?.selectedDropdownData?.mdUniversity[0]?.value == "" || props?.selectedDropdownData?.mdUniversity[0]?.value == null)? null : props?.selectedDropdownData?.mdUniversity : props?.selectedDropdownData?.mdUniversity }
              isSearchable={true}
            />
          </div>
          {props.formik.errors?.mdUniversity &&
            props.formik.touched?.mdUniversity ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.mdUniversity}
            </div>
          ) : null}
        </div>
        {props.formik.values.mdUniversity === "Others" && (
          <div className="mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">University Name</span>
            </div>
            <div className="popins-medium input-ele">
              <TextField
                error={
                  props.formik.errors.mdUniversityName &&
                  props.formik.touched.mdUniversityName
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={() => { }}
                type="text"
                name="mdUniversityName"
                id="mdUniversityName"
                inputProps={{
                  className: cx(
                    `!text-xs open-sans-regular boxedInput popins-medium`
                  ),
                  style: {
                    padding: "9.5px 14px",
                    height: "0.43em !important",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    color: "#525252",
                  },
                }}
                autoComplete="off"
                placeholder="Enter the University name"
                onBlur={props.formik.handleBlur}
                onChange={props.formik.handleChange}
                value={props.formik.values.mdUniversityName}
              />
            </div>
            {props.formik.errors.mdUniversityName &&
              props.formik.touched.mdUniversityName ? (
              <div className="text-red-500 error_text md:mt-1">
                {props.formik.errors.mdUniversityName}
              </div>
            ) : null}
          </div>
        )}
        {console.log(props.selectedDropdownData.mdUniversity,"props.selectedDropdownData.mdUniversity")}
        {props.formik.values.mdUniversity === "Others" ?
          <div className="mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">College Name</span>
            </div>
            <div className="popins-medium input-ele">
              <TextField
                error={
                  props.formik.errors.mdCollegeName &&
                  props.formik.touched.mdCollegeName
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={() => { }}
                type="text"
                name="mdCollegeName"
                id="mdCollegeName"
                inputProps={{
                  className: cx(
                    `!text-xs open-sans-regular boxedInput popins-medium`
                  ),
                  style: {
                    padding: "9.5px 14px",
                    height: "0.43em !important",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    color: "#525252",
                  },
                }}
                autoComplete="off"
                placeholder="Enter the college name"
                onBlur={props.formik.handleBlur}
                onChange={props.formik.handleChange}
                value={props.formik.values.mdCollegeName}
              />
            </div>
            {props.formik.errors.mdCollegeName &&
              props.formik.touched.mdCollegeName ? (
              <div className="text-red-500 error_text md:mt-1">
                {props.formik.errors.mdCollegeName}
              </div>
            ) : null}
          </div> : <div className="mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">College Name</span>
            </div>
            <div className="input-ele">
              <Select
                dpName="mdCollegeName"
                options={props.dropDownData.mdCollegeName}
                onChange={(data) => {
                  props.handleDropdownChange([data], "mdCollegeName");
                }}
                placeholder="Select a college"
                onMenuOpen={props.collegeDataHandler}
                // value={props.selectedDropdownData?.mdCollegeName}
                value={Array.isArray(props.selectedDropdownData?.mdCollegeName) ? (props.selectedDropdownData?.mdCollegeName[0]?.value == "" || props.selectedDropdownData?.mdCollegeName[0]?.value == null) ? null :props.selectedDropdownData?.mdCollegeName : null }
                isSearchable={true}
              />
            </div>
            {props.formik.errors?.mdCollegeName &&
              props.formik.touched?.mdCollegeName ? (
              <div className="text-red-500 error_text md:mt-1">
                {props.formik.errors.mdCollegeName}
              </div>
            ) : null}
          </div>}
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Course</span>
          </div>
          <div className="input-ele">
            <Select
              dpName="mdTitle"
              options={props.dropDownData.mdTitle}
              onChange={(data) => {
                props.handleDropdownChange([data], "mdTitle", ["mdStream"]);
                if (data.value === "Others") {
                  props.mastersDropdownChange(data, "mdTitle");
                }
              }}
              placeholder="Select the course"
              onMenuOpen={props.titleDataHandler}
              // value={props.selectedDropdownData.mdTitle}
              value={Array.isArray(props.selectedDropdownData.mdTitle) ? (props.selectedDropdownData.mdTitle[0]?.value == "" || props.selectedDropdownData.mdTitle[0]?.value == null)? null : props.selectedDropdownData.mdTitle : null }
              isSearchable={true}
            />
          </div>
          {props.formik.errors?.mdTitle && props.formik.touched?.mdTitle ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.mdTitle}
            </div>
          ) : null}
        </div>
        {props.formik.values.mdTitle === "Others" && (
          <div className="mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Course Name</span>
            </div>
            <div className="popins-medium input-ele">
              <TextField
                error={
                  props.formik.errors.mdTitleName &&
                  props.formik.touched.mdTitleName
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={() => { }}
                type="text"
                name="mdTitleName"
                id="mdTitleName"
                inputProps={{
                  className: cx(
                    `!text-xs open-sans-regular boxedInput popins-medium`
                  ),
                  style: {
                    padding: "9.5px 14px",
                    height: "0.43em !important",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    color: "#525252",
                  },
                }}
                autoComplete="off"
                placeholder="Enter the Course name"
                onBlur={props.formik.handleBlur}
                onChange={props.formik.handleChange}
                value={props.formik.values.mdTitleName}
              />
            </div>
            {props.formik.errors.mdTitleName &&
              props.formik.touched.mdTitleName ? (
              <div className="text-red-500 error_text md:mt-1">
                {props.formik.errors.mdTitleName}
              </div>
            ) : null}
          </div>
        )}
        {props.formik.values.mdTitle === "Others" ?
          <div className="mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Specialization</span>
            </div>
            <div className="popins-medium input-ele">
              <TextField
                error={
                  props.formik.errors.mdStream &&
                  props.formik.touched.mdStream
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={() => { }}
                type="text"
                name="mdStream"
                id="mdStream"
                inputProps={{
                  className: cx(
                    `!text-xs open-sans-regular boxedInput popins-medium`
                  ),
                  style: {
                    padding: "9.5px 14px",
                    height: "0.43em !important",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    color: "#525252",
                  },
                }}
                autoComplete="off"
                placeholder="Enter the stream"
                onBlur={props.formik.handleBlur}
                onChange={props.formik.handleChange}
                value={props.formik.values.mdStream}
              />
            </div>
            {props.formik.errors.mdStream &&
              props.formik.touched.mdStream ? (
              <div className="text-red-500 error_text md:mt-1">
                {props.formik.errors.mdStream}
              </div>
            ) : null}
          </div> :
          <div className="mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Specialization</span>
            </div>
            <div className="input-ele">
              <Select
                dpName="mdStream"
                options={props.dropDownData.mdStream}
                onChange={(data) => {
                  props.handleDropdownChange([data], "mdStream");
                }}
                placeholder="Select the stream"
                onMenuOpen={props.streamDataHandler}
                // value={props.selectedDropdownData.mdStream}
                value={Array.isArray(props.selectedDropdownData.mdStream) ? (props.selectedDropdownData.mdStream[0]?.value == "" || props.selectedDropdownData.mdStream[0]?.value == null)? null : props.selectedDropdownData.mdStream : null }
                isSearchable={true}
              />
            </div>
            {props.formik.errors?.mdStream && props.formik.touched?.mdStream ? (
              <div className="text-red-500 error_text md:mt-1">
                {props.formik.errors.mdStream}
              </div>
            ) : null}
          </div>}

        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Country</span>
          </div>
          <div className="input-ele">
            <Select
              dpName="mdCountry"
              options={props.dropDownData.mdCountry}
              onChange={(data) => {
                props.handleDropdownChange([data], "mdCountry", [
                  "mdState",
                  "mdCity",
                ]);
              }}
              placeholder="Select a country"
              onMenuOpen={props.countryDataHandler}
              // value={props.selectedDropdownData.mdCountry}
              value={Array.isArray(props.selectedDropdownData.mdCountry) ? (props.selectedDropdownData.mdCountry[0]?.value == "" || props.selectedDropdownData.mdCountry[0]?.value == null)? null : props.selectedDropdownData.mdCountry : null }
              isSearchable={true}
            />
          </div>
          {props.formik.errors?.mdCountry && props.formik.touched?.mdCountry ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.mdCountry}
            </div>
          ) : null}
        </div>

        <div className=" mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">State</span>
          </div>
          <div className="input-ele">
            <Select
              dpName="mdState"
              options={props.dropDownData.mdState}
              onChange={(data) => {
                props.handleDropdownChange([data], "mdState", ["mdCity"]);
              }}
              placeholder="Select a state"
              onMenuOpen={props.stateDataHandler}
              // value={props.selectedDropdownData.mdState}
              value={Array.isArray(props.selectedDropdownData.mdState) ? (props.selectedDropdownData.mdState[0]?.value == "" || props.selectedDropdownData.mdState[0]?.value == null)? null : props.selectedDropdownData.mdState : null }
              isSearchable={true}
            />
          </div>
          {props.formik.errors?.mdState && props.formik.touched?.mdState ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.mdState}
            </div>
          ) : null}
        </div>
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">City</span>
          </div>
          <div className="input-ele">
            <Select
              dpName="mdCity"
              options={props.dropDownData.mdCity}
              onChange={(data) => {
                props.handleDropdownChange([data], "mdCity");
              }}
              placeholder="Select a city"
              onMenuOpen={props.cityDataHandler}
              // value={props.selectedDropdownData.mdCity}
              value={Array.isArray(props.selectedDropdownData.mdCity) ? (props.selectedDropdownData.mdCity[0]?.value == "" || props.selectedDropdownData.mdCity[0]?.value == null)? null : props.selectedDropdownData.mdCity : null }
              isSearchable={true}
            />
          </div>
          {props.formik.errors?.mdCity && props.formik.touched?.mdCity ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.mdCity}
            </div>
          ) : null}
        </div>
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Percentage / CGPA</span>
          </div>
          <div className="popins-medium input-ele grid grid-cols-4">
          <Select
              className="col-span-2"
              options={props.dropDownData.mdGradeType}
              onChange={(data) => {
                props.handleDropdownChange([data], "mdGradeType");
              }}
              placeholder="Select"
              value={props.selectedDropdownData.mdGradeType}
            />
            {console.log(props?.selectedDropdownData.mdGradeType?"props?.selectedDropdownData?.mdGradeType[0]?.value":"")}
            <TextField
              className="col-span-2"
              error={
                props.formik.errors.mdGrade &&
                props.formik.touched.mdGrade
              }
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onClick={() => { }}
              type="text"
              name="mdGrade"
              id="mdGrade"
              inputProps={{
                className: cx(
                  `!text-xs open-sans-regular boxedInput popins-medium`
                ),
                style: {
                  padding: "11px 14px",
                  height: "0.43em !important",
                  fontSize: "12px",
                  fontFamily: "Poppins-Medium",
                  color: "#525252",
                },
              }}
              //props?.selectedDropdownData?.mdGradeType[0]?.value ? props?.selectedDropdownData?.mdGradeType[0]?.value :
              autoComplete="off"
              placeholder={`Enter your ${props?.selectedDropdownData?.mdGradeType ? props?.selectedDropdownData?.mdGradeType[0]?.value : "Percentage"}`}
              onBlur={props.formik.handleBlur}
              onChange={props.formik.handleChange}
              value={props.formik.values.mdGrade}
              onKeyPress={(e) => {
                // Check if the entered character is a number or a dot (for decimal input)
                const validInput = /^[0-9.]$/.test(e.key);

                if (!validInput) {
                  e.preventDefault(); // Prevent input of non-numeric characters
                }
              }}
            />
          </div>
          {props.formik.errors?.mdGradeType &&
          props.formik.errors.mdGradeType &&
          props.formik.touched.mdGradeType ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.mdGradeType}
            </div>
          ) : props.formik.errors.mdGrade &&
            props.formik.touched.mdGrade ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.mdGrade}
            </div>
          ) : null}
        </div>
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Year of Joining</span>
          </div>
          <div className="popins-medium input-ele">
            <TextField
              error={
                props.formik.errors.mdyearOfJoining &&
                props.formik.touched.mdyearOfJoining
              }
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onClick={() => { }}
              type="text"
              name="mdyearOfJoining"
              id="mdyearOfJoining"
              inputProps={{
                className: cx(
                  `!text-xs open-sans-regular boxedInput popins-medium`
                ),
                style: {
                  padding: "9.5px 14px",
                  height: "0.43em !important",
                  fontSize: "12px",
                  fontFamily: "Poppins-Medium",
                  color: "#525252",
                },
              }}
              autoComplete="off"
              placeholder="Enter your joining year"
              onBlur={props.formik.handleBlur}
              onChange={props.formik.handleChange}
              value={props.formik.values.mdyearOfJoining}
            />
          </div>
          {props.formik.errors.mdyearOfJoining &&
            props.formik.touched.mdyearOfJoining ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.mdyearOfJoining}
            </div>
          ) : null}
        </div>
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Year of Passing</span>
          </div>
          <div className="popins-medium input-ele">
            <TextField
              error={
                props.formik.errors.mdyearOfPassing &&
                props.formik.touched.mdyearOfPassing
              }
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onClick={() => { }}
              type="text"
              name="mdyearOfPassing"
              id="mdyearOfPassing"
              inputProps={{
                className: cx(
                  `!text-xs open-sans-regular boxedInput popins-medium`
                ),
                style: {
                  padding: "9.5px 14px",
                  height: "0.43em !important",
                  fontSize: "12px",
                  fontFamily: "Poppins-Medium",
                  color: "#525252",
                },
              }}
              autoComplete="off"
              placeholder="Enter your passing year"
              onBlur={props.formik.handleBlur}
              onChange={props.formik.handleChange}
              value={props.formik.values.mdyearOfPassing}
            />
          </div>
          {props.formik.errors.mdyearOfPassing &&
            props.formik.touched.mdyearOfPassing ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.mdyearOfPassing}
            </div>
          ) : null}
        </div>
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Course Type</span>
          </div>
          <div className="input-ele">
            <Select
              options={props.dropDownData.mdCourseType}
              onChange={(data) => {
                props.handleDropdownChange([data], "mdCourseType");
              }}
              placeholder="Select the course type"
              value={props.selectedDropdownData.mdCourseType}
            />
          </div>
          {props.formik.errors?.mdCourseType &&
            props.formik.touched?.mdCourseType ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.mdCourseType}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MastersDegree;
