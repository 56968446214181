import React, { useEffect, useRef, useState } from "react";
import { useTable, useGlobalFilter } from "react-table";
import { ReactComponent as VideoIcon } from "../../../assets/video.svg";
import "./AdminCandidateTable.scss";
import { Switch } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { ReactComponent as NoDataFound } from "../../../assets/noDataFound.svg";

const AdminCandidateTable = (props) => {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "orange",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleSwitchChange = (id, checked) => {
    // Update the state or perform any desired action
    console.log(`Switch with ID ${id} changed to ${checked}`);
  };
  let [candidateData, setCandidateData] = useState([]);
  const cardSearchInput = useRef(null);
  let columns = React.useMemo(() => {
    const baseColumns = [
      {
        Header: "Profile",
        accessor: "name",
        width: "25%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
            }}
            className="name"
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "Skills",
        accessor: "skills",
        width: "20%",
        Cell: (e) => (
          <div
            className="datatrimmer"
            title={
              e?.value && e?.value.map((skill) => skill?.skillName).join(", ")
            }
          >
            {e?.value ? (
              e.value.length > 2
                ? e.value.slice(0, 2).map((skill, index) =>
                    `${skill?.skillName}${index !== 2 ? ", " : ""}`
                  )
                : e.value.map((skill, index) =>
                    `${skill?.skillName}${index !== e.value.length - 1 ? ", " : ""}`
                  )
            ) : (
              "--"
            )}
            {e?.value?.length > 2 && "..."}
          </div>
        ),
      },
      
      {
        Header: "Location",
        accessor: "location",
        width: "20%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
            }}
            className=""
          >
            {e.value? e.value:"--"}
          </div>
        ),
      },
      {
        Header: "Highest Degree",
        accessor: "highestDegree",
        width: "20%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
            }}
            className="datatrimmer"
          >
            {e?.value ? (
              e.value.length > 22
                ? e.value.substring(0, 22) + "..."
                : e.value
            ) : (
              "--"
            )}
          </div>
        ),
      },
      
    ];

    if (props.showToggle) {
      baseColumns.push({
        Header: "",
        accessor: "actionsss",
        width: "15%",
        Cell: ({ row, value: e }) => (
          // <div className="flex">
          //  <Typography className="mx-2">Publish</Typography>
          //   <IOSSwitch
          //     checked={row.original.active}
          //     onChange={(event) => {
          //       handleSwitchChange(row.original.id, event.target.checked, e);
          //       console.log(props,row);
          //     }}
          //   />

          // </div>
          <div class="toggle-button-cover">
            <div class="button-cover">
              <div class="button r" id="button-1">
                <input
                  type="checkbox"
                  class="checkbox"
                  checked={row.original.active}
                  onChange={(event) => {
                    handleSwitchChange(
                      row.original.id,
                      event.target.checked,
                      e
                    );
                    console.log(props, row);
                  }}
                />
                <div class="knobs"></div>
                <div class="layer"></div>
              </div>
            </div>
          </div>
        ),
      });
    } else if (props.allProfileTab) {
      baseColumns.push({
        Header: "Status",
        accessor: "profileUpdated",
        width: "15%",
        Cell: ({ row, value: e }) => (
          <div
            className="flex"
            onClick={() => {
              console.log(e);
            }}
          >
            {e ? (
              <p className="text-green-600 font-bold relative top-2">
                Profile Completed
              </p>
            ) : (
              <p className="text-red-500 font-extrabold relative top-2">
                Profile Pending
              </p>
            )}
          </div>
        ),
      });
    } else {
      baseColumns.push({
        Header: "",
        accessor: "action",
        width: "15%",
        Cell: ({ row, value: e }) => <div className="">{e}</div>,
      });
    }

    return baseColumns;
  }, [props.showToggle]);

  let actionele = (data) => {
    return (
      <div className="hire-now-btn">
        {!props.hideBtn && (
          <button
            className="datatrimmer"
            //  onClick={() => {props.handleCandidateBtn(props.btnName?props.btnName:"Hire Now",data)}}
          >
            {props.btnName ? props.btnName : "Hire Now"}
          </button>
        )}
        {props.hideBtn && props.candidateStatus ? props.candidateStatus : null}
      </div>
    );
  };
  let data = React.useMemo(() => candidateData, [candidateData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  let handelSearch = (e) => {
    setGlobalFilter(e.target.value);
  };
  useEffect(() => {
    let data = [];
    if (props.tableSearch && cardSearchInput.current.value) {
      cardSearchInput.current.value = "";
    }
    props.candidatesData.map((candidate) => {
      data.push({ ...candidate, action: actionele(candidate) });
    });
    setCandidateData([...data]);
  }, [props.candidatesData]);

  return (
    <div
      className="admin-candidate-table overflow-y-auto"
      style={{ height: "100%" }}
      onScroll={(e) => {
        if (props.handleScroll) {
          props.handleScroll(e);
        }
      }}
    >
      {props.tableSearch && (
        <input
          className="table-search-input"
          onChange={handelSearch}
          type="text"
          ref={cardSearchInput}
          placeholder="Search"
        />
      )}
      <table {...getTableProps()} className=" overflow-x-auto">
        <thead className="text-left  h-10">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  width={column.width}
                  {...column.getHeaderProps()}
                  className={` reportTableheader popins-semi-bold text-lg font-semibold ${
                    column.Header !== "Action" ? "text-left " : "text-center"
                  }
                    ${column.Header === "Profile" ? "profileHeader" : ""}`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {data.length === 0 ? (
          <>
          <div className="noDataCompany">
                <div className="no-data-availableIcon">
                  <NoDataFound />
                </div>
                <div className="no-data-availableText">
                  <p>Oops !!</p> <p>There are no data to be found yet</p>
                </div>
              </div>
              </>
         
        ) : (
          <tbody
            {...getTableBodyProps()}
            // className="border-t-2"
            //   onScroll={handlescroll}
          >
            {rows.length > 0 ? (
              <>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className={``} id="defectlog">
                      {row.cells.map((cell) => {
                        return (
                          <td
                            width={cell?.column?.width}
                            {...cell.getCellProps()}
                            className={`datatrimmer ${cell?.column?.Header?.toLowerCase()}`}
                          >
                            <div className="dataContainer flex items-center">
                              {cell?.column?.Header === "Profile" && (
                                <span className="profile-icon">
                                  {cell?.row?.original.name.slice(0, 2)}
                                </span>
                              )}
                              <div>
                                {cell.render("Cell")}
                                {/* {cell?.column?.Header === "Profile" && (
                                  <div className="rating">
                                    Rating {cell?.row?.original?.rating}
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
              <div className="noDataCompany">
                <div className="no-data-availableIcon">
                  <NoDataFound />
                </div>
                <div className="no-data-availableText">
                  <p>Oops !!</p> <p>There are no data to be found yet</p>
                </div>
              </div>
              </>
            )}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default AdminCandidateTable;
