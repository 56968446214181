import React from 'react'
import './InfoPage.scss'
import NavigationHeader from '../nav-section/NavigationHeader'

function InfoPage() {
    return (
        <div className='infoPage'>
            {/* <div className="navbarSection-aboutUs">
        <NavigationHeader />
      </div> */}
            <div className=' relative top-0  mt-40'>
                <p className='infoHead'>About Us</p>
                <p className='infoSubHead'>Your one-stop talent refinery</p>

            </div>
            <div className='infosubHead_infoPage'>Find exceptional talent, technical or non-technical, with our streamlined recruitment process. We optimize your search, saving time and cost with assured quality. Our analytics, daily active interviews, and top-rated interviewers give you access to the best. Build your dream team, globally, effortlessly. Recruit smarter, recruit AcrossGeo . </div>
            <div style={{ maxWidth: "100%" }} className=' row flex justify-content-center your-classPadding'>
                <div className='col-sm-12 col-md-3 sideImg justify-center'>
                </div>
                <div className='col-sm-12 col-md-5 midImage justify-center'>
                </div>
                <div className='col-sm-12 col-md-3 sideImg justify-center'>
                </div>
            </div>
        </div>
    )
}

export default InfoPage