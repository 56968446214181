import React from "react";
import "./FooterSection.scss";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import youtubeIcon from "../../../assets/youtube1.svg";
import fBIcon from "../../../assets/fcebook1.svg";
import instagramIcon from "../../../assets/Instagram1.svg";
import linkedinIcon from "../../../assets/linkedin1.svg";
import twitterIcon from "../../../assets/twitter1.svg";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { Link, useNavigate } from "react-router-dom";

const FooterSection = () => {
  const navigate = useNavigate();
  const footerData = {
    companyLinks: [
      {
        title: "About Us",
        url: "/about-us",
      },
      {
        title: "News",
        url: "/news",
      },
      {
        title: "Partners",
        url: "/partners",
      },
      {
        title: "Statistics",
        url: "/statistics",
      },
    ],
    resourcesLinks: [
      {
        title: "Case Study",
        url: "/case-study",
      },
      {
        title: "E-books",
        url: "/e-books",
      },
      {
        title: "Blogs",
        url: "/blogs",
      },
      {
        title: "Events",
        url: "/events",
      },
    ],
    serviceSupportLinks: [
      {
        title: "Training",
        url: "/training",
      },
      {
        title: "Learning",
        url: "/learning",
      },
      {
        title: "Hiring",
        url: "/hiring",
      },
      {
        title: "Hackathons",
        url: "/hackathons",
      },
      {
        title: "Events",
        url: "/events",
      },
      {
        title: "Customer Support",
        url: "/customerSupport",
      },
    ],
    contactLinks: [
      {
        title: "Demos",
        url: "/demos",
      },
      {
        title: "Branches",
        url: "/branches",
      },
      {
        title: "Units",
        url: "/units",
      },
    ],
  };

  return (
    <div className="footerContainer">
      <Slide left cascade delay={0} duration={1000}>
        <div className="row">
          <div className="col">
            <h5>Company</h5>
            <ul>
              {/* {footerData.companyLinks.map((link, index) => (
              <li key={index}>
              <a href={link.url}> {link.title}</a> 
              </li>
            ))} */}
              4th floor, IndiQube South Mile building, Vijayarangam Layout,
              Jayanagar, Bengaluru, Karnataka, 560070, India.
            </ul>
          </div>
          <div className="col cursor-pointer ">
            <p className="footerTags ml-8">Job Seeker</p>
            <br />

            <p
              className="subFooterTags ml-8"
              onClick={() => {
                navigate("/register/JobSeekerRegister");
              }}
            >
              Post a Profile
            </p>

            {/* <ul>
            {footerData.resourcesLinks.map((link, index) => (
              <li key={index}>
                <a href={link.url}>{link.title}</a>
              </li>
            ))}
          </ul> */}
          </div>
          <div className="col cursor-pointer">
            <p className="footerTags">Employer</p>
            <br />

            <p
              className="subFooterTags "
              onClick={() => {
                navigate("/register/EmployerRegister");
              }}
            >
              Post a Job
            </p>
            {/* <ul>
            {footerData.serviceSupportLinks.map((link, index) => (
              <li key={index}>
                <a href={link.url}>{link.title}</a>
              </li>
            ))}
          </ul> */}
          </div>
          <div className="col">
            <h5>Contact Us</h5>
            <ul>
              {/* {footerData.contactLinks.map((link, index) => (
              <li key={index}>
                <a href={link.url}>{link.title}</a>
              </li>
            ))} */}
              <li className="flex">
                <span>
                  <PhoneIcon />
                </span>
                <label className="mx-2">+91- 8296776506</label>
              </li>
              <li className="flex my-2">
                <span>
                  <EmailIcon />
                </span>
                <label className="mx-2">info@acrossgeo.com</label>
              </li>
            </ul>
          </div>
          <div className="col">
            <h5>Follow Us</h5>
            <ul className="social-icons d-flex flex-wrap ">
              <li className="col-4 col-md-4 my-2">
                <img src={fBIcon} />
              </li>
              <li className="col-4 col-md-4 my-2">
                <img src={instagramIcon} />
              </li>
              <li className="col-4 col-md-4 my-2">
                <img src={linkedinIcon} />
              </li>
              <li className="col-4 col-md-4 my-2">
                <img src={twitterIcon} />
              </li>
              <li className="col-4 col-md-4 my-2">
                <img src={youtubeIcon} />
              </li>
            </ul>
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default FooterSection;
