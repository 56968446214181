import React, { memo, useEffect, useState } from "react";
import Modal from "react-modal";
import "./CandidateDetails.scss";
import WarningIcon from "@mui/icons-material/Warning";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import {
  uploadCandidateResume,
  DownloadCandidateResume,
  deleteCandidateResume,
  UpdateCandidateResume,
} from "../../../api/api-services";
import ResumePreviewModal from "../../candidate/modals/preview-resume-modal";
import CommonLoader from "../CommonLoader/commonloader";
import { ToastContainer, toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import { Input, Tooltip } from "@material-ui/core";
import CachedIcon from "@mui/icons-material/Cached";
import BackupIcon from "@mui/icons-material/Backup";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const customStylesPage = {
  overlay: {
    background: "rgba(90, 90, 90,0.5)",
  },

  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "38%",
    background: "#fbfcfd 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 11px #000000a1",
    borderRadius: "4px",
    opacity: "1",
    outline: "none",
    index: "9999",
    padding: "3px",
  },
};
const dltcustomStylesPage = {
  overlay: {
    background: "rgba(90, 90, 90,0.5)",
  },
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    // height: "33%",
    background: "#fbfcfd 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 11px #000000a1",
    // borderRadius: "4px",
    opacity: "1",
    outline: "none",
    borderTop: "4px solid #FFC600",
  },
};

const CandidatesDetailsModal = (props) => {
  let [candidateData, setCandidateData] = useState({ ...props.candidate });
  let [candidateDetails, setCandidateDetails] = useState({
    ...props.candidateDetailsData,
  });
  const [selectedFilename, setSelecteFilename] = useState("");
  const [selectedFile, setSelectedFile] = useState({});
  const [openResumeModal, setopenResumeModal] = useState(false);
  const [editResume, setEditResume] = useState(false);
  let userID = JSON.parse(localStorage.getItem("across-geo-user")).uid;
  const [resume, setResume] = useState("");
  const [selectingFile, setSelectingFile] = useState(false);
  let [preview, setPreview] = useState(false);
  let [filepath, setFilePath] = useState();
  let [uploadingResume, setUploadingResume] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [largeSize, setLargeSize] = useState(false);
  const [personalDetailsExpand, setPersonalDetailsExpand] = useState(true);
  const [technicalDetailsExpand, setTechnicalDetailsExpand] = useState(false);
  const [educationDetailsExpand, setEducationDetailsExpand] = useState(false);
  const [workDetailsExpand, setWorkDetailsExpand] = useState(false);
  const [currentAddressExpand, setCurrentAddressExpand] = useState(false);
  const [permanentAddressExpand, setPermanentAddressExpand] = useState(false);
  const handleOpen = () => {
    setopenResumeModal(true);
    setLargeSize(false);
  };
  const handleClose = () => {
    setLargeSize(false);
    setopenResumeModal(false);
    if (!candidateDetails.resume) {
      setResume("");
    }
    if (candidateDetails.resume && resume != candidateDetails.resume) {
      setResume(candidateDetails?.resume);
    }
  };
  let getSkillsdata = (data) => {
    let string = "";
    data.map((obj, index) => {
      if (index === 0) {
        string = obj.skillName;
      } else {
        string = string + "," + obj.skillName;
      }
    });
    return string;
  };
  useEffect(() => {
    setCandidateDetails({
      ...props.candidateDetailsData,
    });
    setCandidateData({
      ...props.candidateDetailsData.personalDetails,
    });
    setResume(props?.candidateDetailsData?.resume);
  }, [props.candidateDetailsData]);
  const fileHandler = (e) => {
    let fileObj = e.target.files[0];
    let fileName = fileObj.name;
    setSelectingFile(true);
    setSelectedFile(fileObj);
    setSelecteFilename(fileName);
    setResume(userID + "/" + fileName);
    e.target.value = "";
    checkFileSize(fileObj);
  };

  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }
  const downloadResume = async () => {
    let filename = candidateDetails.resume;
    try {
      let response = await DownloadCandidateResume(filename);
      if (response.data.response !== "") {
        saveFileFromBlob(response?.data, filename);
      }
    } catch (error) {
      console.log("error");
    }
  };
  const UploadResume = async () => {
    const formdata = new FormData();
    formdata.append("file", selectedFile);
    if (selectingFile) {
      setUploadingResume(true);
      if (candidateDetails.resume) {
        try {
          let response = await UpdateCandidateResume(userID, formdata);
          if (response?.data?.statusCode === 200) {
            handleClose();
            toast.success(
              <>
                <div className="toast-heading">Success</div>
                <div>{"Resume Uploaded successfully"}</div>
              </>
            );
            setTimeout(() => {
              window.location.reload();
            }, 2000);

            props?.getCandidateDetails();
          } else if (response) {
            toast.info(
              <>
                <div className="toast-heading">Info</div>
                <div>{"File is too large choose <5MB"}</div>
              </>
            );
          } else {
            console.log("UploadResume", response);
          }
        } catch (error) {
          toast.info(
            <>
              <div className="toast-heading">Info</div>
              <div>{"File is too large choose <5MB"}</div>
            </>
          );
          console.log("error");
        }
      } else {
        try {
          let response = await uploadCandidateResume(userID, formdata);
          if (response?.data?.statusCode === 200) {
            handleClose();
            toast.success(
              <>
                <div className="toast-heading">Success</div>
                <div>{"Resume uploaded successfully"}</div>
              </>
            );
            setTimeout(() => {
              window.location.reload();
            }, 5000);
            props?.getCandidateDetails();
          } else if (response?.data?.statusCode === 413) {
            toast.info(
              <>
                <div className="toast-heading">Info</div>
                <div>{"File is too large choose <5MB"}</div>
              </>
            );
          }
        } catch (error) {
          console.log("error");
          toast.info(
            <>
              <div className="toast-heading">Info</div>
              <div>{"File is too large choose <5MB"}</div>
            </>
          );
        }
      }
    }
    setUploadingResume(false);
  };
  const checkFileSize = (e) => {
    const fileLeng = e.size;
    if (fileLeng <= 5000000) {
      setLargeSize(false);
    } else {
      setLargeSize(true);
    }
  };
  const DeleteResume = async () => {
    setDeletePopUp(false);
    let filename = candidateDetails.resume;
    try {
      let response = await deleteCandidateResume(userID, filename);
      if (response?.data?.statusCode === 200) {
        toast.success(
          <>
            <div className="toast-heading">Success</div>
            <div>{"Resume Deleted successfully"}</div>
          </>
        );
        setTimeout(() => {
          window.location.reload();
        }, 4000);

        setSelecteFilename("");

        props?.getCandidateDetails();
      } else {
        console.log("UploadResume", response);
      }
    } catch (error) {
      console.log("error");
    }
  };
  function previewFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var blob = new Blob([blob], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      setFilePath(blobURL);
      setPreview(true);
      // window.open(blobURL);
    }
  }

  const FilePreview = async () => {
    let filename = candidateDetails.resume;
    let compleateFile = candidateDetails.resume.split("/");
    let lastInd = compleateFile.length-1
    const fileID = compleateFile[0];
    const fileName = compleateFile[lastInd];
    try {
      let response = await DownloadCandidateResume(fileID, fileName);
      if (response.data.response !== "") {
        previewFileFromBlob(response?.data, filename);
      }
    } catch (error) {
      console.log("error");
    }
  };
  function closePreviewModal(state) {
    setPreview(state);
  }
  return (
    <div className="px-3 py-2" style={{height:"100%"}}>
    <div className="flex items-center profile-title-container">
        <span className="profile-icon">{candidateData?.name?.slice(0, 2)}</span>
        <span className="profile-title">
          <div className="name">{candidateData?.name}</div>
          {/* <div className="rating">Rating {candidateData.rating}</div> */}
        </span>
        <div className="edit-btn ml-auto">
          <span className="mr-1">
            {candidateDetails.resume ? (
              <span>
                <Tooltip title="Replace Resume">
                  <CachedIcon
                    color="#ef7f09"
                    onClick={() => {
                      setopenResumeModal(true);
                      setEditResume(true);
                    }}
                  ></CachedIcon>
                </Tooltip>
              </span>
            ) : props.resumeBtn ? (
              <button
                onClick={() => {
                  handleOpen();
                }}
              >
                Upload Resume
              </button>
            ) : (
              ""
            )}
            <span title={candidateDetails.resume ? candidateDetails.resume.split("/")[2]:"hello"} className="resumeText" onClick={FilePreview}>
              {candidateDetails.resume
                ? candidateDetails.resume.split("/")[2].length > 22 ? candidateDetails.resume.split("/")[2].substring(0, 22) + "..." :candidateDetails.resume.split("/")[2]
                : ""}
            </span>{" "}
            {candidateDetails.resume && (
              <Tooltip title="Delete resume">
                <span>
                  <DeleteForeverIcon
                    onClick={() => setDeletePopUp(true)}
                    fontSize="small"
                    color="error"
                  ></DeleteForeverIcon>
                </span>
              </Tooltip>
            )}
          </span>
          {props.editBtn && (
            <span>
              <button
                onClick={() => {
                  props.handleEdit();
                }}
              >
                Edit Profile
              </button>
            </span>
          )}
        </div>
      </div>
    <div className="profile-details-container mt-2">
      <div className="profile-additional-details p-3 mb-2">
        <div className="details-set px-3 py-2">
          <div>
            <div className="details-header">Personal Details</div>
            <div className="float-right">
            {
              personalDetailsExpand ? <ExpandLessIcon onClick={()=>{setPersonalDetailsExpand(false)}}/> : <ExpandMoreIcon onClick={()=>{setPersonalDetailsExpand(true)}}/>
            }
            </div>
          </div>
          
          {personalDetailsExpand && <div className="data-body grid grid-cols-3 p-3">
            <div className="col-span-1 data-set">
              <div className="data-label">Phone Number</div>
              <div className="each-data datatrimmer">
                {candidateDetails?.personalDetails?.mobile
                  ? candidateDetails?.personalDetails?.mobile
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Email</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.email
                    ? candidateDetails?.personalDetails?.email
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.email
                  ? candidateDetails?.personalDetails?.email
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">DOB</div>
              <div className="each-data datatrimmer">
                {candidateDetails?.personalDetails?.dob
                  ? candidateDetails.personalDetails.dob
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Gender</div>
              <div className="each-data datatrimmer">
                {candidateDetails?.personalDetails?.gender
                  ? candidateDetails.personalDetails.gender
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Country</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.permanentAddress?.country
                    ? candidateDetails.personalDetails?.permanentAddress
                        ?.country
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.permanentAddress?.country
                  ? candidateDetails.personalDetails?.permanentAddress?.country
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">State</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.permanentAddress?.state
                    ? candidateDetails.personalDetails?.permanentAddress?.state
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.permanentAddress?.state
                  ? candidateDetails.personalDetails?.permanentAddress?.state
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">City</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.permanentAddress?.city
                    ? candidateDetails.personalDetails?.permanentAddress?.city
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.permanentAddress?.city
                  ? candidateDetails.personalDetails?.permanentAddress?.city
                  : "--"}
              </div>
            </div>
          </div>}
        </div>
        <div className="details-set px-3 py-2 mt-3">
          <div>
            <div className="details-header">Current Address</div>
            <div className="float-right	">
            {
              currentAddressExpand ? <ExpandLessIcon onClick={()=>{setCurrentAddressExpand(false)}}/> : <ExpandMoreIcon onClick={()=>{setCurrentAddressExpand(true)}}/>
            }
            </div>
          </div>
          {currentAddressExpand && <div className="data-body grid grid-cols-4 p-3">
            <div className="col-span-1 data-set">
              <div className="data-label">Country</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.currentAddress.country
                    ? candidateDetails?.personalDetails?.currentAddress.country
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.currentAddress.country
                  ? candidateDetails?.personalDetails?.currentAddress.country
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">State</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.currentAddress.state
                    ? candidateDetails?.personalDetails?.currentAddress.state
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.currentAddress.state
                  ? candidateDetails?.personalDetails?.currentAddress.state
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">City</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.currentAddress.city
                    ? candidateDetails.personalDetails.currentAddress.city
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.currentAddress.city
                  ? candidateDetails.personalDetails.currentAddress.city
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Pincode</div>
              <div className="each-data datatrimmer">
                {candidateDetails?.personalDetails?.currentAddress.pinCode
                  ? candidateDetails.personalDetails.currentAddress.pinCode
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Address</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.currentAddress?.description
                    ? candidateDetails.personalDetails?.currentAddress
                        ?.description
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.currentAddress?.description
                  ? candidateDetails.personalDetails?.currentAddress
                      ?.description
                  : "--"}
              </div>
            </div>
          </div>}
        </div>
        <div className="details-set px-3 py-2 mt-3">
          <div>
            <div className="details-header">Permanent Address</div>
            <div className="float-right	">
            {
              permanentAddressExpand ? <ExpandLessIcon onClick={()=>{setPermanentAddressExpand(false)}}/> : <ExpandMoreIcon onClick={()=>{setPermanentAddressExpand(true)}}/>
            }
            </div>
          </div>
          {permanentAddressExpand && <div className="data-body grid grid-cols-4 p-3">
            <div className="col-span-1 data-set">
              <div className="data-label">Country</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.permanentAddress.country
                    ? candidateDetails?.personalDetails?.permanentAddress
                        .country
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.permanentAddress.country
                  ? candidateDetails?.personalDetails?.permanentAddress.country
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">State</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.permanentAddress.state
                    ? candidateDetails?.personalDetails?.permanentAddress.state
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.permanentAddress.state
                  ? candidateDetails?.personalDetails?.permanentAddress.state
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">City</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.permanentAddress.city
                    ? candidateDetails.personalDetails.permanentAddress.city
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.permanentAddress.city
                  ? candidateDetails.personalDetails.permanentAddress.city
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Pincode</div>
              <div className="each-data datatrimmer">
                {candidateDetails?.personalDetails?.permanentAddress.pinCode
                  ? candidateDetails.personalDetails.permanentAddress.pinCode
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Address</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.permanentAddress
                    ?.description
                    ? candidateDetails.personalDetails?.permanentAddress
                        ?.description
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.permanentAddress
                  ?.description
                  ? candidateDetails.personalDetails?.permanentAddress
                      ?.description
                  : "--"}
              </div>
            </div>
          </div>}
        </div>
        <div className="details-set px-3 py-2 mt-3">
          <div>
            <div className="details-header">Technical Details</div>
            <div className="float-right	">
            {
              technicalDetailsExpand ? <ExpandLessIcon onClick={()=>{setTechnicalDetailsExpand(false)}}/> : <ExpandMoreIcon onClick={()=>{setTechnicalDetailsExpand(true)}}/>
            }
            </div>
          </div>
          {technicalDetailsExpand && <div className="data-body grid grid-cols-3 p-3">
            <div className="col-span-1 data-set">
              <div className="data-label">Domain</div>
              <div title={candidateDetails.technicalDetails.domain} className="each-data datatrimmer">
                {candidateDetails?.technicalDetails?.domain
                  ? candidateDetails.technicalDetails.domain
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Job Role</div>
              <div className="each-data datatrimmer">
                {candidateDetails?.technicalDetails?.jobTitle
                  ? candidateDetails.technicalDetails.jobTitle
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="dalabel">Skills</div>
              {candidateDetails?.technicalDetails?.skills?.length > 0 ? (
                <div
                  className="each-data datatrimmer"
                  title={getSkillsdata(
                    candidateDetails.technicalDetails.skills
                  )}
                >
                  {getSkillsdata(candidateDetails.technicalDetails.skills)}
                </div>
              ) : (
                <div>--</div>
              )}
            </div>
          </div>}
        </div>
        <div className="details-set px-3 py-2 mt-3">
          <div>
            <div className="details-header">Education Details</div>
            <div className="float-right	">
            {
              educationDetailsExpand ? <ExpandLessIcon onClick={()=>{setEducationDetailsExpand(false)}}/> : <ExpandMoreIcon onClick={()=>{setEducationDetailsExpand(true)}}/>
            }
            </div>
          </div>
          {educationDetailsExpand && <div className="acrobat-data-body ">
            {candidateDetails?.educationDetails?.masterDegree &&
              candidateDetails?.educationDetails?.masterDegree?.collegeName !==
                null && (
                <div className="acrobat-container flex items-stretch">
                  <div className="timeline-dot"></div>
                  <div className="education-name self-stretch ">
                    {" "}
                    Masters Degree
                  </div>
                  <div className="timeline-content grid grid-cols-3">
                    <div className="col-span-1 data-set-responsive">
                      <div className="data-label">University</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.university
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.university
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.university
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.university
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set-responsive">
                      <div className="data-label">College Name</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.collegeName
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.collegeName
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.collegeName
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.collegeName
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set-responsive">
                      <div className="data-label">Course</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.title
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.title
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.masterDegree?.title
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.title
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set-responsive">
                      <div className="data-label">Specialization</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.stream
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.stream
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.stream
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.stream
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">{`${
                        candidateDetails?.educationDetails?.masterDegree
                          ?.percentage > 0
                          ? "Percentage"
                          : "CGPA"
                      }`}</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.percentage > 0
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.percentage
                          : candidateDetails.educationDetails?.masterDegree
                              ?.cgpa > 0
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.cgpa
                          : ""}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">College Country</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.address?.country
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.address?.country
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.address?.country
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.address?.country
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">College State</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.address?.state
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.address?.state
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.address?.state
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.address?.state
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">College City</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.address?.city
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.address?.city
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.address?.city
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.address?.city
                          : "--"}
                      </div>
                    </div>

                    <div className="col-span-1 data-set">
                      <div className="data-label">Year of Joining</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.yearOfJoining
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.yearOfJoining
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">Year of Passing</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.yearOfPassing
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.yearOfPassing
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">Course Type</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.courseType
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.courseType
                          : "--"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {candidateDetails?.educationDetails?.degree &&
              candidateDetails?.educationDetails?.degree.collegeName !==
                null && (
                <div className="acrobat-container flex items-stretch">
                  <div className="timeline-dot"></div>
                  <div className="education-name self-stretch "> Degree</div>
                  <div className="timeline-content grid grid-cols-3">
                    <div className="col-span-1 data-set-responsive">
                      <div className="data-label">University</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.degree?.university
                            ? candidateDetails.educationDetails?.degree
                                ?.university
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.degree?.university
                          ? candidateDetails.educationDetails?.degree
                              ?.university
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set-responsive">
                      <div className="data-label">College Name</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.degree
                            ?.collegeName
                            ? candidateDetails.educationDetails?.degree
                                ?.collegeName
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.degree?.collegeName
                          ? candidateDetails.educationDetails?.degree
                              ?.collegeName
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set-responsive">
                      <div className="data-label">Course</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.degree?.title
                            ? candidateDetails.educationDetails?.degree?.title
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.degree?.title
                          ? candidateDetails.educationDetails?.degree?.title
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set-responsive">
                      <div className="data-label">Specialization</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.degree?.stream
                            ? candidateDetails.educationDetails?.degree?.stream
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.degree?.stream
                          ? candidateDetails.educationDetails?.degree?.stream
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">{`${
                        candidateDetails?.educationDetails?.degree?.percentage >
                        0
                          ? "Percentage"
                          : "CGPA"
                      }`}</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.degree
                          ?.percentage > 0
                          ? candidateDetails.educationDetails?.degree
                              ?.percentage
                          : candidateDetails.educationDetails?.degree?.cgpa > 0
                          ? candidateDetails.educationDetails?.degree?.cgpa
                          : ""}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">College Country</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.degree?.address
                            ?.country
                            ? candidateDetails.educationDetails?.degree?.address
                                ?.country
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.degree?.address
                          ?.country
                          ? candidateDetails.educationDetails?.degree?.address
                              ?.country
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">College State</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.degree?.address
                            ?.state
                            ? candidateDetails.educationDetails?.degree?.address
                                ?.state
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.degree?.address
                          ?.state
                          ? candidateDetails.educationDetails?.degree?.address
                              ?.state
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">College City</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.degree?.address
                            ?.city
                            ? candidateDetails.educationDetails?.degree?.address
                                ?.city
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.degree?.address
                          ?.city
                          ? candidateDetails.educationDetails?.degree?.address
                              ?.city
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">Year of Joining</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.degree
                          ?.yearOfJoining
                          ? candidateDetails.educationDetails?.degree
                              ?.yearOfJoining
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">Year of Passing</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.degree
                          ?.yearOfPassing
                          ? candidateDetails.educationDetails?.degree
                              ?.yearOfPassing
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">Course Type</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.degree?.courseType
                          ? candidateDetails.educationDetails?.degree
                              ?.courseType
                          : "--"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {candidateDetails?.educationDetails?.diploma && (
              <div className="acrobat-container flex items-stretch">
                <div className="timeline-dot"></div>
                <div className="education-name self-stretch "> Diploma</div>
                <div className="timeline-content grid grid-cols-3">
                  <div className="col-span-1 data-set">
                    <div className="data-label">College Name</div>
                    <div
                      className="each-data datatrimmer"
                      title={
                        candidateDetails?.educationDetails?.diploma?.collegeName
                          ? candidateDetails.educationDetails?.diploma
                              ?.collegeName
                          : ""
                      }
                    >
                      {candidateDetails?.educationDetails?.diploma?.collegeName
                        ? candidateDetails.educationDetails?.diploma
                            ?.collegeName
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Board Name</div>
                    <div
                      className="each-data datatrimmer"
                      title={
                        candidateDetails?.educationDetails?.diploma?.boardName
                          ? candidateDetails.educationDetails?.diploma
                              ?.boardName
                          : ""
                      }
                    >
                      {candidateDetails?.educationDetails?.diploma?.boardName
                        ? candidateDetails.educationDetails?.diploma?.boardName
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Specialization</div>
                    <div
                      className="each-data datatrimmer"
                      title={
                        candidateDetails?.educationDetails?.diploma?.stream
                          ? candidateDetails.educationDetails?.diploma?.stream
                          : ""
                      }
                    >
                      {candidateDetails?.educationDetails?.diploma?.stream
                        ? candidateDetails.educationDetails?.diploma?.stream
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">{`${
                      candidateDetails?.educationDetails?.diploma?.percentage >
                      0
                        ? "Percentage"
                        : "CGPA"
                    }`}</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.diploma?.percentage >
                      0
                        ? candidateDetails.educationDetails?.diploma?.percentage
                        : candidateDetails.educationDetails?.diploma?.cgpa > 0
                        ? candidateDetails.educationDetails?.diploma?.cgpa
                        : ""}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Joining</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.diploma
                        ?.yearOfJoining
                        ? candidateDetails.educationDetails?.diploma
                            ?.yearOfJoining
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Passing</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.diploma
                        ?.yearOfPassing
                        ? candidateDetails.educationDetails?.diploma
                            ?.yearOfPassing
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Course Type</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.diploma?.courseType
                        ? candidateDetails.educationDetails?.diploma?.courseType
                        : "--"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {candidateDetails?.educationDetails?.twelfth && (
              <div className="acrobat-container flex items-stretch">
                <div className="timeline-dot"></div>
                <div className="education-name self-stretch "> PUC</div>
                <div className="timeline-content grid grid-cols-3">
                  <div className="col-span-1 data-set">
                    <div className="data-label">{`${
                      candidateDetails?.educationDetails?.twelfth?.percentage >
                      0
                        ? "Percentage"
                        : "CGPA"
                    }`}</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.twelfth?.percentage >
                      0
                        ? candidateDetails.educationDetails?.twelfth?.percentage
                        : candidateDetails.educationDetails?.twelfth?.cgpa > 0
                        ? candidateDetails.educationDetails?.twelfth?.cgpa
                        : ""}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Joining</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.twelfth
                        ?.yearOfJoining
                        ? candidateDetails.educationDetails?.twelfth
                            ?.yearOfJoining
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Passing</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.twelfth
                        ?.yearOfPassing
                        ? candidateDetails.educationDetails?.twelfth
                            ?.yearOfPassing
                        : "--"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {candidateDetails?.educationDetails?.iti && (
              <div className="acrobat-container flex items-stretch">
                <div className="timeline-dot"></div>
                <div className="education-name self-stretch "> ITI</div>
                <div className="timeline-content grid grid-cols-3">
                  <div className="col-span-1 data-set">
                    <div className="data-label">College Name</div>
                    <div
                      className="each-data datatrimmer"
                      title={
                        candidateDetails?.educationDetails?.iti?.collegeName
                          ? candidateDetails.educationDetails?.iti?.collegeName
                          : ""
                      }
                    >
                      {candidateDetails?.educationDetails?.iti?.collegeName
                        ? candidateDetails.educationDetails?.iti?.collegeName
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Board Name</div>
                    <div
                      className="each-data datatrimmer"
                      title={
                        candidateDetails?.educationDetails?.iti?.boardName
                          ? candidateDetails.educationDetails?.iti?.boardName
                          : ""
                      }
                    >
                      {candidateDetails?.educationDetails?.iti?.boardName
                        ? candidateDetails.educationDetails?.iti?.boardName
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Specialization</div>
                    <div
                      className="each-data datatrimmer"
                      title={
                        candidateDetails?.educationDetails?.iti?.stream
                          ? candidateDetails.educationDetails?.iti?.stream
                          : ""
                      }
                    >
                      {candidateDetails?.educationDetails?.iti?.stream
                        ? candidateDetails.educationDetails?.iti?.stream
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">{`${
                      candidateDetails?.educationDetails?.iti?.percentage > 0
                        ? "Percentage"
                        : "CGPA"
                    }`}</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.iti?.percentage > 0
                        ? candidateDetails.educationDetails?.iti?.percentage
                        : candidateDetails.educationDetails?.iti?.cgpa > 0
                        ? candidateDetails.educationDetails?.iti?.cgpa
                        : ""}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Joining</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.iti?.yearOfJoining
                        ? candidateDetails.educationDetails?.iti?.yearOfJoining
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Passing</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.iti?.yearOfPassing
                        ? candidateDetails.educationDetails?.iti?.yearOfPassing
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Course Type</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.iti?.courseType
                        ? candidateDetails.educationDetails?.iti?.courseType
                        : "--"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {candidateDetails?.educationDetails?.sslc && (
              <div className="acrobat-container flex items-stretch">
                <div className="timeline-dot"></div>
                <div className="education-name self-stretch ">
                  {" "}
                  SSLC {`(10th)`}
                </div>
                <div className="timeline-content grid grid-cols-3">
                  <div className="col-span-1 data-set">
                    <div className="data-label">{`${
                      candidateDetails?.educationDetails?.sslc?.percentage > 0
                        ? "Percentage"
                        : "CGPA"
                    }`}</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.sslc?.percentage > 0
                        ? candidateDetails.educationDetails?.sslc?.percentage
                        : candidateDetails.educationDetails?.sslc?.cgpa > 0
                        ? candidateDetails.educationDetails?.sslc?.cgpa
                        : ""}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Joining</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.sslc?.yearOfJoining
                        ? candidateDetails.educationDetails?.sslc?.yearOfJoining
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Passing</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.sslc?.yearOfPassing
                        ? candidateDetails.educationDetails?.sslc?.yearOfPassing
                        : "--"}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>}
        </div>
        {candidateDetails?.workHistories?.length > 0 && (
          <div className="details-set px-3 py-2 mt-3">
            <div>
              <div className="details-header">Work History</div>
              <div className="float-right	">
              {
                workDetailsExpand ? <ExpandLessIcon onClick={()=>{setWorkDetailsExpand(false)}}/> : <ExpandMoreIcon onClick={()=>{setWorkDetailsExpand(true)}}/>
              }
              </div>
            </div>
            {workDetailsExpand && <div className="acrobat-data-body ">
              {candidateDetails?.workHistories?.map((cmp, index) => {
                return (
                  <div className="acrobat-work-container flex items-stretch">
                    <div className="timeline-dot"></div>
                    <div title={cmp?.company} className="education-name self-stretch ">
                      {" "}
                      {cmp?.company.length >= 20? cmp?.company.substring(0, 20)+ "...":cmp?.company }
                    </div>
                    <div className="timeline-content grid grid-cols-10">
                      <div className="col-span-3 data-set">
                        <div className="data-label">Job Title</div>
                        <div
                          className="each-data datatrimmer"
                          title={cmp?.jobTitle ? cmp?.jobTitle : ""}
                        >
                          {cmp?.jobTitle ? cmp?.jobTitle : "--"}
                        </div>
                      </div>
                      <div className="col-span-7 grid grid-cols-3">
                        <div className="col-span-1 data-set">
                          <div className="data-label">City</div>
                          <div
                            className="each-data datatrimmer"
                            title={cmp?.city ? cmp?.city : ""}
                          >
                            {cmp?.city ? cmp?.city : "--"}
                          </div>
                        </div>
                        <div className="col-span-1 data-set">
                          <div className="data-label">From</div>
                          <div className="each-data datatrimmer">
                            {cmp?.startDate ? cmp?.startDate : "--"}
                          </div>
                        </div>
                        <div className="col-span-1 data-set">
                          <div className="data-label">To</div>
                          <div className="each-data datatrimmer">
                            {cmp?.endDate ? cmp?.endDate : "--"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>}
          </div>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {preview && (
        <ResumePreviewModal path={filepath} closeModal={closePreviewModal} />
      )}
      {openResumeModal && (
        <Modal isOpen={openResumeModal} style={customStylesPage}>
          <div className="flex justify-between mapCompanymodal-heading p-0">
            <p>Upload Resume</p>
            <div>
              {/* <button
              onClick={handleClose}
              type="button"
              className="modal-close-btn relative right-24 p-3  modal-close-icon"
            >
              <HighlightOffIcon />
            </button> */}
            </div>
          </div>
          <div className="flex justify-center p-4">
            <div className="flex relative">
              <span
                style={{ display: "inline-block", marginTop: "10px" }}
                className="big-size-file"
              >
                <input
                  type="file"
                  title=""
                  accept=".pdf"
                  multiple={false}
                  onChange={(e) => {
                    fileHandler(e);
                  }}
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    opacity: 0,
                    height: "100%",
                    width: "80px",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    paddingBottom: "0px",
                  }}
                />
                <button
                  onChange={(e) => {
                    fileHandler(e);
                  }}
                  style={{
                    padding: "1px 12px",
                    border: " 1px solid #989797",
                    borderRadius: "4px",
                    color: "#727171",
                    cursor: "pointer !important",
                    fontSize: "15px",
                    fontFamily: "OpenSans-Regular",
                  }}
                >
                  <span>
                    <BackupIcon fontSize="small"></BackupIcon>
                  </span>{" "}
                  {resume ? "Replace file" : "Choose file"}
                </button>
              </span>

              <div
                style={{ display: "inline-block", marginTop: "10px" }}
                className="ml-6"
              >
                <div className="flex">
                  <div>
                    <Input
                      sx={{ borderBottom: "0px solid !important" }}
                      label="Filled"
                      placeholder="Select a file..."
                      value={resume ? resume?.split("/")[1] === "resume" ? resume?.split("/")[2] : resume?.split("/")[1]  : ""}
                    />
                    {resume && (
                      <span>
                        <Tooltip title="Clear">
                          <ClearIcon
                            onClick={() => {
                              setResume("");
                              setLargeSize(false);
                              setSelectingFile(false);
                            }}
                            fontSize="small"
                            color="error"
                          ></ClearIcon>
                        </Tooltip>
                      </span>
                    )}
                  </div>
                  {/* <span className={`mx-2 resume_icons ${candidateDetails.resume|| selectedFilename !==""?"block":"hidden"} ${selectingFile && uploadingResume===false?"opacity-100":"opacity-50"}`} title="Upload Resume" onClick={UploadResume}>
              <FileUploadIcon/>
            </span>
        
            <span className={`mx-2 resume_icons ${candidateDetails.resume?"block":"hidden"} ${uploadingResume===false?"opacity-100":"opacity-50"}`} title="Download"  onClick={() => {uploadingResume===false &&
              downloadResume()
            }}>
              <DownloadIcon/>
            </span>
            <span className={`mx-2 resume_icons ${candidateDetails.resume?"block":"hidden"} ${uploadingResume===false?"opacity-100":"opacity-50"}`} title="Delete" onClick={() => {uploadingResume===false &&
              DeleteResume();
            }}>
              <DeleteIcon/>
            </span> */}
                  {uploadingResume && (
                    <span>
                      <CommonLoader />
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          {largeSize && (
            <p
              style={{
                textAlign: "center",
                color: "red",
                paddingBottom: "0px",
                paddingTop: "0px",
              }}
            >
              please choose file less than 5MB
            </p>
          )}
          <div className="float-right">
            <div className="col-span-8"></div>
            <div className="grid grid-cols-12 col-span-4 flex justify-center">
              <div
                className="grid grid-cols-12 col-span-6 h-8 cnclBtn mr-4"
                onClick={() => handleClose()}
              >
                <span className="btn_close col-span-10 rounded-sm flex justify-center cursor-pointer">
                  <button>Cancel</button>
                </span>
              </div>
              <div
                className={`grid grid-cols-12 col-span-6 h-8 dltBtn ${
                  !resume || largeSize ? "bg-orange-200" : "bg-orange-500"
                }`}
                onClick={UploadResume}
              >
                <span
                  className={`btn_back col-span-10 rounded-sm flex justify-center cursor-pointer`}
                >
                  <button disabled={largeSize}>Upload</button>
                </span>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {deletePopUp && (
        <Modal
          isOpen={deletePopUp}
          style={dltcustomStylesPage}
          className="p-px"
        >
          <div>
            <div className="grid grid-cols-12 deleteModal">
              <div className="grid grid-cols-12 col-span-12 warning-popup py-2">
                <div className="col-span-12 warning-icon flex">
                  <div className=" relative  left-8 warningText top-2.5">
                    <span>
                      <WarningIcon color="warning" />
                    </span>{" "}
                    warning!
                  </div>
                </div>
                <div className="grid grid-cols-12 col-span-10 pl-7 my-2">
                  {/* <div className="col-span-12 py-1">
                 <span className="message">
                   Hi ,
                 </span>
               </div> */}
                  <div className="col-span-12">
                    <>
                      <span className="messageContent">
                        Are you sure! Do you want to delete the resume?
                      </span>
                    </>
                  </div>
                  {/* <div className="col-span-12">
                 <span className="message">How do you want to proceed?</span>
               </div> */}
                </div>
              </div>
            </div>
            <div className="float-right p-2">
              <div className="grid grid-cols-12 col-span-4 flex float-right">
                <div
                  className="grid grid-cols-12 col-span-6 h-8 cnclBtn mr-4"
                  onClick={() => setDeletePopUp(false)}
                >
                  <span className="btn_close col-span-10 rounded-sm flex justify-end cursor-pointer">
                    <button>Cancel</button>
                  </span>
                </div>
                <div
                  className={`grid grid-cols-12 col-span-6 h-8  dltBtn bg-orange-500 `}
                  onClick={DeleteResume}
                >
                  <span className="btn_back col-span-10 rounded-sm flex justify-center cursor-pointer">
                    <button>Delete</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
    </div>
  );
};

export default memo(CandidatesDetailsModal);
