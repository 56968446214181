import React, { useEffect, useRef, useState } from "react";
import { Checkbox } from "@material-ui/core";
import { ReactComponent as EducationIcon } from "../../../assets/education.svg";
import "./ClientInterviewCandidate.scss";

import CandidateTable from "../../common/candidate-table/CandidateTable";
import InterviewModals from "../../common/interview-modals/InterviewModals";
import CandidateColumnOption from "../../common/CandidateColumnOption";
import {
  getAdminBdCandReq,
  shortlistCandReq,
  ByStateSearch,
} from "../../../api/api-services";
import { ToastContainer, toast } from "react-toastify";

const ClientInterviewCandidate = (props) => {
  let [hireTableData, setHireTableData] = useState([]);
  let [openFilter, setOpenFilter] = useState(false);
  let [filterOptions, setFilterOptions] = useState([]);
  let [selectedFilters, setSelectedFilters] = useState({});
  let [listview, setListview] = useState(true);
  let [selectedTab, setSelectedtab] = useState("Shared Profiles");
  let [candidateCardsData, setCandidateCardsData] = useState([]);
  const cardSearchInput = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  let user = JSON.parse(localStorage.getItem("across-geo-user"));
  let [scheduleInterviewModal, setScheduleInterviewModal] = useState(false);
  let [selectCandidate, setSelectedCandidate] = useState({});
  let [isLoading, setIsLoading] = useState(false);
  let [hiddenColumnsData, setHiddenColumnsData] = useState([
    "email",
    "gender",
    "location",
    "highest_degree",
    "requested_clients",
    "shareable",
  ]);
  const [contextMenuData, setContextMenuData] = useState([]);
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let size = 10;
  let [tableApiData, setTableApiData] = useState({
    totalCount: 20,
    columnDef: {
      columns: [],
    },
    content: [],
  });
  let handelColumnsDispaly = (e) => {
    let value = e.target.value;
    let data = [...hiddenColumnsData];
    if (data.includes(value) && !e.target.checked) {
      data.splice(data.indexOf(value), 1);
    } else if (e.target.checked) {
      data.push(value);
    }
    setHiddenColumnsData([...data]);
  };

  const handleFilterSelection = (e, type, value) => {
    let selectedData = { ...selectedFilters };
    if (e.target.checked) {
      if (selectedData?.[type]?.length > 0) {
        selectedData?.[type].push(value);
      } else {
        selectedData[type] = [value];
      }
    } else {
      if (selectedData?.[type]?.length > 0) {
        let index = selectedData?.[type].indexOf(value);
        selectedData?.[type].splice(index, 1);
      }
    }
    setSelectedFilters({ ...selectedData });
  };

  let handleCandidateBtn = async (data) => {
    let payload = {};
    let response;
    if (selectedTab === "Shared Profiles") {
      payload.candidateID = data.id;
      payload.jobID = props?.job?.id;
      payload.bdID = user.uid;
      try {
        response = await shortlistCandReq(payload);
        if (response.data) {
          toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>Candidate shortlisted successfully</div>
            </>
          );
          gettableData(selectedTab, 0);
        }
      } catch (err) {
        console.error(err, "share api error");
      }
    } else if (selectedTab === "Scheduled Interviews") {
      setSelectedCandidate({ ...data });
      setScheduleInterviewModal(true);
    }
  };

  // const handlescroll = (event) => {
  //   if (
  //     Math.ceil(event?.target?.scrollTop + event?.target?.clientHeight) ===
  //     event?.target?.scrollHeight
  //   ) {
  //     if (lastPage === false) {
  //       gettableData(selectedTab, pageno);
  //     }
  //   }
  // };
  const resetSearch = () => {
    setIsSearching(false);
    setpageNo(0);
    setLastpage(false);
  };
  let gettableDataSearched = async (e, tab, page) => {
    let payload = {
      pageNo: page,
      pageSize: size,
      jobPostId: props.job.id,
    };
    let response;
    if (tab === "Shared Profiles") {
      try {
        payload.candidateState = "SHARED";
        response = await ByStateSearch(e, payload);
        setIsSearching(true); // Set the flag to indicate search
        setIsLoading(true);
        setSearchedText(e);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    } else if (tab === "Shortlisted Profiles") {
      try {
        payload.candidateState = "SHORTLISTED";
        response = await ByStateSearch(e, payload);
        setIsSearching(true); // Set the flag to indicate search
        setIsLoading(true);
        setSearchedText(e);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shortlisted dynamic table error");
      }
    } else if (tab === "Scheduled Interviews") {
      try {
        payload.candidateState = "SCHEDULED";
        response = await ByStateSearch(e, payload);
        setIsSearching(true); // Set the flag to indicate search
        setIsLoading(true);
        setSearchedText(e);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    } else if (tab === "Offered") {
      try {
        payload.candidateState = "OFFERED";
        response = await ByStateSearch(e, payload);
        setIsSearching(true); // Set the flag to indicate search
        setIsLoading(true);
        setSearchedText(e);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    }
    if (page === 0) {
      prepareHideDropDown(response?.data?.response);
    }
    setIsLoading(true);
  };
  const handlescroll = (event) => {
    const target = event?.target;
    if (!target) return;

    const scrolledToBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >=
      target.scrollHeight - 1;

    if (scrolledToBottom && !lastPage && !isSearching) {
      gettableData(selectedTab, pageno);
    } else if (scrolledToBottom && !lastPage && isSearching) {
      gettableDataSearched(searchedText, selectedTab, pageno);
    }
  };

  let gettableData = async (tab, page) => {
    let payload = {
      pageNo: page,
      pageSize: size,
      jobPostId: props.job.id,
    };
    let response;
    if (tab === "Shared Profiles") {
      try {
        payload.candidateState = "SHARED";
        response = await getAdminBdCandReq(payload);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    } else if (tab === "Shortlisted Profiles") {
      try {
        payload.candidateState = "SHORTLISTED";
        response = await getAdminBdCandReq(payload);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shortlisted dynamic table error");
      }
    } else if (tab === "Scheduled Interviews") {
      try {
        payload.candidateState = "SCHEDULED";
        response = await getAdminBdCandReq(payload);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    } else if (tab === "Offered") {
      try {
        payload.candidateState = "OFFERED";
        response = await getAdminBdCandReq(payload);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    }
    if (page === 0) {
      prepareHideDropDown(response.data.response);
    }
    setIsLoading(true);
  };
  let prepareHideDropDown = (response) => {
    let contextData = [];
    response.columnDef.columns.map((data) => {
      contextData.push({
        option: data.columnName,
      });
    });
    setContextMenuData([...contextData]);
  };

  let handleTabs = (tab) => {
    if (tab !== selectedTab && !listview) {
      cardSearchInput.current.value = "";
      setIsLoading(false);
    }
    if (tab === "Shared Profiles") {
      gettableData("Shared Profiles", 0);
      setSelectedtab("Shared Profiles");
    } else if (tab === "Shortlisted Profiles") {
      gettableData("Shortlisted Profiles", 0);
      setSelectedtab("Shortlisted Profiles");
    } else if (tab === "Scheduled Interviews") {
      gettableData("Scheduled Interviews", 0);
      setSelectedtab("Scheduled Interviews");
    } else if (tab === "Offered") {
      gettableData("Offered", 0);
      setSelectedtab("Offered");
    }
    setTableApiData({ ...tableApiData });
    if (cardSearchInput?.current) {
      cardSearchInput.current.value = "";
    }
  };

  let handelCardSearch = (e) => {
    let fieldValue = e.target.value.toLowerCase();
    let candidateData = [];
    hireTableData.map((candidate) => {
      if (
        candidate.profile.toLowerCase().includes(fieldValue) ||
        candidate.skills.toLowerCase().includes(fieldValue) ||
        candidate.location.toLowerCase().includes(fieldValue) ||
        candidate.education.toLowerCase().includes(fieldValue)
      ) {
        candidateData.push({ ...candidate });
      }
    });
    setCandidateCardsData([...candidateData]);
  };

  let closeModal = (val) => {
    if (scheduleInterviewModal) {
      setScheduleInterviewModal(false);
    }
  };
  let actionele = (data) => {
    return (
      <div className="hire-now-btn">
        {selectedTab === "Offered" && (
          <span className="candidate-status">Offered</span>
        )}
        {(selectedTab === "Scheduled Interviews" ||
          selectedTab === "Shared Profiles" ||
          selectedTab === "Shortlisted Profiles") && (
          <button
            className={`datatrimmer pl-2 pr-2 ${
              selectedTab === "Shortlisted Profiles" ? "opacity-50" : ""
            }`}
            onClick={() => {
              handleCandidateBtn(data);
            }}
            disabled={selectedTab === "Shortlisted Profiles"}
            title={
              selectedTab === "Shared Profiles"
                ? "Shortlist"
                : selectedTab === "Shortlisted Profiles"
                ? "Shortlisted"
                : selectedTab === "Scheduled Interviews"
                ? "Update Interview"
                : ""
            }
          >
            {selectedTab === "Shared Profiles"
              ? "Shortlist"
              : selectedTab === "Shortlisted Profiles"
              ? "Shortlisted"
              : selectedTab === "Scheduled Interviews"
              ? "Update Interview"
              : ""}
          </button>
        )}
      </div>
    );
  };
  useEffect(() => {
    setIsLoading(true);
    gettableData("Shared Profiles", 0);
  }, []);
  return (
    <div className="client-interview-candidate-section mx-3 w-full home-component-display mt-2.5 overflow-auto">
      <div className="componentHeader flex">
        <EducationIcon className="hidden" />
        <div
          className={`${
            selectedTab === "Shared Profiles"
              ? "selected-tabletitle"
              : "tabletitle"
          } cursor-pointer`}
          onClick={() => {
            handleTabs("Shared Profiles");
          }}
        >
          <span>Shared Profiles</span>
        </div>
        <div
          className={`${
            selectedTab === "Shortlisted Profiles"
              ? "selected-tabletitle"
              : "tabletitle"
          } cursor-pointer`}
          onClick={() => {
            handleTabs("Shortlisted Profiles");
          }}
        >
          <span>Shortlisted Profiles</span>
        </div>
        <div
          className={`${
            selectedTab === "Scheduled Interviews"
              ? "selected-tabletitle"
              : "tabletitle"
          } cursor-pointer`}
          onClick={() => {
            handleTabs("Scheduled Interviews");
          }}
        >
          <span>Scheduled Interviews</span>
        </div>
        <div
          className={`${
            selectedTab === "Offered" ? "selected-tabletitle" : "tabletitle"
          } cursor-pointer`}
          onClick={() => {
            handleTabs("Offered");
          }}
        >
          <span>Offered</span>
        </div>
        <div className="clickables">
          <CandidateColumnOption
            contextMenuData={contextMenuData}
            func={(event) => {
              handelColumnsDispaly(event);
            }}
            hiddenColumnsData={hiddenColumnsData}
          />
        </div>
      </div>
      <div className={`hireTable ${openFilter ? "table-with-filter" : null} `}>
        <input
          className="table-search-input-coordinator col-span-2 "
          onChange={(e) => {
            if (e.target.value.trim() === "") {
              resetSearch();
              gettableData(selectedTab, pageno);
            } else {
              gettableDataSearched(e.target.value, selectedTab, 0);
            }
          }}
          ref={cardSearchInput}
          type="text"
          placeholder="Search"
        />
        <CandidateTable
          isLoading={isLoading}
          tableApiData={tableApiData}
          actionele={actionele}
          handlescroll={handlescroll}
          actionColumn={true}
          actionColumnWidth={"15%"}
          profileColumn={"name"}
          hiddenColumnsData={hiddenColumnsData}
          selectedTab={selectedTab}
          showVideo={
            selectedTab === "Shared Profiles" ||
            selectedTab === "Shortlisted Profiles"
              ? "preview"
              : "both"
          }
          tableType="client-table"
        />
      </div>
      {openFilter && (
        <div className="hire-filter">
          <div className="filter-header">
            <span>Filter By</span>
            <span className="apply-btn">
              <button
                onClick={() => {
                  setOpenFilter(!openFilter);
                }}
              >
                Apply
              </button>
            </span>
          </div>
          {filterOptions.map((data, index) => {
            return (
              <div className="individual-type">
                <div>{data.type}</div>
                {data.values.map((value) => {
                  return (
                    <div className="typeData">
                      <Checkbox
                        color="primary"
                        value={value}
                        checked={selectedFilters?.[data.type]?.includes(value)}
                        onChange={(e) => {
                          handleFilterSelection(e, data.type, value);
                        }}
                        className="root-check-all"
                      />
                      <span>{value}</span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
      {scheduleInterviewModal && (
        <InterviewModals
          candidate={selectCandidate}
          job={props.job}
          toast={toast}
          gettableData={gettableData}
          header={
            selectedTab === "Shared Profiles"
              ? "Schedule Interview"
              : "Update Interview Details"
          }
          selectedTab={selectedTab}
          closeModal={closeModal}
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        className={"tost-msg-stylinh"}
        draggable={false}
        theme="light"
      />
    </div>
  );
};

export default ClientInterviewCandidate;
