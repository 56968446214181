import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";
import { useState } from "react";
import { SelectDropdown } from "../../landing-page/common/select-dropdown";
import { useFormik } from "formik";
import * as yup from "yup";
import $ from "jquery";
import { scheduleInterviewReq } from "../../../api/api-services";
import MultiEmailInputField from "../MultiEmailInputField";
import { useEffect } from "react";
import { validateFromAndToTime } from "../../../util/common-utils";

const ScheduleInterview = (props) => {
  const inputBorder = "1px solid #26428B80";
  let [interviewModedata, setInterviewModedata] = useState([
    { label: "OFFLINE", value: "OFFLINE" },
    { label: "ONLINE", value: "ONLINE" },
  ]);
  let [selectedMode, setSelectedMode] = useState([]);
  const today = dayjs();
  const [dateValue, setDateValue] = useState("");
  let user = JSON.parse(localStorage.getItem("across-geo-user"));
  const [selectedEmails, setSelectedEmails] = useState([]);

  const handleModeChange = (data) => {
    setSelectedMode([...data]);
    if (data.length > 0) {
      formikDetails.setFieldValue("mode", data?.[0].value, true);
    } else {
      formikDetails.setFieldValue("mode", "", true);
    }
  };
  const handleEmailChange = (data) => {
    setSelectedEmails([...data]);
    if (data.length > 0) {
      formikDetails.setFieldValue("participants", [...data], true);
    } else {
      formikDetails.setFieldValue("participants", [], true);
    }
  };
  let initialValues = {
    date: "",
    mode: "",
    fromTime: "",
    totime: "",
    participants: [],
    linkorlocation: "",
  };
  // const validationSchema = yup.object({
  //   date: yup.string().required("Date is required"),
  //   mode: yup.string().required("Interview Mode is required"),
  //   participants: yup.array(),
  //   fromTime: yup.string().required("Please enter proper time"),
  //   // .test('valid-time', 'Please enter Valid time', function (value) {
  //   //   return this.parent.totime > value;
  //   // }),
  //   totime: yup.string().required("Please enter proper time"),
  //   // .test('valid-time', 'Please enter Valid time', function (value) {
  //   //   return this.parent.fromTime < value;
  //   // })
  //   linkorlocation: yup.string().required(`Required`),
  // });
  const validationSchema = yup.object({
    date: yup.string().required("Date is required"),
    mode: yup.string().required("Interview Mode is required"),
    participants: yup.array(),
    // fromTime: yup
    //   .string()
    //   .required("Please enter proper time")
    //   .test(
    //     "valid-time",
    //     "From Time must be earlier than To Time",
    //     function (value) {
    //       const toTime = this.parent.totime;
    //       return validateFromAndToTime(value, toTime);
    //     }
    //   ),
    // totime: yup
    //   .string()
    //   .required("Please enter proper time")
    //   .test(
    //     "valid-time",
    //     "From Time must be earlier than To Time",
    //     function (value) {
    //       const fromTime = this.parent.fromTime;
    //       return validateFromAndToTime(fromTime, value);
    //     }
    //   ),
    fromTime: yup.string().required("Please enter proper time").test("am-pm", "Invalid time format. Please provide AM/PM", (value) => {
      if (!value) {
        return false; // Value is required
      }
      return /(AM|PM)$/i.test(value);
    }).test(
      "valid-time",
      "From Time must be earlier than To Time",
      function (value) {
        const toTime = this.parent.totime;
        return validateFromAndToTime(value, toTime);
      }
    ) .test("past-time", "Past time not allowed", function (value) {
      const currentDate = dayjs().format("YYYY-MM-DD");
      const selectedDate = this.parent.date;
      if (selectedDate === currentDate) {
        const currentTime = dayjs().format("hh:mm A");
        return dayjs(value, "hh:mm A").isAfter(dayjs(currentTime, "hh:mm A"));
      }
      return true;
    }),
    totime: yup.string().required("Please enter proper time").test("am-pm", "Invalid time format. Please provide AM/PM", (value) => {
      if (!value) {
        return false; // Value is required
      }
      return /(AM|PM)$/i.test(value);
    }).test(
      "valid-time",
      "From Time must be earlier than To Time",
      function (value) {
        const fromTime = this.parent.fromTime;
        return validateFromAndToTime(fromTime, value);
      }
    ) .test("past-time", "Past time not allowed", function (value) {
      const currentDate = dayjs().format("YYYY-MM-DD");
      const selectedDate = this.parent.date;
      if (selectedDate === currentDate) {
        const currentTime = dayjs().format("hh:mm A");
        return dayjs(value, "hh:mm A").isAfter(dayjs(currentTime, "hh:mm A"));
      }
      return true;
    }),
    linkorlocation: yup.string().required("Required"),
  });

  

  const onSubmit = async (values) => {
    let response;
    let payload = {
      candidateID: props.candidate.id,
      date: values.date,
      interviewType: "EXTERNAL",
      startTime: values.fromTime,
      endTime: values.totime,
      schedulerId: user.uid,
      schedulerName: user.username,
      interviewMode: values.mode,
      jobPostId: props.job.id,
      interviewersId: props?.job?.clientId,
      participants: [props.clientData?.email, props.candidate?.email],
    };
    if (values?.participants?.length > 0) {
      values?.participants?.map((emailid) => {
        if (!payload?.participants?.includes(emailid)) {
          payload?.participants?.push(emailid);
        }
      });
    }
    if (values.mode === "ONLINE") {
      payload.meetingLink = values.linkorlocation;
    } else if (values.mode === "OFFLINE") {
      payload.locationDetails = values.linkorlocation;
    }
    try {
      response = await scheduleInterviewReq(payload);
      console.log(response)
      if (
        response.status === 200 
        
      ) {
        props.toast.success(
          <>
            <div className="toast-heading">Success</div>
            <div>Interview scheduled successfully</div>
          </>
        );
        props.closeModal();
      } else {
        
        props.toast.info(
          <>
            <div className="toast-heading">Info</div>
            <div>
             Another interview exists with given date and time
            </div>
          </>
        );
      }
    } catch (err) {
      console.error(err, "schedule interview api error");
    }
  };
  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const handleCount = (value) => {
    let charCount = value.length,
      current = $("#current");
    current.text(charCount);
  };
  return (
    <form
      onSubmit={formikDetails.handleSubmit}
      className="schedule-interview-form"
    >
      <div className="individual-field">
        <div className="mb-2 label">
          <span className="create-labels">Date and Time</span>
        </div>
        <div className="popins-medium input-ele">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              minDate={today}
              value={dateValue}
              id="date"
              name="date"
              slotProps={{
                textField: (props) => ({
                  error: false,
                  readOnly: true,
                  // label: position === "start" ? "From" : "To",
                }),
              }}
              onChange={(newValue) => {
                formikDetails.setFieldValue("date", dayjs(newValue).format('YYYY-MM-DD'));
                setDateValue(newValue);
              }}
              format="DD-MM-YYYY"
              // placeholder={<span className="date-placeholder">Select...</span>}
              className="date-picker"
            />
          </LocalizationProvider>
          {formikDetails.errors.date &&
          formikDetails.touched.date &&
          !formikDetails.values.date ? (
            <div className="text-red-500 error_text md:mt-1">
              {formikDetails.errors.date}
            </div>
          ) : null}
        </div>
        <div className="mt-2 time-label">
          <span className="create-labels">From</span>
          <span className="create-labels">To</span>
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["MultiInputTimeRangeField"]}>
            <MultiInputTimeRangeField
              ampm={true}
              shouldRespectLeadingZeros={true}
              // disablePast={true}
              slotProps={{
                textField: (props) => ({
                  error: false,
                }),
              }}
              onChange={(value) => {
                if (value[0] !== null && value[0]?.$d !== "Invalid Date") {
                  formikDetails.setFieldValue(
                    "fromTime",
                    dayjs(value[0]).format("hh:mm:ss A"),
                    true
                  );
                }
                if (value[1] !== null && value[1]?.$d !== "Invalid Date") {
                  formikDetails.setFieldValue(
                    "totime",
                    dayjs(value[1]).format("hh:mm:ss A"),
                    true
                  );
                }
              }}
              // onError={(val)=>{
              //   console.log(val)
              // }}
            />
          </DemoContainer>
        </LocalizationProvider>
        {/* {(formikDetails.errors.fromTime && formikDetails.touched.fromTime) ||
        (formikDetails.errors.totime && formikDetails.touched.totime) ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.totime || formikDetails.errors.totime}
          </div>
        ) : null} */}
        {(formikDetails.errors.fromTime && formikDetails.touched.fromTime) ||
  (formikDetails.errors.totime && formikDetails.touched.totime) ? (
    <div className="text-red-500 error_text md:mt-1">
      {formikDetails.errors.fromTime || formikDetails.errors.totime}
    </div>
  ) : null}
      </div>
      <div className="field-wrapper mt-2 individual-field">
        <div className="mb-2 label">
          <span className="create-labels">Interview Mode</span>
        </div>
        <div className="input-ele">
          <SelectDropdown
            data={interviewModedata}
            onChange={handleModeChange}
            selectedData={selectedMode}
            deselection={true}
            searchable={false}
          />
        </div>
        {formikDetails.errors.mode &&
        formikDetails.touched.mode &&
        !formikDetails.values.mode ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.mode}
          </div>
        ) : null}
      </div>
      <div className="field-wrapper mt-2 individual-field">
        <div className="mb-2 label">
          <span className="create-labels">Other Participants</span>
        </div>
        <div className="input-ele">
          <MultiEmailInputField
            handleEmailChange={handleEmailChange}
            selectedEmails={selectedEmails}
            fieldName={"participants"}
          />
        </div>
      </div>
      {selectedMode?.[0]?.value === "ONLINE" ? (
        <div className="individual-field mt-4">
          <div className="mb-2 label">
            <span className="create-labels">Meeting Link</span>
          </div>
          <div className="popins-medium">
            <input
              className="input-field"
              name="link"
              type={"text"}
              onChange={(e) => {
                formikDetails.setFieldValue(
                  "linkorlocation",
                  e.target.value,
                  true
                );
              }}
            />
          </div>
          {formikDetails.errors.linkorlocation &&
          formikDetails.touched.linkorlocation ? (
            <div className="text-red-500 error_text md:mt-1">
            Interview link is Required
            </div>
          ) : null}
        </div>
      ) : (
        selectedMode?.[0]?.value === "OFFLINE" && (
          <div className="individual-field mt-3">
            <div className="mb-2 label">
              <span className="create-labels">Location</span>
            </div>
            <div className="popins-medium">
              <textarea
                className="form-control shadow-none textarea_contact"
                name="address"
                cols="40"
                rows="5"
                value={formikDetails.values.linkorlocation}
                onChange={(e) => {
                  formikDetails.setFieldValue(
                    "linkorlocation",
                    e.target.value,
                    true
                  );
                }}
                style={{ border: inputBorder }}
                //   onKeyDown={handleEnter}
                maxLength="500"
                onKeyUp={(e) => handleCount(e.target?.value)}
              ></textarea>
              <div className="d-flex  mb-1 labelTitleDemo">
                {formikDetails.errors.linkorlocation &&
                formikDetails.touched.linkorlocation ? (
                  <span className="text-red-500 float-left error_text md:mt-1">
                    Location is Required
                  </span>
                ) : null}
                <span className="float-right ml-auto">
                  <span id="current">0</span>
                  <span id="maximum">&nbsp;/ 500</span>
                </span>
              </div>
            </div>
          </div>
        )
      )}
      <div className="fixed bottom-8 right-7">
        <div className="hire-now-btn">
          <button className="datatrimmer updateBtn" type="submit">
            Update
          </button>
        </div>
      </div>
    </form>
  );
};

export default ScheduleInterview;
