import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "./Myleadcards.scss";
import { ReactComponent as LocationIcon } from "../../../assets/location.svg";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import WarningIcon from "@mui/icons-material/Warning";
import Modal from "react-modal";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import HandshakeIcon from '@mui/icons-material/Handshake';
// import AdminJobPostCard from "./AdminJobPostCard";
import {
    getNewJobPost,
    getOldJobPost,
    adminNewJobPostSearch,
    convertToAccount,
    adminOldJobPostSearch,
    getAllLeads,
    getAllLeadsById,
} from "../../../api/api-services";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NoDataFound } from "../../../assets/noDataFound.svg";
// import AdminJobPostDetails from "./AdminJobPostDetails";
import JobDetails from "../../client/modals/JobDetails";
import NewCompanyAdd from "./NewCompanyAdd";
import PostJobModal from "../../client/modals/PostJobModal";
import LeadDetails from "./LeadDetails";
import AdminJobPostCard from "../../admin/admin-jobpost/AdminJobPostCard";
import { element } from "prop-types";

const customStylesPage = {
    overlay: {
        background: "rgba(90, 90, 90,0.5)",
    },

    content: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        height: "30%",
        background: "#fbfcfd 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 11px #000000a1",
        borderRadius: "4px",
        opacity: "1",
        outline: "none",
        index: "9999",
        padding: "3px",
    },
};

function Myleadcards() {
    const [convertId, setConvertId] = useState("")
    const [tabIndex, setTabIndex] = useState(0);
    const [allLeads, setAllleeds] = useState([]);
    const [addJobPost, setAddJobPost] = useState(false);
    const [mode, setMode] = useState("");
    let [jobTableDetails, setJobTableDetails] = useState(false);
    const [newCompanyForm, setNewCompanyForm] = useState(false)
    const [allLeadsCount, setAllleedsCount] = useState();
    const [leadsByIdCount, setleadsByIdCount] = useState();
    const [editValues, setEditValues] = useState({})
    const [leadsById, setleadsById] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [leadsByIdSearched, setleadsByIdSearched] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [openPop, setOpenPop] = useState(false)
    const [editLeadModal, setEditLeadModal] = useState(false)
    const location = useLocation();
    let userid = JSON.parse(localStorage.getItem("across-geo-user")).uid;
    const queryParams = new URLSearchParams(location.search);
    const shouldSelectOldClientsTabParam = queryParams.get(
        "shouldSelectOldClientsTab"
    );
    const [shouldSelectOldClientsTab, setShouldSelectOldClientsTab] = useState(
        shouldSelectOldClientsTabParam === "true" // Convert the string to a boolean value
    );
    let [postJobDetails, setPostDetails] = useState(false);
    const [selectedJob, setSelectedJob] = useState([]);
    let [pageno, setpageNo] = useState(0);
    let [allLeadpageno, setAllLeadpageNo] = useState(0);
    let [lastPage, setLastpage] = useState(false);
    const [convert, setConvert] = useState(true)
    let [oldJobpageno, setOldJobpageno] = useState(0);
    let [oldlastPage, setOldLastpage] = useState(false);
    let size = 10;
    const [isSearching, setIsSearching] = useState(false);
    const resetSearch = () => {
        setIsSearching(false);
        setpageNo(0);
        setLastpage(false);
        tabIndex === 0 ? setAllleeds([]) : setleadsById([]);
    };
    const handelMoreBtn = (data, type) => {
        setSelectedJob({ ...data });
        if (type === "Details") {
            setPostDetails(true);
        }
    };
    const fetchallleads = async (allLeadpageno) => {
        try {
            const response = await getAllLeads(allLeadpageno, size);
            if (response?.data?.response?.last === false) {
                setAllLeadpageNo(allLeadpageno + 1);
                setLastpage(response?.data?.response?.last);
            }
            setAllleeds((prevList) => [...prevList, ...response.data.response.content]);
            setIsLoading(false);
            if (allLeadpageno === 0) {
                setAllleeds([...response.data.response.content]);
            } else {
                setAllleeds([...allLeads, ...response.data.response.content]);
            }
            // setpageNo(allLeadpageno + 1);
            // setLastpage(response.data.response.last);
            setAllleedsCount(response.data.response.totalElements);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (tabIndex === 0) {
            fetchLeadById(0);
        }
    }, []);
    const fetchLeadById = async (pageno) => {
        try {
            const response = await getAllLeadsById(userid, pageno, size);
            console.log(response, "response")
            setleadsByIdCount(response.data.response.totalElements);
            if (response?.data?.response?.last === false) {
                setpageNo(pageno + 1);
            }
            if (pageno === 0) {
                setleadsById([...response.data.response.content]);
            } else {
                setleadsById([...leadsById, ...response.data.response.content]);
            }
            setIsLoading(false);
            setLastpage(response.data.response.last);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };
    // const handleSearch = async (event, page) => {
    //     const searchTerm = event;
    //     setSearchTerm(searchTerm);

    //     try {
    //         setIsLoading(true);
    //         setIsSearching(true);

    //         let response;

    //         if (tabIndex === 0) {
    //             // Call adminNewJobPostSearch API
    //             response = await adminNewJobPostSearch(searchTerm, page, size);
    //             if (page === 0) {
    //                 setAllleeds([...response.data.response.content]);
    //             } else {
    //                 setAllleeds([...allLeads, ...response.data.response.content]);
    //             }
    //             if (response?.data?.response?.last === false) {
    //                 setpageNo(page + 1);
    //             }
    //             // setAllleeds(response.data.response.content);
    //             // setpageNo(page + 1);
    //             setLastpage(response.data.response.last);
    //             setAllleedsCount(response.data.response.totalElements);
    //         } else if (tabIndex === 1) {
    //             // Call adminOldJobPostSearch API
    //             response = await adminOldJobPostSearch(searchTerm, page, size);
    //             if (page === 0) {
    //                 setleadsByIdSearched([...response.data.response.content]);
    //             } else {
    //                 setleadsByIdSearched([...leadsById, ...response.data.response.content]);
    //             }
    //             if (response?.data?.response?.last === false) {
    //                 setOldJobpageno(page + 1);
    //             }
    //             // setleadsById(response.data.response.content);
    //             // setOldJobpageno(page + 1);
    //             setOldLastpage(response.data.response.last);
    //             setleadsByIdCount(response.data.response.totalElements);
    //         }

    //         setIsLoading(false);
    //     } catch (error) {
    //         console.error(error);
    //         setIsLoading(false);
    //     }
    // };


    const handlescroll = async (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.target;
        const target = event?.target;
        if (!target) return;

        const scrolledToBottom =
            Math.ceil(target.scrollTop + target.clientHeight) >=
            target.scrollHeight - 1;

        if (scrolledToBottom) {
            setIsLoading(true);

            if (tabIndex === 0) {
                if (!lastPage && !isLoading) {
                    await fetchLeadById(pageno);
                }
            } else if (tabIndex === 1) {
                if (!lastPage && !isLoading) {
                    await fetchallleads(allLeadpageno);
                }
            }
        }
    };
  

    const handleConvertClick = async (id) => {
        let errorMsg
        try {
            const resp = await convertToAccount(id)
            if (resp.data.statusCode === 200) {
                setOpenPop(false)
                toast.success(
                    <>
                        <div className="toast-heading">Success</div>
                        <div>Lead Converted To Account</div>
                    </>
                )
            } else {
                toast.error(
                    <>
                        <div className="toast-heading">Error</div>
                        <div>User Already Exist</div>
                    </>
                );
            }
        } catch {
            toast.error(
                <>
                    <div className="toast-heading">Error</div>
                    <div>User Already Exist</div>
                </>
            );
            console.log("failed in converting the lead to account")
        }
    }
 
    return (
        <div className="adminJobPostContainer mx-3 w-full home-component-display mt-2.5 overflow-x-hidden overflow-y-auto">
            <div className="headerContent">
                <div className="tabContents my-2">
                    <Tabs
                        selectedIndex={tabIndex}
                        onSelect={(index) => {
                            setTabIndex(index);
                            setSearchTerm("");
                            setPostDetails(false);
                            resetSearch();
                            index === 0 ? fetchLeadById(0) : fetchallleads(0);
                        }}
                    >
                        <div className="pannelTabs row flex">
                            <div className="col-6">
                                <TabList className="flex outline-none" style={{ height: "50px" }}>
                                    <Tab className={tabIndex === 0 ? "selectedTab" : ""}>
                                        My Leads{leadsByIdCount ? `-${leadsByIdCount}` : ""}
                                    </Tab>
                                    <Tab className={tabIndex === 1 ? "selectedTab ml-2" : "ml-2"}>
                                        Total Leads{allLeadsCount ? `-${allLeadsCount}` : ""}
                                    </Tab>
                                </TabList>
                            </div>
                            <div className="searchComponent flex row col-6">
                                <div className="flex">
                                <div className="searchInput">
                                    <input
                                        className="table-search-input"
                                        // onChange={handelCardSearch}
                                        // onChange={(e) => {
                                        //   if (e.target.value.trim() === "") {
                                        //     resetSearch();
                                        //     getCoordinateList(0);
                                        //   } else {
                                        //     handelCardSearch(e.target.value, 0);
                                        //   }
                                        // }}
                                        type="text"
                                        // ref={cardSearchInput}
                                        placeholder="Search"
                                    />
                                    </div>
                                    <div
                                className="addBtn float-right"
                                onClick={() => {
                                    setNewCompanyForm(true);
                                }}
                            >
                                <button>
                                    + New Lead
                                </button>

                            </div>
                            </div>
                              </div>
                        </div>

                        <TabPanel>
                            {leadsById.length != 0 ? (
                                <div style={{ maxHeight: "70vh" }}
                                    className='p-2 overflow-y-auto'
                                    onScroll={handlescroll}>
                                    {leadsById.map((element) => {
                                           const websiteAddres = () => {
                                            let webAdress;
                                            if (!(element.company.website.includes("http://")|| element.company.website.includes("https://"))) {
                                                webAdress = `http://${element.company.website}`
                                            } else {
                                                webAdress = element.company.website;
                                            }
                                            return webAdress;
                                        }
                                        console.log(element, "element", leadsById)
                                        return (
                                            <div className="m-2">
                                                <div className='opacity-100  job-card border border-gray-400 rounded-lg shadow-md border-opacity-0 transform' >
                                                    <div className='flex row p-3'>
                                                        <div className='col-3 border-r-2 border-#dadada-500'>
                                                            <h5 style={{ fontSize: "16px", fontFamily: "poppins-Medium" }}>Company Name:</h5>
                                                            <span style={{ fontFamily: "poppins-regular" }} title={element.company.name} className='text-orange-500 '>{element.company.name ? element.company.name.length > 10 ? element.company.name.substring(0, 10) + "..." : element.company.name : ""} </span>
                                                            <div className='row border-right'>
                                                                <div className='col-6 p-2 pt-4"'><h5 style={{ fontSize: "16px", fontFamily: "poppins-Medium" }}>Headquarters:</h5>
                                                                    <div className="flex">
                                                                        <span style={{ paddingTop: "5px" }}> <LocationIcon className="text-orange-500" height="15px" width="15px" /></span>{" "}<span className="">{element.company.headquarters ? element.company.headquarters : "--"}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-9'>
                                                            <div className='row'>
                                                                <div className='col-6 flex'><h5 style={{lineHeight:"25px"}}>Website:</h5><a target="_blank" href={`${websiteAddres()}`}><span className='text-blue-700 cursor-pointer'>{websiteAddres()}</span></a></div>
                                                                <div className='col-5 flex'>
                                                                    <div className="ol-6 flex">
                                                                        <h5>Industry:</h5><span>{element.company.industry ? element.company.industry : "--"}</span></div>
                                                                </div>
                                                                <div
                                                                    title="Edit"
                                                                    className="float-right col-1 cursor-pointer"
                                                                    onClick={() => {
                                                                        setEditLeadModal(true);
                                                                        setEditValues(element)
                                                                    }}
                                                                ><ModeEditIcon /></div>
                                                                <div className="flux">
                                                                    <div className="row">
                                                                    <div className='col-6 flex'><h5>Company Size:</h5><span>{element.company.no_of_employee ? element.company.no_of_employee : "--"}</span></div>
                                                                    <div className='col-6 flex'><h5>Specialities:</h5><span style={{lineHeight:"normal"}}>{" "}{element.company.specialities ? element.company.specialities : "--"}</span></div>
                                                                    </div>
                                                                    {(element.company && element.client) ?
                                                                        <div className='col-12'>
                                                                            <button style={{ width: "180px" }} className='float-right shareProfileBtn ' onClick={() => {
                                                                                setOpenPop(true);
                                                                                setConvertId(element.id)
                                                                            }}>Convert to Account</button>
                                                                        </div> : <div title="add client information" className='col-12'>
                                                                            <button disabled style={{ width: "180px", borderColor: "rgb(241 245 249)" }} className='float-right shareProfileBtn bg-slate-200 opacity-1 text-slate-400' onCLick={() => { }}>Convert to Account</button>
                                                                        </div>
                                                                    }
                                                                    
                                                                </div>
                                                                {element.client &&
                                                                    <div className="flex row">
                                                                        <div style={{marginRight: "11px"}} className='col-6 flex'>
                                                                            <h5>Client Name:</h5> <span className='pb-0'>{" "}{element?.client?.name ? element?.client?.name : "--"}</span>
                                                                        </div>
                                                                        <div className="col-5 flex">
                                                                            <h5>Client Mail:</h5><span title={element?.client?.email} style={{lineHeight: "normal"}} className='pb-0 cursor-pointer'>{" "}{ element?.client?.email.length > 10 ? element?.client?.email.substring(0, 10) + "..." :element?.client?.email}</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div >
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : (
                                <div
                                    className="job-card-container overflow-y-auto "
                                    onScroll={handlescroll}
                                >
                                    {leadsById.length === 0 && !isLoading ? (
                                        <>
                                            <div className="no-data-availableIcon">
                                                <NoDataFound />
                                            </div>
                                            <div className="no-data-availableText">
                                                <p>Oops !!</p> <p>There are no data to be found yet</p>
                                            </div>
                                        </>
                                    ) : <div>
                                    </div>

                                    }
                                </div>
                            )}
                        </TabPanel>
                        <TabPanel>

                            {allLeads ? (
                                <div style={{ maxHeight: "70vh" }}
                                    className='p-2 mb-1 overflow-y-auto'
                                    onScroll={handlescroll}>
                                    {allLeads.map((element) => {
                                        console.log(element, "element")
                                        return (
                                            <div className="m-2">
                                                <div className='opacity-100  border border-gray-400 rounded-lg shadow-md border-opacity-0 transform' >
                                                    <div className='flex row p-3'>
                                                        <div className='col-3 border-r-2 border-#dadada-500'>
                                                            <h5 style={{ fontSize: "16px", fontFamily: "poppins-Medium" }}>Company Name:</h5>
                                                            <span style={{ fontFamily: "poppins-regular" }} title={element.company.name} className='text-orange-500 '>{element.company.name ? element.company.name.length > 10 ? element.company.name.substring(0, 10) + "..." : element.company.name : ""} </span>
                                                            <div className='row border-right'>
                                                                <div className='col-6 p-2 pt-4"'><h5 style={{ fontSize: "16px", fontFamily: "poppins-Medium" }}>Headquarters:</h5>
                                                                    <div className="flex">
                                                                        <span style={{ paddingTop: "5px" }}> <LocationIcon height="15px" width="15px" /></span>{" "}<span className="">{element.company.headquarters ? element.company.headquarters : "--"}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-9'>
                                                            <div className='row'>
                                                                <div className='col-6 flex'><h5>Website:</h5>
                                                                    <span className='text-blue-700 cursor-pointer'><a href={element.company.website} target="_blank">{element.company.website ? element.company.website : "--"}</a></span>
                                                                </div>
                                                                <div className='col-6 flex'><h5 style={{lineeight: "normal"}}>Industry:</h5>
                                                                    <span style={{marginBottom:"3px"}}>{element.company.industry ? element.company.industry : "--"}</span>
                                                                </div>
                                                                <div className='col-6 flex'><h5>Company Size:</h5><span>{element.company.no_of_employee ? element.company.no_of_employee : "--"}</span></div>
                                                                <div className='col-6 flex'><h5 style={{lineeight: "0px"}}>Specialities:</h5><span style={{ lineHeight: "normal" }}>{element.company.specialities ? element.company.specialities : "--"}</span></div>
                                                                {element.client &&
                                                                    <div className="flex p-2">
                                                                        <div className='col-6'>
                                                                            <span className='pb-0'><h5>Client Name:</h5>{" "}<span style={{ lineHeight: "normal" }}>{element?.client?.name ? element?.client?.name : "--"}</span></span>
                                                                        </div>
                                                                        <div className="col-6 ">
                                                                            <span className='pb-0 flex'><h5>Client Mail:</h5>{" "}<span title={element?.client?.email} style={{lineHeight:"normal"}}>{ element?.client?.email.length > 10 ? element?.client?.email.substring(0, 10) + "..." :element?.client?.email}</span></span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div >
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                                : (
                                    <div
                                        className="job-card-container overflow-y-auto"
                                        onScroll={handlescroll}
                                    >
                                        {allLeads.length === 0 || (isSearching && leadsByIdSearched.length === 0) && !isLoading ? (
                                            <>
                                                <div className="no-data-availableIcon">
                                                    <NoDataFound />
                                                </div>
                                                <div className="no-data-availableText">
                                                    <p>Oops !!</p> <p>There are no data to be found yet</p>
                                                </div>
                                            </>
                                        ) :
                                            allLeads.length === 0 || (isSearching && leadsByIdSearched.length === 0) && !isLoading ? (
                                                <div className="no-data-page text-center flex items-center">
                                                    <div className="noDataCompany">
                                                        <div className="no-data-availableIcon">
                                                            <NoDataFound />
                                                        </div>
                                                        <div className="no-data-availableText">
                                                            <p>Oops !!</p>{" "}
                                                            <p>There are no data to be found yet</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : !isSearching ? (
                                                allLeads.map((job, index) => (
                                                    <AdminJobPostCard
                                                        key={job.id}
                                                        job={job}
                                                        index={index}
                                                        tabIndex={tabIndex}
                                                        showShareBtn="true"
                                                        handelMoreBtn={handelMoreBtn}
                                                    />
                                                ))
                                            ) : leadsByIdSearched.map((job, index) => (
                                                <AdminJobPostCard
                                                    key={job.id}
                                                    job={job}
                                                    index={index}
                                                    tabIndex={tabIndex}
                                                    showShareBtn="true"
                                                    handelMoreBtn={handelMoreBtn}
                                                />
                                            ))}
                                    </div>
                                )}
                        </TabPanel>
                    </Tabs>
                </div>
                {newCompanyForm && (
                    <NewCompanyAdd
                        toast={toast}
                        // fetchLeadById={fetchLeadById}
                        setEditLeadModal={setEditLeadModal}
                        userid={userid}
                        setEditLead={useState}
                        fetchLeadById={() => { fetchLeadById(0) }}
                        newCompanyForm={newCompanyForm}
                        setNewCompanyForm={setNewCompanyForm}
                    />
                )}
                {editLeadModal && (
                    <NewCompanyAdd
                        toast={toast}
                        allLeads={allLeads}
                        editLeadModal={editLeadModal}
                        editValues={editValues}
                        setEditLeadModal={setEditLeadModal}
                        userid={userid}
                        setEditLead={useState}
                        fetchLeadById={() => { fetchLeadById(0) }}
                        newCompanyForm={newCompanyForm}
                        setNewCompanyForm={setNewCompanyForm}
                    />
                )}
                {addJobPost && (
                    <PostJobModal
                        toast={toast}
                        selectedJob={selectedJob}
                        // getJobList={getJobList}
                        mode={mode}
                        closeModal={addJobPost}
                    />
                )}
                {openPop &&
                    (
                        <Modal
                            isOpen={openPop}
                            style={customStylesPage}
                            className="p-px"
                        >
                            <div className="flex justify-between mapCompanymodal-heading p-0">
                                <div className="flex"><span style={{ margin: "18px" }}><WarningIcon color="warning" /></span><p style={{ paddingLeft: "0px" }}>Convert To Account</p></div>
                            </div>
                            <div className="flex justify-center p-4">
                                <div>
                                    <h6>Are you sure you want to convert this Lead to Account?</h6>
                                </div>
                            </div>
                            <div className="float-right">
                                <div className="col-span-8"></div>
                                <div className="grid grid-cols-12 col-span-4 flex">
                                    <div
                                        className="grid grid-cols-12 col-span-6 h-8 cnclBtn mr-4"
                                        onClick={() => { setOpenPop(false) }}
                                    >
                                        <span className="btn_close col-span-10 rounded-sm flex justify-center cursor-pointer">
                                            <button>Cancel</button>
                                        </span>
                                    </div>
                                    <div
                                        className={`grid grid-cols-12 col-span-6 h-8  dltBtn bg-orange-500 `}
                                        onClick={() => {
                                            handleConvertClick(convertId);
                                            setOpenPop(false);
                                            setTimeout(() => {
                                                fetchLeadById(0)
                                            }, 1000);
                                        }}
                                    >
                                        <span className="btn_back col-span-10 rounded-sm flex justify-center cursor-pointer">
                                            <button>Convert</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    )
                }
            </div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className={"tost-msg-stylinh"}
                draggable={false}
                theme="light"
            />

        </div>
    )
}

export default Myleadcards