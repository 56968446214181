import React from 'react';
import JobListItem from './JobListItem';
import './JobListHome.scss';


function JobListHome(props) {
  
 
  return (
    <div  style={{ paddingTop: " 0px !important" }} className='width-full listContainer listContainerpaddingresp '>
      {props?.filteredData?.content?.map((details) => {
        return (
          <div>

          <JobListItem
          details={details}
          filteredData={props.filteredData}
          />
          </div>

         
        )
      })}

    </div>
  )
}

export default JobListHome