import React, { useState } from "react";
import Modal from "react-modal";
import "./InterviewModals.scss";
import CandidatePreviewModal from "../../common/CandidatePreviewModal";
import { useGlobalFilter, useTable } from "react-table";
import { useRef } from "react";
import { useEffect } from "react";
import CoordinatorCard from "../interview-coordinator-home/CoordinatorCard";
import CandidateColumnOption from "../../common/CandidateColumnOption";
import CandidateTable from "../../common/candidate-table/CandidateTable";
import { getAllScheduleInterviews,interviewerSearch } from "../../../api/api-services";

function AssignedInterviewerModal(props) {
  const [openModal, setOpenModal] = useState(true);

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "60%",
      transform: "translate(-50%, -50%)",
      width: "82%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
      outline: "none",
      //   borderTopLeftRadius: " 12px",
    },
  };
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let size = 10;
  const [contextMenuData, setContextMenuData] = useState([]);
  let [hiddenColumnsData, setHiddenColumnsData] = useState([
    "email",
    "gender",
    "location",
    "highest_degree",
    "requested_clients",
    "shareable",
  ]);
  let [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  let [tableApiData, setTableApiData] = useState({
    columnDef: {
      columns: [],
    },
    content: [],
  });
  let handelColumnsDispaly = (e) => {
    let value = e.target.value;
    let data = [...hiddenColumnsData];
    if (data.includes(value) && !e.target.checked) {
      data.splice(data.indexOf(value), 1);
    } else if (e.target.checked) {
      data.push(value);
    }
    setHiddenColumnsData([...data]);
  };
  let gettableData = async (page) => {
    let response;

    try {
      response = await getAllScheduleInterviews(
        props.selectedInterviewerId,
        page,
        size
      );

      if (response.data) {
        setLastpage(response?.data?.response?.last);
        if (response?.data?.response?.last === false) {
          setpageNo(page + 1);
        }
        let tabledata = { ...tableApiData };
        if (page === 0) {
          tabledata = response.data.response;
        } else {
          tabledata.content = tabledata.content.concat(
            response.data.response.content
          );
        }
        setTableApiData(tabledata);
      }
    } catch (err) {
      console.error(err, "shared dynamic table error");
    }
    console.log(page, "page");

    if (page === 0) {
      prepareHideDropDown(response.data.response);
    }
    setIsLoading(true);
  };
  let prepareHideDropDown = (response) => {
    console.log(response, "oop");
    let contextData = [];
    response.columnDef.columns.map((data) => {
      contextData.push({
        option: data.columnName,
      });
    });
    setContextMenuData([...contextData]);
  };
  // const handlescroll = (event) => {
  //   if (
  //     Math.ceil(event?.target?.scrollTop + event?.target?.clientHeight) ===
  //     event?.target?.scrollHeight
  //   ) {
  //     if (lastPage === false) {
  //       gettableData(pageno);
  //     }
  //   }
  // };
  const handlescroll = (event) => {
    const target = event?.target;
    if (!target) return;

    const scrolledToBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >=
      target.scrollHeight - 1;

    if (scrolledToBottom && !lastPage &&  !isSearching) {
      gettableData(pageno);
    }else if(scrolledToBottom && !lastPage &&  isSearching){
      searchInterviewers(searchedText, pageno)
    }
  };
  useEffect(() => {
    setIsLoading(false);
    gettableData(0);
  }, []);

  let searchInterviewers = async (e, page) => {
   
    setIsSearching(true); // Set the flag to indicate search
    setIsLoading(true);
    setSearchedText(e);
    const response = await interviewerSearch(e, page, size, props.selectedInterviewerId);
    if (response.data) {
      setLastpage(response?.data?.response?.last);
      if (response?.data?.response?.last === false) {
        setpageNo(page + 1);
      }
      let tabledata = { ...tableApiData };
      if (page === 0) {
        tabledata = { ...response.data.response };
      } else {
        tabledata.content = tabledata.content.concat(
          response.data.response.content
        );
      }
    
    
      setTableApiData({ ...tabledata });
    } else {
      console.log("error in fetch");
    }
   
    setIsLoading(true);
  };
  const resetSearch = () => {
    setIsSearching(false);
    setpageNo(0);
    setLastpage(false);
  };

  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      style={customStylesPage}
      className="p-px"
    >
      <div className="flex items-center rounded-tl-xl Interview-list-modal-heading items-center assignedinterviewerListModal">
        <div className="header"> Scheduled Interviews Details</div>
        <button
          onClick={() => {
            setOpenModal(false);
            props.closeModal(false);
          }}
          type="button"
          className="modal-close-btn float-left ml-auto"
        >
          X
        </button>
      </div>
      <div className="assignedInterviewerContainer">
        <div className="job-card-sharedProfile my-2">
          <CoordinatorCard job={props.assignedInterViewerData} />
        </div>
        <div className="client-interview-candidate-section mx-3 w-full home-component-display mt-2.5 overflow-auto">
          <div className="componentHeader flex">
            <h2>Scheduled Interview List - {tableApiData?.totalElements}</h2>
            {/* <input
              className="coordinate-list-search-input  col-span-2 "
              onChange={(e) => {}}
              type="text"
              placeholder="Search"
            /> */}
            <div className="clickables">
              <CandidateColumnOption
                contextMenuData={contextMenuData}
                func={(event) => {
                  handelColumnsDispaly(event);
                }}
                hiddenColumnsData={hiddenColumnsData}
              />
            </div>
          </div>
          <div className={`hireTable `}>
          <input
          className="table-search-input-coordinator col-span-2 "
          onChange={(e) => {
            if (e.target.value.trim() === "") {
              resetSearch();
              gettableData( 0);
            } else {
              searchInterviewers(e.target.value, 0);
            }
          }}
          // ref={cardSearchInput}
          type="text"
          placeholder="Search"
        />
            <CandidateTable
              isLoading={isLoading}
              tableApiData={tableApiData}
              // actionele={actionele}
              // actionColumn={true}
              handlescroll={handlescroll}
              actionColumnWidth={"15%"}
              profileColumn={"name"}
              hiddenColumnsData={hiddenColumnsData}
              hideRating={false}
              // tableSearch={true}
              interviewerTable={"interviewerTable"}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AssignedInterviewerModal;
