import React ,{Fragment}from 'react'
import { useNavigate } from "react-router-dom";
import { Popover, Menu, Transition } from "@headlessui/react";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as User } from "../../../assets/userProfile/user_solid.svg";
import { ReactComponent as Email } from "../../../assets/userProfile/email.svg";
import { ReactComponent as SignOut } from "../../../assets/userProfile/sign-out.svg";

import "./UserProfile.scss"
export const UserProfile = () => {
    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
      }
    
      const navigate = useNavigate();
      const signOut = () => {
        localStorage.clear();
        // navigate(`/login`, { replace: true });
        navigate(`/`, { replace: true });
      };
      let userName=JSON.parse(
        localStorage.getItem("across-geo-user")
      ).username
  return (
    <div className='user-profile-header'>
         <Menu
              as="div"
              className="relative inline-block text-left"
              key="option-menu"
            >
              {({ open }) => (
                <>
                  <Menu.Button>
                    <IconButton color="primary" size="small" id="user-icon">
                      <span className="profile-icon">{userName
                      ?.substring(0,2)}</span>
                    </IconButton>
                  </Menu.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="origin-top-right absolute z-40 right-6 mt-2 w-56 rounded-t-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none py-2"
                      style={{ height: "100px" }}
                    >
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              "text-center  px-4 py-2 user_profile"
                            )}
                          >
                            <div className="flex">
                              <div className="userProfile_icon mr-2">
                                <User />
                              </div>
                              <div
                                className="username_datatrimmer w-34"
                                // title={username}
                              >
                                {
                                  userName
                                }
                              </div>
                            </div>
                          </button>
                        )}
                      </Menu.Item>
                      {/* <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          
                          className={classNames(
                            "text-center  px-4 py-2 user_profile"
                          )}
                        >
                          <div className="flex">
                            <div className="userProfile_icon mr-2">
                              <Email/>
                            </div>
                            <div className="username_datatrimmer w-34" 
                            title={email}
                            >
                             {email} 
                            </div>
                          </div>
                        </button>
                      )}
                    </Menu.Item> */}

                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={signOut}
                            className={classNames(
                              "text-center  px-4 py-2 user_profile"
                            )}
                          >
                            <div className="flex">
                              <div className="userProfile_icon mr-2">
                                <SignOut />
                              </div>
                              <div>Signout</div>
                            </div>
                          </button>
                        )}
                      </Menu.Item>
                      {/* </div> */}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
    </div>
  )
}
