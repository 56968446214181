import React, { useEffect, useState } from "react";

import "./SharedInterViewCoordinateList.scss";
import CandidateTable from "../../common/candidate-table/CandidateTable";
import { getAllScheduleInterviews } from "../../../api/api-services";
import CandidateColumnOption from "../../common/CandidateColumnOption";

import CoordinatorCard from "./CoordinatorCard";
import { useLocation, useNavigate } from "react-router-dom";

const SharedInterViewCoordinateList = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { requestedProfile } = state;
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let size = 10;
  const [contextMenuData, setContextMenuData] = useState([]);
  let [hiddenColumnsData, setHiddenColumnsData] = useState([
    "email",
    "gender",
    "location",
    "highest_degree",
    "requested_clients",
    "shareable",
  ]);
  let [isLoading, setIsLoading] = useState(false);

  let [tableApiData, setTableApiData] = useState({
    columnDef: {
      columns: [],
    },
    content: [],
  });
  let handelColumnsDispaly = (e) => {
    let value = e.target.value;
    let data = [...hiddenColumnsData];
    if (data.includes(value) && !e.target.checked) {
      data.splice(data.indexOf(value), 1);
    } else if (e.target.checked) {
      data.push(value);
    }
    setHiddenColumnsData([...data]);
  };
  let gettableData = async (page) => {
    let response;

    try {
      response = await getAllScheduleInterviews(
        requestedProfile.id,
        page,
        size
      );

      if (response.data) {
        setLastpage(response?.data?.response?.last);
        if (response?.data?.response?.last === false) {
          setpageNo(page + 1);
        }
        let tabledata = { ...tableApiData };
        if (page === 0) {
          tabledata = response.data.response;
        } else {
          tabledata.content = tabledata.content.concat(
            response.data.response.content
          );
        }
        setTableApiData(tabledata);
      }
    } catch (err) {
      console.error(err, "shared dynamic table error");
    }
    console.log(page, "page");

    if (page === 0) {
      prepareHideDropDown(response.data.response);
    }
    setIsLoading(true);
  };
  let prepareHideDropDown = (response) => {
    console.log(response, "oop");
    let contextData = [];
    response.columnDef.columns.map((data) => {
      contextData.push({
        option: data.columnName,
      });
    });
    setContextMenuData([...contextData]);
  };
  // const handlescroll = (event) => {
  //   if (
  //     Math.ceil(event?.target?.scrollTop + event?.target?.clientHeight) ===
  //     event?.target?.scrollHeight
  //   ) {
  //     if (lastPage === false) {
  //       gettableData(pageno);
  //     }
  //   }
  // };
  const handlescroll = (event) => {
    const target = event?.target;
    if (!target) return;

    const scrolledToBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >=
      target.scrollHeight - 1;

    if (scrolledToBottom && !lastPage) {
      gettableData(pageno);
    }
  };
  useEffect(() => {
    setIsLoading(false);
    gettableData(0);
  }, []);
  console.log({ tableApiData });

  let actionele = (data) => {
    return (
      <>
        <button
          className=" hover:text-orange-600 interviewListActionBtn flex "
          onClick={() => {}}
        >
          <p className=""> Interview List</p>
        </button>
      </>
    );
  };
  return (
    <div className="sharedInterviewCoordinate">
      <div class="cnclBtnDiv">
        <button
          class="cancelBtn"
          onClick={() => {
            navigate("/coordinator/interviewer");
          }}
        >
          Back
        </button>
      </div>
      <div className="job-card-sharedProfile my-2">
        <CoordinatorCard job={requestedProfile} />
      </div>
      <div className="client-interview-candidate-section mx-3 w-full home-component-display mt-2.5 overflow-auto">
        <div className="componentHeader flex">
          <h2>Scheduled Interview List - {tableApiData?.totalElements}</h2>
          {/* <input
              className="coordinate-list-search-input  col-span-2 "
              onChange={(e) => {}}
              type="text"
              placeholder="Search"
            /> */}
          <div className="clickables">
            <CandidateColumnOption
              contextMenuData={contextMenuData}
              func={(event) => {
                handelColumnsDispaly(event);
              }}
              hiddenColumnsData={hiddenColumnsData}
            />
          </div>
        </div>
        <div className={`hireTable `}>
          <CandidateTable
            isLoading={isLoading}
            tableApiData={tableApiData}
            // actionele={actionele}
            // actionColumn={true}
            handlescroll={handlescroll}
            actionColumnWidth={"15%"}
            profileColumn={"name"}
            hiddenColumnsData={hiddenColumnsData}
            hideRating={false}
            tableSearch={true}
            interviewerTable={"interviewerTable"}
          />
        </div>
      </div>
    </div>
  );
};

export default SharedInterViewCoordinateList;
