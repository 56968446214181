import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { TextField, TextareaAutosize, Select, MenuItem } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./AdminAddClient.scss";
import { SelectDropdown } from "../../../landing-page/common/select-dropdown";
import {
  createNewClient,
  getIndustriesListReq,
} from "../../../../api/api-services";

const validationSchema = Yup.object({
  companyName: Yup.string()
    .required("Company Name is required")
    .matches(
      /^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/,
      "Company Name should contain only alphabets and numericals and no leading spaces"
    )
    .min(3, "Company Name should be minimum 3 characters")
    .max(50, "Company Name should be maximum 50 characters"),
  overview: Yup.string()
    .required("Overview is required")
    .matches(/^(?!.*\s{2})/, "Consecutive Spaces or special characters are not allowed")
    .max(500, "Overview should be maximum 500 characters"),
  website: Yup.string()
    .required("Website is required")
    .matches(/^(?!.*\s{2})/, "Consecutive Spaces or special characters are not allowed"),
  industry: Yup.string().required("Industry is required"),
  companySize: Yup.string().required("Company Size is required").matches(/^(?!.*\s{2})/, "Company Size should not be blank"),
  headquarters: Yup.string().required("Headquarters is required").matches(/^(?!.*\s{2})/, "Headquarters should not be blank"),
  specialities: Yup.string().required("Specialities is required").matches(/^(?!.*\s{2})/, "Specialities should not be blank"),
  // companyType: Yup.string().required("CompanyType is required"),
});

const AdminAddClient = (props) => {
  const [openAddClientModal, setOpenAddClientModal] = useState(true);
  const [companyExistsError, setCompanyExistsError] = useState(null);
  const companyNameRef = useRef();
  const handleCompanyExistsError = (errorMessage) => {
    setCompanyExistsError(errorMessage);

    // Scroll to the Company Name text field when the error occurs
    if (companyNameRef.current) {
      companyNameRef.current.focus();
    }
  };

  const formikDetails = useFormik({
    initialValues: {
      companyName: "",
      overview: "",
      website: "",
      industry: "",
      companySize: "",
      headquarters: "",
      specialities: "",
      // companyType: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const payload = {
          name: values.companyName,
          // short_name: (() => {
          //   const companyName = values.companyName.trim();
          //   const initials = companyName
          //     .split(" ")
          //     .map((word) => word.charAt(0).toUpperCase())
          //     .join("");

          //   return initials;
          // })(),
          // video_url: "www.companyurl.com",
          // type_company: {
          //   name: values.companyType,
          // },
          // status: "test_016d88ebf709",
          // verify: false,
          // filled_details: true,
          // global_location: "test_dc8a031876e4",
          description: values.overview,
          website: values.website,
          industry: values.industry,
          no_of_employee: values.companySize,
          headquarters: values.headquarters,
          specialities: values.specialities,
        };

        // Call the API function
        const response = await createNewClient(payload);
        console.log("API response:", response);

        if (response?.data?.statusCode === 201) {
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>Company Created Successfully</div>
            </>
          );
          setOpenAddClientModal(false);
          props.closeModal(false);
          props.fetchClientDetails(0);
        }
        //   else{
        //   props.toast.error(
        //     <>
        //       <div className="toast-heading">Success</div>
        //       <div>Something went wrong</div>
        //     </>
        //   );

        // }
        if (response && response.response && response.response.status === 400) {
          handleCompanyExistsError(response.response.data.response);
          return;
        }

        // Close the modal or perform any other actions
      } catch (error) {
        console.error("API error:", error);
      }
      console.log("Submitted values:", values);
    },
  });

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "80%",
      transform: "translate(-50%, -50%)",
      width: "40%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
      outline: "none",
      index: "9999",
    },
  };
  const [industryData, setIndustryData] = useState([]);
  const [selectedIndustryData, setSelectedIndustryData] = useState([]);
  const handleIndustryChange = (data) => {
    formikDetails.setFieldValue("industry", data?.[0].value);

    setSelectedIndustryData([...data]);
  };
  const handleSelectIndustryDataBlur = (event) => {
    if (event.currentTarget.contains(event.relatedTarget)) {
      formikDetails.setFieldTouched("industry", true);
      if (formikDetails.touched.industry && !formikDetails.values.industry) {
        formikDetails.setFieldError("industry", "Industry is required");
      }
    }
  };
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await getIndustriesListReq();
        const companies = response.data.response.map((company) => ({
          label: company,
          value: company,
        }));
        setIndustryData(companies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  // company Size
  // const [companySizeData, setCompanySizeData] = useState([
  //   { value: "companySiz1", label: "companySiz1" },
  //   { value: "companySiz2", label: "companySiz2" },
  //   { value: "companySiz3", label: "companySiz3" },
  // ]);
  // const [selectedCompanySizeData,setSelectedCompanySizeData]=useState([])
  // const handleCompanySizeChange = (data) => {
  //   formikDetails.setFieldValue("companySize", data?.[0].value);

  //   setSelectedCompanySizeData([...data]);
  // };
  // const handleCompanySizeBlur = (event) => {
  //   if (event.currentTarget.contains(event.relatedTarget)) {
  //     formikDetails.setFieldTouched("companySize", true);
  //     if (formikDetails.touched.companySize && !formikDetails.values.companySize) {
  //       formikDetails.setFieldError("companySize", "Industry is required");
  //     }
  //   }
  // };

  // HeadQuarters
  const [headquarterData, setHeadquarterData] = useState([
    { value: "Banglore", label: "Banglore" },
    { value: "Bhubaneswar", label: "Bhubaneswar" },
    { value: "Chennai", label: "Chennai" },
  ]);
  const [selectedHeadquarterData, setSelectedHeadquarterData] = useState([]);
  const handleHeadquarterChange = (data) => {
    formikDetails.setFieldValue("headquarters", data?.[0].value);

    setSelectedHeadquarterData([...data]);
  };
  const handleHeadquarterBlur = (event) => {
    if (event.currentTarget.contains(event.relatedTarget)) {
      formikDetails.setFieldTouched("headquarters", true);
      if (
        formikDetails.touched.headquarters &&
        !formikDetails.values.headquarters
      ) {
        formikDetails.setFieldError("headquarters", "Industry is required");
      }
    }
  };
  // Specialities
  const [specialitiesData, setSpecialitiesData] = useState([
    { value: "specialities1", label: "specialities1" },
    { value: "specialities2", label: "specialities2" },
    { value: "specialities3", label: "specialities3" },
  ]);
  const [selectedSpecialitiesData, setSelectedSpecialitiesData] = useState([]);
  const handleSpecialitiesChange = (data) => {
    formikDetails.setFieldValue("specialities", data?.[0].value);

    setSelectedSpecialitiesData([...data]);
  };
  const handleSpecialitiesBlur = (event) => {
    if (event.currentTarget.contains(event.relatedTarget)) {
      formikDetails.setFieldTouched("specialities", true);
      if (
        formikDetails.touched.specialities &&
        !formikDetails.values.specialities
      ) {
        formikDetails.setFieldError("specialities", "Industry is required");
      }
    }
  };
  // Company Type
  // const [companyTypeData, setCompanyTypeData] = useState([
  //   { value: "Small", label: "Small" },
  //   { value: "Medium", label: "Medium" },
  //   { value: "Large", label: "Large" },
  // ]);
  // const [selectedCompanyTypeData, setSelectedCompanyTypeData] = useState([]);
  // const handleCompanyTypeChange = (data) => {
  //   formikDetails.setFieldValue("companyType", data?.[0].value);

  //   setSelectedCompanyTypeData([...data]);
  // };
  // const handleCompanyTypeBlur = (event) => {
  //   if (event.currentTarget.contains(event.relatedTarget)) {
  //     formikDetails.setFieldTouched("companyType", true);
  //     if (
  //       formikDetails.touched.companyType &&
  //       !formikDetails.values.companyType
  //     ) {
  //       formikDetails.setFieldError("companyType", "Industry is required");
  //     }
  //   }
  // };
  return (
    <Modal
      isOpen={openAddClientModal}
      style={customStylesPage}
      className="p-px"
      ariaHideApp={false}
    >
      <div className="addAdminModalContent">
        <div className="flex justify-between create-modal-heading p-2">
          <p>Add New Company</p>
          <div>
            <span className="modal-close-icon">
              <button
                onClick={() => {
                  setOpenAddClientModal(false);
                  props.closeModal(false);
                }}
                type="button"
                className="modal-close-btn p-3"
              >
                <HighlightOffIcon />
              </button>
            </span>
          </div>
        </div>
        <div className="formContent">
          <form onSubmit={formikDetails.handleSubmit}>
            <div className="allContents">
              <div className="form-field">
                <label className="create-labels">Company Name</label>
                <TextField
                  inputProps={{
                    ref: companyNameRef,
                    style: {
                      padding: "9.5px 14px",
                      height: "0.43em !important",
                    },
                  }}
                  style={{ width: "100%", marginTop: "1px" }}
                  id="companyName"
                  name="companyName"
                  value={formikDetails.values.companyName}
                  onChange={(e) => {
                    formikDetails.handleChange(e);
                    handleCompanyExistsError("");
                  }}
                  onBlur={formikDetails.handleBlur}
                  error={
                    formikDetails.touched.companyName &&
                    formikDetails.errors.companyName
                  }
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  placeholder="Enter company name"
                />
                {formikDetails.errors.companyName &&
                  formikDetails.touched.companyName && (
                    <div className="error-message -mt-1.5">
                      {formikDetails.errors.companyName}
                    </div>
                  )}
                {companyExistsError && (
                  <div
                    id="companyExistsError"
                    className="error-message -mt-1.5"
                  >
                    {companyExistsError}
                  </div>
                )}
              </div>
              <div className="form-field  mt-4">
                <label className="create-labels">Overview</label>
                <TextField
                  multiline
                  maxRows={Infinity}
                  id="overview"
                  name="overview"
                  value={formikDetails.values.overview}
                  onChange={formikDetails.handleChange}
                  onBlur={formikDetails.handleBlur}
                  error={
                    formikDetails.touched.overview &&
                    formikDetails.errors.overview
                  }
                  placeholder="Type here..."
                  minRows={3}
                  style={{ width: "100%", marginBottom: "16px" }}
                />
                {formikDetails.errors.overview &&
                  formikDetails.touched.overview && (
                    <div className="error-message -mt-3">
                      {formikDetails.errors.overview}
                    </div>
                  )}
              </div>
              <div className="form-field">
                <label className="create-labels">Website</label>
                <TextField
                  id="website"
                  name="website"
                  value={formikDetails.values.website}
                  onChange={formikDetails.handleChange}
                  placeholder="Enter website name"
                  onBlur={formikDetails.handleBlur}
                  error={
                    formikDetails.touched.website &&
                    formikDetails.errors.website
                  }
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  inputProps={{
                    style: {
                      padding: "9.5px 14px",
                      height: "0.43em !important",
                    },
                  }}
                  style={{ width: "100%", marginTop: "1px" }}
                />
                {formikDetails.errors.website &&
                  formikDetails.touched.website && (
                    <div className="error-message -mt-1.5">
                      {formikDetails.errors.website}
                    </div>
                  )}
              </div>

              <div className="form-field my-4 ">
                <label htmlFor="industry ">Industry</label>

                <div
                  className={`w-full  ${
                    (formikDetails.errors.industry &&
                      formikDetails.touched.industry) ||
                    (formikDetails.touched.industry &&
                      !formikDetails.values.industry)
                      ? "error-input"
                      : ""
                  }`}
                  onBlur={handleSelectIndustryDataBlur}
                >
                  <SelectDropdown
                    data={industryData}
                    onChange={handleIndustryChange}
                    selectedData={selectedIndustryData}
                    searchable={false}
                  />
                </div>

                {formikDetails.touched.industry &&
                  formikDetails.errors.industry && (
                    <div className="error-message mt-1 ml-1">
                      {formikDetails.errors.industry}
                    </div>
                  )}
              </div>
              <div className="form-field my-4">
                <label htmlFor="companySize">Company Size</label>

                <div
                  className={`w-full  ${
                    (formikDetails.errors.companySize &&
                      formikDetails.touched.companySize) ||
                    (formikDetails.touched.companySize &&
                      !formikDetails.values.companySize)
                      ? "error-input"
                      : ""
                  }`}
                  // onBlur={handleCompanySizeBlur}
                >
                  {/* <SelectDropdown
                      data={companySizeData}
                      onChange={handleCompanySizeChange}
                      selectedData={selectedCompanySizeData}
                      searchable={false}
                    /> */}
                  <TextField
                    id="companySize"
                    name="companySize"
                    value={formikDetails.values.companySize}
                    onChange={formikDetails.handleChange}
                    placeholder="Enter company size"
                    onBlur={formikDetails.handleBlur}
                    error={
                      formikDetails.touched.companySize &&
                      formikDetails.errors.companySize
                    }
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    inputProps={{
                      style: {
                        padding: "9.5px 14px",
                        height: "0.43em !important",
                      },
                    }}
                    style={{ width: "100%", marginTop: "1px" }}
                  />
                </div>
                {formikDetails.touched.companySize &&
                  formikDetails.errors.companySize && (
                    <div className="error-message  mt-1 ml-1">
                      {formikDetails.errors.companySize}
                    </div>
                  )}
              </div>
              <div className="form-field my-4">
                <label htmlFor="headquarters">Headquarters</label>

                <div
                  className={`w-full  ${
                    (formikDetails.errors.headquarters &&
                      formikDetails.touched.headquarters) ||
                    (formikDetails.touched.headquarters &&
                      !formikDetails.values.headquarters)
                      ? "error-input"
                      : ""
                  }`}
                  onBlur={handleHeadquarterBlur}
                >
                  {/* <SelectDropdown
                      data={headquarterData}
                      onChange={handleHeadquarterChange}
                      selectedData={selectedHeadquarterData}
                      searchable={false}
                    /> */}
                  <TextField
                    id="headquarters"
                    name="headquarters"
                    value={formikDetails.values.headquarters}
                    onChange={formikDetails.handleChange}
                    placeholder="Enter headquarters"
                    onBlur={formikDetails.handleBlur}
                    error={
                      formikDetails.touched.headquarters &&
                      formikDetails.errors.headquarters
                    }
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    inputProps={{
                      style: {
                        padding: "9.5px 14px",
                        height: "0.43em !important",
                      },
                    }}
                    style={{ width: "100%", marginTop: "1px" }}
                  />
                </div>
                {formikDetails.touched.headquarters &&
                  formikDetails.errors.headquarters && (
                    <div className="error-message   ml-1">
                      {formikDetails.errors.headquarters}
                    </div>
                  )}
              </div>
              <div className="form-field my-4">
                <label htmlFor="specialities">Specialities</label>

                <div
                  className={`w-full  ${
                    (formikDetails.errors.specialities &&
                      formikDetails.touched.specialities) ||
                    (formikDetails.touched.specialities &&
                      !formikDetails.values.specialities)
                      ? "error-input"
                      : ""
                  }`}
                  onBlur={handleSpecialitiesBlur}
                >
                  {/* <SelectDropdown
                      data={specialitiesData}
                      onChange={handleSpecialitiesChange}
                      selectedData={selectedSpecialitiesData}
                      searchable={false}
                    /> */}
                  <TextField
                    id="specialities"
                    name="specialities"
                    value={formikDetails.values.specialities}
                    onChange={formikDetails.handleChange}
                    placeholder="Enter specialities"
                    onBlur={formikDetails.handleBlur}
                    error={
                      formikDetails.touched.specialities &&
                      formikDetails.errors.specialities
                    }
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    inputProps={{
                      style: {
                        padding: "9.5px 14px",
                        height: "0.43em !important",
                      },
                    }}
                    style={{ width: "100%", marginTop: "1px" }}
                  />
                </div>
                {formikDetails.touched.specialities &&
                  formikDetails.errors.specialities && (
                    <div className="error-message   ml-1">
                      {formikDetails.errors.specialities}
                    </div>
                  )}
              </div>
              {/* <div className="form-field my-4">
                <label htmlFor="companyType">Company Type</label>

                <div
                  className={`w-full  ${
                    (formikDetails.errors.companyType &&
                      formikDetails.touched.companyType) ||
                    (formikDetails.touched.companyType &&
                      !formikDetails.values.companyType)
                      ? "error-input"
                      : ""
                  }`}
                  onBlur={handleCompanyTypeBlur}
                >
                  <SelectDropdown
                    data={companyTypeData}
                    onChange={handleCompanyTypeChange}
                    selectedData={selectedCompanyTypeData}
                    searchable={false}
                  />
                </div>
                {formikDetails.touched.companyType &&
                  formikDetails.errors.companyType && (
                    <div className="error-message  mt-1 ml-1">
                      {formikDetails.errors.companyType}
                    </div>
                  )}
              </div> */}
            </div>

            <button
              className="addBtn"
              variant="contained"
              color="primary"
              type="submit"
            >
              Add
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AdminAddClient;
