import React, { useState } from 'react'
import AccountDetails from './AccountDetails'
import { useNavigate } from "react-router-dom";
import "./AccountCard.scss"
import { ReactComponent as LocationIcon } from "../../../assets/location.svg";

function AccountCard(props) {
    const size = 10
    let [lastPage, setLastpage] = useState(false);
    let [pageno, setpageNo] = useState(0);
    const navigate = useNavigate();
    const [accountDetails, setAccountDetails] = useState(false)
    console.log(props, "props")

    const navigateToJobPost = (row) => {
        localStorage.setItem("leadAccuntSelectedTab", props.tabIndex);
        navigate("/bd/account/details", {
            state: {
                accountDetails: props.account
            },
        });
    };
    
    const websiteAddres = () => {
        let webAdress;
        if (!(props?.account?.website?.includes("http://")|| props?.account?.website?.includes("https://"))) {
            webAdress = `http://${props.account.website}`
        } else {
            webAdress = props?.account?.website;
        }
        return webAdress;
    }
    return (
        <div
            onClick={navigateToJobPost}
            className='opacity-100  job-card border border-gray-400 rounded-lg shadow-md border-opacity-0 transform' >
            <div className='flex row p-3 cursor-pointer cursor-pointer'>
                <div className='col-4 border-r-2 border-#dadada-500'>
                    <h5 style={{color:"#ef7f09"}}>{!accountDetails ? props.account.name ? props.account.name : "Comapny Name" : ""} </h5>
                    <div className='row border-right'>
                        <div className='col-6 flex'><h5 >Total Job Post</h5>
                            <span>{!accountDetails ? `${props.account.totalJobPost}` ? `-${props.account.totalJobPost}`: `-${props.account.totalJobPost}` : ""}</span>
                        </div>
                        <div className='col-6 flex '><h5>Total Offers</h5><span>{!accountDetails ? `-${props.account.totalOffered}` ? `-${props.account.totalOffered}` : `-${props.account.totalOffered}` : ""}</span></div>
                        <div className='col-6 flex'><h5 >Total Spocs</h5><span>{!accountDetails ? `-${props.account.totalClients}` ? `-${props.account.totalClients}` : `-${props.account.totalClients}` : ""}</span></div>
                        <div className='col-6 flex'><h5 >Total Shared</h5><span>{!accountDetails ? `-${props.account.totalShared}` ? `-${props.account.totalShared}` : `-${props.account.totalShared}` : ""}</span></div>
                        <div className='col-6 flex'><h5 >Headquarters</h5></div>
                        {/* <span style={{ marginLeft: "30px", marginBottom: "2px" }}>{!accountDetails ? props.account.headquarters ? props.account.headquarters : props.account.headquarters : "--"}</span> */}
                       <span className='flex'><span style={{ paddingTop: "5px" }}> <LocationIcon className="text-orange-500" height="15px" width="15px" /></span>{" "}<span className="">{ props.account.headquarters ? props.account.headquarters :  "--"}</span></span> 
                    </div>
                </div>
                <div className='col-8'>
                    <div className='row'>
                        <div className='col-6 flex'><h5>Website:</h5>
                            <span className='text-blue-700 cursor-pointer'>{" "}<a href={websiteAddres()} target='_blank'>{websiteAddres()}</a></span>
                        </div>
                        <div className='col-6 flex'><h5>Industry:</h5><span>{!accountDetails ? props.account.industry ? props.account.industry : "--" : ""}</span>
                            {/* <div className='p-2'>
                                <span></span>
                            </div> */}
                        </div>
                        {console.log(props.account.no_of_employee, "props.account.no_of_employee")}
                        <div className='col-6 flex'><h5>Company Size:</h5><span>{!accountDetails ? props.account.no_of_employee ? props.account.no_of_employee : "--" : ""}</span></div>
                        <div className='col-6 flex'><h5>Specialities:</h5><span>{!accountDetails ? props.account.specialities ? props.account.specialities : "--" : ""}</span></div>
                        <div className='col-12'>
                            <h5>OverView:</h5>
                            {props.account.description ?
                                <div className='bg-slate-100 rounded-2xl max-h-24 h-24 overflow-y-scroll p-2'><span title={props.account.description}>{!accountDetails ? props.account.description ? props.account.description : "" : ""}</span></div> : `${"  "} --`
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AccountCard