import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./ResetPassword.scss";
import { Link } from "react-router-dom";
import {
  forgotPassword,
  existEmailPhoneCheck,
} from "../../../../api/api-services";
import { ToastContainer, toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import { Button, IconButton, InputAdornment, Grid } from "@material-ui/core";
import LeftArrow from "../../website-assets/leftArrow.png";
import cx from "classnames";
import GreenTickIcon from "../../website-assets/greenTickIcon.png";
import CrossIcon from "../../website-assets/crossIcon.png";
const useStyles = makeStyles((theme) => ({
  form: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  field: {
    margin: theme.spacing(1),
    margin: theme.spacing(1), // Keep margin
    marginTop: 0, // Remove top margin
    marginBottom: 0, // Remove bottom margin
    width: "95%",
    // height: "58px",

    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // border:' 1px solid #DADADA',
    opacity: "1",
  },

  input: {
    backgroundColor: "#fff",
    "&:focused": {
      backgroundColor: "#fff",
    },
    "& .Mui-focused": {
      backgroundColor: "#fff",
    },
  },
}));

const initialValues = {
  emailId: "",
};

const validationSchema = Yup.object({
  emailId: Yup.string()
    .matches(/[A-Za-z0-9]+@[a-zA-Z]+\.[A-Za-z]{2,3}/, "Enter valid email Id")
    .email("Enter valid email Id")
    .required("Email id is required"),
});

const ResetPassword = (props) => {

  const classes = useStyles();
  const [isSuccessMsgShow, setIsSuccessMsgShow] = useState(false);
  const [storeSuccessMsgShow, setStoreSuccessMsgShow] = useState("");
  const [availabilityStatusEmail, setAvailabilityStatusEmail] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const checkAvailability = async (id, value) => {
    try {
      const response = await existEmailPhoneCheck(value);

      if (id === "emailId") {
        setAvailabilityStatusEmail(response?.data?.response);
      }
    } catch (error) {
      console.error("Error occurred while checking availability:", error);
    }
  };
  const handleSubmit = async (values) => {
    if (isSubmitting) {
      return; // Prevent multiple form submissions
    }
    try {
      setIsSubmitting(true);
      const { emailId } = values;
      const response = await forgotPassword(emailId);
      console.log(response);
      if (response?.data?.statusCode === 200) {
        setIsSuccessMsgShow(true);
        setStoreSuccessMsgShow(response?.data?.response);
        setTimeout(() => {
          setStoreSuccessMsgShow(""); // Clear the message after 5 seconds
          setIsSuccessMsgShow(false);
        }, 5000);
        // toast.success(
        //   <>
        //     <div className="toast-heading">Success</div>
        //     <div>{response?.data?.response}</div>
        //   </>
        // );
      }
      // else{
      //   toast.error(
      //     <>
      //       <div className="toast-heading">Alert</div>
      //       <div>{response?.response?.data?.response}</div>

      //     </>
      //   );
      // }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false); // Stop the loading state
    }
  };
  const changeGreenBorderStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#007456"
      },
      "&:hover fieldset": {
        borderColor: "#007456"
      }
    }
  }
  return (
    <div className="resetPasswordSection">
      <div className="successMsg ">
        {isSuccessMsgShow && (
          <>
            <div className="flex justify-center align-middle relative  mt-4">
              {" "}
              <img src={GreenTickIcon} style={{ height: "30px" }} />
            </div>

            <p className="storeSuccessMsg">{storeSuccessMsgShow}</p>
          </>
        )}
      </div>
      <div className=" p-6">
      
        <div className="formContents-forgotPW">
        <div style={{height:"28px",marginBottom:"24px"}}>
                  {" "}
                  <p className="headingFp">
                  Forgot Password
                  </p>
                  <p
                    to="/"
                    className="cursor-pointer flex justify-end relative "
                    style={{ bottom: "77px", left: "30px" }}
                    onClick={() => {
            props.setShowSetPasswordModal(false);
            props.setSelectedTab(0);
          }}
                  >
                    <img
                      src={CrossIcon}
                      className="  top-7  relative"
                      style={{ height: "20px", right: "31px" }}
                    />
                  </p>
                </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, setFieldValue, handleBlur }) => (
              <Form className={classes.form}>
                <span className="text-red-500 ml-2 mx-1">*</span>
                <label className=" formLabelLogin ml-0.5">Email</label>
                <Field
                  name="emailId"
                  id="emailId"
                  placeholder="Enter your email"
                  as={TextField}
                  variant="outlined"
                  className={classes.field}
                   // change the border color from blue to green on focous
                sx={changeGreenBorderStyle}
                  error={touched.emailId && Boolean(errors.emailId)}
                  // helperText={
                  //   touched.emailId && errors.emailId ? (
                  //     errors.emailId
                  //   ) : (
                  //     <p className="text-red-500 error_text  absolute -ml-2">
                  //       {availabilityStatusEmail}
                  //     </p>
                  //   )
                  // }
                  onBlur={(event) => {
                    checkAvailability(event.target.id, event.target.value);
                    handleBlur(event);
                  }}
                  onChange={(event) => {
                    setFieldValue("emailId", event.target.value);
                    setAvailabilityStatusEmail("");
                  }}
                  autoComplete="off"
                 
                  inputProps={{
                  className: cx(` boxedInput Poppins-Medium `),
                  style: {
                    // padding: "12px 14px",
                    // height: "0.43em !important",
                    // fontFamily: "Poppins-Regular",
                    padding: "12px 14px",
                    fontFamily: "Poppins-Regular",
                    fontSize: "14px !important",
                  },
                }}
                />

                {touched.emailId && errors.emailId ? (
                  <p className=" error_text pt-0.5 absolute left-8">
                    {errors.emailId}
                  </p>
                ) : (
                  <p className=" error_text pt-0.5 absolute left-8 ">
                    {availabilityStatusEmail}
                  </p>
                )}
                <div className=" " style={{ marginTop: "34px" }}>
                  <button
                    disabled={isSubmitting || availabilityStatusEmail}
                    type="submit"
                    className="logInBtn logInBtnContainer"
                  >
                    {isSubmitting ? "Sending..." : "Get New Password"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div
          className="registerContainerForgetPw my-2 flex relative top-2  left-2.5 cursor-pointer"
          onClick={() => {
            props.setShowSetPasswordModal(false);
            props.setSelectedTab(0);
          }}
        >
          <img src={LeftArrow} style={{ height: "25px" }} />
          <p className="ml-2 labelPrev">Back to Login</p>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        className={"tost-msg-stylinh"}
        draggable={false}
        theme="light"
      />
    </div>
  );
};

export default ResetPassword;
