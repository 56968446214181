import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./BdJobPost.scss";
import BdJobCard from "./BdJobCard";
import { Checkbox } from "@material-ui/core";
import CandidateColumnOption from "../../common/CandidateColumnOption";
import CandidateTable from "../../common/candidate-table/CandidateTable";
import { useEffect } from "react";
import InterviewModals from "../../common/interview-modals/InterviewModals";
import {
  getAdminBdCandReq,
  getAllAdminBdCandReq,
  shortlistCandReq,
  shareCandReq,
  AllProfileSearch,
  ByStateSearch,
  getIntrestedProfileReq,
  intrestedCandidateSearch
} from "../../../api/api-services";
import { ToastContainer, toast } from "react-toastify";
import AdminJobPostDetails from "../../admin/admin-jobpost/AdminJobPostDetails";
import BdJobPostModal from "../BdJobPostModal";

const ShareProfile = (props) => {
  console.log(props, "propspropsprops")
  const { state } = useLocation();
  const { requestedProfile } = state;
  let [postJobDetails, setPostDetails] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  let [selectedTab, setSelectedtab] = useState("Shared Profiles");
  let user = JSON.parse(localStorage.getItem("across-geo-user"));
  const cardSearchInput = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [contextMenuData, setContextMenuData] = useState([
    { option: "name" },
    { option: "skills" },
    { option: "current_salary" },
    { option: "years_of_experience" },
    { option: "interview" },
    { option: "resume" },
    { option: "gender" },
    { option: "email" },
    { option: "location" },
    { option: "highest_degree" },
    { option: "requested_clients" },
    { option: "shareable" },
  ]);
  let [openFilter, setOpenFilter] = useState(false);
  let [selectCandidate, setSelectedCandidate] = useState({});
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let size = 10;
  const navigate = useNavigate();
  let [tableApiData, setTableApiData] = useState({
    totalCount: 20,
    columnDef: {
      columns: [],
    },
    content: [],
    lastPage: true,
  });
  let [scheduleInterviewModal, setScheduleInterviewModal] = useState(false);
  let [hiddenColumnsData, setHiddenColumnsData] = useState([
    "email",
    "gender",
    "location",
    "highest_degree",
    "requested_clients",
    "shareable",
  ]);

  // const [hiddenColumnsData, setHiddenColumnsData] = useState([]);
  const [editJobPost, setEditJobPost] = useState(false)
  useEffect(() => {
    if (selectedTab === "Shared Profiles") {
      setHiddenColumnsData([
        "internalInterviewTaken",
        "isPublished",

      ]);
    } else {
      setHiddenColumnsData([
        "email",
        "gender",
        "location",
        "highest_degree",
        "requested_clients",
        "shareable",
      ]);
    }
  }, [selectedTab]);

  let [selectedFilters, setSelectedFilters] = useState({});
  let [filterOptions, setFilterOptions] = useState([]);
  const fetchFilterOptions = () => {
    setFilterOptions([
      {
        type: "Location",
        values: ["Bangalore", "Mysore", "Shivamogga", "Goa", "Mangalore"],
      },
      {
        type: "Experiance",
        values: ["Fresher", "0 - 1 yrs", "1 - 3 yrs", "3 - 5 yrs", "5+ yrs"],
      },
    ]);
  };
  let handelColumnsDispaly = (e) => {
    let value = e.target.value;
    let data = [...hiddenColumnsData];
    if (data.includes(value) && !e.target.checked) {
      data.splice(data.indexOf(value), 1);
    } else if (e.target.checked) {
      data.push(value);
    }
    setHiddenColumnsData([...data]);
  };

  let gettableData = async (tab, page) => {
    let payload = {
      jobPostId: requestedProfile.id,
    };
    let response;
    if (tab === "All Profiles") {
      payload.pageNo = page;
      payload.pageSize = size;
      payload.jobId = requestedProfile.id;
      delete payload.jobPostId;
      response = await getAllAdminBdCandReq(payload);
      if (response.data) {
        setLastpage(response?.data?.response?.last);
        if (response?.data?.response?.last === false) {
          setpageNo(page + 1);
        }
        let tabledata = { ...tableApiData };
        if (page === 0) {
          tabledata = { ...response.data.response };
        } else {
          tabledata.content = tabledata.content.concat(
            response.data.response.content
          );
        }

        setTableApiData({ ...tabledata });
      }
    } else if (tab === "Interested Profiles") {

      response = await getIntrestedProfileReq(requestedProfile.id, page, size);
      if (response.data) {
        setLastpage(response?.data?.response?.last);
        if (response?.data?.response?.last === false) {
          setpageNo(page + 1);
        }
        let tabledata = { ...tableApiData };
        if (page === 0) {
          tabledata = response.data.response;
        } else {
          tabledata.content = tabledata.content.concat(
            response.data.response.content
          );
        }

        setTableApiData(tabledata);
        // setReRenderTable(true)
      }
    } else if (tab === "Shared Profiles") {
      try {
        payload.pageNo = page;
        payload.pageSize = size;
        payload.candidateState = "SHARED";
        response = await getAdminBdCandReq(payload);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = { ...response.data.response };
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }
          setTableApiData({ ...tabledata });
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    } else if (tab === "Shortlisted Profiles") {
      try {
        payload.pageNo = page;
        payload.pageSize = size;
        payload.candidateState = "SHORTLISTED";
        response = await getAdminBdCandReq(payload);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = { ...response.data.response };
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData({ ...tabledata });
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    } else if (tab === "Scheduled Interviews") {
      try {
        payload.pageNo = page;
        payload.pageSize = size;
        payload.candidateState = "SCHEDULED";
        response = await getAdminBdCandReq(payload);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = { ...response.data.response };
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData({ ...tabledata });
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    } else if (tab === "Offered") {
      try {
        payload.pageNo = page;
        payload.pageSize = size;
        payload.candidateState = "OFFERED";
        response = await getAdminBdCandReq(payload);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = { ...response.data.response };
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData({ ...tabledata });
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    }
    setIsLoading(true);
  };
  // const handlescroll = (event) => {
  //   if (
  //     Math.ceil(event?.target?.scrollTop + event?.target?.clientHeight) ===
  //     event?.target?.scrollHeight
  //   ) {
  //     if (lastPage === false) {
  //       gettableData(selectedTab, pageno);
  //     }
  //   }
  // };
  const handlescroll = (event) => {
    const target = event?.target;
    if (!target) return;

    const scrolledToBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >= target.scrollHeight - 1;


    if (scrolledToBottom && !lastPage && !isSearching) {
      gettableData(selectedTab, pageno);
    } else if (scrolledToBottom && !lastPage && isSearching) {
      gettableDataSearched(searchedText, selectedTab, pageno);
    }
  };

  let handleTabs = (tab) => {
    if (tab !== selectedTab) {
      setpageNo(0);
      setLastpage(false);
      setIsLoading(true);

      // if (tab === "Interested Profiles") {

      //   gettableData("Interested Profiles", 0);
      //   setSelectedtab("Interested Profiles");
      // }
      // else
      // if (tab === "All Profiles") {
      //   gettableData("All Profiles", 0);
      //   setSelectedtab("All Profiles");
      // } else
      if (tab === "Shared Profiles") {
        gettableData("Shared Profiles", 0);
        setSelectedtab("Shared Profiles");
      } else if (tab === "Shortlisted Profiles") {
        gettableData("Shortlisted Profiles", 0);
        setSelectedtab("Shortlisted Profiles");
      } else if (tab === "Scheduled Interviews") {
        gettableData("Scheduled Interviews", 0);
        setSelectedtab("Scheduled Interviews");
      } else if (tab === "Offered") {
        gettableData("Offered", 0);
        setSelectedtab("Offered");
      }
      if (cardSearchInput?.current) {
        cardSearchInput.current.value = "";
      }
    }
  };
  const handleFilterSelection = (e, type, value) => {
    let selectedData = { ...selectedFilters };
    if (e.target.checked) {
      if (selectedData?.[type]?.length > 0) {
        selectedData?.[type].push(value);
      } else {
        selectedData[type] = [value];
      }
    } else {
      if (selectedData?.[type]?.length > 0) {
        let index = selectedData?.[type].indexOf(value);
        selectedData?.[type].splice(index, 1);
      }
    }
    setSelectedFilters({ ...selectedData });
  };
  let closeModal = (val) => {
    if (scheduleInterviewModal) {
      setScheduleInterviewModal(false);
    }
  };
  let handleCandidateBtn = async (data) => {
    let payload = {};
    let response;
    if (selectedTab === "Shared Profiles") {
      payload.candidateID = data.id;
      payload.jobID = requestedProfile.id;
      payload.bdID = user.uid;
      try {
        response = await shortlistCandReq(payload);
        if (response.data) {
          toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>Candidate shortlisted successfully</div>
            </>
          );
          gettableData(selectedTab, 0);
        }
      } catch (err) {
        console.error(err, "share api error");
      }
    } else if (selectedTab === "Scheduled Interviews") {
      setSelectedCandidate({ ...data });
      setScheduleInterviewModal(true);
    }
  };
  // let handleCandidateBtn = async (data, type) => {
  //   let payload = {};
  //   let response;
  //   setSelectedCandidate({ ...data });
  //   if (type === "All Profiles") {
  //     payload.bdId = user.uid;
  //     payload.candidateID = data.id;
  //     payload.jobID = requestedProfile.id;
  //     try {
  //       response = await shareCandReq(payload);
  //       if (response.data) {
  //         toast.success(
  //           <>
  //             <div className="toast-heading">Success</div>
  //             <div>Candidate shared successfully</div>
  //           </>
  //         );
  //         gettableData(type, 0);
  //       }
  //     } catch (err) {
  //       console.error(err, "share api error");
  //     }
  //   } else if (type === "Shared Profiles") {
  //   } else if (type === "Shortlisted Profiles") {
  //     setScheduleInterviewModal(true);
  //   } else if (type === "Scheduled Interviews") {
  //     setScheduleInterviewModal(true);
  //   } else if (type === "Offered") {
  //   }
  // };
  let actionele = (data) => {
    return (
      <div className="hire-now-btn">
        {selectedTab === "Offered" && (
          <span className="candidate-status">Offered</span>
        )}
        {(selectedTab === "Scheduled Interviews" ||
          selectedTab === "Shared Profiles" ||
          selectedTab === "Shortlisted Profiles") && (
            <button
              className={`datatrimmer pl-2 pr-2 ${selectedTab === "Shortlisted Profiles" ? "opacity-50" : ""
                }`}
              onClick={() => {
                handleCandidateBtn(data);
              }}
              disabled={selectedTab === "Shortlisted Profiles"}
              title={
                selectedTab === "Shared Profiles"
                  ? "Shortlist"
                  : selectedTab === "Shortlisted Profiles"
                    ? "Shortlisted"
                    : selectedTab === "Scheduled Interviews"
                      ? "Update Interview"
                      : ""
              }
            >
              {selectedTab === "Shared Profiles"
                ? "Shortlist"
                : selectedTab === "Shortlisted Profiles"
                  ? "Shortlisted"
                  : selectedTab === "Scheduled Interviews"
                    ? "Update Interview"
                    : ""}
            </button>
          )}
      </div>
    );
  };

  const resetSearch = () => {
    setIsSearching(false);
    setpageNo(0);
    setLastpage(false);
  };
  let gettableDataSearched = async (e, tab, page) => {
    let payload = {
      jobPostId: requestedProfile.id,
    };
    let response;
    if (tab === "Interested Profiles") {

      setIsSearching(true); // Set the flag to indicate search
      setIsLoading(true);
      setSearchedText(e);
      response = await intrestedCandidateSearch(requestedProfile.id, page, size, e);
      if (response.data) {
        setLastpage(response?.data?.response?.last);
        if (response?.data?.response?.last === false) {
          setpageNo(page + 1);
        }
        let tabledata = { ...tableApiData };
        if (page === 0) {
          tabledata = response.data.response;
        } else {
          tabledata.content = tabledata.content.concat(
            response.data.response.content
          );
        }

        setTableApiData(tabledata);
        // setReRenderTable(true)
      }
    }
    else if (tab === "All Profiles") {
      payload.pageNo = page;
      payload.pageSize = size;
      payload.jobId = requestedProfile.id;
      delete payload.jobPostId;
      setIsSearching(true); // Set the flag to indicate search
      setIsLoading(true);
      setSearchedText(e);
      response = await AllProfileSearch(requestedProfile.id, e, page, size);
      if (response.data) {
        setLastpage(response?.data?.response?.last);
        if (response?.data?.response?.last === false) {
          setpageNo(page + 1);
        }
        let tabledata = { ...tableApiData };
        if (page === 0) {
          tabledata = response.data.response;
        } else {
          tabledata.content = tabledata.content.concat(
            response.data.response.content
          );
        }

        setTableApiData(tabledata);

      }
    }
    else if (tab === "Shared Profiles") {
      try {
        payload.pageNo = page;
        payload.pageSize = size;
        payload.candidateState = "SHARED";
        response = await ByStateSearch(e, payload);
        setIsSearching(true); // Set the flag to indicate search
        setIsLoading(true);
        setSearchedText(e);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    }
    else if (tab === "Shortlisted Profiles") {
      try {
        payload.pageNo = page;
        payload.pageSize = size;
        payload.candidateState = "SHORTLISTED";
        response = await ByStateSearch(e, payload);
        setIsSearching(true); // Set the flag to indicate search
        setIsLoading(true);
        setSearchedText(e);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    }
    else if (tab === "Interview Scheduled") {
      try {
        payload.pageNo = page;
        payload.pageSize = size;
        payload.candidateState = "SCHEDULED";
        response = await ByStateSearch(e, payload);
        setIsSearching(true); // Set the flag to indicate search
        setIsLoading(true);
        setSearchedText(e);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    }
    else if (tab === "Offered") {
      try {
        payload.pageNo = page;
        payload.pageSize = size;
        payload.candidateState = "OFFERED";
        response = await ByStateSearch(e, payload);
        setIsSearching(true); // Set the flag to indicate search
        setIsLoading(true);
        setSearchedText(e);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    }

    setIsLoading(true);
  };

  useEffect(() => {
    gettableData("Shared Profiles", 0);
    setIsLoading(false);
  }, []);

  const handelMoreBtn = (data, type) => {
    setSelectedJob({ ...data });
    if (type === "Details") {
      setPostDetails(true);
    }
  };
  let closeModalBdDetails = (val) => {
    setPostDetails(false);
  };
  return (
    <>
      {postJobDetails ? (
        <AdminJobPostDetails
          jobModalHeight="355px"
          closeModal={closeModalBdDetails}
          selectedJob={selectedJob}
        />
      ) : editJobPost ?
        <BdJobPostModal
          toast={toast}
          selectedJob={requestedProfile}
          // getJobList={getJobList}
          mode={"edit"}
          from={"sharedProfile"}
          closeModal={editJobPost}
          setEditJobPost={setEditJobPost}
        // setEdirJobPost={setEdirJobPost}
        // getJobListById={getJobListById}
        /> : (
          <div className="bdJobPostContainer">
            <div className="cnclBtnDiv">
              <button
                className="cancelBtn"
                onClick={() => {
                  navigate("/bd/job-post");
                }}
              >
                Back
              </button>
            </div>
            <div className="job-card-sharedProfile my-2">
              <BdJobCard
                job={requestedProfile}
                handelMoreBtn={handelMoreBtn}
                showShareBtn="false"
                from="sharedProfile"
                setSelectedJob={setSelectedJob}
                editJobPost={editJobPost}
                setEditJobPost={setEditJobPost}
              />
            </div>
            <div className="client-interview-candidate-section adminSharedProfile-section mx-3 w-full home-component-display mt-2.5 overflow-auto">
              <div className="componentHeader flex">
                {/* <div
                className={`${
                  selectedTab === "Interested Profiles"
                    ? "selected-tabletitle text-lg"
                    : "tabletitle text-lg"
                } cursor-pointer`}
                onClick={() => {
                  handleTabs("Interested Profiles");
                }}
              >
                <span>Interested Profiles</span>
              </div> */}
                {/* <div
                className={`${
                  selectedTab === "All Profiles"
                    ? "selected-tabletitle"
                    : "tabletitle"
                } cursor-pointer`}
                onClick={() => {
                  handleTabs("All Profiles");
                }}
              >
                <span>All Profiles</span>
              </div> */}
                <div
                  className={`${selectedTab === "Shared Profiles"
                    ? "selected-tabletitle"
                    : "tabletitle"
                    } cursor-pointer`}
                  onClick={() => {
                    handleTabs("Shared Profiles");
                  }}
                >
                  <span>Shared Profiles</span>
                </div>
                <div
                  className={`${selectedTab === "Shortlisted Profiles"
                    ? "selected-tabletitle"
                    : "tabletitle"
                    } cursor-pointer`}
                  onClick={() => {
                    handleTabs("Shortlisted Profiles");
                  }}
                >
                  <span>Shortlisted Profiles</span>
                </div>
                <div
                  className={`${selectedTab === "Scheduled Interviews"
                    ? "selected-tabletitle"
                    : "tabletitle"
                    } cursor-pointer`}
                  onClick={() => {
                    handleTabs("Scheduled Interviews");
                  }}
                >
                  <span>Scheduled Interviews</span>
                </div>
                <div
                  className={`${selectedTab === "Offered"
                    ? "selected-tabletitle"
                    : "tabletitle"
                    } cursor-pointer`}
                  onClick={() => {
                    handleTabs("Offered");
                  }}
                >
                  <span>Offered</span>
                </div>
                <div className="clickables">
                  <CandidateColumnOption
                    contextMenuData={contextMenuData}
                    func={(event) => {
                      handelColumnsDispaly(event);
                    }}
                    hiddenColumnsData={hiddenColumnsData}
                  />
                </div>
              </div>
              <div
                className={`hireTable ${openFilter ? "table-with-filter" : null
                  } `}
              >
                <input
                  className="table-search-input-coordinator col-span-2 "
                  onChange={(e) => {
                    if (e.target.value.trim() === "") {
                      resetSearch();
                      gettableData(selectedTab, pageno);
                    } else {
                      gettableDataSearched(e.target.value, selectedTab, 0);
                    }
                  }}
                  ref={cardSearchInput}
                  type="text"
                  placeholder="Search"
                />
                <CandidateTable
                  isLoading={isLoading}
                  tableApiData={tableApiData}
                  actionele={actionele}
                  actionColumn={selectedTab !== "Interested Profiles" && true}
                  handlescroll={handlescroll}
                  actionColumnWidth={"15%"}
                  profileColumn={"name"}
                  hiddenColumnsData={hiddenColumnsData}
                  selectedTab={selectedTab}
                  showVideo={selectedTab !== "Interested Profiles" && "both"}
                  tableType={"bde-table"}
                />
              </div>
              {openFilter && (
                <div className="hire-filter">
                  <div className="filter-header">
                    <span>Filter By</span>
                    <span className="apply-btn">
                      <button
                        onClick={() => {
                          setOpenFilter(!openFilter);
                        }}
                      >
                        Apply
                      </button>
                    </span>
                  </div>
                  {filterOptions.map((data, index) => {
                    return (
                      <div className="individual-type">
                        <div>{data.type}</div>
                        {data.values.map((value) => {
                          return (
                            <div className="typeData">
                              <Checkbox
                                color="primary"
                                value={value}
                                checked={selectedFilters?.[data.type]?.includes(
                                  value
                                )}
                                onChange={(e) => {
                                  handleFilterSelection(e, data.type, value);
                                }}
                                className="root-check-all"
                              />
                              <span>{value}</span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {scheduleInterviewModal && (
              <InterviewModals
                toast={toast}
                gettableData={gettableData}
                candidate={selectCandidate}
                job={requestedProfile}
                header={
                  selectedTab === "Shortlisted Profiles"
                    ? "Schedule Interview"
                    : "Update Interview Details"
                }
                selectedTab={selectedTab}
                closeModal={closeModal}
              />
            )}
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              className={"tost-msg-stylinh"}
              draggable={false}
              theme="light"
            />
          </div>
        )}
    </>
  );
};

export default ShareProfile;
