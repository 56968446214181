import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { SelectDropdown } from "../../landing-page/common/select-dropdown";
import MastersDegree from "./edu-forms/MastersDegree";
import Degree from "./edu-forms/Degree";
import DiplomaPucIti from "./edu-forms/DiplomaPucIti";
import Sslc from "./edu-forms/Sslc";
import { stringToDropdownValue, dropdownLabelFinder } from "../../../util/common-utils";
import {
  getAllCollegesReq,
  getAllUniversitiesReq,
  getAllCitiesReq,
  getAllCountriesReq,
  getAllStatesReq,
  getAllTitlesReq,
  getAllStreamsReq,
} from "../../../api/api-services";

const EducationDetailsForm = (props) => {
  const [dropDownData, setDropDownData] = useState({
    mdCollegeName: [],
    mdUniversity: [],
    mdTitle: [],
    mdStream: [],
    mdCountry: [],
    mdState: [],
    mdCity: [],
    mdCourseType: [
      { label: "Part Time", value: "PART_TIME" },
      { label: "Full Time", value: "FULL_TIME" },
    ],
    mdGradeType: [
      { label: "CGPA", value: "CGPA" },
      { label: "Percentage", value: "Percentage" },
    ],
    dgCollegeName: [],
    dgUniversity: [],
    dgTitle: [],
    dgStream: [],
    dgCountry: [],
    dgState: [],
    dgCity: [],
    dgCourseType: [
      { label: "Part Time", value: "PART_TIME" },
      { label: "Full Time", value: "FULL_TIME" },
    ],
    dgGradeType: [
      { label: "CGPA", value: "CGPA" },
      { label: "Percentage", value: "Percentage" },
    ],
    dpCollegeName: [],
    dpboardName: [],
    dpStream: [],
    dpCourseType: [
      { label: "Part Time", value: "PART_TIME" },
      { label: "Full Time", value: "FULL_TIME" },
    ],
    dpGradeType: [
      { label: "CGPA", value: "CGPA" },
      { label: "Percentage", value: "Percentage" },
    ],
    pucGradeType: [
      { label: "CGPA", value: "CGPA" },
      { label: "Percentage", value: "Percentage" },
    ],
    itiCollegeName: [],
    itiboardName: [],
    itiStream: [],
    itiCourseType: [
      { label: "Part Time", value: "PART_TIME" },
      { label: "Full Time", value: "FULL_TIME" },
    ],
    itiGradeType: [
      { label: "CGPA", value: "CGPA" },
      { label: "Percentage", value: "Percentage" },
    ],
    sslcboardName: [],
    sslcGradeType: [
      { label: "CGPA", value: "CGPA" },
      { label: "Percentage", value: "Percentage" },
    ],
  });
  const [highestEduData, setHighestEduData] = useState([
    { label: "Master's Degree", value: "Master's Degree" },
    { label: "Degree", value: "Degree" },
    { label: "Diploma/PUC/ITI", value: "Diploma/PUC/ITI" },
    { label: "SSLC (10th)", value: "SSLC (10th)" },
  ]);
  const [selectedHighestEdu, setSelectedHighestEdu] = useState([]);
  const [selectedPreDegree, setSelectedPreDegree] = useState([]);
  const [selectedDropdownData, setSelectedDropdownData] = useState({
    mdCollegeName: [],
    mdUniversity: [],
    mdTitle: [],
    mdStream: [],
    mdCountry: [],
    mdState: [],
    mdCity: [],
    mdCourseType: [],
    mdGradeType: [],
    dgCollegeName: [],
    dgUniversity: [],
    dgTitle: [],
    dgStream: [],
    dgCountry: [],
    dgState: [],
    dgCity: [],
    dgCourseType: [],
    dgGradeType: [],
    dpCollegeName: [],
    dpboardName: [],
    dpStream: [],
    dpCourseType: [],
    dpGradeType: [],
    pucGradeType: [],
    itiCollegeName: [],
    itiboardName: [],
    itiStream: [],
    itiCourseType: [],
    itiGradeType: [],
    sslcGradeType: [],
  });
  let initialSchema = {
    highestEducation: yup.string().trim().required("Please Enter your Educational Details"),
  };
  let initValues = {
    highestEducation: props.data?.highestEducation
      ? props.data?.highestEducation
      : "",
  };
  const [validationSchema, setValidationSchema] = useState(
    yup.object(initialSchema)
  );
  const [currentSchema, setcurrentSchema] = useState({ ...initialSchema });
  const [initialValues, setInitialValues] = useState({ ...initValues });

  const handleDropdownChange = (data, type, keysToBeCleared) => {
    let schema = { ...currentSchema };
    if (Array.isArray(keysToBeCleared) && Array.isArray(data)) {
      if ( keysToBeCleared[0] === 'dgCollegeName' && data[0].value !== "Others" && formik.values.dgUniversityName || formik.errors?.dgUniversityName ) {
        delete schema.dgUniversityName;
        // formik.values.dgUniversityName = "";
        setcurrentSchema(schema)
     }
     if ( keysToBeCleared[0] === 'dgStream' && data[0].value !== "Others" && formik.values.dgTitleName) {
       delete schema.dgTitleName;
       setcurrentSchema(schema)
      }
      if ( keysToBeCleared[0] === 'mdCollegeName' && data[0].value !== "Others" && formik.values.mdUniversityName) {
        delete schema.mdCollegeName;
        setcurrentSchema(schema)
      }
      if ( keysToBeCleared[0] === 'mdStream' && data[0].value !== "Others" && formik.values.mdTitleName) {
        delete schema.mdStream;
        setcurrentSchema(schema)

      }
    }
    if (type === "mdGradeType") {
      formik.values.mdGrade = ""
    }
    if (type === "dgGradeType") {  
      formik.values.dgGrade = ""
    }
    if (type === "dpGradeType") {
      formik.values.dpGrade = ""
    }
    if (type === "pucGradeType") {
      formik.values.pucGrade = ""
    }
    if (type === "itiGradeType") {
      formik.values.itiGrade = ""
    }
    if (type === "sslcGradeType") {
      formik.values.sslcGrade = ""
    }
    let allSelectedData = { ...selectedDropdownData };
    allSelectedData[type] = [...data];
    keysToBeCleared?.forEach((element) => {
      formik.values[element] = "";
      allSelectedData[element] = [];
    });
    setSelectedDropdownData({ ...allSelectedData });
    formik.setFieldValue(type, data?.[0]?.value ? data?.[0]?.value : "");
  };
  const handleOthersTypeDropdownChange = (data, key) => {
    formik.values[key] = data.value;
    let updatedSchema = { ...currentSchema };
    let updateInitialValues = { ...formik.values };
    let  obj =  {};
    let initObj = {};
    
    if ( data.value === "Others") {
      if(key === "mdUniversity") {
        obj.mdUniversityName = yup.string().trim().required("University name is required");
        initObj.mdUniversityName = "";
      }
      if(key === "mdTitle") {
        obj.mdTitleName = yup.string().trim().required("Course name is required");
        initObj.mdTitleName = "";
      }
      if(key === "dgUniversity") {
        obj.dgUniversityName = yup.string().trim().required("University name is required");
        initObj.dgUniversityName = "";
      } 
      if(key === "dgTitle") {
        obj.dgTitleName = yup.string().trim().required("Course name is required");
        initObj.dgTitleName = "";
      }
    }
    updatedSchema = {
      ...updatedSchema,
      ...obj,
    };
    updateInitialValues = {
      ...updateInitialValues,
      ...initObj,
    };
    setcurrentSchema({ ...updatedSchema });
    setInitialValues({ ...updateInitialValues });
    setValidationSchema(yup.object(updatedSchema));
  }
  const handleDiplomaBoardChange = (data) => {
    let updatedSchema = { ...currentSchema };
    let updateInitialValues = { ...formik.values };
    updateInitialValues.dpboardName = data.value;
    updatedSchema = {
      ...updatedSchema,
      ...buildSchemaWithvalue("diploma_others"),
    };
    updateInitialValues = {
      ...updateInitialValues,
      ...buildInitialValues("diploma_others", updateInitialValues),
    };
    setcurrentSchema({ ...updatedSchema });
    setInitialValues({ ...updateInitialValues });
    setValidationSchema(yup.object(updatedSchema));
  }
  const handleItiBoardChange = (data) => {
    let updatedSchema = { ...currentSchema };
    let updateInitialValues = { ...formik.values };
    updateInitialValues.itiboardName = data.value;
    updatedSchema = {
      ...updatedSchema,
      ...buildSchemaWithvalue("iti_others"),
    };
    updateInitialValues = {
      ...updateInitialValues,
      ...buildInitialValues("iti_others", updateInitialValues),
    };
    setcurrentSchema({ ...updatedSchema });
    setInitialValues({ ...updateInitialValues });
    setValidationSchema(yup.object(updatedSchema));
  }
  const handleEducationDataForm = (val) => {
    let tempObj = {};
    tempObj["sslc"] = {
      yearOfPassing: val.sslcyearOfPassing,
      yearOfJoining: val.sslcyearOfJoining,
    };
    if(val.sslcGradeType === "Percentage"){
      tempObj.sslc.percentage = val.sslcGrade ? val.sslcGrade : "";
      tempObj.sslc.cgpa = "";
    } else {
      tempObj.sslc.cgpa = val.sslcGrade ? val.sslcGrade : "";
      tempObj.sslc.percentage = "";
    }
    if (selectedPreDegree.includes("iti")) {
      tempObj["iti"] = {
        collegeName: val.itiCollegeName,
        boardName: val.itiboardName,
        stream: val.itiStream,
        yearOfPassing: val.itiyearOfPassing,
        yearOfJoining: val.itiyearOfJoining,
        courseType: val.itiCourseType,
      };
      if (tempObj["iti"].boardName === "Others") {
        tempObj["iti"].board = val.itiBoard;
      }
      if(val.itiGradeType === "Percentage"){
        tempObj.iti.percentage = val.itiGrade ? val.itiGrade : "";
        tempObj.iti.cgpa = "";
      } else {
        tempObj.iti.cgpa = val.itiGrade ? val.itiGrade : "";
        tempObj.iti.percentage = "";
      }
    }
    if (selectedPreDegree.includes("twelfth")) {
      tempObj["twelfth"] = {
        yearOfPassing: val.pucyearOfPassing,
        yearOfJoining: val.pucyearOfJoining,
      };
      if(val.pucGradeType === "Percentage"){
        tempObj.twelfth.percentage = val.pucGrade ? val.pucGrade : "";
        tempObj.twelfth.cgpa = "";
      } else {
        tempObj.twelfth.cgpa = val.pucGrade ? val.pucGrade : "";
        tempObj.twelfth.percentage = "";
      }
    }
    if (selectedPreDegree.includes("diploma")) {
      tempObj["diploma"] = {
        collegeName: val.dpCollegeName,
        boardName: val.dpboardName,
        stream: val.dpStream,
        yearOfPassing: val.dpyearOfPassing,
        yearOfJoining: val.dpyearOfJoining,
        courseType: val.dpCourseType,
      };
      if (tempObj["diploma"].boardName === "Others") {
        tempObj["diploma"].board = val.dpBoard;
      }
      if(val.dpGradeType === "Percentage"){
        tempObj.diploma.percentage = val.dpGrade ? val.dpGrade : "";
        tempObj.diploma.cgpa = "";
      } else {
        tempObj.diploma.cgpa = val.dpGrade ? val.dpGrade : "";
        tempObj.diploma.percentage = "";
      }
    }
    tempObj["degree"] = {
      collegeName: val.dgCollegeName,
      university: val.dgUniversity,
      title: val.dgTitle,
      stream: val.dgStream,
      address: {
        country: val.dgCountry,
        state: val.dgState,
        city: val.dgCity,
      },
      yearOfPassing: val.dgyearOfPassing,
      yearOfJoining: val.dgyearOfJoining,
      courseType: val.dgCourseType,
    };
    if (tempObj["degree"].university === "Others") {
      tempObj["degree"].universityName = val.dgUniversityName;
    }
    if (tempObj["degree"].title === "Others") {
      tempObj["degree"].titleName = val.dgTitleName;
    }
    if (val.dgGradeType === "Percentage") {
      tempObj.degree.percentage = val.dgGrade ? val.dgGrade : "";
      tempObj.degree.cgpa = "";
    } else {
      tempObj.degree.cgpa = val.dgGrade ? val.dgGrade : "";
      tempObj.degree.percentage = "";
    }

    tempObj["masterDegree"] = {
      collegeName: val.mdCollegeName,
      university: val.mdUniversity,
      title: val.mdTitle,
      stream: val.mdStream,
      address: {
        country: val.mdCountry,
        state: val.mdState,
        city: val.mdCity,
      },
      yearOfPassing: val.mdyearOfPassing,
      yearOfJoining: val.mdyearOfJoining,
      courseType: val.mdCourseType,
    };
    if (tempObj["masterDegree"].university === "Others") {
      tempObj["masterDegree"].universityName = val.mdUniversityName;
    }
    if (tempObj["masterDegree"].title === "Others") {
      tempObj["masterDegree"].titleName = val.mdTitleName;
    }
    if(val.mdGradeType === "Percentage"){
      tempObj.masterDegree.percentage = val.mdGrade ? val.mdGrade : "";
      tempObj.masterDegree.cgpa = "";
    } else {
      tempObj.masterDegree.cgpa = val.mdGrade ? val.mdGrade : "";
      tempObj.masterDegree.percentage = "";
    }
    tempObj["highestEducation"] = val.highestEducation;
    tempObj["preDegree"] = val.preDegree;
    return { educationDetails: tempObj };
  };
  const buildSchemaWithvalue = (datatype) => {
    if (datatype === "Master's Degree" || datatype === "MasterDegree_others") {
      let tempObj = {
        mdCollegeName: yup.string().trim().required("College Name is required"),
        mdUniversity: yup.string().trim().required("University is required"),
        mdTitle: yup.string().trim().required("Course is required"),
        mdStream: yup.string().trim().required("Stream is required"),
        mdCountry: yup.string().trim().required("Country is required"),
        mdState: yup.string().trim().required("State is required"),
        mdCity: yup.string().trim().required("City is required"),
        mdGradeType: yup
        .string()
        .required("Please select Percentage / CGPA"),
        mdGrade: yup
          .number()
          .required("Percentage/CGPA is required")
          .test('grade-validation', 'Select Percentage/CGPA', function(value) {
            const mdGradeType = this.parent.mdGradeType;

            if (mdGradeType === 'CGPA') {
              if (value >= 1 && value <= 10) {
                const decimalCount = value.toString().split('.')[1]?.length || 0;
                if (decimalCount <= 1) {
                  return true;
                } else {
                  return this.createError({
                    path: 'mdGrade',
                    message: 'CGPA can have only one decimal value',
                  });
                }
              } else {
                return this.createError({
                  path: 'mdGrade',
                  message: 'CGPA must be between 1 and 10',
                });
              }
            } else if (mdGradeType === 'Percentage') {
              if (value >= 1 && value <= 100) {
                const decimalCount = value.toString().split('.')[1]?.length || 0;
                if (decimalCount <= 2) {
                  return true;
                } else {
                  return this.createError({
                    path: 'mdGrade',
                    message: 'Percentage can have at most two decimal values',
                  });
                }
              } else {
                return this.createError({
                  path: 'mdGrade',
                  message: 'Percentage must be between 1 and 100',
                });
              }
            }
            return false;
          }),
        mdyearOfJoining: yup
          .number()
          .required("Year of Joining is required")
          .typeError('Year must be only digits')
          .min(1920, "Enter the valid year")
          .max(9999, "Year must be exactly 4 digits")
          .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
        ,
        mdyearOfPassing: yup
          .number()
          .required("Year of Passing is required")
          .typeError('Year must be only digits')
          .min(1920, "Enter the valid year")
          .max(9999, "Year must be exactly 4 digits")
          .test("is-year-after-joining", "Year of Passing must be after Year of Joining", function (value) {
            const joiningYear = parseInt(this.resolve(yup.ref("mdyearOfJoining"), this));
            const passingYear = parseInt(value);
            return isNaN(joiningYear) || isNaN(passingYear) || passingYear > joiningYear;
          })
          .test("is-year-before-joining", "Year of Joining must be before Year of Passing", function (value) {
            const joiningYear = parseInt(this.resolve(yup.ref("mdyearOfJoining"), this));
            const passingYear = parseInt(value);
            return isNaN(joiningYear) || isNaN(passingYear) || joiningYear < passingYear;
          }),
        mdCourseType: yup.string().trim().required("Course Type is required"),
      };
      if (props.data?.masterDegree?.university === "Others") {
        tempObj.mdUniversityName = yup.string().trim().required("University name is required");
      }
      if (props.data?.masterDegree?.title === "Others") {
        tempObj.mdTitleName = yup.string().trim().required("Course name is required");
      }
      return tempObj;
    } else if (datatype === "Degree" || datatype === "Degree_others") {
      let tempObj = {
        dgCollegeName: yup.string().trim().required("College Name is required"),
        dgUniversity: yup.string().trim().required("University is required"),
        dgTitle: yup.string().trim().required("Course is required"),
        dgStream: yup.string().trim().required("Stream is required"),
        dgCountry: yup.string().trim().required("Country is required"),
        dgState: yup.string().trim().required("State is required"),
        dgCity: yup.string().trim().required("City is required"),
        dgGradeType: yup.string().trim().required("Please select Percentage / CGPA"),
        dgGrade: yup
          .number()
          .required("Percentage/CGPA is required")
          .test('grade-validation', 'Select Percentage/CGPA', function(value) {
            const dgGradeType = this.parent.dgGradeType;

            if (dgGradeType === 'CGPA') {
              if (value >= 1 && value <= 10) {
                const decimalCount = value.toString().split('.')[1]?.length || 0;
                if (decimalCount <= 1) {
                  return true;
                } else {
                  return this.createError({
                    path: 'dgGrade',
                    message: 'CGPA can have only one decimal value',
                  });
                }
              } else {
                return this.createError({
                  path: 'dgGrade',
                  message: 'CGPA must be between 1 and 10',
                });
              }
            } else if (dgGradeType === 'Percentage') {
              if (value >= 1 && value <= 100) {
                const decimalCount = value.toString().split('.')[1]?.length || 0;
                if (decimalCount <= 2) {
                  return true;
                } else {
                  return this.createError({
                    path: 'dgGrade',
                    message: 'Percentage can have at most two decimal values',
                  });
                }
              } else {
                return this.createError({
                  path: 'dgGrade',
                  message: 'Percentage must be between 1 and 100',
                });
              }
            }
            return false;
          }),
        dgyearOfJoining: yup
          .number()
          .required("Year of Joining is required")
          .typeError('Year must be only digits')
          .min(1920, "Enter the valid year")
          .max(9999, "Year must be exactly 4 digits")
          .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)),
        dgyearOfPassing: yup
          .number()
          .required("Year of Passing is required")
          .typeError('Year must be only digits')
          .min(1920, "Enter the valid year")
          .max(9999, "Year must be exactly 4 digits")
          .test("is-year-after-joining", "Year of Passing must be after Year of Joining", function (value) {
            const joiningYear = parseInt(this.resolve(yup.ref("dgyearOfJoining"), this));
            const passingYear = parseInt(value);
            return isNaN(joiningYear) || isNaN(passingYear) || passingYear > joiningYear;
          })
          .test("is-year-before-joining", "Year of Joining must be before Year of Passing", function (value) {
            const joiningYear = parseInt(this.resolve(yup.ref("dgyearOfJoining"), this));
            const passingYear = parseInt(value);
            return isNaN(joiningYear) || isNaN(passingYear) || joiningYear < passingYear;
          }),
        dgCourseType: yup.string().trim().required("Course Type is required"),
      }
      if (props.data?.degree?.university === "Others") {
        tempObj.dgUniversityName = yup.string().trim().required("University name is required");
      }
      if (props.data?.degree?.title === "Others") {
        tempObj.dgTitleName = yup.string().trim().required("Course name is required");
      }
      return tempObj;
    } else if (datatype === "Diploma/PUC/ITI") {
      return { preDegree: yup.array().min(1, "PreDegree is required") };
    } else if (datatype === "SSLC (10th)") {
      return {
        sslcGradeType: yup.string().trim().required("Please select Percentage / CGPA"),
        sslcGrade: yup
          .number()
          .required("Percentage/CGPA is required")
          .test('grade-validation', 'Select Percentage/CGPA', function(value) {
            const sslcGradeType = this.parent.sslcGradeType;

            if (sslcGradeType === 'CGPA') {
              if (value >= 1 && value <= 10) {
                const decimalCount = value.toString().split('.')[1]?.length || 0;
                if (decimalCount <= 1) {
                  return true;
                } else {
                  return this.createError({
                    path: 'sslcGrade',
                    message: 'CGPA can have only one decimal value',
                  });
                }
              } else {
                return this.createError({
                  path: 'sslcGrade',
                  message: 'CGPA must be between 1 and 10',
                });
              }
            } else if (sslcGradeType === 'Percentage') {
              if (value >= 1 && value <= 100) {
                const decimalCount = value.toString().split('.')[1]?.length || 0;
                if (decimalCount <= 2) {
                  return true;
                } else {
                  return this.createError({
                    path: 'sslcGrade',
                    message: 'Percentage can have at most two decimal values',
                  });
                }
              } else {
                return this.createError({
                  path: 'sslcGrade',
                  message: 'Percentage must be between 1 and 100',
                });
              }
            }
            return false;
          }),
        sslcyearOfJoining: yup
          .number()
          .required("Year of Joining is required")
          .typeError('Year must be only digits')
          .min(1920, "Enter the valid year")
          .max(9999, "Year must be exactly 4 digits")
          .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)),
        sslcyearOfPassing: yup
          .number()
          .required("Year of Passing is required")
          .typeError('Year must be only digits')
          .min(1920, "Enter the valid year")
          .max(9999, "Year must be exactly 4 digits")
          .test("is-year-after-joining", "Year of Passing must be after Year of Joining", function (value) {
            const joiningYear = parseInt(this.resolve(yup.ref("sslcyearOfJoining"), this));
            const passingYear = parseInt(value);
            return isNaN(joiningYear) || isNaN(passingYear) || passingYear >= joiningYear;
          })
          .test("is-year-before-joining", "Year of Joining must be before Year of Passing", function (value) {
            const joiningYear = parseInt(this.resolve(yup.ref("sslcyearOfJoining"), this));
            const passingYear = parseInt(value);
            return isNaN(joiningYear) || isNaN(passingYear) || joiningYear <= passingYear;
          }),
      };
    } else if (datatype === "diploma" || datatype === "diploma_others") {
      let tempObj = {
        dpCollegeName: yup.string().trim().required("College Name is required"),
        dpboardName: yup.string().trim().required("Board name is required"),
        dpStream: yup.string().trim().required("Stream is required"),
        dpGradeType: yup.string().trim().required("Please select Percentage / CGPA"),
        dpGrade: yup
          .number()
          .required("Percentage/CGPA is required")
          .test('grade-validation', 'Select Percentage/CGPA', function(value) {
            const dpGradeType = this.parent.dpGradeType;

            if (dpGradeType === 'CGPA') {
              if (value >= 1 && value <= 10) {
                const decimalCount = value.toString().split('.')[1]?.length || 0;
                if (decimalCount <= 1) {
                  return true;
                } else {
                  return this.createError({
                    path: 'dpGrade',
                    message: 'CGPA can have only one decimal value',
                  });
                }
              } else {
                return this.createError({
                  path: 'dpGrade',
                  message: 'CGPA must be between 1 and 10',
                });
              }
            } else if (dpGradeType === 'Percentage') {
              if (value >= 1 && value <= 100) {
                const decimalCount = value.toString().split('.')[1]?.length || 0;
                if (decimalCount <= 2) {
                  return true;
                } else {
                  return this.createError({
                    path: 'dpGrade',
                    message: 'Percentage can have at most two decimal values',
                  });
                }
              } else {
                return this.createError({
                  path: 'dpGrade',
                  message: 'Percentage must be between 1 and 100',
                });
              }
            }
            return false;
          }),
        dpyearOfJoining: yup
          .number()
          .required("Year of Joining is required")
          .typeError('Year must be only digits')
          .min(1920, "Enter the valid year")
          .max(9999, "Year must be exactly 4 digits")
          .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)),
        dpyearOfPassing: yup
          .number()
          .required("Year of Passing is required")
          .typeError('Year must be only digits')
          .min(1920, "Enter the valid year")
          .max(9999, "Year must be exactly 4 digits")
          .test("is-year-after-joining", "Year of Passing must be after Year of Joining", function (value) {
            const joiningYear = parseInt(this.resolve(yup.ref("dpyearOfJoining"), this));
            const passingYear = parseInt(value);
            return isNaN(joiningYear) || isNaN(passingYear) || passingYear > joiningYear;
          })
          .test("is-year-before-joining", "Year of Joining must be before Year of Passing", function (value) {
            const joiningYear = parseInt(this.resolve(yup.ref("dpyearOfJoining"), this));
            const passingYear = parseInt(value);
            return isNaN(joiningYear) || isNaN(passingYear) || joiningYear < passingYear;
          }),
        dpCourseType: yup.string().trim().required("Course Type is required"),
      };
      if (datatype === "diploma_others") {
        tempObj.dpBoard = yup.string().trim().required("Board Name is required");
      }
      return tempObj;
    } else if (datatype === "twelfth") {
      return {
        pucGradeType: yup.string().trim().required("Please select Percentage / CGPA"),
        pucGrade: yup
          .number()
          .required("Percentage/CGPA is required")
          .test('grade-validation', 'Select Percentage/CGPA', function(value) {
            const pucGradeType = this.parent.pucGradeType;

            if (pucGradeType === 'CGPA') {
              if (value >= 1 && value <= 10) {
                const decimalCount = value.toString().split('.')[1]?.length || 0;
                if (decimalCount <= 1) {
                  return true;
                } else {
                  return this.createError({
                    path: 'pucGrade',
                    message: 'CGPA can have only one decimal value',
                  });
                }
              } else {
                return this.createError({
                  path: 'pucGrade',
                  message: 'CGPA must be between 1 and 10',
                });
              }
            } else if (pucGradeType === 'Percentage') {
              if (value >= 1 && value <= 100) {
                const decimalCount = value.toString().split('.')[1]?.length || 0;
                if (decimalCount <= 2) {
                  return true;
                } else {
                  return this.createError({
                    path: 'pucGrade',
                    message: 'Percentage can have at most two decimal values',
                  });
                }
              } else {
                return this.createError({
                  path: 'pucGrade',
                  message: 'Percentage must be between 1 and 100',
                });
              }
            }
            return false;
          }),
        pucyearOfJoining: yup
          .number()
          .required("Year of Joining is required")
          .typeError('Year must be only digits')
          .min(1920, "Enter the valid year")
          .max(9999, "Year must be exactly 4 digits")
          .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)),
        pucyearOfPassing: yup
          .number()
          .required("Year of Passing is required")
          .typeError('Year must be only digits')
          .min(1920, "Enter the valid year")
          .max(9999, "Year must be exactly 4 digits")
          .test("is-year-after-joining", "Year of Passing must be after Year of Joining", function (value) {
            const joiningYear = parseInt(this.resolve(yup.ref("pucyearOfJoining"), this));
            const passingYear = parseInt(value);
            return isNaN(joiningYear) || isNaN(passingYear) || passingYear > joiningYear;
          })
          .test("is-year-before-joining", "Year of Joining must be before Year of Passing", function (value) {
            const joiningYear = parseInt(this.resolve(yup.ref("pucyearOfJoining"), this));
            const passingYear = parseInt(value);
            return isNaN(joiningYear) || isNaN(passingYear) || joiningYear < passingYear;
          }),
      };
    } else if (datatype === "iti" || datatype === "iti_others") {
      let tempObj = {
        itiCollegeName: yup.string().trim().required("College Name is required"),
        itiboardName: yup.string().trim().required("Board Name is required"),
        itiStream: yup.string().trim().required("Stream is required"),
        itiGradeType: yup.string().trim().required("Please select Percentage / CGPA"),
        itiGrade: yup
          .number()
          .required("Percentage/CGPA is required")
          .test('grade-validation', 'Select Percentage/CGPA', function(value) {
            const itiGradeType = this.parent.itiGradeType;

            if (itiGradeType === 'CGPA') {
              if (value >= 1 && value <= 10) {
                const decimalCount = value.toString().split('.')[1]?.length || 0;
                if (decimalCount <= 1) {
                  return true;
                } else {
                  return this.createError({
                    path: 'itiGrade',
                    message: 'CGPA can have only one decimal value',
                  });
                }
              } else {
                return this.createError({
                  path: 'itiGrade',
                  message: 'CGPA must be between 1 and 10',
                });
              }
            } else if (itiGradeType === 'Percentage') {
              if (value >= 1 && value <= 100) {
                const decimalCount = value.toString().split('.')[1]?.length || 0;
                if (decimalCount <= 2) {
                  return true;
                } else {
                  return this.createError({
                    path: 'itiGrade',
                    message: 'Percentage can have at most two decimal values',
                  });
                }
              } else {
                return this.createError({
                  path: 'itiGrade',
                  message: 'Percentage must be between 1 and 100',
                });
              }
            }
            return false;
          }),
        itiyearOfJoining: yup
          .number()
          .required("Year of Joining is required")
          .typeError('Year must be only digits')
          .min(1920, "Enter the valid year")
          .max(9999, "Year must be exactly 4 digits")
          .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)),
        itiyearOfPassing: yup
          .number()
          .required("Year of Passing is required")
          .typeError('Year must be only digits')
          .min(1920, "Enter the valid year")
          .max(9999, "Year must be exactly 4 digits")
          .test("is-year-after-joining", "Year of Passing must be after Year of Joining", function (value) {
            const joiningYear = parseInt(this.resolve(yup.ref("itiyearOfJoining"), this));
            const passingYear = parseInt(value);
            return isNaN(joiningYear) || isNaN(passingYear) || passingYear > joiningYear;
          })
          .test("is-year-before-joining", "Year of Joining must be before Year of Passing", function (value) {
            const joiningYear = parseInt(this.resolve(yup.ref("itiyearOfJoining"), this));
            const passingYear = parseInt(value);
            return isNaN(joiningYear) || isNaN(passingYear) || joiningYear < passingYear;
          }),
        itiCourseType: yup.string().trim().required("Course Type is required"),
      }
      if (datatype === "iti_others") {
        tempObj.itiBoard = yup.string().trim().required("Board Name is required");
      }
      return tempObj;
    }
  };
  const buildInitialValues = (datatype, values) => {
    let tempObj = {};
    if (datatype === "Master's Degree") {
      tempObj = {
        mdCollegeName: props.data?.masterDegree?.collegeName
          ? props.data?.masterDegree?.collegeName
          : "",
        mdUniversity: props.data?.masterDegree?.university
          ? props.data?.masterDegree?.university
          : "",
        mdTitle: props.data?.masterDegree?.title
          ? props.data?.masterDegree?.title
          : "",
        mdStream: props.data?.masterDegree?.stream
          ? props.data?.masterDegree?.stream
          : "",
        mdCurrentSem: props?.data?.masterDegree?.currentSem
          ? props?.data?.masterDegree?.currentSem
          : "",
        mdCountry: props.data?.masterDegree?.address?.country
          ? props.data?.masterDegree?.address?.country
          : "",
        mdState: props.data?.masterDegree?.address?.state
          ? props.data?.masterDegree?.address?.state
          : "",
        mdCity: props.data?.masterDegree?.address?.city
          ? props.data?.masterDegree?.address?.city
          : "",
        mdyearOfJoining: props.data?.masterDegree?.yearOfJoining
          ? props.data?.masterDegree?.yearOfJoining
          : "",
        mdyearOfPassing: props.data?.masterDegree?.yearOfPassing
          ? props.data?.masterDegree?.yearOfPassing
          : "",
        mdCourseType: props.data?.masterDegree?.courseType
          ? props.data?.masterDegree?.courseType
          : "",
      };
      if (props.data?.masterDegree?.universityName !== undefined) {
        tempObj.mdUniversityName = props.data?.masterDegree.universityName ? props.data?.masterDegree.universityName : "";
      }
      if (props.data?.masterDegree?.titleName !== undefined) {
        tempObj.mdTitleName = props.data?.masterDegree.titleName ? props.data?.masterDegree.titleName : "";
      }
      if(props.data?.masterDegree?.percentage > 0){
        tempObj.mdGradeType = "Percentage";
        tempObj.mdGrade = props.data.masterDegree.percentage;
      } else if(props.data?.masterDegree?.cgpa > 0){
        tempObj.mdGradeType = "CGPA";
        tempObj.mdGrade = props.data.masterDegree.cgpa;
      } else {
        tempObj.mdGradeType = "";
        tempObj.mdGrade = "";
      }
      return tempObj;
    } else if (datatype === "Degree") {
      tempObj = {
        dgCollegeName: props.data?.degree?.collegeName
          ? props.data?.degree.collegeName
          : "",
        dgUniversity: props.data?.degree?.university
          ? props.data?.degree.university
          : "",
        dgTitle: props.data?.degree?.title ? props.data?.degree?.title : "",
        dgStream: props.data?.degree?.stream ? props.data?.degree.stream : "",
        dgUsn: props.data?.degree?.usn ? props.data?.degree?.usn : "",
        dgCurrentSem: props.data?.degree?.currentSem
          ? props.data?.degree?.currentSem
          : "",
        dgCountry: props.data?.degree?.address?.country
          ? props.data?.degree?.address.country
          : "",
        dgState: props.data?.degree?.address?.state
          ? props.data?.degree?.address.state
          : "",
        dgCity: props.data?.degree?.address?.city
          ? props.data?.degree?.address.city
          : "",
        dgyearOfJoining: props.data?.degree?.yearOfJoining
          ? props.data?.degree?.yearOfJoining
          : "",
        dgyearOfPassing: props.data?.degree?.yearOfPassing
          ? props.data?.degree?.yearOfPassing
          : "",
        dgCourseType: props.data?.degree?.courseType
          ? props.data?.degree.courseType
          : "",
      };
      if (props.data?.degree?.universityName !== undefined) {
        tempObj.dgUniversityName = props.data?.degree.universityName ? props.data?.degree.universityName : "";
      }
      if (props.data?.degree?.titleName !== undefined) {
        tempObj.dgTitleName = props.data?.degree.titleName ? props.data?.degree.titleName : "";
      }
      if(props.data?.degree?.percentage > 0){
        tempObj.dgGradeType = "Percentage";
        tempObj.dgGrade = props.data.degree.percentage;
      } else if(props.data?.degree?.cgpa > 0){
        tempObj.dgGradeType = "CGPA";
        tempObj.dgGrade = props.data.degree.cgpa;
      } else {
        tempObj.dgGradeType = "";
        tempObj.dgGrade = "";
      }
      return tempObj;
    } if (datatype === "MasterDegree_others") {
      tempObj = {
        mdCollegeName: values.mdCollegeName
          ? values.mdCollegeName
          : "",
        mdUniversity: values.mdUniversity
          ? values.mdUniversity
          : "",
        mdTitle: values.mdTitle
          ? values.mdTitle
          : "",
        mdStream: values.mdStream
          ? values.mdStream
          : "",
        mdCurrentSem: values.mdCurrentSem
          ? values.mdCurrentSem
          : "",
        mdCountry: values.address?.mdCountry
          ? values.address?.mdCountry
          : "",
        mdState: values.address?.mdState
          ? values.address?.mdState
          : "",
        mdCity: values.address?.mdCity
          ? values.address?.mdState
          : "",
        mdyearOfJoining: values.mdyearOfJoining
          ? values.mdyearOfJoining
          : "",
        mdyearOfPassing: values.mdyearOfPassing
          ? values.mdyearOfPassing
          : "",
        mdCourseType: values.mdCourseType
          ? values.mdCourseType
          : "",
      };

      if (values.mdUniversity === "Others") {
        tempObj.mdUniversityName = values.mdUniversityName
          ? values.mdUniversityName
          : "";
      }
      if (values.mdTitle === "Others") {
        tempObj.mdTitleName = values.mdTitleName
          ? values.mdTitleName
          : "";
      }
      if(values.mdPercentage > 0){
        tempObj.mdGradeType = "Percentage";
        tempObj.mdGrade = values.mdPercentage;
      } else if(values.mdCgpa > 0){
        tempObj.mdGradeType = "CGPA";
        tempObj.mdGrade = values.mdCgpa;
      } else {
        tempObj.mdGradeType = "";
        tempObj.mdGrade = "";
      }
      return tempObj;
    } else if (datatype === "Degree_others") {
      tempObj = {
        dgCollegeName: values.dgCollegeName
          ? values.dgCollegeName
          : "",
        dgUniversity: values.dgUniversity
          ? values.dgUniversity
          : "",
        dgTitle: values.dgTitle ?
          values.dgTitle
          : "",
        dgStream: values.dgStream ?
          values.dgStream
          : "",
        dgUsn: values.dgUsn ?
          values.dgUsn
          : "",
        dgCurrentSem: values.dgCurrentSem
          ? values.dgCurrentSem
          : "",
        dgCountry: values.dgCountry
          ? values.dgCountry
          : "",
        dgState: values.dgState
          ? values.dgState
          : "",
        dgCity: values.dgCity
          ? values.dgCity
          : "",
        dgyearOfJoining: values.dgyearOfJoining
          ? values.dgyearOfJoining
          : "",
        dgyearOfPassing: values.dgyearOfPassing
          ? values.dgyearOfPassing
          : "",
        dgCourseType: values.dgCourseType
          ? values.dgCourseType
          : "",
      };
      if (values.dgUniversity === "Others") {
        tempObj.dgUniversityName = values.dgUniversityName
          ? values.dgUniversityName
          : "";
      }
      if (values.dgTitle === "Others") {
        tempObj.dgTitleName = values.dgTitleName
          ? values.dgTitleName
          : "";
      }
      if(values.dgPercentage > 0){
        tempObj.dgGradeType = "Percentage";
        tempObj.dgGrade = values.dgPercentage;
      } else if(values.dgCgpa > 0){
        tempObj.dgGradeType = "CGPA";
        tempObj.dgGrade = values.dgCgpa;
      } else {
        tempObj.dgGradeType = "";
        tempObj.dgGrade = "";
      }
      return tempObj;
    } else if (datatype === "Diploma/PUC/ITI") {
      return {
        preDegree: props.data?.preDegree?.length
          ? [...props.data.preDegree]
          : [],
      };
    } else if (datatype === "SSLC (10th)") {
      tempObj = {
        sslcCollegeName: props.data?.sslc?.schoolName
          ? props.data?.sslc?.schoolName
          : "",
        sslcboardName: props.data?.sslc?.boardName
          ? props.data?.sslc?.boardName
          : "",
        
        sslcyearOfJoining: props.data?.sslc?.yearOfJoining
          ? props.data?.sslc?.yearOfJoining
          : "",
        sslcyearOfPassing: props.data?.sslc?.yearOfPassing
          ? props.data?.sslc.yearOfPassing
          : "",
      };
      if(props.data?.sslc?.percentage > 0){
        tempObj.sslcGradeType = "Percentage";
        tempObj.sslcGrade = props.data.sslc.percentage;
      } else if(props.data?.sslc?.cgpa > 0){
        tempObj.sslcGradeType = "CGPA";
        tempObj.sslcGrade = props.data.sslc.cgpa;
      } else {
        tempObj.sslcGradeType = "";
        tempObj.sslcGrade = "";
      }
      return tempObj;
    } else if (datatype === "diploma") {
      tempObj = {
        dpCollegeName: props.data?.diploma?.collegeName
          ? props.data?.diploma?.collegeName
          : "",
        dpboardName: props.data?.diploma?.boardName
          ? props.data?.diploma?.boardName
          : "",
        dpStream: props.data?.diploma?.stream
          ? props.data?.diploma?.stream
          : "",
        dpyearOfJoining: props.data?.diploma?.yearOfJoining
          ? props.data?.diploma?.yearOfJoining
          : "",
        dpyearOfPassing: props.data?.diploma?.yearOfPassing
          ? props.data?.diploma?.yearOfPassing
          : "",
        dpCourseType: props.data?.diploma?.courseType
          ? props.data?.diploma?.courseType
          : "",
      };
      if (props.data?.diploma?.board !== undefined) {
        tempObj.dpBoard = props.data?.diploma.board ? props.data?.diploma.board : "";
      }
      if(props.data?.diploma?.percentage > 0){
        tempObj.dpGradeType = "Percentage";
        tempObj.dpGrade = props.data.diploma.percentage;
      } else if(props.data?.diploma?.cgpa > 0){
        tempObj.dpGradeType = "CGPA";
        tempObj.dpGrade = props.data.diploma.cgpa;
      } else {
        tempObj.dpGradeType = "";
        tempObj.dpGrade = "";
      }
      return tempObj;
    } else if (datatype === "diploma_others") {
      tempObj = {
        dpCollegeName: values.dpCollegeName
          ? values.dpCollegeName
          : "",
        dpboardName: values.dpboardName
          ? values.dpboardName
          : "",
        dpBoard: values.dpboard
          ? values.dpboard
          : "",
        dpStream: values.dpStream
          ? values.dpStream
          : "",
        dpyearOfJoining: values.dpyearOfJoining
          ? values.dpyearOfJoining
          : "",
        dpyearOfPassing: values.dpyearOfPassing
          ? values.dpyearOfPassing
          : "",
        dpCourseType: values.dpCourseType
          ? values.dpCourseType
          : "",
      };
      // if(values.dpPercentage > 0){
      //   tempObj.dpGradeType = "Percentage";
      //   tempObj.dpGrade = values.dpPercentage;
      // } else if(values.dpCgpa > 0){
      //   tempObj.dpGradeType = "CGPA";
      //   tempObj.dpGrade = values.dpCgpa;
      // } else {
      //   tempObj.dpGradeType = "";
      //   tempObj.dpGrade = "";
      // }
      if(values.dpGradeType === "Percentage" || values.dpGradeType === "CGPA"){
        // tempObj.dpGradeType = "Percentage";
        tempObj.dpGrade = values.dpGrade;
      } else {
        // tempObj.dpGradeType = "";
        tempObj.dpGrade = "";
      }
      return tempObj;
    } else if (datatype === "twelfth") {
      tempObj = {       
        pucyearOfJoining: props.data?.twelfth?.yearOfJoining
          ? props.data?.twelfth?.yearOfJoining
          : "",
        pucyearOfPassing: props.data?.twelfth?.yearOfPassing
          ? props.data?.twelfth?.yearOfPassing
          : "",
      };
      if(props.data?.twelfth?.percentage > 0){
        tempObj.pucGradeType = "Percentage";
        tempObj.pucGrade = props.data.twelfth.percentage;
      } else if(props.data?.twelfth?.cgpa > 0){
        tempObj.pucGradeType = "CGPA";
        tempObj.pucGrade = props.data.twelfth.cgpa;
      } else {
        tempObj.pucGradeType = null;
        tempObj.pucGrade = "";
      }
      return tempObj;
    } else if (datatype === "iti") {
      tempObj = {
        itiCollegeName: props.data?.iti?.collegeName
          ? props.data?.iti?.collegeName
          : "",
        itiboardName: props.data?.iti?.boardName
          ? props.data?.iti?.boardName
          : "",
        itiStream: props.data?.iti?.stream ? props.data?.iti?.stream : "",
        
        itiyearOfJoining: props.data?.iti?.yearOfJoining
          ? props.data?.iti?.yearOfJoining
          : "",
        itiyearOfPassing: props.data?.iti?.yearOfPassing
          ? props.data?.iti?.yearOfPassing
          : "",
        itiCourseType: props.data?.iti?.courseType
          ? props.data?.iti?.courseType
          : "",
      };
      if (props.data?.iti?.board !== undefined) {
        tempObj.itiBoard = props.data?.iti.board ? props.data?.iti.board : "";
      }
      if(props.data?.iti?.percentage > 0){
        tempObj.itiGradeType = "Percentage";
        tempObj.itiGrade = props.data.iti.percentage;
      } else if(props.data?.iti?.cgpa > 0){
        tempObj.itiGradeType = "CGPA";
        tempObj.itiGrade = props.data.iti.cgpa;
      } else {
        tempObj.itiGradeType = "";
        tempObj.itiGrade = "";
      }
      return tempObj;
    } else if (datatype === "iti_others") {
      tempObj = {
        itiCollegeName: values.itiCollegeName
          ? values.itiCollegeName
          : "",
        itiboardName: values.itiboardName
          ? values.itiboardName
          : "",
        itiBoard: values.itiBoard
          ? values.itiBoard
          : "",
        itiStream: values.itiStream ? values.itiStream : "",
        
        itiyearOfJoining: values.itiyearOfJoining
          ? values.itiyearOfJoining
          : "",
        itiyearOfPassing: values.itiyearOfPassing
          ? values.itiyearOfPassing
          : "",
        itiCourseType: values.itiCourseType
          ? values.itiCourseType
          : "",
      };
      // if(values.itiPercentage > 0){
      //   tempObj.itiGradeType = "Percentage";
      //   tempObj.itiGrade = values.Percentage;
      // } else if(values.itiCgpa > 0){
      //   tempObj.itiGradeType = "CGPA";
      //   tempObj.itiGrade = values.itiCgpa;
      // } else {
      //   tempObj.itiGradeType = "";
      //   tempObj.itiGrade = "";
      // }
      if(values.itiGradeType === "Percentage" || values.itiGradeType === "CGPA"){
        tempObj.itiGrade = values.itiGrade;
      } else {
        tempObj.itiGrade = "";
      }
      return tempObj;
    }
  };
  const createValidation = (data, tempvalues, preDegreetype) => {
    let updatedSchema = { ...currentSchema };
    let updateInitialValues = { ...tempvalues };
    if (data === "Master's Degree") {
      if (props.data?.masterDegree?.university === "Others" || props.data?.masterDegree?.title === "Others") {
        updatedSchema = {
          ...initialSchema,
          ...buildSchemaWithvalue("MasterDegree_others"),
          ...buildSchemaWithvalue("Degree"),
          ...buildSchemaWithvalue("Diploma/PUC/ITI"),
          ...buildSchemaWithvalue("SSLC (10th)"),
        };
      } else {
        updatedSchema = {
          ...initialSchema,
          ...buildSchemaWithvalue("Master's Degree"),
          ...buildSchemaWithvalue("Degree"),
          ...buildSchemaWithvalue("Diploma/PUC/ITI"),
          ...buildSchemaWithvalue("SSLC (10th)"),
        };
      }
      updateInitialValues = {
        ...updateInitialValues,
        ...buildInitialValues("Master's Degree", formik.values),
        ...buildInitialValues("Degree"),
        ...buildInitialValues("Diploma/PUC/ITI"),
        ...buildInitialValues("SSLC (10th)"),
      };
    } else if (data === "Degree") {
      if (props.data?.degree?.university === "Others" || props.data?.degree?.title === "Others") {
        updatedSchema = {
          ...initialSchema,
          ...buildSchemaWithvalue("Degree_others"),
          ...buildSchemaWithvalue("Diploma/PUC/ITI"),
          ...buildSchemaWithvalue("SSLC (10th)"),
        };
      } else {
        updatedSchema = {
          ...initialSchema,
          ...buildSchemaWithvalue("Degree"),
          ...buildSchemaWithvalue("Diploma/PUC/ITI"),
          ...buildSchemaWithvalue("SSLC (10th)"),
        };
      }

      updateInitialValues = {
        ...updateInitialValues,
        ...buildInitialValues("Degree", formik.values),
        ...buildInitialValues("Diploma/PUC/ITI"),
        ...buildInitialValues("SSLC (10th)"),
      };
    } else if (data === "Diploma/PUC/ITI") {
      updatedSchema = {
        ...initialSchema,
        ...buildSchemaWithvalue("Diploma/PUC/ITI"),
        ...buildSchemaWithvalue("SSLC (10th)"),
      };
      updateInitialValues = {
        ...updateInitialValues,
        ...buildInitialValues("Diploma/PUC/ITI"),
        ...buildInitialValues("SSLC (10th)"),
      };
    } else if (data === "SSLC (10th)") {
      updatedSchema = {
        ...initialSchema,
        ...buildSchemaWithvalue("SSLC (10th)"),
      };
      updateInitialValues = {
        ...updateInitialValues,
        ...buildInitialValues("SSLC (10th)"),
      };
    }
    if (data === "predegree" || props.data?.preDegree?.length) {
      if (
        preDegreetype === "diploma" ||
        props.data?.preDegree?.includes("diploma")
      ) {
        if (props.data?.diploma?.boardName === "Others") {
          updatedSchema = {
            ...updatedSchema,
            ...buildSchemaWithvalue("diploma_others"),
          };
        } else {
          updatedSchema = {
            ...updatedSchema,
            ...buildSchemaWithvalue("diploma"),
          };
        }
        updateInitialValues = {
          ...updateInitialValues,
          ...buildInitialValues("diploma", formik.values),
        };
      }
      if (
        preDegreetype === "twelfth" ||
        props.data?.preDegree?.includes("twelfth")
      ) {
        updatedSchema = {
          ...updatedSchema,
          ...buildSchemaWithvalue("twelfth"),
        };
        updateInitialValues = {
          ...updateInitialValues,
          ...buildInitialValues("twelfth"),
        };
      }
      if (preDegreetype === "iti" || props.data?.preDegree?.includes("iti")) {
        if (props.data?.iti?.boardName === "Others") {
          updatedSchema = {
            ...updatedSchema,
            ...buildSchemaWithvalue("iti_others"),
          };
        } else {
          updatedSchema = {
            ...updatedSchema,
            ...buildSchemaWithvalue("iti"),
          };
        }
        updateInitialValues = {
          ...updateInitialValues,
          ...buildInitialValues("iti", formik.values),
        };
      }
    }
    setcurrentSchema({ ...updatedSchema });
    setInitialValues({ ...updateInitialValues });
    setValidationSchema(yup.object(updatedSchema));
  };
  const removePreDegreeValidation = (value, tempvalues, currentvalue) => {
    let updatedSchema = { ...currentSchema };
    let updateInitialValues = { ...tempvalues };
    if (currentvalue === "diploma") {
      delete updatedSchema.dpCollegeName;
      delete updatedSchema.dpboardName;
      delete updatedSchema.dpStream;
      delete updatedSchema.dpGradeType;
      delete updatedSchema.dpGrade;
      delete updatedSchema.dpCgpa;
      delete updatedSchema.dpPercentage;
      delete updatedSchema.dpyearOfJoining;
      delete updatedSchema.dpyearOfPassing;
      delete updatedSchema.dpCourseType;
      delete updateInitialValues.dpCollegeName;
      delete updateInitialValues.dpboardName;
      delete updateInitialValues.dpStream;
      delete updateInitialValues.dpCgpa;
      delete updateInitialValues.dpPercentage;
      delete updateInitialValues.dpyearOfJoining;
      delete updateInitialValues.dpyearOfPassing;
      delete updateInitialValues.dpCourseType;
      delete updateInitialValues.dpGradeType;
    } else if (currentvalue === "twelfth") {
      delete updatedSchema.pucCollegeName;
      delete updatedSchema.pucboardName;
      delete updatedSchema.pucGradeType;
      delete updatedSchema.pucGrade;
      delete updatedSchema.pucPercentage;
      delete updatedSchema.pucyearOfJoining;
      delete updatedSchema.pucyearOfPassing;
      delete updateInitialValues.pucCollegeName;
      delete updateInitialValues.pucboardName;
      delete updateInitialValues.pucPercentage;
      delete updateInitialValues.pucyearOfJoining;
      delete updateInitialValues.pucyearOfPassing;
    } else if (currentvalue === "iti") {
      delete updatedSchema.itiCollegeName;
      delete updatedSchema.itiboardName;
      delete updatedSchema.itiStream;
      delete updatedSchema.itiCgpa;
      delete updatedSchema.itiGradeType;
      delete updatedSchema.itiGrade;
      delete updatedSchema.itiPercentage;
      delete updatedSchema.itiyearOfJoining;
      delete updatedSchema.itiyearOfPassing;
      delete updatedSchema.itiCourseType;
      delete updateInitialValues.itiCollegeName;
      delete updateInitialValues.itiboardName;
      delete updateInitialValues.itiStream;
      delete updateInitialValues.itiCgpa;
      delete updateInitialValues.itiPercentage;
      delete updateInitialValues.itiyearOfJoining;
      delete updateInitialValues.itiyearOfPassing;
      delete updateInitialValues.itiCourseType;
    }
    setcurrentSchema({ ...updatedSchema });
    setInitialValues({ ...updateInitialValues });
    setValidationSchema(yup.object(updatedSchema));
  };
  const handlehighEduChange = (data) => {
    if (selectedHighestEdu[0]?.value !== data[0].value) {
      setSelectedHighestEdu([...data]);
      formik.setFieldValue("highestEducation", data[0].value, true);
      let tempvalues = { ...initValues };
      tempvalues.highestEducation = data[0].value;
      let tempSelectedData = {};
      console.log(data,tempvalues,"tempvalues","data")
      if (props.data?.masterDegree) {
        let tempObj = props.data?.masterDegree;
        let tempGradeType = "";
        if(tempObj?.percentage > 0){
          tempGradeType = "Percentage"
        } else if(tempObj?.cgpa > 0){
          tempGradeType = "CGPA"
        } else {
          tempGradeType = null
        }
        if(tempGradeType !== null)
        {
          tempSelectedData = {
          ...tempSelectedData,
          mdGradeType: [stringToDropdownValue(tempGradeType)],
          };
        }
        tempSelectedData = {
          ...tempSelectedData,
          mdCollegeName: [stringToDropdownValue(tempObj.collegeName)],
          mdUniversity: [stringToDropdownValue(tempObj.university)],
          mdTitle: [stringToDropdownValue(tempObj.title)],
          mdStream: [stringToDropdownValue(tempObj.stream)],
          mdCountry: [stringToDropdownValue(tempObj?.address?.country)],
          mdState: [stringToDropdownValue(tempObj?.address?.state)],
          mdCity: [stringToDropdownValue(tempObj?.address?.city)],
          mdCourseType: [dropdownLabelFinder(tempObj.courseType, dropDownData.mdCourseType)],
        };
      }
      if (props.data?.degree) {
        let tempObj = props.data?.degree;
        let tempGradeType = "";
        if(tempObj?.percentage > 0){
          tempGradeType = "Percentage"
        } else if(tempObj?.cgpa > 0){
          tempGradeType = "CGPA"
        } else {
          tempGradeType = null
        }
        if(tempGradeType !== null)
        {
          tempSelectedData = {
          ...tempSelectedData,
          dgGradeType: [stringToDropdownValue(tempGradeType)],
          };
        }
        tempSelectedData = {
          ...tempSelectedData,
          dgCollegeName: [stringToDropdownValue(tempObj.collegeName)],
          dgUniversity: [stringToDropdownValue(tempObj.university)],
          dgTitle: [stringToDropdownValue(tempObj.title)],
          dgStream: [stringToDropdownValue(tempObj.stream)],
          dgCountry: [stringToDropdownValue(tempObj?.address?.country)],
          dgState: [stringToDropdownValue(tempObj?.address?.state)],
          dgCity: [stringToDropdownValue(tempObj?.address?.city)],
          dgCourseType: [dropdownLabelFinder(tempObj.courseType, dropDownData.dgCourseType)],
        };
      }
      if (props.data?.preDegree?.includes("diploma")) {
        let tempObj = props.data?.diploma;
        let tempGradeType = "";
        if(tempObj?.percentage > 0){
          tempGradeType = "Percentage"
        } else if(tempObj?.cgpa > 0){
          tempGradeType = "CGPA"
        } else {
          tempGradeType = null
        }
        if(tempGradeType !== null)
        {
          tempSelectedData = {
          ...tempSelectedData,
          dpGradeType: [stringToDropdownValue(tempGradeType)],
          };
        }
        tempSelectedData = {
          ...tempSelectedData,
          dpCollegeName: [stringToDropdownValue(tempObj.collegeName)],
          dpboardName: [stringToDropdownValue(tempObj.boardName)],
          dpStream: [stringToDropdownValue(tempObj.stream)],
          dpCourseType: [dropdownLabelFinder(tempObj.courseType, dropDownData.dpCourseType)],
        };
      }
      if (props.data?.preDegree?.includes("twelfth")) {
        let tempObj = props.data?.twelfth;
        let tempGradeType = "";
        if(tempObj?.percentage > 0){
          tempGradeType = "Percentage"
        } else if(tempObj?.cgpa > 0){
          tempGradeType = "CGPA"
        } else {
          tempGradeType = null
        }
        if(tempGradeType !== null)
        {
          tempSelectedData = {
          ...tempSelectedData,
          pucGradeType: [stringToDropdownValue(tempGradeType)],
          };
        }
      }
      if (props.data?.preDegree?.includes("iti")) {
        let tempObj = props.data?.iti;
        let tempGradeType = "";
        if(tempObj?.percentage > 0){
          tempGradeType = "Percentage"
        } else if(tempObj?.cgpa > 0){
          tempGradeType = "CGPA"
        } else {
          tempGradeType = null
        }
        if(tempGradeType !== null)
        {
          tempSelectedData = {
          ...tempSelectedData,
          itiGradeType: [stringToDropdownValue(tempGradeType)],
          };
        }
        tempSelectedData = {
          ...tempSelectedData,
          itiCollegeName: [stringToDropdownValue(tempObj.collegeName)],
          itiboardName: [stringToDropdownValue(tempObj.boardName)],
          itiStream: [stringToDropdownValue(tempObj.stream)],
          itiCourseType: [dropdownLabelFinder(tempObj.courseType, dropDownData.itiCourseType)],
        };
      }
      if (props.data?.sslc) {
        let tempObj = props.data?.sslc;
        let tempGradeType = "";
        if(tempObj?.percentage > 0){
          tempGradeType = "Percentage"
        } else if(tempObj?.cgpa > 0){
          tempGradeType = "CGPA"
        } else {
          tempGradeType = null
        }
        if(tempGradeType !== null)
        {
          tempSelectedData = {
          ...tempSelectedData,
          sslcGradeType: [stringToDropdownValue(tempGradeType)],
        };
      }
      }
      setSelectedDropdownData({
        ...tempSelectedData,
      });
      setSelectedPreDegree(
        props.data?.preDegree?.length ? [...props.data?.preDegree] : []
      );
      createValidation(data[0].value, tempvalues);
    }
  };
  let handelPreDegreeCheckbox = (checked, value, currentvalue) => {
    if (checked) {
      formik.setFieldValue("preDegree", value, true);
      let tempvalues = { ...formik.values };
      tempvalues.preDegree = value;
      createValidation("predegree", tempvalues, currentvalue);
    } else {
      formik.setFieldValue("preDegree", value, true);
      let tempvalues = { ...formik.values };
      tempvalues.preDegree = value;
      removePreDegreeValidation(value, tempvalues, currentvalue);
    }
    setSelectedPreDegree([...value]);
  };
  const getGradUniversities = async (key) => {
    try {
      const res = await getAllUniversitiesReq();
      if (res.data) {
        let tempUniversity = res.data.response.map((val) => {
          return stringToDropdownValue(val);
        });
        tempUniversity.push(stringToDropdownValue("Others"));
        setDropDownData({ ...dropDownData, [key]: tempUniversity });
      } else {
        setDropDownData({ ...dropDownData, [key]: [] });
      }
    } catch (e) {
      console.log("universities error", e);
    }
  };
  const getGradColleges = async (key, university) => {
    try {
      const res = await getAllCollegesReq(university);
      if (res.data) {
        let tempCollege = res.data.response.map((val) => {
          return stringToDropdownValue(val);
        });
        setDropDownData({ ...dropDownData, [key]: tempCollege });
      } else {
        setDropDownData({ ...dropDownData, [key]: [] });
      }
    } catch (e) {
      console.log("Grad colleges error", e);
    }
  };
  const getCountries = async (key) => {
    try {
      const res = await getAllCountriesReq();
      if (res?.data) {
        // let tempCountry = res.data.length > 0 ? res.data : [];
        let tempCountry = res.data.response.map((val) => {
          return stringToDropdownValue(val);
        });
        setDropDownData({
          ...dropDownData,
          [key]: tempCountry,
        });
      } else {
        setDropDownData({ ...dropDownData, [key]: [] });
      }
    } catch (e) {
      console.log("countires error", e);
    }
  };
  const getStates = async (key, country) => {
    try {
      const res = await getAllStatesReq(country);
      if (res?.data) {
        // let tempState = res.data.length > 0 ? res.data : [];
        let tempState = res.data.response.map((val) => {
          return stringToDropdownValue(val);
        });
        setDropDownData({ ...dropDownData, [key]: tempState });
      } else {
        setDropDownData({ ...dropDownData, [key]: [] });
      }
    } catch (e) {
      console.log("states error", e);
    }
  };
  const getCities = async (key, country, state) => {
    try {
      const res = await getAllCitiesReq(country, state);
      if (res?.data) {
        let tempCity = res.data.response.map((val) => {
          return stringToDropdownValue(val);
        });
        setDropDownData({ ...dropDownData, [key]: tempCity });
      } else {
        setDropDownData({ ...dropDownData, [key]: [] });
      }
    } catch (e) {
      console.log("cities error", e);
    }
  };
  const getTitles = async (key, degree) => {
    try {
      const res = await getAllTitlesReq(degree);
      if (res?.data) {
        let tempTitle = res.data.response.map((val) => {
          return stringToDropdownValue(val);
        });
        // tempTitle.push(stringToDropdownValue("Others"));
        setDropDownData({ ...dropDownData, [key]: tempTitle });
      } else {
        setDropDownData({ ...dropDownData, [key]: [] });
      }
    } catch (e) {
      console.log("states error", e);
    }
  };
  const getStreams = async (key, degree, title) => {
    try {
      const res = await getAllStreamsReq(degree, title);
      if (res?.data) {
        let tempStream = res.data.response.map((val) => {
          return stringToDropdownValue(val);
        });
        setDropDownData({ ...dropDownData, [key]: tempStream });
      } else {
        setDropDownData({ ...dropDownData, [key]: [] });
      }
    } catch (e) {
      console.log("cities error", e);
    }
  };
  const getMdUniversityHandler = () => {
    if (formik.values.mdUniversity?.value !== null) {
      getGradUniversities("mdUniversity");
    }
  };
  const getMdCollegeHandler = () => {
    if (
      formik.values.mdUniversity !== undefined &&
      formik.values.mdUniversity !== null &&
      formik.values.mdUniversity !== ""
    ) {
      getGradColleges("mdCollegeName", formik.values.mdUniversity);
    }
  };
  const getMdCountryHandler = () => {
    if (formik.values.mdCountry?.value !== null) {
      getCountries("mdCountry");
    }
  };
  const getMdStateHandler = () => {
    if (formik.values.mdCountry?.value !== null) {
      getStates("mdState", formik.values.mdCountry);
    }
  };
  const getMdCityHandler = () => {
    if (
      formik.values.mdCountry?.value !== null &&
      formik.values.mdState?.value !== null
    ) {
      getCities("mdCity", formik.values.mdCountry, formik.values.mdState);
    }
  };
  const getMdTitleHandler = () => {
    if (formik.values.mdTitle?.value !== null) {
      getTitles("mdTitle", "MASTERS_DEGREE");
    }
  };
  const getMdStreamHandler = () => {
    if (
      formik.values.mdTitle?.value !== null &&
      formik.values.mdStream?.value !== null
    ) {
      getStreams("mdStream", "MASTERS_DEGREE", formik.values.mdTitle);
    }
  };
  const getDgUniversityHandler = () => {
    getGradUniversities("dgUniversity");
  };
  const getDgCollegeHandler = () => {
    if (
      formik.values.dgUniversity !== undefined &&
      formik.values.dgUniversity !== null &&
      formik.values.dgUniversity !== ""
    ) {
      getGradColleges("dgCollegeName", formik.values.dgUniversity);
    }
  };
  const getDgCountryHandler = () => {
    if (formik.values.dgCountry?.value !== null) {
      getCountries("dgCountry");
    }
  };
  const getDgStateHandler = () => {
    if (formik.values.dgCountry?.value !== null) {
      getStates("dgState", formik.values.dgCountry);
    }
  };
  const getDgCityHandler = () => {
    if (
      formik.values.dgCountry?.value !== null &&
      formik.values.dgState?.value !== null
    ) {
      getCities("dgCity", formik.values.dgCountry, formik.values.dgState);
    }
  };
  const getDgTitleHandler = () => {
    if (formik.values.dgTitle?.value !== null) {
      getTitles("dgTitle", "DEGREE");
    }
  };
  const getDgStreamHandler = () => {
    if (
      formik.values.dgTitle?.value !== null &&
      formik.values.dgStream?.value !== null
    ) {
      getStreams("dgStream", "DEGREE", formik.values.dgTitle);
    }
  };
  const getDiplomaStreamHandler = () => {
    getStreams("dpStream", "DIPLOMA", "diploma");
  };
  const getItiStreamHandler = () => {
    getStreams("itiStream", "ITI", "iti");
  };
  const getDiplomaBoardHandler = () => {
    getGradUniversities("dpboardName");
  };
  const getDiplomaCollegeHandler = () => {
    if (
      formik.values.dpboardName !== undefined &&
      formik.values.dpboardName !== null &&
      formik.values.dpboardName !== ""
    ) {
      getGradColleges("dpCollegeName", formik.values.dpboardName);
    }
  };
  const getItiBoardHandler = () => {
    getGradUniversities("itiboardName");
  };
  const getItiCollegeHandler = () => {
    if (
      formik.values.itiboardName !== undefined &&
      formik.values.itiboardName !== null &&
      formik.values.itiboardName !== ""
    ) {
      getGradColleges("itiCollegeName", formik.values.itiboardName);
    }
  };
  useEffect(() => {
    if (props.data) {
      handlehighEduChange([
        {
          label: props.data?.highestEducation,
          value: props.data?.highestEducation,
        },
      ]);
    }
  }, []);
  const onSubmit = async (values) => {
    props.setDiplomoForm(true)
    props.changeTab("workDetails");
    console.log("education Details values", values);
    props.fetchDataFromForms(handleEducationDataForm(values));
    props.getPreDegree(values.preDegree);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <div className="edu-details-form ">
      {console.log(selectedHighestEdu,"selectedHighestEdu")}
      <form className="details-form-tag" onSubmit={formik.handleSubmit}>
        <div className="details-form-body">
          <div className="field-wrapper  mt-2 individual-field">
            <div className="mb-2 col-span-3 label">
              <span className="create-labels">Highest Education</span>
            </div>
            <div className="input-ele grid grid-cols-3">
              <SelectDropdown
                data={highestEduData}
                onChange={(data) => {
                  handlehighEduChange(data);
                }}
                selectedData={Array.isArray(selectedHighestEdu)?selectedHighestEdu[0]?.value == ""?null: selectedHighestEdu?selectedHighestEdu:null:selectedHighestEdu}
                deselection={true}
                searchable={true}
              />
            </div>
            {formik.errors?.highestEducation &&
              formik.touched?.highestEducation ? (
              <div className="text-red-500 error_text p-1">
                {formik.errors.highestEducation}
              </div>
            ) : null}
          </div>
          {selectedHighestEdu[0]?.value === "Master's Degree" && (
            <MastersDegree
              dropDownData={dropDownData}
              handleDropdownChange={handleDropdownChange}
              selectedDropdownData={selectedDropdownData}
              formik={formik}
              universityDataHandler={getMdUniversityHandler}
              collegeDataHandler={getMdCollegeHandler}
              countryDataHandler={getMdCountryHandler}
              stateDataHandler={getMdStateHandler}
              cityDataHandler={getMdCityHandler}
              titleDataHandler={getMdTitleHandler}
              streamDataHandler={getMdStreamHandler}
              mastersDropdownChange={handleOthersTypeDropdownChange}
            />
          )}
          {(selectedHighestEdu[0]?.value === "Master's Degree" ||
            selectedHighestEdu[0]?.value === "Degree") && (
              <Degree
                dropDownData={dropDownData}
                handleDropdownChange={handleDropdownChange}
                selectedDropdownData={selectedDropdownData}
                formik={formik}
                universityDataHandler={getDgUniversityHandler}
                collegeDataHandler={getDgCollegeHandler}
                countryDataHandler={getDgCountryHandler}
                stateDataHandler={getDgStateHandler}
                cityDataHandler={getDgCityHandler}
                titleDataHandler={getDgTitleHandler}
                streamDataHandler={getDgStreamHandler}
                degreeDropdownChange={handleOthersTypeDropdownChange}
              />
            )}
            {console.log(props.diplomoForm,"props.setDiplomoForm")}
          {(selectedHighestEdu[0]?.value === "Master's Degree" ||
            selectedHighestEdu[0]?.value === "Degree" ||
            selectedHighestEdu[0]?.value === "Diploma/PUC/ITI") && (
            <DiplomaPucIti
                setDiplomoForm={props.setDiplomoForm}
                diplomoForm={props.diplomoForm}
                dropDownData={dropDownData}
                setSelectedPreDegree={setSelectedPreDegree}
                selectedPreDegree={selectedPreDegree}
                handelPreDegreeCheckbox={handelPreDegreeCheckbox}
                handleDropdownChange={handleDropdownChange}
                selectedDropdownData={selectedDropdownData}
                formik={formik}
                diplomaStreamDataHandler={getDiplomaStreamHandler}
                diplomaBoardDataHandler={getDiplomaBoardHandler}
                diplomaCollegeDataHandler={getDiplomaCollegeHandler}
                itiStreamDataHandler={getItiStreamHandler}
                itiBoardDataHandler={getItiBoardHandler}
                itiCollegeDataHandler={getItiCollegeHandler}
                diplomaBoardChange={handleDiplomaBoardChange}
                itiBoardChange={handleItiBoardChange}
              />
            )}
          {(selectedHighestEdu[0]?.value === "Master's Degree" ||
            selectedHighestEdu[0]?.value === "Degree" ||
            selectedHighestEdu[0]?.value === "Diploma/PUC/ITI" ||
            selectedHighestEdu[0]?.value === "SSLC (10th)") && (
              <Sslc
                dropDownData={dropDownData}
                handleDropdownChange={handleDropdownChange}
                selectedDropdownData={selectedDropdownData}
                formik={formik}
              />
            )}
        </div>
        <div className="details-form-footer">
          <button className="datatrimmer submity-btn" title={""} type="submit">
            Next
          </button>
          <button
            className="datatrimmer previous-btn mr-4"
            onClick={() => {
              props.fetchDataFromForms(handleEducationDataForm(formik.values));
              props.changeTab("personalDetails");
            }}
            title={""}
            type="button"
          >
            Previous
          </button>
          {props.completeProfileData ? <button
            className="cursor-not-allowed"
            disabled="true"
            style={
              {
                fontSize: "12px",
                height: "30px",
                width: "94px",
                backgroundColor: "#f2f2f2",
                border: "1px solid #dadada",
                color: "#525252",
                borderRadius: "32px",
                opacity: "1",
                float: "right",
                marginRight: "10px"
              }}
            onClick={() => {
              props.closeScreen();
            }}
            title="Please enter the details"
          >
            Cancel
          </button> : <button
            disabled={props.completeProfileData}
            className="datatrimmer cancel-btn-responsive  mr-4"
            onClick={() => {
              props.closeScreen(true);
            }}
            title={props.completeProfileData ? "Please enter the details" : "Cancel"}
          >
            Cancel
          </button>}
        </div>
      </form>
    </div>
  );
};

export default EducationDetailsForm;
