import { useState, useEffect } from "react";
import React from "react";
import Modal from "react-modal";
import warningIcon from "../../../../assets/toast-warning.svg";
import "./AdminAddEditUser.scss"
import { yellow } from "@mui/material/colors";

function DeleteInterViewerModal(props) {
  const [openModal, setOpenModal] = useState(true);
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "40%",
      // height: "33%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      // borderRadius: "4px",
      opacity: "1",
      outline: "none",
      borderTop: "4px solid #FFC600",
    },
  };
  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px"
      // onRequestClose={() => {
      //   props.closeModal(false);
      //   setOpenModal(false);
      // }}
    >
      <div className="grid grid-cols-12 deleteModal">
        <div className="grid grid-cols-12 col-span-12 warning-popup py-2">
          <div className="col-span-12 warning-icon flex">
           
              <img alt="warning" src={warningIcon} />
              <div className=" relative  left-8 warningText top-2.5">
              warning!
              
            </div>
           
          </div>
          <div className="grid grid-cols-12 col-span-10 pl-7 my-2">
            
            {/* <div className="col-span-12 py-1">
              <span className="message">
                Hi ,
              </span>
            </div> */}
            <div className="col-span-12">
              <>
                <span className="messageContent"> 
                Your data will be lost. Are you sure you want to delete {props.editDetails.name} {props.usertype==="BD"?"BD":props.usertype==="interviewer"?"Interviewer":"Interview Coordinator"}?
                How do you want to proceed? </span>
              </>
            </div>
            {/* <div className="col-span-12">
              <span className="message">How do you want to proceed?</span>
            </div> */}
          </div>
        </div>

        <div className="grid grid-cols-12 col-span-12 warning_popup_btn   pt-2">
          <div className="col-span-8"></div>
          <div className="grid grid-cols-12 col-span-4 flex justify-center">
            <div className="grid grid-cols-12 col-span-6 h-8 cnclBtn mr-4" onClick={()=>{props.closeModal(false)}}>
              <span className="btn_close col-span-10 rounded-sm flex justify-center cursor-pointer">
                <button>Cancel</button>
              </span>
            </div>
            <div className="grid grid-cols-12 col-span-6 h-8 dltBtn bg-orange-500"  onClick={()=>{props.handelDlt(props.editDetails)}}>
              <span className="btn_back col-span-10 rounded-sm flex justify-center cursor-pointer" >
                <button>Delete</button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default DeleteInterViewerModal;
