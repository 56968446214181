import React, { useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as FilterIcon } from "../../../../assets/filterIcon.svg";
import { ReactComponent as NoDataFound } from "../../../../assets/noDataFound.svg";
import { Checkbox, Modal } from "@material-ui/core";
import CandidateColumnOption from "../../../common/CandidateColumnOption";
import AdminContextMenuModal from "../../AdminContextMenuModal";
import CandidateTable from "../../../common/candidate-table/CandidateTable";
import DeleteInterViewerModal from "../../admin-modals/add-interviewer/DeleteInterViewerModal";
import AdminAddEditUser from "../../admin-modals/add-interviewer/AdminAddEditUser";
import { useEffect } from "react";
import {
  deleteinterviewercordinator,
  getInterviewersCoordinate,
  resendinterviewCoordinatorActivation,
  adminCoOrdinatorSearch,
} from "../../../../api/api-services";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import { useRef } from "react";
import BDdetailsModule from "../../admin-modals/add-interviewer/BDdetailsModule";

const InterviewersCoordinateList = (props) => {
  let [filterOptions, setFilterOptions] = useState([]);
  let [selectedFilters, setSelectedFilters] = useState({});
  let [openFilter, setOpenFilter] = useState(false);
  const [addBD, setaddBD] = useState(false);
  const [editBD, setEditBD] = useState(false);
  const [editDetails, setEditDetails] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  const cardSearchInput = useRef(null);
  const [bdDetails, setbdDetails] = useState(false);
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let size = 10;
  const [isSearching, setIsSearching] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  let [tableApiData, setTableApiData] = useState({
    totalCount: 20,
    columnDef: {
      columns: [
        {
          columnName: "name",
          column_order: 1,
        },
        {
          columnName: "email",
          column_order: 2,
        },
        {
          columnName: "mobile",
          column_order: 3,
        },
        {
          columnName: "status",
          column_order: 4,
        },
      ],
    },
    content: [],
    lastPage: true,
  });
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "80%",
      transform: "translate(-50%, -50%)",
      width: "40%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
      outline: "none",
      index: "9999",
      //   borderTopLeftRadius: "12px",
    },
  };

  const contextMenuData = [
    {
      option: "Details",
    },
    {
      option: "Resend Activation Link",
    },
  ];
  let [columnData, setColumnData] = useState([]);
  const [detailData, setDetailData] = useState({});
  let [hiddenColumnsData, setHiddenColumnsData] = useState([
    "email",
    "gender",
    "location",
    "highest_degree",
    "requested_clients",
    "shareable",
  ]);
  let resendActivationInterviewCoordinator = async (id) => {
    try {
      let response = await resendinterviewCoordinatorActivation(id);
      if (response.status === 200) {
        toast.success(
          <>
            <div className="toast-heading">Success</div>
            <div>{"Activation link resent successfully"}</div>
          </>
        );
      }
    } catch (err) {
      console.error(err, "bd list api error");
    }
  };
  function contextMenuFunction(event, resObj) {
    if (event.target.textContent === "Details") {
      setbdDetails(true);
      setDetailData(resObj);
      console.log("contextMenuFunctionDetails", resObj, event, bdDetails);
    }
    if (event.target.textContent === "Resend Activation Link") {
      console.log(resObj, "resObj");
      resendActivationInterviewCoordinator(resObj.id);
    }
    console.log("coming in");
  }
  let closeModal = (status) => {
    if (editBD) {
      setEditBD(status);
    } else if (addBD) {
      setaddBD(status);
    } else if (deleteModalOpen) {
      setDeleteModalOpen(status);
    }
  };
  let handelColumnsDispaly = (e) => {
    let value = e.target.value;
    let data = [...hiddenColumnsData];
    if (data.includes(value) && !e.target.checked) {
      data.splice(data.indexOf(value), 1);
    } else if (e.target.checked) {
      data.push(value);
    }
    setHiddenColumnsData([...data]);
  };
  const handleFilterSelection = (e, type, value) => {
    let selectedData = { ...selectedFilters };
    if (e.target.checked) {
      if (selectedData?.[type]?.length > 0) {
        selectedData?.[type].push(value);
      } else {
        selectedData[type] = [value];
      }
    } else {
      if (selectedData?.[type]?.length > 0) {
        let index = selectedData?.[type].indexOf(value);
        selectedData?.[type].splice(index, 1);
      }
    }
    setSelectedFilters({ ...selectedData });
  };
  const resetSearch = () => {
    setIsSearching(false);
    setpageNo(0);
    setLastpage(false);
    setTableApiData([]); // Clear existing search results
  };
  let handelCardSearch = async (e,page) => {
    // setSearchedValue(e.target.value);
    setIsSearching(true); // Set the flag to indicate search
    
      setSearchedText(e);
    try {
      let response = await adminCoOrdinatorSearch(e,page, size);
      if (
        response.status === 200 ||
        response?.data?.response ||
        response?.data
      ) {
        props.handleinterviewersCoordinateCount(
          response.data.response.totalElements
        );
        setLastpage(response?.data?.response?.last);
        if (response?.data?.response?.last === false) {
          setpageNo(page + 1);
        }
        let tabledata = { ...tableApiData };
        if (page === 0) {
          tabledata.content = [...response.data.response.content];
        } else {
          tabledata.content = tabledata.content.concat(
            response.data.response.content
          );
        }
        // if (cardSearchInput.current.value) {
        //   setSearchedValue("");
        //   cardSearchInput.current.value = "";
        // }
        setTableApiData(tabledata);
      }
    } catch (err) {
      console.error(err, "bd list api error");
    }
  };
  function debounce(callback, delay = 1000) {
    var time;
    return (...args) => {
      clearTimeout(time);
      time = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  }
  let actionele = (data) => {
    return (
      <div className="relative ">
        <Tooltip title="Edit" placement="top">
          <button
            className=" hover:text-orange-600"
            onClick={() => {
              setEditBD(true);
              setEditDetails(data);
            }}
          >
            <ModeEditIcon />
          </button>
        </Tooltip>
        <Tooltip title="Delete" placement="top">
          <button
            className="mx-2 hover:text-orange-600"
            onClick={() => {
              setDeleteModalOpen(true);
              setEditDetails(data);
            }}
          >
            <DeleteIcon />
          </button>
        </Tooltip>
        <AdminContextMenuModal
          contextMenuData={contextMenuData}
          bdData={data}
          func={(event) => {
            contextMenuFunction(event, data);
          }}
        />
      </div>
    );
  };
  let getCoordinateList = async (page) => {
    try {
      let response = await getInterviewersCoordinate(page, size);
      if (
        response.status === 200 ||
        response?.data?.response ||
        response?.data
      ) {
        props.handleinterviewersCoordinateCount(
          response.data.response.totalElements
        );
        setLastpage(response?.data?.response?.last);
        if (response?.data?.response?.last === false) {
          setpageNo(page + 1);
        }
        let tabledata = { ...tableApiData };
        if (page === 0) {
          tabledata.content = [...response.data.response.content];
        } else {
          tabledata.content = tabledata.content.concat(
            response.data.response.content
          );
        }
        // if (cardSearchInput.current.value) {
        //   setSearchedValue("");
        //   cardSearchInput.current.value = "";
        // }
        setTableApiData(tabledata);
      }
    } catch (err) {
      console.error(err, "bd list api error");
    }
  };
  console.log(tableApiData, "setTableApiData");
  const handlescroll = (event) => {
    if (
      Math.ceil(event?.target?.scrollTop + event?.target?.clientHeight) ===
      event?.target?.scrollHeight
    ) {
      if (lastPage === false && !isSearching) {
        getCoordinateList(pageno);
      } else if (isSearching && lastPage === false ) {
        handelCardSearch(searchedText, pageno);
      }
    }
  };
  let handelDlt = async (data) => {
    let response;
    try {
      response = await deleteinterviewercordinator(data.id);
      if (response.data) {
        toast.success(
          <>
            <div className="toast-heading">Success</div>
            <div>Interview Coordinator Deleted Successfully</div>
          </>
        );
        getCoordinateList(0);
        closeModal(false);
      }
    } catch (err) {
      console.error(err, "dlt bd api error");
    }
  };
  useEffect(() => {
    !isSearching && getCoordinateList(pageno);
    let contextData = [];
    tableApiData?.columnDef?.columns.map((data) => {
      contextData.push({
        option: data.columnName,
      });
    });
    setColumnData([...contextData]);
  }, []);
  return (
    <div className="bdtableComponent">
      <div className="searchComponent flex mb-2">
        <div className="searchInput">
          <input
            className="table-search-input"
            // onChange={handelCardSearch}
            onChange={(e) => {
              if (e.target.value.trim() === "") {
                resetSearch();
                getCoordinateList(0);
              } else {
                handelCardSearch(e.target.value, 0);
              }
            }}
            type="text"
            // ref={cardSearchInput}
            placeholder="Search"
          />
        </div>
        <CandidateColumnOption
          contextMenuData={columnData}
          func={(event) => {
            handelColumnsDispaly(event);
          }}
          btnClass={"hide-column-btn"}
          hiddenColumnsData={hiddenColumnsData}
        />
        <div className="filterBtn">
          {/* <button
            // onClick={() => {
            //   if (!openFilter) {
            //     fetchFilterOptions();
            //   }
            //   setOpenFilter(!openFilter);
            // }}
            className={`${openFilter ? "selected-btn" : null}`}
          >
            <span>
              <FilterIcon />
            </span>
            Filters
          </button> */}
        </div>
        <div
          className="addBtn"
          onClick={() => {
            setaddBD(true);
            setEditDetails({});
          }}
        >
          <button>+ Coordinator</button>
        </div>
      </div>
      {tableApiData?.data?.length === 0 ? (
        <>
          <div className="no-data-availableIcon">
            <NoDataFound />
          </div>
          <div className="no-data-availableText">
            <p>Oops !!</p> <p>There are no data to be found yet</p>
          </div>
        </>
      ) : (
        <CandidateTable
          tableApiData={tableApiData}
          actionele={actionele}
          searchedValue={searchedValue}
          isLoading={true}
          actionColumn={true}
          statusClmnName={"status"}
          actionColumnWidth={"15%"}
          profileColumn={"name"}
          handlescroll={handlescroll}
          hiddenColumnsData={hiddenColumnsData}
          hideRating={true}
        />
      )}
      {openFilter && (
        <div className="hire-filter">
          <div className="filter-header">
            <span>Filter By</span>
            <span className="apply-btn">
              <button
                onClick={() => {
                  setOpenFilter(!openFilter);
                }}
              >
                Apply
              </button>
            </span>
          </div>
          {filterOptions.map((data, index) => {
            return (
              <div className="individual-type">
                <div>{data.type}</div>
                {data.values.map((value) => {
                  return (
                    <div className="typeData">
                      <Checkbox
                        color="primary"
                        value={value}
                        // disableRipple
                        // disabled={
                        //   !props.data ||
                        //   (props.data && !props.data.length) ||
                        //   props.disableRootCheckbox
                        // }
                        checked={selectedFilters?.[data.type]?.includes(value)}
                        // indeterminate={isCheckAllIndeterminate}
                        onChange={(e) => {
                          handleFilterSelection(e, data.type, value);
                        }}
                        className="root-check-all"
                      />
                      <span>{value}</span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
      {addBD && (
        <AdminAddEditUser
          getUserList={getCoordinateList}
          usertype="interviewCoordinator"
          toast={toast}
          mode="add"
          closeModal={closeModal}
        />
      )}
      {editBD && (
        <AdminAddEditUser
          usertype="interviewCoordinator"
          mode="edit"
          toast={toast}
          getUserList={getCoordinateList}
          closeModal={closeModal}
          editDetails={editDetails}
        />
      )}
      {deleteModalOpen && (
        <DeleteInterViewerModal
          toast={toast}
          closeModal={closeModal}
          usertype="InterviewCoordinator"
          editDetails={editDetails}
          handelDlt={handelDlt}
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        className={"tost-msg-stylinh"}
        draggable={false}
        theme="light"
      />
      {bdDetails && (
        <BDdetailsModule
          usertype="InterviewCoordinator"
          setbdDetails={setbdDetails}
          detailData={detailData}
        />
      )}
    </div>
  );
};

export default InterviewersCoordinateList;
