import React, { memo, useEffect, useState } from "react";

import "./InterviewerCandidatesDetails.scss";

import { ToastContainer, toast } from "react-toastify";

const InterviewerCandidatesDetails = (props) => {
  let [candidateData, setCandidateData] = useState({ ...props.candidate });
  let [candidateDetails, setCandidateDetails] = useState({
    ...props.candidateDetailsData,
  });
  const [selectedFilename, setSelecteFilename] = useState("");
  const [selectedFile, setSelectedFile] = useState({});
  const [openResumeModal, setopenResumeModal] = useState(false);
  const [editResume, setEditResume] = useState(false);
  let userID = JSON.parse(localStorage.getItem("across-geo-user")).uid;
  const [resume, setResume] = useState("");
  const [selectingFile, setSelectingFile] = useState(false);
  let [preview, setPreview] = useState(false);
  let [filepath, setFilePath] = useState();
  let [uploadingResume, setUploadingResume] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [largeSize, setLargeSize] = useState(false);
  const handleOpen = () => {
    setopenResumeModal(true);
    setLargeSize(false);
  };
  const handleClose = () => {
    setLargeSize(false);
    setopenResumeModal(false);
    if (!candidateDetails.resume) {
      setResume("");
    }
    if (candidateDetails.resume && resume != candidateDetails.resume) {
      setResume(candidateDetails?.resume);
    }
  };
  console.log(resume, "resume");
  let getSkillsdata = (data) => {
    let string = "";
    data?.map((obj, index) => {
      if (index === 0) {
        string = obj.skillName;
      } else {
        string = string + "," + obj.skillName;
      }
    });
    return string;
  };

  let getLanguages = (data) => {
    return data?.join(", ");
  };

  useEffect(() => {
    setCandidateDetails({
      ...props.candidateDetailsData,
    });
    setCandidateData({
      ...props.candidateDetailsData.personalDetails,
    });
    setResume(props?.candidateDetailsData?.resume);
  }, [props.candidateDetailsData]);
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };
  return (
    <div className="candidate-details-modal">
      <div className="flex  profile-title-container">
        <span className="profile-icon">{candidateData?.name?.slice(0, 2)}</span>
        <span className="profile-title">
          <div className="name flex">
            {candidateData?.name} -{" "}
            <p className="mx-1 text-orange-600">Jobseeker</p>
          </div>
          <div>
            <ul>
              <li className="flex label-data">
                YOP :{" "}
                <p className="blackedText mx-1">
                  {(() => {
                    const highestEducation =
                      candidateDetails?.educationDetails?.highestEducation;
                    const masterDegreeYOP =
                      candidateDetails?.educationDetails?.masterDegree
                        ?.yearOfPassing;
                    const degreeYOP =
                      candidateDetails?.educationDetails?.degree?.yearOfPassing;
                    const diplomaYOP =
                      candidateDetails?.educationDetails?.diploma
                        ?.yearOfPassing;
                    const itiYOP =
                      candidateDetails?.educationDetails?.iti?.yearOfPassing;
                    const preDegree =
                      candidateDetails?.educationDetails?.preDegree;
                    const sslcYOP =
                      candidateDetails?.educationDetails?.sslc?.yearOfPassing;
                    if (highestEducation === "Master's Degree") {
                      return masterDegreeYOP || "--";
                    } else if (highestEducation === "Degree") {
                      return degreeYOP || "--";
                    } else if (highestEducation === "SSLC (10th)") {
                      return sslcYOP || "--";
                    } else if (highestEducation === "Diploma/PUC/ITI") {
                      if (diplomaYOP) {
                        return diplomaYOP;
                      } else if (itiYOP) {
                        return itiYOP;
                      } else if (preDegree && preDegree.includes("twelfth")) {
                        return (
                          candidateDetails?.educationDetails?.twelfth
                            ?.yearOfPassing || "--"
                        );
                      }
                    }

                    return "--"; // Default fallback if none of the conditions match
                  })()}
                </p>
              </li>
              <li className="flex label-data">
                Highest Education :{" "}
                <p className="blackedText mx-1">
                  {" "}
                  {candidateDetails?.educationDetails?.highestEducation}
                </p>
              </li>
              <li className="flex label-data">
              <p  style={{ width:"fit-Content", whiteSpace: "nowrap" }}
                  className="label-data">  Language Fluency :{" "}</p>
                <p className="blackedText mx-1">
                  {getLanguages(candidateDetails?.personalDetails?.languages)}
                </p>
              </li>
              <li className="flex">
                <p style={{ width: "65px" }} className="label-data">
                  Skills :
                </p>
                <p className="blackedText mx-1">
                  {"  "}
                  {getSkillsdata(candidateDetails?.technicalDetails?.skills)}
                </p>
              </li>
            </ul>
          </div>
        </span>
      </div>

      
      <div className="interview-additional-details">
      <div
        style={{
         
          top: "0",
          paddingBottom: "10px",
          textAlign: "left",
          fontFamily: "poppins-Medium",
          fontSize: "18px",
          fontWeight: "400",
          letterSpacing: "0px",
          color: "#282828",
          opacity: "1",
        }}
        className="detailsHeader"
      >
        Personal Details
      </div>
        <div className="details-set">
          <div className="data-body grid grid-cols-3 p-3">
            <div className="col-span-1 data-set">
              <div className="data-label">Phone Number</div>
              <div className="each-data datatrimmer">
                {candidateDetails?.personalDetails?.mobile
                  ? candidateDetails?.personalDetails?.mobile
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Email</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.email
                    ? candidateDetails?.personalDetails?.email
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.email
                  ? candidateDetails?.personalDetails?.email
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">DOB</div>
              <div className="each-data datatrimmer">
                {candidateDetails?.personalDetails?.dob
                  ? candidateDetails.personalDetails.dob
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Gender</div>
              <div className="each-data datatrimmer">
                {candidateDetails?.personalDetails?.gender
                  ? candidateDetails.personalDetails.gender
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Country</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.permanentAddress?.country
                    ? candidateDetails.personalDetails?.permanentAddress
                        ?.country
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.permanentAddress?.country
                  ? candidateDetails.personalDetails?.permanentAddress?.country
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">State</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.permanentAddress?.state
                    ? candidateDetails.personalDetails?.permanentAddress?.state
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.permanentAddress?.state
                  ? candidateDetails.personalDetails?.permanentAddress?.state
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">City</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.permanentAddress?.city
                    ? candidateDetails.personalDetails?.permanentAddress?.city
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.permanentAddress?.city
                  ? candidateDetails.personalDetails?.permanentAddress?.city
                  : "--"}
              </div>
            </div>
          </div>
        </div>
        <div className="details-set">
          <div className="detailsHeader">Current Address</div>
          <div className="data-body grid grid-cols-4 p-3">
            <div className="col-span-1 data-set">
              <div className="data-label">Country</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.currentAddress.country
                    ? candidateDetails?.personalDetails?.currentAddress.country
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.currentAddress.country
                  ? candidateDetails?.personalDetails?.currentAddress.country
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">State</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.currentAddress.state
                    ? candidateDetails?.personalDetails?.currentAddress.state
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.currentAddress.state
                  ? candidateDetails?.personalDetails?.currentAddress.state
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">City</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.currentAddress.city
                    ? candidateDetails.personalDetails.currentAddress.city
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.currentAddress.city
                  ? candidateDetails.personalDetails.currentAddress.city
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Pincode</div>
              <div className="each-data datatrimmer">
                {candidateDetails?.personalDetails?.currentAddress.pinCode
                  ? candidateDetails.personalDetails.currentAddress.pinCode
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Address</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.currentAddress?.description
                    ? candidateDetails.personalDetails?.currentAddress
                        ?.description
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.currentAddress?.description
                  ? candidateDetails.personalDetails?.currentAddress
                      ?.description
                  : "--"}
              </div>
            </div>
          </div>
        </div>
        <div className="details-set">
          <div className="detailsHeader">Permanent Address</div>
          <div className="data-body grid grid-cols-4 p-3">
            <div className="col-span-1 data-set">
              <div className="data-label">Country</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.permanentAddress.country
                    ? candidateDetails?.personalDetails?.permanentAddress
                        .country
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.permanentAddress.country
                  ? candidateDetails?.personalDetails?.permanentAddress.country
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">State</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.permanentAddress.state
                    ? candidateDetails?.personalDetails?.permanentAddress.state
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.permanentAddress.state
                  ? candidateDetails?.personalDetails?.permanentAddress.state
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">City</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.permanentAddress.city
                    ? candidateDetails.personalDetails.permanentAddress.city
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.permanentAddress.city
                  ? candidateDetails.personalDetails.permanentAddress.city
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Pincode</div>
              <div className="each-data datatrimmer">
                {candidateDetails?.personalDetails?.permanentAddress.pinCode
                  ? candidateDetails.personalDetails.permanentAddress.pinCode
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Address</div>
              <div
                className="each-data datatrimmer"
                title={
                  candidateDetails?.personalDetails?.permanentAddress
                    ?.description
                    ? candidateDetails.personalDetails?.permanentAddress
                        ?.description
                    : ""
                }
              >
                {candidateDetails?.personalDetails?.permanentAddress
                  ?.description
                  ? candidateDetails.personalDetails?.permanentAddress
                      ?.description
                  : "--"}
              </div>
            </div>
          </div>
        </div>
        <div className="details-set">
          <div className="detailsHeader">Technical Details</div>
          <div className="data-body grid grid-cols-3 p-3">
            <div className="col-span-1 data-set">
              <div className="data-label">Domain</div>
              <div className="each-data datatrimmer">
                {candidateDetails?.technicalDetails?.domain
                  ? candidateDetails.technicalDetails.domain
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="data-label">Job Role</div>
              <div className="each-data datatrimmer">
                {candidateDetails?.technicalDetails?.jobTitle
                  ? candidateDetails.technicalDetails.jobTitle
                  : "--"}
              </div>
            </div>
            <div className="col-span-1 data-set">
              <div className="dalabel">Skills</div>
              {candidateDetails?.technicalDetails?.skills?.length > 0 ? (
                <div
                  className="each-data datatrimmer"
                  title={getSkillsdata(
                    candidateDetails.technicalDetails.skills
                  )}
                >
                  {getSkillsdata(candidateDetails.technicalDetails.skills)}
                </div>
              ) : (
                <div>--</div>
              )}
            </div>
          </div>
        </div>
        <div className="details-set">
          <div className="detailsHeader">Education Details</div>
          <div className="acrobat-data-body ">
            {candidateDetails?.educationDetails?.masterDegree &&
              candidateDetails?.educationDetails?.masterDegree?.collegeName !==
                null && (
                <div className="acrobat-container flex items-stretch">
                  <div className="timeline-dot"></div>
                  <div className="education-name self-stretch ">
                    {" "}
                    Masters Degree
                  </div>
                  <div className="timeline-content grid grid-cols-3">
                    <div className="col-span-1 data-set">
                      <div
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.university
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.university
                            : ""
                        }
                        className="data-label"
                      >
                        University
                      </div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.university
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.university
                            : ""
                        }
                        style={{ width: "140px" }}
                      >
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.university
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.university
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">College Name</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.collegeName
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.collegeName
                            : ""
                        }
                        style={{ width: "140px" }}
                      >
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.collegeName
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.collegeName
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">Course</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.title
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.title
                            : ""
                        }
                        style={{ width: "140px" }}
                      >
                        {candidateDetails?.educationDetails?.masterDegree?.title
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.title
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">Specialization</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.stream
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.stream
                            : ""
                        }
                        style={{ width: "140px" }}
                      >
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.stream
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.stream
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">{`${
                        candidateDetails?.educationDetails?.masterDegree
                          ?.percentage > 0
                          ? "Percentage"
                          : "CGPA"
                      }`}</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.percentage > 0
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.percentage
                          : candidateDetails.educationDetails?.masterDegree
                              ?.cgpa > 0
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.cgpa
                          : ""}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">College Country</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.address?.country
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.address?.country
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.address?.country
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.address?.country
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">College State</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.address?.state
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.address?.state
                            : ""
                        }
                        style={{ width: "140px" }}
                      >
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.address?.state
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.address?.state
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">College City</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.masterDegree
                            ?.address?.city
                            ? candidateDetails.educationDetails?.masterDegree
                                ?.address?.city
                            : ""
                        }
                        style={{ width: "140px" }}
                      >
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.address?.city
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.address?.city
                          : "--"}
                      </div>
                    </div>

                    <div className="col-span-1 data-set">
                      <div className="data-label">Year of Joining</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.yearOfJoining
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.yearOfJoining
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">Year of Passing</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.yearOfPassing
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.yearOfPassing
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">Course Type</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.masterDegree
                          ?.courseType
                          ? candidateDetails.educationDetails?.masterDegree
                              ?.courseType
                          : "--"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {candidateDetails?.educationDetails?.degree &&
              candidateDetails?.educationDetails?.degree.collegeName !==
                null && (
                <div className="acrobat-container flex items-stretch">
                  <div className="timeline-dot"></div>
                  <div className="education-name self-stretch "> Degree</div>
                  <div className="timeline-content grid grid-cols-3">
                    <div className="col-span-1 data-set">
                      <div className="data-label">University</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.degree?.university
                            ? candidateDetails.educationDetails?.degree
                                ?.university
                            : ""
                        }
                        style={{ width: "140px" }}
                      >
                        {candidateDetails?.educationDetails?.degree?.university
                          ? candidateDetails.educationDetails?.degree
                              ?.university
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">College Name</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.degree
                            ?.collegeName
                            ? candidateDetails.educationDetails?.degree
                                ?.collegeName
                            : ""
                        }
                        style={{ width: "140px" }}
                      >
                        {candidateDetails?.educationDetails?.degree?.collegeName
                          ? candidateDetails.educationDetails?.degree
                              ?.collegeName
                          : "--"}
                      </div>
                    </div>
                    <div
                      style={{ width: "140px" }}
                      className="col-span-1 data-set"
                    >
                      <div className="data-label">Course</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.degree?.title
                            ? candidateDetails.educationDetails?.degree?.title
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.degree?.title
                          ? candidateDetails.educationDetails?.degree?.title
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">Specialization</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.degree?.stream
                            ? candidateDetails.educationDetails?.degree?.stream
                            : ""
                        }
                        style={{ width: "140px" }}
                      >
                        {candidateDetails?.educationDetails?.degree?.stream
                          ? candidateDetails.educationDetails?.degree?.stream
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">{`${
                        candidateDetails?.educationDetails?.degree?.percentage >
                        0
                          ? "Percentage"
                          : "CGPA"
                      }`}</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.degree
                          ?.percentage > 0
                          ? candidateDetails.educationDetails?.degree
                              ?.percentage
                          : candidateDetails.educationDetails?.degree?.cgpa > 0
                          ? candidateDetails.educationDetails?.degree?.cgpa
                          : ""}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">College Country</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.degree?.address
                            ?.country
                            ? candidateDetails.educationDetails?.degree?.address
                                ?.country
                            : ""
                        }
                      >
                        {candidateDetails?.educationDetails?.degree?.address
                          ?.country
                          ? candidateDetails.educationDetails?.degree?.address
                              ?.country
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">College State</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.degree?.address
                            ?.state
                            ? candidateDetails.educationDetails?.degree?.address
                                ?.state
                            : ""
                        }
                        style={{ width: "140px" }}
                      >
                        {candidateDetails?.educationDetails?.degree?.address
                          ?.state
                          ? candidateDetails.educationDetails?.degree?.address
                              ?.state
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">College City</div>
                      <div
                        className="each-data datatrimmer"
                        title={
                          candidateDetails?.educationDetails?.degree?.address
                            ?.city
                            ? candidateDetails.educationDetails?.degree?.address
                                ?.city
                            : ""
                        }
                        style={{ width: "140px" }}
                      >
                        {candidateDetails?.educationDetails?.degree?.address
                          ?.city
                          ? candidateDetails.educationDetails?.degree?.address
                              ?.city
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">Year of Joining</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.degree
                          ?.yearOfJoining
                          ? candidateDetails.educationDetails?.degree
                              ?.yearOfJoining
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">Year of Passing</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.degree
                          ?.yearOfPassing
                          ? candidateDetails.educationDetails?.degree
                              ?.yearOfPassing
                          : "--"}
                      </div>
                    </div>
                    <div className="col-span-1 data-set">
                      <div className="data-label">Course Type</div>
                      <div className="each-data datatrimmer">
                        {candidateDetails?.educationDetails?.degree?.courseType
                          ? candidateDetails.educationDetails?.degree
                              ?.courseType
                          : "--"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {candidateDetails?.educationDetails?.diploma && (
              <div className="acrobat-container flex items-stretch">
                <div className="timeline-dot"></div>
                <div className="education-name self-stretch "> Diploma</div>
                <div className="timeline-content grid grid-cols-3">
                  <div className="col-span-1 data-set">
                    <div className="data-label">College Name</div>
                    <div
                      className="each-data datatrimmer"
                      title={
                        candidateDetails?.educationDetails?.diploma?.collegeName
                          ? candidateDetails.educationDetails?.diploma
                              ?.collegeName
                          : ""
                      }
                      style={{ width: "140px" }}
                    >
                      {candidateDetails?.educationDetails?.diploma?.collegeName
                        ? candidateDetails.educationDetails?.diploma
                            ?.collegeName
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Board Name</div>
                    <div
                      className="each-data datatrimmer"
                      title={
                        candidateDetails?.educationDetails?.diploma?.boardName
                          ? candidateDetails.educationDetails?.diploma
                              ?.boardName
                          : ""
                      }
                      style={{ width: "140px" }}
                    >
                      {candidateDetails?.educationDetails?.diploma?.boardName
                        ? candidateDetails.educationDetails?.diploma?.boardName
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Specialization</div>
                    <div
                      className="each-data datatrimmer"
                      title={
                        candidateDetails?.educationDetails?.diploma?.stream
                          ? candidateDetails.educationDetails?.diploma?.stream
                          : ""
                      }
                      style={{ width: "140px" }}
                    >
                      {candidateDetails?.educationDetails?.diploma?.stream
                        ? candidateDetails.educationDetails?.diploma?.stream
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">{`${
                      candidateDetails?.educationDetails?.diploma?.percentage >
                      0
                        ? "Percentage"
                        : "CGPA"
                    }`}</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.diploma?.percentage >
                      0
                        ? candidateDetails.educationDetails?.diploma?.percentage
                        : candidateDetails.educationDetails?.diploma?.cgpa > 0
                        ? candidateDetails.educationDetails?.diploma?.cgpa
                        : ""}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Joining</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.diploma
                        ?.yearOfJoining
                        ? candidateDetails.educationDetails?.diploma
                            ?.yearOfJoining
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Passing</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.diploma
                        ?.yearOfPassing
                        ? candidateDetails.educationDetails?.diploma
                            ?.yearOfPassing
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Course Type</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.diploma?.courseType
                        ? candidateDetails.educationDetails?.diploma?.courseType
                        : "--"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {candidateDetails?.educationDetails?.twelfth && (
              <div className="acrobat-container flex items-stretch">
                <div className="timeline-dot"></div>
                <div className="education-name self-stretch "> PUC</div>
                <div className="timeline-content grid grid-cols-3">
                  <div className="col-span-1 data-set">
                    <div className="data-label">{`${
                      candidateDetails?.educationDetails?.twelfth?.percentage >
                      0
                        ? "Percentage"
                        : "CGPA"
                    }`}</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.twelfth?.percentage >
                      0
                        ? candidateDetails.educationDetails?.twelfth?.percentage
                        : candidateDetails.educationDetails?.twelfth?.cgpa > 0
                        ? candidateDetails.educationDetails?.twelfth?.cgpa
                        : ""}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Joining</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.twelfth
                        ?.yearOfJoining
                        ? candidateDetails.educationDetails?.twelfth
                            ?.yearOfJoining
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Passing</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.twelfth
                        ?.yearOfPassing
                        ? candidateDetails.educationDetails?.twelfth
                            ?.yearOfPassing
                        : "--"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {candidateDetails?.educationDetails?.iti && (
              <div className="acrobat-container flex items-stretch">
                <div className="timeline-dot"></div>
                <div className="education-name self-stretch "> ITI</div>
                <div className="timeline-content grid grid-cols-3">
                  <div className="col-span-1 data-set">
                    <div className="data-label">College Name</div>
                    <div
                      className="each-data datatrimmer"
                      title={
                        candidateDetails?.educationDetails?.iti?.collegeName
                          ? candidateDetails.educationDetails?.iti?.collegeName
                          : ""
                      }
                      style={{ width: "140px" }}
                    >
                      {candidateDetails?.educationDetails?.iti?.collegeName
                        ? candidateDetails.educationDetails?.iti?.collegeName
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Board Name</div>
                    <div
                      className="each-data datatrimmer"
                      title={
                        candidateDetails?.educationDetails?.iti?.boardName
                          ? candidateDetails.educationDetails?.iti?.boardName
                          : ""
                      }
                      style={{ width: "140px" }}
                    >
                      {candidateDetails?.educationDetails?.iti?.boardName
                        ? candidateDetails.educationDetails?.iti?.boardName
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Specialization</div>
                    <div
                      className="each-data datatrimmer"
                      title={
                        candidateDetails?.educationDetails?.iti?.stream
                          ? candidateDetails.educationDetails?.iti?.stream
                          : ""
                      }
                      style={{ width: "140px" }}
                    >
                      {candidateDetails?.educationDetails?.iti?.stream
                        ? candidateDetails.educationDetails?.iti?.stream
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">{`${
                      candidateDetails?.educationDetails?.iti?.percentage > 0
                        ? "Percentage"
                        : "CGPA"
                    }`}</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.iti?.percentage > 0
                        ? candidateDetails.educationDetails?.iti?.percentage
                        : candidateDetails.educationDetails?.iti?.cgpa > 0
                        ? candidateDetails.educationDetails?.iti?.cgpa
                        : ""}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Joining</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.iti?.yearOfJoining
                        ? candidateDetails.educationDetails?.iti?.yearOfJoining
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Passing</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.iti?.yearOfPassing
                        ? candidateDetails.educationDetails?.iti?.yearOfPassing
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Course Type</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.iti?.courseType
                        ? candidateDetails.educationDetails?.iti?.courseType
                        : "--"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {candidateDetails?.educationDetails?.sslc && (
              <div className="acrobat-container flex items-stretch">
                <div className="timeline-dot"></div>
                <div className="education-name self-stretch ">
                  {" "}
                  SSLC {`(10th)`}
                </div>
                <div className="timeline-content grid grid-cols-3">
                  <div className="col-span-1 data-set">
                    <div className="data-label">{`${
                      candidateDetails?.educationDetails?.sslc?.percentage > 0
                        ? "Percentage"
                        : "CGPA"
                    }`}</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.sslc?.percentage > 0
                        ? candidateDetails.educationDetails?.sslc?.percentage
                        : candidateDetails.educationDetails?.sslc?.cgpa > 0
                        ? candidateDetails.educationDetails?.sslc?.cgpa
                        : ""}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Joining</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.sslc?.yearOfJoining
                        ? candidateDetails.educationDetails?.sslc?.yearOfJoining
                        : "--"}
                    </div>
                  </div>
                  <div className="col-span-1 data-set">
                    <div className="data-label">Year of Passing</div>
                    <div className="each-data datatrimmer">
                      {candidateDetails?.educationDetails?.sslc?.yearOfPassing
                        ? candidateDetails.educationDetails?.sslc?.yearOfPassing
                        : "--"}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {candidateDetails?.workHistories?.length > 0 && (
          <div className="details-set mb-4">
            <div className="detailsHeader">Work History</div>
            <div className="acrobat-data-body ">
              {candidateDetails?.workHistories?.map((cmp, index) => {
                return (
                  <div className="acrobat-work-container flex items-stretch">
                    <div className="timeline-dot"></div>
                    {/* <div className="education-name self-stretch " title={cmp?.company ? cmp?.company : ""}>
                      {" "}
                      {cmp?.company.length >= 20 ?cmp?.company.substring(0, 20)+"...":cmp?.company}
                    </div> */}
                    <div class="education-name self-stretch"  title={cmp?.company}>
                      <p  style={{width:"150px"}} class="text-sm whitespace-nowrap overflow-hidden text-ellipsis each-data">
                      {truncateText(cmp?.company, 12)}
                      </p>
                    </div>
                    <div className="timeline-content grid grid-cols-10">
                      {/* <div className="col-span-3 custom_data-set">
                        <div className="data-label">Job Title</div>
                        <p
                          className="each-data datatrimmer"
                          title={cmp?.jobTitle ? cmp?.jobTitle : ""}
                         
                        >
                          {cmp?.jobTitle ? cmp?.jobTitle : "--"}
                        </p>
                      </div> */}
                      <div class="col-span-3 custom_data-set" title={cmp?.jobTitle}>
                        <p class="text-sm font-semibold">Job Title</p>
                        <p class="text-sm whitespace-nowrap overflow-hidden text-ellipsis each-data"  style={{ width: "140px" }}>
                          {cmp?.jobTitle ? cmp?.jobTitle : "--"}
                        </p>
                      </div>
                      <div className="col-span-7 grid grid-cols-3">
                        {/* <div className="col-span-1 data-set">
                          <div className="data-label">City</div>
                          <div
                            className="each-data datatrimmer"
                            title={cmp?.city ? cmp?.city : ""}
                            style={{ width: "140px" }}
                          >
                            {cmp?.city ? cmp?.city : "--"}
                          </div>
                        </div>
                        <div className="col-span-1 data-set">
                          <div className="data-label">From</div>
                          <div className="each-data datatrimmer">
                            {cmp?.startDate ? cmp?.startDate : "--"}
                          </div>
                        </div>
                        <div className="col-span-1 data-set">
                          <div className="data-label">To</div>
                          <div className="each-data datatrimmer">
                            {cmp?.endDate ? cmp?.endDate : "--"}
                          </div>
                        </div> */}

                        <div
                          class="col-span-1 custom_data-set"
                          title={cmp?.city ? cmp?.city : ""}
                        >
                          <p class="text-sm font-semibold">City</p>
                          <p class="text-sm whitespace-nowrap overflow-hidden text-ellipsis each-data">
                          {truncateText(cmp?.city, 9)} 
                          </p>
                        </div>
                        <div
                          class="col-span-1 custom_data-set"
                          title={cmp?.startDate ? cmp?.startDate : ""}
                        >
                          <p class="text-sm font-semibold">From</p>
                          <p class="text-sm whitespace-nowrap overflow-hidden text-ellipsis each-data">
                            {cmp?.startDate}
                          </p>
                        </div>
                        <div
                          class="col-span-1 custom_data-set"
                          title={cmp?.endDate ? cmp?.endDate : ""}
                        >
                          <p class="text-sm font-semibold">To</p>
                          <p class="text-sm whitespace-nowrap overflow-hidden text-ellipsis each-data">
                            {cmp?.endDate}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default memo(InterviewerCandidatesDetails);
