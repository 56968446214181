import React, { useEffect, useState } from 'react'
import NavigationHeader from '../nav-section/NavigationHeader'
import './JobSearchHome.scss'
import SearchIcon from "../../../assets/searchIcon.svg";
import FilterSection from './filter-section/FilterSection';
import { useNavigate } from "react-router-dom";
import FooterSectionHome from '../footer-section/FooterSectionHome';
import JobDataSection from './data-section/JobDataSection';
import { useTheme } from '@mui/material/styles';
import { useLocation } from "react-router-dom";
import { getFilteredJobs, getIndustriesListReq, getTotalCitiesReq } from '../../../api/api-services';
import { MultiSelectDropdown } from '../../landing-page/common/select-dropdown';
import MyLocation from "../website-assets/myLocation.png"
import zoomIcon from "../../../assets/zoomicon.svg" 
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CategoryIcon from "../website-assets/categoryIcon.svg"
import ReactSelectDropdown from '../../landing-page/common/react-select-dropdown';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: state.isFocused ? 'none' : provided.boxShadow,
        '&:hover': {
            borderColor: 'transparent',
        },
        borderColor: 'transparent',
    }),
};

const style = {
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    left: '15%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    height: "100%",
};

function JobSearchHome() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [searchValue, setSearchValue] = useState(state?.searchValue ? state?.searchValue : "");
    const [citiesValue, setCitiesValue] = useState([]);
    const [selectedcitiesValue, setSelectedCitiesValue] = useState([]);
    const [industryData, setIndustryData] = useState([]);
    const [selectedIndustryValue, setSelectedIndustryValue] = useState(state?.category ? state.category : [])
    const [filteredJobs, setFilteredJobs] = useState({})
    const [paylodedata, setPaylodedata] = useState(0)
    const [apply, setApply] = useState(0)
    const [sideFilter, setSideFilter] = useState(false)
    const [filtersSelected, setFiltersSelected] = useState({
        WorkExperience: [],
        EmploymentType: [],
        Course: [],
        expId : []
    })
    const [page, setPage] = useState(0);
    const [pagination, setPagination] = useState(1)

    const handleClose = () => {
        setSideFilter(false)
    }

    // useEffect(() => {
     
    // }, [state])
    
    console.log(state,"state")

    console.log(state?.category, "state?.category");
    // Event handler for input change
    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    // Event handler for search button click
    const handleSearchClick = () => {
        getFilteredData(selectedcitiesValue, selectedIndustryValue, searchValue, "searchedValue")
    };

    const jobExperienceSort = (data) => {
        //Initialize variables to store overall min and max values
        let overallMin = Infinity;
        let overallMax = -Infinity;
        let tempArr = [...data]
        console.log(tempArr, "tempArr")

        // Loop through the array and update overall min and max values
        tempArr.forEach(obj => {
            overallMin = Math.min(overallMin, obj.min);
            overallMax = Math.max(overallMax, obj.max);
        });

        // Form a new array with a single object containing overall min and max values
        const resultArray = [{ min: overallMin, max: overallMax }];

        console.log(resultArray, "resultArray");
        return resultArray
    }

    const getAllCityOptions = async () => {
        try {
            let response = await getTotalCitiesReq();
            let cityData = [];
            if (response?.data?.response?.length > 0) {
                response?.data?.response.forEach((data) => {
                    cityData.push({ label: data, value: data });
                });
            }
            setCitiesValue([...cityData]);
        } catch (err) {
            console.error(err, "All cities api error");
        }

    }
    const fetchCompanies = async () => {
        try {
            let response = await getIndustriesListReq();
            let cityData = [];
            if (response?.data?.response?.length > 0) {
                response?.data?.response.forEach((data) => {
                    cityData.push({ label: data, value: data });
                });
            }
            setIndustryData([...cityData]);
        } catch (err) {
            console.error(err, "All categories api error");
        }
    };

    const preparePayload = (city, industry, textValue) => {
        let payload = {};
        if (searchValue != "") {
            payload["jobHeadlines"] = [searchValue];
        }
        if (Array.isArray(city) && city?.length != 0) {
            console.log("entering city loop")
            let cityData = []
            city.map((data) => {
                cityData.push(data.value)
            })
            payload = { ...payload, ["location"]: cityData }
        }

        if (Array.isArray(selectedIndustryValue) && selectedIndustryValue?.length != 0) {
            payload = { ...payload, ["industry"]: [selectedIndustryValue[0].value] }
        }

        if (filtersSelected?.Course?.length != 0) {
            payload["highestQualification"] = filtersSelected.Course
        }

        if (filtersSelected?.WorkExperience?.length != 0) {
            let data = jobExperienceSort(filtersSelected.WorkExperience)
            payload["workExperience"] = data;
        }

        if (filtersSelected.EmploymentType.length != 0) {
            payload["employmentType"] = filtersSelected.EmploymentType
        }

        function isEmpty() {
            for (const prop in payload) {
                if (Object.hasOwn(payload, prop)) {
                    return false;
                }
            }
            return true;
        }

        if (state === null && isEmpty()) {
            payload = null
        }
        console.log(payload, "payload")
        return payload
    }

    const getFilteredData = async (city, industry, textValue, type) => {
        let payload = type === "searchedValue" ? preparePayload(city, industry, textValue) : textValue;
        let pageno = type === "searchedValue" ? 0 : page
        console.log(payload, "payload in apicall")
        if (payload != null && Object.keys(payload).length === 0) {
            payload = null
        }
        try {
            const response = await getFilteredJobs(page, 10, payload)
            setFilteredJobs(response.data.response)
        } catch (err) {
            console.log(`error in filter jobs API`)
        }
    }

    useEffect(() => {
        fetchCompanies()
        console.log(state, "state from nav")
        getAllCityOptions()
    }, [])

    useEffect(() => {
        let payload = preparePayload(selectedcitiesValue, selectedIndustryValue, searchValue)
        if (payload != null && apply === -1) {
            delete payload["highestQualification"];
            delete payload["workExperience"];
            delete payload["employmentType"];
            if (searchValue === "" && selectedcitiesValue.length < 1 && selectedIndustryValue < 1) {
                payload = null
            }
        }
        getFilteredData(page, 10, payload, "onPageChange")
    }, [page, paylodedata, apply])


    const options = [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Option 3', value: 'option3' },
        // Add more options as needed
    ];

    const handledropChange = (data) => {
        setSelectedIndustryValue([...data]);
    };

    const handleChange = (data) => {
        setSelectedCitiesValue([...data]);
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top on component mount

        const handleRefresh = () => {
            window.scrollTo(0, 0); // Scroll to the top on page refresh
        };

        window.addEventListener("beforeunload", handleRefresh);

        return () => {
            window.removeEventListener("beforeunload", handleRefresh);
        };
    }, []);

    return (
        <div className='JobSearch_Container'>
            <div className="navbarSection-jobSearch">
                <NavigationHeader />
            </div>
            <div className="searchHeader" >
                <p className='screenHeader'>
                    Find Your Dream Jobs
                </p>
                <div className='flex justify-content-center'>
                    <div className='row search-container1'>
                        <div className='col-lg-3 col-md-12 flex  flex judtify-content-cenyter paddingresp'>
                            <div class="search-icon zoomicon">
                                <img src={zoomIcon} />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="text"
                                    placeholder="Job title"
                                    value={searchValue}
                                    onChange={handleInputChange}
                                    class="search-input"
                                />
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-12  flex judtify-content-cenyter'>
                            <div className="filterBox">
                                <MultiSelectDropdown
                                    data={citiesValue}
                                    handleSelectChange={handleChange}
                                    selectedData={selectedcitiesValue}
                                    // placeholder={"All Cities"}
                                    placeholder={
                                        <label className="flex">
                                            <img src={MyLocation} style={{ height: "22px", width: "22px" }} />
                                            <label className="mx-2"> All Cities</label>
                                        </label>
                                    }
                                />

                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12  flex judtify-content-cenyter'>
                            <div style={{ borderRight: "1px solid #EEEEEE" }} className="filterBox">
                                {console.log(selectedIndustryValue,"selectedIndustryValue")}
                                <MultiSelectDropdown
                                    data={industryData}
                                    handleSelectChange={handledropChange}
                                    selectedData={selectedIndustryValue}
                                    placeholder={
                                        <label className="flex">
                                            <img src={CategoryIcon} style={{ height: "22px", width: "22px" }} />
                                            <label className="mx-2"> All Categories</label>
                                        </label>
                                    }
                                />
                            </div>
                        </div>
                        <div  className='col-lg-3 flex judtify-content-cenyter col-md-12' >
                            
                                <div className='col-md-6 differ' onClick={() => {
                                    setSelectedCitiesValue([]);
                                    setSelectedIndustryValue([]);
                                    setSearchValue("");
                                    if (state) {
                                        state.searchValue = ""
                                        state.category = []
                                    }
                                    navigate("/job-search", { state: {} })
                                    setPage(0)
                                    setPaylodedata(paylodedata + 1)
                                }} class="clearBtn clearBtnaligen cursor-pointer">
                                    Clear
                                </div>
                                <div style={{ width: "0px" }} className='col-mg-6 col-sm-12'>
                                    <button
                                        type="submit"
                                        onClick={handleSearchClick}
                                        class="search-button1 marginreresponse"
                                    >
                                        Search
                                    </button>
                                </div>
                           
                        </div>
                    </div>
                </div>

                {/* <div className=' flex justify-center'>
                    <div class="search-container1">
                        <div class="search-icon">
                            <img src={SearchIcon} />
                        </div>
                        <input
                            type="text"
                            name="text"
                            placeholder="Job title"
                            value={searchValue}
                            onChange={handleInputChange}
                            class="search-input"
                        />
                        <div class="filter-icon"></div>
                        <div className="filterBox">
                            <MultiSelectDropdown
                                data={citiesValue}
                                handleSelectChange={handleChange}
                                selectedData={selectedcitiesValue}
                                // placeholder={"All Cities"}
                                placeholder={
                                    <label className="flex">
                                        <img src={MyLocation} style={{ height: "22px", width: "22px" }} />
                                        <label className="mx-2"> All Cities</label>
                                    </label>
                                }
                            />

                        </div>
                        <div className="filterBox">
                            <MultiSelectDropdown
                                data={industryData}
                                handleSelectChange={handledropChange}
                                selectedData={selectedIndustryValue}
                                placeholder={
                                    <label className="flex">
                                        <img src={CategoryIcon} style={{ height: "22px", width: "22px" }} />
                                        <label className="mx-2"> All Categories</label>
                                    </label>
                                }
                            />
                        </div>
                        <div style={{ borderLeft: "1px solid #EEEEEE" }} className='flex float-right'>
                            <div onClick={() => {
                                setSelectedCitiesValue([]);
                                setSelectedIndustryValue([]);
                                setSearchValue("");
                                if (state) {
                                    state.searchValue = ""
                                    state.category = []
                                }
                                navigate("/job-search", { state: {} })
                                setPage(0)
                                setPaylodedata(paylodedata + 1)
                            }} class="clearBtn cursor-pointer">
                                Clear
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    onClick={handleSearchClick}
                                    class="search-button1"
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='marginClass'>
                <div className='flex  justify-center' >
                    <div className='filterBox_div d-none d-lg-block '>
                        <FilterSection
                            setFiltersSelected={setFiltersSelected}
                            filtersSelected={filtersSelected}
                            setApply={setApply}
                            setPage={setPage}
                            filteredJobs={filteredJobs}
                            setPagination={setPagination}
                            apply={apply}
                            pagination={pagination}
                        />
                    </div>
                    <div className='cardList_div'>
                        <JobDataSection
                            setSideFilter={setSideFilter}
                            sideFilter={sideFilter}
                            filteredJobs={filteredJobs}
                            page={page}
                            setPage={setPage}
                            setPagination={setPagination}
                            pagination={pagination}
                        />
                    </div>
                </div>
            </div>
            <div>
                <FooterSectionHome />
            </div>
            {sideFilter &&
                <div>
                    <Modal
                        open={sideFilter}
                        onClose={handleClose}
                    >
                        <Box sx={style}>
                            <FilterSection
                                modfilter = {"modFilter"}
                                setFiltersSelected={setFiltersSelected}
                                handleClose={handleClose}
                                filteredJobs={filteredJobs}
                                filtersSelected={filtersSelected}
                                setApply={setApply}
                                setPage={setPage}
                                setPagination={setPagination}
                                apply={apply}
                                pagination={pagination}
                            />
                        </Box>
                    </Modal>
                </div>
            }
        </div >
    )
}

export default JobSearchHome