import React from "react";
import { useState } from "react";
import JobCard from "./JobCard";
import PostJobModal from "./modals/PostJobModal";
import "./PostJobList.scss";
import { useRef } from "react";
import JobDetails from "./modals/JobDetails";
import CardWithTableDetails from "./CardWithTableDetails";
import { getJobListClientReq ,clientJobPostSearch} from "../../api/api-services";
import { useEffect } from "react";
import { jwtDecode } from 'jwt-decode'
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as NoDataFound } from "../../assets/noDataFound.svg";

const PostJobList = () => {
  let [postJobModal, setPostJobModal] = useState(false);
  let [postJobDetails, setPostDetails] = useState(false);
  let [jobTableDetails, setJobTableDetails] = useState(false);
  const [mode, setMode] = useState("");
  const [selectedJob, setSelectedJob] = useState({});
  const cardSearchInput = useRef(null);
  let [jobs, setjobs] = useState([]);
  let user = JSON.parse(localStorage.getItem("across-geo-user"));
  let [jobList, setJobList] = useState([]);
  let [jobListCount ,setJobListCount] =useState();
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let size = 10;
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  let token = JSON.parse(localStorage.getItem("across-geo-user")).access_token;
  const mailId = jwtDecode(token).email;
  let closeModal = (val) => {
    if (postJobModal) {
      setPostJobModal(false);
      if (mode === "edit") {
        setMode("");
      }
    }
    if (postJobDetails) {
      setPostDetails(false);
      setMode("");
    }
    if (jobTableDetails) {
      setJobTableDetails(false);
      setMode("");
    }
  };
  // let handelCardSearch = debounce((e) => {
  //   let fieldValue = e.target.value.toLowerCase();
  //   let jobData = [];

  //   jobs.map((job) => {
  //     let skillSet = false;
  //     for (let i = 0; i < job.keySkills.length; i++) {
  //       if (job.keySkills[i].toLowerCase().includes(fieldValue)) {
  //         skillSet = true;
  //         break;
  //       }
  //     }
  //     let LocationSearched = false;
  //     for (let i = 0; i < job.location.length; i++) {
  //       if (job.location[i].toLowerCase().includes(fieldValue)) {
  //         skillSet = true;
  //         break;
  //       }
  //     }
  //     if (
  //       job?.jobHeadlines?.toLowerCase().includes(fieldValue) ||
  //       job?.roles?.toLowerCase().includes(fieldValue) ||
  //       job?.vacancyCount?.toString().includes(fieldValue) ||
  //       // job?.addedProfiles?.toLowerCase()?.includes(fieldValue) ||
  //       job?.jobDescription?.toLowerCase().includes(fieldValue) ||
  //       LocationSearched ||
  //       skillSet
  //     ) {
  //       jobData.push({ ...job });
  //     }
  //     console.log(job)
  //   });
  //   setJobList([...jobData]);
  // });
  const filterJobs = (jobs, fieldValue) => {
    const lowerCaseFieldValue = fieldValue.toLowerCase();
    return jobs.filter((job) => {
      const hasKeywordInSkills = job.keySkills.some((skill) =>
        skill.toLowerCase().includes(lowerCaseFieldValue)
      );
  
      const hasKeywordInLocation = job.location.some((location) =>
        location.toLowerCase().includes(lowerCaseFieldValue)
      );
  
      return (
        job.jobHeadlines.toLowerCase().includes(lowerCaseFieldValue) ||
        job.roles.toLowerCase().includes(lowerCaseFieldValue) ||
        job.vacancyCount.toString().includes(lowerCaseFieldValue) ||
        // job.addedProfiles.toLowerCase().includes(lowerCaseFieldValue) ||
        job.jobDescription.toLowerCase().includes(lowerCaseFieldValue) ||
        hasKeywordInLocation ||
        hasKeywordInSkills
      );
    });
  };
  
  const handelCardSearch = debounce((e) => {
    const fieldValue = e.target.value;
    const filteredJobs = filterJobs(jobs, fieldValue);
    setJobList(filteredJobs);
  });
  
  function debounce(callback, delay = 1000) {
    var time;
    return (...args) => {
      clearTimeout(time);
      time = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  }
  const handelCardClick = (data, event) => {
    if (data.status !== "CANCEL") {
      setSelectedJob({ ...data });
      setMode("table details");
      setJobTableDetails(true);
    }
  };
  const handleEdit = (data) => {
    if (data.status !== "CANCEL") {
      console.log(data)
      setSelectedJob({ ...data });
      setMode("edit");
      setPostJobModal(true);
    }
  };
  const handelMoreBtn = (data, type) => {
    // if (data.status !== "CANCEL") {
    //   setSelectedJob({ ...data });
    //   if (type === "Details") {
    //     setMode("Details");
    //     setPostDetails(true);
    //   }
    // }
    setSelectedJob({ ...data });
      if (type === "Details") {
        setMode("Details");
        setPostDetails(true);
      }
  };
  const getJobList = async (page) => {
    try {
      let response = await getJobListClientReq(user.uid, page, size);
      console.log(response);
      if (response.status === 200 || response?.data?.response)
        console.log(response?.data?.response?.last);
      setLastpage(response?.data?.response?.last);
      if (response?.data?.response?.last === false) {
        setpageNo(page + 1);
      }
      if (page === 0) {
        setjobs([
          ...JSON.parse(JSON.stringify(response.data.response?.content)),
        ]);
        setJobList([...response?.data?.response?.content]);
        setJobListCount(response?.data?.response?.totalElements);
      } else {
        setjobs([
          ...jobs,
          ...JSON.parse(JSON.stringify(response.data?.response?.content)),
        ]);
        setJobList([...jobList, ...response?.data?.response?.content]);
      }
    } catch (err) {
      console.error(err, "getJobList req error");
    }
  };

  // const handlescroll = (event) => {
  //   console.log(lastPage, "-----------------method");
  //   if (
  //     Math.ceil(event?.target?.scrollTop + event?.target?.clientHeight) ===
  //     event?.target?.scrollHeight
  //   ) {
  //     if (lastPage === false) {
  //       getJobList(pageno);
  //     }
  //   }
  // };
  const handlescroll = (event) => {
    const target = event?.target;
    if (!target) return;
  
    const scrolledToBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >= target.scrollHeight - 1;
  
    if (scrolledToBottom && !lastPage && !isSearching) {
      getJobList(pageno);
    }else if(scrolledToBottom && !lastPage  && isSearching){
      searchedJobList(searchTerm, pageno)
    }
  };
  const resetSearch = () => {
    setIsSearching(false);
    setpageNo(0);
    setLastpage(false);
    setJobList([]);
  };
  const searchedJobList = async (e,page) => {
    try {
      setIsSearching(true);
      let response = await clientJobPostSearch(e, page, size,user.uid);
      console.log(response);
      if (response.status === 200 || response?.data?.response)
        console.log(response?.data?.response?.last);
      setLastpage(response?.data?.response?.last);
      if (response?.data?.response?.last === false) {
        setpageNo(page + 1);
      }
      if (page === 0) {
        setjobs([
          ...JSON.parse(JSON.stringify(response.data.response?.content)),
        ]);
        setJobList([...response?.data?.response?.content]);
        setJobListCount(response?.data?.response?.totalElements);
      } else {
        setjobs([
          ...jobs,
          ...JSON.parse(JSON.stringify(response.data?.response?.content)),
        ]);
        setJobList([...jobList, ...response?.data?.response?.content]);
      }
    } catch (err) {
      console.error(err, "getJobList req error");
    }
  };
  useEffect(() => {
    getJobList(pageno);
  }, []);
  if (postJobModal) {
    return (
      <PostJobModal
        toast={toast}
        selectedJob={selectedJob}
        getJobList={getJobList}
        mode={mode}
        closeModal={closeModal}
      />
    );
  } else if (postJobDetails) {
    return (
      <JobDetails
        toast={toast}
        selectedJob={selectedJob}
        closeModal={closeModal}
      />
    );
  } else if (jobTableDetails) {
    return (
      <CardWithTableDetails
        toast={toast}
        selectedJob={selectedJob}
        handleEdit={handleEdit}
        handelMoreBtn={handelMoreBtn}
        closeModal={closeModal}
        getJobList={getJobList}
      />
    );
  } else {
    return (
      <div className="job-list-page">
        <div className="hire-now-btn ml-auto">
        <span>
           My Job Posts{jobListCount!==undefined && "-" +jobListCount}
          </span>
          <button
            className="datatrimmer"
            onClick={() => {
              setPostJobModal(true);
            }}
            title={""}
          >
            Post a Job
          </button>
          <input
            className="job-list-search-input col-span-2 "
            // onChange={(e) => {
            //   handelCardSearch(e);
            // }}
            onChange={(e) => {
                setSearchTerm(e.target.value);
                if (e.target.value.trim() === "") {
                  resetSearch();
                  getJobList(0);
                } else {
                  searchedJobList(e.target.value, 0);
                }
              }}
            type="text"
            // ref={cardSearchInput}
            placeholder="Search"
          />
        </div>
        <div
          className="job-card-container"
          onScroll={(e) => {
            handlescroll(e);
          }}
        >
          {jobList.map((job, index) => {
            return (
              <JobCard
                job={job}
                index={index}
                handleEdit={handleEdit}
                handelMoreBtn={handelMoreBtn}
                handelCardClick={handelCardClick}
              />
            );
          })}
          {jobList.length === 0 && (
            <>
              <div className="no-data-availableIcon">
                <NoDataFound />
              </div>
              <div className="no-data-availableText">
                <p>Oops !!</p> <p>There are no data to be found yet</p>
              </div>
            </>
          )}
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          className={"tost-msg-stylinh"}
          draggable={false}
          theme="light"
        />
      </div>
    );
  }
};

export default PostJobList;
