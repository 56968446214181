import React from "react";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { ReactComponent as NoDataFound } from "../../../assets/noDataFound.svg";

const JobCard = ({
  job,
  buttonText,
  onClick,
  selectedTab,
  resumeCount,
  props,
}) => {
  let buttonClassName = "";

  if (selectedTab === 0) {
    buttonClassName += "shareProfileBtn";
  } else if (selectedTab === 1) {
    buttonClassName += "resumeButton";
  } else if (selectedTab === 2) {
    buttonClassName += "offeredButton";
  }
  return (
    <div
      className={
        job.status === "CANCEL"
          ? " opacity-50 cardContent m-2 flex  border border-gray-400 rounded-lg shadow-md border-opacity-0 transform"
          : " opacity-100 cardContent m-2 flex  border border-gray-400 rounded-lg shadow-md border-opacity-0 transform"
      }
    >
      <div className="leftCardItem  ">
        <div className="leftCardItemContent grid grid-cols-3 gap-4 ">
          <div className="col-span-12">
            <div className="label">Company Name</div>
            <div className="value">{job.companyName}</div>
          </div>
          <div className="col-span-12">
            <div className="label">Client Name</div>
            <div className="value">{job.clientName}</div>
          </div>
          {/* <div className="col-span-12 flex">
                      <div className="label"><RoomOutlinedIcon/></div>
                      <div className="value  mx-2">{job.location}</div>
                    </div> */}
        </div>
      </div>
      <div className="cardItem grid grid-cols-9 gap-4  flex  ">
        <div className="col-span-3 ">
          <div className="label">{job.jobHeadlines}</div>
          {/* <div className="value">{job.companyName}</div> */}
        </div>
        <div className="col-span-3 flex  ">
          <div className="label">Role : </div>
          <div className="value">{job.roles}</div>
        </div>
        <div className="col-span-3 flex">
          <div className="label">Openings : </div>
          <div className="value mx-1">{job?.vacancyCount}</div>
        </div>

        {/* <div className="col-span-3 datatrimmer">
          <div className="label">Job Description</div>
          <div className="value">
            
              {job.jobDescription}
          </div>
        </div> */}
        <div className="col-span-3 pl-2 datatrimmer" title={job.jobDescription}>
          <div className="label pb-2">Job description </div>
          <span className="value">{job.jobDescription}</span>
        </div>
        <div className="col-span-3">
          <div className="label">Key Skills</div>
          <div className="value">{job?.keySkills?.join(", ")}</div>
        </div>
        <div className="col-span-2">
          <div className="label">Work Location</div>
          <div className="flex">
            <div className="label">
              <RoomOutlinedIcon style={{ height: "18px" }} />
            </div>
            <div
              className="value datatrimmer"
              style={{ maxWidth: "50%" }}
              title={job.location ? job.location : ""}
            >
              {job.location.join(", ")}
            </div>
          </div>
        </div>

        <div className="col-span-1 ">
          <div className="label"></div>
          <button
            disabled={job.status === "CANCEL"}
            className={buttonClassName}
            onClick={() => onClick(job)}
          >
            {buttonText}
          </button>
        </div>
        <h1 className="endDate">
          Last date for sharing the profile : {job.dateForSharingProfiles}
        </h1>
      </div>
      {/* */}
    </div>
  );
};
const AdminClientJobPosts = ({
  jobData,
  buttonText,
  onClick,
  showMore,
  selectedTab,
  resumeCount,
}) => {
  console.log(jobData);
  return (
    // <div>
    //   {" "}
    //   <div className={!showMore ? "jobCardList" : ""}>
    //     {jobData.map((job, index) => (
    //       <JobCard
    //         key={index}
    //         job={job}
    //         buttonText={
    //           selectedTab === 2
    //             ? `${resumeCount?.[index]} Offered`
    //             : selectedTab === 1
    //             ? `${resumeCount?.[index]} Resume`
    //             : buttonText
    //         }
    //         resumeCount={resumeCount?.[index]}
    //         onClick={onClick}
    //         selectedTab={selectedTab}
    //       />
    //     ))}
    //   </div>
    // </div>
    <div>
      {jobData.length === 0 ? (
        <>
          <div className="no-data-availableIcon">
            <NoDataFound />
          </div>
          <div className="no-data-availableText">
            <p>Oops !!</p> <p>There are no data to be found yet</p>
          </div>
        </>
      ) : (
        <div className={!showMore ? "jobCardList" : ""}>
          {jobData.map((job, index) => (
            <JobCard
              key={index}
              job={job}
              buttonText={
                selectedTab === 2
                  ? `${resumeCount?.[index]} Offered`
                  : selectedTab === 1
                  ? `${resumeCount?.[index]} Resume`
                  : buttonText
              }
              resumeCount={resumeCount?.[index]}
              onClick={onClick}
              selectedTab={selectedTab}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminClientJobPosts;
