import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as HeaderSquareLogo } from "../../assets/headerSquareBox.svg";
import AccrossGeoLogo from "../../assets/accrossGeo.svg";
import "./NavBarComponent.scss";
import { useNavigate } from "react-router-dom";

const NavBarComponent = (props) => {
  const navigate = useNavigate();
  const [isTooltipOpenServices, setIsTooltipOpenServices] = useState(false);
  const [isHoveredServices, setIsHoveredServices] = useState(false);
  const REACT_PLATFORM_APP_BASE_URL = process.env.REACT_APP_PLATFORM_BASE_URL;
  const handleMouseEnterServices = () => {
    setIsTooltipOpenServices(true);
    setIsHoveredServices(true);
    setIsTooltipOpenJobSeeker(false);
    setIsHoveredJobSeeker(false);
  };

  const handleMouseLeaveServices = () => {
    setIsTooltipOpenServices(false);
    setIsHoveredServices(false);
  };

  const ServicesTooltip = () => {
    return (
      <div
        className="services-tooltip-nav header-navbar-item"
        onMouseEnter={handleMouseEnterServices}
      >
        <button
          button
          className={`header-navbar-item services-button-nav  flex ${
            isHoveredServices ? "orangeText" : ""
          }`}
        >
          Employer
          {/* <KeyboardArrowDownIcon /> */}
        </button>
        {isTooltipOpenServices && (
          <ul
            className="tooltip-content-nav"
            onMouseEnter={handleMouseEnterServices}
          >
            <li
              onClick={() => {
               
                navigate("/register/EmployerRegister",{state:{source:"backToHome"}});
                window.location.reload()
              }}
            >
              Post a Job
            </li>
          </ul>
        )}
      </div>
    );
  };
  const [isTooltipOpenJobSeeker, setIsTooltipOpenJobSeeker] = useState(false);
  const [isHoveredJobSeeker, setIsHoveredJobSeeker] = useState(false);

  const handleMouseEnterJobSeeker = () => {
    setIsTooltipOpenJobSeeker(true);
    setIsHoveredJobSeeker(true);
    setIsTooltipOpenServices(false);
    setIsHoveredServices(false);
  };

  const handleMouseLeaveJobSeeker = () => {
    setIsTooltipOpenJobSeeker(false);
    setIsHoveredJobSeeker(false);
  };
  const JobSeekerTooltip = () => {
    return (
      <div
        className="jobseeker-tooltip header-navbar-item"
        onMouseEnter={handleMouseEnterJobSeeker}
        // onMouseLeave={handleMouseLeaveJobSeeker}
      >
        <button
          button
          className={`header-navbar-item services-button-nav flex ${
            isHoveredJobSeeker ? "orangeText" : ""
          }`}
        >
          Job Seeker
          {/* <KeyboardArrowDownIcon /> */}
        </button>
        {isTooltipOpenJobSeeker && (
          <ul
            className="tooltip-content-jobSeeker-nav"
            onMouseEnter={handleMouseEnterJobSeeker}
          >
            <li
              onClick={() => {

                navigate("/register/JobSeekerRegister",{state:{source:"backToHome"}});
                window.location.reload()
              }}
            >
              Post a Profile
            </li>
          </ul>
        )}
      </div>
    );
  };

  return (
    <div
      className=""
      onMouseLeave={
        isTooltipOpenServices
          ? handleMouseLeaveServices
          : handleMouseLeaveJobSeeker
      }
    >
      <nav className="navbar">
        <div className="navbar-brand" onMouseEnter={handleMouseLeaveServices}>
          {/* <HeaderSquareLogo className="headerSquareLogo"/> */}
          <div>
            {" "}
            <img
              className=" cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
              src={AccrossGeoLogo}
              alt="Logo"
            ></img>
          </div>
          {/* <div className="hiringText">Hiring</div> */}
        </div>
        <div className="navbar-menu-nav">
          <ServicesTooltip />
          <JobSeekerTooltip />
          <button
            onMouseEnter={handleMouseLeaveJobSeeker}
            className="contactBtn"
            onClick={() => {
              navigate("/contactUs");
            }}
          >
            Contact Us
          </button>
        </div>
        <div className="navbar-end">
          <div className="header-navbar-item">
            <button
              className="button is-primary"
              onClick={() => {
                navigate("/login")
               
              }}
            >
              Login
            </button>
            <p className="deviderHr"></p>
            <button
              className="button is-light"
              onClick={() => {
                navigate("/register");
                window.location.reload()
              }}
            >
              Register
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBarComponent;
