import React from "react";
import { SelectDropdown } from "../../../landing-page/common/select-dropdown";
import cx from "classnames";
import { TextField } from "@mui/material";
import Select from "react-select";

const Degree = (props) => {
  return (
    <div className="degree col-span-3 grid grid-cols-3">
      <div className="col-span-3 heading">Degree</div>
      <div className="col-span-3 body grid grid-cols-3">
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">University</span>
          </div>
          <div className="input-ele">
            <Select
               noOptionsMessage={() => {
                return "No more options";
              }}
              dpName="dgUniversity"
              options={props.dropDownData.dgUniversity}
              onChange={(data) => {
                props.handleDropdownChange([data], "dgUniversity", [
                  "dgCollegeName",
                  "dgCollegeName",
                ]);
                if (data.value === "Others") {
                  props.degreeDropdownChange(data, "dgUniversity");
                }
              }}
              placeholder="Select a University"
              onMenuOpen={props.universityDataHandler}
              value={Array.isArray(props.selectedDropdownData.dgUniversity) ? (props.selectedDropdownData.dgUniversity[0]?.value == "" || props.selectedDropdownData.dgUniversity[0]?.value == null)? null : props.selectedDropdownData.dgUniversity : null}
              // value={props.selectedDropdownData.dgUniversity }
              isSearchable={true}
            />
          </div>
          {props.formik.errors?.dgUniversity &&
          props.formik.touched?.dgUniversity ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.dgUniversity}
            </div>
          ) : null}
        </div>
        {props.formik.values.dgUniversity === "Others" && (
          <div className="mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">University Name</span>
            </div>
            <div className="popins-medium input-ele">
              <TextField
                error={
                  props.formik.errors.dgUniversityName &&
                  props.formik.touched.dgUniversityName
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={() => {}}
                type="text"
                name="dgUniversityName"
                id="dgUniversityName"
                inputProps={{
                  className: cx(
                    `!text-xs open-sans-regular boxedInput popins-medium`
                  ),
                  style: {
                    padding: "9.5px 14px",
                    height: "0.43em !important",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    color: "#525252",
                  },
                }}
                autoComplete="off"
                placeholder="Enter the University name"
                onBlur={props.formik.handleBlur}
                onChange={props.formik.handleChange}
                value={props.formik.values.dgUniversityName}
              />
            </div>
            {props.formik.errors.dgUniversityName &&
            props.formik.touched.dgUniversityName ? (
              <div className="text-red-500 error_text md:mt-1">
                {props.formik.errors.dgUniversityName}
              </div>
            ) : null}
          </div>
        )}
        {props.formik.values.dgUniversity === "Others" ? (
          <div className="mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">College Name</span>
            </div>
            <div className="popins-medium input-ele">
              <TextField
                error={
                  props.formik.errors.dgCollegeName &&
                  props.formik.touched.dgCollegeName
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={() => {}}
                type="text"
                name="dgCollegeName"
                id="dgCollegeName"
                inputProps={{
                  className: cx(
                    `!text-xs open-sans-regular boxedInput popins-medium`
                  ),
                  style: {
                    padding: "9.5px 14px",
                    height: "0.43em !important",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    color: "#525252",
                  },
                }}
                autoComplete="off"
                placeholder="Enter the college name"
                onBlur={props.formik.handleBlur}
                onChange={props.formik.handleChange}
                value={props.formik.values.dgCollegeName}
              />
            </div>
            {props.formik.errors.dgCollegeName &&
            props.formik.touched.dgCollegeName ? (
              <div className="text-red-500 error_text md:mt-1">
                {props.formik.errors.dgCollegeName}
              </div>
            ) : null}
          </div>
        ) : (
          <div className="mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">College Name</span>
            </div>
            <div className="input-ele">
              <Select
                dpName="dgCollegeName"
                options={props.dropDownData.dgCollegeName}
                onChange={(data) => {
                  props.handleDropdownChange([data], "dgCollegeName");
                }}
                placeholder="Select a college"
                onMenuOpen={props.collegeDataHandler}
                  // value={props.selectedDropdownData.dgCollegeName}
                  value={Array.isArray(props.selectedDropdownData.dgCollegeName) ? (props.selectedDropdownData.dgCollegeName[0]?.value == "" || props.selectedDropdownData.dgCollegeName[0]?.value == null)? null : props.selectedDropdownData.dgCollegeName : null }
                isSearchable={true}
              />
            </div>
            {props.formik.errors?.dgCollegeName &&
            props.formik.touched?.dgCollegeName ? (
              <div className="text-red-500 error_text md:mt-1">
                {props.formik.errors.dgCollegeName}
              </div>
            ) : null}
          </div>
        )}

        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Course</span>
          </div>
          <div className="input-ele">
            <Select
              dpName="dgTitle"
              options={props.dropDownData.dgTitle}
              onChange={(data) => {
                props.handleDropdownChange([data], "dgTitle", ["dgStream"]);
                if (data.value === "Others") {
                  props.degreeDropdownChange(data, "dgTitle");
                }
              }}
              placeholder="Select the course"
              onMenuOpen={props.titleDataHandler}
              // value={props.selectedDropdownData.dgTitle}
              value={Array.isArray(props.selectedDropdownData.dgTitle) ? (props.selectedDropdownData.dgTitle[0]?.value == "" || props.selectedDropdownData.dgTitle[0]?.value == null)? null : props.selectedDropdownData.dgTitle : null }
              isSearchable={true}
            />
          </div>
          {props.formik.errors?.dgTitle && props.formik.touched?.dgTitle ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.dgTitle}
            </div>
          ) : null}
        </div>
        {props.formik.values.dgTitle === "Others" && (
          <div className="mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Course Name</span>
            </div>
            <div className="popins-medium input-ele">
              <TextField
                error={
                  props.formik.errors.dgTitleName &&
                  props.formik.touched.dgTitleName
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={() => {}}
                type="text"
                name="dgTitleName"
                id="dgTitleName"
                inputProps={{
                  className: cx(
                    `!text-xs open-sans-regular boxedInput popins-medium`
                  ),
                  style: {
                    padding: "9.5px 14px",
                    height: "0.43em !important",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    color: "#525252",
                  },
                }}
                autoComplete="off"
                placeholder="Enter the Course name"
                onBlur={props.formik.handleBlur}
                onChange={props.formik.handleChange}
                value={props.formik.values.dgTitleName}
              />
            </div>
            {props.formik.errors.dgTitleName &&
            props.formik.touched.dgTitleName ? (
              <div className="text-red-500 error_text md:mt-1">
                {props.formik.errors.dgTitleName}
              </div>
            ) : null}
          </div>
        )}
        {props.formik.values.dgTitle === "Others" ? (
          <div className="mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Specialization</span>
            </div>
            <div className="popins-medium input-ele">
              <TextField
                error={
                  props.formik.errors.dgStream && props.formik.touched.dgStream
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={() => {}}
                type="text"
                name="dgStream"
                id="dgStream"
                inputProps={{
                  className: cx(
                    `!text-xs open-sans-regular boxedInput popins-medium`
                  ),
                  style: {
                    padding: "9.5px 14px",
                    height: "0.43em !important",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    color: "#525252",
                  },
                }}
                autoComplete="off"
                placeholder="Enter the stream"
                onBlur={props.formik.handleBlur}
                onChange={props.formik.handleChange}
                value={props.formik.values.dgStream}
              />
            </div>
            {props.formik.errors.dgStream && props.formik.touched.dgStream ? (
              <div className="text-red-500 error_text md:mt-1">
                {props.formik.errors.dgStream}
              </div>
            ) : null}
          </div>
        ) : (
          <div className="mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Specialization</span>
            </div>
            <div className="input-ele">
              <Select
                // dpName="dgStream"
                options={props.dropDownData.dgStream}
                onChange={(data) => {
                  props.handleDropdownChange([data], "dgStream");
                }}
                placeholder="Select the stream"
                onMenuOpen={props.streamDataHandler}
                  // value={props.selectedDropdownData.dgStream}
                  value={Array.isArray(props.selectedDropdownData.dgStream) ? (props.selectedDropdownData.dgStream[0]?.value == "" || props.selectedDropdownData.dgStream[0]?.value == null)? null : props.selectedDropdownData.dgStream : null }
                isSearchable={true}
              />
            </div>
            {props.formik.errors?.dgStream && props.formik.touched?.dgStream ? (
              <div className="text-red-500 error_text md:mt-1">
                {props.formik.errors.dgStream}
              </div>
            ) : null}
          </div>
        )}
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Country</span>
          </div>
          <div className="input-ele">
            <Select
              dpName="dgCountry"
              options={props.dropDownData.dgCountry}
              onChange={(data) => {
                props.handleDropdownChange([data], "dgCountry", [
                  "dgState",
                  "dgCity",
                ]);
              }}
              placeholder="Select a country"
              onMenuOpen={props.countryDataHandler}
              // value={props.selectedDropdownData.dgCountry}
              value={Array.isArray(props.selectedDropdownData.dgCountry) ? (props.selectedDropdownData.dgCountry[0]?.value == "" || props.selectedDropdownData.dgCountry[0]?.value == null)? null : props.selectedDropdownData.dgCountry : null }
              isSearchable={true}
            />
          </div>
          {props.formik.errors?.dgCountry && props.formik.touched?.dgCountry ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.dgCountry}
            </div>
          ) : null}
        </div>
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">State</span>
          </div>
          <div className="input-ele">
            <Select
              dpName="dgState"
              options={props.dropDownData.dgState}
              onChange={(data) => {
                props.handleDropdownChange([data], "dgState", ["dgCity"]);
              }}
              placeholder="Select a state"
              onMenuOpen={props.stateDataHandler}
              // value={props.selectedDropdownData.dgState}
              value={Array.isArray(props.selectedDropdownData.dgState) ? (props.selectedDropdownData.dgState[0]?.value == "" || props.selectedDropdownData.dgState[0]?.value == null)? null : props.selectedDropdownData.dgState : null }
              isSearchable={true}
            />
          </div>
          {props.formik.errors?.dgState && props.formik.touched?.dgState ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.dgState}
            </div>
          ) : null}
        </div>
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">City</span>
          </div>
          <div className="input-ele">
            <Select
              dpName="dgCity"
              options={props.dropDownData.dgCity}
              onChange={(data) => {
                props.handleDropdownChange([data], "dgCity");
              }}
              placeholder="Select a city"
              onMenuOpen={props.cityDataHandler}
              // value={props.selectedDropdownData.dgCity}
              value={Array.isArray(props.selectedDropdownData.dgCity) ? (props.selectedDropdownData.dgCity[0]?.value == "" || props.selectedDropdownData.dgCity[0]?.value == null)? null : props.selectedDropdownData.dgCity : null }
              isSearchable={true}
            />
          </div>
          {props.formik.errors?.dgCity && props.formik.touched?.dgCity ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.dgCity}
            </div>
          ) : null}
        </div>
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Percentage / CGPA</span>
          </div>
          <div className="popins-medium input-ele grid grid-cols-4">
            <Select
              className="col-span-2"
              options={props.dropDownData.dgGradeType}
              onChange={(data) => {
                props.handleDropdownChange([data], "dgGradeType");
              }}
              placeholder="Select"
              value={props.selectedDropdownData.dgGradeType ? props.selectedDropdownData.dgGradeType : ""}
            />
            <TextField
              className="col-span-2"
              error={
                props.formik.errors.dgGrade && props.formik.touched.dgGrade
              }
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onClick={() => {}}
              type="text"
              name="dgGrade"
              id="dgGrade"
              inputProps={{
                className: cx(
                  `!text-xs open-sans-regular boxedInput popins-medium`
                ),
                style: {
                  padding: "11px 14px",
                  height: "0.43em !important",
                  fontSize: "12px",
                  fontFamily: "Poppins-Medium",
                  color: "#525252",
                },
              }}
              autoComplete="off"
              placeholder={`Enter your ${
                props.selectedDropdownData.dgGradeType
                  ? props.selectedDropdownData.dgGradeType[0].value
                  : "Percentage"
              }`}
              onBlur={props.formik.handleBlur}
              onChange={props.formik.handleChange}
              value={props.formik.values.dgGrade}
              onKeyPress={(e) => {
                // Check if the entered character is a number or a dot (for decimal input)
                const validInput = /^[0-9.]$/.test(e.key);

                if (!validInput) {
                  e.preventDefault(); // Prevent input of non-numeric characters
                }
              }}
            />
          </div>
          {props.formik.errors?.dgGradeType &&
          props.formik.errors.dgGradeType &&
          props.formik.touched.dgGradeType ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.dgGradeType}
            </div>
          ) : props.formik.errors.dgGrade && props.formik.touched.dgGrade ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.dgGrade}
            </div>
          ) : null}
        </div>
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Year of Joining</span>
          </div>
          <div className="popins-medium input-ele">
            <TextField
              error={
                props.formik.errors.dgyearOfJoining &&
                props.formik.touched.dgyearOfJoining
              }
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onClick={() => {}}
              type="text"
              name="dgyearOfJoining"
              id="dgyearOfJoining"
              inputProps={{
                className: cx(
                  `!text-xs open-sans-regular boxedInput popins-medium`
                ),
                style: {
                  padding: "9.5px 14px",
                  height: "0.43em !important",
                  fontSize: "12px",
                  fontFamily: "Poppins-Medium",
                  color: "#525252",
                },
              }}
              autoComplete="off"
              placeholder="Enter your joining year"
              onBlur={props.formik.handleBlur}
              onChange={props.formik.handleChange}
              value={props.formik.values.dgyearOfJoining}
            />
          </div>
          {props.formik.errors.dgyearOfJoining &&
          props.formik.touched.dgyearOfJoining ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.dgyearOfJoining}
            </div>
          ) : null}
        </div>
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Year of Passing</span>
          </div>
          <div className="popins-medium input-ele">
            <TextField
              error={
                props.formik.errors.dgyearOfPassing &&
                props.formik.touched.dgyearOfPassing
              }
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onClick={() => {}}
              type="text"
              name="dgyearOfPassing"
              id="dgyearOfPassing"
              inputProps={{
                className: cx(
                  `!text-xs open-sans-regular boxedInput popins-medium`
                ),
                style: {
                  padding: "9.5px 14px",
                  height: "0.43em !important",
                  fontSize: "12px",
                  fontFamily: "Poppins-Medium",
                  color: "#525252",
                },
              }}
              autoComplete="off"
              placeholder="Enter your passing year"
              onBlur={props.formik.handleBlur}
              onChange={props.formik.handleChange}
              value={props.formik.values.dgyearOfPassing}
            />
          </div>
          {props.formik.errors.dgyearOfPassing &&
          props.formik.touched.dgyearOfPassing ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.dgyearOfPassing}
            </div>
          ) : null}
        </div>
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Course Type</span>
          </div>
          <div className="input-ele">
            <Select
              options={props.dropDownData.dgCourseType}
              onChange={(data) => {
                props.handleDropdownChange([data], "dgCourseType");
              }}
              placeholder="Select the course type"
              value={props.selectedDropdownData.dgCourseType ? props.selectedDropdownData.dgCourseType : ""}
            />
          </div>
          {props.formik.errors?.dgCourseType &&
          props.formik.touched?.dgCourseType ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.dgCourseType}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Degree;
