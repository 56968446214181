import React, { useEffect, useState } from "react";
import AccountCard from "./AccountCard";
import { useNavigate, useLocation } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Menu from '@mui/material/Menu';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from '@mui/material/MenuItem';
import Modal from "react-modal";
import { ReactComponent as LocationIcon } from "../../../assets/location.svg";
import "react-phone-input-2/lib/material.css";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Button, Grid, Typography } from "@material-ui/core";

import {
    getAllClints,
    getBdJobListReq,
    getClientJobPost,
    getJobPostByCompName,
} from "../../../api/api-services";
import AdminClientJobPosts from "../../admin/admin-client/AdminClientJobPosts";
import { ReactComponent as NoDataFound } from "../../../assets/noDataFound.svg";
import "./AccountCard.scss";
import CandidateTable from "../../common/candidate-table/CandidateTable";
import CandidateColumnOption from "../../common/CandidateColumnOption";
import DetailsPage from "./DetailsPage";
import AdminContextMenuModal from "../../admin/AdminContextMenuModal";
import JobDetails from "../../client/modals/JobDetails";
const customStylesPage = {
    overlay: {
        background: "rgba(90, 90, 90,0.5)",
    },
    content: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "auto",
        height: "auto",
        background: "#fbfcfd 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 11px #000000a1",
        borderRadius: "4px",
        opacity: "1",
        outline: "none",
        index: "9999",
    },
};

function AccountDetails() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [tabIndex, setTabIndex] = useState(0);
    // const [menu, setMenu] = useState(false)
    const [detailModel, setDetailModel] = useState(false)
    const [jobDetailModel, setJobDetailModel] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [spocsCount, setSpocsCount] = useState();
    const [jobPosrCount, setJobPostCount] = useState();
    let [clintList, setclintList] = useState([]);
    let [clints, setclints] = useState([]);
    const [Jobs, setJobs] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    let [pageno, setpageNo] = useState(0);
    let [jobpageno, setJobpageNo] = useState(0);
    let [lastPage, setLastpage] = useState(false);
    let [jobList, setJobList] = useState([]);
    const [jobPostList, setJobPostList] = useState();
    const [searchedValue, setsearchedValue] = useState('')
    const open = Boolean(anchorEl);
    let userid = JSON.parse(localStorage.getItem("across-geo-user")).uid;
    let size = 10;
    const compName = state.accountDetails.name;
    console.log(compName, "compName", pageno, "pageno")
    let [postJobDetails, setPostDetails] = useState(false);
    let [tableApiData, setTableApiData] = useState({
        totalCount: 20,
        columnDef: {
            columns: [
                {
                    columnName: "name",
                    column_order: 1,
                },
                {
                    columnName: "email",
                    column_order: 2,
                },
                {
                    columnName: "mobile",
                    column_order: 3,
                },
                {
                    columnName: "location",
                    column_order: 4,
                },
            ],
        },
        content: [],
        lastPage: true,
    });
    let [tableJobPostApiData, setTableJobPostApiData] = useState({
        totalCount: 20,
        columnDef: {
            columns: [
                {
                    columnName: "roles",
                    column_order: 1,
                },
                {
                    columnName: "keySkills",
                    column_order: 2,
                },
                {
                    columnName: "highestQualification",
                    column_order: 3,
                },
                {
                    columnName: "vacancyCount",
                    column_order: 4,
                },

                {
                    columnName: "location",
                    column_order: 5,
                },
            ],
        },
        content: [],
        lastPage: true,
    });
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const contextMenuData = [
        {
            option: "Details",
        },

    ];
    function contextMenuFunction(event, resObj) {
        if (event.target.textContent === "Details") {
            setDetailModel(true);
            setclintList(resObj);
            console.log("contextMenuFunctionDetails", resObj, event,);
        }
        console.log("coming in");
    }
    function jobContextMenuFunction(event, resObj) {
        if (event.target.textContent === "Details") {
            setJobDetailModel(true);
            setJobPostList(resObj);
            console.log("contextMenuFunctionDetails", resObj, event,);
        }
        console.log("coming in");
    }
    let getJobList = async (jobpageno) => {
        try {
            let response = await getJobPostByCompName(compName, jobpageno, size);
            if (response.status === 200 || response?.data?.response || response?.data)
                setLastpage(response?.data?.response?.last);
                setJobPostCount(response.data.response.totalElements)
            if (response?.data?.response?.last === false) {
                setJobpageNo(jobpageno + 1);
            }
            let tabledata = { ...tableJobPostApiData };
            if (jobpageno === 0) {
                // setJobList([...response?.data?.response?.content]);
                tabledata.content = [...response.data.response.content];
            } else {
                // setJobList([...jobList, ...response?.data?.response?.content]);
                tabledata.content = tabledata.content.concat(
                    response.data.response.content
                );
            }
            setTableJobPostApiData(tabledata);
        } catch (err) {
            console.error(err, "list error");
        }
    };
    let getallClintsForCompany = async (pageno) => {
        try {
            let response = await getAllClints(compName, pageno, size);
            if (response.status === 200 || response?.data?.response || response?.data)
                setLastpage(response?.data?.response?.last);
                setSpocsCount(response.data.response.totalElements)
            if (response?.data?.response?.last === false) {
                setpageNo(pageno + 1);
            }
            let tabledata = { ...tableApiData };
            if (pageno === 0) {
                setclints([
                    ...JSON.parse(JSON.stringify(response.data.response?.content)),
                ]);
                setclintList([...response?.data?.response?.content]);
                tabledata.content = [...response.data.response.content];
            } else {
                setclints([
                    ...jobList,
                    ...JSON.parse(JSON.stringify(response.data.response?.content)),
                ]);
                setclintList([...jobList, ...response?.data?.response?.content]);
                tabledata.content = tabledata.content.concat(
                    response.data.response.content
                );
            }
            setTableApiData(tabledata);
        } catch (err) {
            console.error(err, "list error");
        }
    };
    // const handleTablescroll = async (event) => {
    //     const { scrollTop, clientHeight, scrollHeight } = event.target;
    //     const target = event?.target;
    //     if (!target) return;

    //     const scrolledToBottom =
    //         Math.ceil(target.scrollTop + target.clientHeight) >=
    //         target.scrollHeight - 1;

    //     if (scrolledToBottom) {
    //         setIsLoading(true);

    //         if (tabIndex === 0 && !isLoading) {
    //                 await getallClintsForCompany(pageno);
    //         } else if (tabIndex === 1 && !isLoading) {
    //                 await getJobList(jobpageno);

    //         }
    //     }
    // };
    // const handleScroll = async (event) => {
    //     const { scrollTop, clientHeight, scrollHeight } = event.target;
    //     const target = event?.target;
    //     // if (!target) return;
    //     console.log(tabIndex,isLoading,"is searching")

    //     const scrolledToBottom =
    //         Math.ceil(target.scrollTop + target.clientHeight) >=
    //         target.scrollHeight - 1;

    //     if (scrolledToBottom) {
    //         // setIsLoading(true);

    //         if (tabIndex === 0 ) {
    //             await getallClintsForCompany(pageno);
    //     } else if (tabIndex === 1 ) {
    //             await getJobList(jobpageno);
    //             alert('inside hadlescroll')

    //     }
    //     }
    // };
    const handleScroll = async (event) => {
        const target = event?.target;
        // if (!target) return;

        const scrolledToBottom =
            Math.ceil(target.scrollTop + target.clientHeight) >= target.scrollHeight - 1;
        if (scrolledToBottom) {
            setIsLoading(true);

            if (tabIndex === 0 && !lastPage) {
                await getallClintsForCompany(pageno);
            } else if (tabIndex === 1 && !lastPage) {
                await getJobList(jobpageno);
            }
        }
    };
    const actionele = (data) => {

        return (
            // <div onClick={handleClick}>
            //     <MoreVertIcon className="h-5 w-5" />
            // </div>
            <AdminContextMenuModal
                contextMenuData={contextMenuData}
                // bdData={data}
                func={(event) => {
                    contextMenuFunction(event, data);
                }}
            />
        );
    };
    const actionJobele = (data) => {

        return (
            // <div onClick={handleClick}>
            //     <MoreVertIcon className="h-5 w-5" />
            // </div>
            <AdminContextMenuModal
                contextMenuData={contextMenuData}
                // bdData={data}
                func={(event) => {
                    jobContextMenuFunction(event, data);
                }}
            />
        );
    };
    // useEffect(() => {
    // }, [jobDetailModel])

    useEffect(() => {
        if (tabIndex === 0) {
            getallClintsForCompany(0);
        }
    }, []);
    let closeModal = () => {
        if (jobDetailModel) {
            setJobDetailModel(false);
        }
    }
    const websiteAddres = () => {
        let webAdress;
        if (!(state?.accountDetails.website.includes("http://") || state?.accountDetails.website.includes("https://"))) {
            webAdress = `http://${state?.accountDetails.website}`
        } else {
            webAdress = state?.accountDetails.website;
        }
        return webAdress;
    }

    if (!jobDetailModel) {
        return (
            <div
                style={{ height: "800px" }}
                onScroll={handleScroll}
                className="adminJobPostContainer mx-3 w-full home-component-display mt-2.5 overflow-y-scroll"
            >
                <div
                    className=" flex justify-between"
                    style={{ height: "60px", borderBottom: "1px solid #e5e7eb;" }}
                >
                    {/* <p>Job Post Lists - {tabIndex === 0 ? newJobListCount :oldJobListCount}</p> */}
                    <p
                        onClick={() => {
                            console.log(state.accountDetails, "data from navigate");
                        }}
                        style={{ fontSize: "18px", fontFamily: "poppins-Medium" }}
                        className="m-3"
                    >
                        Account List{" "}
                        {/* {tabIndex === 0 ? newJobListCount : oldJobListCount} */}
                    </p>
                    <div className="m-2">
                        <button
                            className="cancelBtn p-1"
                            onClick={() => {
                                navigate("/bd/account");
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
                <div className="m-1">
                    <div className="opacity-100  job-card border border-gray-400 rounded-lg shadow-md border-opacity-0 transform">
                        <div className="flex p-2 row">
                            <div className="col-4 border-r-2 border-#dadada-500">
                                <h5 style={{ color: "#ef7f09" }}>
                                    {state?.accountDetails.name}{" "}
                                </h5>
                                <div className="row border-right">
                                    <div className="col-6 flex">
                                        <h5>Total Job Post</h5>
                                        <span>{`-${state?.accountDetails.totalJobPost}`}</span>
                                    </div>
                                    <div className="col-6 flex">
                                        <h5>Total Offers</h5>
                                        <span>{`-${state?.accountDetails.totalOffered}`}</span>
                                    </div>
                                    <div className="col-6 flex">
                                        <h5>Total Spocs</h5>
                                        <span>{`-${state?.accountDetails.totalClients}`}</span>
                                    </div>
                                    <div className='col-6 flex'><h5>Total Shared</h5><span>{`-${state?.accountDetails.totalShared}` ? `-${state?.accountDetails.totalShared}` : ""}</span></div>
                                    <div className="col-6 flex">
                                        <span></span> <h5>Headquarters</h5>
                                    </div>
                                    <span className='flex'><span style={{ paddingTop: "5px" }}> <LocationIcon className="text-orange-500" height="15px" width="15px" /></span>{" "}<span className="">{state?.accountDetails.headquarters ? state?.accountDetails.headquarters : "--"}</span></span>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col-12 "></div>
                                    <div className="col-6 flex">
                                        <h5>Website:</h5>
                                        <span className="text-blue-700 cursor-pointer">
                                            <a href={`${websiteAddres()}`} target="_blank"> {websiteAddres()}</a>
                                        </span>
                                    </div>
                                    <div className="col-6 flex">
                                        <h5> Industry:</h5><span>{`${state?.accountDetails.industry}`}</span>
                                    </div>
                                    <div className="col-6 flex">
                                        <h5> Company Size:</h5> <span>{`${state?.accountDetails.no_of_employee}`}</span>
                                    </div>
                                    <div className="col-6 flex">
                                        <h5>Specialities:</h5> <span>{`${state?.accountDetails.specialities}`}</span>
                                    </div>
                                    <div className="col-12">
                                        <h5 className="pb-0">OverView:</h5>
                                        {/* {props.account.description ? */}
                                        <div className="bg-slate-100 rounded-2xl max-h-24 h-24 overflow-y-scroll p-2">
                                            <span> {state?.accountDetails.description}</span>
                                        </div>
                                        {/* } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pannelTabs p-2 border-solid-1">
                    <Tabs
                        selectedIndex={tabIndex}
                        onSelect={(index) => {
                            setTabIndex(index);
                            setSearchTerm("");
                            setPostDetails(false);
                            //  resetSearch();
                            index === 0
                                ? getallClintsForCompany(0)
                                : getJobList(0);
                        }}
                    >
                        <TabList className="flex outline-none" style={{ height: "44px" }}>
                            <Tab className={tabIndex === 0 ? "selectedTab" : ""}>Spocs{spocsCount ? `-${spocsCount}` : ""} </Tab>
                            <Tab className={tabIndex === 1 ? "selectedTab ml-2" : "ml-2"}>
                                JobPosts{jobPosrCount ? `-${jobPosrCount}` : ""}
                            </Tab>
                        </TabList>

                        <TabPanel>
                            <div
                                style={{ maxHeight: "70vh", width: "100%", marginLeft: "4px" }}
                                className="p-2 overflow-y-auto"
                            // onScroll={handleTablescroll}
                            >
                                <div
                                    style={{ height: "560px" }}
                                    className="p-2 overflow-y-auto"
                                // onScroll={handleTablescroll}
                                >
                                    <CandidateTable
                                        isLoading={isLoading}
                                        tableApiData={tableApiData}
                                        actionele={actionele}
                                        actionColumn={true}
                                        searchedValue={searchedValue}
                                        handlescroll={handleScroll}
                                        actionColumnWidth={"15%"}
                                    // profileColumn={"name"}
                                    // hiddenColumnsData={hiddenColumnsData}
                                    // selectedTab={selectedTab}
                                    // showVideo={"both"}
                                    // tableType={"admin-table"}
                                    />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div
                                style={{ maxHeight: "70vh", width: "100%", marginLeft: "4px" }}
                                className="p-2 overflow-y-auto"
                            // onScroll={handleScroll}
                            >
                                <div
                                    style={{ height: "560px" }}
                                    className="p-2 overflow-y-auto"
                                // onScroll={handleScroll}
                                >
                                    <CandidateTable
                                        isLoading={isLoading}
                                        tableApiData={tableJobPostApiData}
                                        actionele={actionJobele}
                                        actionColumn={true}
                                        handlescroll={handleScroll}
                                        actionColumnWidth={"15%"}
                                    // profileColumn={"name"}
                                    // hiddenColumnsData={hiddenColumnsData}
                                    // selectedTab={selectedTab}
                                    // showVideo={"both"}
                                    // tableType={"admin-table"}
                                    />
                                </div>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => {
                        handleClose();
                        setDetailModel(true);

                    }}>Details</MenuItem>
                </Menu>
                {/* {jobDetailModel &&
                    <div style={{ height: "393px" }}>
                        <JobDetails
                            jobModalHeight="155px"
                            closeModal={closeModal}
                            selectedJob={jobPostList}
                        />
                    </div>
                } */}
                {detailModel &&
                    <DetailsPage
                        clintList={clintList}
                        setDetailModel={setDetailModel}
                        detailModel={setDetailModel}
                        tableJobPostApiData={tableJobPostApiData}
                    />
                }
            </div>
        );
    } else {
        return (
            <div>
                <JobDetails
                    jobModalHeight="255px"
                    closeModal={closeModal}
                    selectedJob={jobPostList}
                />
            </div>
        )
    }
}

export default AccountDetails;
