import React, { useState, useEffect, useRef } from "react";
import "./DescriptionContent.scss";
import BgLogoOne from "../../../assets/bgOneLogo.png";
import BgLogoTwo from "../../../assets/bgTwoLogo.png";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Ellipse from "../../../assets/Ellipse 55.svg";
import Horse from "../../../assets/horse.svg";

import Content1 from "../../../assets/howWeWork1.svg";
import Content2 from "../../../assets/howWeWork2.svg";
import Content3 from "../../../assets/howweWork3.svg";
import Content4 from "../../../assets/howWeWork4.svg";

import AboutUs from "./AboutUs";
import Analytics from "./Analytics";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#EF7F09" : "#308fe8",
  },
}));

const SkillProgressBar = ({ value }) => {
  const progressRef = useRef(null);
  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    setProgressWidth(progressRef.current.getBoundingClientRect().width);
  }, []);

  const getValuePosition = (value) => {
    return `${value}%`;
  };

  return (
    <Box sx={{ position: "relative" }}>
      <BorderLinearProgress
        ref={progressRef}
        variant="determinate"
        value={value}
      />
      <Typography
        sx={{
          position: "absolute",
          top: "-20px",
          left: getValuePosition(value),
          transform: "translateX(-50%)",
        }}
        variant="subtitle2"
      >
        {value}%
      </Typography>
    </Box>
  );
};
const skills = [
  { name: "Assurance on the Quality of resources with us", value: 100 },
  { name: "Experience significant time and cost savings with our streamlined recruitment process", value: 90 },
  { name: "A substantial number of successful job-seekers find employment through our portal", value: 90 },
];

const SkillSet = ({ name, value }) => {
  return (
    <div className="skillSet my-4">
      <label style={{maxWidth:`${value}%`}}>{name}</label>
      <SkillProgressBar value={value} />
    </div>
  );
};

const SkillSection = ({ skills }) => {
  return (
    <div className="skillSection">
      {skills.map((skill) => (
        <SkillSet name={skill.name} value={skill.value} key={skill.name} />
      ))}
    </div>
  );
};





const timelineData = [
  {
    id: 1,
    title: "Post Your Job Requirements.",
    description:
      "Share your specific job requirements, detailing the skills, experience, and qualities you seek and Provide a comprehensive job description to attract the right talent.",
    image1: Content1,
  },
  {
    id: 2,
    title: "Tap into Our Extensive Talent Pool.",
    description:
      "Once you've posted your requirements, leverage our vast talent pool. Our expert team will handle the task of shortlisting the most suitable job-seeker for your consideration.",
    image1: Content2,
  },
  {
    id: 3,
    title: "Thoughtful Shortlisting Process.",
    description:
      "Rely on our thorough approach. We conduct multiple internal interviews to thoughtfully shortlist the top applicants. This way, you get a chance to meet the most promising job-seekers.",
    image1: Content3,
  },
  {
    id: 4,
    title: "Final Interview and Selection.",
    description:
      "Take the next step and hold the final interview with the shortlists. Choose the ideal applicant from our curated pool of talent and extend your offer to the best-fit resource.With our efficient workflow, you'll confidently welcome exceptional talent to join your team, ensuring a successful journey together. Partner with us for seamless and effective hiring processes.",
    image1: Content4,
  },
];
const DescriptionContent = (props) => {
  return (
    <div className="descriptionContent">
      <div className="reasonForChoose">
        <Fade left delay={0} duration={1000}>
          <div className="imageContainer">
            <img src={BgLogoOne} />
          </div>
        </Fade>
        <Fade right delay={0} duration={1000}>
          <div className="contentSection">
            <p className="title">Why hire through us?</p>

            <p className="titleContent -mt-4">
            Job seekers and Employers are gravitating towards our platform to discover their right fit for their hiring’s through us!
            </p>
            <SkillSection skills={skills} />
          </div>
        </Fade>
      </div>
      {/* <div className="aboutUsContainer">
      <AboutUs aboutUsRef={props.aboutUsRef}/>

      </div> */}
      <div className="analyticsContainer">
        <Analytics />
      </div>

      <div className="workingCulture">
        <div className="contentSection">
          <p className="title">How we Work ?</p>
          <span>
          Our Workflow: Delivering Exceptional Talent to You . <br/> Discover how our streamlined process ensures you find the perfect talent for your needs: 
          </span>
          <div class="timeline">
            {timelineData.map((data) => (
              <div className="timeline-item" key={data.id}>
                <div className="timeline-img">
                  {/* <img src={Ellipse} alt="Image 1" />
                <img src={Horse} alt="Image 1" /> */}
                  <img src={data.image1} alt="Image 1" />
                </div>

                <div className="timeline-content">
                  <Fade left delay={0} duration={1000}>
                    <h2>{data.title}</h2>
                    <p>{data.description}</p>
                  </Fade>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Fade right delay={0} duration={1000}>
          <div className="imageContainer">
            <img src={BgLogoTwo} />
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default DescriptionContent;
