import { useEffect, useState } from "react";
import { MultiSelectDropdown, SelectDropdown } from "../../landing-page/common/select-dropdown";
import * as Yup from "yup";
import { Tooltip } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "./InterviewerCandidatesDetails.scss";
import { useFormik } from "formik";
import ReactSelectDropdown from "../../landing-page/common/react-select-dropdown";
import { updateInterviewRating } from "../../../api/api-services";
const InterviewerUpdateInterview = (props) => {
  const [error, setError] = useState(false);
  let [initialValues, setInitialValues] = useState({ skills: [] });
  let initialSchema = { skills: Yup.array().min(1, "Skills is required") };
  const [currentSchema, setcurrentSchema] = useState({ ...initialSchema });
  const [disable,setDisable] = useState(true)
  let [validationSchema, setValidationSchema] = useState(
    Yup.object(initialSchema)
  );
  const [statusDropdownSelected, setStatusDropdownSelected] = useState(false);
  const [allSkillsSelected, setAllSkillsSelected] = useState({});

  const skillValidationSchemaHandler = (skills) => {
    let tempObj = {};
    skills.forEach((skill) => {
      tempObj = {
        ...tempObj, ['skill_' + skill]: Yup
          .number().integer('only integers are allowed')
          .max(10, "Rating must be less than 10")
          .typeError('Please select number ')
          .min(0, "Rating must be more than 0")
          // .test(
          //   "is-decimal",
          //   "Skill Rating. eg: 00.00 or 0.0",
          //   (val) => {
          //     if (val != undefined) {
          //       return /^\d+(\.\d{1,2})?$/.test(val);
          //     }
          //     return true;
          //   }
          // )
          .required("Skill rating is required"),
      }
    });
    return tempObj;
  }
  const selectedSkillsHandler = () => {
    let candSkills = []
    props.skillData?.map((skills) => {
      candSkills.push({ "label": skills, "value": skills })
    })
    setAllSkillsSelected(candSkills);
    formikDetails.setFieldValue("skills", props.skillData);
    let updatedSchema = {
      ...initialSchema,
      ...skillValidationSchemaHandler(props.skillData),
    };
    setValidationSchema(Yup.object(updatedSchema));
  }
  const handleSkillsChange = (data) => {
    console.log(data, "data handleSkillsChange")
    setAllSkillsSelected(data);
    let list = [];
    data?.map((data) => {
      list.push(data.value);
    });
    formikDetails.setFieldValue("skills", list);
    let updatedSchema = {
      ...initialSchema,
      ...skillValidationSchemaHandler(list),
    };
    let updateInitialValues = {
      ...initialValues, list
    };
    setInitialValues(updateInitialValues);
    setValidationSchema(Yup.object(updatedSchema));
  };


  useEffect(() => {
    selectedSkillsHandler();
  }, []);

  const handleChange = (e, i) => {
    const tempData = JSON.parse(JSON.stringify(props.selectedSkills))
    tempData[i].val = e.target.value;
    props.setSelectedSkills([...tempData])
  }
  const onSubmit = async (values) => {
    console.log("form values", values);
    let payload = [];
    values.skills.forEach((skill) => {
      payload.push({
        "rating": values["skill_"+skill],
        "skillName": skill
      })
    })
    // props.getUpdatedInterviews(payload)
    let res = await updateInterviewRating(props.candidateDetailsData.id, payload)
    if (res.data.statusCode === 200) {
      props.toast.success(
        <>
          <div className="toast-heading">Success</div>
          <div>{"Interview Updated Successfully"}</div>
        </>
      );
        props.closeModal()
        

    } else {
      toast.info(
        <>
          <div className="toast-heading">Info</div>
          <div>{res.message}</div>
        </>
      );
        props.getAllInterviewers(0);  
        props.setInterviewModule(false)
    }
   
  }
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const disableBtn = () => {
    console.log(allSkillsSelected, "allSkillsSelected")
    let val = []
    let tenpLen = 0;
    let length = allSkillsSelected.length;
    allSkillsSelected.map((ele) => {
      console.log('skill_' + ele.value)
      let skillField = document.getElementById('skill_' + ele.value)
      val.push(skillField.value)
    })
    val.map((val) => {
      if (val != "") {
        tenpLen++
      }
    })
    if (length == tenpLen) {
     setDisable(false)
    } else {
      setDisable(true)

   }
  }

  return (
    <div className="m-3">
      <form onSubmit={formikDetails.handleSubmit}>
        <div className="mb-3" >
          <p style={{marginBottom:"0px", fontWeight:"4px", fontFamily:"poppins-Regular"}}>Interview Status</p>
          <SelectDropdown
            data={[{ "label": "Interview Taken", "value": true }]}
            onChange={(e) => { setStatusDropdownSelected(true) }}
          />
        </div>
        {statusDropdownSelected && <div>
          {console.log(props.allskills,"props.allskillsprops.allskills")}
          <div>
            <p style={{marginBottom:"0px", fontWeight:"4px", fontFamily:"poppins-Regular"}}>Skill Set Rating</p>
            <ReactSelectDropdown
              className="my-select-container-postJob cursor-pointer"
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={handleSkillsChange}
                  options={props.allskills}
                  value={allSkillsSelected}
            />
            
          </div>
          {formikDetails.errors?.skills &&
            formikDetails.touched?.skills ? (
            <div className="text-red-500 error_text md:mt-1">
              {formikDetails.errors?.skills}
            </div>
          ) : null}
          <div id="skillBlock" style={{ overflow: "scroll", height: "310px", marginTop: "3px", paddingTop: "3px" }}>
            {allSkillsSelected.map((ele, i) => {
              return (
                <div>
                <div className="p-2 flex row grid justify-items-stretch ">
                  <Tooltip title={ele.value} placement="bottom">
                      <div style={{ width: "70%" }} className="col-5">
                        <span className="pr-2 , mr-0">{(ele.value.length > 18) ? ele.value.substring(0, 18) + '...' : ele.value}</span>
                      </div>
                  </Tooltip>
                    <div className="col-2">
                      <div className="flex">
                        <input min={0} max={10} type="number" onBlur={(e) => formikDetails.handleBlur(e)} autoCapitalize="off" id={'skill_' + ele.value} name={'skill_' + ele.value}
                          onChange={(e) => {
                            disableBtn()
                          formikDetails.handleChange(e);
                        }} style={{ borderWidth: "1px", width: "50px", padding: "2px" }}></input>
                        <span style={{fontFamily:"poppins-Regular", fontWeight:"16px", marginTop:"3px"}}>/10</span>
                      </div>
                    </div>
                    <span style={{ width: "70%" }}>
                    {formikDetails.errors?.['skill_' + ele.value] &&
                     (formikDetails.touched?.['skill_' + ele.value]) ? (
                      <div className="text-red-500 error_text md:mt-1">
                      {formikDetails.errors?.['skill_' + ele.value]}
                       </div>
                      ) : null}
                    </span>
                  </div>
                </div>
              )
            })}
            <div>
            </div>
          </div>
          <div>
            {disable ?
              <button
              className="cursor-not-allowed submitBtn mt-3"
              disabled="true"
               style={
               {fontSize : "12px",
               height: "40px",
               width: "98px",
               backgroundColor: "#f2f2f2",
               border: "1px solid #dadada",
               color: "#525252",
               borderRadius: "32px",
               opacity: "1",
               float: "right",
               marginTop: "-3px",
               marginRight:"10px"
                 }}
                type="submit">
              Update
              </button> :
              <button type="submit" className="submitBtn mt-3">
              Update
            </button>
            }
          </div>
        </div>}
      </form>
    </div>
  )
};

export default InterviewerUpdateInterview;
