import { useEffect, useState } from "react";
import Modal from "react-modal";
import CandidatesDetailsModal from "../../common/candidate-details/CandidatesDetailsModal";
import ScheduleInterview from "./ScheduleInterview";
import "./InterviewModals.scss";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import UpdateInterview from "./UpdateInterview";
import { getCandidateDetailsReq, getClientDetails } from "../../../api/api-services";
import InterviewCandidModals from "./InterviewCandidModals";

function InterviewModals(props) {
  const [openModal, setOpenModal] = useState(true);
  let [candidateDetailsData, setCandidateDetailsData] = useState({});
  let [clientData, setClientDataData] = useState({});

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "59%",
      transform: "translate(-50%, -50%)",
      width: "82%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
      outline: "none",
      //   borderTopLeftRadius: " 12px",
    },
  };
  let closeInterviewModal = () => {
    props.gettableData(props.selectedTab, 0);
    setOpenModal(false);
    props.closeModal(false);
  };
  const getCandidateDetails = async () => {
    try {
      const response = await getCandidateDetailsReq(props.candidate?.id);
      if (response.data) {
        setCandidateDetailsData({ ...response.data.response });
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  const getClientDetailsReq = async () => {
    try {
      const response = await getClientDetails(props.job?.clientId);
      if (response.data) {
        setClientDataData({ ...response.data.response });
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  useEffect(() => {
    getClientDetailsReq();
    getCandidateDetails();
  }, []);
  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      style={customStylesPage}
      className="p-px"
    >
      <div className="flex items-center rounded-tl-xl Interview-modal-heading items-center">
        <div className="header">{props.header}</div>
        <button
          onClick={() => {
            setOpenModal(false);
            props.closeModal(false);
          }}
          type="button"
          className="modal-close-btn float-left ml-auto p-3"
        >
          <HighlightOffIcon />
        </button>
      </div>
      <div className="flex Interview-modal-body">
        <div className="modal-candidate-details-container">
          <InterviewCandidModals
            clientData={clientData}
            candidateData={candidateDetailsData}
          />
        </div>
        <div className="candidate-form">
          {props.header === "Schedule Interview" ? (
            <ScheduleInterview
              toast={props.toast}
              closeModal={closeInterviewModal}
              candidate={props.candidate}
              clientData={clientData}
              job={props.job}
            />
          ) : (
            <UpdateInterview
              toast={props.toast}
              closeModal={closeInterviewModal}
              candidate={props.candidate}
              clientData={clientData}
              job={props.job}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}

export default InterviewModals;
