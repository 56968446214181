import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, InputAdornment, Grid } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import "./LoginForm.scss";
import { Link, useNavigate } from "react-router-dom";
import cx from "classnames";
import { TextField } from "@mui/material";

import { loginReq } from "../../../../api/api-services";
import VisibleEye from "../../website-assets/visibleEyeNew.png";
import InvisibleEye from "../../website-assets/invisibleEyeNew.png";

const initialValues = {
  username: "",
  password: "",
};

const validationSchema = Yup.object({
  username: Yup.string()
    .trim()
    .required("Email id is required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Enter valid email Id"),
  password: Yup.string().required("Password is required"),
});
const useStyles = makeStyles((theme) => ({
  form: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  field: {
    margin: "8px 8px 0px 8px",
    width: "95%",

    background: "#FFFFFF 0% 0% no-repeat padding-box",
    opacity: "1",
  },

  input: {
    backgroundColor: "#fff",
    "&:focused": {
      backgroundColor: "#fff",
    },
    "& .Mui-focused": {
      backgroundColor: "#fff",
    },
  },
}));
const LoginForm = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState([]);
  const [showError, setShowError] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.stopPropagation();
  };
  const handleSubmit = (values) => {
    let tempRole = localStorage.getItem("selected-role");
    const lowercasedUsername = values.username.toLowerCase();
    const updatedValues = { ...values, username: lowercasedUsername };

    try {
      const res = loginReq(updatedValues)
        .then((res) => {
          if (res.data && res.data.response) {
            console.log("response", res.headers);
            const response = res.data.response;
            if (response.access_token) {
              localStorage.setItem("across-geo-user", JSON.stringify(response));
              navigate("/" + response.role.toLowerCase(), { replace: true });
            } else {
              setShowError(true);
              console.log("no token ");
            }
          } else {
            console.log("no resp ", res);
            if (res.response.status === 400) {
              setErrorContent("User account is not active");
            } else if (res.response.status === 404) {
              setErrorContent("Email or Password is invalid, Please try again");
            } else {
              setErrorContent("Something went wrong. Please try again later");
            }

            setShowError(true);
          }
        })
        .catch((error) => {
          console.error("login error", error);
        });
    } catch (e) {
      console.error("try catch ", e);
    }
  };
  const changeGreenBorderStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#007456"
      },
      "&:hover fieldset": {
        borderColor: "#007456"
      }
    }
  }
  return (
    <div className="login_formContainer  ">
      <div className="formContents">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form className={classes.form}>
              <label className=" formLabelLogin relative  top-1.5 left-1.5">
                {" "}
                <span className="text-red-500 ">*</span> Email
              </label>
              <Field
                name="username"
                placeholder="Enter your email"
                autoFocus
                as={TextField}
                onFocus={() => setShowError(false)}
                variant="outlined"
                className={classes.field}
                // change the border color from blue to green on focous
                sx={changeGreenBorderStyle}
                error={touched.username && Boolean(errors.username)}
                inputProps={{
                  className: cx(` boxedInput Poppins-Medium `),
                  style: {
                    // padding: "12px 14px",
                    // height: "0.43em !important",
                    // fontFamily: "Poppins-Regular",
                    padding: "12px 14px",
                    fontFamily: "Poppins-Regular",
                    fontSize: "14px !important",
                  },
                }}
                autoComplete="off"
              />
              {errors.username && touched.username ? (
                <div className="text-red-500 error_text absolute ml-2 -mt-2">
                  {errors.username}
                </div>
              ) : null}

              <div className="mt-3">
                <label className=" formLabelLogin relative  top-1.5 left-1.5">
                  {" "}
                  <span className="text-red-500  ">*</span> Password
                </label>
                <Field
                  name="password"
                  autoComplete="off"
                  placeholder="Enter your password"
                  as={TextField}
                  onFocus={() => setShowError(false)}
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  className={classes.field}
                  // change the border color from blue to green on focous
                  sx={changeGreenBorderStyle}
                  error={touched.password && Boolean(errors.password)}
                  inputProps={{
                    className: cx(`  boxedInput Poppins-Medium impFont`),
                    style: {
                      // padding: "12px 14px",
                      // height: "0.43em !important",
                      // fontFamily: "Poppins-Regular",
                      padding: "12px 14px",
                      fontFamily: "Poppins-Regular",
                      fontSize: "14px !important",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <div
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        className=" cursor-pointer"
                      >
                        {!showPassword ? (
                          <img src={InvisibleEye} style={{ height: "20px" }} />
                        ) : (
                          <img src={VisibleEye} style={{ height: "20px" }} />
                        )}
                      </div>
                    ),
                  }}
                />
              </div>
              {errors.password && touched.password ? (
                <div className="text-red-500 error_text  ml-2 absolute -mt-2">
                  {errors.password}
                </div>
              ) : null}
              {showError === true ? (
                <div
                  // style={{ position: "relative", }}
                  className="error-msg error_text absolute  left-8 -mt-1.5"
                >
                  {errorContent}
                </div>
              ) : (
                ""
              )}
              <div
                className="forgotPw mt-2 cursor-pointer "
                onClick={() => {
                  props.handelOpenSetPasswordModal(true);
                }}
              >
                Forgot Password?
              </div>
              <div className=" mt-2">
                <button type="submit" className="logInBtn logInBtnContainer">
                  Sign in
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="registerContainer my-2 flex">
        <p>Don't have an account?</p>
        <p
          className="mx-2 cursor-pointer registerText"
          onClick={() => {
            // window.location.reload();
            props.setSelectedTab(1);
            props.handleRegistrationData({});
          }}
        >
          Register
        </p>
      </div>
    </div>
  );
};

export default LoginForm;
