import React from "react";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as DeleteIcon } from "../../assets/deleteBlack.svg";
import { ReactComponent as EditIcon } from "../../assets/editBlack.svg";
import { ReactComponent as LocationIcon } from "../../assets/location.svg";
import AdminContextMenuModal from "../admin/AdminContextMenuModal";
import { Tooltip } from "@mui/material";

const JobCard = (props) => {
  const contextMenuData = [
    {
      option: "Details",
    },
  ];
  function contextMenuFunction(event, resObj) {
    props.handelMoreBtn(resObj, event.target.textContent);
    console.log(resObj, event.target.textContent);
  }
  return (
    <div
      className={`job-card rounded-lg cursor-pointer ${
        props.job.status === "CANCEL" ? "opacity-50" : "shadow-md"
      }`}
      onClick={() => {
        if (props?.handelCardClick) {
          props.handelCardClick(props.job);
        }
      }}
      key={props.job.name + props.index}
    >
      <div className="job-placement grid grid-cols-4">
        <div
          className="col-span-1 pb-4 job-label pl-2 datatrimmer"
          title={props.job?.jobHeadlines ? props.job.jobHeadlines : "--"}
        >
          {props.job?.jobHeadlines ? props.job.jobHeadlines : "--"}
          {/* {props.job.status === "CANCEL" && (
            <span className="job-Skill-conatiner">
              <div className="skills-pill datatrimmer" title="Canceled">
                Canceled
              </div>
            </span>
          )} */}
        </div>
        <div className="col-span-1 pb-4 pl-2">
          <span className="job-label">Role: </span>
          <span>{props.job?.roles ? props.job.roles : "--"}</span>
        </div>
        <div className="col-span-1 pb-4">
          <span>Openings: </span>
          <span className="job-label mr-2">
            {props.job?.vacancyCount ? props.job.vacancyCount : "--"}
          </span>
        </div>
        <div className="col-span-1 pb-4 flex" style={{width:"276px"}}>
          <span
            onClick={() => {
              console.log(props.job);
            }}
          >
            Profiles shared
          </span>
          <span className="job-label mx-1">: {props.job.profilesShared}</span>
          <div className="action-btns flex ml-20">
            <span className="">
              <button className={"block"}>
                <AdminContextMenuModal
                  contextMenuData={contextMenuData}
                  page={"jobCard"}
                  func={(event) => {
                    event.stopPropagation();
                    contextMenuFunction(event, props.job);
                  }}
                  // disable={props.job.status === "CANCEL"}
                  onMouseOutz
                />
              </button>
              {/* <IconButton
                sx={
                  false
                    ? {
                        "&:hover": {
                          opacity: "0.4",
                          backgroundColor: "#FAF9F6",
                          boxShadow:
                            " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        },
                      }
                    : {
                        "&:hover": {
                          backgroundColor: "#FAF9F6",
                          boxShadow:
                            " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        },
                      }
                }
              >
                
              </IconButton> */}
            </span>
            <span className="">
              <Tooltip
                title={
                  props.job.profilesShared
                    ? "Job post cannot be edited as the Candidate profile already shared "
                    : props.job.status === "CANCEL"
                    ? "Cancelled job post cannot be edited"
                    : "Edit"
                }
                placement="top"
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    !props.job.profilesShared &&
                      props.handleEdit(props.job, "edit");
                  }}
                  // disabled={props.job.profilesShared}
                  sx={
                    false
                      ? {
                          "&:hover": {
                            opacity: "0.4",
                            backgroundColor: "#FAF9F6",
                            boxShadow:
                              " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                          },
                        }
                      : {
                          "&:hover": {
                            backgroundColor: "#FAF9F6",
                            boxShadow:
                              " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                          },
                        }
                  }
                >
                  <button
                    className={`block ${
                      props.job.profilesShared ? "opacity-50" : ""
                    }`}
                    disabled={props.job.profilesShared}
                  >
                    <EditIcon height="15px" width="15px" />
                  </button>
                </IconButton>
              </Tooltip>
            </span>
          </div>
        </div>
        <div className="col-span-1 pl-2">
          <div className="job-label pb-2">Job description </div>
          <div
            className="datatrimmer"
            title={props.job?.jobDescription ? props.job.jobDescription : "--"}
          >
            {props.job?.jobDescription ? props.job.jobDescription : "--"}
          </div>
        </div>
        <div className="col-span-1 pl-2">
          <div className="job-label pb-2">Key Skills </div>
          <div className="job-Skill-conatiner">
            {props.job?.keySkills?.map((skill) => {
              console.log(skill,"skillskillskill")
              return (
                <div
                  className="skills-pill datatrimmer"
                  title={skill}
                  key={skill}
                >
                  {skill}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-span-1" title={props.job.location}>
          <div className="job-label pb-2">Work Location </div>
          <div className="location-with-action flex">
            <p className="flex items-center">
              <LocationIcon height="15px" width="15px" />{" "}
            </p>
            <p className="whitespace-nowrap  mx-1">
              {" "}
              {props.job?.location
                ? props.job.location.length > 3
                  ? props.job.location.slice(0, 3).join(", ") + "..."
                  : props.job.location.join(", ")
                : "--"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
