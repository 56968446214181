import React, { useState } from "react";
import "./AdminJobPost.scss";
import { ReactComponent as LocationIcon } from "../../../assets/location.svg";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { useNavigate } from "react-router-dom";
import MapCompanyModal from "../admin-modals/map-company/MapCompanyModal";
import AdminContextMenuModal from "../AdminContextMenuModal";

const AdminJobPostCard = (props) => {
  const contextMenuData = [
    {
      option: "Details",
    },
  ];
  const [openMapCompanyModal, setOpenMapCompanyModal] = useState(false);
  
  const closeMapCompanyModal = () => {
    setOpenMapCompanyModal(!openMapCompanyModal);
  };
  const navigate = useNavigate();
  const handleShareProfile = () => {
    navigate("/admin/jobposts/share-profile", {
      state: { requestedProfile: props.job },
    });
  };
  function contextMenuFunction(event, resObj) {
    props.handelMoreBtn(resObj, event.target.textContent);
   
  }
  return (
    <div
      className={props.job.status==="CANCEL" ? " opacity-50 job-card border border-gray-400 rounded-lg shadow-md border-opacity-0 transform" : " opacity-100  job-card border border-gray-400 rounded-lg shadow-md border-opacity-0 transform"}
      onClick={() => {
        if (props?.handelCardClick) {
          props.handelCardClick(props.job);
        }
      }}
      key={props.job.name + props.index}
    >
      <div className="leftCardItem grid grid-cols-3 gap-4 ">
        <div className="col-span-12 leftCardItemContent">
          <div className="label">Company Name</div>
          <div className="value">{props.job.companyName}</div>
        </div>
        <div className="col-span-12">
          <div className="label">Client Name</div>
          <div className="value">{props.job.clientName}</div>
        </div>
        {/* <div className="col-span-12 flex">
          <div className="label">
            <RoomOutlinedIcon />
          </div>
          <div className="value  mx-2">{props.job.companyLocation}</div>
        </div> */}
      </div>
      <div className="job-placement grid grid-cols-9">
        <div className="col-span-3 pb-4 job-label pl-2 datatrimmer" title={props.job.jobHeadlines}>
          {props.job.jobHeadlines}
        </div>
        <div className="col-span-2 pb-4 pl-2">
          <span className="job-label">Role: </span>
          <span className="job-role">{props.job.roles}</span>
        </div>
        <div className="col-span-2 pb-4">
          <span className="job-label">Openings: </span>
          <span className="job-role mr-2">{props.job.vacancyCount}</span>
          {/* <span>Added Profiles: </span>
          <span className="job-label">{props.job.addedProfiles}</span> */}
        </div>


{props.tabIndex === 0 && (
  <div className="action-btns flex ml-20">
              <span className="">
                <button className={"block"}>
                  <AdminContextMenuModal
                    contextMenuData={contextMenuData}
                    page={"jobCard"}
                    func={(event) => {
                      event.stopPropagation();
                      contextMenuFunction(event, props.job);
                    }}
                    // disable={props.job.status === "CANCEL"}
                    onMouseOutz
                  />
                </button>
                
              </span>
             
            </div>

)}
       








        {props.tabIndex === 1  && (
          <div className="col-span-2 pb-4 flex">
            <span className="job-label">Profiles Shared: </span>
            <span className="job-role mr-2">{props.job.profilesShared}</span>

            <div className="action-btns flex ">
              <span className="">
                <button className={"block"}>
                  <AdminContextMenuModal
                    contextMenuData={contextMenuData}
                    page={"jobCard"}
                    func={(event) => {
                      event.stopPropagation();
                      contextMenuFunction(event, props.job);
                    }}
                    // disable={props.job.status === "CANCEL"}
                    onMouseOutz
                  />
                </button>
                
              </span>
             
            </div>
          </div>
        )}
        { window.location.href?.includes("share-profile")  && (
          <div className="col-span-2 pb-4 flex">
           <div>
           <span className="job-label">Profiles Shared: </span>
            <span className="job-role mr-2">{props.job.profilesShared}</span>
           </div>
            <div className="action-btns flex ">
              <span className="">
                <button className={"block"}>
                  <AdminContextMenuModal
                    contextMenuData={contextMenuData}
                    page={"jobCard"}
                    func={(event) => {
                      event.stopPropagation();
                      contextMenuFunction(event, props.job);
                    }}
                    // disable={props.job.status === "CANCEL"}
                    onMouseOutz
                  />
                </button>
                
              </span>
             
            </div>
          </div>
        )}

        <div className="col-span-3 pl-2 datatrimmer" title={props.job.jobDescription}>
          <div className="job-label pb-2">Job description </div>
          <span className="job-role">{props.job.jobDescription}</span>
        </div>
        <div className="col-span-2 pl-2">
          <div className="job-label pb-2">Key Skills </div>
          <div className="skills-pill-Container">
            {props.job?.keySkills.map((skill) => {
              return (
                <div
                  key={skill}
                  className="skills-pill datatrimmer job-role"
                  title={skill}
                >
                  {skill}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-span-2">
          <div className="job-label pb-2" title={props.job?.location}>Work Location </div>
          <div className="location-with-action">
            <span className="flex items-center job-role" title={props.job?.location}>
              <LocationIcon height="15px" width="15px" /> 
              {/* {props.job.location} */}
              {props.job?.location ? (
        props.job.location.length > 1 ? (
          props.job.location.slice(0, 1).join(', ') + '...'
        ) : (
          props.job.location.join(', ')
        )
      ) : "--"}
            </span>
          </div>
        </div>
        {props.tabIndex === 0 ? (
          <div className="col-span-2 shareProfileBtn my-2">
            <div className="label"></div>
            <button
              className="text-blue-700 font-semibold Poppins-Medium"
              onClick={() => {
                if(props.job.status !== "CANCEL")
                {setOpenMapCompanyModal(true);}
                console.log(props.job.status);
              }}
            >
              Map Company
            </button>
          </div>
        ) : (
          props.showShareBtn === "true" && (
            <div className="col-span-2 shareProfileBtn my-2">
              <div className="label"></div>
              <button
              disabled={props.job.status==="CANCEL" }
                className=""
                onClick={() => {
                  console.log(props.job);
                  handleShareProfile();
                }}
              >
                +Share Profile
              </button>
            </div>
          )
        )}
        <h1 className="endDate">Last date for sharing the profile : {props.job.dateForSharingProfiles}</h1>
      </div>
      {openMapCompanyModal && (
        <MapCompanyModal
          fetchNewJobPosts={props.fetchNewJobPosts}
          toast={props.toast}
          jobPostId={props?.job?.id}
          closeMapCompanyModal={closeMapCompanyModal}
        />
      )}
    </div>
  );
};

export default AdminJobPostCard;
