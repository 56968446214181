import React, { useState, Fragment, useEffect } from "react";
import "./InterviewerPageHeader.scss";
import QspiderLogo from "../../../assets/accrossGeo.svg";
import { useNavigate } from "react-router-dom";
import { UserProfile } from "../../common/user-profile-header/UserProfile";

function InterviewerPageHeader(props) {
  const navigate = useNavigate();
  let userName = JSON.parse(localStorage.getItem("across-geo-user"))?.username;
  return (
    <div>
    <nav className="app-navbar">
      <div className="navbar-brand">
        {/* <HeaderSquareLogo className="headerSquareLogo"/> */}
        <div>
          {" "}
          <img src={QspiderLogo} alt="Logo"></img>
        </div>
      </div>
      <div className="navbar-app-end">
        <span className="header-name">{userName}</span>
        <div className="navbar-app-item">
          <UserProfile />
        </div>
      </div>
    </nav>
  </div>
  );
}

export default InterviewerPageHeader;
