import React, { useState, useRef, useEffect } from "react";
import "./BdJobPost.scss";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import BdJobCard from "./BdJobCard";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as NoDataFound } from "../../../assets/noDataFound.svg";
import { getBdJobListReq, BdJobPostSearch, getJobListReq } from "../../../api/api-services";
import JobDetails from "../../client/modals/JobDetails";
import { useNavigate } from "react-router-dom";
import BdJobPostModal from "../BdJobPostModal";
import { jwtDecode } from 'jwt-decode'
import { AddLocation } from "@mui/icons-material";

const BdJobPost = () => {
  const cardSearchInput = useRef(null);
  let user = JSON.parse(localStorage.getItem("across-geo-user"));
  let [pageno, setpageNo] = useState(0);
  let [bdpageno, setBdpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let size = 10;
  const navigate = useNavigate();
  const [mode, setMode] = useState("");
  let [jobs, setJobs] = useState([]);
  let [jobList, setJobList] = useState([]);
  let [jobListCount, setJobListCount] = useState();
  let [jobListCount1, setJobListCount1] = useState();
  let [bdjobs, setBdJobs] = useState([]);
  let [bdjobList, setBdJobList] = useState([]);
  const [addJobPost, setAddJobPost] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  let [postJobDetails, setPostDetails] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [editJobPost, setEdirJobPost] = useState(false)
  let token = JSON.parse(localStorage.getItem("across-geo-user")).access_token;
  const mailId = jwtDecode(token).email;
  let handelCardSearch = debounce((e) => {
    let fieldValue = e.target.value.toLowerCase();
    // searchCode here
    let filteredJobs = jobs.filter((job) => {
      // Check if the job name or skills contain the search keyword
      return (
        job?.jobHeadlines?.toLowerCase()?.includes(fieldValue) ||
        job.keySkills?.some((skill) => skill.toLowerCase().includes(fieldValue)) || job?.companyName?.toLowerCase()?.includes(fieldValue) || job?.clientName?.toLowerCase()?.includes(fieldValue) || job?.jobDescription?.toLowerCase()?.includes(fieldValue)
      );
    });
    setJobList(filteredJobs);
  });
  function debounce(callback, delay = 1000) {
    var time;
    return (...args) => {
      clearTimeout(time);
      time = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  }
  let getJobList = async (page) => {
    try {
      let response = await getBdJobListReq(user.uid, page, size);
      if (response.status === 200 || response?.data?.response || response?.data)
        setLastpage(response?.data?.response?.last);
      if (response?.data?.response?.last === false) {
        setpageNo(page + 1);
      }
      if (page === 0) {
        setJobs([...JSON.parse(JSON.stringify(response.data.response?.content))])
        setJobList([...response?.data?.response?.content]);
        setJobListCount1(response.data.response.totalElements)
      } else {
        setJobs([...jobList, ...JSON.parse(JSON.stringify(response.data.response?.content))])
        setJobList([...jobList, ...response?.data?.response?.content]);
      }
    } catch (err) {
      console.error(err, "list error");
    }
  };

  const getJobListById = async (page) => {
    try {
      let response = await getJobListReq(mailId, page, size);
      if (response.status === 200 || response?.data?.response || response?.data)
        setLastpage(response?.data?.response?.last);
      if (response?.data?.response?.last === false) {
        setBdpageNo(page + 1);
      }
      if (page === 0) {
        setBdJobs([...JSON.parse(JSON.stringify(response.data.response?.content))])
        setBdJobList([...response?.data?.response?.content]);
        setJobListCount(response.data.response.totalElements)
      } else {
        setBdJobs([...bdjobList, ...JSON.parse(JSON.stringify(response.data.response?.content))])
        setBdJobList([...bdjobList, ...response?.data?.response?.content]);
      }
    } catch (err) {
      console.error(err, "list error");
    }
  };
  // const handlescroll = (event) => {
  //   if (
  //     Math.ceil(event?.target?.scrollTop + event?.target?.clientHeight) ===
  //     event?.target?.scrollHeight
  //   ) {
  //     if (lastPage === false) {
  //       getJobList(pageno);
  //     }
  //   }
  // };
  const resetSearch = () => {
    setIsSearching(false);
    setpageNo(0);
    setLastpage(false);
    setJobList([]);
  };
  let getJobListSearched = async (e, page) => {
    try {
      setIsSearching(true);
      if(tabIndex===0){

      }
      else if(tabIndex===1){
      let response = await BdJobPostSearch(user.uid, e, page, size);
      if (response.status === 200 || response?.data?.response || response?.data)
        setLastpage(response?.data?.response?.last);
      if (response?.data?.response?.last === false) {
        setpageNo(page + 1);
      }
      if (page === 0) {
        setJobs([...JSON.parse(JSON.stringify(response.data.response?.content))])
        setJobList([...response?.data?.response?.content]);
      } else {
        setJobs([...jobList, ...JSON.parse(JSON.stringify(response.data.response?.content))])
        setJobList([...jobList, ...response?.data?.response?.content]);
      }
      setJobListCount1(response?.data?.response?.totalElements);
    }
    } catch (err) {
      console.error(err, "list error");
    }
  };
  const handlescroll = (event) => {
    const target = event?.target;
    if (!target) return;

    const scrolledToBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >= target.scrollHeight - 1;

    if (scrolledToBottom && !lastPage && !isSearching) {
      getJobListById(bdpageno);
    } else if (scrolledToBottom && !lastPage && isSearching) {
      getJobListSearched(searchTerm, pageno)
    }
  };

  const handlAllJobPostescroll = (event) => {
    const target = event?.target;
    if (!target) return;

    const scrolledToBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >= target.scrollHeight - 1;

    if (scrolledToBottom && !lastPage && !isSearching) {
      getJobList(pageno);
    } else if (scrolledToBottom && !lastPage && isSearching) {
      getJobListSearched(searchTerm, pageno)
    }
  };

  useEffect(() => {
    getJobListById(0);
  }, []);
  const handelMoreBtn = (data, type) => {

    setSelectedJob({ ...data });
    if (type === "Details") {

      setPostDetails(true);
    }
  };
  let closeModal = (val) => {


    setPostDetails(false);



  };
  return (
    <>
      {addJobPost ? (
        <BdJobPostModal
          toast={toast}
          selectedJob={selectedJob}
          getJobList={getJobList}
          // mode={mode}
          closeModal={addJobPost}
          setAddJobPost={setAddJobPost}
          getJobListById={getJobListById}
          
        />
      ) : editJobPost ? <>
        <BdJobPostModal
          toast={toast}
          selectedJob={selectedJob}
          getJobList={getJobList}
          mode={"edit"}
          closeModal={editJobPost}
          setEdirJobPost={setEdirJobPost}
          getJobListById={getJobListById}
        />

      </> : (
        <>
          {postJobDetails ? (
            <JobDetails jobModalHeight="inherit" closeModal={closeModal} selectedJob={selectedJob} />) :
            <div className="adminJobPostContainer mx-3 w-full home-component-display mt-2.5 overflow-x-hidden overflow-y-auto">
              <div className="headerContent">
                <div className="tabContents my-2">
                  <Tabs
                    selectedIndex={tabIndex}
                    onSelect={(index) => {
                      setTabIndex(index);
                      setSearchTerm("");
                      setPostDetails(false);
                      resetSearch();
                      index === 0 ? getJobListById(0) : getJobList(0);
                    }}
                  >
                    <div className="pannelTabs row flex">
                      <div className="col-6">
                        <TabList className="flex outline-none" style={{ height: "50px" }}>
                          <Tab className={tabIndex === 0 ? "selectedTab" : ""}>
                            My Job Post{jobListCount !==undefined && " - " + jobListCount}
                          </Tab>
                          <Tab className={tabIndex === 1 ? "selectedTab ml-2" : "ml-2"}>
                            Company Job Post{jobListCount1 !== undefined && " - "+ jobListCount1}
                          </Tab>
                        </TabList>
                      </div>
                      <div className="searchComponent flex row col-6">
                        <div className="flex">
                          <div className="searchInput">
                            <input
                              className="table-search-input"
                              value={searchTerm}
                              onChange={(e) => {
                                setSearchTerm(e.target.value);
                                if (e.target.value.trim() === "") {
                                  resetSearch();
                                  getJobList(0);
                                } else {
                                  getJobListSearched(e.target.value, 0);
                                }
                              }}
                              type="text"
                              // ref={cardSearchInput}
                              placeholder="Search"
                            />
                          </div>
                          <div
                            className="addBtn float-right"
                            onClick={() => {
                              setAddJobPost(true)
                            }}
                          >
                            <button style={{ marginTop: "4px", width: "140px" }}>
                              Post a Job
                            </button>
                          </div>
                        </div>
                      </div>
                      <TabPanel>
                        <div
                          className="job-card-container"
                          onScroll={(e) => {
                            handlescroll(e);
                          }}
                        >
                          {bdjobList?.map((job, index) => {
                            return <BdJobCard
                              job={job}
                              handelMoreBtn={handelMoreBtn}
                              index={index} showShareBtn="true"
                              from="myJobPost"
                              setSelectedJob={setSelectedJob}
                              getJobListById={getJobListById}
                              setEdirJobPost={setEdirJobPost}
                              editJobPost={editJobPost}
                            />;
                          })}
                          {bdjobList.length === 0 && (
                            <>
                              <div className="no-data-availableIcon">
                                <NoDataFound />
                              </div>
                              <div className="no-data-availableText">
                                <p>Oops !!</p> <p>There are no data to be found yet</p>
                              </div>
                            </>
                          )}
                        </div>
                        <ToastContainer
                          position="top-right"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                      </TabPanel>
                      <TabPanel>
                        <div
                          className="job-card-container"
                          onScroll={(e) => {
                            handlAllJobPostescroll(e);
                          }}
                        >
                          {jobList?.map((job, index) => {
                            return <BdJobCard
                              job={job} handelMoreBtn={handelMoreBtn}
                              index={index}
                              showShareBtn="true"
                              from="companyJobPost"
                              setSelectedJob={setSelectedJob}
                              getJobListById={getJobListById}
                              setEdirJobPost={setEdirJobPost}
                              editJobPost={editJobPost}
                            />;
                          })}
                          {jobList.length === 0 && (
                            <>
                              <div className="no-data-availableIcon">
                                <NoDataFound />
                              </div>
                              <div className="no-data-availableText">
                                <p>Oops !!</p> <p>There are no data to be found yet</p>
                              </div>
                            </>
                          )}
                        </div>
                        <ToastContainer
                          position="top-right"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          }
        </>
      )}
    </>

  );
};

export default BdJobPost;
