import React, { useState, useEffect } from "react";
import "./AdminJobPost.scss";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AdminJobPostCard from "./AdminJobPostCard";
import {
  getNewJobPost,
  getOldJobPost,
  adminNewJobPostSearch,
  adminOldJobPostSearch,
} from "../../../api/api-services";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { ReactComponent as NoDataFound } from "../../../assets/noDataFound.svg";
import AdminJobPostDetails from "./AdminJobPostDetails";
import JobDetails from "../../client/modals/JobDetails";

const AdminJobPost = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [newJobList, setNewJobList] = useState([]);
  const [newJobListCount, setNewJobListCount] = useState();
  const [oldJobListCount, setOldJobListCount] = useState();
  const [oldJobList, setOldJobList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shouldSelectOldClientsTabParam = queryParams.get(
    "shouldSelectOldClientsTab"
  );
  const [shouldSelectOldClientsTab, setShouldSelectOldClientsTab] = useState(
    shouldSelectOldClientsTabParam === "true" // Convert the string to a boolean value
  );
  let [postJobDetails, setPostDetails] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let [oldJobpageno, setOldJobpageno] = useState(0);
  let [oldlastPage, setOldLastpage] = useState(false);
  let size = 10;
  const [isSearching, setIsSearching] = useState(false);

  const fetchNewJobPosts = async (page) => {
    try {
      const response = await getNewJobPost(page, size);
      // if (response?.data?.response?.last === false) {
      //   setpageNo(page + 1);
      //   setLastpage(response?.data?.response?.last);
      // }
      // setNewJobList((prevList) => [...prevList, ...response.data.response.content]);
      setIsLoading(false);
      if (page === 0) {
        setNewJobList([...response.data.response.content]);
      } else {
        setNewJobList([...newJobList, ...response.data.response.content]);
      }
      setpageNo(page + 1);
      setLastpage(response.data.response.last);
      setNewJobListCount(response.data.response.totalElements);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const fetchOldJobPosts = async (page) => {
    try {
      const response = await getOldJobPost(page, size);
      setOldJobListCount(response.data.response.totalElements);
      setOldJobList((prevList) => [
        ...prevList,
        ...response.data.response.content,
      ]);
      setIsLoading(false);
      setOldJobpageno(page + 1);
      setOldLastpage(response.data.response.last);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const fetchJobPosts = (pageno, oldJobpageno) => {
    setIsLoading(true);

    if (tabIndex === 0) {
      fetchNewJobPosts(pageno);
    } else if (tabIndex === 1) {
      fetchOldJobPosts(oldJobpageno);
    }
  };

  // useEffect(() => {
  //   fetchJobPosts(pageno, oldJobpageno);
  // }, [tabIndex]);
  // useEffect(() => {
  //   if (tabIndex === 0) {
  //     fetchNewJobPosts(pageno);
  //   }
  // }, []);
  const [hasFetchedOldJobPosts, setHasFetchedOldJobPosts] = useState(false);

  useEffect(() => {
    if (!hasFetchedOldJobPosts && !isSearching && hasFetchedNewJobPosts) {
      setHasFetchedOldJobPosts(true);
      fetchOldJobPosts(0);
    }
  }, [hasFetchedOldJobPosts]);


  const [hasFetchedNewJobPosts, setHasFetchedNewJobPosts] = useState(false);

  useEffect(() => {
    if (!hasFetchedNewJobPosts && !isSearching) {
      setHasFetchedNewJobPosts(true);
      fetchNewJobPosts(0);
    }
  }, [hasFetchedNewJobPosts]);
  console.log(tabIndex);
  // const handleScroll = (event) => {
  //   const { scrollTop, clientHeight, scrollHeight } = event.target;

  //   if (scrollTop + clientHeight >= scrollHeight - 20) {
  //     if (tabIndex === 0 && lastPage === false && !isLoading) {
  //       setIsLoading(true); // Set isLoading to prevent concurrent API calls

  //       fetchNewJobPosts(pageno);
  //     } else if (tabIndex === 1 && !oldlastPage && !isLoading) {
  //       setIsLoading(true); // Set isLoading to prevent concurrent API calls
  //       fetchOldJobPosts(oldJobpageno);
  //     }
  //   }
  // };

  

  const handleScroll = async (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const target = event?.target;
    if (!target) return;

    const scrolledToBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >=
      target.scrollHeight - 1;

    if (scrolledToBottom) {
      setIsLoading(true);

      if (tabIndex === 0) {
        if (!lastPage && !isLoading) {
          if (isSearching) {
            await handleSearch(searchTerm, pageno);
          } else {
            await fetchNewJobPosts(pageno);
          }
        }
      } else if (tabIndex === 1) {
        if (!oldlastPage && !isLoading) {
          if (isSearching) {
            await handleSearch(searchTerm, oldJobpageno);
          } else {
            await fetchOldJobPosts(oldJobpageno);
          }
        }
      }
    }
  };


  const [oldJobListSearched, setoldJobListSearched] = useState([]);
  const handleSearch = async (event, page) => {
    const searchTerm = event;
    setSearchTerm(searchTerm);

    try {
      setIsLoading(true);
      setIsSearching(true);

      let response;

      if (tabIndex === 0) {
        // Call adminNewJobPostSearch API
        response = await adminNewJobPostSearch(searchTerm, page, size);
        if (page === 0) {
          setNewJobList([...response.data.response.content]);
        } else {
          setNewJobList([...newJobList, ...response.data.response.content]);
        }
        if (response?.data?.response?.last === false) {
          setpageNo(page + 1);
        }
        // setNewJobList(response.data.response.content);
        // setpageNo(page + 1);
        setLastpage(response.data.response.last);
        setNewJobListCount(response.data.response.totalElements);
      } else if (tabIndex === 1) {
        // Call adminOldJobPostSearch API
        response = await adminOldJobPostSearch(searchTerm, page, size);
        if (page === 0) {
          setoldJobListSearched([...response.data.response.content]);
        } else {
          setoldJobListSearched([...oldJobList, ...response.data.response.content]);
        }
        if (response?.data?.response?.last === false) {
          setOldJobpageno(page + 1);
        }
        // setOldJobList(response.data.response.content);
        // setOldJobpageno(page + 1);
        setOldLastpage(response.data.response.last);
        setOldJobListCount(response.data.response.totalElements);
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (shouldSelectOldClientsTab) {
      fetchOldJobPosts(0)
      setTabIndex(1); // Set the tab index to 1 to select the "Old Clients Request" tab
    }
  }, [shouldSelectOldClientsTab]);
  const handelMoreBtn = (data, type) => {
    setSelectedJob({ ...data });
    if (type === "Details") {
      setPostDetails(true);
    }
  };
  let closeModal = (val) => {
    setPostDetails(false);
  };
  const resetSearch = () => {
    setIsSearching(false);
    setpageNo(0);
    setLastpage(false);
    tabIndex === 0 ? setNewJobList([]) : setOldJobList([]);
    
  };
  return (
    <>
        {postJobDetails ? (
                <JobDetails
                  jobModalHeight="355px"
                  closeModal={closeModal}
                  selectedJob={selectedJob}
                />
              ) : (
  
    <div className="adminJobPostContainer mx-3 w-full home-component-display mt-2.5 overflow-x-hidden overflow-y-auto">
      <div className="">
        <div className=" flex justify-end " style={{height:"32px"}} >
        
          {!postJobDetails && (
            <input
              type="text"
              className="admin-jobPost-search-input mt-10  relative "
              placeholder="Search job posts"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                if (e.target.value.trim() === "") {
                  resetSearch();
                  console.log(e.target.value)
                  tabIndex === 0 ? fetchNewJobPosts(0) : fetchOldJobPosts(0);
                  // tabIndex === 0 ? setHasFetchedNewJobPosts(false) :  setHasFetchedOldJobPosts(false);
                } else {
                  handleSearch(e.target.value, 0);
                }
              }}
            />
          )}
        </div>
        <div className="tabContents my-2">
      
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => {
              setTabIndex(index);
              setSearchTerm("");
              setPostDetails(false);
              resetSearch();
              index === 0 ? fetchNewJobPosts(0) : fetchOldJobPosts(0);
              // index === 0 ? setOldJobListCount("") : setNewJobListCount("");
              
            }}
          >
            <div className="pannelTabs">
              <TabList className="flex outline-none relative " style={{ height: "44px" ,bottom:"-4px",position:"absolute"}}>
                <Tab className={tabIndex === 0 ? "selectedTab" : ""}>
                  New Clients Request  { newJobListCount !== undefined && " - "+ newJobListCount}
                </Tab>
                <Tab className={tabIndex === 1 ? "selectedTab" : ""}>
                  Old Clients Request  { oldJobListCount !== undefined && " - "+ oldJobListCount}
                </Tab>
              </TabList>
            </div>

            <TabPanel>
              {postJobDetails ? (
                <JobDetails
                  jobModalHeight="355px"
                  closeModal={closeModal}
                  selectedJob={selectedJob}
                />
              ) : (
                <div
                  className="job-card-container overflow-y-auto "
                  onScroll={handleScroll}
                >
                  {newJobList.length === 0 && !isLoading ? (
                    <>
                      <div className="no-data-availableIcon">
                        <NoDataFound />
                      </div>
                      <div className="no-data-availableText">
                        <p>Oops !!</p> <p>There are no data to be found yet</p>
                      </div>
                    </>
                  ) : newJobList.length === 0 && !isLoading ? (
                    <div className="no-data-page text-center flex items-center">
                      <div className="noDataCompany">
                        <div className="no-data-availableIcon">
                          <NoDataFound />
                        </div>
                        <div className="no-data-availableText">
                          <p>Oops !!</p>{" "}
                          <p>There are no data to be found yet</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    newJobList.map((job, index) => (
                      <>
                        <AdminJobPostCard
                          key={job.id}
                          job={job}
                          index={index}
                          tabIndex={tabIndex}
                          toast={toast}
                          fetchNewJobPosts={fetchNewJobPosts}
                          handelMoreBtn={handelMoreBtn}
                        />
                      </>
                    ))
                  )}
                </div>
              )}
            </TabPanel>
            <TabPanel>
             
                <div
                  className="job-card-container overflow-y-auto"
                  onScroll={handleScroll}
                >
                  {oldJobList.length === 0 || (isSearching && oldJobListSearched.length === 0 ) && !isLoading ? (
                    <>
                      <div className="no-data-availableIcon">
                        <NoDataFound />
                      </div>
                      <div className="no-data-availableText">
                        <p>Oops !!</p> <p>There are no data to be found yet</p>
                      </div>
                    </>
                  ) : oldJobList.length === 0 || (isSearching && oldJobListSearched.length === 0 )&& !isLoading ? (
                    <div className="no-data-page text-center flex items-center">
                      <div className="noDataCompany">
                        <div className="no-data-availableIcon">
                          <NoDataFound />
                        </div>
                        <div className="no-data-availableText">
                          <p>Oops !!</p>{" "}
                          <p>There are no data to be found yet</p>
                        </div>
                      </div>
                    </div>
                  ) :!isSearching ? (
                    oldJobList.map((job, index) => (
                      <AdminJobPostCard
                        key={job.id}
                        job={job}
                        index={index}
                        tabIndex={tabIndex}
                        showShareBtn="true"
                        handelMoreBtn={handelMoreBtn}
                      />
                    ))
                  ) :oldJobListSearched.map((job, index) => (
                      <AdminJobPostCard
                        key={job.id}
                        job={job}
                        index={index}
                        tabIndex={tabIndex}
                        showShareBtn="true"
                        handelMoreBtn={handelMoreBtn}
                      />
                    ))}
                </div>
              
            </TabPanel>
          </Tabs>
            
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        className={"tost-msg-stylinh"}
        draggable={false}
        theme="light"
      />
    </div>
              )}

    </>
  );
};

export default AdminJobPost;
