import React from 'react'
import './WorkDetails.scss'

function WorkDetails() {
    return (
        <div>
            <div>
                <p className='weWorhHead'>How we Work ?</p>
                <p className='reviewSubHead'>Exceptional Talent, Effortlessly Delivered.</p>
            </div>

            <div style={{padding:"30px 30px 0px 30px"}} className='flex justify-content-center'>
                <div>
                    <div className='flex justify-content-center row'>
                        <div className='col-md-6 col-sm-12'>
                            <div style={{ justifyContent: "center" }} className='flex'>
                                <div className='divLogo'>

                                </div>
                            </div>
                            <div className='workCard'>
                                <p className='workCardHead'>Post Your Job Requirements</p>
                                <p className='workCardSubHead'>
                                    Share specific details and attract the right talent with a comprehensive job description.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <div style={{ justifyContent: "center" }} className='flex'>
                                <div className='divLogo1'>
                                </div>
                            </div>
                            <div className='workCard'>
                                <p className='workCardHead'>Tap into Our Talent Pool</p>
                                <p className='workCardSubHead'>
                                    Leverage our vast pool after posting requirements; our experts will shortlist suitable candidates.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex row'>
                        <div className='col-md-6 col-sm-12'>
                            <div style={{ justifyContent: "center" }} className='flex'>
                                <div className='divLogoCal'>
                                </div>
                            </div>
                            <div className='workCard'>
                                <p className='workCardHead'>Thoughtful Shortlisting</p>
                                <p className='workCardSubHead'>
                                    Multiple internal interviews ensure careful selection of top applicants for your consideration.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12 '>
                            <div style={{ justifyContent: "center" }} className='flex'>
                                <div className='divLogoOffer'>
                                </div>
                            </div>
                            <div className='workCard'>
                                <p className='workCardHead'>Final Interview and Selection</p>
                                <p className='workCardSubHead'>
                                    Conduct the final interview with shortlisted candidates, confidently extending offers to the best-fit resource. Partner with us for seamless and effective hiring processes
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default WorkDetails