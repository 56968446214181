import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, InputAdornment, Grid } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import "./LoginPage.scss";
import { Link, useNavigate } from "react-router-dom";
import { SelectDropdown } from "../landing-page/common/select-dropdown";
import { loginReq } from "../../api/api-services";
import NavBarComponent from "../landing-page/NavBarComponent";
import FooterSection from "../landing-page/footer-section/FooterSection";

const roles = [
  { label: "Admin", value: "admin" },
  { label: "Client", value: "client" },
  { label: "Candidate", value: "candidate" },
  { label: "Interviewer", value: "interviewer" },
  { label: "Interviewer co-ordinator", value: "coordinator" },
  { label: "Business Development Executive", value: "bd" },
];

const useStyles = makeStyles((theme) => ({
  form: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  field: {
    margin: theme.spacing(1),
    width: "100%",

    background: "#FFFFFF 0% 0% no-repeat padding-box",
    opacity: "1",
  },

  input: {
    backgroundColor: "#fff",
    "&:focused": {
      backgroundColor: "#fff",
    },
    "& .Mui-focused": {
      backgroundColor: "#fff",
    },
  },
}));

const initialValues = {
  username: "",
  password: "",
};

const validationSchema = Yup.object({
  username: Yup
  .string()
  .trim()
  .required("Email id is required")
  .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Enter valid email Id"),
  password: Yup.string().required("Password is required"),
});

const LoginPage = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState([]);
  const [showError, setShowError] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const handleRoleChange = (data) => {
    console.log(data);
    localStorage.setItem("selected-role", data[0].value);
    setSelectedRole([...data]);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.stopPropagation()
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on component mount

    const handleRefresh = () => {
      window.scrollTo(0, 0); // Scroll to the top on page refresh
    };

    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, []);

  const handleSubmit = (values) => {
    let tempRole = localStorage.getItem("selected-role");
    const lowercasedUsername = values.username.toLowerCase();
    const updatedValues = { ...values, username: lowercasedUsername };
   
    try {
      const res = loginReq(updatedValues)
        .then((res) => {
          if (res.data && res.data.response) {
            console.log("response", res.headers);
            const response = res.data.response;
            if (response.access_token) {
              localStorage.setItem("across-geo-user", JSON.stringify(response));
              navigate("/" + response.role.toLowerCase(), { replace: true });
            } else {
              setShowError(true);
              console.log("no token ");
            }
          } else {
            console.log("no resp ", res);
            if (res.response.status === 400) {
              setErrorContent("User account is not active");
            } else if (res.response.status === 404) {
              setErrorContent("Email or Password is invalid. Please try again");
            } else {
              setErrorContent("Something went wrong. Please try again later");
            }

            setShowError(true);
          }
        })
        .catch((error) => {
          console.error("login error", error);
        });
    } catch (e) {
      console.error("try catch ", e);
    }
  };

  return (
    <div className=" overflow-x-hidden">
       <div className="loginContainer">
      <Grid container spacing={0}>
        <div className="navbarSection-stuReg">
          <NavBarComponent />
        </div>
        <div className="registrationContent flex justify-center">
          <h1>
            Welcome <span className="mx-2"> Back!</span>
          </h1>
          {/* <h5>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt tet clita kasd
          </h5> */}
        </div>
        <Grid item xs={12} sm={6}>
          <div className="formContainer">
            <p className="heading">Log In</p>
            <div className="formContents">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form className={classes.form}>
                    <p className=" formLabelLogin"> <span className="text-red-500 ">*</span>Email</p>
                    <Field
                      name="username"
                      placeholder="Enter your email"
                      autoFocus
                      as={TextField}
                      onFocus={() => setShowError(false)}
                      variant="outlined"
                      className={classes.field}
                      error={touched.username && Boolean(errors.username)}
                     
                      autoComplete="off"
                      inputProps={{
                        className: classes.input,
                      }}
                    />
                      {errors.username && touched.username ? (
                  <div className="text-red-500 error_text md:mt-1 ml-2">
                    {errors.username}
                  </div>
                ) : null}
                    <p className=" formLabelLogin"> <span className="text-red-500  ">*</span> Password</p>
                    <Field
                      name="password"
                      autoComplete="off"
                      placeholder="Enter your password"
                      as={TextField}
                      onFocus={() => setShowError(false)}
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      className={classes.field}
                      error={touched.password && Boolean(errors.password)}
                      
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {!showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.password && touched.password ? (
                  <div className="text-red-500 error_text md:mt-1 ml-2">
                    {errors.password}
                  </div>
                ) : null}
                    {showError === true ? (
                      <span
                        // style={{ position: "relative", }}
                        className="error-msg error_text ml-0.5"
                      >
                        {errorContent}
                      </span>
                    ) : (
                      ""
                    )}
                    <div className="forgotPw my-3 cursor-pointer">
                      <Link to="/forgot-password">Forgot Password ?</Link>
                    </div>
                    <div className="logInBtnContainer">
                      <Button type="submit" className="logInBtn">
                        Login
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="registerContainer my-2">
              <p>Don't have an account ?</p>
              <p
                className="mx-2 cursor-pointer"
                onClick={() => {
              
                  navigate("/register");
                  // window.location.reload();
                  props.handleRegistrationData({});
                }}
              >
                Register
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
     
    </div>
    <FooterSection/>
    </div>
   
  );
};

export default LoginPage;
