import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CustomerReviews.scss";
import Slide from "react-reveal/Slide";
import AvtarTwo from "../../../assets/avtar2_testimonial.png";
import AvtarThree from "../../../assets/avtar3_testimonial.png";

const CustomerReviews = () => {
  const sliderRef = useRef(null);
  const cards = [
    {
      id: 1,
      avatar: AvtarTwo,
      name: " Divyashree K P ",
      // company: "Valtech",
      designation: "Business Developer ",
      // date: "05 dec 2022 | 5:30",
      // headerContent: "The support is awesome",
      content:
        "Grateful to ACROSSGEO for outstanding support in securing my job; their expert guidance and personalized approach made the process seamless. From grooming to interview prep, their unwavering commitment proved invaluable. Thanks to them, I'm now in a role aligning perfectly with my career goals",
    },
    {
      id: 2,
      avatar: AvtarTwo,
      name: "Anusha S ",
      // company: "Valtech",
      designation: "HR Executive",
      // date: "05 dec 2022 | 5:30",
      // headerContent: "The support is awesome",
      content:
        "I was skeptical about online job portals, but AcrossGeo proved me wrong. Their personalized recommendations and expert career guidance landed me a job offer faster than I ever thought possible. Game changer!",
    },
    {
      id: 3,
      avatar: AvtarThree,
      name: "Darshan",
      // company: "TCS",
      designation: "Software Developer ",
      // headerContent: "I recommend this agency",
      // date: "23 feb 2023 | 8:45",
      content:
        "AcrossGeo transformed my job search, providing a seamless platform that connected me with the perfect opportunity. Their personalized support and effective network played a pivotal role in securing my current job. I highly recommend AcrossGeo for its outstanding assistance in navigating the job market.",
    },
    {
      id: 4,
      avatar: AvtarThree,
      name: "Shashank M",
      // company: "Deloitte",
      designation: "Software Tester",
      // headerContent: "Excellent aesthetic design",
      // date: "22 Apr 2023 | 3:38",
      content:
        "Thankful to AcrossGeo for guidance in securing a remarkable job, their approach and commitment to understanding my career goals set them apart. The team's expertise made the job-seeking process seamless and rewarding. I wholeheartedly recommend AcrossGeo for connecting talent with opportunities. ",
    },

    {
      id: 5,
      avatar: AvtarThree,
      name: "Sagar ",
      // company: "Wipro",
      designation: "PHP Developer",
      // headerContent:"Excellent aesthetic design",
      // date: " 16 mar 2022 | 7:05",
      content:
        "Job hunting was made effortless with AcrossGeo's seamless platform and supportive network. Navigating the process was a breeze, and I secured a job I love, all thanks to AcrossGeo's incredible platform and unwavering support!",
    },
    {
      id: 6,
      avatar: AvtarThree,
      name: "Arun Kumar  ",
      // company: "KPMG",
      designation: "DevOps Engineer",
      // headerContent: "The support is awesome",
      // date: "12 apr 2022 | 05:25",
      content:
        "AcrossGeo made my job search smooth, With their user-friendly platform and robust support, I secured the ideal job during my transition. Highly recommend AcrossGeo for a seamless job-seeking experience .",
    },
  ];

  const [activeDot, setActiveDot] = useState(0);

  const calculateSlidesToShow = (windowWidth) => {
    if (windowWidth >= 2560) {
      return 5;
    } else if (windowWidth >= 1024) {
      return 3;
    } else if (windowWidth >= 600) {
      return 2;
    } else {
      return 1;
    }
  };

  // Add an event listener to handle window resize
  useEffect(() => {
    const handleResize = () => {
      const slidesToShow = calculateSlidesToShow(window.innerWidth);
      setActiveDot(0); // Reset the active dot when the window resizes
      setSlidesToShow(slidesToShow);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // In your component state, add a state for slidesToShow
  const [slidesToShow, setSlidesToShow] = useState(
    calculateSlidesToShow(window.innerWidth)
  );

  // Update the slidesToShow property in your Slider component
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    beforeChange: (current, next) => {
      setActiveDot(next);
    },
    appendDots: (dots) => (
      <div
        className="flex justify-center items-center appendDots"
        style={{ position: "relative", right: "2rem" }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        key={i}
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: activeDot === i ? "#000000" : "#aaa",
          margin: "0 5px",
          cursor: "pointer",
          transition: "all 0.3s",
          transform: activeDot === i ? "scale(1.5)" : "scale(1)",
        }}
      ></div>
    ),
  };

  return (
    <div className="CustomerReviewsComponent">
      <h1 className="headerContent">What our candidates say</h1>
      <p className="subHeader">Don't take our word for it, take theirs!</p>

      <div className="reviewContainer">
        <Slider {...sliderSettings} className="cardSection" ref={sliderRef}>
          {cards.map((card) => (
            <div
              key={card.id}
              className="cardBody mx-3 lg:w-1/3 md:w-1/2 sm:w-full"
            >
              <div className="cardHeader flex ">
                <img src={card.avatar} />
                <div className="card-title my-2 mx-2">
                  <div className="subTitle">
                    <label>{card.name} </label>{" "}
                    {/* Add {card.date} if needed */}
                    <h5>{card.date}</h5>
                  </div>
                  <p className="companyInfo">
                    {/* {card.company} | */}
                    {card.designation}
                  </p>
                </div>
              </div>
              <div
                className="card-body "
                style={{
                  wordWrap: "breakWord",
                  wordBreak: "breakWord",
                  maxHeight: "312px", // Set your desired max height
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <div className="bodyHeaderContent">{card.headerContent} </div>
                <p>{card.content}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CustomerReviews;
