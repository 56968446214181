import React from "react";
import { SelectDropdown } from "../../../landing-page/common/select-dropdown";
import cx from "classnames";
import { TextField } from "@mui/material";
import Select from "react-select";

const Sslc = (props) => {
  return (
    <div className="sslc-form col-span-3 grid grid-cols-3">
      <div className="col-span-3 heading">SSLC {`(10th)`}</div>
      <div className="col-span-3 body grid grid-cols-3">
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Percentage / CGPA</span>
          </div>
          <div className="popins-medium input-ele grid grid-cols-4">
            <Select
              className="col-span-2"
              options={props.dropDownData.sslcGradeType}
              onChange={(data) => {
                props.handleDropdownChange([data], "sslcGradeType");
              }}
              placeholder="Select"
              value={props.selectedDropdownData.sslcGradeType ? props.selectedDropdownData.sslcGradeType : ""}
            />
            <TextField
              className="col-span-2"
              error={
                props.formik.errors.sslcGrade && props.formik.touched.sslcGrade
              }
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onClick={() => {}}
              type="text"
              name="sslcGrade"
              id="sslcGrade"
              inputProps={{
                className: cx(
                  `!text-xs open-sans-regular boxedInput popins-medium`
                ),
                style: {
                  padding: "11px 14px",
                  height: "0.43em !important",
                  fontSize: "12px",
                  fontFamily: "Poppins-Medium",
                  color: "#525252",
                },
              }}
              autoComplete="off"
              placeholder={`Enter your ${props?.selectedDropdownData?.sslcGradeType ? props?.selectedDropdownData?.sslcGradeType[0]?.value : "Percentage"}`}
              onBlur={props.formik.handleBlur}
              onChange={props.formik.handleChange}
              value={props.formik.values.sslcGrade}
              onKeyPress={(e) => {
                // Check if the entered character is a number or a dot (for decimal input)
                const validInput = /^[0-9.]$/.test(e.key);

                if (!validInput) {
                  e.preventDefault(); // Prevent input of non-numeric characters
                }
              }}
            />
          </div>
          {props.formik.errors?.sslcGradeType &&
          props.formik.errors.sslcGradeType &&
          props.formik.touched.sslcGradeType ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.sslcGradeType}
            </div>
          ) : props.formik.errors.sslcGrade &&
            props.formik.touched.sslcGrade ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.sslcGrade}
            </div>
          ) : null}
        </div>
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Year of Joining</span>
          </div>
          <div className="popins-medium input-ele">
            <TextField
              error={
                props.formik.errors.sslcyearOfJoining &&
                props.formik.touched.sslcyearOfJoining
              }
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onClick={() => {}}
              type="text"
              name="sslcyearOfJoining"
              id="sslcyearOfJoining"
              inputProps={{
                className: cx(
                  `!text-xs open-sans-regular boxedInput popins-medium`
                ),
                style: {
                  padding: "9.5px 14px",
                  height: "0.43em !important",
                  fontSize: "12px",
                  fontFamily: "Poppins-Medium",
                  color: "#525252",
                },
              }}
              autoComplete="off"
              placeholder="Enter your joining year"
              onBlur={props.formik.handleBlur}
              onChange={props.formik.handleChange}
              value={props.formik.values.sslcyearOfJoining}
            />
          </div>
          {props.formik.errors.sslcyearOfJoining &&
          props.formik.touched.sslcyearOfJoining ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.sslcyearOfJoining}
            </div>
          ) : null}
        </div>
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Year of Passing</span>
          </div>
          <div className="popins-medium input-ele">
            <TextField
              error={
                props.formik.errors.sslcyearOfPassing &&
                props.formik.touched.sslcyearOfPassing
              }
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onClick={() => {}}
              type="text"
              name="sslcyearOfPassing"
              id="sslcyearOfPassing"
              inputProps={{
                className: cx(
                  `!text-xs open-sans-regular boxedInput popins-medium`
                ),
                style: {
                  padding: "9.5px 14px",
                  height: "0.43em !important",
                  fontSize: "12px",
                  fontFamily: "Poppins-Medium",
                  color: "#525252",
                },
              }}
              autoComplete="off"
              placeholder="Enter your passing year"
              onBlur={props.formik.handleBlur}
              onChange={props.formik.handleChange}
              value={props.formik.values.sslcyearOfPassing}
            />
          </div>
          {props.formik.errors.sslcyearOfPassing &&
          props.formik.touched.sslcyearOfPassing ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors.sslcyearOfPassing}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Sslc;
