import { useState, useEffect } from "react";
import { Outlet, Routes, Route, useRoutes } from "react-router-dom";
import BreadCrumbs from "../common/BreadCrumbs";
import AdminPageHeader from "./AdminPageHeader";
import AdminPageLeftMenu from "./AdminPageLeftMenu";
import AdminCandidate from "./admin-candidate/AdminCandidate";
import AdminInterviewer from "./admin-interviewer/AdminInterviewer";
import AdminClient from "../admin/admin-client/AdminClient";
import AdminClientDetails from "../admin/admin-client/AdminClientDetails";
import AdminJobPost from "./admin-jobpost/AdminJobPost";
import AdminSharedProfileJobPost from "./admin-jobpost/AdminSharedProfileJobPost";
import DemoAdminJobPost from "./admin-jobpost/DemoAdminJobPost";

function AdminHome() {
  const [expandSideMenu, setExpandSideMenu] = useState(true);

  return (
    <div>
      {/* <ProgressContainer> */}

      <div className="">
        <AdminPageHeader />
      </div>
      <div className="flex">
        <div>
          <AdminPageLeftMenu />
        </div>
        <div className="w-full home-component-display overflow-auto">
          <BreadCrumbs />
          <Routes>
          <Route
              path="/"
              element={<AdminJobPost tabletitleRow={true} />}
            />
             <Route
              path="/jobposts"
              element={<AdminJobPost tabletitleRow={true} />}
            />
             <Route
              path="/jobposts/share-profile"
              element={<AdminSharedProfileJobPost  />}
            />
            <Route
              path="/candidates"
              element={<AdminCandidate tabletitleRow={true} />}
            />
             <Route
              path="/users"
              element={<AdminInterviewer  />}
            />
             <Route
              path="/company"
              element={<AdminClient  />}
            />
             <Route
              path="/company/requested-details"
              element={<AdminClientDetails  />}
            />
             <Route
              path="/demoUIJobPost"
              element={<DemoAdminJobPost tabletitleRow={true} />}
            />
            
          </Routes>
        </div>
      </div>
      {/* </ProgressContainer> */}
    </div>
  );
}

export default AdminHome;
