import React from "react";
import JobCard from "./JobCard";
import ClientInterviewCandidate from "./client-job-table/ClientInterviewCandidate";

const CardWithTableDetails = (props) => {
  const handleModalClose = async () => {
    try {
      // Make the API call
      await props.getJobList(0);

      // Once the API call is successful, close the modal
      props.closeModal(true);
    } catch (error) {
      console.error("API call failed: ", error);

      // props.closeModal(true);
    }
  };


  return (
    <div className="job-list-page">
      <div className="hire-now-btn ml-auto">
        <button
          className="datatrimmer cancel-btn"
          onClick={handleModalClose}
          title={""}
        >
          Back
        </button>
        {/* <input
            className="job-list-search-input col-span-2 "
            onChange={(e) => {
              handelCardSearch(e);
            }}
            type="text"
            ref={cardSearchInput}
            placeholder="Search"
          /> */}
      </div>
      <div className="job-card-container">
        <JobCard
          job={props.selectedJob}
          handleEdit={props.handleEdit}
          handelMoreBtn={props.handelMoreBtn}
        />
        <ClientInterviewCandidate job={props.selectedJob} />
      </div>
    </div>
  );
};

export default CardWithTableDetails;
