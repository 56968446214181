import React, { useCallback, useState, useEffect } from "react";
import "./CandidateProgress.scss";
import CandidatesDetailsModal from "../common/candidate-details/CandidatesDetailsModal";
import UpdateProfile from "./modals/UpdateProfile";
import { getCandidateDetailsReq } from "../../api/api-services";

const CandidateProgress = () => {
  let [candidateDetailsData, setCandidateDetailsData] = useState({});
  let [updateProfileModal, setUpdateProfileModal] = useState(false);
  let [completeProfileData, setCompleteProfileData] = useState(false)

  const handleEdit = useCallback(() => {
    setUpdateProfileModal(true);
  }, [candidateDetailsData]);
  const getCandidateDetails = async () => {
    let tempUid = JSON.parse(localStorage.getItem("across-geo-user")).uid;
    try {
      const response = await getCandidateDetailsReq(tempUid);
      if (response.data) {
        setCandidateDetailsData({ ...response.data.response });
        if (
          !response.data?.response?.personalDetails ||
          !response.data?.response?.educationDetails ||
          !response.data?.response?.workHistories
        ) {
          setUpdateProfileModal(true);
          setCompleteProfileData(true)
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const closeScreen = () => {
    if (updateProfileModal) {
      setUpdateProfileModal(false);
    }
  };
  useEffect(() => {
    getCandidateDetails();
  }, []);

  return (
    <div className="candidate-progress-details-container m-2">
      {!updateProfileModal && (
        <>
          {/* <div className="flex  count-container">
            <div className="interview-taken">
              <div className="inline">
                <span className="count">0</span>
              </div>
              <div className="inline">
                <span className="desc">No. of Interview Taken</span>
              </div>
            </div>
            <div className="worked-for">
              <div className="inline">
                <span className="count">0</span>
              </div>
              <div className="inline">
                <span className="desc">Worked For (Clients)</span>
              </div>
            </div>
          </div> */}
          
          <CandidatesDetailsModal
            candidateDetailsData={candidateDetailsData}
            editBtn={true}
            resumeBtn={true}
            handleEdit={handleEdit}
            getCandidateDetails={getCandidateDetails}
          />
        </>
      )}
      {updateProfileModal && (
        <UpdateProfile
          getCandidateDetails={getCandidateDetails}
          candidateDetailsData={candidateDetailsData}
          closeScreen={closeScreen}
          completeProfileData={completeProfileData}
        />
      )}
    </div>
  );
};

export default CandidateProgress;
