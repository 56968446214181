import React, { useState, useEffect } from "react";
import "./AdminClient.scss";
import { ReactComponent as FilterIcon } from "../../../assets/filterIcon.svg";
import { useTable, useGlobalFilter } from "react-table";
import AdminAddClient from "../admin-modals/add-clients/AdminAddClient";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  getAllClientCompany,
  getClientCompanyDetails,
  adminCompanySearch,
} from "../../../api/api-services";
import { ReactComponent as NoDataFound } from "../../../assets/noDataFound.svg";
const AdminClient = () => {
  const navigate = useNavigate();
  const [openAddClient, setOpenAddClient] = useState(false);
  let companyDetails = {};
  const [isLoading, setIsLoading] = useState(true);
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let size = 10;
  const [isSearching, setIsSearching] = useState(false);
  const [clientCompanyData, setClientCompanyData] = useState([]);
  const [totalClientCount, setTotalClientCount] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const fetchClientDetails = async (page) => {
    try {
      const response = await getAllClientCompany(page, size);

      setTotalClientCount(response?.data?.response?.totalElements);
      setIsLoading(false);
      if (page === 0) {
        setClientCompanyData([...response.data.response.content]);
      } else {
        setClientCompanyData([
          ...clientCompanyData,
          ...response.data.response.content,
        ]);
      }
      if (response?.data?.response?.last === false) {
        setpageNo(page + 1);
      }
      setLastpage(response.data.response.last);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!isSearching) {
      fetchClientDetails(pageno);
    }
  }, []);

  const companyListSearch = async (e, page) => {
    try {
      setIsSearching(true);
      setSearchedText(e);
      const response = await adminCompanySearch(e, page, size);
      console.log(response);
      setTotalClientCount(response?.data?.response?.totalElements);
      setIsLoading(false);
      if (page === 0) {
        setClientCompanyData([...response?.data?.response?.content]);
      } else {
        setClientCompanyData([
          ...clientCompanyData,
          ...response?.data?.response?.content,
        ]);
      }
      if (response?.data?.response?.last === false) {
        setpageNo(page + 1);
      }
      setLastpage(response.data.response.last);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const resetSearch = () => {
    setIsSearching(false);
    setpageNo(0);
    setLastpage(false);
    setClientCompanyData([]); // Clear existing search results
  };

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;

    if (scrollTop + clientHeight >= scrollHeight - 20) {
      if (lastPage === false && !isLoading && !isSearching) {
        setIsLoading(true); // Set isLoading to prevent concurrent API calls
        fetchClientDetails(pageno); // Call fetchClientDetails with the current pageno
      } else if (isSearching && lastPage === false && !isLoading) {
        companyListSearch(searchedText, pageno);
      }
    }
  };

  const fetchCompanyDetails = async (companyName, row, action) => {
    try {
      const response = await getClientCompanyDetails(companyName);
      console.log(response?.data?.response);
      if (response?.data?.statusCode === 200) {
        companyDetails = response?.data?.response;
        if (action === "requested") {
          navigateToJobPost(row);
        } else if (action === "approved") {
          navigateToAddedResume(row);
        } else if (action === "offered") {
          navigateToOffered(row);
        }
      }
    } catch (error) {
      console.error("Error occurred while fetching company details:", error);
    }
  };
  const navigateToJobPost = (row) => {
    navigate("/admin/company/requested-details", {
      state: {
        activeTab: 0,
        requestedProfile: row.original,
        selectedRow: 1,
        companyDetails: companyDetails,
      },
    });
  };

  const navigateToAddedResume = (row) => {
    navigate("/admin/company/requested-details", {
      state: {
        activeTab: 1,
        requestedProfile: row.original,
        companyDetails: companyDetails,
      },
    });
  };
  const navigateToOffered = (row) => {
    navigate("/admin/company/requested-details", {
      state: {
        activeTab: 2,
        requestedProfile: row.original,
        companyDetails: companyDetails,
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "companyName",
        Cell: ({ value, row }) => (
          <div
            onClick={(event) => {
              fetchCompanyDetails(value, row, "requested");
              console.log(row);
            }}
            style={{ width: "180px" }}
            title={value}
          >
            {value?.length >= 22 ? value.substring(0, 22) + "..." : value}
          </div>
        ),
      },
      {
        Header: "Jobpost",
        accessor: "jobPosts",
        Cell: ({ value, row }) => (
          <div
            onClick={(event) => {
              console.log(row);
              fetchCompanyDetails(row?.original?.companyName, row, "requested");
            }}
            className="text-blue-700 font-semibold -ml-11"
            style={{ width: "100px" }}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Candidates Requested",
        accessor: "requested",
        Cell: ({ value, row }) => (
          <div
            onClick={(event) => {
              console.log(row);
              fetchCompanyDetails(row?.original?.companyName, row, "requested");
              // navigate("/admin/company/requested-details", { state: { activeTab:0,requestedProfile: row.original } })
            }}
            className="text-blue-700 font-semibold"
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Candidates Shared",
        accessor: "shared",
        Cell: ({ value, row }) => (
          <div
            onClick={(event) => {
              fetchCompanyDetails(row?.original?.companyName, row, "approved");

              //  navigate("/admin/company/requested-details", { state: { activeTab:1,requestedProfile: row.original } })
            }}
            className="text-blue-700 font-semibold"
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Candidates Offered",
        accessor: "offered",
        Cell: ({ value, row }) => (
          <div
            onClick={(event) => {
              fetchCompanyDetails(row?.original?.companyName, row, "offered");
              // navigate("/admin/company/requested-details", { state: { activeTab:2,requestedProfile: row.original } })
            }}
            className="text-blue-700 font-semibold"
          >
            {value}
          </div>
        ),
      },
    ],
    []
  );

  // Create the table instance
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({ columns, data: clientCompanyData }, useGlobalFilter);
  function openAddClientModalClose(status) {
    setOpenAddClient(status);
  }

  return (
    <div className="ClientAdminSection mx-3 w-full home-component-display mt-2.5 ">
      <div className="headerComponent">
        <div style={{ fontSize: "20px" }} className="leftHeaderContent">
          Company List -{totalClientCount}
        </div>

        <div className="rightHeaderContent">
          {/* <button
            // onClick={() => {
            //   if (!openFilter) {
            //     fetchFilterOptions();
            //   }
            //   setOpenFilter(!openFilter);
            // }}
            // className={`${openFilter ? "selected-btn" : null}`}
            className="mx-4 filerBtn"
          >
            <FilterIcon />
            Filters
          </button> */}
          <div className="searchBar">
            <input
              className="company-list-search-input col-span-2 "
              onChange={(e) => {
                if (e.target.value.trim() === "") {
                  resetSearch();
                  fetchClientDetails(pageno);
                } else {
                  companyListSearch(e.target.value, 0);
                }
              }}
              //  onChange={(e) => setGlobalFilter(e.target.value)}
              type="text"
              placeholder="Search"
            />
          </div>
          <div
            className="addBtn"
            onClick={() => {
              setOpenAddClient(true);
            }}
          >
            <button>+ New Company</button>
          </div>
        </div>
      </div>

      <div className="clientTableComponent " onScroll={handleScroll}>
        <table {...getTableProps()} className="clientTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    style={{
                      fontWeight: "600",
                      fontFamily: "Poppins-SemiBold",
                      color: "black",
                    }}
                    className={` reportTableheader Poppins-Regular text-lg font-semibold ${
                      column.Header !== "Action" ? "text-left " : "text-center"
                    }
                    ${column.Header === "Profile" ? "profileHeader" : ""}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {clientCompanyData.length === 0 ? (
            <div className="grid grid-cols-12 nodefect_container">
              <div className="col-span-12 flex justify-center flex align-middle">
                {/* if any loader required give it here */}
                {/* Loading... */}
              </div>
            </div>
          ) : (
            <tbody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                <>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, index) => (
                          <td
                            className={
                              index > 0
                                ? " pl-16 cursor-pointer"
                                : " cursor-pointer"
                            }
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <div className="noDataCompany">
                  <div className="no-data-availableIcon">
                    <NoDataFound />
                  </div>
                  <div className="no-data-availableText">
                    <p>Oops !!</p> <p>There are no data to be found yet</p>
                  </div>
                </div>
              )}
            </tbody>
          )}
        </table>
      </div>
      {openAddClient && (
        <AdminAddClient
          toast={toast}
          fetchClientDetails={fetchClientDetails}
          closeModal={openAddClientModalClose}
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AdminClient;
