import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "./LoginSignUpModal.scss";
import CrossIcon from "../website-assets/crossIcon.png";
import LoginForm from "./login-form/LoginForm";
import ResetPassword from "./login-form/ResetPassword";
import RegistrationContainer from "./registration-form/RegistrationContainer";
import OtpVerificationForm from "./otp-form/OtpVerificationForm";

const LoginSignUpModal = (props) => {
  const [openModal, setOpenModal] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [showSetPasswordModal, setShowSetPasswordModal] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const isContactUsPage = window.location.pathname.includes("/contactUs");
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      // top: isContactUsPage ? "55%" : selectedTab === 1 ? "57%" : "55%",
      top: selectedTab === 1 ? "57%" : "55%",
      left: "50%",
      transform: "translate(-50%, -50%)",

      borderRadius: "10px",
      opacity: "1",
      outline: "none",
      width: "400px",
      // height: "468px",
      height: "auto",
      background: " #FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 20px #00000029",
    },
  };
  let handelTabs = (tabName) => {
    setSelectedTab(tabName);
    handleRegistrationData({});
    setShowSetPasswordModal(false);
  };
  let handelOpenSetPasswordModal = (value) => {
    setShowSetPasswordModal(value);
  };
  let handelOpenOpenOtpModal = (value) => {
    setOpenOtpModal(value);
  };
  const [registrationData, setRegistrationData] = useState({});
  const handleRegistrationData = (data) => {
    setRegistrationData(data);
  };
  const [userInfo, setUserInfo] = useState({
    mode: "",
    mobileNumber: "",
  });
  const [shouldSelectLoginTabInOtpPage, setShouldSelectLoginTabInOtpPage] =
    useState(false);
  useEffect(() => {
    if (shouldSelectLoginTabInOtpPage) {
      setSelectedTab(0);
      setOpenOtpModal(false);
      setShouldSelectLoginTabInOtpPage(false);
    }
  }, [shouldSelectLoginTabInOtpPage]);
  // useEffect(() => {
  //   if (props.clickMode === "jobPost") {
  //     setSelectedTab(1);
  //   }
  // }, [props.clickMode]);

  useEffect(() => {
    if (openModal) {
      // Disable scrolling when the modal is open
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [openModal]);

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px">
      <div className="loginModalContainer">
        {!openOtpModal ? (
          <div className="headerSection ">
            {!showSetPasswordModal ? (
              <Tabs selectedIndex={selectedTab} onSelect={handelTabs}>
                <div className="pannelTabs">
                 
                  <TabList className="flex p-3.5 outline-none">
                    <Tab
                      className={
                        !showSetPasswordModal && selectedTab === 0
                          ? "selectedTab"
                          : ""
                      }
                      onClick={() => {
                        if (!showSetPasswordModal) {
                          setSelectedTab(0);
                        } else {
                          // Reset to Login tab and load LoginForm when switching from ResetPassword
                          setShowSetPasswordModal(false);
                          setSelectedTab(0);
                        }
                      }}
                    >
                      Login
                    </Tab>

                    <Tab
                      className={
                        !showSetPasswordModal && selectedTab === 1
                          ? "selectedTab mx-4"
                          : "mx-4"
                      }
                      onClick={() => {
                        if (!showSetPasswordModal) {
                          setSelectedTab(1);
                        }
                      }}
                    >
                      Sign Up
                    </Tab>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        props.setIsOpenLoginModal(false);
                        props.setShowMediaIcons(false);
                      }}
                    >
                      <img
                        src={CrossIcon}
                        className="  top-7  absolute"
                        style={{ height: "20px", right: "31px" }}
                      />
                    </div>
                  </TabList>
                </div>

                <TabPanel>
                  <div className="logInHome">
                    <LoginForm
                      handelOpenSetPasswordModal={handelOpenSetPasswordModal}
                      setSelectedTab={setSelectedTab}
                      handleRegistrationData={handleRegistrationData}
                    />
                  </div>
                </TabPanel>

                <TabPanel>
                  <RegistrationContainer
                    handelTabs={handelTabs}
                    setSelectedTabLogin={setSelectedTab}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    handleRegistrationData={handleRegistrationData}
                    registrationData={registrationData}
                    handelOpenOpenOtpModal={handelOpenOpenOtpModal}
                    isNewUser={props?.isNewUser}
                    newUserId={props?.newUserId}
                  />
                </TabPanel>
              </Tabs>
            ) : (
              <ResetPassword
                setShowSetPasswordModal={setShowSetPasswordModal}
                setSelectedTab={setSelectedTab}
              />
            )}
          </div>
        ) : (
          <div>
            <OtpVerificationForm
              setIsOpenLoginModal={props.setIsOpenLoginModal}
              setShouldSelectLoginTabInOtpPage={
                setShouldSelectLoginTabInOtpPage
              }
              handelOpenOpenOtpModal={handelOpenOpenOtpModal}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              registrationData={registrationData}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default LoginSignUpModal;
