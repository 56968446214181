import React, { useEffect, useState } from "react";
import "./LatestJobCards.scss";
import { getFilteredJobs } from "../../../api/api-services";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../website-assets/greenLocation.svg";
import LoginSignUpModal from "../website-signup-home/LoginSignUpModal";

const LatestJobCards = () => {
  const [jobPostsData, setJobPostsData] = useState([]);
  const [appliedJobId, setAppliedJobId] = useState("");
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const fetchOldJobPosts = async (page) => {
    try {
      const response = await getFilteredJobs(page, 10);

      setJobPostsData((prevList) => [
        ...prevList,
        ...response.data.response.content,
      ]);
    } catch (error) {
      console.error(error);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    fetchOldJobPosts(0);
  }, []);
  const formatDate = (dateString) => {
    const currentDate = new Date();
    const [day, month, year] = dateString.split("/");
    const postDate = new Date(`${year}-${month}-${day}`); // Adjusted date format
    const timeDiff = currentDate - postDate;

    const months = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));
    const years = Math.floor(months / 12);

    // if (months < 1) {
    //   return "Less than a month ago";
    // } else if (months < 12) {
    //   return `${months} month${months > 1 ? "s" : ""} ago`;
    // } else {
    //   return `${years} year${years > 1 ? "s" : ""} ago`;
    // }
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    // if (days < 1) {
    //   return "Less than a day left to apply";
    // } else {
    //   return `${days} day${days > 1 ? "s" : ""} left to apply`;
    // }
    if (days < 1) {
      return <p className="dayText">Less than a day left to apply</p>;
    } else {
      return (
        <p>
          <span className="dayValue">{days}</span>{" "}
          <span className="dayText">
            day{days > 1 ? "s" : ""} left to apply
          </span>
        </p>
      );
    }
  };
  const visibleJobPosts = jobPostsData.slice(0, 4); // Only take the first 8 items

  function toCamelCase(inputString) {
    return inputString.replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  }

  return (
    <div className="  latest-job-cards  row">
      <Slide top cascade>
        {/* <h1 className="headerContent">Featured jobs</h1> */}
        <div className="subHeader">
          <div className="headerContent">Featured jobs</div>
          {/* 2020 jobs live – 293 added today.{" "} */}
          <div
            onClick={() => {
              navigate("/job-search");
            }}
            className="rightSubHeader cursor-pointer"
          >
            View all jobs
          </div>
        </div>
      </Slide>
      <div className="grid cardContainer grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2  gap-4">
        {visibleJobPosts.map((jobCard) => (
          <div
            className="card cursor-pointer"
            key={jobCard.id}
            onClick={(e) => {
              if (!e.target.classList.contains("apply-now-button")) {
                navigate(`/job-details/${jobCard.id}`, {
                  state: {
                    jobDetails: jobCard,
                    getSemilarJobPost: jobPostsData,
                  },
                });
              }
            }}
          >
            <Fade bottom cascade>
              <div className="card-header flex">
                <div style={{ width: "65px", paddingRight: "5px" }}>
                  <span className="companyIcon">
                    {jobCard.companyName.slice(0, 2)}
                  </span>
                </div>
                <div className="headerInfo text-sm sm:text-base md:text-lg lg:text-xl">
                  {" "}
                  <label>
                    {jobCard.jobHeadlines.length > 40
                      ? `${jobCard.jobHeadlines.slice(0, 40)}...`
                      : jobCard.jobHeadlines}
                  </label>
                  <br />
                  <div className="card-sub-header mt-0.5">
                    <p className="text-sm sm:text-base md:text-lg lg:text-xl">
                      by{" "}
                      <label className="mx-1 text-sm sm:text-base md:text-lg lg:text-xl">
                        {jobCard.companyName}{" "}
                      </label>{" "}
                      in{" "}
                      <label className="text-sm sm:text-base md:text-lg lg:text-xl">
                        {" "}
                        {jobCard.functionalArea.length > 55
                          ? `${jobCard.functionalArea.slice(0, 50)}...`
                          : jobCard.functionalArea}
                      </label>
                    </p>
                  </div>{" "}
                </div>
              </div>

              <div className="card-content flex relative top-1.5">
                <div className="card-content-One ">
                  {" "}
                  <p className="">
                    {jobCard?.employmentType &&
                      toCamelCase(jobCard?.employmentType.replace(/_/g, " ").toLowerCase())
                      }
                  </p>
                </div>
                <div className="card-content-two mx-1">
                  <p className=" text-blue-700 flex ">
                    <LocationIcon
                      height="12px"
                      width="12px"
                      className=" hover:text-white"
                    />{" "}
                    {jobCard?.location[0]}
                  </p>
                </div>
                <div className="card-content-three">
                  <p className="mx-1">
                    {jobCard?.salaryRange &&
                      `${jobCard?.salaryRange.min} - ${jobCard?.salaryRange.max}/Year`}
                  </p>
                </div>
              </div>
              <div className="card-footer flex justify-between items-center">
                <p className=" ">
                  {jobCard?.postedAgo === 0 ? (
                    <span className="dayText"> Posted today</span>
                  ) : (
                    <>
                      <span className="dayText">Posted </span>
                      <span className="dayValue">
                        {" "}
                        {jobCard?.postedAgo}
                      </span>{" "}
                      <span className="dayText"> days ago</span>
                    </>
                  )}
                </p>
                <button
                  className=" ml-auto apply-now-button"
                  onClick={() => {
                      setIsOpenLoginModal(true);
                      setAppliedJobId(jobCard.id)
                    }}
                >
                  Apply
                </button>
              </div>
            </Fade>
          </div>
        ))}
      </div>
      {isOpenLoginModal && (
        <LoginSignUpModal
          clickMode={"logIn"}
          setIsOpenLoginModal={setIsOpenLoginModal}
          setShowMediaIcons={setIsOpenLoginModal}
          isNewUser={true}
          newUserId={appliedJobId}
        />
      )}
    </div>
  );
};

export default LatestJobCards;
