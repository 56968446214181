import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import cx from "classnames";
import { TextField } from "@mui/material";
import { SelectDropdown } from "../../landing-page/common/select-dropdown";
import CompanyForm from "./work-form/CompanyForm";
import Select from "react-select";
import {
  getAllCompany,
  getTotalCitiesReq,
  getroleListReq,
} from "../../../api/api-services";
import {
  dropdownLabelFinder,
  stringToDropdownValue,
} from "../../../util/common-utils";
import ReactSelectDropdown from "../../landing-page/common/react-select-dropdown";

const WorkHistoryForm = (props) => {
  let initialSchema = {
    empType: yup.string().required("Please select any of the above criteria"),
  };
  let initValues = {
    empType: "",
  };
  const [validationSchema, setValidationSchema] = useState(
    yup.object(initialSchema)
  );
  const [selectedEmpType, setSelectedEmpType] = useState("");
  const [currentSchema, setcurrentSchema] = useState({ ...initialSchema });
  const [initialValues, setInitialValues] = useState({ ...initValues });
  const [currentCompanyError, setCurrentCompanyError] = useState("");
  let tempselectedData = {
    preferedloc: [],
    prefeShift: [],
    prefWorkMode: [],
    noticePeriod: [],
  };
  const [selectedDropdownData, setSelectedDropdownData] = useState({
    ...tempselectedData,
  });
  const [companyIndexs, setCompanyindexs] = useState([0]);
  const [dropDownData, setDropDownData] = useState({
    company: [],
    location: [],
    city: [],
    jobTitle: [],
    prefWorkMode: [
      { label: "In-Office", value: "INOFFICE" },
      { label: "Hybrid", value: "HYBRID" },
      { label: "Remote", value: "REMOTE" },
    ],
    prefeShift: [
      { label: "Day", value: "DAY" },
      { label: "Night", value: "NIGHT" },
    ],
    noticePeriod: [
      { label: "Immediate Joiner", value: "Immediate Joiner" },
      { label: "15 Days or less", value: "15 Days or less" },
      { label: "1 Month", value: "1 Month" },
      { label: "2 Months", value: "2 Months" },
      { label: "3 Months", value: "3 Months" },
      { label: "More than 3 Months", value: "More than 3 Months" },
    ],
  });
  const onSubmit = async (values) => {
    formAndSendData();
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });
  const handelworkTabs = (e, type) => {
    if (type !== selectedEmpType) {
      setSelectedDropdownData({ ...tempselectedData });
    }
    formik.setFieldValue("empType", type, true);
    let tempvalues = { ...initValues };
    tempvalues.empType = type;
    createValidation(type, tempvalues);
    setSelectedEmpType(type);
  };
  const buildSchemaWithvalue = (datatype, value) => {
    if (datatype === "experienced") {
      return {
        currentEmp: yup
          .boolean()
          .required("Please select any of the above criteria"),
        totWorkExp: yup
          .string()
          // .trim()
          .required("Total work experience is required")
          .matches(
            /^(0\d{1}|[1-9]\d{0,1})(\.\d{1,1})?$/,
            "Work Experience should be numerical & no space allowed"
          )
          .matches(/^(\S+$)/g, "This field cannot contain any blankspaces"),
        noticePeriod: yup.string().trim().required("Notice period is required"),
        preferedloc: yup.array().min(1, "Preferred work location is required"),
        preferedSal: yup
          .number()
          .min(0, "Preferred Annual salary cannot be negative")
          .typeError("Preferred Annual salary should be numerical")
          .required("Preferred Annual salary is required")
          .transform((value, originalValue) =>
            /\s/.test(originalValue) ? NaN : value
          ),
        prefWorkMode: yup
          .string()
          .trim()
          .required("Preferred workMode is required"),
        prefeShift: yup.string().trim().required("Preferred shift is required"),
        currentCtc: yup
          .number()
          .min(0, "Current Annual salary cannot be negative")
          .typeError("Current Annual salary should be numerical")
          .required("Current Annual salary is required")
          .transform((value, originalValue) =>
            /\s/.test(originalValue) ? NaN : value
          ),
      };
    } else if (datatype === "addCompany") {
      console.log("props work history data", props.candidateData.workHistories);
      let obj = {
        [`${"company" + value}`]: yup
          .string()
          .trim()
          .required("Company is required"),
        [`${"jobTitle" + value}`]: yup
          .string()
          .trim()
          .required("Job Title is required"),
        [`${"city" + value}`]: yup.string().trim().required("City is required"),
        [`${"currentCompany" + value}`]: yup
          .boolean()
          .required("Current company or not is required"),
        [`${"workingFrom" + value}`]: yup
          .string()
          .trim()
          .required("Working From is required"),
        [`${"workingtill" + value}`]: yup
          .string()
          .trim()
          .required("Working to is required")
          .test("valid-time", "Please enter valid date", function (inpvalue) {
            if (inpvalue === "Present") {
              return true;
            } else {
              let fromDate =
                this.parent?.[`${"workingFrom" + value}`]?.split("-");
              let toDate = inpvalue.split("-");
              if (fromDate?.length === 3 && toDate?.length === 3) {
                if (parseInt(fromDate[2]) < parseInt(toDate[2])) {
                  return true;
                } else if (
                  parseInt(fromDate[2]) === parseInt(toDate[2]) &&
                  parseInt(fromDate[1]) < parseInt(toDate[1])
                ) {
                  return true;
                } else if (
                  parseInt(fromDate[2]) === parseInt(toDate[2]) &&
                  parseInt(fromDate[1]) === parseInt(toDate[1]) &&
                  parseInt(fromDate[0]) < parseInt(toDate[0])
                ) {
                  return true;
                } else {
                  return false;
                }
              }
            }
          }),
      };
      if(props.candidateData.workHistories !== null && props.candidateData.workHistories[value]?.company === "others"){
        obj[`${"companyName" + value}`] = yup
        .string()
        .trim()
        .required("Company Name is required");
      }
      return obj;
    } else if (datatype === "fresher") {
      return {
        preferedloc: yup.array().min(1, "Preferred work location is required"),
      };
    }
  };
  const buildInitialValues = (datatype, values, cmpdata) => {
    if (datatype === "experienced") {
      return {
        currentEmp: values?.currentlyEmployed ? values.currentlyEmployed: false,
        totWorkExp: values?.totExp ? values.totExp : "",
        noticePeriod: values?.noticePeriod ? values.noticePeriod : "",
        preferedloc: values?.location ? values.location : [],
        preferedSal: values?.salaryRange ? values.salaryRange : "",
        prefWorkMode: values?.workMode ? values.workMode : "",
        prefeShift: values?.shift ? values.shift : "",
        currentCtc: values?.currentCtc ? values.currentCtc : "",
      };
    } else if (datatype === "addCompany") {
      let obj = {
        [`${"company" + values}`]: cmpdata?.company ? cmpdata.company : "",
        [`${"jobTitle" + values}`]: cmpdata?.jobTitle ? cmpdata.jobTitle : "",
        [`${"city" + values}`]: cmpdata?.city ? cmpdata.city : "",
        [`${"workingFrom" + values}`]: cmpdata?.startDate
          ? cmpdata.startDate
          : "",
        [`${"workingtill" + values}`]: cmpdata?.endDate ? cmpdata.endDate : "",
        [`${"currentCompany" + values}`]: cmpdata?.thisCurrentCompany
          ? cmpdata.thisCurrentCompany
          : false,
      };
      if(cmpdata?.company === "others") {
        obj[`${"companyName" + values}`] = cmpdata.companyName ? cmpdata.companyName : "";
      }
      return obj;
    } else if (datatype === "fresher") {
      return {
        preferedloc: values?.location ? values.location : [],
      };
    }
  };
  const createValidation = (data, tempvalues, preDegreetype) => {
    let updatedSchema;
    let updateInitialValues;
    if (data === "experienced") {
      updatedSchema = {
        ...initialSchema,
        ...buildSchemaWithvalue("experienced"),
        ...buildSchemaWithvalue("addCompany", companyIndexs[0]),
      };
      updateInitialValues = {
        ...tempvalues,
        ...buildInitialValues("experienced"),
        ...buildInitialValues("addCompany", companyIndexs[0]),
      };
      let tempSelectedValues = { ...selectedDropdownData };
      setSelectedDropdownData({
        ...tempSelectedValues,
        [`${"city" + companyIndexs[0]}`]: [],
        [`${"company" + companyIndexs[0]}`]: [],
        [`${"jobTitle" + companyIndexs[0]}`]: [],
        [`${"prefWorkMode" + companyIndexs[0]}`]: [],
      });
      setcurrentSchema({ ...updatedSchema });
      setInitialValues({ ...updateInitialValues });
      setValidationSchema(yup.object(updatedSchema));
    } else if (data === "fresher") {
      updatedSchema = {
        ...initialSchema,
        ...buildSchemaWithvalue("fresher"),
      };
      updateInitialValues = {
        ...tempvalues,
        ...buildInitialValues("fresher"),
      };
      setcurrentSchema({ ...updatedSchema });
      setInitialValues({ ...updateInitialValues });
      setValidationSchema(yup.object(updatedSchema));
    }
  };
  const handleCompanyDropdownChange = (data, index) => {
    let updatedSchema = { ...currentSchema };
    let updateInitialValues = { ...formik.values };
    let  obj =  {};
    let initObj = {};
    if ( data[0].value === "others") {
      obj[`${"companyName" + index}`] = yup
      .string()
      .trim()
      .required("Company Name is required");
      updateInitialValues[`${"company" + index}`] = data[0].value;
      updateInitialValues[`${"companyName" + index}`] = "";
    }
    updatedSchema = {
      ...updatedSchema,
      ...obj,
    };
    updateInitialValues = {
      ...updateInitialValues,
      ...initObj,
    };
    setcurrentSchema({ ...updatedSchema });
    setInitialValues({ ...updateInitialValues });
    setValidationSchema(yup.object(updatedSchema));
  }
  const handleDropdownChange = (data, type, adressType, index) => {
    let allSelectedData = { ...selectedDropdownData };
    if (type === "preferedloc") {
      let locations = [];
      data?.map((val) => {
        locations.push(val.value);
      });
      allSelectedData[type] = data;
      formik.setFieldValue(type, [...locations]);
    } else {
      allSelectedData[type] = [...data];
      formik.setFieldValue(type, data?.[0]?.value)
    }
    allSelectedData[type] = [...data];
    setSelectedDropdownData({ ...allSelectedData });

    if (data?.[0]?.value === "others") {
      handleCompanyDropdownChange(data,index)
   } else {
    if(formik.values?.["companyName"+index]){
      formik.values.companyName = "";
    }
   }
  };
  const addCompany = (data, index) => {
    if (
      !(
        formik.errors?.[`${"company" + data}`] ||
        formik.errors?.[`${"jobTitle" + data}`] ||
        formik.errors?.[`${"city" + data}`] ||
        formik.errors?.[`${"workingFrom" + data}`] ||
        formik.errors?.[`${"workingtill" + data}`] ||
        formik.errors?.[`${"currentCompany" + data}`]
      )
    ) {
      let tempcompanyIndex = [...companyIndexs];
      let updatedSchema;
      let updateInitialValues;
      tempcompanyIndex.push(data + 1);
      setCompanyindexs([...tempcompanyIndex]);
      updatedSchema = {
        ...currentSchema,
        ...buildSchemaWithvalue("addCompany", data + 1),
      };
      updateInitialValues = {
        ...formik.values,
        ...buildInitialValues("addCompany", data + 1),
      };
      let tempSelectedValues = { ...selectedDropdownData };
      setSelectedDropdownData({
        ...tempSelectedValues,
        [`${"city" + (data + 1)}`]: [],
        [`${"company" + (data + 1)}`]: [],
        [`${"jobTitle" + (data + 1)}`]: [],
      });
      setcurrentSchema({ ...updatedSchema });
      setInitialValues({ ...updateInitialValues });
      setValidationSchema(yup.object(updatedSchema));
    }
  };
  const dltCompany = (data, index) => {
    let tempcompanyIndex = [...companyIndexs];
    let updatedSchema = { ...currentSchema };
    let updateInitialValues = { ...formik.values };
    tempcompanyIndex.splice(index, 1);
    setCompanyindexs([...tempcompanyIndex]);
    delete updatedSchema?.[`${"city" + data}`];
    delete updatedSchema?.[`${"company" + data}`];
    delete updatedSchema?.[`${"jobTitle" + data}`];
    delete updatedSchema?.[`${"workingFrom" + data}`];
    delete updatedSchema?.[`${"workingtill" + data}`];
    delete updatedSchema?.[`${"currentCompany" + data}`];
    delete updateInitialValues?.[`${"city" + data}`];
    delete updateInitialValues?.[`${"company" + data}`];
    delete updateInitialValues?.[`${"jobTitle" + data}`];
    delete updateInitialValues?.[`${"workingFrom" + data}`];
    delete updateInitialValues?.[`${"workingtill" + data}`];
    delete updateInitialValues?.[`${"currentCompany" + data}`];
    let tempSelectedValues = { ...selectedDropdownData };
    delete tempSelectedValues?.[`${"city" + data}`];
    delete tempSelectedValues?.[`${"company" + data}`];
    delete tempSelectedValues?.[`${"jobTitle" + data}`];
    setSelectedDropdownData({
      ...tempSelectedValues,
    });
    setcurrentSchema({ ...updatedSchema });
    setInitialValues({ ...updateInitialValues });
    setValidationSchema(yup.object(updatedSchema));
  };

  let handelPrePopulateData = ({
    workHistories,
    currentCtc,
    currentlyEmployed,
    totalYearsOfExperience,
    noticePeriod,
    preferences,
  }) => {
    if (workHistories?.length > 0) {
      formik.setFieldValue("empType", "experienced", true);
      let tempvalues = { ...initValues };
      tempvalues.empType = "experienced";
      // createValidation(type, tempvalues);
      setSelectedEmpType("experienced");
      let experiencedFields = {
        currentlyEmployed: currentlyEmployed,
        totExp: totalYearsOfExperience,
        noticePeriod: noticePeriod,
        location: preferences.location,
        salaryRange: preferences.salaryRange,
        workMode: preferences.workMode,
        shift: preferences.shift,
        currentCtc: currentCtc,
      };
      let updatedSchema = {
        ...initialSchema,
        ...buildSchemaWithvalue("experienced"),
      };
      let updateInitialValues = {
        ...tempvalues,
        ...buildInitialValues("experienced", experiencedFields),
      };
      let tempcompanyIndexs = [];
      let tempSelectedValues = { ...selectedDropdownData };

      if (preferences && preferences.location) {
        const tempLocations = Array.isArray(preferences.location)
          ? preferences.location.map((location) =>
              stringToDropdownValue(location)
            )
          : [];
        tempSelectedValues.preferedloc = tempLocations;
      }

      if (preferences && preferences.workMode) {
        tempSelectedValues.prefWorkMode = [
          dropdownLabelFinder(preferences.workMode, dropDownData.prefWorkMode),
        ];
      }

      if (preferences && preferences.shift) {
        tempSelectedValues.prefeShift = [
          dropdownLabelFinder(preferences.shift, dropDownData.prefeShift),
        ];
      }

      if (noticePeriod) {
        tempSelectedValues.noticePeriod = [
          { label: noticePeriod, value: noticePeriod },
        ];
      }

      workHistories?.map((exp, index) => {
        tempcompanyIndexs.push(index);
        updatedSchema = {
          ...updatedSchema,
          ...buildSchemaWithvalue("addCompany", index),
        };
        updateInitialValues = {
          ...updateInitialValues,
          ...buildInitialValues("addCompany", index, exp),
        };
        tempSelectedValues = {
          ...tempSelectedValues,
          [`${"city" + index}`]: [{ label: exp.city, value: exp.city }],
          [`${"company" + index}`]: [
            { label: exp.company, value: exp.company },
          ],
          [`${"jobTitle" + index}`]: [
            { label: exp.jobTitle, value: exp.jobTitle },
          ],
        };
      });
      setSelectedDropdownData({
        ...tempSelectedValues,
      });
      setcurrentSchema({ ...updatedSchema });
      setInitialValues({ ...updateInitialValues });
      setValidationSchema(yup.object(updatedSchema));
      setCompanyindexs([...tempcompanyIndexs]);
    } else if (preferences?.location) {
      setSelectedEmpType("fresher");
      let tempvalues = { ...initValues };
      tempvalues.empType = "fresher";
      let tempSelectedValues = { ...selectedDropdownData };
      const tempLocations = Array.isArray(preferences.location)
        ? preferences.location.map((location) =>
            stringToDropdownValue(location)
          )
        : [];
      tempSelectedValues.preferedloc = tempLocations;
      let updatedSchema = {
        ...initialSchema,
        ...buildSchemaWithvalue("fresher"),
      };
      let updateInitialValues = {
        ...tempvalues,
        ...buildInitialValues("fresher", preferences),
      };
      setSelectedDropdownData({
        ...tempSelectedValues,
      });
      setcurrentSchema({ ...updatedSchema });
      setInitialValues({ ...updateInitialValues });
      setValidationSchema(yup.object(updatedSchema));
    }
  };

  let formAndSendData = () => {
    let allData = { ...formik.values };
    let payload = {
      workHistories: [],
    };
    if (selectedEmpType === "experienced") {
      companyIndexs.map((data) => {
        let tempObj = {
          company: allData?.[`${"company" + data}`],
          jobTitle: allData?.[`${"jobTitle" + data}`],
          city: allData?.[`${"city" + data}`],
          startDate: allData?.[`${"workingFrom" + data}`],
          endDate: allData?.[`${"workingtill" + data}`],
          thisCurrentCompany: allData?.[`${"currentCompany" + data}`],
        };
        if(tempObj.company === "others"){
          tempObj.companyName = allData?.[`${"companyName" + data}`];
        }
        payload.workHistories.push(tempObj);
      });
      payload.preferences = {
        location: allData?.preferedloc,
        salaryRange: allData?.preferedSal,
        shift: allData?.prefeShift,
        workMode: allData?.prefWorkMode,
      };
      payload.currentlyEmployed = allData.currentEmp;
      payload.currentCtc = allData.currentCtc;
      payload.noticePeriod = allData.noticePeriod;
      payload.totalYearsOfExperience = parseInt(allData.totWorkExp);
    } else if (selectedEmpType === "fresher") {
      payload.preferences = {
        location: allData?.preferedloc,
      };
    }
    if (selectedEmpType) {
      props?.fetchDataFromForms(payload);
    }
    let currentCompanyCount = 0;
    payload.workHistories.forEach((data) => {
      if (data.thisCurrentCompany) {
        currentCompanyCount = currentCompanyCount + 1;
      }
    });
    if (payload.currentlyEmployed) {
      if (currentCompanyCount === 1) {
        props.changeTab("technicalDetails");
      } else if (currentCompanyCount > 1) {
        setCurrentCompanyError("Current company cannot be more than one");
      } else {
        setCurrentCompanyError("Please choose one company as current company");
      }
    } else {
      props.changeTab("technicalDetails");
    }
  };
  const getLocations = async () => {
    let allData = { ...dropDownData };
    try {
      let response = await getTotalCitiesReq();
      let cityData = [];

      if (response?.data?.response?.length > 0) {
        response?.data?.response.map((data) => {
          cityData.push({ label: data, value: data });
        });
      }
      cityData.push({ label: "Any Location", value: "Any Location" });
      allData.city = [...cityData];
    } catch (err) {
      console.error(err, "country api error");
    }
    setDropDownData({ ...allData });
  };
  const getDropData = async () => {
    let allData = { ...dropDownData };
    try {
      let response = await getTotalCitiesReq();
      let cityData = [];

      if (response?.data?.response?.length > 0) {
        response?.data?.response.map((data) => {
          cityData.push({ label: data, value: data });
        });
      }
      cityData.push({ label: "Any Location", value: "Any Location" });
      allData.city = [...cityData];
    } catch (err) {
      console.error(err, "country api error");
    }
    try {
      let response = await getroleListReq("IT");
      let jobtitlelistData = [];
      if (response.data) {
        response.data?.response?.map((data) => {
          jobtitlelistData.push({ label: data, value: data });
        });
      }
      allData.jobTitle = [...jobtitlelistData];
    } catch (err) {
      console.error(err, "role api error");
    }
    try {
      const response = await getAllCompany();
      let compData = [];
      if (response?.data?.response?.length > 0) {
        response.data.response.map((company) => {
          compData.push({
            label: company,
            value: company,
          });
        });
        compData.push({ label: "others", value: "others" });
      }
      allData.company = [...compData];
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
    setDropDownData({ ...allData });
  };
  useEffect(() => {
    handelPrePopulateData(props.candidateData);
    if (props?.candidateData?.workHistories?.length > 0) {
      formik.setFieldValue("empType", "experienced", true);
    } else if (props?.candidateData?.preferences?.location) {
      formik.setFieldValue("empType", "fresher", true);
    }
  }, [props.candidateData]);
  useEffect(() => {
    getDropData();
  }, []);
  return (
    <div className="work-details-form">
      <form className="details-form-tag" onSubmit={formik.handleSubmit}>
        <div className="details-form-body">
          <div className="empType">
            <input
              type="radio"
              checked={formik.values.empType === "experienced" ? true : false}
              id="experienced"
              disabled={props?.apiData?.workHistories?.length > 0}
              name="empType"
              value="experienced"
              onClick={(e) => {
                if (formik.values.empType !== "experienced") {
                  selectedDropdownData.preferedloc = [];
                  handelworkTabs(e, "experienced");
                }
              }}
            />
            <label for="experienced" className="each-checkbox">
              {" "}
              I'm experienced
            </label>
            <input
              type="radio"
              checked={formik.values.empType === "fresher" ? true : false}
              id="fresher"
              name="empType"
              disabled={props?.apiData?.workHistories?.length > 0}
              value="fresher"
              onClick={(e) => {
                if (formik.values.empType !== "fresher") {
                  handelworkTabs(e, "fresher");
                }
              }}
            />
            <label for="fresher" className="each-checkbox">
              {" "}
              I'm a fresher
            </label>
          </div>
          {formik.errors.empType && formik.touched.empType ? (
            <div className="text-red-500 error_text md:mt-1">
              {formik.errors.empType}
            </div>
          ) : null}
          {selectedEmpType === "experienced" && (
            <>
              <CompanyForm
                dropDownData={dropDownData}
                candidateData={props.candidateData}
                companyIndexs={companyIndexs}
                handleDropdownChange={handleDropdownChange}
                selectedDropdownData={selectedDropdownData}
                addCompany={addCompany}
                dltCompany={dltCompany}
                setCurrentCompanyErrorHandler={setCurrentCompanyError}
                formik={formik}
              />
              <div className="text-red-500 error_text md:mt-1 ml-8">
                {currentCompanyError}
              </div>
            </>
          )}
          {selectedEmpType === "experienced" && (
            <div className="grid grid-cols-3">
              <div className="mt-2 individual-field ">
                <div className="mb-2 label-responsive">
                  <span className="create-labels">
                    Total Work Experience(in years)
                  </span>
                </div>
                <div className="popins-medium input-ele">
                  <TextField
                    error={
                      formik.errors.totWorkExp && formik.touched.totWorkExp
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => {}}
                    type="text"
                    name="totWorkExp"
                    id="totWorkExp"
                    inputProps={{
                      className: cx(
                        `!text-xs open-sans-regular boxedInput popins-medium`
                      ),
                      style: {
                        padding: "9.5px 14px",
                        height: "0.43em !important",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        color: "#525252",
                      },
                    }}
                    autoComplete="off"
                    placeholder="Enter your total work experience"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.totWorkExp}
                  />
                </div>
                {formik.errors.totWorkExp && formik.touched.totWorkExp ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formik.errors.totWorkExp}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field ">
                <div className="mb-2 label-responsive">
                  <span className="create-labels">Notice Period</span>
                </div>
                <div className="input-ele">
                  <SelectDropdown
                    name="noticePeriod"
                    data={dropDownData.noticePeriod}
                    backspaceDelete={true}
                    onChange={(data) => {
                      handleDropdownChange(data, "noticePeriod");
                    }}
                    selectedData={selectedDropdownData.noticePeriod}
                    deselection={true}
                    searchable={true}
                  />
                </div>
                {/* <div className="popins-medium input-ele">
                  <TextField
                    error={
                      formik.errors.noticePeriod && formik.touched.noticePeriod
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => {}}
                    type="text"
                    name="noticePeriod"
                    id="noticePeriod"
                    inputProps={{
                      className: cx(
                        `!text-xs open-sans-regular boxedInput popins-medium`
                      ),
                      style: {
                        padding: "9.5px 14px",
                        height: "0.43em !important",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        color: "#525252",
                      },
                    }}
                    autoComplete="off"
                    placeholder="Enter your total WorkExp"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.noticePeriod}
                  />
                </div> */}
                {formik.errors.noticePeriod && formik.touched.noticePeriod ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formik.errors.noticePeriod}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field">
                <div className="mb-2 label-responsive">
                  <span className="create-labels">Preferred Location</span>
                </div>
                <div className="input-ele">
                  <ReactSelectDropdown
                    options={dropDownData?.city}
                    value={selectedDropdownData?.preferedloc ? selectedDropdownData?.preferedloc : ""}
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    placeholder="Select your preferred locations"
                    isClearable={false}
                    className="my-select-container"
                    onChange={(data) => {
                      handleDropdownChange(data, "preferedloc");
                    }}
                    specialOption={{
                      value: "Any Location",
                      label: "Any Location",
                    }}
                    onMenuOpen={getLocations}
                  />
                </div>
                {/* formik.touched?.preferedloc */}
                { formik.errors?.preferedloc && formik.touched?.preferedloc ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formik.errors?.preferedloc}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field ">
                <div className="mb-2 label-responsive">
                  <span className="create-labels">Preferred Annual Salary</span>
                </div>
                <div className="popins-medium input-ele">
                  <TextField
                    error={
                      formik.errors.preferedSal && formik.touched.preferedSal
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => {}}
                    type="text"
                    name="preferedSal"
                    id="preferedSal"
                    inputProps={{
                      className: cx(
                        `!text-xs open-sans-regular boxedInput popins-medium`
                      ),
                      style: {
                        padding: "9.5px 14px",
                        height: "0.43em !important",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        color: "#525252",
                      },
                    }}
                    autoComplete="off"
                    placeholder="Enter your preferred salary"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.preferedSal}
                  />
                </div>
                {formik.errors.preferedSal && formik.touched.preferedSal ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formik.errors.preferedSal}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field ">
                <div className="mb-2 label-responsive">
                  <span className="create-labels">Preferred WorkMode</span>
                </div>
                <div className="input-ele">
                  <SelectDropdown
                    data={dropDownData.prefWorkMode}
                    onChange={(data) => {
                      handleDropdownChange(data, "prefWorkMode");
                    }}
                    selectedData={selectedDropdownData.prefWorkMode}
                    deselection={true}
                    searchable={true}
                  />
                </div>

                {formik.errors.prefWorkMode && formik.touched.prefWorkMode ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formik.errors.prefWorkMode}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field ">
                <div className="mb-2 label-responsive">
                  <span className="create-labels">Preferred Shift</span>
                </div>
                <div className="input-ele">
                  <SelectDropdown
                    data={dropDownData.prefeShift}
                    onChange={(data) => {
                      handleDropdownChange(data, "prefeShift");
                    }}
                    selectedData={selectedDropdownData.prefeShift}
                    deselection={true}
                    searchable={true}
                  />
                </div>
                {formik.errors.prefeShift && formik.touched.prefeShift ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formik.errors.prefeShift}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field ">
                <div className="mb-2 label-responsive">
                  <span className="create-labels">Current Annual Salary</span>
                </div>
                <div className="popins-medium input-ele">
                  <TextField
                    error={
                      formik.errors.currentCtc && formik.touched.currentCtc
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => {}}
                    type="text"
                    name="currentCtc"
                    id="currentCtc"
                    inputProps={{
                      className: cx(
                        `!text-xs open-sans-regular boxedInput popins-medium`
                      ),
                      style: {
                        padding: "9.5px 14px",
                        height: "0.43em !important",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        color: "#525252",
                      },
                    }}
                    autoComplete="off"
                    placeholder="Enter your current salary"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.currentCtc}
                  />
                </div>
                {formik.errors.currentCtc && formik.touched.currentCtc ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formik.errors.currentCtc}
                  </div>
                ) : null}
              </div>
            </div>
          )}
          {selectedEmpType === "fresher" && (
            <div className="mt-2 individual-field grid grid-cols-4">
              <div className="mb-2 label col-span-4">
                <span className="create-labels">Preferred work location</span>
              </div>
              <div className="input-ele col-span-2">
                <ReactSelectDropdown
                  options={dropDownData?.city}
                  value={selectedDropdownData?.preferedloc} 
                  isSearchable
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  placeholder="Select your preferred locations"
                  isClearable={false}
                  className="my-select-container"
                  onChange={(data) => {
                    handleDropdownChange(data, "preferedloc");
                  }}
                  specialOption={{
                    value: "Any Location",
                    label: "Any Location",
                  }}
                  onMenuOpen={getLocations}
                />
              </div>
              {formik.errors?.preferedloc && formik.touched?.preferedloc ? (
                <div className="text-red-500 col-span-4 error_text md:mt-1">
                  {formik.errors.preferedloc}
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="details-form-footer">
          <button className="datatrimmer submity-btn" title={""} type="submit">
            Next
          </button>
          <button
            className="datatrimmer previous-btn mr-4"
            onClick={() => {
              formAndSendData();
              props.changeTab("educationDetails");
            }}
            title={""}
            type="button"
          >
            Previous
          </button>
          {props.completeProfileData ? (
            <button
              className="cursor-not-allowed"
              disabled="true"
              style={{
                fontSize: "12px",
                height: "30px",
                width: "94px",
                backgroundColor: "#f2f2f2",
                border: "1px solid #dadada",
                color: "#525252",
                borderRadius: "32px",
                opacity: "1",
                float: "right",
                marginRight: "10px",
              }}
              onClick={() => {
                props.closeScreen();
              }}
              title="Please enter the details"
            >
              Cancel
            </button>
          ) : (
            <button
              disabled={props.completeProfileData}
              className="datatrimmer cancel-btn-responsive mr-4"
              onClick={() => {
                props.closeScreen(true);
              }}
              title={
                props.completeProfileData
                  ? "Please enter the details"
                  : "Cancel"
              }
            >
              Cancel
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default WorkHistoryForm;
