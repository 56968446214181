import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import cx from "classnames";
import { TextField } from "@mui/material";
import style from "../../landing-page/common/inputs/inputs.module.scss";
import "./ContactUsWebsite.scss";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {
  MultiSelectDropdown,
  SelectDropdown,
} from "../../landing-page/common/select-dropdown";
import "react-phone-number-input/style.css";
import $ from "jquery";
import {
  contactUsReq,
  defaultCountry,
  getRegisteredCompanyList,
  getroleListReq,
  getskillsListReq,
} from "../../../api/api-services";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";

import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";

import "react-toastify/dist/ReactToastify.css";
import ReactSelectDropdown from "../../landing-page/common/react-select-dropdown";
import Select from "react-select";
import NavigationHeader from "../nav-section/NavigationHeader";
import FooterSectionHome from "../footer-section/FooterSectionHome";
import PhoneIconAnimated from "../website-assets/phoneAnimated.gif";
import HomeAnimated from "../website-assets/homeAnimated.gif";
import GmailAnimated from "../website-assets/gmailAnimated.gif";
import { Email } from "@material-ui/icons";

const ContactUsWebsite = (props) => {
  const navigate = useNavigate();
  const EMAIL_PATTERN =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [emailError, setEmailError] = useState();
  const [companyData, setCompanyData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [skillsData, setSkillsData] = useState([]);
  const [value, setValue] = useState();
  const [error, setError] = useState({ phone: false, validPhone: false });
  const [selectedCompanyData, setSelectedCompanyData] = useState([]);
  const [selectedRoleData, setSelectedRoleData] = useState([]);
  const [selectedSkillsData, setSelectedSkillsData] = useState([]);
  const inputBorder = "1px solid #26428B80";
  const inputBorderErr = "1px solid #ea0322";
  const [presentCountry, setPresentCountry] = useState("");

  const [selectedJobTitleData, setSelectedJobTitleData] = useState([]);
  const [jobTitleData, setJobTitleData] = useState([
    { label: "Analyst", value: "Analyst" },
    { label: "Automation Architect", value: "Automation Architect" },
    { label: "CEO", value: "CEO" },
    { label: "CTO", value: "CTO" },
    { label: "Consultant", value: "Consultant" },
    { label: "Director", value: "Director" },
    { label: "DevOps Engineer", value: "DevOps Engineer" },
    { label: "Engineering Manager", value: "Engineering Manager" },
    { label: "Manager", value: "Manager" },
    { label: "QA Engineer", value: "QA Engineer" },
    { label: "QA Lead", value: "QA Lead" },
    { label: "Service Provider", value: "Service Provider" },
    { label: "Software Engineer", value: "Software Engineer" },
    { label: "VP", value: "VP" },
    { label: "Others", value: "Others" },
  ]);
  const handlejobTitleChange = (data) => {
    formikDetails.setFieldValue("jobTitle", data?.value);

    setSelectedJobTitleData(data);
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await getRegisteredCompanyList();
        const companies = response.data.response.map((company) => ({
          label: company,
          value: company,
        }));
        setCompanyData([...companies, { label: "Others", value: "Others" }]);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getroleListReq("IT");
        const roles = response.data.response.map((role) => ({
          label: role,
          value: role,
        }));
        setRoleData(roles);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

  let handlecompanyChange = (data) => {
    let list = [];
    data?.map((company) => {
      list.push(company.value);
    });
    formikDetails.setFieldValue("companyName", [...list]);
    setSelectedCompanyData([...data]);
  };

  const handleCompanyChange = (data) => {
    if (data.value != "") {
      formikDetails.setFieldValue("companyName", data?.value);
    } else {
      formikDetails.setFieldValue("companyName", "");
    }
    setSelectedCompanyData(data);
  };
  // const customStyles = {
   
  //   control: (base) => ({
  //     ...base,
  //     cursor: "pointer", // Set the cursor to pointer for the control
  //   }),
  //   option: (base) => ({
  //     ...base,
  //     cursor: "pointer", // Set the cursor to pointer for the options
  //   }),
  //   control: (provided, state) => ({
  //     ...provided,
  //     borderColor: state.isFocused ? "#007456" : provided.borderColor, // Change border color to green when focused
  //     boxShadow: "none", // Remove the default box shadow

  //     borderStyle: "solid",
  //     "&:hover": {
  //       borderColor: "#007456", // Change border color to green on hover

  //     },
  //   }),
  // };
  const customStyles = {
    control: (base) => ({
      ...base,
      cursor: "pointer", // Set the cursor to pointer for the control
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      cursor: "pointer", // Set the cursor to pointer for the options
      backgroundColor: isFocused ? "#8bca84" : isSelected ? "#8bca84" : base.backgroundColor, // Change background color on hover and when selected
      color: isFocused ? "#fff" : isSelected ? "#fff" : base.color, // Change text color for better visibility on hover and when selected
      ":hover": {
        color: "#fff", // Change text color to white on hover
      },
      ":active": {
        backgroundColor: "#8bca84", // Change background color when the option is active (clicked)
      },
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#007456" : provided.borderColor, // Change border color to green when focused
      boxShadow: "none", // Remove the default box shadow
      borderStyle: "solid",
      "&:hover": {
        borderColor: "#007456", // Change border color to green on hover
      },
    }),
    singleValue: (base, { isFocused }) => ({
      ...base,
      color: isFocused ? "#fff" : base.color, // Change text color when selected or on hover
    }),
  };
  const changeGreenBorderStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#007456"
      },
      "&:hover fieldset": {
        borderColor: "#007456"
      }
    }
  }
  const customStylesForReactDropDownSelect = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#007456' : provided.borderColor,
      borderWidth: 2,
      borderStyle: 'solid',
      '&:hover': {
        borderColor: '#007456', // Change border color to green on hover
      },
    }),
  };

  // const handleRoleChange = (data) => {
  //   if (data?.[0]?.value) {
  //     let selecData = [];
  //     data?.map((role) => {
  //       selecData.push(role?.value);
  //     });
  //     formikDetails.setFieldValue("hiringFor", data?.[0]?.value);
  //     if (data?.[0]?.value === "Software Development") {
  //       let sdRoles = [
  //         "Java",
  //         "HTML",
  //         "J2Ee",
  //         "Python",
  //         "Hibernate",
  //         "Spring",
  //         "Servlets",
  //         "JDBC",
  //         "Log4j",
  //         "Spring Mvc",
  //         "Intellij Idea",
  //         "Eclipse",
  //         "Java Script",
  //       ];
  //       let sddropdownData = [];
  //       sdRoles.map((data) => {
  //         sddropdownData.push({ label: data, value: data });
  //       });
  //       setSkillsData([...sddropdownData]);
  //     } else if (data?.[0]?.value === "Software Testing") {
  //       let roles = [
  //         "Manual Box Testing",
  //         "Black Box",
  //         "Data Base Testing",
  //         "Regression Testing",
  //         "Smoke Testing",
  //         "Sanity Testing",
  //         "GUI Testing",
  //         "Functional Testing",
  //         "Mobile Application Testing",
  //         "Web Application Testing",
  //         "Defect Life Cycle",
  //         "Bug Report",
  //         "Buzilla",
  //         "STLC",
  //         "SDLC",
  //         "Test Cases",
  //         "Test Scenarios",
  //         "Test Plan Preparation",
  //       ];
  //       let sddropdownData = [];
  //       roles.map((data) => {
  //         sddropdownData.push({ label: data, value: data });
  //       });
  //       setSkillsData([...sddropdownData]);
  //     } else if (data?.[0]?.value === "Tech Support") {
  //       let roles = [
  //         "Networking",
  //         "Troubleshooting",
  //         "Hardware",
  //         "Linux",
  //         "Computer Hardware",
  //         "Windows",
  //         "CCNA",
  //         "Installation",
  //         "System Administration",
  //         "Desktop support",
  //         "Rounting",
  //         "Switching",
  //         "Bgp",
  //         "VLAN",
  //         "Firewall",
  //         "Remote Access",
  //       ];
  //       let sddropdownData = [];
  //       roles.map((data) => {
  //         sddropdownData.push({ label: data, value: data });
  //       });
  //       setSkillsData([...sddropdownData]);
  //     } else if (data?.[0]?.value === "Devops Engineer") {
  //       let roles = [
  //         "Devops",
  //         "Linux",
  //         "AWS",
  //         "Jenkins",
  //         "Shell Scripting",
  //         "Python",
  //         "GIT",
  //         "Puppet",
  //         "NAGIOS",
  //         "Docker",
  //         "Redhat Linux",
  //         "Ubuntu",
  //         "C",
  //         "Unix",
  //         "Apache",
  //         "Solaris",
  //         "Samba",
  //         "Networking",
  //         "System Administration",
  //         "Windows",
  //       ];
  //       let sddropdownData = [];
  //       roles.map((data) => {
  //         sddropdownData.push({ label: data, value: data });
  //       });
  //       setSkillsData([...sddropdownData]);
  //     } else if (data?.[0]?.value === "Business Process") {
  //       let roles = [
  //         "Finance",
  //         "Investment Banking",
  //         "KYC",
  //         "Accounts Payable",
  //         "Team Leading",
  //         "Team Handling",
  //         "Aml",
  //         "Customer Service",
  //         "Team Management",
  //         "Banking",
  //         "Customer Handling",
  //         "Customer Support",
  //         "Customer Care",
  //         "Customer Management",
  //         "Operations",
  //         "Branch Operations",
  //         "After Sales Service",
  //         "Customer Retention",
  //         "Marketing",
  //         "CRM",
  //       ];
  //       let sddropdownData = [];
  //       roles.map((data) => {
  //         sddropdownData.push({ label: data, value: data });
  //       });
  //       setSkillsData([...sddropdownData]);
  //     }
  //     if (data?.[0].value !== selectedRoleData?.[0]?.value) {
  //       formikDetails.setFieldValue("skills", []);
  //       setSelectedSkillsData([]);
  //     }
  //     setSelectedRoleData([...data]);
  //   }
  // };
  const handleRoleChange = async (data) => {
    if (data?.value) {
      const selectedRole = data?.value;
      formikDetails.setFieldValue("hiringFor", selectedRole);

      try {
        const response = await getskillsListReq("IT", selectedRole);
        const skills = response.data.response.map((skill) => ({
          label: skill,
          value: skill,
        }));
        setSkillsData(skills);
      } catch (error) {
        console.error("Error fetching skills:", error);
        setSkillsData([]);
      }

      if (selectedRole !== selectedRoleData?.value) {
        formikDetails.setFieldValue("skills", []);
        setSelectedSkillsData([]);
      }
      setSelectedRoleData(data);
    }
  };

  const handleSkillsChange = (data) => {
    let selecData = [];
    data.map((skill) => {
      selecData.push(skill.value);
    });
    formikDetails.setFieldValue("skills", [...selecData]);
    setSelectedSkillsData([...data]);
  };

  let initialValues = {
    name: "",
    emailId: "",
    jobTitle: "",
    jobTitleOthers: "",
    message: "",
    phone: "",
    companyName: "",
    otherCompanyName: "",
    location: "",
    hiringFor: "",
    skills: [],
    termsAndConditions: false,
  };
  const onSubmit = async (values) => {
    console.log(values);
    let response;
    let payload = {
      clientName: values.name,
      phone: values.phone,
      email: values.emailId,
      lookingFor: values.hiringFor,
      skills: values.skills,
      location: values.location,
      message: values.message,
    };
    // if (formikDetails.values.message) {
    //   payload.message = formikDetails.values.message;
    // }
    if (values.jobTitle === "Others") {
      payload.jobTittle = values.jobTitleOthers;
    } else {
      payload.jobTittle = values.jobTitle;
    }

    if (values.companyName === "Others") {
      payload.company = values.otherCompanyName;
    } else {
      payload.company = values.companyName;
    }
    try {
      response = await contactUsReq(payload);
      console.log(response);
      if (response.status === 201) {
        navigate("/thankYou");
        window.scrollTo(0, 0);
        // toast.error("Enquiry added successfully");
        // toast.warn("Enquiry added successfully");
        toast.success(
          <>
            <div className="toast-heading">Success</div>
            <div>Enquiry added successfull</div>
          </>
        );
        formikDetails.resetForm(initialValues);
        setSelectedCompanyData([]);
        setSelectedRoleData([]);
        setSelectedSkillsData([]);
        setSkillsData([]);
        setValue();
        setSelectedJobTitleData([]);
      } else {
        toast.info(
          <>
            <div className="toast-heading">Info</div>
            <div>{response?.response?.data?.["Error 1"]}</div>
          </>
        );
      }
    } catch (err) {
      console.error(err);
    }
  };
  const validationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .min(3, "Minimum 3 characters")
      .max(25, "Maximum 25 characters")
      .matches(/^[A-Za-z ]*$/, "Name should be alphabetic")
      .required("Name is required"),
    emailId: yup
      .string()
      .trim()
      .required("Email id is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Enter valid email address"),
    jobTitle: yup.string().required("Job title is required"),
    jobTitleOthers: yup
      .string()
      .min(2, "Minimum 2 characters")
      .max(50, "Maximum 50 characters")
      .when("jobTitle", {
        is: (value) => value === "Others",
        then: () =>
          yup
            .string()
            .required("Job title others is required")
            .matches(
              /^[A-Za-z0-9 ]*$/,
              "Job title others can not have special characters"
            ),
      }),
    phone: yup.string().required("Valid Phone number is required"),
    companyName: yup.string().required("Company Name is required"),
    hiringFor: yup.string().required("Hiring For is required"),
    skills: yup.array().min(1, "Skills is required"),
    message: yup.string().required("Message is required"),
    otherCompanyName: yup.string().when("companyName", {
      is: (value) => value === "Others",
      then: () =>
        yup
          .string()
          .required("Others Company Name is required")
          .min(2, "Minimum 2 characters")
          .matches(/^[A-Za-z0-9 ]*$/, "Special characters not allowed"),
    }),
    location: yup.string().when("companyName", {
      is: (value) => value === "Others",
      then: () => yup.string().required("Location is required"),
    }),
    termsAndConditions: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions"),
  });
  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  function isEmailValid(email) {
    return EMAIL_PATTERN.test(email);
  }

  function checkValidEmail() {
    if (formikDetails.values.emailId) {
      isEmailValid(formikDetails.values.emailId);
      //    && checkIfEmailExists();
    } else {
      setEmailError("");
      formikDetails.setFieldValue("name", "");
      formikDetails.setFieldValue("phoneNumbers", "");
    }
  }
  function handleTrimmedBlur(event) {
    event.target.value = event.target.value.trim();
    formikDetails.handleChange(event);
    formikDetails.handleBlur(event);
  }
  const handleCount = (value) => {
    let charCount = value.length,
      current = $("#current");
    current.text(charCount);
  };

  const handleOnBlur = (id) => {
    if (!value) {
      setError({ ...error, [id]: true });
    } else if (!isValidPhoneNumber("+" + value?.toString())) {
      setError({ ...error, [id]: false, validPhone: true });
    } else {
      setError({ ...error, [id]: false, validPhone: false });
    }
  };

  useEffect(() => {
    defaultCountry()
      .then((res) => {
        setPresentCountry(res.data.country);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const handleBlur = () => {
    if (formikDetails.touched.jobTitle && !formikDetails.values.jobTitle) {
      formikDetails.setFieldError("jobTitle", "Job title is required");
    }
  };

  const handleSelectBlur = (event) => {
    if (event.currentTarget.contains(event.relatedTarget)) {
      formikDetails.setFieldTouched("jobTitle", true);
      handleBlur();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on component mount

    const handleRefresh = () => {
      window.scrollTo(0, 0); // Scroll to the top on page refresh
    };

    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, []);
  
  return (
    <div className="contactUsWebsiteComponent">
      <div className="navbarSection-contactUs">
        <NavigationHeader />
      </div>

      <div className=" overflow-x-hidden">
        <section className="contact-us-page">
          <div class="row">
            <div class="contact_banner_content">
              <h1
                class="wow fadeInUp ml_150 flex justify-center"
                data-wow-delay="0.3s"
              >
                Contact Us
              </h1>
            </div>
            <div className="subHeader flex justify-center">
              <p class=" wow fadeInUp ml_175 " data-wow-delay="0.4s">
                Have a question or need more information? Just drop us a line!
                {/* <div class="contact_banner_line my-4"></div> */}
              </p>
            </div>
          </div>
          <div className="sendingMsg mb-2.5">Send us a message</div>
          <div className="contact-us-form row flex">
            <div class="contactinfo custom-padding col-lg-4 col-sm-12">
              {/* <h2 class="text-left label">Contact Info</h2> */}
              {/* <div className="customizedHR my-2" /> */}
              <h3 className="label">India</h3>
              <div class="phonenumber d-flex">
                {/* <div className="leftBorder"></div> */}
                <div class="">
                  {/* <CallIcon style={{  width: "20px" }} /> */}
                  <img src={PhoneIconAnimated} style={{ width: "25px" }} />
                </div>
                <div class="">
                  <p className="value mx-2">(+91) 8296776506</p>
                </div>
              </div>
              <div class="phonenumber d-flex">
                {/* <div className="leftBorder"></div> */}

                <div class="">
                  {/* <EmailIcon style={{  width: "20px" }} /> */}
                  <img src={GmailAnimated} style={{ width: "25px" }} />
                </div>
                <div class="">
                  <p className="value mx-2">contactus@acrossgeo.com</p>
                </div>
              </div>
              <div class="phonenumber d-flex">
                {/* <div className="leftBorder"></div> */}
                <div class="">
                  {/* <LocationOnIcon style={{  width: "20px" }} /> */}
                  <img src={HomeAnimated} style={{ width: "25px", maxWidth:"fit-content" }} />
                </div>
                <div class="">
                  <p className="value mx-2">
                    IndiQube South Mile building, Vijayarangam
                    Layout, Jayanagar, Bengaluru, Karnataka, 560070, India.
                  </p>
                </div>
              </div>
              <h3 className="label">Canada</h3>
              <div class="phonenumber d-flex">
                {/* <div className="leftBorder"></div> */}

                <div class="">
                  {/* <CallIcon style={{  width: "20px" }} /> */}
                  <img src={PhoneIconAnimated} style={{ width: "25px" }} />
                </div>
                <div class="">
                  <p className="value mx-2">+1 888 980 7010</p>
                </div>
              </div>
              <div class="phonenumber d-flex ">
                {/* <div className="leftBorder"></div> */}

                <div class="">
                  {/* <EmailIcon style={{  width: "20px" }} /> */}
                  <img src={GmailAnimated} style={{ width: "25px" }} />
                </div>
                <div class="">
                  <p className="value mx-2">contactus@acrossgeo.com</p>
                </div>
              </div>
              <div class="phonenumber d-flex">
                {/* <div className="leftBorder"></div> */}
                <div class="">
                  <img src={HomeAnimated} style={{ width: "25px", maxWidth:"fit-content" }}/>
                </div>
                <div class="mb_10 ">
                  <p className="value mx-2">
                    120 Adelaide Street West, Suite 2500, Toronto, ON, M5H 1T1,
                    Canada.
                  </p>
                </div>
              </div>
              <h3 className="label">United Kingdom</h3>
              <div class="phonenumber d-flex">
                {/* <div className="leftBorder"></div> */}

                <div class="">
                  <img src={PhoneIconAnimated} style={{ width: "25px" }} />
                </div>
                <div class="">
                  <p className="value mx-2">+44 0203 4115 450</p>
                </div>
              </div>
              <div class="phonenumber d-flex ">
                {/* <div className="leftBorder"></div> */}

                <div class="">
                  {/* <EmailIcon style={{  width: "20px" }} /> */}
                  <img src={GmailAnimated} style={{ width: "25px" }} />
                </div>
                <div class="">
                  <p className="value mx-2">contactus@acrossgeo.com</p>
                </div>
              </div>
              <div class="phonenumber d-flex">
                {/* <div className="leftBorder"></div> */}
                <div class="">
                  <img src={HomeAnimated} style={{ width: "25px", maxWidth:"fit-content" }} />
                </div>
                <div class="mb_10">
                  <p className="value mx-2">
                    1 Elmfield Park, Bromley - BR1 1LU, United Kingdom.
                  </p>
                </div>
              </div>
              <h3 className="label">United States of America</h3>
              <div class="phonenumber d-flex">
                {/* <div className="leftBorder"></div> */}

                <div class="">
                  <img src={PhoneIconAnimated} style={{ width: "25px" }} />
                </div>
                <div class="">
                  <p className="value mx-2">+1 888 980 7010</p>
                </div>
              </div>
              <div class="phonenumber d-flex ">
                {/* <div className="leftBorder"></div> */}

                <div class="">
                  {/* <EmailIcon style={{  width: "20px" }} /> */}
                  <img src={GmailAnimated} style={{ width: "25px" }} />
                </div>
                <div class="">
                  <p className="value mx-2">contactus@acrossgeo.com</p>
                </div>
              </div>
              <div class="phonenumber d-flex">
                {/* <div className="leftBorder"></div> */}
                <div class="">
                  <img src={HomeAnimated} style={{ width: "25px", maxWidth:"fit-content" }} />
                </div>
                <div class="">
                  <p className="value mx-2">
                    99 South Almaden Blvd, Suite 600, San Jose, California, CA
                    95113.
                  </p>
                </div>
              </div>
              {/* <div className="icons flex justify-end">
                <p>
                  <FacebookIcon className="text-white" />
                </p>
                <p className="mx-2">
                  <YouTubeIcon className="text-white" />
                </p>
                <p>
                  <LinkedInIcon className="text-white" />
                </p>
                <p className="mx-2">
                  <TwitterIcon className="text-white" />
                </p>
              </div> */}
            </div>
            <div className="formbox col-md-8 col-sm-12">
              <form onSubmit={formikDetails.handleSubmit}>
                <div className="required-fields-styles">
                  <div className="mt-2 individual-field">
                    <div className=" label">
                      <span className="text-red-500 ">*</span>
                      <span className="create-labels mx-1">Name</span>
                    </div>
                    <div className="popins-medium">
                      <TextField
                        error={
                          formikDetails.errors.name &&
                          formikDetails.touched.name
                        }
                        // change the border color from blue to green on focous
                        sx={changeGreenBorderStyle}
                        autoFocus
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onClick={() => { }}
                        type="text"
                        name="name"
                        id="name"
                        inputProps={{
                          className: cx(
                            `!text-xs  boxedInput  impFont`
                          ),
                          style: {
                            padding: "8px 14px",
                            height: "0.43em !important",
                            fontFamily: "normal normal 700 14px Inter",
                          },
                        }}
                        autoComplete="off"
                        placeholder="Enter your name"
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        value={formikDetails.values.name}
                      />
                    </div>
                    {formikDetails.errors.name && formikDetails.touched.name ? (
                      <div className="text-red-500 error_text absolute ">
                        {formikDetails.errors.name}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group mt-2 phoneNumberSection individual-field text-left">
                    <div className=" label ">
                      <span className="text-red-500 ">*</span>
                      <span className="create-labels mx-1">Phone Number</span>
                    </div>

                    <PhoneInput
                      type="text"
                      // placeholder="Enter phone number"

                      searchPlaceholder="Search..."
                      searchNotFound="No Countries Found"
                      specialLabel=""
                      autoFormat={false}
                      enableAreaCodeStretch
                      country={presentCountry && presentCountry.toLowerCase()}
                      name="phone"
                      id="phone"
                      value={formikDetails.values.phone}
                      onChange={(e) => {
                        formikDetails.setFieldValue("phone", e);
                        setValue(e);
                      }}
                      defaultCountry={presentCountry}
                      style={{
                        border: `${error.phone || error.validPhone
                            ? inputBorderErr
                            : inputBorder
                          }`,
                        borderRadius: "5px",
                      }}
                      enableSearch
                      international
                      inputProps={{
                        // className: cx(`!text-xs impFont_14px boxedInput `),
                        style: {
                          // padding: "9.5px 14px",
                          height: "0.43em !important",
                        },
                      }}
                      autoComplete="off"
                      onBlur={() => handleOnBlur("phone")}
                      countryCodeEditable={false}
                    />
                    {(error.phone ||
                      (formikDetails.errors.phone &&
                        formikDetails.touched.phone)) && (
                        <div className="error_text absolute">
                          Phone number is required
                        </div>
                      )}
                    {error.validPhone && !error.phone && (
                      <div className="error_text absolute">
                        Invalid phone number
                      </div>
                    )}
                  </div>
                  <div className="mt-4 individual-field">
                    <div className=" label">
                      <span className="text-red-500 ">*</span>
                      <span className="create-labels mx-1">Email</span>
                    </div>
                    <div className="popins-medium">
                      <div
                        className={cx(
                          "w-full user-email-input-tyle",
                          style["labeled-input"],
                          {
                            [style["number-input-control"]]:
                              props?.type === "number",
                          }
                        )}
                      >
                        <TextField
                          error={
                            formikDetails.errors.emailId &&
                            formikDetails.touched.emailId
                          }
                          fullWidth
                          hiddenLabel
                          className={cx(
                            "text-xs open-sans-regular",
                            style["form-control"]
                          )}
                          placeholder={"Enter your email"}
                          value={formikDetails.values.emailId.trim()}
                          onBlur={(event) => {
                            formikDetails.handleBlur(event);
                            handleTrimmedBlur(event);
                            // checkValidEmail(event);
                          }}
                          // change the border color from blue to green on focous
                          sx={changeGreenBorderStyle}
                          style={{ fontFamily: "normal normal normal 12px Inter" }}
                          onChange={formikDetails.handleChange}
                          autoComplete="off"
                          name="emailId"
                          id="emailId"
                          onFocus={() => setEmailError("")}
                          inputProps={{
                            className: cx(`!text-xs  boxedInput `),
                            style: {
                              padding: "8.5px 14px",
                              height: "0.43em !important",
                              fontFamily: "normal normal normal 14px Inter",
                            },
                          }}
                          FormHelperTextProps={{
                            className: " nooo",
                          }}
                          onKeyDown={(evt) =>
                            ["Enter"].includes(evt.key) && evt.preventDefault()
                          }
                        />
                      </div>
                    </div>
                    {formikDetails.errors.emailId &&
                      formikDetails.touched.emailId ? (
                      <div className="text-red-500 error_text  absolute">
                        {formikDetails.errors.emailId}
                      </div>
                    ) : null}
                  </div>
                  <div className="field-wrapper mt-4 individual-field">
                    <div className=" label">
                      <span className="text-red-500 ">*</span>
                      <span className="create-labels mx-1">Company Name</span>
                    </div>
                    <div className="" id="search_dropdown_contact">
                      <Select
                        options={companyData}
                        onChange={handleCompanyChange}
                        // handleSelectChange={handlecompanyChange}
                        value={selectedCompanyData}
                        isSearchable={true}
                        isClearable={false}
                        className="custom-select" // Add a custom class
                        styles={customStyles} // Apply the custom styles

                      />
                    </div>
                    {formikDetails.errors.companyName &&
                      formikDetails.touched.companyName ? (
                      <div className="text-red-500 error_text  absolute">
                        {formikDetails.errors.companyName}
                      </div>
                    ) : null}
                  </div>

                  {formikDetails.values.companyName === "Others" && (
                    <div className="field-wrapper mt-4 individual-field">
                      <div className=" label">
                        <span className="text-red-500 ">*</span>
                        <span className="create-labels mx-1">
                          Other Company
                        </span>
                      </div>
                      <div className="popins-medium">
                        <TextField
                          id="otherCompanyName"
                          name="otherCompanyName"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            className: cx(
                              `!text-xs impFont boxedInput popins-medium`
                            ),
                            style: {
                              padding: "9.5px 14px",
                              height: "0.43em !important",
                              fontFamily: "normal normal normal 14px Inter",
                            },
                          }}
                          sx={changeGreenBorderStyle}
                          autoComplete="off"
                          placeholder="Enter your company name"
                          onBlur={formikDetails.handleBlur}
                          value={formikDetails.values.otherCompanyName}
                          onChange={formikDetails.handleChange}
                          error={
                            formikDetails.touched.otherCompanyName &&
                            Boolean(formikDetails.errors.otherCompanyName)
                          }
                        />
                      </div>
                      {formikDetails.errors.otherCompanyName &&
                        formikDetails.touched.otherCompanyName ? (
                        <div className="text-red-500 error_text  absolute">
                          {formikDetails.errors.otherCompanyName}
                        </div>
                      ) : null}
                    </div>
                  )}
                  {formikDetails.values.companyName === "Others" && (
                    <div className="field-wrapper mt-4 individual-field">
                      <div className=" label ">
                        <span className="text-red-500 ">*</span>
                        <span className="create-labels"> Location</span>
                      </div>
                      <div className="popins-medium">
                        <TextField
                          id="location"
                          name="location"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            className: cx(
                              `!text-xs impFont boxedInput popins-medium`
                            ),
                            style: {
                              padding: "9.5px 14px",
                              height: "0.43em !important",
                              fontFamily: "normal normal normal 14px Inter",
                            },
                          }}
                          sx={changeGreenBorderStyle}
                          autoComplete="off"
                          placeholder="Enter your location"
                          onBlur={formikDetails.handleBlur}
                          value={formikDetails.values.location}
                          onChange={formikDetails.handleChange}
                          error={
                            formikDetails.touched.location &&
                            Boolean(formikDetails.errors.location)
                          }
                        />
                      </div>
                      {formikDetails.errors.location &&
                        formikDetails.touched.location ? (
                        <div className="text-red-500 error_text  absolute">
                          {formikDetails.errors.location}
                        </div>
                      ) : null}
                    </div>
                  )}
                  <div className="field-wrapper mt-4 individual-field">
                    <div className=" label">
                      <span className="text-red-500 ">*</span>
                      <span className="create-labels mx-1">Hiring For</span>
                    </div>
                    <div className="" id="search_dropdown_contact">
                      {/* <MultiSelectDropdown
                  data={roleData}
                  handleSelectChange={handleRoleChange}
                  selectedData={selectedRoleData}
                /> */}
                      
                       <Select
                        options={roleData}
                        onChange={handleRoleChange}
                       
                        value={selectedRoleData}
                        isSearchable={true}
                        isClearable={false}
                        className="custom-select" // Add a custom class
                        styles={customStyles} // Apply the custom styles

                      />
                    </div>
                    {formikDetails.errors.hiringFor &&
                      formikDetails.touched.hiringFor ? (
                      <div className="text-red-500 error_text  absolute">
                        {formikDetails.errors.hiringFor}
                      </div>
                    ) : null}
                  </div>
                  <div className="field-wrapper mt-4 individual-field">
                    <div className=" label">
                      <span className="text-red-500 ">*</span>
                      <span className="create-labels mx-1">
                        Required Skills
                      </span>
                    </div>
                    <div className="" id="search_dropdown_contact">
                      <ReactSelectDropdown
                        noOptionMsg={
                          selectedRoleData?.[0]?.value
                            ? "No options"
                            : "please select Hiring for"
                        }
                        options={skillsData}
                        onChange={handleSkillsChange}
                        value={selectedSkillsData}
                        isMulti={true}

                      />
                      
                    </div>
                    {formikDetails.errors.skills &&
                      formikDetails.touched.skills ? (
                      <div className="text-red-500 error_text   absolute">
                        {formikDetails.errors.skills}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="form-group  customwidth field-wrapper mt-4 individual-field text-left customwidandpadding">
                  <div className=" label">
                    <span className="text-red-500 ">*</span>
                    <span className="create-labels mx-1">Job Title</span>
                  </div>

                  <div className="popins-medium" style={{ height: "52px" }}>
                    <div
                      className={`w-full user-email-input-tyle ${(formikDetails.errors.jobTitle &&
                          formikDetails.touched.jobTitle) ||
                          (formikDetails.touched.jobTitle &&
                            !formikDetails.values.jobTitle)
                          ? "error-input"
                          : ""
                        }`}
                      onBlur={handleSelectBlur}
                      id="search_dropdown_contact"
                    >
                      {/* <SelectDropdown
                        data={jobTitleData}
                        onChange={handlejobTitleChange}
                        selectedData={selectedJobTitleData}
                        searchable={false}
                      /> */}
                      <Select
                        options={jobTitleData}
                        onChange={handlejobTitleChange}
                       
                        value={selectedJobTitleData}
                        isSearchable={true}
                        isClearable={false}
                        className="custom-select" // Add a custom class
                        styles={customStyles} // Apply the custom styles

                      />
                    </div>
                    {formikDetails.errors.jobTitle &&
                      formikDetails.touched.jobTitle && (
                        <div className="text-red-500 error_text ">
                          {formikDetails.errors.jobTitle}
                        </div>
                      )}
                  </div>
                </div>
                {formikDetails.values.jobTitle === "Others" && (
                  <div  className=" custWidth field-wrapper my-3.5 relative bottom-2.5 individual-field customwidandpadding">
                    <div className="mb-1 label">
                      <span className="text-red-500 ">*</span>
                      <span className="create-labels mx-1">
                        Other Job Title
                      </span>
                    </div>
                    <div className="popins-medium">
                      <TextField
                        error={
                          formikDetails.errors.jobTitleOthers &&
                          formikDetails.touched.jobTitleOthers
                        }
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onClick={() => { }}
                        type="text"
                        name="jobTitleOthers"
                        id="jobTitleOthers"
                        inputProps={{
                          className: cx(`!text-xs  boxedInput `),
                          style: {
                            padding: "7px 14px",
                            height: "0.43em !important",
                            fontFamily: "font:normal normal normal 12px Inter;",
                          },
                        }}
                        FormHelperTextProps={{
                          className: " nooo",
                        }}
                        style={{ fontFamily: "normal normal normal 12px Inter" }}
                        sx={changeGreenBorderStyle}
                        autoComplete="off"
                        placeholder="Enter your job title"
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        value={formikDetails.values.jobTitleOthers}
                      />
                    </div>
                    {formikDetails.errors.jobTitleOthers &&
                      formikDetails.touched.jobTitleOthers ? (
                      <div className="text-red-500 error_text  ">
                        {formikDetails.errors.jobTitleOthers}
                      </div>
                    ) : null}
                  </div>
                )}
                <div className="msg-field mt-2 paddingclass">
                  <div className="form-group text-left mb-0">
                    <div className=" label">
                      <span className="text-red-500 ">* {`  `}</span>
                      <span className="create-labels ">Message</span>
                    </div>
                    <textarea
                      placeholder="Type here..."
                      className="form-control shadow-none textarea_contact"
                      name="message"
                      id="message"
                      cols="40"
                      rows="5"
                      error={
                        formikDetails.errors.emailId &&
                        formikDetails.touched.emailId
                      }
                      value={formikDetails.values.message}
                      onChange={formikDetails.handleChange}
                      style={{ border: inputBorder, resize: "none" }}
                      //   onKeyDown={handleEnter}
                      inputProps={{
                        className: cx(
                          `!text-xs  boxedInput impFont`
                        ),
                        style: {
                          padding: "8px 14px",
                          height: "0.43em !important",
                          fontFamily: "normal normal 700 14px Inter",
                        },
                      }}
                      maxLength="500"
                      onKeyUp={(e) => handleCount(e.target.value)}
                    ></textarea>
                    {formikDetails.errors.message &&
                      formikDetails.touched.message ? (
                      <div className="text-red-500 error_text_msg  absolute">
                        {formikDetails.errors.message}
                      </div>
                    ) : null}
                  </div>
                  <div className="d-flex justify-content-end  labelTitleDemo">
                    <span id="current">0</span>
                    <span id="maximum">&nbsp;/ 500</span>
                  </div>
                </div>
                <div className="field-wrapper individual-field termsAndConditions">
                  <div className="popins-medium flex " style={{height:"36px",gap:"8px"}}>
                    <input
                      name="termsAndConditions"
                      type="checkbox"
                      checked={formikDetails.values.termsAndConditions}
                      onChange={formikDetails.handleChange}
                      onBlur={formikDetails.handleBlur}
                      className="relative termCheckBox"
                    />
                    <p className="relative  mx-1 fontCustom">
                      Accept <b style={{color:"#007456"}} className=" mx-1">Terms of Use</b>{" "}
                      and <b style={{color:"#007456"}} className="mx-1">Privacy Policy</b>{" "}
                    </p>
                  </div>
                  {formikDetails.errors.termsAndConditions &&
                    formikDetails.touched.termsAndConditions ? (
                    <div className="text-red-500 absolute error_text -mt-1.5 ">
                      {formikDetails.errors.termsAndConditions}
                    </div>
                  ) : null}
                </div>

                <div className="search-footer mt-4">
                  <button
                    type="submit"
                    onClick={() => { }}
                    disabled={error.validPhone && !error.phone}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>

          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            className={"tost-msg-stylinh"}
            draggable={false}
            theme="light"
          />
        </section>
        <FooterSectionHome />
      </div>
    </div>
  );
};

export default ContactUsWebsite;
