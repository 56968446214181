import React from "react";
import "./FooterSectionHome.scss";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import youtubeIcon from "../../../assets/youtube1.svg";
import fBIcon from "../../../assets/fcebook1.svg";
import instagramIcon from "../../../assets/Instagram1.svg";
import linkedinIcon from "../../../assets/linkedin1.svg";
import twitterIcon from "../../../assets/twitter1.svg";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import {Link, useNavigate } from "react-router-dom";


function FooterSectionHome() {
  const navigate = useNavigate();
  const footerData = {
    companyLinks: [
      {
        title: "About Us",
        url: "/about-us",
      },
      {
        title: "News",
        url: "/news",
      },
      {
        title: "Partners",
        url: "/partners",
      },
      {
        title: "Statistics",
        url: "/statistics",
      },
    ],
    resourcesLinks: [
      {
        title: "Case Study",
        url: "/case-study",
      },
      {
        title: "E-books",
        url: "/e-books",
      },
      {
        title: "Blogs",
        url: "/blogs",
      },
      {
        title: "Events",
        url: "/events",
      },
    ],
    serviceSupportLinks: [
      {
        title: "Training",
        url: "/training",
      },
      {
        title: "Learning",
        url: "/learning",
      },
      {
        title: "Hiring",
        url: "/hiring",
      },
      {
        title: "Hackathons",
        url: "/hackathons",
      },
      {
        title: "Events",
        url: "/events",
      },
      {
        title: "Customer Support",
        url: "/customerSupport",
      },
    ],
    contactLinks: [
      {
        title: "Demos",
        url: "/demos",
      },
      {
        title: "Branches",
        url: "/branches",
      },
      {
        title: "Units",
        url: "/units",
      },
    ],
  };
  return (
    <div className="footerContainer">
      <Slide left cascade delay={0} duration={1000}>
        <div style={{ width: "100%" }} className="row">
          <div className="col">
            <h5>India</h5>
            <ul>
              {/* {footerData.companyLinks.map((link, index) => (
            <li key={index}>
            <a href={link.url}> {link.title}</a> 
            </li>
          ))} */}
              IndiQube South Mile building, Vijayarangam Layout, Jayanagar,
              Bengaluru, Karnataka, 560070, India.
            </ul>
          </div>
          <div className="col cursor-pointer ">
            <h5>Company</h5>
            <div className="social-icons ">
              <Link
                to="/about-us"
                className="col-6 col-md-6 my-2 leading-8 subFooterTags "
              >
                About Us
              </Link>
              <br />
              <div className="col-6 col-md-6 my-2 leading-8 subFooterTags">
                Career
              </div>
              <br />
              <div className="col-5 col-md-5 my-2 leading-8 subFooterTags">
                Blogs
              </div>
              <br />
              <div className="col-4 col-md-4 my-2 leading-8 subFooterTags">
                FAQ's
              </div>
              <br />
              <Link
                to="/contactUs"
                className="col-5 col-md-5 my-2 leading-8 subFooterTags"
              >
                Contact
              </Link>
            </div>
          </div>
          <div className="col cursor-pointer ">
            <h5>Services</h5>
            <div className="social-icons ">
              <p
                onClick={() => {
                  navigate("/job-search");
                }}
                className="col-6 col-md-6 my-2 leading-8 subFooterTags"
              >
                Jobs
              </p>
              <br />
              <p className="col-6 col-md-6 my-2 leading-8 subFooterTags">
                Companies
              </p>
              {/* <br />
              <p className="col-5 col-md-5 my-2 leading-8 subFooterTags">
                Candidates
              </p> */}
              <br />
              <p className="col-4 col-md-4 my-2 leading-8 subFooterTags">
                Pricing
              </p>
              <br />
              <p className="col-5 col-md-5 my-2 leading-8 subFooterTags">
                Partner
              </p>
            </div>
          </div>

          <div className="col cursor-pointer">
            <h5>Support</h5>

            <div className="social-icons ">
              <p className="col-6 col-md-6 my-2 leading-8 subFooterTags">
                Privacy Policy
              </p>
              <br />
              <p className="col-6 col-md-6 my-2 leading-8 subFooterTags">
                Terms Of Use
              </p>
              <br />
              <p className="col-5 col-md-5 my-2 leading-8 subFooterTags">
                Help Center
              </p>
              <br />
              <p className="col-4 col-md-4 my-2 leading-8 subFooterTags">
                Updates
              </p>
              <br />
              <p className="col-5 col-md-5 my-2 leading-8 subFooterTags">
                Documentation
              </p>
            </div>
          </div>
          <div className="col cursor-pointer connectSection">
            <h5>Connect</h5>
            <ul className="social-icons" style={{ width: "50%" }}>
              <li className="col-6 col-md-6 my-2   subFooterTags ">
                <p className="flex mb-1.5">
                  <img src={fBIcon} />
                  <Link to="https://www.facebook.com/61552688134233/">{"  "}Facebook</Link>
                </p>
              </li>
              <li className="col-6 col-md-6    subFooterTags ">
                <p className="flex  mb-1.5">
                  {" "}
                  <img src={instagramIcon} />
                  <Link to="https://www.instagram.com/acrossgeo/">{"  "}Instagram</Link>
                </p>
              </li>
              <li className="col-5 col-md-5 my-2   subFooterTags">
                <p className="flex mb-1.5">
                  <img src={linkedinIcon} />
                  <Link to="https://www.linkedin.com/company/acrossgeo/">{"  "}Linkedin</Link>
                </p>
              </li>
              <li className="col-4 col-md-4 my-2   subFooterTags">
                <p className="flex mb-1.5">
                  <img src={twitterIcon} />
                  <Link to="">{"  "}Twitter</Link>
                </p>
              </li>
              <li className="col-6 col-md-6 my-2   subFooterTags">
                <p className="flex mb-1.5">
                  <img src={youtubeIcon} />
                  <Link to="https://www.youtube.com/channel/UCxO6KURoEvlU146DOP1mBlQ">{"  "}YouTube</Link>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </Slide>
    </div>
  );
}

export default FooterSectionHome;
