import React, { useState } from "react";
import "./JobPostCard.scss";
import { ReactComponent as JobIcon } from "../../../assets/briefcase.svg";
import { ReactComponent as ClientIcon } from "../../../assets/client.svg";
import { ReactComponent as RoleIcon } from "../../../assets/role.svg";
import { ReactComponent as LocationIcon } from "../../../assets/location.svg";
import { useNavigate } from "react-router-dom";

const JobPostCard = (props) => {
  const [openMapCompanyModal, setOpenMapCompanyModal] = useState(false);
  const closeMapCompanyModal = () => {
    setOpenMapCompanyModal(!openMapCompanyModal);
  };
  const navigate = useNavigate();
  const handleShareProfile = () => {
    navigate("/admin/jobposts/share-profile", {
      state: { requestedProfile: props.job },
    });
  };

  return (
    <div
      className="common-job-card "
      onClick={() => {
        if (props?.handelCardClick) {
          props.handelCardClick(props.job);
        }
      }}
      key={props.job.name + props.index}
    >
      <div className="leftCardItem grid grid-cols-3 gap-4 ">
        <div className="col-span-12 ">
          <div className="cmp-name">{props.job.companyName}</div>
        </div>
        <div className="col-span-12 flex">
          <span className="mr-2">
            <ClientIcon
              className="inline-block jobIcon"
              width={"20px"}
              height={"20px"}
            />
          </span>
          <div>
            <div className="job-label">Client Name</div>
            <div className="job-value datatrimmer">{props.job.clientName}</div>
          </div>
        </div>
        <div className="col-span-12 flex">
          <span className="mr-2">
            <RoleIcon
              className="inline-block jobIcon"
              width={"20px"}
              height={"20px"}
            />
          </span>
          <div>
            <div className="job-label">Role</div>
            <div
              className="job-value datatrimmer"
              title={props.job?.roles ? props.job.roles : "--"}
            >
              {props.job?.roles ? props.job.roles : "--"}
            </div>
          </div>
        </div>
        <div className="col-span-12 flex">
          <span className="mr-2">
            <JobIcon
              className="inline-block jobIcon"
              width={"20px"}
              height={"17px"}
            />
          </span>
          <div>
            <div className="job-label">Openings</div>
            <div
              className="job-value datatrimmer"
              title={props.job?.vacancyCount ? props.job.vacancyCount : "--"}
            >
              {props.job?.vacancyCount ? props.job.vacancyCount : "--"}
            </div>
          </div>
        </div>
        <div className="col-span-12 flex">
          <span className="mr-2">
            <LocationIcon
              className="inline-block jobIcon"
              width={"20px"}
              height={"17px"}
            />
          </span>
          <div>
            <div className="job-label">Location</div>
            <div
              className="job-value datatrimmer"
              title={props.job?.location ? props.job.location : "--"}
            >
              {props.job?.location ? props.job.location : "--"}
            </div>
          </div>
        </div>
        {/* <div className="col-span-12 flex">
          <div className="label">
            <RoomOutlinedIcon />
          </div>
          <div className="value  mx-2">{props.job.companyLocation}</div>
        </div> */}
      </div>
      <div className="job-placement">
        <div className="col-span-12 job-right-data-container mb-4">
          <div className="job-label">Key Skills</div>
          <div className="job-value datatrimmer">
            {props.job?.keySkills.map((skill) => {
              return (
                <span
                  key={skill}
                  className="each-Skill datatrimmer job-role pl-6"
                  title={skill}
                >
                  <span className="skills-icon mr-2"></span>
                  {skill}
                </span>
              );
            })}
          </div>
        </div>
        <div className="col-span-12 row-span-2 grid grid-cols-12 job-right-data-container">
          <div className="col-span-12">
            <div className="job-label pb-2 col-span-12 ">Job description</div>
            <div
              className="job-value desc-area pl-6 col-span-12 "
              title={
                props.job?.jobDescription ? props.job.jobDescription : "--"
              }
            >
              {props.job?.jobDescription ? props.job.jobDescription : "--"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPostCard;
