import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import "./FAQHome.scss";
import AskedQsnImg from "../website-assets/faqBGNew.png"
import { Fade } from 'react-reveal'
import RemoveIcon from '@mui/icons-material/Remove';

function FAQHome() {
    const qandA = [
        {
            "question": "1. How do I create an employer account with acrossgeo?",
            "answer": "It's easy to register for an account with acrossgeo. Using OTP, you can register and authenticate your mobile number."
        },
        {
            "question": "2. Can I post job for free?",
            "answer": "Yes, you may post jobs for free with us."
        },
        {
            "question": "3. Is there any limit on how many jobs can be posted?",
            "answer": "No, you may add as many job postings on acrossgeo as you like."
        },
        {
            "question": "4. What types of candidates can I find on acrossgeo?",
            "answer": "We have top-tier applicants who are knowledgeable in software development, software testing, devops, tech support, business operations, and more."
        },
        {
            "question": "5. What fundamentals should I use to shortlist candidates?",
            "answer": "You can review the candidate's education information and interview video to make a choice."
        },
        {
            "question": "6. Is there a chance for me to conduct another interview with acrossgeo candidates?",
            "answer": "Yes, based on your choices, we can set up one more interview with our candidates."
        }
    ]
    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };
    const data = JSON.parse(JSON.stringify(qandA))
    return (
        <div className='faqsurrounding'>
            <div className='flex'>
                <div>
                    <h2 className="faqHeader">Frequently Asked Questions</h2>
                </div>
                <div>
                    <div className='d-md-none'>
                        <Fade left delay={0} duration={1000}>
                            <div>
                                <img src={AskedQsnImg} />
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className='row flex '>
                <Fade left delay={0} duration={1000}>
                    <div className='col-4 d-none d-sm-block '>
                        <img src={AskedQsnImg} />
                    </div>
                </Fade>
                <Fade right delay={0} duration={1000}>
                    <div className='col-sm-12 col-md-8'>
                        {data.length && (
                            data.map((info, index) => {
                                return (
                                    <div className='mb-2'>
                                        <Accordion expanded={expanded === `panel${index}`}
                                            onChange={handleChange(`panel${index}`)}>
                                            <AccordionSummary
                                                expandIcon={
                                                    expanded === `panel${index}` ? (
                                                        <RemoveIcon fontSize='small' />
                                                    ) : (
                                                        <AddIcon fontSize='small' />
                                                    )
                                                }
                                                aria-controls={`panel${index}-content`}
                                                id={`panel${index}-header`}
                                            >
                                                <p className='question'>{info.question}</p>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <p className='answers'>
                                                    {info.answer}
                                                </p>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                )
                            })
                        )}
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default FAQHome