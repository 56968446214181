import React, { useState } from 'react'
import "./EmpCandCard.scss"
import EmployeeCard from "../website-assets/employeeCard.png"
import EmployeeImg from '../../../assets/divelementor-element.svg'
import EmpCard from "../website-assets/empCard.png"
import Fade from "react-reveal/Fade";
import LoginSignUpModal from '../website-signup-home/LoginSignUpModal';
import CategoriesList from '../find-categories/categories-list/CategoriesList';

function EmpCandCard() {
    const [openSignupModel, setSignupModel] = useState(false)
    return (
        <div className="empCandCardSection">
            <div className='m-5 p-4 mb-0 ml-2 grid cardContainer grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2  gap-4 '>
                <div>
                    <Fade left delay={0} duration={1000}>
                        <div className='flex justify-between  cardDesigne p-4 cardheight'>
                            
                                <div className='contents_card'>
                                    <div className='cardtitle'>
                                        <p>For Employers</p>
                                    </div>
                                    <div>
                                        <p className='carddiscription mt-3'>
                                            Find professionals from around the world and across all skills.
                                        </p>
                                    </div>
                                    <div className='cardBtn relative gapbetweenb2'>
                                        <button onClick={() => { setSignupModel(true) }}>Post jobs for Free</button>
                                    </div>
                                </div>
                      
                            <div className='yourDivClass'><img src={EmpCard} /></div>

                        </div>
                    </Fade>
                </div>
                <div>
                    <Fade right delay={0} duration={1000} className="flex justify-content-center">
                        <div className='flex justify-between cardDesigne p-4 cardheight '>
                            <div className='contents_card'>
                                <div className='cardtitle '>
                                    <p>For Candidate</p>
                                </div>
                                <div>
                                    <p className='carddiscription mt-3 '>
                                        Build your professional profile, find new job opportunities.
                                    </p>
                                </div>
                                <div className='cardBtn gapbetween '>
                                    <button onClick={() => { setSignupModel(true) }}>Upload your CV</button>
                                </div>
                            </div>
                            <div className='yourDivClass'><img src={EmployeeImg} style={{height: "166.42px",
    width: "195.38px"}}/></div>
                        </div>
                    </Fade>
                </div>
                {openSignupModel &&
                    <LoginSignUpModal clickMode={"login"} setIsOpenLoginModal={setSignupModel} />
                }
            </div>
            <CategoriesList />
        </div>
    )
}

export default EmpCandCard