import React, { useState } from "react";
import { SelectDropdown } from "../../../landing-page/common/select-dropdown";
import cx from "classnames";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect } from "react";
import { Checkbox } from "@material-ui/core";
import Select from "react-select";

const GenerateCompany = (props) => {
  let workHistory = props?.candidateData?.workHistories;
  let data = props.data;
  let index = props.index;
  let lastIndex = props.companyIndexs.length - 1;
  let companyField = `company${data}`;
  let companyNameField = `companyName${data}`;
  let jobTitleField = `jobTitle${data}`;
  let cityField = `city${data}`;
  let workingFromField = `workingFrom${data}`;
  let workingTillField = `workingtill${data}`;
  let currentCompanyField = `currentCompany${data}`;
  const [startField, setStartField] = useState("");
  const [endField, setEndField] = useState("");

  useEffect(() => {
    // Reset the start date when candidateData or formik.values.currentEmp changes
    if (
      props.formik.values[companyField] === "" &&
      props.formik.values[jobTitleField] === "" &&
      props.formik.values[cityField] === ""
    ) {
      setStartField("");
      setEndField(""); // Reset startDate when "I'm a fresher" is selected
      props.setCurrentCompanyErrorHandler("");
    }
  }, [props.candidateData, props.formik.values.currentEmp]);
  useEffect(() => {
    let currentdata = props.formik?.values?.[workingFromField]
      ? props.formik.values[workingFromField]
      : workHistory?.[data];
    let fromdate = "";
    if (currentdata?.startDate) {
      let dateArray = currentdata.startDate.split("-");
      fromdate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
    }
    let todate = "";
    if (currentdata?.endDate) {
      let dateArray = currentdata.endDate.split("-");
      todate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
    }
    setEndField(todate);
    setStartField(fromdate);
  }, []);
  let cityData = [...props.dropDownData.city]
  let companyDetailsCityArr = () => {
    let data =[]
    cityData.map((city) => {
      if (city.value !== "Any Location") {
        data.push(city)
      }
    })
    return data;
  }
  return (
    <div className="col-span-3 body grid grid-cols-3">
      <div className="mt-2 individual-field">
        <div className="mb-2 label">
          <span className="create-labels">Company </span>
        </div>
        <div className="input-ele">
          <Select
            dpName={companyField}
            options={props.dropDownData.company}
            onChange={(data) => {
              props.handleDropdownChange(
                [data],
                `company${props.data}`,
                [`company${props.data}`],
                props.data,
                props.formik
              );
            }}
            placeholder="Select a Company Name"
            onMenuOpen={props.selectedDropdownData[companyField]}
            value={props.selectedDropdownData[companyField]}
          //  value={Array.isArray(props.selectedDropdownData[companyField]) ? props.selectedDropdownData[companyField][0]?.value == ""? null : props.selectedDropdownData[companyField] : null }
            isSearchable={true}
          />
        </div>
        {props.formik.errors?.[companyField] &&
        props.formik.touched?.[companyField] ? (
          <div className="text-red-500 error_text md:mt-1">
            {props.formik.errors?.[companyField]}
          </div>
        ) : null}
      </div>
      {Array.isArray(props?.selectedDropdownData[companyField]) && props?.selectedDropdownData[companyField].length > 0 && props?.selectedDropdownData[companyField][0]?.value === "others" && (
        <div className="mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Company Name</span>
          </div>
          <div className="popins-medium input-ele">
            <TextField
              error={
                props.formik.errors?.[companyNameField] &&
                props.selectedDropdownData[companyNameField]
              }
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onClick={() => {}}
              type="text"
              name={companyNameField}
              inputProps={{
                className: cx(
                  `!text-xs open-sans-regular boxedInput popins-medium`
                ),
                style: {
                  padding: "9.5px 14px",
                  height: "0.43em !important",
                  fontSize: "12px",
                  fontFamily: "Poppins-Medium",
                  color: "#525252",
                },
              }}
              autoComplete="off"
              placeholder="Enter the Company Name"
              onBlur={props.formik.handleBlur}
              onChange={(data) => {
                props.formik.handleChange(data);
              }}
              value={props.formik?.values?.[companyNameField]}
            />
          </div>
          {props.formik.errors?.[companyNameField] &&
          props.formik.touched?.[companyNameField] ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors?.[companyNameField]}
            </div>
          ) : null}
        </div>
      )}
      <div className="mt-2 individual-field">
        <div className="mb-2 label">
          <span className="create-labels">Job Title</span>
        </div>
        <div className="input-ele">
          <SelectDropdown
            data={props.dropDownData.jobTitle}
            onChange={(data) => {
              props.handleDropdownChange(data, jobTitleField);
            }}
            selectedData={Array.isArray(props.selectedDropdownData[jobTitleField]) ? props.selectedDropdownData[jobTitleField][0]?.value == ""? null : props.selectedDropdownData[jobTitleField] : null }
            deselection={true}
            searchable={true}
          />
        </div>
        {props.formik.errors?.[jobTitleField] &&
        props.formik.touched?.[jobTitleField] ? (
          <div className="text-red-500 error_text md:mt-1">
            {props.formik.errors?.[jobTitleField]}
          </div>
        ) : null}
      </div>
      <div className="mt-2 individual-field">
        <div className="mb-2 label">
          <span className="create-labels">City</span>
        </div>
        <div className="input-ele">
          <SelectDropdown
            
            data={companyDetailsCityArr()}
            onChange={(data) => {
              props.handleDropdownChange(data, cityField);
            }}
            selectedData={Array.isArray(props.selectedDropdownData[cityField]) ? props.selectedDropdownData[cityField][0]?.value == ""? null : props.selectedDropdownData[cityField] : null }
            backspaceDelete={true}
            deselection={true}
            searchable={true}
          />
        </div>
        {props.formik.errors?.[cityField] &&
        props.formik.touched?.[cityField] ? (
          <div className="text-red-500 error_text md:mt-1">
            {props.formik.errors?.[cityField]}
          </div>
        ) : null}
      </div>
      <div className="mt-2 individual-field">
        <div className="mb-2 label">
          <span className="create-labels">Start Date</span>
        </div>
        <div className="multi-input-eles">
          <div className="input-ele">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(new Date(startField))}
                defaultValue={dayjs(new Date(startField))}
                id="dob"
                name="dob"
                onChange={(newValue) => {
                  props.formik.setFieldValue(
                    workingFromField,
                    dayjs(newValue).format("DD-MM-YYYY")
                  );
                  setStartField(newValue);
                }}
                format="DD-MM-YYYY"
                className="date-picker"
                disableFuture={true}
                slotProps={{
                  textField: {
                    readOnly: true,
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
        {props.formik.errors?.[workingFromField] &&
        props.formik.touched?.[workingFromField] ? (
          <div className="text-red-500 error_text md:mt-1">
            {props.formik.errors?.[workingFromField]}
          </div>
        ) : null}
      </div>
      <div className="mt-2 individual-field">
        <div className="mb-2 label">
          <span className="create-labels">End Date</span>
        </div>
        <div className="multi-input-eles">
          {data === props.currentCompanyIndex &&  props.formik.values[`currentCompany${data}`] ? (
            <div className="popins-medium input-ele">
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={() => {}}
                type="text"
                name="dob"
                id="dob"
                inputProps={{
                  className: cx(
                    `!text-xs open-sans-regular boxedInput popins-medium`
                  ),
                  style: {
                    padding: "9.5px 14px",
                    height: "0.43em !important",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    color: "#525252",
                  },
                }}
                autoComplete="off"
                disabled={true}
                placeholder="Enter your last working date"
                value="Present"
              />
            </div>
          ) : (
            <div className="input-ele">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={ endField != "" ? dayjs(new Date(endField)) : ""}
                  defaultValue={dayjs(new Date(endField))}
                  id="dob"
                  name="dob"
                  onChange={(newValue) => {
                    props.formik.setFieldValue([workingTillField], dayjs(newValue).format("DD-MM-YYYY"))
                    setEndField(newValue);
                  }}
                  format="DD-MM-YYYY"
                  className="date-picker"
                  slotProps={{
                    textField: {
                      readOnly: true,
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          )}
        </div>
        {props.formik.errors?.[workingTillField] &&
        props.formik.touched?.[workingTillField] ? (
          <div className="text-red-500 error_text md:mt-1">
            {props.formik.errors?.[workingTillField]}
          </div>
        ) : null}
      </div>
      {props.currentEmployed && (
        <div className="mt-2 individual-field">
          <div className="mt-8 label">
            <span
              className={`${
                !(
                  props.currentCompanyIndex === -1 ||
                  data === props.currentCompanyIndex
                )
                  ? "opacity-50"
                  : ""
              }`}
            >
              Is this the current company
            </span>
            {console.log(props.formik,"formik")}
            <Checkbox
              color="primary"
              value={props.formik.values?.[currentCompanyField]}
              disabled={
                !(
                  props.currentCompanyIndex === -1 ||
                  data === props.currentCompanyIndex
                )
              }
              checked={props.formik.values?.[currentCompanyField]}
              onChange={(e) => {
                props.formik.values[currentCompanyField] = e.target.checked ;
                if (e.target.checked) {
                  props.formik.setFieldValue([workingTillField], "Present");
                  props.setCurrentCompanyIndex(data);
                  props.setCurrentCompanyErrorHandler("");
                  setEndField("");
                } else {
                  props.formik.values[currentCompanyField] = e.target.checked ;
                  props.setCurrentCompanyIndex(-1);
                  props.formik.values[workingTillField] = "";
                }
              }}
            />
          </div>
          {props.formik.errors?.[currentCompanyField] &&
          props.formik.touched?.[currentCompanyField] ? (
            <div className="text-red-500 error_text md:mt-1">
              {props.formik.errors?.[currentCompanyField]}
            </div>
          ) : null}
        </div>
      )}
      {(props.companyIndexs[lastIndex] === data ||
        props.companyIndexs.length > 1) && (
        <div className="col-span-3">
          {props.companyIndexs[lastIndex] === data && (
            <button
              onClick={() => {
                props.formik.validateField(`${"company" + data}`);
                props.formik.validateField(`${"jobTitle" + data}`);
                props.formik.validateField(`${"city" + data}`);
                props.formik.validateField(`${"workingFrom" + data}`);
                props.formik.validateField(`${"workingtill" + data}`);
                props.addCompany(data, index);
              }}
              className="datatrimmer add-company-btn"
            >
              + Company
            </button>
          )}
          {props.companyIndexs.length > 1 && (
            <button
              onClick={() => {
                props.dltCompany(data, index);
                // if(props.currentCompanyIndex > 0) {
                //   props.setCurrentCompanyIndex(data + 1);
                // }
              }}
              className={`${
                data === props.currentCompanyIndex ? "opacity-50 " : ""
              } datatrimmer mr-2 dlt-company-btn p-1`}
              disabled={data === props.currentCompanyIndex}
            >
              Delete Company
            </button>
          )}
        </div>
      )}
    </div>
  );
};
const CompanyForm = (props) => {
  const [currentCompanyIndex, setCurrentCompanyIndex] = useState(-1);
  useEffect(() => {
    let workHistory = props.candidateData?.workHistories;
    if (
      workHistory !== undefined &&
      workHistory !== null &&
      workHistory.length > 0
    ) {
      workHistory.forEach((obj, index) => {
        if (obj.thisCurrentCompany) {
          setCurrentCompanyIndex(index);
        }
      });
    }
  }, []);
  useEffect(() => {
    //future
  }, [currentCompanyIndex]);
  return (
    <div className="col-span-3 grid grid-cols-3">
      <div className="mt-2 individual-field col-span-3">
        <div className="mb-2 label">
          <span className="create-labels">Are your currently employed</span>
        </div>
        <div className="popins-medium input-ele flex" style={{width:"30%"}}>
          <div
            onClick={() => {
              props.formik.setFieldValue("currentEmp", true);
            }}
            className={`${
              props.formik.values.currentEmp ? "selectedGender" : ""
            } genderField `}
          >
            Yes
          </div>
          <div
            onClick={() => {
              props.formik.setFieldValue("currentEmp", false);
              props.formik.setFieldValue(
                "currentCompany" + currentCompanyIndex,
                false
              );
              props.formik.setFieldValue(
                "workingtill" + currentCompanyIndex,
                ""
              );
              props.setCurrentCompanyErrorHandler("");
              setCurrentCompanyIndex(-1);
            }}
            className={`${
              !props.formik.values.currentEmp ? "selectedGender" : ""
            } genderField`}
          >
            No
          </div>
        </div>
        {props.formik.errors.currentEmp && props.formik.touched.currentEmp ? (
          <div className="text-red-500 error_text md:mt-1">
            {props.formik.errors.currentEmp}
          </div>
        ) : null}
      </div>
      <div className="company-form col-span-3 grid grid-cols-3">
        <div className="col-span-3 heading"> Company Details</div>
        {props.companyIndexs.map((data, index) => (
          <GenerateCompany
            data={data}
            key={data}
            index={index}
            dropDownData={props.dropDownData}
            companyIndexs={props.companyIndexs}
            handleCompanyDropdownChange={props.handleCompanyDropdownChange}
            handleDropdownChange={props.handleDropdownChange}
            selectedDropdownData={props.selectedDropdownData}
            addCompany={props.addCompany}
            candidateData={props.candidateData}
            dltCompany={props.dltCompany}
            formik={props.formik}
            currentCompanyIndex={currentCompanyIndex}
            setCurrentCompanyIndex={setCurrentCompanyIndex}
            currentEmployed={props.formik.values.currentEmp}
            setCurrentCompanyErrorHandler={props.setCurrentCompanyErrorHandler}
          />
        ))}
      </div>
    </div>
  );
};

export default CompanyForm;
