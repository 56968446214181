import React, { useState } from "react";
import Modal from "react-modal";
import "react-phone-input-2/lib/material.css";
import { Button, Grid, Typography } from "@material-ui/core";
import "./BDdetailsModule.scss";

function BDdetailsModule(props) {
  const [bdDetails, setbdDetails] = useState(true);
  console.log(props.detailData, "props.detailData");
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
      height: "auto",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
      outline: "none",
      index: "9999",
    },
  };

  const companyDetails = () => {
    let details;
    let saperator;
    if (props?.detailData?.companyNames) {
      details = props?.detailData?.companyNames?.map((ele, ind) => {
        return (
          <span className="p-0.5">
            {ele}
            {","}
          </span>
        );
      });
    }
    console.log(details);
    return details;
  };
  const domainDetails = () => {
    let details;
    if (props?.detailData?.domains) {
      details = props?.detailData?.domains?.map((ele, ind) => {
        return (
          <span className="p-2">
            {ele}
            {","}
          </span>
        );
      });
    }
    console.log(details);
    return details;
  };

  const displayBDDetails = () => {
    if (props?.detailData) {
      return <div>{companyDetails()}</div>;
    }
  };
  const displayInterviewerDomain = () => {
    if (props?.detailData) {
      return <div>{domainDetails()}</div>;
    }
  };

  return (
    <Modal
      isOpen={bdDetails}
      style={customStylesPage}
      className="p-px"
      ariaHideApp={false}
    >
      <div className="addInterviewerModal userDetailsModal">
        <div className="flex justify-between create-modal-heading header-Font  p-2">
          <p>{props?.detailData?.name} Details</p>
        </div>
        <Grid
          className="p-4"
          container
          rowSpacing={4}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid className="py-2 flex-items-center" item xs={4}>
            <div>
              <Typography variant="h6" className="semiBoldpoppins">Name</Typography>
              <Typography className="labels">
                {props?.detailData?.name}
              </Typography>
            </div>
          </Grid>
          <Grid className="py-2 flex-items-center" item xs={4}>
            <div>
              <Typography variant="h6" className="semiBoldpoppins">Mobile</Typography>
              <Typography className="labels">
                {props?.detailData?.mobile}
              </Typography>
            </div>
          </Grid>
          <Grid
            className="pr-4 flex-items-center"
            item
            xs={4}
            title={props?.detailData?.email}
          >
            <Typography variant="h6" className="semiBoldpoppins">Email</Typography>
            <Typography className="datatrimmer labels">
              {props?.detailData?.email ? props?.detailData?.email : "-"}
            </Typography>
          </Grid>
          <Grid className="py-2 flex-items-center" item xs={4}>
            <Typography variant="h6" className="semiBoldpoppins">Gender</Typography>
            <Typography className="labels">
              {props?.detailData?.gender}
            </Typography>
          </Grid>
          {props.usertype === "interviewer" ? (
            <Grid item className="py-2 flex-items-center" xs={12}>
              <Typography variant="h6" className="semiBoldpoppins">Domain</Typography>
              <Typography className="labels">
                {props?.detailData && displayInterviewerDomain()}
              </Typography>
            </Grid>
          ) : props.usertype === "BD" ? (
            <Grid item className="py-2 flex-items-center" xs={12}>
              <Typography variant="h6" className="semiBoldpoppins">
                {props.usertype != "InterviewCoordinator"
                  ? "Company Details"
                  : ""}
              </Typography>
              <Typography className="labels">
                {props?.detailData && displayBDDetails()}
              </Typography>
            </Grid>
          ) : (
            ""
          )}
          <Grid className="py-2 flex-items-center" item xs={4}>
            <Typography variant="h6" className="semiBoldpoppins">Created By</Typography>
            <Typography className="labels">
              {props?.detailData?.createdByUname}
            </Typography>
          </Grid>

          <Grid className="py-2 flex-items-center" item xs={4}>
            <Typography variant="h6" className="semiBoldpoppins">Created On</Typography>
            <Typography className="labels">
              {props?.detailData?.createdOn &&
                props.detailData.createdOn.split(" ")[0] +
                  " || " +
                  props.detailData.createdOn.split(" ")[1]}
            </Typography>
          </Grid>
          <Grid className="py-2 flex-items-center" item xs={4}>
            <Typography variant="h6" className="semiBoldpoppins">Modified By</Typography>
            <Typography className="labels">
              {props?.detailData?.modifiedByUname}
            </Typography>
          </Grid>
          <Grid className="py-2 flex-items-center" item xs={4}>
            <Typography variant="h6" className="semiBoldpoppins">Modified On</Typography>
            <Typography className="labels">
              {props?.detailData?.modifiedOn &&
                props.detailData.modifiedOn.split(" ")[0] +
                  " || " +
                  props.detailData.modifiedOn.split(" ")[1]}
            </Typography>
          </Grid>
          <Grid className="py-2 flex-items-center" item xs={4}>
            <Typography variant="h6" className="semiBoldpoppins">Status</Typography>
            {props?.detailData?.status === "DISABLED" ? (
              <p style={{ color: "red" }} className="labels">
                {props?.detailData?.status}
              </p>
            ) : props?.detailData?.status === "ACTIVE" ? (
              <p style={{ color: "green" }} className="labels">
                {props?.detailData?.status}
              </p>
            ) : (
              <Typography className="labels">
                {props?.detailData?.status}
              </Typography>
            )}
          </Grid>
        </Grid>
        <div className="float-right m-1 submitButton">
          <button onClick={() => setbdDetails(props.setbdDetails(false))}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}
export default BDdetailsModule;
