import React, { useEffect, useState } from "react";
import "./JobPostDetails.scss";
import { useLocation,useParams  } from "react-router-dom";
import NavigationHeader from "../nav-section/NavigationHeader";
import FooterSectionHome from "../footer-section/FooterSectionHome";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ReactComponent as LocationIcon } from "../website-assets/greenLocation.svg";
import { ReactComponent as Calender } from "../website-assets/calendar2Icom.svg";
import { ReactComponent as LocationOnIcon } from "../website-assets/location_on_icon.svg";
import { NavLink } from "react-router-dom";
import LoginSignUpModal from "../website-signup-home/LoginSignUpModal";
import { getClientCompanyDetails,getJobPostByID } from "../../../api/api-services";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SalaryIcon } from "../website-assets/salaryIcon.svg";
import { ReactComponent as ExperienceIcon } from "../website-assets/experienceIcon.svg";
import { ReactComponent as PositionIcon } from "../website-assets/positionIcon.svg";
import { ReactComponent as QualificationIcon } from "../website-assets/qualificationIcon.svg";
import { ReactComponent as CareerIcon } from "../website-assets/careerIcon.svg";
const JobPostDetails = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { jobId } = useParams();
  
  const [companyDetail, setCompanyDetail] = useState([]);
  const [jobPostDetails,setJobPostDetails]=useState({})
  const { getSemilarJobPost } = state || {};

  function toCamelCase(inputString) {
    return inputString.replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  }

  console.log({ state });
  function handleClick(event) {
    // event.preventDefault();
    console.info(event, "You clicked a breadcrumb.");
  }
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const breadcrumbs = [
    <NavLink
      underline="hover"
      key="1"
      color="inherit"
      to="/"
      onClick={handleClick}
    >
      Home
    </NavLink>,
    <NavLink
      underline="hover"
      key="2"
      color="inherit"
      to="/job-search"
      onClick={handleClick}
    >
      Jobs
    </NavLink>,

    <Typography key="4" color="text.primary">
      {jobPostDetails.jobHeadlines}
    </Typography>,
  ];
  const descriptionParts =
  jobPostDetails && jobPostDetails.jobDescription
    ? jobPostDetails.jobDescription.split(/\d+\./)
    : [];
    const filteredSimilarJobCards = getSemilarJobPost
    ? getSemilarJobPost.filter((jobCard) => jobCard.id !== jobPostDetails.id)
    : [];
  
  console.log({ filteredSimilarJobCards });
  const fetchCompanyDetails = async (companyName) => {
    try {
      const response = await getClientCompanyDetails(companyName);
      console.log(response?.data?.response);
      if (response?.data?.statusCode === 200) {
        let companyDetails = response?.data?.response;
        setCompanyDetail(companyDetails);
      }
    } catch (error) {
      console.error("Error occurred while fetching company details:", error);
    }
  };
  const fetchIndividualJobPostDetails = async () => {
    try {
      const response = await getJobPostByID(jobId);
      console.log(response?.data?.response);
      if (response?.data?.statusCode === 200) {
        setJobPostDetails(response?.data?.response)
        
      }
    } catch (error) {
      console.error("Error occurred while fetching company details:", error);
    }
  };
  
  useEffect(() => {
    console.log({jobPostDetails})
    if (jobPostDetails && jobPostDetails.companyName) {
      fetchCompanyDetails(jobPostDetails.companyName);
    }
  }, [jobPostDetails]);
  useEffect(() => {
    fetchIndividualJobPostDetails();
  }, [jobId]);


  const formattedDate = jobPostDetails?.createdOn?.split(" ")[0];
  const formatQualification = (qualification) => {
    if (!qualification) {
      return "";
    }

    // Replace underscores with spaces and capitalize words
    const formattedString = qualification
      .replace(/_/g, " ")
      .replace(/_./g, (match) => match.charAt(1).toUpperCase());

    // Ensure the first character is capitalized and the rest are lowercase
    return (
      formattedString?.charAt(0).toUpperCase() +
      formattedString?.slice(1).toLowerCase()
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on component mount

    const handleRefresh = () => {
      window.scrollTo(0, 0); // Scroll to the top on page refresh
    };

    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, []);
  return (
    <div className="jobPostDetails ">
      <div className="navbarSection-jobDetails">
        <NavigationHeader />
      </div>
      <div className="jobPostDetails-Container">
        <div className="breadCrumbSection">
          <Stack spacing={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="row no-gutters joblistDetails">
          <div className="col-12 col-sm-6 col-md-7 job-left-container">
            <div className="job-left-container-details">
              <div className="headerDetails">
                <div className="card-header flex">
                  <div style={{ width: "65px", paddingRight: "5px" }}>
                    <span className="companyIcon">
                      {jobPostDetails?.companyName?.slice(0, 2)}
                    </span>
                  </div>
                  <div className="headerInfo_jobs text-sm sm:text-base md:text-lg lg:text-xl">
                    {" "}
                    <label className="jobName">
                      {jobPostDetails.jobHeadlines?.length > 40
                        ? `${jobPostDetails?.jobHeadlines?.slice(0, 40)}...`
                        : jobPostDetails?.jobHeadlines}
                    </label>
                    <br />
                    <div className="card-sub-header mt-0.5">
                      <p className="text-sm sm:text-base md:text-lg lg:text-xl">
                        by{" "}
                        <label className="mx-1 text-sm sm:text-base md:text-lg lg:text-xl">
                          {jobPostDetails.companyName}{" "}
                        </label>{" "}
                        in{" "}
                        <label className="text-sm sm:text-base md:text-lg lg:text-xl">
                          {" "}
                          {jobPostDetails.functionalArea?.length > 55
                            ? `${jobPostDetails?.functionalArea?.slice(0, 50)}...`
                            : jobPostDetails?.functionalArea}
                        </label>
                      </p>
                    </div>{" "}
                    <div className="card-content flex ">
                      <div className="card-content-One ">
                        {" "}
                        <p className="">
                          {jobPostDetails?.employmentType &&
                            toCamelCase(jobPostDetails?.employmentType
                              .replace(/_/g, " ")
                              .toLowerCase())
                          }
                        </p>
                      </div>
                      <div className="card-content-two mx-1">
                        <p className=" text-blue-700 flex ">
                          <LocationIcon
                            height="13px"
                            width="12px"
                            className=""
                          />{" "}
                          {jobPostDetails?.location?.[0]}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="jobCard-body">
                <label className="firstheading">Job role insights</label>
                <div className="container-fluid jobCard-body-container">
                  <div className="row jobCard-body-row">
                    <div className="col-sm col-xxl-3 col-xl-4 col-lg-4 col-md-6 my-3 flex">
                     <div> <Calender height={"24px"} width={"24px"} /></div>
                      <div className="grid jobCard-body-data">
                        <label className="labelStyle">Date posted</label>
                        <label className="valueStyle">{formattedDate}</label>
                      </div>
                    </div>
                    <div className="col-sm col-xxl-3 col-xl-4 col-lg-4 col-md-6 my-3  flex">
                      <Calender height={"24px"} width={"24px"} />
                      <div className="grid jobCard-body-data">
                        <label className="labelStyle">Closing date</label>
                        <label className="valueStyle">
                          {jobPostDetails?.dateForSharingProfiles?.replace(
                            /\//g,
                            "-"
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm col-xxl-3 col-xl-4 col-lg-4 col-md-6 my-3  flex">
                      <LocationOnIcon height={"24px"} width={"24px"} />
                      <div
                        className="grid jobCard-body-data cursor-pointer"
                        title={jobPostDetails?.location}
                      >
                        <label className="labelStyle cursor-pointer">
                          Hiring location
                        </label>
                        <label className="valueStyle cursor-pointer">
                          {" "}
                          {jobPostDetails?.location?.[0]}
                        </label>
                      </div>
                    </div>
                  
                 
                    <div className="col-sm col-xxl-3 col-xl-4 col-lg-4 col-md-6 my-3  flex">
                      <SalaryIcon height={"24px"} width={"24px"} />
                      <div className="grid jobCard-body-data">
                        <label className="labelStyle">Offered salary</label>
                        <label className="valueStyle">
                          {jobPostDetails?.salaryRange?.min +
                            "-" +
                            jobPostDetails?.salaryRange?.max}
                          /year
                        </label>
                      </div>
                    </div>
                    {/* <div className="col-sm flex">
                      <CareerIcon height={"24px"} width={"24px"} />
                      <div className="grid jobCard-body-data">
                        <label className="labelStyle">Career level</label>
                        <label className="valueStyle">
                        {jobPostDetails?.roles}
                        </label>
                      </div>
                    </div> */}
                    <div className="col-sm col-xxl-3 col-xl-4 col-lg-4 col-md-6 my-3  flex">
                      <CareerIcon height={"24px"} width={"24px"} />
                      <div className="grid jobCard-body-data">
                        <label className="labelStyle">Career level</label>
                        <label className="valueStyle">
                          {jobPostDetails?.roles}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm col-xxl-3 col-xl-4 col-lg-4 col-md-6 my-3  flex">
                      <QualificationIcon height={"24px"} width={"24px"} />
                      <div className="grid jobCard-body-data">
                        <label className="labelStyle">Qualification</label>
                        <label className="valueStyle">
                          {formatQualification(
                            jobPostDetails?.highestQualification
                          )}
                        </label>
                      </div>
                    </div>
                 
               
                    <div className="col-sm col-xxl-3 col-xl-4 col-lg-4 col-md-6 my-3  flex">
                      <ExperienceIcon height={"24px"} width={"24px"} />
                      <div className="grid jobCard-body-data">
                        <label className="labelStyle">Experience</label>
                        <label className="valueStyle">
                          {jobPostDetails?.workExperience?.min +
                            "-" +
                            jobPostDetails?.workExperience?.max}{" "}
                          year
                        </label>
                      </div>
                    </div>
                    <div className="col-sm col-xxl-3 col-xl-4 col-lg-4 col-md-6 my-3  flex">
                      <PositionIcon height={"24px"} width={"24px"} />
                      <div className="grid jobCard-body-data">
                        <label className="labelStyle">No. of Positions</label>
                        <label className="valueStyle">
                          {jobPostDetails?.vacancyCount}
                        </label>
                      </div>
                    </div>
                    {/* <div className="col-sm col-xxl-3 col-xl-4 col-lg-4 col-md-6 my-3  flex invisible">
                      <Calender height={"24px"} width={"24px"} />
                      <div className="grid jobCard-body-data">
                        <label className="labelStyle">&nbsp;</label>
                        <label className="valueStyle">&nbsp;</label>
                      </div>
                    </div> */}
                    </div>
                </div>
              </div>

              <div className="cardDescription">
                <div className="requirementContent">
                  <label className="firstheading">Description</label>
                  <div className="requirementDetails">
                    <ul>
                      {descriptionParts.map(
                        (part, index) =>
                          part.trim() !== "" && (
                            <li key={index}>
                              {/* {index + 1}.  */}
                              {part.trim()}
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                </div>

                <div className="skillsContent">
                  <label className="firstheading">Skills</label>
                  <div className="skillDetails row ">
                  {jobPostDetails?.keySkills && jobPostDetails.keySkills.length > 0 && (
  <div className="skillDetails row">
    {jobPostDetails.keySkills.map((skill) => (
      <div
        key={skill}
        className="card-content-two-skills mx-2 col-sm-6 col-md-6 my-3 datatrimmer"
        title={skill}
      >
        {skill}
      </div>
    ))}
  </div>
)}

                  </div>
                </div>
              </div>
            </div>
            {/* <div className="intrestedJob">
              <div class="row  intrestedJobBody">
                <div class="col col-md-8 col-sm-12 grid detailsData">
                  <label>Interested in this job?</label>
                  <label>
                    <p className=" ">
                      {jobPostDetails?.postedAgo === 0 ? (
                        <span className="dayText"> Posted today</span>
                      ) : (
                        <>
                          <span className="dayText">Posted </span>
                          <span className="dayValue">
                            {" "}
                            {jobPostDetails?.postedAgo}
                          </span>{" "}
                          <span className="dayText"> days ago</span>
                        </>
                      )}
                    </p>
                  </label>
                </div>
                <div class="col col-md-4 col-sm-12 applyBtnContainer">
                  <button
                    className="applyBtn"
                    onClick={() => {
                      setIsOpenLoginModal(true);
                    }}
                  >
                    Apply now
                  </button>
                </div>
              </div>
            </div> */}


            <div className="similarJobSection">
            {filteredSimilarJobCards.length > 0 && (
  
    <label className="firstheading">Similar jobs</label>

)}
              {/* <label className="firstheading">Similar jobs</label> */}
              <div className="similarJobCard">
                <div className="JobCard-body">
                  <div className="grid cardContainer ">
                    {filteredSimilarJobCards &&
                      (filteredSimilarJobCards?.length >= 4
                        ? filteredSimilarJobCards?.slice(0, 3)
                        : filteredSimilarJobCards
                      ).map((jobCard) => (
                        <div
                          className="card cursor-pointer"
                          onClick={() => {
                            navigate(`/job-details/${jobCard.id}`, {
                              state: {
                                
                                getSemilarJobPost: getSemilarJobPost,
                              },
                             
                            });
                            window.scrollTo(0, 0);
                          }}
                          key={jobCard.id}
                        >
                          <div className="card-header d-block d-lg-flex">
                            <div className="flex">
                            <div style={{ width: "65px", paddingRight: "5px" }}>
                              <span className="companyIcon">
                                {jobCard.companyName?.slice(0, 2)}
                              </span>
                            </div>
                            <div className="headerInfo_jobs text-sm sm:text-base md:text-lg lg:text-xl">
                              {" "}
                              <label>
                                {jobCard.jobHeadlines?.length > 40
                                  ? `${jobCard.jobHeadlines?.slice(0, 40)}...`
                                  : jobCard.jobHeadlines}
                              </label>
                              <br />
                              <div className="card-sub-header flex mt-0.5">
                                <p className="text-sm sm:text-base md:text-lg lg:text-xl">
                                  by{" "}
                                  <label className="mx-1 text-sm sm:text-base md:text-lg lg:text-xl">
                                    {jobCard.companyName}{" "}
                                  </label>{" "}
                                  in{" "}
                                  <label className="text-sm sm:text-base md:text-lg lg:text-xl">
                                    {" "}
                                    {jobCard.functionalArea?.length > 55
                                      ? `${jobCard.functionalArea?.slice(
                                          0,
                                          50
                                        )}...`
                                      : jobCard.functionalArea}
                                  </label>
                                </p>
                              </div>{" "}
                             
                            </div>
                            </div>
                            <button
            className="ml-auto apply-now-button"
            onClick={(e) => {
                      setIsOpenLoginModal(true);
                      
                    }}
          >
            Apply 
          </button>
                          </div>

                          <div className="card-content flex relative top-1.5">
                            <div className="card-content-One ">
                              {" "}
                              <p className="">
                                {jobCard?.employmentType &&
                                  toCamelCase(jobCard?.employmentType
                                    .replace(/_/g, " ")
                                    .toLowerCase())
                                  }
                              </p>
                            </div>
                            <div className="card-content-two mx-1">
                              <p className=" text-blue-700 flex ">
                                <LocationIcon
                                  height="12px"
                                  width="12px"
                                  className=" "
                                />{" "}
                                {jobCard?.location?.[0]}
                              </p>
                            </div>
                            <div className="card-content-three">
                              <p className="mx-1">
                                {jobCard?.salaryRange &&
                                  `${jobCard?.salaryRange.min} - ${jobCard?.salaryRange.max}/Year`}
                              </p>
                            </div>
                            <div>
                              {jobCard?.postedAgo === 0 ? (
                                <div className="card-footer flex">
                                  <p className=" ">
                                    {/* {formatDate(jobCard?.dateForSharingProfiles)} */}
                                    <span className="dayText">
                                      {" "}
                                      Posted today
                                    </span>
                                  </p>
                                </div>
                              ) : (
                                <div className="card-footer flex">
                                  <p className=" ">
                                    {/* {formatDate(jobCard?.dateForSharingProfiles)} */}
                                    <span className="dayText">Posted </span>
                                    <span className="dayValue">
                                      {" "}
                                      {jobCard?.postedAgo}
                                    </span>{" "}
                                    <span className="dayText"> days ago</span>
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5  job-right-container">
            <div className="intrestedJob">
              <div class="row  intrestedJobBody">
                <div class="col col-md-8 col-sm-12 grid detailsData">
                  <label>Interested in this job?</label>
                  <label>
                    {console.log(jobPostDetails,"jobPostDetails?.postedAgo")}
                    <p className="datePosted ">
                      {jobPostDetails?.postedAgo === 0 ? (
                        <span className="dayText"> Posted today</span>
                      ) : (
                        <>
                          <span className="dayText">Posted </span>
                          <span className="dayValue">
                            {" "}
                            {jobPostDetails?.postedAgo}
                          </span>{" "}
                          <span className="dayText"> days ago</span>
                        </>
                      )}
                    </p>
                  </label>
                </div>
                <div class="col col-md-4 col-sm-12 applyBtnContainer">
                  <button
                    className="applyBtn"
                    onClick={() => {
                      setIsOpenLoginModal(true);
                    }}
                  >
                    Apply now
                  </button>
                </div>
              </div>
            </div>

            <div className="companyInfo">
              <div className="headerDetails">
                <div className="card-header flex">
                  <div style={{ width: "65px", paddingRight: "5px" }}>
                    <span className="companyIcon">
                      {companyDetail?.name?.slice(0, 2)}
                    </span>
                  </div>
                  <div className="headerInfo_jobs text-sm sm:text-base md:text-lg lg:text-xl">
                    {" "}
                    <label className="jobName">
                      {companyDetail?.name?.length > 40
                        ? `${companyDetail?.name?.slice(0, 40)}...`
                        : companyDetail?.name}
                    </label>
                    <br />
                    <div className="card-sub-header mt-0.5">
                      <p className="text-sm sm:text-base md:text-lg lg:text-xl">
                        View Company Profile
                      </p>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div className="overviewComponent">
                <label className="firstheading">Overview</label>
                {/* <label className="jobText ml-6">
                  Jobs <span>{companyDetail?.no_of_employee}</span>
                </label> */}
                <div className="overviewBody">{companyDetail?.description}</div>
              </div>
              <div className="companyDetails row">
                <div className="col-12 companyDetailsData">
                  <label className="labelData">Specialization</label>
                  <label className="greenText">
                    {companyDetail?.specialities}
                  </label>
                </div>
                <div className="col-12 companyDetailsData ">
                  <label className="labelData">Company Size</label>
                  <label className="labelValue">
                    {companyDetail?.no_of_employee}
                  </label>
                </div>
                {/* <div className="col-12 companyDetailsData ">
                  <label className="labelData">Founded in</label>
                  <label className="labelValue">2020</label>
                </div> */}
                <div className="col-12 companyDetailsData ">
                  <label className="labelData">Location</label>
                  <label className="labelValue">
                    {companyDetail?.headquarters}
                  </label>
                </div>
                <div className="col-12 companyDetailsData ">
                  <label className="labelData">Phone</label>
                  <label className="labelValue">**********</label>
                </div>
                <div className="col-12 companyDetailsData ">
                  <label className="labelData">Email</label>
                  <label className="labelValue">*****************</label>
                </div>
              </div>
              {/* req clarification and new screen required for send a message  */}
              {/* <div className="companyBtn row">
                <div class="col-sm">
                  <button>{companyDetail?.website}</button>
                </div>
                <div class="col-sm">
                  <button>Send Message</button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer_container">
        <FooterSectionHome />
      </div>
      {isOpenLoginModal && (
        <LoginSignUpModal
          clickMode={"logIn"}
          setIsOpenLoginModal={setIsOpenLoginModal}
          setShowMediaIcons={setIsOpenLoginModal}
          isNewUser={true}
          newUserId={jobPostDetails.id}
        />
      )}
    </div>
  );
};

export default JobPostDetails;
