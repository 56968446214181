import React, { useEffect, useRef, useState } from "react";
import { useTable, useGlobalFilter } from "react-table";
import { Checkbox } from "@material-ui/core";
import { ReactComponent as FilterIcon } from "../../../assets/filterIcon.svg";
import { ReactComponent as EducationIcon } from "../../../assets/education.svg";
import "./AdminCandidate.scss";
import AdminCandidateCard from "../admin-candidate-card/AdminCandidateCard";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { getAllAdminClients, adminCandidateSearch } from "../../../api/api-services";
import AdminCandidateTable from "../admin-candidate-table/AdminCandidateTable";

const AdminCandidate = (props) => {
  let [hireTableData, setHireTableData] = useState([]);
  let [openFilter, setOpenFilter] = useState(false);
  let [filterOptions, setFilterOptions] = useState([]);
  let [selectedFilters, setSelectedFilters] = useState({});
  let [listview, setListview] = useState(true);
  let [selectedTab, setSelectedtab] = useState("All Profiles");
  let [candidateCardsData, setCandidateCardsData] = useState([]);
  const cardSearchInput = useRef(null);
  const [publishToggle, setPublishToggle] = useState({});
  const [allProfileCount, setAllProfileCount] = useState();
  const [isSearching, setIsSearching] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [pageno, setpageNo] = useState(0);
  const [lastPage, setLastpage] = useState(false);
  const size = 10;

  // Function to fetch client details from API
  const fetchClientDetails = async (page) => {
    try {
      const response = await getAllAdminClients(page, size);
      setAllProfileCount(response.data.response.totalElements);
      setIsLoading(false);
      if (page === 0) {
        setHireTableData([...response.data.response.content]);
      } else {
        setHireTableData([...hireTableData, ...response.data.response.content]);
      }
      if (response?.data?.response?.last === false) {
        setpageNo(page + 1);
      }
      setLastpage(response.data.response.last);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  // Fetch initial data using useEffect
  useEffect(() => {
    if (!isSearching) {
      fetchClientDetails(pageno);
    }

    const hireTableElement = document.querySelector(".hireTable");
    hireTableElement.addEventListener("scroll", handleScroll);

    // Clean up the event listener on unmount
    return () => {
      hireTableElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to handle scroll for pagination
  // const handleScroll = (event) => {
  //   const { scrollTop, clientHeight, scrollHeight } = event.target;

  //   if (scrollTop + clientHeight >= scrollHeight - 20) {

  //     if (lastPage === false && !isLoading && !isSearching) {
  //       setIsLoading(true);

  //       fetchClientDetails(pageno);
  //     }
  //   }
  // };
  const [searchedText, setSearchedText] = useState("");
  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;

    if (scrollTop + clientHeight >= scrollHeight - 20) {
      if (lastPage === false && !isLoading && !isSearching) {
        setIsLoading(true);
        fetchClientDetails(pageno);
      } else if (isSearching && lastPage === false && !isLoading) {
        handleSearch(searchedText, pageno);
      }
    }
  };

  const handlePublishToggle = (profile) => {
    console.log(profile);
    setPublishToggle((prevState) => ({
      ...prevState,
      [profile]: !prevState[profile],
    }));
  };

  const fetchFilterOptions = () => {
    setFilterOptions([
      {
        type: "Location",
        values: ["Bangalore", "Mysore", "Shivamogga", "Goa", "Mangalore"],
      },
      {
        type: "Experiance",
        values: ["Fresher", "0 - 1 yrs", "1 - 3 yrs", "3 - 5 yrs", "5+ yrs"],
      },
    ]);
  };

  const handleFilterSelection = (e, type, value) => {
    console.log(e.target.checked);
    let selectedData = { ...selectedFilters };
    if (e.target.checked) {
      if (selectedData?.[type]?.length > 0) {
        selectedData?.[type].push(value);
      } else {
        selectedData[type] = [value];
      }
    } else {
      if (selectedData?.[type]?.length > 0) {
        let index = selectedData?.[type].indexOf(value);
        selectedData?.[type].splice(index, 1);
      }
    }
    console.log(selectedData);
    setSelectedFilters({ ...selectedData });
  };

  let handleTabs = (tab) => {
    if (tab !== selectedTab && !listview) {
      cardSearchInput.current.value = "";
    }

    if (tab === "All Profiles") {
      setSelectedtab("All Profiles");
      setCandidateCardsData([...hireTableData]);
    } else if (tab === "Interviewed Profile") {
      setSelectedtab("Interviewed Profile");
      setCandidateCardsData([...hireTableData]);
    } else if (tab === "Published Profile") {
      setSelectedtab("Published Profile");
      setCandidateCardsData([...hireTableData]);
    } else if (tab === "Offered Profiles") {
      setSelectedtab("Offered Profiles");
      setCandidateCardsData([...hireTableData]);
    }
  };

  let handelCardSearch = (e) => {
    console.log(e.target.value);
    let fieldValue = e.target.value.toLowerCase();
    let candidateData = [];
    hireTableData.map((candidate) => {
      if (
        candidate.profile.toLowerCase().includes(fieldValue) ||
        candidate.skills.toLowerCase().includes(fieldValue) ||
        candidate.location.toLowerCase().includes(fieldValue) ||
        candidate.education.toLowerCase().includes(fieldValue)
      ) {
        candidateData.push({ ...candidate });
      }
    });
    setCandidateCardsData([...candidateData]);
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const [switchState, setSwitchState] = useState(false);
  let getCandidateStatus = (candidate) => {
    if (selectedTab === "Offered Profiles") {
      return <span className="candidate-status">Mapped to (Oracle)</span>;
    } else if (selectedTab === "Interviewed Profile") {
      return (
        <div>
          {/* <FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Off</Typography>
            <Switch
              checked={publishToggle[candidate.profile] || false}
              onChange={() => handlePublishToggle(candidate.profile)}
             
              inputProps={{ 'aria-label': 'ant design' }}
            />
            <Typography>On</Typography>
          </Stack>
        </FormGroup> */}
          {/* <Switch
            checked={switchState}
            onChange={(event) => setSwitchState(event.target.checked)}
          /> */}
        </div>
      );
    }
  };
  const resetSearch = () => {
    setIsSearching(false);
    setpageNo(0);
    setLastpage(false);
    setHireTableData([]); // Clear existing search results
  };

  const handleSearch = async (e, page) => {
    try {
      setIsSearching(true); // Set the flag to indicate search
      setIsLoading(true);
      setSearchedText(e);
      const response = await adminCandidateSearch(e, page, size);

      setAllProfileCount(response.data.response.totalElements);
      setIsLoading(false);
      if (page === 0) {
        setHireTableData([...response.data.response.content]);
      } else {
        setHireTableData([...hireTableData, ...response.data.response.content]);
      }
      if (response?.data?.response?.last === false) {
        setpageNo(page + 1);
      }
      setLastpage(response.data.response.last);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <div className="candidate-section mx-3 w-full home-component-display mt-2.5 overflow-auto">
      <div className="componentHeader flex">
        <EducationIcon className="hidden" />
        <div
          className={`${
            selectedTab === "All Profiles"
              ? "selected-tabletitle"
              : "tabletitle"
          } cursor-pointer`}
          onClick={() => {
            handleTabs("All Profiles");
          }}
        >
          <span>All Profiles - {allProfileCount}</span>
        </div>
        <div>
          <input
            type="text"
            className="candidate-section-search-input"
            onChange={(e) => {
              if (e.target.value.trim() === "") {
                resetSearch();
                fetchClientDetails(pageno);
              } else {
                handleSearch(e.target.value, 0);
              }
            }}
            placeholder="search..."
          />
        </div>
        {/* <div
          className={`${
            selectedTab === "Interviewed Profile"
              ? "selected-tabletitle"
              : "tabletitle"
          } cursor-pointer`}
          onClick={() => {
            handleTabs("Interviewed Profile");
          }}
        >
          <span>Interviewed Profile</span>
        </div>
        <div
          className={`${
            selectedTab === "Published Profile"
              ? "selected-tabletitle"
              : "tabletitle"
          } cursor-pointer`}
          onClick={() => {
            handleTabs("Published Profile");
          }}
        >
          <span>Published Profile</span>
        </div>
        <div
          className={`${
            selectedTab === "Offered Profiles"
              ? "selected-tabletitle"
              : "tabletitle"
          } cursor-pointer`}
          onClick={() => {
            handleTabs("Offered Profiles");
          }}
        >
          <span>Offered Profiles</span>
        </div> */}
        {/* <div className="clickables">
          <button
            // onClick={() => {
            //   if (!openFilter) {
            //     fetchFilterOptions();
            //   }
            //   setOpenFilter(!openFilter);
            // }}
            className={`${openFilter ? "selected-btn" : null}`}
          >
            <span>
              <FilterIcon />
            </span>
            Filters
          </button>
        </div> */}
      </div>
      <div className={`hireTable ${openFilter ? "table-with-filter" : null} `}>
        {listview ? (
          <AdminCandidateTable
            // tableSearch={true}
            hideBtn={
              selectedTab === "Offered Profiles" ||
              selectedTab === "Interviewed Profile" ||
              selectedTab === "All Profiles"
                ? true
                : false
            }
            candidateStatus={getCandidateStatus(hireTableData)}
            btnName={selectedTab === "Published Profile" ? "Offer" : ""}
            candidatesData={hireTableData}
            handleCandidateBtn={handlePublishToggle}
            selectedTab={selectedTab}
            handleScroll={handleScroll}
            showToggle={selectedTab === "Interviewed Profile" ? true : false}
            allProfileTab={selectedTab === "All Profiles" ? true : false}
          />
        ) : (
          <div className="card-container">
            <input
              className="card-search-input place-self-center col-span-2 "
              onChange={handelCardSearch}
              type="text"
              ref={cardSearchInput}
              placeholder="Search"
            />

            {candidateCardsData.map((candidate, index) => {
              return (
                <div className="candidate-card">
                  <AdminCandidateCard
                    alignmentclass={
                      index % 2 === 0 ? "align-card-right" : "align-card-left"
                    }
                    hideBtn={
                      selectedTab === "Offered Profiles" ||
                      selectedTab === "Interviewed Profile" ||
                      selectedTab === "All Profiles"
                        ? true
                        : false
                    }
                    footerStatus={getCandidateStatus(candidate)}
                    btnName={selectedTab === "Published Profile" ? "Offer" : ""}
                    candidate={candidate}
                    handleCandidateBtn={handlePublishToggle}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      {openFilter && (
        <div className="hire-filter">
          <div className="filter-header">
            <span>Filter By</span>
            <span className="apply-btn">
              <button
                onClick={() => {
                  setOpenFilter(!openFilter);
                }}
              >
                Apply
              </button>
            </span>
          </div>
          {filterOptions.map((data, index) => {
            return (
              <div className="individual-type">
                <div>{data.type}</div>
                {data.values.map((value) => {
                  return (
                    <div className="typeData">
                      <Checkbox
                        color="primary"
                        value={value}
                        // disableRipple
                        // disabled={
                        //   !props.data ||
                        //   (props.data && !props.data.length) ||
                        //   props.disableRootCheckbox
                        // }
                        checked={selectedFilters?.[data.type]?.includes(value)}
                        // indeterminate={isCheckAllIndeterminate}
                        onChange={(e) => {
                          handleFilterSelection(e, data.type, value);
                        }}
                        className="root-check-all"
                      />
                      <span>{value}</span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AdminCandidate;
