import "./App.scss";
import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/landing-page/HomePage";
import WebsitePage from "./pages/website/WebsitePage";
import LoginPage from "./pages/login/LoginPage";
import ForgotPassword from "./pages/login/ForgotPassword";
import ContactUs from "./pages/landing-page/contact-us/ContactUs";
import ResetPassword from "./pages/login/ResetPassword";
import RegistrationHome from "./pages/registration/RegistrationHome";
import StudentRegistration from "./pages/registration/StudentRegistration";
import ClientRegistration from "./pages/registration/ClientRegistration";
import OtpVerification from "./pages/registration/OtpVerification";
import AdminHome from "./pages/admin/AdminHome";
import ClientHome from "./pages/client/ClientHome";
import CandidateHome from "./pages/candidate/candidate-home/CandidateHome";
import InterviewerHome from "./pages/interviewer/interviewer-home/InterviewerHome";
import InterViewCoordinatorHome from "./pages/interviewer-coordinator/interview-coordinator-home/InterViewCoordinatorHome";
import BdHome from "./pages/business-development/BdHome";
import ThankYouPage from "./pages/landing-page/contact-us/ThankYouPage";
import FilterJobHome from "./pages/website/find-jobs/JobSearchHome";

import JobSearchHome from "./pages/website/find-jobs/JobSearchHome";
import ContactUsWebsite from "./pages/website/contact-us-website/ContactUsWebsite";
import SetPassword from "./pages/website/website-signup-home/login-form/SetPassword";
import AboutUsHome from "./pages/website/about-us/AboutUsHome";
import ThanksPage from "./pages/website/thankyou-page/ThanksPage";
import JobPostDetails from "./pages/website/jobpost-details/JobPostDetails";
import AllCategories from "./pages/website/categories/AllCategories";
function App() {
  const [registrationData, setRegistrationData] = useState({});
  const [getFilteredData, setgetFilteredData] = useState({});
  const handleRegistrationData = (data) => {
    setRegistrationData(data);
  };
  const handleFilterData = (data) => {
    setgetFilteredData(data);
  };
  return (
    <Routes>
      <Route path="/" element={<WebsitePage />} />
      <Route path="/admin/*" element={<AdminHome />} />
      <Route path="/job-search" element={<JobSearchHome />} />
      <Route
        path="/job-details/:jobId"
        element={
          <JobPostDetails
            getFilteredData={getFilteredData}
            handleFilterData={handleFilterData}
          />
        }
      />
      <Route path="/about-us" element={<AboutUsHome />} />
      <Route path="/categories" element={<AllCategories />} />
      <Route path="/client/*" element={<ClientHome />} />
      <Route path="/coordinator/*" element={<InterViewCoordinatorHome />} />
      <Route path="/candidate/*" element={<CandidateHome />} />
      <Route path="/interviewer/*" element={<InterviewerHome />} />
      <Route path="/bd/*" element={<BdHome />} />
      <Route
        path="/login"
        element={<LoginPage handleRegistrationData={handleRegistrationData} />}
      />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      <Route path="/contactUs" element={<ContactUsWebsite />} />

      <Route path="/reset-password/*" element={<SetPassword />} />
      <Route path="/set-password/*" element={<SetPassword />} />
      <Route path="/register" element={<WebsitePage />} />
      <Route path="/thankYou" element={<ThanksPage />} />
    </Routes>
  );
}

export default App;
