import { useState } from "react";
import Modal from "react-modal";
import CandidatesDetailsModal from "./candidate-details/CandidatesDetailsModal";
// import ScheduleInterview from "./ScheduleInterview";
import "./CandidatePreviewModal.scss";
// import UpdateInterview from "./UpdateInterview";
import CancelIcon from '@mui/icons-material/Cancel';

function CandidatePreviewModal(props) {
  const [openModal, setOpenModal] = useState(true);
  let [candidateDetailsData, setCandidateDetailsData] = useState({});
  let [skills, setSkills] = useState([
    "HTML",
    "Java Script",
    "Boot Strap",
    "CSS",
  ]);

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "60%",
      transform: "translate(-50%, -50%)",
      width: "82%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
      outline: "none",
      //   borderTopLeftRadius: " 12px",
    },
  };
  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      style={customStylesPage}
      className="p-px"
    >
      <div className="flex items-center rounded-tl-xl candidate-preview-modal-heading items-center">
        <div className="header">{props.header}</div>
        <button
          onClick={() => {
            setOpenModal(false);
            props.closeModal(false);
          }}
          type="button"
          className="modal-close-btn float-left ml-auto"
        >
          <CancelIcon />
        </button>
      </div>
      <div className="flex candidate-preview-modal-body">
        <div className="modal-candidate-details-container">
          <CandidatesDetailsModal
            candidateDetailsData={candidateDetailsData}
            candidate={props.candidate}
          />
        </div>
        <div className="candidate-preview-form">
          {/* {props.selectedTab === "All Profiles" ? (
            <ScheduleInterview />
          ) : (
            <UpdateInterview />
          )} */}
          <div className="candidate-preview-form-body">
            <div className="individual-field mt-7">
              <div className="mb-2 label">
                <span className="create-labels">Skill Set Rating</span>
              </div>
            </div>
            {skills.map((data) => {
              return (
                <div className="flex rating-individual-field mt-2">
                  <div className="mb-2 label">
                    <span className="create-labels">{data}</span>
                  </div>
                  <div className="popins-medium">
                    <input className="rating-input-field" type={"text"} />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="fixed bottom-16 right-7">
            <div className="job-submit-btn items-center ml-auto">
              <button
                className="datatrimmer cancel-btn mr-4"
                onClick={() => {
                  props.closeModal(true);
                }}
                title={""}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CandidatePreviewModal;
