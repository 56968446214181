import { param } from "jquery";
import httpConnection from "./http-connection.js";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const AUTH_URL = BASE_URL + "/ag-auth";
const CANDIDATE_URL = BASE_URL + "/ag-candidate";
const CLIENT_URL = BASE_URL + "/ag-client";
const ADMIN_URL = BASE_URL + "/ag-admin";
const BD_URL = BASE_URL + "/ag-bd";
const COUNTRY_URL = CANDIDATE_URL + "/country";
const QUALIFICATION_URL = CANDIDATE_URL + "/qualification";
const QSPIDER_URL = process.env.REACT_APP_QSPIDER_BASE_URL + "/api/student";

// https://golive.qspiders.com/api/student/get-countries
// website
export const getLatestJobCards = (pageNo, pageSize) =>
  httpConnection.get(
    `${CLIENT_URL}/jobPost?pageNo=${pageNo}&pageSize=${pageSize}`
  );
//contact us
export const contactUsReq = (payload) =>
  httpConnection.post(`${CLIENT_URL}/client/enquiry/`, payload);
export const defaultCountry = () =>
  httpConnection.get(`https://api.country.is/`);
// signup
export const candidateSignUp = (payload) =>
  httpConnection.post(`${CANDIDATE_URL}/candidate/signup`, payload);
export const clientSignUp = (payload) =>
  httpConnection.post(`${CLIENT_URL}/client/signup`, payload);
export const existEmailPhoneCheck = (value) =>
  httpConnection.get(`${BASE_URL}/ag-auth/auth/emailOrMobile/${value}`);
export const forgotPassword = (emailId) =>
  httpConnection.post(
    `${BASE_URL}/ag-auth/auth/forgotPassword?email=${emailId}`
  );
export const resetPassword = (token, password) =>
  httpConnection.post(
    `${BASE_URL}/ag-auth/auth/setPassword/${token}?password=${password}`
  );
export const getRegisteredCompanyList = () =>
  httpConnection.get(`${BASE_URL}/ag-auth/auth/allCompany`);

//sign-in
export const loginReq = (payload) =>
  httpConnection.post(`${AUTH_URL}/auth/login`, payload);
//job-filter-Api
export const getFilteredJobs = (pageNo, pageSize, payload) =>
  httpConnection.post(`${ADMIN_URL}/jobPost/jobPostSearchFilter?pageNo=${pageNo}&pageSize=${pageSize}`, payload
  );
// Admin Apis
export const getNewJobPost = (pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/jobPost/newJobPost?pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const getAllCompany = () =>
  httpConnection.get(`${CLIENT_URL}/jobPost/companyNames`);
export const getAllSkills = () =>
  httpConnection.get(`${CLIENT_URL}/jobPost/skillNames`);
export const getOldJobPost = (pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/jobPost/oldJobPost?pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const getAllBd = (pageNo, pageSize) =>
  httpConnection.get(`${ADMIN_URL}/bd?pageNo=${pageNo}&pageSize=${pageSize}`);
export const getInterviewers = (pageNo, pageSize) =>
  httpConnection.get(`${ADMIN_URL}/interviewer?pageNo=${pageNo}&pageSize=${pageSize}`);


export const getInterviewersCoordinate = (pageNo, pageSize) =>
  httpConnection.get(`${ADMIN_URL}/interviewCoordinator?pageNo=${pageNo}&pageSize=${pageSize}`);
export const createBdReq = (payload, adminId) =>
  httpConnection.post(`${ADMIN_URL}/bd?adminId=${adminId}`, payload);
export const editBdReq = (payload, adminId) =>
  httpConnection.put(`${ADMIN_URL}/bd?adminId=${adminId}`, payload);
export const createInterviewCoordinatorReq = (payload, adminId) =>
  httpConnection.post(`${ADMIN_URL}/interviewCoordinator`, payload);
export const editInterviewCoordinatorReq = (payload, adminId) =>
  httpConnection.put(`${ADMIN_URL}/interviewCoordinator`, payload);
export const createInterviewerReq = (payload, adminId) =>
  httpConnection.post(`${ADMIN_URL}/interviewer?adminId=${adminId}`, payload);
export const editInterviewerReq = (payload, adminId) =>
  httpConnection.put(`${ADMIN_URL}/interviewer?adminId=${adminId}`, payload);
export const deletebdReq = (bdId) =>
  httpConnection.delete(`${ADMIN_URL}/bd/${bdId}`);
export const deleinterviewerReq = (bdId) =>
  httpConnection.delete(`${ADMIN_URL}/interviewer/${bdId}`);
export const deleteinterviewercordinator = (bdId) =>
  httpConnection.delete(`${ADMIN_URL}/interviewCoordinator/${bdId}`);
export const mapCompany = (jobPostId, companyName) =>
  httpConnection.put(
    `${ADMIN_URL}/jobPost/map?jobPostId=${jobPostId}&companyName=${companyName}`
  );
export const resendActivation = (id) =>
  httpConnection.get(`${ADMIN_URL}/bd/resendActivationLink?bdId=${id}`)
export const resendinterviewerActivation = (id) =>
  httpConnection.get(`${ADMIN_URL}/interviewer/resendActivationLink?id=${id}`)
export const resendinterviewCoordinatorActivation = (id) =>
  httpConnection.get(`${ADMIN_URL}/interviewCoordinator/resendActivationLink?id=${id}`)
export const createNewClient = (payload) =>
  httpConnection.post(`${ADMIN_URL}/company`, payload);
export const getAllClientCompany = (pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/company/clientList?pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const getAllDomains = () =>
  httpConnection.get(
    `${CLIENT_URL}/jobPost/domains`
  );
export const getClientJobPost = (companyName, pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/company/jobPosts?companyName=${companyName}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const getClientaddedResume = (companyName, pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/company/addedResume?companyName=${companyName}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const getClientOffered = (companyName, pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/company/offered?companyName=${companyName}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const enableDisableUser = (status, userId) =>
  httpConnection.put(`${ADMIN_URL}/bd/${status}/${userId}`);
export const getClientCompanyDetails = (companyName) =>
  httpConnection.get(`${ADMIN_URL}/company/byName/${companyName}`);
export const resetPasswordForBD = (token, password) =>
  httpConnection.get(
    `${ADMIN_URL}/bd/tokenVerify/${token}?password=${password}`
  );
export const getAllAdminClients = (pageNo, pageSize) =>
  httpConnection.get(
    `${CANDIDATE_URL}/candidate?page=${pageNo}&size=${pageSize}`
  );
//Interview Coordinator
export const getActiveInterviewers = (pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/interviewer/activeInterviewers?pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const getInterviewersAsPerDomain = (domain) =>
  httpConnection.get(
    `${ADMIN_URL}/interviewer/domainMatching?domain=${domain}`
  );
//bd Apis
export const getBdJobListReq = (bdId, pageNo, pageSize) =>
  httpConnection.get(
    `${BD_URL}/bd/jobPostByCompanyName?bdId=${bdId}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const getdisabledCompReq = (bdId) =>
  httpConnection.get(
    `${ADMIN_URL}/bd/companyForBd/${bdId}`
  );
export const getAllAccounts = (pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/company/accounts?pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const getAllClintsByCompany = (CompName) =>
  httpConnection.get(
    `${ADMIN_URL}/company/clientsDetails?companyName=${CompName}`
  );
export const getAllAccountsById = (id, pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/company/accountsForUser?id=${id}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const getJobPostByCompName = (compName, pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/company/jobPosts?companyName=${compName}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const getAllClints = (companyName, pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/company/clients?companyName=${companyName}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const getAllLeads = (pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/lead?pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const getAllLeadsById = (id, pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/lead/leads?id=${id}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const convertToAccount = (id) =>
  httpConnection.put(
    `${ADMIN_URL}/lead/${id}`
  );
export const addcompanylead = (payload) =>
  httpConnection.post(`${ADMIN_URL}/lead`, payload);
export const updateCompanylead = (payload) =>
  httpConnection.put(`${ADMIN_URL}/lead`, payload);
//all categories API
export const getAllCategories = () =>
httpConnection.get(`${CLIENT_URL}/jobPost/categoriesJobPostCount`);

// client api's
export const getJobListReq = (mailid, pageNo, pageSize) =>
  httpConnection.get(
    `${CLIENT_URL}/jobPost/client?userEmail=${mailid}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
  export const getJobListClientReq = (uid, pageNo, pageSize) =>
  httpConnection.get(
    `${CLIENT_URL}/jobPost/clientId?clientId=${uid}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const postJobReq = (payload, mailid) =>
  httpConnection.post(`${CLIENT_URL}/jobPost?userEmail=${mailid}`, payload);
export const editJobReq = (payload, mailid) =>
  httpConnection.put(`${CLIENT_URL}/jobPost?userEmail=${mailid}`, payload);
export const getIndustriesListReq = () =>
  httpConnection.get(`${CLIENT_URL}/jobPost/allIndustry`);
export const getroleListReq = (industry) =>
  httpConnection.get(`${CLIENT_URL}/jobPost/allRoles?industryName=${industry}`);
export const getskillsListReq = (industry, role) =>
  httpConnection.get(
    `${CLIENT_URL}/jobPost/skills?role=${role}&industryName=${industry}`
  );
export const getClientDetails = (clientId) =>
  httpConnection.get(`${CLIENT_URL}/client/${clientId}`);

//Candidate API's
export const getCandidateDetailsReq = (candidateId) =>
  httpConnection.get(
    `${CANDIDATE_URL}/candidate/fullProfile?id=${candidateId}`
  );
export const editCandidateDetails = (payload) =>
  httpConnection.put(`${CANDIDATE_URL}/candidate`, payload);

// //Generic API's
export const getAllCountriesReq = () =>
  httpConnection.get(`${COUNTRY_URL}/allCountries`);
export const getTotalCitiesReq = () =>
  httpConnection.get(`${COUNTRY_URL}/cities`);
export const getAllStatesReq = (country) =>
  httpConnection.get(`${COUNTRY_URL}/allStates?country=${country}`);
export const getAllCitiesReq = (country, state) =>
  httpConnection.get(
    `${COUNTRY_URL}/allCities?country=${country}&state=${state}`
  );

export const getAllUniversitiesReq = () =>
  httpConnection.get(`${CANDIDATE_URL}/universities`);
export const getAllCollegesReq = (university) =>
  httpConnection.get(`${CANDIDATE_URL}/colleges?name=${university}`);

export const getAllDegreesReq = () =>
  httpConnection.get(`${QUALIFICATION_URL}/highestDegree`);
export const getAllTitlesReq = (degree) =>
  httpConnection.get(`${QUALIFICATION_URL}/Titles?highestDegree=${degree}`);
export const getAllStreamsReq = (degree, title) =>
  httpConnection.get(
    `${QUALIFICATION_URL}/streams?highestDegree=${degree}&title=${title}`
  );

//dynamic table
export const getAllAdminBdCandReq = (payload) =>
  httpConnection.get(`${BD_URL}/bd`, { params: payload });
export const getAdminBdCandReq = (payload) =>
  httpConnection.post(`${BD_URL}/bd/state`, payload);
export const shareCandReq = (payload) =>
  httpConnection.post(`${BD_URL}/bd/share`, payload);
export const shortlistCandReq = (payload) =>
  httpConnection.post(
    `${BD_URL}/bd/shortlist`, payload
  );
export const scheduleInterviewReq = (payload) =>
  httpConnection.post(`${BD_URL}/bd/schedule`, payload);
export const reScheduleInterviewReq = (payload) =>
  httpConnection.post(`${BD_URL}/bd/reschedule`, payload);
export const updateInterviewReq = (type, payload) =>
  httpConnection.post(
    `${BD_URL}/bd/${type}`, payload
  );
export const nextRoundInterviewReq = (payload) =>
  httpConnection.post(`${BD_URL}/bd/client/nextRound`, payload);
export const scheduleOneMoreRoundInterviewReq = (payload) =>
  httpConnection.post(`${BD_URL}/bd/nxt/schedule`, payload);
  export const getIntrestedProfileReq = (jobPostId, pageNo, pageSize) =>
  httpConnection.get(
    `${BD_URL}/bd/interestingCandidates?jobPostId=${jobPostId}&pageNo=${pageNo}&pageSize=${pageSize}`
  );

// interview list
export const getAllInterviewCandReq = (id, page, size) =>
  httpConnection.get(`${BD_URL}/internal/interviews?id=${id}&pageNo=${page}&pageSize=${size}`);
export const getClientInterviewReq = (userId) =>
  httpConnection.get(`${BD_URL}/bd/client/interviews/${userId}`);
export const getCandidateInterviewReq = (userId) =>
  httpConnection.get(`${BD_URL}/bd/candidate/interviews/${userId}`);
export const updateInterviewRating = (userId, payload) =>
  httpConnection.put(`${BD_URL}/internal/taken?id=${userId}`, payload);
export const uploadCandidateResume = (userId, payload) => httpConnection.put(`${CANDIDATE_URL}/candidate/resumeUpload/${userId}`, payload, { type: "formdata" });
export const DownloadCandidateResume = (filename, resName) => httpConnection({
  method: 'GET',
  url: `${CANDIDATE_URL}/candidate/download?filename=${filename}/resume/${resName}`,
  responseType: 'blob',

});
export const deleteCandidateResume = (userID, filename) => httpConnection.delete(`${CANDIDATE_URL}/candidate/delete/${userID}?filename=${filename}`);
export const UpdateCandidateResume = (userID, payload) => httpConnection.put(`${CANDIDATE_URL}/candidate/updateResume/${userID}`, payload, { type: "formdata" });

// interview cooordinate internal
export const getAllScheduleInterviews = (cooordinateID, pageNo, pageSize) =>
  httpConnection.get(
    `${BD_URL}/internal/interviews?id=${cooordinateID}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const getAllCandidates = (pageNo, pageSize) =>
  httpConnection.get(
    `${BD_URL}/internal?pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const getAllCandidatesByStatus = (status, pageNo, pageSize) =>
  httpConnection.get(
    `${BD_URL}/internal/byState?status=${status}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const ScheduleInterviewForCoordinate = (payload) =>
  httpConnection.put(`${BD_URL}/internal/schedule`, payload);
export const ReScheduleInterviewForCoordinate = (payload) =>
  httpConnection.put(`${BD_URL}/internal/reSchedule`, payload);
export const cancelInterviewForCoordinate = (id, feedback) =>
  httpConnection.put(
    `${BD_URL}/internal/cancel?id=${id}&feedBack=${feedback}`
  );
export const videoUpload = (id, payload, startTime, endTime) =>
  httpConnection.post(
    `${BASE_URL}/ag-Multimedia/media/upload?candidateId=${id}&startTime=${startTime}&endTime=${endTime}`, payload, { type: "formdata" }
  );
export const completeProfileAfterVideoUpload = (id, uploadVideoKey) =>
  httpConnection.put(
    `${BD_URL}/internal/complete?id=${id}&uploadVideo=${uploadVideoKey}`
  );
export const getAssignedInterviewerDetails = (id) =>
  httpConnection.get(
    `${ADMIN_URL}/interviewer/${id}`
  );

// search Apis
export const adminCandidateSearch = (name, pageNo, pageSize) =>
  httpConnection.get(
    `${CANDIDATE_URL}/candidate/search?name=${name}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const adminNewJobPostSearch = (value, pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/jobPost/newJobPostSearch?value=${value}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const adminOldJobPostSearch = (value, pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/jobPost/oldJobPostSearch?value=${value}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const adminCompanySearch = (value, pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/company/companySearch?pageNo=${pageNo}&pageSize=${pageSize}&value=${value}`
  );
export const adminCoOrdinatorSearch = (value, pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/interviewCoordinator/coordinatorSearch?value=${value}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const adminInterviewerSearch = (value, pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/interviewer/interviewerSearch?value=${value}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const adminBDSearch = (value, pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/bd/bdSearch?value=${value}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const coordinatorInterviewerSearch = (value, pageNo, pageSize) =>
  httpConnection.get(
    `${ADMIN_URL}/interviewer/searchActiveInterviewers?value=${value}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const coordinatorAllProfileCandidateSearch = (value, pageNo, pageSize) =>
  httpConnection.get(
    `${BD_URL}/internal/allCandidateSearchInInternalInterview?value=${value}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
export const coordinatorCandidateByStateSearch = (value, pageNo, pageSize, status) =>
  httpConnection.get(
    `${BD_URL}/internal/byStateSearch?value=${value}&pageNo=${pageNo}&pageSize=${pageSize}&status=${status}`
  );

export const AllProfileSearch = (id, value, pageNo, pageSize) =>
  httpConnection.get(
    `${BD_URL}/bd/allCandidateSearch?jobId=${id}&value=${value}&pageNo=${pageNo}&pageSize=${pageSize} `
  );
export const ByStateSearch = (value, payload) =>
  httpConnection.post(
    `${BD_URL}/bd/stateSearch?value=${value}`, payload
  );
export const BdJobPostSearch = (id, value, pageNo, pageSize) =>
  httpConnection.get(
    `${BD_URL}/bd/searchJobPostByCompanyName?bdId=${id}&value=${value}&pageNo=${pageNo}&pageSize=${pageSize} `
  );
export const clientJobPostSearch = (value, pageNo, pageSize, id) =>
  httpConnection.get(
    `${CLIENT_URL}/jobPost/jobPostSearch?value=${value}&pageNo=${pageNo}&pageSize=${pageSize}&id=${id} `
  );
export const interviewerSearch = (value, pageNo, pageSize, id) =>
  httpConnection.get(
    `${BD_URL}/internal/interviewsSearch?&pageNo=${pageNo}&pageSize=${pageSize}&value=${value}&id=${id} `
  );

  export const intrestedCandidateSearch = (id, pageNo, pageSize,value) =>
  httpConnection.post(
    `${BD_URL}/bd/InterestedCandidatesSearch?jobPostId=${id}&pageNo=${pageNo}&pageSize=${pageSize}&value=${value} `
  );
  
  export const getJobPostByID = (id) =>
  httpConnection.get(`${CLIENT_URL}/jobPost/${id}`);
