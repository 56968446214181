import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import './FilterSection.scss';

function FilterSection(props) {
  // const initialCheckedState = {
  //   Full_Time: false,
  //   Internship: false,
  //   Part_Time: false,
  //   Remote: false,
  //   MASTERS_DEGREE: false,
  //   ITI: false,
  //   SSLC: false,
  //   PUC: false,
  //   DIPLOMA: false,
  //   DEGREE: false,
  //   one_2Years: false,
  //   three_5Years: false,
  //   six_9Years: false,
  //   tenPlus: false,
  // };

  // const [isChecked, setChecked] = useState({});

  const handleCheckboxChange = (event, category, type, ststus) => {

    if (category === 'Highest Qualification') {
      const updatedCourseArray = [...props.filtersSelected.Course];
      if (event.target.checked) {
        updatedCourseArray.push(type);
        props.setFiltersSelected(updatedCourseArray)
      } else {
        const indexToRemove = updatedCourseArray.indexOf(type);
        if (indexToRemove !== -1) {
          updatedCourseArray.splice(indexToRemove, 1);
          props.setFiltersSelected(updatedCourseArray)
        }
      }
      props.setFiltersSelected({ ...props.filtersSelected, Course: updatedCourseArray });
    }

    if (category === 'JOBS TYPE') {
      const updatedCourseArray = [...props.filtersSelected.EmploymentType];
      if (event.target.checked) {
        updatedCourseArray.push(type);
        props.setFiltersSelected(updatedCourseArray)
      } else {
        const indexToRemove = updatedCourseArray.indexOf(type);
        if (indexToRemove !== -1) {
          updatedCourseArray.splice(indexToRemove, 1);
          props.setFiltersSelected(updatedCourseArray)
        }
      }
      props.setFiltersSelected({ ...props.filtersSelected, EmploymentType: updatedCourseArray });
    }

    if (category === 'JOBS EXPERIENCE') {
      // Make a shallow copy of the original array
      const updatedCourseArray = [...props.filtersSelected.WorkExperience];
      const updatedCourseIdArray = [...props.filtersSelected.expId];
      if (event.target.checked) {
        updatedCourseArray.push(type);
        updatedCourseIdArray.push(type.id)
        props.setFiltersSelected(updatedCourseArray)
      } else {
        // Find the index of the item to remove
        const indexToRemove = updatedCourseArray.findIndex(item => item.id === type.id);
        if (indexToRemove !== -1) {
          // If found, remove the item from the array
          updatedCourseArray.splice(indexToRemove, 1);
          updatedCourseIdArray.splice(indexToRemove, 1)
        }
      }
      console.log(updatedCourseIdArray, "idArr", updatedCourseArray, "updatedCourseArray")


      console.log(props.filtersSelected, "filtersSelected")


      props.setFiltersSelected({ ...props.filtersSelected, WorkExperience: updatedCourseArray, expId: updatedCourseIdArray });
    }

    props.setPage(0)
    props.setPagination(1)
    props.setApply(props.apply + 1)
  };

  console.log(props.filtersSelected, "filtersSelected");
  // console.log(props.filtersSelected['Highest Qualification'].includes("ITI"), "test");


  return (
    <div className={props.modfilter === 'modFilter' ? 'filterContainer1' : 'filterContainer'}>
      <div style={{ justifyContent: "space-between" }} className='borderBtm flex'>
        <div>
          <p className='filterHeader'>Filter</p>
        </div>
      </div>
      <div className='borderBtm'>
        <div className='p-3'>
          <p className='filterSubTitle'>EMPLOYMENT TYPE</p>
          <div>
            <FormGroup>
              {Object.entries({
                'Full Time': 'FULL_TIME',
                'Casual': 'CASUAL',
                'Part Time': 'PART_TIME',
                'Contract Type': 'CONTRACT_TYPE',
              }).map(([label, type]) => (
                <FormControlLabel
                  key={type}
                  control={<Checkbox size='small'
                    checked={props.filtersSelected['EmploymentType'] && props.filtersSelected['EmploymentType'].includes(type)}
                    onChange={(e) => handleCheckboxChange(e, 'JOBS TYPE', type)}
                  />}
                  label={label}
                />
              ))}

            </FormGroup>
          </div>
        </div>
      </div>
      <div className='borderBtm'>
        <div className='p-3'>
          <p className='filterSubTitle'>Highest Qualification</p>
          <div>
            <FormGroup>
              {Object.entries({
                'MASTERS DEGREE': 'MASTERS_DEGREE',
                'ITI': 'ITI',
                'DEGREE': 'DEGREE',
                'DIPLOMA': 'DIPLOMA',
                'PUC': 'PUC',
                'SSLC': 'SSLC',
              }).map(([label, type]) => (
                <FormControlLabel
                  key={type.id}
                  control={<Checkbox size='small'
                    checked={props.filtersSelected['Course'] && props.filtersSelected['Course'].includes(type)}
                    onChange={(e) => handleCheckboxChange(e, 'Highest Qualification', type)}
                  />}
                  label={label}
                />
              ))}
            </FormGroup>
          </div>
        </div>
      </div>
      <div className=''>
        <div className='p-3'>
          <p className='filterSubTitle'>JOBS EXPERIENCE</p>
          <div>
            <FormGroup>
              {Object.entries({
                '1 - 2 Years': { min: 1, max: 2, id: 1 },
                '3 - 5 Years': { min: 3, max: 5, id: 2 },
                '6 - 9 Years': { min: 6, max: 9, id: 3 },
                '10+ Years': { min: 10, max: 25, id: 4 },
              }).map(([label, type]) => (
                <FormControlLabel
                  key={type}
                  control={<Checkbox size='small'
                    checked={props.filtersSelected['expId'] && props.filtersSelected['expId'].includes(type.id)}
                    onChange={(e) => handleCheckboxChange(e, 'JOBS EXPERIENCE', type)}
                  />}

                  label={label}
                />
              ))}
            </FormGroup>
          </div>
        </div>
      </div>

      {props.modfilter && (
        <div className=' flex cursor-pointer'>
          <div>
            <button onClick={props.handleClose} className='search-button2'>
              {`Show ${props?.filteredJobs?.totalElements}Jobs`}
            </button>
          </div>
        </div>
      )}

    </div>
  );
}

export default FilterSection;
