import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import "./select-component.scss";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  disableOther,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };
  const hasChildren = !!children;
  const isSpecialOption =
    children === "Pan India" || children === "Any Location";
  const textClass = isSpecialOption
    ? "text-black opacity-100 cursor-pointer"
    : disableOther
    ? "text-gray-600 opacity-50"
    : "text-black opacity-100 cursor-pointer";

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <div>
        <span>
          {" "}
          <input
            type="checkbox"
            className="mx-2 cursor-pointer"
            checked={isSelected}
          />
        </span>
        <span className={textClass}>{children}</span>
      </div>
    </components.Option>
  );
};

const ReactSelectDropdown = ({
  options,
  value,
  onChange,
  className,
  isMulti,
  closeMenuOnSelect,
  hideSelectedOptions,
  noOptionMsg,
  specialOption, // Receive "Pan India" or "Any Location" as a prop
  disabled
}) => {
  const [disableOther, setDisableOthers] = useState(false);

  useEffect(() => {
    // Check if the current selected value is the special option
    const isSpecialOptionSelected = value.some(
      (option) => option?.value === specialOption?.value
    );

    // If the special option is selected, disable others
    setDisableOthers(isSpecialOptionSelected);
  }, [value, specialOption]);
  const handleChange = (selectedValues) => {
    const isPanIndiaSelected = selectedValues.some(
      (value) => value?.value === specialOption?.value
    );

    if (isPanIndiaSelected) {
      setDisableOthers(true);
      onChange([specialOption]);
    } else {
      onChange(selectedValues);
      setDisableOthers(false);
    }
  };
  const CustomNoOption = () => (
    <div
      className={`noData pt-5 pb-5 flex justify-center ${
        noOptionMsg ? "text-red-600" : ""
      }`}
    >
      {noOptionMsg ? noOptionMsg : "No options"}
    </div>
  );

  return (
    <div className="selectDropDown">
      <Select
        className={className || "my-select-container-postJob"}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect || false}
        hideSelectedOptions={hideSelectedOptions || false}
        onChange={handleChange}
        options={options}
        value={value}
        isDisabled={disabled}
        components={{
          Option: (props) => (
            <InputOption
              {...props}
              disableOther={disableOther} // Pass the disableOther state
            />
          ),
          NoOptionsMessage: CustomNoOption,
        }}
      />
    </div>
  );
};

export default ReactSelectDropdown;
