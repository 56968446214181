import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { SelectDropdown } from "../../landing-page/common/select-dropdown";
import { validateFromAndToTime } from "../../../util/common-utils";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";
import MultiEmailInputField from "../../common/MultiEmailInputField";
import {
  getInterviewersAsPerDomain,
  ScheduleInterviewForCoordinate,
  getAssignedInterviewerDetails,
} from "../../../api/api-services";
import AssignedInterviewerModal from "./AssignedInterviewerModal";
import Select from "react-select";


const ScheduleInterview = (props) => {
  console.log(props);
  let user = JSON.parse(localStorage.getItem("across-geo-user"));
  const validationSchema = yup.object({
    interviewer: yup.string().required("Interviewer data is required"),
    // dateAndTime: yup
    //   .date()
    //   .nullable(true) // Allow a null (empty) date field
    //   .required("Date and Time is required"),
    // interviewLink: yup.string().required("Interview Link is required"),
    date: yup.string().required("Date is required"),
    // mode: yup.string().required("Interview Mode is required"),
    fromTime: yup
      .string()
      // .required("Please enter proper time")
      .required("From To time is required ")
      .test("am-pm", "Invalid time format. Please provide AM/PM", (value) => {
        if (!value) {
          return false; // Value is required
        }
        return /(AM|PM)$/i.test(value);
      })
      .test(
        "valid-time",
        "From Time must be earlier than To Time",
        function (value) {
          const toTime = this.parent.totime;
          return validateFromAndToTime(value, toTime);
        }
      )
      .test("past-time", "Past time not allowed", function (value) {
        const currentDate = dayjs().format("YYYY-MM-DD");
        const selectedDate = this.parent.date;
        if (selectedDate === currentDate) {
          const currentTime = dayjs().format("hh:mm A");
          return dayjs(value, "hh:mm A").isAfter(dayjs(currentTime, "hh:mm A"));
        }
        return true;
      }),
    totime: yup
      .string()
      // .required("Please enter proper time")
      .required("From To time is required ")
      .test("am-pm", "Invalid time format. Please provide AM/PM", (value) => {
        if (!value) {
          return false; // Value is required
        }
        return /(AM|PM)$/i.test(value);
      })
      .test(
        "valid-time",
        "From Time must be earlier than To Time",
        function (value) {
          const fromTime = this.parent.fromTime;
          return validateFromAndToTime(fromTime, value);
        }
      )
      .test("past-time", "Past time not allowed", function (value) {
        const currentDate = dayjs().format("YYYY-MM-DD");
        const selectedDate = this.parent.date;
        if (selectedDate === currentDate) {
          const currentTime = dayjs().format("hh:mm A");
          return dayjs(value, "hh:mm A").isAfter(dayjs(currentTime, "hh:mm A"));
        }
        return true;
      }),
    participants: yup.array(),
    // linkorlocation: yup.string().required("Required"),
    linkorlocation: yup
      .string()
      .required("Link is Required")
      .matches(
        /^(https?:\/\/)?([\da-zA-Z.-]+)\.([a-zA-Z]{2,6})([/\w .-]*)*\/?$/,
        "Invalid URL"
      ),
  });
  let responseHandled = false;
  const formikDetails = useFormik({
    initialValues: {
      interviewer: "",
      // dateAndTime: null, // Initialize with null (empty)
      // interviewLink: "",
      date: "",
      fromTime: "",
      participants: [],
      totime: "",
      linkorlocation: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const payloadEmails = [
        ...selectedEmails,
        props?.candidateDetailsData.personalDetails.email,
        selectedInterviewerData.emailID,
      ];
      let payload = {
        candidateID: props.candidate.id,
        date: values.date,
        interviewType: "INTERNAL",
        startTime: values.fromTime,
        endTime: values.totime,
        schedulerId: user.uid,
        schedulerName: user.username,
        meetingLink: formikDetails.values.linkorlocation,
        interviewersId: selectedInterviewerId,
        participants: payloadEmails,
        interviewMode: "ONLINE",
      };
      console.log(payload, values);
      let response;
      try {
        if (isSubmitting) {
          return;
        }
        setIsSubmitting(true);
    
        response = await ScheduleInterviewForCoordinate(payload);
        console.log(response);
        if (response.data) {
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>Interview scheduled successfully</div>
            </>
          );
          props.closeModal();
          setIsSubmitting(false);
        } 
         if(response &&
          response?.response &&
          response?.response?.data &&
          response?.response?.data?.statusCode === 400 ){
         props.toast.info(
            <>
              <div className="toast-heading">Info</div>
              <div>
               {response?.response?.data?.response}
              </div>
            </>
            
          );
        }
      } catch (err) {
        console.error(err, "schedule interview api error");
      }
    },
  });

  const disablePastDates = (date) => {
    // Disable selecting dates before today
    return date < dayjs().startOf("day");
  };
  const [interviewerData, setInterviewerData] = useState([]);
  const [selectedInterviewerId, setSelectedInterviewerId] = useState("");
  console.log(props?.candidateDetailsData);
  useEffect(() => {
    const fetchDomain = async () => {
      try {
        const response = await getInterviewersAsPerDomain(
          props?.candidateDetailsData.technicalDetails.jobTitle
        );
        const jobTitle = response.data.response.map((value) => ({
          label: value.name,
          value: value.name,
          id: value.id,
          emailID: value.email,
        }));
        setInterviewerData([...jobTitle]);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchDomain();
  }, [props?.candidateDetailsData]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [openAssignedInterviewModal, setOpenAssignedInterviewModal] =
    useState(false);
  const [dateValue, setDateValue] = useState("");
  const [selectedInterviewerData, setSelectedInterviewerData] = useState([]);
  const handleInterviewerChange = (data) => {
    // formikDetails.setFieldValue("interviewer", data?.[0].value);
    // setSelectedInterviewerId(data?.[0]?.id);
    // setSelectedInterviewerData([...data]);
    formikDetails.setFieldValue("interviewer", data?.value);
    setSelectedInterviewerId(data?.id);
    setSelectedInterviewerData(data);
  };
  const handleBlur = () => {
    if (
      formikDetails.touched.interviewer &&
      !formikDetails.values.interviewer
    ) {
      formikDetails.setFieldError(
        "interviewer",
        "Interviewer data is required"
      );
    }
  };
  const handleSelectBlur = (event) => {
    if (event.currentTarget.contains(event.relatedTarget)) {
      formikDetails.setFieldTouched("interviewer", true);
      handleBlur();
    }
  };
  const today = dayjs();
  const handleEmailChange = (data) => {
    setSelectedEmails([...data]);
    if (data.length > 0) {
      formikDetails.setFieldValue("participants", [...data], true);
    } else {
      formikDetails.setFieldValue("participants", [], true);
    }
  };
  const handleListOfInterViewers = () => {
    setOpenAssignedInterviewModal(true);
    assignedInterViewerList();
  };
  const CloseListOfInterViewers = () => {
    setOpenAssignedInterviewModal(false);
  };
  let [assignedInterViewerData, setAssignedInterViewerData] = useState({});
  let assignedInterViewerList = async () => {
    let response;

    try {
      response = await getAssignedInterviewerDetails(selectedInterviewerId);

      if (response) {
        setAssignedInterViewerData(response.data.response);
      }
    } catch (err) {
      console.error(err, "shared dynamic table error");
    }
  };

  return (
    <div className="schedule-interview-form">
      <form onSubmit={formikDetails.handleSubmit}>
        <div className="form-group   individual-field text-left">
          <div className="mb-2 label">
            <span className="text-red-500 ">*</span>
            <span className="create-labels mx-1">Interviewer</span>
          </div>

          <div className="popins-medium" style={{ height: "80px" }}>
            <div
              className={`w-full user-email-input-tyle ${
                (formikDetails.errors.interviewer &&
                  formikDetails.touched.interviewer) ||
                (formikDetails.touched.interviewer &&
                  !formikDetails.values.interviewer)
                  ? "error-input"
                  : ""
              }`}
              onBlur={handleSelectBlur}
            >
              <Select
                // data={interviewerData}
                // onChange={handleInterviewerChange}
                // selectedData={selectedInterviewerData}
                // backspaceDelete={true}
                // searchable={true}
                // inputProps={{
                //   style: {
                //     padding: "14px 14px",
                //     minHeight: "53px !important",
                //   },
                // }}
                options={interviewerData}
                value={selectedInterviewerData}
                isSearchable={true}
                placeholder="Select interviewer"
                isClearable={false}
                onChange={handleInterviewerChange}
              />
            </div>
            {formikDetails.errors.interviewer &&
              formikDetails.touched.interviewer && (
                <div className="text-red-500 error_text md:mt-1 ml-1">
                  {formikDetails.errors.interviewer}
                </div>
              )}
              {selectedInterviewerId && (
          <p
            onClick={handleListOfInterViewers}
            className="viewInterviewList cursor-pointer relative "
          >
            view interview scheduled list
          </p>
        )}
          </div>
         
        </div>
      
        {/* <div className="individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Date and Time</span>
          </div>
          <div className="popins-medium">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                className="date-picker"
                name="dateAndTime"
                value={formikDetails.values.dateAndTime}
                onChange={(value) =>
                  formikDetails.setFieldValue("dateAndTime", value)
                }
                onBlur={formikDetails.handleBlur}
                disablePast={true} // Disable selecting past dates
                shouldDisableDate={disablePastDates}
              />
            </LocalizationProvider>
          </div>
          {formikDetails.errors.dateAndTime &&
          formikDetails.touched.dateAndTime ? (
            <div className="text-red-500 error_text md:mt-1">
              {formikDetails.errors.dateAndTime}
            </div>
          ) : null}
        </div> */}
        {/* <div className="individual-field mt-7">
          <div className="mb-2 label">
            <span className="create-labels">Interview Link</span>
          </div>
          <div className="popins-medium">
            <input
              className="input-field"
              type="text"
              name="interviewLink"
              value={formikDetails.values.interviewLink}
              onChange={formikDetails.handleChange}
              onBlur={formikDetails.handleBlur}
            />
          </div>
          {formikDetails.errors.interviewLink &&
          formikDetails.touched.interviewLink ? (
            <div className="text-red-500 error_text md:mt-1">
              {formikDetails.errors.interviewLink}
            </div>
          ) : null}
        </div> */}
        <div className="individual-field relative ">
          <div className="mb-2 label">
            <span className="create-labels">Date and Time</span>
          </div>
          <div className="popins-medium">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                minDate={today}
                slotProps={{
                  textField: (props) => ({
                    error: false,
                    readOnly: true,
                    // label: position === "start" ? "From" : "To",
                  }),
                }}
                value={dateValue}
                id="date"
                name="date"
                onChange={(newValue) => {
                  formikDetails.setFieldValue(
                    "date",
                    dayjs(newValue).format("YYYY-MM-DD")
                  );
                  setDateValue(newValue);
                }}
                format="DD-MM-YYYY"
                // placeholder={<span className="date-placeholder">Select...</span>}
                className="date-picker"
              />
            </LocalizationProvider>
            {formikDetails.errors.date &&
            formikDetails.touched.date &&
            !formikDetails.values.date ? (
              <div className="text-red-500 error_text md:mt-1">
                {formikDetails.errors.date}
              </div>
            ) : null}
          </div>
          <div className="mt-2 time-label">
            <span className="create-labels">From</span>
            <span className="create-labels">To</span>
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["MultiInputTimeRangeField"]}>
              <MultiInputTimeRangeField
                // disablePast={true}
                slotProps={{
                  textField: (props) => ({
                    error: false,

                    // label: position === "start" ? "From" : "To",
                  }),
                }}
                onChange={(value) => {
                  if (value[0] !== null && value[0]?.$d !== "Invalid Date") {
                    formikDetails.setFieldValue(
                      "fromTime",
                      dayjs(value[0]).format("hh:mm:ss A"),
                      true
                    );
                  }
                  if (value[1] !== null && value[1]?.$d !== "Invalid Date") {
                    formikDetails.setFieldValue(
                      "totime",
                      dayjs(value[1]).format("hh:mm:ss A"),
                      true
                    );
                  }
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          {/* {(formikDetails.errors.fromTime && formikDetails.touched.fromTime) ||
          (formikDetails.errors.totime && formikDetails.touched.totime) ? (
            <div className="text-red-500 error_text md:mt-1">
              {formikDetails.errors.totime || formikDetails.errors.totime}
            </div>
          ) : null} */}
          {(formikDetails.errors.fromTime && formikDetails.touched.fromTime) ||
  (formikDetails.errors.totime && formikDetails.touched.totime) ? (
    <div className="text-red-500 error_text md:mt-1">
      {formikDetails.errors.fromTime || formikDetails.errors.totime}
    </div>
  ) : null}

         
        </div>
        <div className="field-wrapper mt-2 individual-field">
          <div className="mb-2 label">
            <span className="create-labels">Other Participants</span>
          </div>
          <div className="input-ele">
            <MultiEmailInputField
              handleEmailChange={handleEmailChange}
              selectedEmails={selectedEmails}
              fieldName={"participants"}
            />
          </div>
        </div>

        <div className="individual-field mt-4">
          <div className="mb-2 label">
            <span className="create-labels">Meeting Link</span>
          </div>
          <div className="popins-medium">
            <input
              className="input-field"
              name="linkorlocation"
              id="linkorlocation"
              type={"text"}
              onChange={formikDetails.handleChange}
              onBlur={(e) => {
                formikDetails.handleBlur(e);
                // Add the following line to mark the field as touched
                formikDetails.setFieldTouched("linkorlocation", true);
              }}
            />
          </div>
          {formikDetails.errors.linkorlocation &&
          formikDetails.touched.linkorlocation ? (
            <div className="text-red-500 error_text md:mt-1">
              {formikDetails.errors.linkorlocation}
            </div>
          ) : null}
        </div>

        <div className="fixed  bottom-3 right-7">
          <div className="hire-now-btn">
            <button className="datatrimmer" type="submit" disabled={isSubmitting} >
              Update
            </button>
          </div>
        </div>
      </form>

      {openAssignedInterviewModal && (
        <AssignedInterviewerModal
          selectedInterviewerId={selectedInterviewerId}
          assignedInterViewerData={assignedInterViewerData}
          closeModal={CloseListOfInterViewers}
        />
      )}
    </div>
  );
};

export default ScheduleInterview;
