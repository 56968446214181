import React from 'react';
import "./HowCanHelp.scss";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import Tada from 'react-reveal/Tada';
import CircularIcon from "../website-assets/circle.png";
import CloudDownloadIcon from "../website-assets/cloudDownload.png";
import NetworkingIcon from "../website-assets/networking.png";

const HowCanHelp = () => {
  return (
    <div className='mainHelpContainer'>
      <Slide top cascade>
        <h1 className="headerContent">How we can help ?</h1>
        <p className="subHeader">Making your job search easy</p>
      </Slide>
      <div className='mainContent  row'>
        <div className='postingJobs col-md-4 col-sm-12'>
          <Tada delay={0} duration={2000}><img src={CircularIcon} /></Tada>
          <p>Free Job Posting</p>
          <p>Post job openings at zero cost and reach top talent effortlessly.</p>

        </div>
        <div className='AllJobs col-md-4 col-sm-12'>
          <Tada delay={0} duration={2000}> <img src={CloudDownloadIcon} style={{ height: "85px" }} /></Tada>
          <p>All Types of Jobs</p>
          <p>Browse through thousands of jobs across hundreds of job types and industries.</p>

        </div>
        <div className='networking col-md-4 col-sm-12'>
          <Tada delay={0} duration={2000}><img src={NetworkingIcon} /></Tada>
          <p>Power of Networking</p>
          <p>Unlock opportunities and amplify career growth through the unparalleled power of networking.
          </p>

        </div>

      </div>

    </div>
  )
}

export default HowCanHelp