import React, { useEffect, useState } from "react";
import "./AdminJobPostDetails.scss";

const AdminJobPostDetails = (props) => {
  console.log(props);
  let [skillSet, setSkillSet] = useState("");
  useEffect(() => {
    let skills = "";
    props.selectedJob?.keySkills?.map((data, index) => {
      if (index === 0) {
        skills = data;
      } else {
        skills = skills + "," + data;
      }
    });
    setSkillSet(skills);
  }, []);
  
  return (
    <div className="post-job-details-modal-admin">
      <div className="job-modal-body">
        <div
          className="job-modal-header datatrimmer"
          title={
            props?.selectedJob?.jobHeadlines
              ? props?.selectedJob?.jobHeadlines
              : "--"
          }
        >
          Job Details -{" "}
          {props?.selectedJob?.jobHeadlines
            ? props?.selectedJob?.jobHeadlines
            : "--"}
        </div>
        <div className="job-form grid grid-cols-3">
          <div className="mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Job Headline</span>
            </div>
            <div
              className="popins-medium field-Data datatrimmer"
              title={
                props?.selectedJob?.jobHeadlines
                  ? props?.selectedJob?.jobHeadlines
                  : "--"
              }
            >
              {props?.selectedJob?.jobHeadlines
                ? props?.selectedJob?.jobHeadlines
                : "--"}
            </div>
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Roles</span>
            </div>
            <div className="field-Data datatrimmer">
              {props.selectedJob?.roles ? props.selectedJob.roles : "--"}
            </div>
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Key Skills</span>
            </div>
            <div
              className="field-Data datatrimmer"
              title={skillSet ? skillSet : "--"}
            >
              {skillSet ? skillSet : "--"}
            </div>
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Openings</span>
            </div>
            <div className="field-Data">
              {props.selectedJob?.vacancyCount
                ? props.selectedJob?.vacancyCount
                : "--"}
            </div>
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Work Mode</span>
            </div>
            <div className="field-Data">
              {props.selectedJob?.workMode ? props.selectedJob?.workMode : "--"}
            </div>
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Work Experience</span>
            </div>
            <div className="multi-data-eles-work flex">
              <div className="multi-data-1">
                <span className="label">Min: </span>
                <span className="datatrimmer">
                  {props.selectedJob?.workExperience?.min
                    ? props.selectedJob?.workExperience.min
                    : "0"}
                </span>
              </div>
              <div className="multi-data-2">
                <span className="label">Max: </span>
                <span className="datatrimmer">
                  {props.selectedJob?.workExperience?.max
                    ? props.selectedJob?.workExperience.max
                    : "0"}
                </span>
              </div>
            </div>
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Salary Range / Yr</span>
            </div>
            <div className="multi-data-eles flex">
              <div className="multi-data-1">
                <span className="label">Min: </span>
                <span className="datatrimmer">
                  {props.selectedJob?.salaryRange?.min
                    ? props.selectedJob?.salaryRange.min
                    : "0"}
                </span>
              </div>
              <div className="multi-data-2">
                <span className="label">Max: </span>
                <span className="datatrimmer">
                  {props.selectedJob?.salaryRange?.max
                    ? props.selectedJob?.salaryRange.max
                    : "0"}
                </span>
              </div>
            </div>
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Location</span>
            </div>
            <div className="field-Data">
            {props.selectedJob?.location ? props.selectedJob?.location.join(', ') : "--"}
            </div>
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Industry</span>
            </div>
            <div className="field-Data">
              {props.selectedJob?.industry ? props.selectedJob?.industry : "--"}
            </div>
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Functional Area</span>
            </div>
            <div className="field-Data">
              {props.selectedJob?.functionalArea
                ? props.selectedJob?.functionalArea
                : "--"}
            </div>
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Vacancy Count</span>
            </div>
            <div className="field-Data">
              {props.selectedJob?.vacancyCount
                ? props.selectedJob?.vacancyCount
                : "0"}
            </div>
          </div>
          <div className="field-wrapper my-2 individual-field">
            <div className="label">
              <span className="create-labels">Gender</span>
            </div>
            <div className="field-Data">
              {props.selectedJob?.gender.length >0
                ? props.selectedJob?.gender.join(", ")
                : "--"}
            </div>
          </div>
          <div className="field-wrapper my-2 individual-field">
            <div className="label">
              <span className="create-labels">Percentage</span>
            </div>
            <div className="field-Data">
              {props.selectedJob?.percentage
                ? props.selectedJob?.percentage
                : "--"}
            </div>
          </div>
          <div className="field-wrapper mt-2 col-span-3 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Job Description</span>
            </div>
            <div className="field-Data ">
              {props.selectedJob?.jobDescription
                ? props.selectedJob?.jobDescription
                : "--"}
            </div>
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Highest Qualification</span>
            </div>
            <div className="field-Data">
              {props.selectedJob?.highestQualification
                ? props.selectedJob?.highestQualification
                : "--"}
            </div>
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Course</span>
            </div>
            <div className="field-Data">
              {props.selectedJob?.course
                ? props.selectedJob?.course.join(", ")
                : "--"}
            </div>
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Specialization</span>
            </div>
            <div className="field-Data">
              {props.selectedJob?.specialization
                ? props.selectedJob?.specialization.join(", ")
                : "--"}
            </div>
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Last Date For Sharing Profiles</span>
            </div>
            <div className="field-Data">
              {props.selectedJob?.dateForSharingProfiles
                ? props.selectedJob?.dateForSharingProfiles
                : "--"}
            </div>
          </div>
          <div className="field-wrapper my-2 individual-field">
            <div className="label"></div>
            <div className="field-Data"></div>
          </div>
          <div className=" col-span-3 client-modal-header  border-b-0 border-indigo-500">
            Client Details
          </div>
          <div className="field-wrapper my-2 individual-field">
            <div className="label">
              <span className="create-labels">Email</span>
            </div>
            <div className="field-Data">
              {props.selectedJob?.clientEmail
                ? props.selectedJob?.clientEmail
                : "--"}
            </div>
          </div>

          <div className="field-wrapper my-2 individual-field pb-2">
            <div className="label">
              <span className="create-labels">Phone Number</span>
            </div>
            <div className="field-Data">
              {props.selectedJob?.clientMobile
                ? props.selectedJob?.clientMobile
                : "--"}
            </div>
          </div>
         
        </div>
       
      </div>
      <div className="job-submit-btn items-center ml-auto">
        <button
          className="datatrimmer cancel-btn mr-4"
          onClick={() => {
            props.closeModal(true);
          }}
          title={""}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AdminJobPostDetails;
