import { Routes, Route } from "react-router-dom";
import BreadCrumbs from "../../common/BreadCrumbs";
import PageHeader from "./InterviewerPageHeader";
import PageLeftMenu from "./InterviewerPageLeftMenu";
import CandidateList from "../candidate-list/CandidateList";
import UnderDevelopment from "../../common/UnderDevelopment";

function InterviewerHome() {
  return (
    <div>
      <div className="">
        <PageHeader />
      </div>
      <div className="flex">
        <div>
          <PageLeftMenu />
        </div>
        <div className="w-full home-component-display overflow-auto">
          <BreadCrumbs />
          <Routes>
            <Route path="/" element={<CandidateList tabletitleRow={true} />} />
            <Route
              path="/candidates"
              element={<CandidateList tabletitleRow={true} />}
            />
            <Route
              path="/dashboard"
              element={<UnderDevelopment />}
            />
            <Route
              path="/configuration"
              element={<UnderDevelopment />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default InterviewerHome;
