import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  MultiSelectDropdown,
  SelectDropdown,
} from "../../landing-page/common/select-dropdown";
import * as yup from "yup";
import { useFormik } from "formik";
import { stringToDropdownValue } from "../../../util/common-utils";
import {
  getskillsListReq,
  getIndustriesListReq,
  getroleListReq,
} from "../../../api/api-services";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const TechnicalDetailsForm = (props) => {
  const [dropDownData, setDropDownData] = useState({
    domain: [],
    jobTitle: [],
    skills: [],
  });
  const [selectedDropdownData, setSelectedDropdownData] = useState({
    domain: [],
    jobTitle: [],
    skills: [],
  });
  let [candidateDetails, setCandidateDetails] = useState({
    ...props.candidateDetailsData,
  });
  let userID = JSON.parse(localStorage.getItem("across-geo-user")).uid;
  const [selectedFile, setSelectedFile] = useState();
  const [selecteFilename, setSelecteFilename] = useState();
  const [resume, setResume] = useState();
  // const[checkState, setCheckState] = useState([])
  const [resumeError, setResumeError] = useState(false);
  const skillWithRatingConverter = (values) => {
    console.log(values, "skillWithRatingConverter");
    values["skills"] =
      values.skills.length > 0 && typeof values.skills[0] === "string"
        ? values?.skills?.map((skill) => {
          return {
            rating: "0.0",
            skillName: skill,
          };
        })
        : values.skills;
    return { technicalDetails: values };
  };
  const onSubmit = async (values) => {
    // if (!resume) {
    //   setResumeError(true)
    // } else {
    //   setResumeError(false)
    props.fetchDataFromForms(skillWithRatingConverter(values), true);
    // }
    console.log("technicalDetails values", values);
  };
  const handleDropdownChange = (data, type, keysToBeCleared) => {
    console.log(selectedDropdownData?.jobTitle,"selectedDropdownData?.jobTitle")
    let allSelectedData = { ...selectedDropdownData };
    if (type === "skills") {
      let skills = [];
      data?.map((val) => {
        skills.push(val.value);
      });
      allSelectedData[type] = data;
      formik.setFieldValue(type, [...skills]);
    } else {
      allSelectedData[type] = [...data];
      formik.setFieldValue(type, data?.[0]?.value);
    }
    keysToBeCleared?.forEach((element) => {
      if (type === "domain") {
        let clear = document.getElementsByClassName("css-1dimb5e-singleValue")
        if (clear[1]?.innerHTML !== "" && clear[1]?.innerHTML !== undefined) {
          console.log(clear[1]?.innerHTML)
          clear[1].innerHTML = 'Select or create your role'
        }
        formik.values['jobTitle'] = '';
      }
      if (element === "skills") {
        formik.values[element] = [];
      } else {
        formik.values[element] = "";
      }
      allSelectedData[element] = [];
    });
    setSelectedDropdownData({ ...allSelectedData });
  };

  const validationSchema = yup.object({
    domain: yup.string().required("Domain is required"),
    jobTitle: yup.string().required("Job Role is required"),
    skills: yup.array().min(1, "Skills are required"),
  });
  let initialValues = {};
  if (props.data) {
    initialValues = {
      domain: props.data.domain,
      jobTitle: props.data.jobTitle,
      skills: props.data.skills,
    };
  } else {
    initialValues = {
      domain: "",
      jobTitle: "",
      skills: [],
    };
  }
  const resumeDataEmpty = () => {
    setResume("");
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  useEffect(() => {
    if (props?.data) {
      // let tempSkills = [];
      // props?.data?.skills?.map((skill) => {
      //   tempSkills.push(stringToDropdownValue(skill.skillName));
      // });
      const tempSkills = Array.isArray(props.data.skills)
        ? props.data.skills.map((skill) =>
          stringToDropdownValue(skill.skillName)
        )
        : [];
      resumeDataEmpty();
      setSelectedDropdownData({
        ...selectedDropdownData,
        skills: tempSkills,
        domain: [stringToDropdownValue(props.data.domain)],
        jobTitle: [stringToDropdownValue(props.data.jobTitle)],
        resumeDetails: props.data.resumeDetails,
      });
    }
  }, []);

  useEffect(() => {
    setResume(props?.candidateDetailsData?.resume);
  }, [props.candidateDetailsData]);
  const fileHandler = (e) => {
    console.log("fileHandler", e.target.files[0]);
    let fileObj = e.target.files[0];
    let fileName = fileObj.name;
    setSelectedFile(fileObj);
    setSelecteFilename(fileName);
    setResume(userID + "/" + fileName);
    setResumeError(false);
  };
  const getIndustries = async (key) => {
    try {
      const res = await getIndustriesListReq();
      console.log("All industries Data", res);
      if (res?.data) {
        let tempIndustry = res.data.response.map((val) => {
          return stringToDropdownValue(val);
        });
        setDropDownData({
          ...dropDownData,
          [key]: tempIndustry,
        });
      } else {
        setDropDownData({ ...dropDownData, [key]: [] });
      }
    } catch (e) {
      console.log("countires error", e);
    }
  };
  const getRoles = async (key, industry) => {
    let encodedValue = encodeURIComponent(industry);
    console.log(key, industry,"key, industry")
    try {
      const res = await getroleListReq(encodedValue);
      console.log("All roles Data", res);
      if (res?.data) {
        let tempRole = res.data.response.map((val) => {
          return stringToDropdownValue(val);
        });
        setDropDownData({ ...dropDownData, [key]: tempRole });
      } else {
        setDropDownData({ ...dropDownData, [key]: [] });
      }
    } catch (e) {
      console.log("roles error", e);
    }
  };
  const getSkills = async (key, industry, role) => {
    try {
      const res = await getskillsListReq(industry, role);
      console.log("All skills Data", res);
      if (res?.data) {
        let tempCity = res.data.response.map((val) => {
          return stringToDropdownValue(val);
        });
        setDropDownData({ ...dropDownData, [key]: tempCity });
      } else {
        setDropDownData({ ...dropDownData, [key]: [] });
      }
    } catch (e) {
      console.log("cities error", e);
    }
  };
  const getIndustriesHandler = () => {
    if (formik.values.domain?.value !== null) {
      getIndustries("domain");
    }
  };
  const getRolesHandler = () => {
    if (formik.values.domain?.value !== null) {
      getRoles("jobTitle", formik.values.domain);
    }
  };
  const getSkillsHandler = () => {
    if (
      formik.values.domain?.value !== null &&
      formik.values.jobTitle?.value !== null
    ) {
      getSkills("skills", formik.values.domain, formik.values.jobTitle);
    }
  };
  return (
    <div className="technical-details-form">
      <form className="details-form-tag" onSubmit={formik.handleSubmit}>
        <div className="details-form-body grid grid-cols-3">
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Domain</span>
            </div>
            <div className="input-ele">
              <Select
                options={dropDownData?.domain}
                value={
                  selectedDropdownData?.domain[0]?.value === ""
                    ? []
                    : selectedDropdownData?.domain
                }
                isSearchable={true}
                placeholder="Select domain"
                isClearable={false}
                onChange={(data) => {
                  handleDropdownChange([data], "domain", [
                    "jobTitle",
                    "skills",
                  ]);
                }}
                onMenuOpen={getIndustriesHandler}
              />
            </div>
            {formik.errors?.domain && formik.touched?.domain ? (
              <div className="text-red-500 error_text md:mt-1">
                {formik.errors.domain}
              </div>
            ) : null}
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Job Role</span>
            </div>
            <div className="input-ele">
              {console.log(selectedDropdownData?.jobTitle,"selectedDropdownData?.jobTitle")}
              <CreatableSelect
                options={dropDownData?.jobTitle}
                // value={checkState[0]}
                value={
                  selectedDropdownData?.jobTitle[0]?.value === ""
                    ? null
                    : selectedDropdownData?.jobTitle[0]
                }
                isSearchable={true}
                placeholder="Select or create your role"
                isClearable={false}
                onChange={(data) => {
                  handleDropdownChange(data ? [data] : [], "jobTitle", ["skills"]);
                }}
                onMenuOpen={getRolesHandler}
                isDisabled={formik.values.domain !== "" ? false : true}
              // ... other props
              />

            </div>
            {formik.errors?.jobTitle && formik.touched?.jobTitle ? (
              <div className="text-red-500 error_text md:mt-1">
                {formik.errors.jobTitle}
              </div>
            ) : null}
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Skills</span>
            </div>
            <div className="input-ele">
              {/* <Select
                options={dropDownData?.skills}
                value={selectedDropdownData?.skills}
                isSearchable={true}
                isMulti={true}
                placeholder="Select your skills"
                isClearable={false}
                className="my-select-container"
                onChange={(data) => {
                  handleDropdownChange(data, "skills");
                }}
                onMenuOpen={getSkillsHandler}
                isDisabled={
                  formik.values.domain !== "" && formik.values.jobTitle !== ""
                    ? false
                    : true
                }
              /> */}
              <CreatableSelect
                options={dropDownData?.skills}
                value={selectedDropdownData?.skills}
                isMulti
                isSearchable={true}
                placeholder="Select or create your skills"
                isClearable={false}
                className="my-select-container"
                onChange={(data) => {
                  handleDropdownChange(data, "skills");
                }}
                onMenuOpen={getSkillsHandler}
                isDisabled={
                  formik.values.domain !== "" && formik.values.jobTitle !== ""
                    ? false
                    : true
                }
              />
            </div>
            {formik.errors?.skills && formik.touched?.skills ? (
              <div className="text-red-500 error_text md:mt-1">
                {formik.errors.skills}
              </div>
            ) : null}
          </div>
        </div>
        <div className="field-wrapper mt-2 ml-8 flex relative mb-0 pb-0 flex-wrap hidden">
          <span
            style={{ display: "inline-block", marginTop: "10px" }}
            className="big-size-file"
          >
            <button
              type="button"
              style={{
                padding: "5px 15px",
                border: " 1px solid #989797",
                borderRadius: "20px",
                color: "#727171",
                cursor: "pointer !important",
                fontSize: "15px",
                fontFamily: "OpenSans-Regular",
              }}
            >
              {resume ? "Replace Resume" : "Update Resume"}
            </button>
            <input
              type="file"
              accept=".pdf"
              multiple={false}
              onChange={fileHandler}
              style={{
                position: "absolute",
                zIndex: 2,
                opacity: 0,
                height: "100%",
                width: "80px",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
            />
          </span>
          <div
            style={{ display: "inline-block", marginTop: "10px" }}
            className="ml-6"
          >
            <div className="flex relative">
              <div>
                {resume ? resume?.split("/")[1] : ""}
                {resume && (
                  <span>
                    <CloseIcon
                      fontSize="small"
                      color="error"
                      onClick={resumeDataEmpty}
                    ></CloseIcon>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        {resumeError && <span className="ml-8 text-red-500 text-xs">Please upload your resume</span>}
        <div className="my-4 details-form-footer-section">
          <button className="datatrimmer submity-btn" title={""} type="submit">
            {props.completeProfileData ? "ADD" : "Update"}
          </button>
          <button
            className="datatrimmer previous-btn mr-4"
            onClick={() => {
              props.changeTab("workDetails");
              props.fetchDataFromForms(
                skillWithRatingConverter(formik.values),
                false
              );
            }}
            title={""}
            type="button"
          >
            Previous
          </button>
          {props.completeProfileData ?
            <button
              className="cursor-not-allowed"
              disabled="true"
              style={
                {
                  fontSize: "12px",
                  height: "30px",
                  width: "94px",
                  backgroundColor: "#f2f2f2",
                  border: "1px solid #dadada",
                  color: "#525252",
                  borderRadius: "32px",
                  opacity: "1",
                  float: "right",
                  marginRight: "10px"
                }}
              onClick={() => {
                props.closeScreen();
              }}
              title="Please enter the details"
            >
              Cancel
            </button> :
            <button
              disabled={props.completeProfileData}
              className="datatrimmer cancel-btn mr-4"
              onClick={() => {
                props.closeScreen(true);
              }}
              title={
                props.completeProfileData ? "Please enter the value" : "Cancel"
              }
              type="button"
            >
              Cancel
            </button>
          }
        </div>
      </form>
    </div>
  );
};

export default TechnicalDetailsForm;
