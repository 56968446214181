import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import "./InterviewerPageLeftMenu.scss";
import { useNavigate, useLocation } from "react-router-dom";

import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { ReactComponent as DashboardIcon } from "../../../assets/dashboard.svg";
import { ReactComponent as CandidatesIcon } from "../../../assets/candidate.svg";
import { ReactComponent as ConfigIcon } from "../../../assets/configuration.svg";

import { Tooltip } from "@material-ui/core";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRight: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  zIndex: 0,
  top: "61px",
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "55px",
  borderRight: "none",
  [theme.breakpoints.up("sm")]: {},
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
function InterviewerPageLeftMenu(props) {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(true);
  const [configMenu, setConfigMenu] = useState(false);
  const [inconfigMenu, setInconfigMenu] = useState(false);
  let getSideMenuIcon = (type) => {
    if (type === "Candidates") {
      return <CandidatesIcon />;
    } else if (type === "Configuration") {
      return <ConfigIcon />;
    } else {
      return <DashboardIcon />;
    }
  };
  const [leftMenuItems, setLeftMenuItems] = useState([
    {
      name: "Dashboard",
      path: "/interviewer/dashboard",
      icon: getSideMenuIcon("Dashboard"),
    },
    {
      name: "Candidates",
      path: "/interviewer/candidates",
      icon: getSideMenuIcon("Candidates"),
    },
    {
      name: "Configuration",
      path: "/interviewer/configuration",
      icon: getSideMenuIcon("Configuration"),
    },
  ]);

  useEffect(() => {
    if (location.pathname.includes("/project/config")) {
      setInconfigMenu(true);
    } else {
      if (configMenu) {
        setConfigMenu(false);
      }
      setInconfigMenu(false);
    }
  }, [location.pathname]);

  let menuRef = useRef();
  useEffect(() => {
    let handeler = (event) => {
      if (menuRef.current && !menuRef?.current?.contains(event.target)) {
        setShowSideMenu(false);
        setConfigMenu(false);
        setShowConfigSubMenu(false);
      }
    };
    document.addEventListener("mousedown", handeler);
    return () => {
      document.removeEventListener("mouseleave", handeler);
    };
  }, []);

  const [showConfigSubMenu, setShowConfigSubMenu] = useState(false);

  const handleMouseLeaveConfig_sub = () => {
    setShowConfigSubMenu(false);
  };
  return (
    <div ref={menuRef}>
      <Box sx={{ display: "flex" }}>
        <Drawer variant="permanent" open={open}>
          <List>
            <div className="left-menu">
              {leftMenuItems.map((data) => {
                return (
                  <div className="my-6  flex allProjectDrawer">
                    <NavLink
                      className={({ isActive }) =>
                        (isActive ||
                          (location.pathname === "/interviewer" &&
                            data.name === "Candidates")) &&
                        !configMenu &&
                        open
                          ? "active-drawer-close left-menu-items-select item-hover-effect -ml-1  focus:outline-none left-menu-icon"
                          : (isActive ||
                              (location.pathname === "/interviewer" &&
                                data.name === "Candidates")) &&
                            !configMenu &&
                            !open
                          ? "text-blue-700 -ml-1 active-drawer-close2 item-hover-effect  focus:outline-none left-menu-icon"
                          : "flex ml-3 left-menu-items-unselect  item-hover-effect focus:outline-none left-menu-icon"
                      }
                      to={data.path}
                    >
                      {data.icon}
                      <Tooltip title={data.name}>
                        <div className="flex overview_icon datatrimmer">
                          <span className="ml-2">{open ? data.name : ""}</span>
                        </div>
                      </Tooltip>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </List>
          <Divider />
        </Drawer>
      </Box>
    </div>
  );
}

export default InterviewerPageLeftMenu;
