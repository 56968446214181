import React, { useState, useEffect } from "react";
import "./AdminClientDetails.scss";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useLocation } from "react-router-dom";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import AdminClientJobPosts from "./AdminClientJobPosts";
import {
  getClientJobPost,
  getClientaddedResume,
  getClientOffered,
} from "../../../api/api-services";

const AdminClientDetails = () => {
  const { state } = useLocation();
  const { activeTab, requestedProfile, selectedRow,companyDetails } = state;
  const [selectedTab, setSelectedTab] = useState(activeTab);
  const [showMore, setShowMore] = useState(selectedRow === 1);
  const [showMoreContent, setShowMoreContent] = useState(selectedRow === 1);
  console.log({companyDetails});
  const [newJobList, setNewJobList] = useState([]);
  const [totalnewJobListCount, setTotalnewJobListCount] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let size = 10;
  const fetchNewJobPosts = async (page) => {
    try {
      const response = await getClientJobPost(
        requestedProfile.companyName,
        page,
        size
      );
      console.log(response.data.response, "fetchNewJobPosts");
      setTotalnewJobListCount(response.data.response.totalElements);
      setIsLoading(false);
      if (page === 0) {
        setNewJobList([...response.data.response.content]);
      } else {
        setNewJobList([...newJobList, ...response.data.response.content]);
      }
      if (response?.data?.response?.last === false) {
        setpageNo(page + 1);
      }
      setLastpage(response.data.response.last);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (selectedTab === 0) {
  //     fetchNewJobPosts(pageno);
  //   }
  // }, []);
  const [hasAddedJobPost, setHasAddedJobPost] = useState(false);

  useEffect(() => {
    if (!hasAddedJobPost) {
      setHasAddedJobPost(true);
      fetchNewJobPosts(pageno);
    }
  }, [hasAddedJobPost]);

  // added resume

  const [addedResumeList, setAddedResumeList] = useState([]);
  const [totalAddedResumeList, setTotalAddedResumeList] = useState("");
  const [isLoadingResume, setIsLoadingResume] = useState(true);
  let [pagenoResumeList, setPagenoResumeList] = useState(0);
  let [lastPageResumeList, setLastPageResumeList] = useState(false);
  const [resumeCountData,setResumeCountData]=useState([])

  const fetchAddedResume = async (page) => {
    try {
      const response = await getClientaddedResume(
        requestedProfile.companyName,
        page,
        size
      );
      setTotalAddedResumeList(response.data.response.totalElements);
      const responseData = response.data.response;
      const jobPosts = responseData.content.map((item) => item.jobPost);
      const candidateCount = (responseData.content.map((item) => item.candidateCount))
      setIsLoadingResume(false);
      if (page === 0) {
        setAddedResumeList([...jobPosts]);
        setResumeCountData([...candidateCount])
      } else {
        // setAddedResumeList([...addedResumeList, ...response.data.response.content]);
        setAddedResumeList((prevList) => [...prevList, ...jobPosts]);
        setResumeCountData((prevList) => [...prevList, ...candidateCount]);
      }
      setPagenoResumeList(page + 1);
      setLastPageResumeList(response.data.response.last);
    } catch (error) {
      console.error(error);
      setIsLoadingResume(false);
    }
  };
  const [hasAddedResume, setHasAddedResume] = useState(false);

  useEffect(() => {
    if (!hasAddedResume) {
      setHasAddedResume(true);
      fetchAddedResume(pagenoResumeList);
    }
  }, [hasAddedResume]);

  // Offered resume

  const [offeredList, setOfferedList] = useState([]);
  const [totalOfferedList, setTotalOfferedList] = useState("");
  const [isLoadingOffered, setIsLoadingOffered] = useState(true);
  let [pagenoOfferedList, setPagenoOfferedList] = useState(0);
  let [lastPageOfferedList, setLastPageOfferedList] = useState(false);
  const [offeredCountData,setOfferedCountData]=useState([])

  const fetchOfferedResume = async (page) => {
    try {
      const response = await getClientOffered(
        requestedProfile.companyName,
        page,
        size
      );
      setTotalOfferedList(response.data.response.totalElements);
      const responseData = response.data.response;
      const OfferedCandidateCount = (responseData.content.map((item) => item.candidateCount))
      const offeredResponse = responseData.content.map((item) => item.jobPost);
      setIsLoadingOffered(false);
      if (page === 0) {
        setOfferedList([...offeredResponse]);
        setOfferedCountData([...OfferedCandidateCount])
      } else {
        // setOfferedList([...addedResumeList, ...response.data.response.content]);
        setOfferedList((prevList) => [...prevList, ...offeredResponse]);
        setOfferedCountData((prevList) => [...prevList, ...OfferedCandidateCount]);
      }
      setPagenoOfferedList(page + 1);
      setLastPageOfferedList(response.data.response.last);
    } catch (error) {
      console.error(error);
      setIsLoadingOffered(false);
    }
  };
  const [hasOffered, setHasOffered] = useState(false);

  useEffect(() => {
    if (!hasOffered) {
      setHasOffered(true);
      fetchOfferedResume(pagenoOfferedList);
    }
  }, [hasOffered]);

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;

    if (scrollTop + clientHeight >= scrollHeight - 20) {
      if (selectedTab === 0 && lastPage === false && !isLoading) {
        setIsLoading(true); // Set isLoading to prevent concurrent API calls
        fetchNewJobPosts(pageno);
      } else if (
        selectedTab === 1 &&
        lastPageResumeList === false &&
        !isLoadingResume
      ) {
        setIsLoadingResume(true); // Set isLoading to prevent concurrent API calls
        fetchAddedResume(pagenoResumeList);
      } else if (
        selectedTab === 2 &&
        lastPageOfferedList === false &&
        !isLoadingOffered
      ) {
        setIsLoadingOffered(true); // Set isLoading to prevent concurrent API calls
        fetchOfferedResume(pagenoOfferedList);
      }
    }
  };

  useEffect(() => {
    setSelectedTab(activeTab);
  }, [activeTab]);

  const handleTabSelect = (index) => {
    setSelectedTab(index);
    setShowMoreContent(false);
    setShowMore(false);
  };
  const toggleLocationText = () => {
    setShowMore(!showMore);
    if (!showMore) {
      setShowMoreContent(true);
    } else {
      setShowMoreContent(false);
    }
  };

  const handleShareProfile = (job) => {
    console.log("Share Profile", job);
    // Add your logic for Share Profile button here
  };

  const handleResumeClick = (job) => {
    console.log("Resume Click", job);
    // Add your logic for Resume button here
  };

  const handleOfferedClick = (job) => {
    console.log("Offered Click", job);
    // Add your logic for Offered button here
  };

  return (
    <div
      className="adminClientDetails mx-3 w-full home-component-display mt-2.5  overflow-x-hidden overflow-y-auto"
      onScroll={handleScroll}
    >
      <div className="headerContent">
        <div className="grid grid-cols-2 gap-4">
          <div className="companyDetails ">
            <div className="flex items-center" title={companyDetails.name}>
              <p className="companyLogo">
                {companyDetails?.name.slice(0, 2)}
              </p>
              <p className="mx-2 companyName">{companyDetails.name}</p>
            </div>
            <p className="locationIcon flex">
              {" "}
              {/* <RoomOutlinedIcon /> <p>{companyDetails?.location}</p> */}
              <button
                className="seeMoreButton flex justify-center mx-4"
                onClick={toggleLocationText}
              >
                {showMore ? "See less" : "See more"}
              </button>
            </p>
          </div>
          <div className="candidateDetails">
            <p onClick={()=>{console.log(requestedProfile)}}>
              {/* <span>{totalnewJobListCount}</span> */}
              <span>{requestedProfile?.jobPosts}</span>
              <br />
              <label>Job Posts</label>
            </p>
            <p>
              <span>{requestedProfile?.requested}</span>
              <br />
              <label>Candidate Request</label>
            </p>
            <p>
              <span>{requestedProfile?.shared}</span>
              <br />
              <label>Added Resume</label>
            </p>
            <p>
              <span>{requestedProfile?.offered}</span>
              <br />
              <label>Offered</label>
            </p>
          </div>
        </div>

        {showMoreContent && (
          <div className="showMoreContent">
         
            <label className="label">Overview</label>
            <p className="value">
              {companyDetails?.description}
            </p>
            <div className=" grid grid-cols-12 gap-4  flex">
              <div className="col-span-6">
                <div className="label">Website</div>
                <a target="_blank" href={`${companyDetails?.website}`} className="text-blue-700"> {companyDetails?.website}</a>
              </div>
              <div className="col-span-6 ">
                <div className="label">Industry</div>
                <div className="value">
                 {companyDetails?.industry}
                </div>
              </div>
            </div>

            <div className=" grid grid-cols-12 gap-4  flex my-2">
              <div className="col-span-6">
                <div className="label">Company Size</div>
                <div className="value"> {companyDetails?.no_of_employee}</div>
              </div>
              <div className="col-span-6">
                <div className="label">Headquarters</div>
                <div className="value"> {companyDetails?.headquarters}</div>
              </div>
            </div>

            <div className=" grid grid-cols-12 gap-4  flex">
              <div className="col-span-6">
                <div className="label">Specialities</div>
                <div className="value">
                {companyDetails?.specialities}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {state.activeTab !== null && (
        <div className="newPage my-2">
          <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
            <div className="pannelTabs">
              <TabList
                className="flex  outline-none "
                style={{ height: "44px" }}
              >
                <Tab className={selectedTab === 0 ? "selectedTab" : ""}>
                  Job Posts
                </Tab>
                <Tab
                  className={selectedTab === 1 ? "selectedTab  mx-4" : " mx-4"}
                >
                  Added Resume
                </Tab>
                <Tab className={selectedTab === 2 ? "selectedTab" : ""}>
                  Offered
                </Tab>
              </TabList>
            </div>

            <TabPanel>
              <AdminClientJobPosts
                jobData={newJobList}
                selectedTab={selectedTab}
                buttonText="+ Share Profile"
                onClick={handleShareProfile}
              />
            </TabPanel>
            <TabPanel>
              <AdminClientJobPosts
                jobData={addedResumeList}
                selectedTab={selectedTab}
               
                onClick={handleResumeClick}
                resumeCount={resumeCountData}
              />
            </TabPanel>
            <TabPanel>
              <AdminClientJobPosts
                jobData={offeredList}
                selectedTab={selectedTab}
                resumeCount={offeredCountData}
                onClick={handleOfferedClick}
                showMore={showMore}
              />
            </TabPanel>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default AdminClientDetails;
