import React, { useState } from "react";
import Modal from "react-modal";
import "react-phone-input-2/lib/material.css";
import { Button, Grid, Typography } from "@material-ui/core";

const customStylesPage = {
    overlay: {
        background: "rgba(90, 90, 90,0.5)",
    },
    content: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "auto",
        height: "auto",
        background: "#fbfcfd 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 11px #000000a1",
        borderRadius: "4px",
        opacity: "1",
        outline: "none",
        index: "9999",
    },
};

function DetailsPage(props) {
    console.log(props, "props")
    return (
        <Modal
            isOpen={props.detailModel}
            style={customStylesPage}
            className="p-px"
            ariaHideApp={false}
        >
            <div className="addInterviewerModal userDetailsModal">
                <div className="flex justify-between create-modal-heading header-Font  p-2">
                    <p> Details</p>
                </div>
                <Grid
                    className="p-4"
                    container
                    rowSpacing={4}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid className="py-2 flex-items-center" item xs={4}>
                        <div>
                            <Typography variant="h6" className="semiBoldpoppins">Name</Typography>
                            <Typography className="labels">
                                {(props.clintList?.name?.length > 10)? props.clintList.name.substring(0, 22) + "...":props.clintList.name}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid className="py-2 flex-items-center" item xs={4}>
                        <div>
                            <Typography variant="h6" className="semiBoldpoppins">Mobile</Typography>
                            <Typography className="labels">
                                {props.clintList.mobile}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid
                        className="pr-4 flex-items-center"
                        item
                        xs={4}
                        title={"title of the mod"}
                    >
                        <Typography variant="h6" className="semiBoldpoppins">Email</Typography>
                        <Typography className="datatrimmer labels">
                            {props.clintList.email}
                        </Typography>
                    </Grid>
                    <Grid className="py-2 flex-items-center" item xs={4}>
                        <Typography variant="h6" className="semiBoldpoppins">Location</Typography>
                        <Typography className="labels">
                            {props.clintList.location?props.clintList.location:"--"}
                        </Typography>
                    </Grid>

                    {/* <Grid item className="py-2 flex-items-center" xs={12}>
                        <Typography variant="h6" className="semiBoldpoppins">Domain</Typography>
                        <Typography className="labels">

                        </Typography>
                    </Grid> */}

                    <Grid item className="py-2 flex-items-center" xs={12}>
                        {/* <Typography variant="h6" className="semiBoldpoppins">
                          {props.usertype != "InterviewCoordinator"
                            ? "Company Details"
                            : ""}
                        </Typography> */}
                        <Typography className="labels">

                        </Typography>
                    </Grid>


                    <Grid className="py-2 flex-items-center" item xs={4}>
                        <Typography variant="h6" className="semiBoldpoppins">Created By</Typography>
                        <Typography className="labels">
                            {props.clintList.createdByUname}
                        </Typography>
                    </Grid>

                    <Grid className="py-2 flex-items-center" item xs={4}>
                        <Typography variant="h6" className="semiBoldpoppins">Created On</Typography>
                        <Typography className="labels">
                            {props.clintList.createdOn}
                        </Typography>
                    </Grid>
                    <Grid className="py-2 flex-items-center" item xs={4}>
                        <Typography variant="h6" className="semiBoldpoppins">Modified By</Typography>
                        <Typography className="labels">
                        {props.clintList.modifiedBy ? props.clintList.modifiedBy:"--"}
                        </Typography>
                    </Grid>
                    <Grid className="py-2 flex-items-center" item xs={4}>
                        <Typography variant="h6" className="semiBoldpoppins">Modified On</Typography>
                        <Typography className="labels">
                        {props.clintList.modifiedOn?props.clintList.modifiedOn:"--"}
                        </Typography>
                    </Grid>
                    {/* <Grid className="py-2 flex-items-center" item xs={4}>
                        <Typography variant="h6" className="semiBoldpoppins">Status</Typography>

                    </Grid> */}
                </Grid>
                <div className="float-right m-1 submitButton">
                    <button onClick={() => { props.setDetailModel(false) }}>
                        Close
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default DetailsPage