import { useState, useEffect } from "react";
import { Outlet, Routes, Route, useRoutes } from "react-router-dom";

import Breadcrumbs from "../common/BreadCrumbs";
import PostJobPageHeader from "./ClientHeader";
import PostJobPageLeftMenu from "./ClientLeftMenu";
import PostJobList from "./PostJobList";
import UnderDevelopment from "../common/UnderDevelopment";

function ClientHome() {
  const [expandSideMenu, setExpandSideMenu] = useState(true);

  return (
    <div>
      {/* <ProgressContainer> */}

      <div className="">
        <PostJobPageHeader />
      </div>
      <div className="flex">
        <div>
          <PostJobPageLeftMenu />
        </div>
        <div className="w-full home-component-display overflow-auto">
          <Breadcrumbs />
          <Routes>
            <Route path="/" element={<PostJobList tabletitleRow={true} />} />
            <Route
              path="/jobposts"
              element={<PostJobList tabletitleRow={true} />}
            />
            <Route path="/dashboard" element={<UnderDevelopment />} />
            <Route path="/configuration" element={<UnderDevelopment />} />
          </Routes>
        </div>
      </div>
      {/* </ProgressContainer> */}
    </div>
  );
}

export default ClientHome;
