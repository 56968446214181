import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  InputAdornment,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import ForgotPasswordBG from "../../assets/forgotPwBG.png";
import "./ResetPassword.scss";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import cx from "classnames";
import { resetPassword, resetPasswordForBD } from "../../api/api-services";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FooterSection from "../landing-page/footer-section/FooterSection";
import NavBarComponent from "../landing-page/NavBarComponent";
const useStyles = makeStyles((theme) => ({
  form: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  field: {
    margin: theme.spacing(1),
    width: "100%",
    height: "77px",

    opacity: "1",
  },

  input: {
    backgroundColor: "#fff",
    "&:focused": {
      backgroundColor: "#fff",
    },
    "& .Mui-focused": {
      backgroundColor: "#fff",
    },
  },
}));

const initialValues = {
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object({
  password: Yup.string()
    .matches(/^\S*$/, `Weak password, please match the password criteria`)

    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,20}$/,
      "Weak password, please match the password criteria"
    )
    .required("New password is required"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password not matched with new password"
    ),
});

const STRONG_PASSWORD_PATTERNS = [
  {
    pattern: /^.{8,20}$/,
    message: "Should contains at least 8 characters and at most 20 characters",
  },
  {
    pattern: /.*[0-9].*/,
    message: "Should contains at least one digit",
  },
  {
    pattern: /.*[A-Z].*/,
    message: "Should contains at least one upper case alphabet",
  },
  {
    pattern: /.*[a-z].*/,
    message: "Should contains at least one lower case alphabet",
  },
  {
    pattern: /.*[!@#$%&*()+=^].*/,
    message:
      "Should contains at least one special character which includes !@#$%&*()+=^",
  },
  {
    pattern: /^\S*$/,
    message: "Should doesn't contain any white space",
  },
];
const ResetPassword = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickConfirmShowPassword = () => {
    setConfirmShowPassword(!confirmShowPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const { password } = values;
      const url = window.location.href;
      const token = url.substring(url.lastIndexOf("/") + 1);
      const urlParts = url.split("/");
    const isSetPasswordUrl = urlParts[urlParts.length - 2] === "set-password";
    const resetApiFunction = isSetPasswordUrl ? resetPasswordForBD : resetPassword;

      const response = await resetApiFunction(token, password);
      console.log(response?.data.statusCode, "resp");
      if (response?.data?.statusCode === 200) {
        toast.success(
          <>
            <div className="toast-heading">Success</div>
            <div>{response?.data?.response?.response}</div>
          </>
        );
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        toast.error(
          <>
            <div className="toast-heading">Alert</div>
            <div>Something went wrong</div>
          </>
        );
      }
      // Handle the response from the API as per your requirement
      console.log(response.data);
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error(error);
    }
  };
  const url = window.location.href;
 
  const urlParts = url.split("/");
const isSetPasswordUrl = urlParts[urlParts.length - 2] === "set-password";
  return (
    <div className=" overflow-x-hidden">
      <div className="resetPasswordContainer">
        <div className="navbarSection-stuReg">
          <NavBarComponent />
        </div>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <div className="formContainer">
              <p className="heading">{isSetPasswordUrl ? "Set Password ?" : "Reset Password ?"}</p>
              <div className="formContents">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched }) => (
                    <Form className={classes.form}>
                      <span className="text-red-500 ">*</span>
                      <label className="my-2 formLabel mx-1">
                        New Password
                      </label>
                      <Field
                        name="password"
                        autoComplete="off"
                        placeholder="Enter new password"
                        as={TextField}
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        className={classes.field}
                        error={touched.password && Boolean(errors.password)}
                        helperText={
                          <div className={cx("relative")}>
                            {touched.password && errors.password}
                            {values.password.length > 7 &&
                            values.password.length < 21 &&
                            values.password.match(
                              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,20}$/
                            ) &&
                            values.password.match(/^\S*$/) ? (
                              <p className="text-green-500 strongPW">
                                Strong password, good
                              </p>
                            ) : null}
                          </div>
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {!showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <div className="infoErrorIcon">
                        {STRONG_PASSWORD_PATTERNS.some(
                          ({ pattern }) => !pattern.test(values.password)
                        ) &&
                          (values.password || touched.password) && (
                            <Tooltip
                              data-html="true"
                              title={
                                <div>
                                  {STRONG_PASSWORD_PATTERNS.map(
                                    (passwordPattern) => {
                                      return (
                                        <Fragment className="absolute" key={passwordPattern.message}>
                                          {!passwordPattern.pattern.test(
                                            values.password
                                          ) && <p style={{
                                              fontSize: "10px",
                                              fontFamily: "Poppins-Regular",
                                              margin: "1px",
                                            }}>{passwordPattern.message}</p>}
                                        </Fragment>
                                      );
                                    }
                                  )}
                                </div>
                              }
                              placement="bottom"
                            >
                              <span
                                className={cx(
                                  "cursor-pointer ",
                                  "absolute",
                                  "mt-8",
                                  "-ml-10 password-info-icon",

                                  {
                                    "text-red-500":
                                      touched.password && errors.password,
                                    "text-blue-700":
                                      !touched.password || !errors.password,
                                  }
                                )}
                              >
                                <InfoOutlinedIcon />
                              </span>
                            </Tooltip>
                          )}
                      </div>

                      <span className="text-red-500 ">*</span>
                      <label className="my-2 formLabel mx-1">
                        Confirm password
                      </label>
                      <Field
                        name="confirmPassword"
                        autoComplete="off"
                        placeholder="Confirm your password"
                        as={TextField}
                        type={confirmShowPassword ? "text" : "password"}
                        variant="outlined"
                        className={classes.field}
                        error={
                          touched.confirmPassword &&
                          Boolean(errors.confirmPassword)
                        }
                       
                        helperText={
                          <div className={cx("relative")}>
                            { touched.confirmPassword && errors.confirmPassword}
                            {touched.password &&
                touched.confirmPassword &&
                values.password.length > 7 &&
                values.password ===
                  values.confirmPassword ? (
                  <div className="text-green-500 strongPW absolute">
                    Password matched
                  </div>
                ) : null}
                          </div>
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickConfirmShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {!confirmShowPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      

                      <div className="logInBtnContainer mt-4">
                        <Button type="submit" className="logInBtn">
                          Submit
                        </Button>
                      </div>
                      <div className="registerContainerForgetPw my-2 ">
                        <p className="ml-8">Back to ?</p>
                        <Link to="/login" className="backToLogin mx-2">
                          Signin
                        </Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Grid>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          className={"tost-msg-stylinh"}
          draggable={false}
          theme="light"
        />
      </div>
      <FooterSection />
    </div>
  );
};

export default ResetPassword;
