import { useState, useEffect } from "react";
import { Outlet, Routes, Route, useRoutes } from "react-router-dom";
import BreadCrumbs from "../common/BreadCrumbs";
import AdminClient from "../admin/admin-client/AdminClient";
import BdPageHeader from "./BdPageHeader";
import BdPageLeftMenu from "./BdPageLeftMenu";
import BdJobPost from "./bd-jobpost/BdJobPost";
import ShareProfile from "./bd-jobpost/ShareProfile";
import UnderDevelopment from "../common/UnderDevelopment";
import Myleadcards from "./lead/Myleadcards";
import PostJobModal from "../client/modals/PostJobModal";
import AccountList from "./account/AccountList";
import AccountDetails from "./account/AccountDetails";

function BdHome() {
  const [expandSideMenu, setExpandSideMenu] = useState(true);

  return (
    <div>
      {/* <ProgressContainer> */}

      <div className="">
        <BdPageHeader />
      </div>
      <div className="flex">
        <div>
          <BdPageLeftMenu />
        </div>
        <div className="w-full home-component-display overflow-auto">
          <BreadCrumbs />
          <Routes>
            <Route path="/" element={<BdJobPost />} />
            <Route path="/job-post" element={<BdJobPost />} />
            <Route path="/job-post/share-profile" element={<ShareProfile />} />
            <Route path="/dashboard" element={<UnderDevelopment />} />
            <Route path="/configuration" element={<UnderDevelopment />} />
            <Route path="/lead" element={<Myleadcards />} />
            <Route path="/account" element={<AccountList />} />
            <Route path="/account/details" element={<AccountDetails />} />
            <Route path="/account/post-Job" element={<PostJobModal />} />
            <Route path="/job-post/post-job" element={<PostJobModal />} />
          </Routes>
        </div>
      </div>
      {/* </ProgressContainer> */}
    </div>
  );
}

export default BdHome;
