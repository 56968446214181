import { useState, useEffect } from "react";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "./InterviewModals.scss";
import InterviewerUpdateInterview from "./InterviewerUpdateInterview";
import InterviewerCandidatesDetails from "./InterviewerCandidatesDetails";
import { getAllSkills, getCandidateDetailsReq, getInterviewers, updateInterviewRating } from "../../../api/api-services";
import CancelIcon from '@mui/icons-material/Cancel';

function InterviewModals(props) {
  console.log(props);
  const [openModal, setOpenModal] = useState(true);
  const [skillData, setSkillData] = useState(props?.candidate?.skills)
  const [selectedSkills, setSelectedSkills] = useState([])
  const [allskills, setAllskills] = useState([])
  let [candidateDetailsData, setCandidateDetailsData] = useState({});

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "60%",
      transform: "translate(-50%, -50%)",
      width: "82%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
      outline: "none",
      //   borderTopLeftRadius: " 12px",
    },
  };
  const getCandidateDetails = async () => {
    try {
      const response = await getCandidateDetailsReq(props?.candidate?.id);
      console.log("candidate Details Data", response);
      if (response.data) {
        setCandidateDetailsData({ ...response.data.response });
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  const getSkills = async () => {
    try {
      const response = await getAllSkills();
      console.log(response.data.response, "response.data.response")
      let allSkillsarr = [];
      response.data.response.map((skillArr) => {
          allSkillsarr.push({ label: skillArr,value: skillArr,})
      });
      console.log(allSkillsarr,"allSkillsallSkills")
      setAllskills(allSkillsarr);
    } catch (error) {
      console.error("Error fetching skills:", error);
    }
  };

 
console.log(allskills,"setAllskills")
  useEffect(() => {
    getCandidateDetails();
    getSkills()
  }, []);
  let closeInterviewModal = () => {
    props.gettableData(props.selectedTab, 0);
    setOpenModal(false);
    props.closeModal(false);
  };
  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      style={customStylesPage}
      className="p-px"
    >
      <div className="flex items-center rounded-tl-xl Interview-modal-heading items-center">
        <div className="header">{props.header}</div>
        <button
          onClick={() => {
            setOpenModal(false);
            props.closeModal(false);
          }}
          type="button"
          className="modal-close-btn float-left ml-auto"
        >
          <CancelIcon />
        </button>
      </div>
      <div className="flex candidate-details-body">
        <div className="modal-candidate-details-container">
          <InterviewerCandidatesDetails
            candidateDetailsData={candidateDetailsData}
            candidate={props.candidate}
          />
        </div>

        <div className="candidate-form">
          <InterviewerUpdateInterview
            closeModal={props.closeModal}
            toast={props.toast}
            getUpdatedInterviews={props.getUpdatedInterviews}
            setInterviewModule={props.setInterviewModule}
            skillData={skillData}
            setAllskills={setAllskills}
            selectedSkills={selectedSkills}
            setSelectedSkills={setSelectedSkills}
            allskills={allskills}
            candidateDetailsData={candidateDetailsData}
            candidate={props.candidate} />
        </div>
      </div>
    </Modal>
  );
}

export default InterviewModals;
