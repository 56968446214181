import { ReactComponent as LocationIcon } from "../../../assets/location.svg";
import "./CoordinatorCard.scss";
import AdminContextMenuModal from "../../admin/AdminContextMenuModal";

const CoordinatorCard = (props) => {
  //   const contextMenuData = [
  //     {
  //       option: "Details",
  //     },
  //   ];

  // function contextMenuFunction(event, resObj) {
  //   props.handelMoreBtn(resObj, event.target.textContent);
  //   console.log(resObj,event.target.textContent)
  // }
  console.log(props);
  return (
    <div
      className="job-card border border-gray-400 rounded-lg shadow-md border-opacity-0 transform"
      key={props.job.name + props.index}
    >
      <div className="leftCardItem grid grid-cols-3 gap-4 " title={props.job.name}>
        <div className="col-span-12 leftCardItemContent">
          <div className="label">Interviewer Name</div>
          <div className="value">{props.job.name}</div>
        </div>
      </div>
      <div className="job-placement grid grid-cols-9" title={props.job.email}>
        <div className="col-span-3 pl-2 datatrimmer">
          <div className="job-label  ">Email </div>
          <span>{props.job.email}</span>
        </div>
        <div className="col-span-2 pb-4 pl-2" title={props.job.mobile}>
          <span className="job-label">Phone Number </span>
          <span className="">{props.job.mobile}</span>
        </div>
        <div className="col-span-2 pb-4 pl-2" title={props.job.status}>
          <span className="job-label">Status </span>
          <br />
          <span>{props.job.status}</span>
        </div>

        <div className="col-span-2 pb-4 flex" title={props?.job?.domains}>
          <div>
            <span className="job-label">Domain </span>
            <br />
            <div className="  max-h-24 overflow-x-hidden overflow-y-auto">{props?.job?.domains?.join(", ")}</div>
          </div>
          {/* <div className="action-btns flex ml-20">
              <span className="">
                <button className={"block"}>
                  <AdminContextMenuModal
                    contextMenuData={contextMenuData}
                    page={"jobCard"}
                    func={(event) => {
                      event.stopPropagation();
                      contextMenuFunction(event, props.job);
                    }}
                    // disable={props.job.status === "CANCEL"}
                    onMouseOutz
                  />
                </button>
                
              </span>
             
            </div> */}
        </div>
        <div className="col-span-3 pl-2 datatrimmer" title={props.job.assigned}>
          <div className="job-label pb-2 ">No of Assigned Interviews </div>
          <span>{props.job.assigned}</span>
        </div>
        <div className="col-span-2 pl-2"  title={props.job.taken}>
          <div className="job-label pb-2">No. of Taken Interviews </div>
          <span>{props.job.taken}</span>
        </div>
        <div className="col-span-2"  title={props.job.canceled}>
          <div className="job-label pb-2">No. of Cancelled Interviews </div>
          <span>{props.job.canceled}</span>
        </div>

        <div className="col-span-2" title={props.job.reScheduled}>
          <div className="job-label pb-2">No. of Rescheduled Interviews</div>
          <span>{props.job.reScheduled}</span>
        </div>
      </div>
    </div>
  );
};

export default CoordinatorCard;
