// MultiEmailInput.js

import React, { useState } from "react";
import { useEffect } from "react";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
// import 'react-multi-email/style.css';

const MultiEmailInputField = ({ selectedEmails, handleEmailChange, fieldName }) => {
  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = React.useState(false);

  const handleOnChange = (emailArray) => {
    setEmails(emailArray);
    handleEmailChange(emailArray);
  };

  useEffect(() => {
    setEmails(selectedEmails?.length ? [...selectedEmails] : []);
  }, [selectedEmails]);

  return (
    <div>
      <ReactMultiEmail
        placeholder="Input your email"
        emails={emails}
        name={fieldName?fieldName:""}
        onChange={handleOnChange}
        // autoFocus={true}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};

export default MultiEmailInputField;
