
import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  InputAdornment,
  Grid,
  Tooltip,
} from "@material-ui/core";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";

import "./ThanksPage.scss";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import cx from "classnames";
import { resetPassword, resetPasswordForBD } from "../../../api/api-services";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


import Modal from "react-modal";
import NavigationHeader from "../nav-section/NavigationHeader";
import FindJobsByKeywordHome from "../find-jobs/FindJobsByKeywordHome";

const customStylesPage = {
  overlay: {
    background: "rgba(90, 90, 90,0.5)",
  },
  content: {
    position: "fixed",
    top: "55%" ,
    left: "50%",
    transform: "translate(-50%, -50%)",

    borderRadius: "10px",
    opacity: "1",
    outline: "none",
    width: "400px",
    // height: "468px",
    height:"auto",
    background: " #FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 20px #00000029",
  },
};

const ThanksPage = () => {
 

  

  const navigate = useNavigate();


const [isModalOpen, setIsModalOpen] = useState(true); // Set modal state



  return (
    <div className=" overflow-x-hidden">
      <div className="setPasswordContainer">
        <div className="navbarSection-stuReg">
          <NavigationHeader />
        </div>
        <FindJobsByKeywordHome/>
        <Modal
        isOpen={isModalOpen}
        // onRequestClose={closeModal}
        contentLabel="Reset Password Modal"
        style={customStylesPage} 
        className="ResetPasswordModal"
      >
        <Grid container spacing={0}>
          <Grid item xs={12} >
            <div className="thanksContainer">
            <div className="successMsg ">
   
    

    </div>
              <p className="heading">Thank You</p>
              <div className="formContents">
                <p>someone will get in touch with you soon.</p>
                <div className=" mt-4 flex justify-center align-middle">
                        <button onClick={() => {
                navigate("/");
                // window.location.reload()
              }}  type="submit" className="pwBtn">
                          OK
                        </button>
                      </div>
              </div>
            </div>
          </Grid>
        </Grid>
        </Modal>
       
      </div>
      
    </div>
  );
};

export default ThanksPage;
