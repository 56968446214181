import React, { useState, Fragment, useEffect } from "react";
import "./AdminPageHeader.scss";
import QspiderLogo from "../../assets/accrossGeo.svg";

import { UserProfile } from "../common/user-profile-header/UserProfile";

function AdminPageHeader(props) {
  let userName=JSON.parse(
    localStorage.getItem("across-geo-user")
  ).username
  return (
    <div className="">
      <nav className="app-navbar">
        <div className="navbar-brand">
          {/* <HeaderSquareLogo className="headerSquareLogo"/> */}
          <div>
            {" "}
            <img src={QspiderLogo} alt="Logo"></img>
          </div>
        </div>
        <div className="navbar-app-end">
          <span className="header-name">
           {userName}
          </span>
          <div className="navbar-app-item">
           <UserProfile/>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default AdminPageHeader;
