import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "react-modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import "./AdminAddEditUser.scss";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  createBdReq,
  editBdReq,
  createInterviewCoordinatorReq,
  editInterviewCoordinatorReq,
  createInterviewerReq,
  editInterviewerReq,
  getAllCompany,
  existEmailPhoneCheck,
  enableDisableUser,
  defaultCountry,
  getdisabledCompReq,
  getAllDomains,
} from "../../../../api/api-services";
import { MultiSelectDropdown } from "../../../landing-page/common/select-dropdown";
import ReactSelectDropdown from "../../../landing-page/common/react-select-dropdown";

const AdminAddEditUser = (props) => {
  const [openInterviewerModal, setOpenInterviewerModal] = useState(true);
  const [value, setValue] = useState("");
  const [error, setError] = useState({ phone: false, validPhone: false });
  const inputBorder = "1px solid #26428B80";
  const inputBorderErr = "1px solid #ea0322";
  const [toggle, setToggle] = useState(false);
  const [listOfCompanies, setListOfCompanies] = useState([]);
  const [listOfDomains, setListOfDomains] = useState([]);
  let [selectedCompanies, setSelectedCompanies] = useState([]);
  let [selectedDomains, setSelectedDomains] = useState([]);
  let user = JSON.parse(localStorage.getItem("across-geo-user"));
  const [availabilityStatusEmail, setAvailabilityStatusEmail] = useState(null);
  const [availabilityStatusPhone, setAvailabilityStatusPhone] = useState(null);
  const [presentCountry, setPresentCountry] = useState("");

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "80%",
      transform: "translate(-50%, -50%)",
      width: "40%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
      outline: "none",
      index: "9999",
      //   borderTopLeftRadius: "12px",
    },
  };

  let initialValues;
  if (props.editDetails) {
    let tempProps = JSON.parse(JSON.stringify(props));
    initialValues = {
      name: tempProps.editDetails.name,
      gender: tempProps.editDetails.gender,
      email: tempProps.editDetails.email,
      phone: tempProps.editDetails.mobile,
    };
    if (props.usertype === "BD") {
      initialValues.company = [...tempProps.editDetails.companyNames];
    } else if (props.usertype === "interviewer") {
      initialValues.domain = [...tempProps.editDetails.domains];
    }
  } else {
    initialValues = {
      name: "",
      gender: "",
      email: "",
      phone: "",
    };
    if (props.usertype === "BD") {
      initialValues.company = [];
    } else if (props.usertype === "interviewer") {
      initialValues.domain = [];
    }
  }
  let interviewCoordinatorSchema = {
    name: Yup.string()
      .required("Name is required")
      .max(25, "Name can accept maximum 25 characters")
      .matches(/^[A-Za-z][A-Za-z\s]*$/, "Name should be alphabetic")
      .matches(/^[A-Za-z\s]*[A-Za-z]$/, "Name should not end with a space")
      .matches(
        /^(?!.*\s{2,})[A-Za-z\s]*$/,
        "Name should not contain consecutive blank spaces"
      )
      .min(3, "Name should be minimum 3 characters"),
    gender: Yup.string().required("Gender is required"),

    email: Yup.string()
      .trim()
      .required("Email id is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Enter valid email Id"),
    phone: Yup.string().required("Phone number is required"),
  };
  let interviewerSchema = {
    name: Yup.string()
      .required("Name is required")
      .max(25, "Name can accept maximum 25 characters")
      // .matches(/^[A-Za-z ]*$/, "Name should be alphabetic")
      .matches(/^[A-Za-z\s]*[A-Za-z]$/, "Name should not end with a space")
      .matches(/^[A-Za-z][A-Za-z\s]*$/, "Name should be alphabetic")
      .matches(
        /^(?!.*\s{2,})[A-Za-z\s]*$/,
        "Name should not contain consecutive blank spaces"
      )
      .min(3, "Name should be Minimum 3 characters"),
    gender: Yup.string().required("Gender is required"),
    email: Yup.string()
      .trim()
      .required("Email id is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Enter valid email Id"),
    phone: Yup.string().required("Phone number is required"),
    domain: Yup.array().min(1, "Domain is required"),
  };
  let bdSchema = {
    name: Yup.string()
      .min(3, "Name should be Minimum 3 characters")
      // .matches(/^[A-Za-z ]*$/, "Name should be alphabetic")
      .matches(/^[A-Za-z][A-Za-z\s]*$/, "Name should be alphabetic")
      .matches(
        /^(?!.*\s{2,})[A-Za-z\s]*$/,
        "Name should not contain consecutive blank spaces"
      )
      .matches(/^[A-Za-z\s]*[A-Za-z]$/, "Name should not end with a space")
      .required("Name is required")
      .max(25, "Name should be maximum 25 characters"),
    gender: Yup.string().required("Gender is required"),
    email: Yup.string()
      .trim()
      .required("Email id is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Enter valid Email id"),
    phone: Yup.string().required("Phone number is required"),
    company: Yup.array().min(1, "Company is required"),
  };

  let validationSchema;
  if (props.usertype === "BD") {
    validationSchema = Yup.object(bdSchema);
  } else if (props.usertype === "interviewer") {
    validationSchema = Yup.object(interviewerSchema);
  } else {
    validationSchema = Yup.object(interviewCoordinatorSchema);
  }

  const checkAvailability = async (id, value) => {
    try {
      const response = await existEmailPhoneCheck(
        id === "phone" ? "+" + formikDetails.values.phone : value
      );
      if (id === "email") {
        setAvailabilityStatusEmail(response?.response?.data?.response);
      } else if (id === "phone") {
        setAvailabilityStatusPhone(response?.response?.data?.response);
      }
    } catch (error) {
      console.error("Error occurred while checking availability:", error);
    }
  };
  const handleOnBlur = (id) => {
    if (!formikDetails.values.phone) {
      setError({ ...error, [id]: true });
    } else if (!isValidPhoneNumber("+" + value?.toString())) {
      setError({ ...error, [id]: false, validPhone: true });
    } else {
      setError({ ...error, [id]: false, validPhone: false });
    }
  };
  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false; // If lengths are different, arrays are not equal.
    }
    return arr1.every((element, index) => arr2.includes(element));
  }
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = (values) => {
    console.log("submit values", values);
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    if (props.mode === "add") {
      let payload = {
        name: values.name,
        gender: values.gender,
        email: values.email,
        mobile: "+" + values.phone,
      };
      if (props.usertype === "BD") {
        payload.workLocation = "Bangalore";
        payload.companyNames = [...values.company];
        createBdRequestHandler(payload);
      } else if (props.usertype === "interviewer") {
        payload.adminId = user.uid;
        payload.domains = values.domain;
        createInterviewerReqHandler(payload);
      } else {
        payload.adminId = user.uid;
        createInterviewCoordinatorReqHandler(payload);
      }
    } else if (props.mode === "edit") {
      if (props.usertype === "BD") {
        editBdRequestHandler(values);
      } else if (props.usertype === "interviewer") {
        editInterviewerReqHandler(values);
      } else {
        editInterviewCoordinatorReqHandler(values);
      }
    }
  };
  const createBdRequestHandler = async (payload) => {
    try {
      let response = await createBdReq(payload, user.uid);
      if (response.data.statusCode === 201) {
        props.toast.success(
          <>
            <div className="toast-heading">Success</div>
            <div>BD Added Successfully</div>
          </>
        );
        props.getUserList(0);
        props.closeModal();
        setIsSubmitting(false);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const createInterviewerReqHandler = async (payload) => {
    try {
      let response = await createInterviewerReq(payload, user.uid);
      if (response.data.statusCode === 201) {
        props.toast.success(
          <>
            <div className="toast-heading">Success</div>
            <div>Interviewer Added Successfully</div>
          </>
        );
        props.getUserList(0);
        props.closeModal();
        setIsSubmitting(false);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const createInterviewCoordinatorReqHandler = async (payload) => {
    try {
      let response = await createInterviewCoordinatorReq(payload);
      if (response.data.statusCode === 201) {
        props.toast.success(
          <>
            <div className="toast-heading">Success</div>
            <div>Interview Coordinator Added Successfully</div>
          </>
        );
        props.getUserList(0);
        props.closeModal();
        setIsSubmitting(false);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const editBdRequestHandler = async (values) => {
    let displayToast = true;
    let payload = {
      ...props.editDetails,
      name: values.name,
      gender: values.gender,
      email: values.email,
      mobile: values.phone,
      workLocation: "Bangalore",
      companyNames: [...values.company],
    };
    delete payload?.status;
    if (
      (props.editDetails?.status === "ACTIVE" && !toggle) ||
      (props.editDetails?.status !== "ACTIVE" && toggle)
    ) {
      try {
        let response = await enableDisableUser(
          toggle ? "activate" : "disable",
          props.editDetails?.id
        );
        if (response.data && displayToast) {
          displayToast = false;
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>BD Edited Successfully</div>
            </>
          );
          props.getUserList(0);
          props.closeModal();
        }
        if (
          response &&
          response?.response &&
          response?.response?.data &&
          response?.response?.data?.statusCode === 400 &&
          displayToast
        ) {
          displayToast = false;
          props.toast.error(
            <>
              <div className="toast-heading">Error</div>
              <div>{response.response.data.response}</div>
            </>
          );
          setTimeout(() => {
            setToggle(true);
          }, 4000);
        }
        console.log(response);
      } catch (err) {
        console.error(err);
      }
    }
    if (
      !arraysAreEqual(values.company, props.editDetails?.companyNames) ||
      values.name !== props.editDetails.name ||
      values.gender !== props.editDetails.gender
    ) {
      try {
        let response = await editBdReq(payload, user.uid);
        console.log(response);
        if (response.data.statusCode === 200 && displayToast) {
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>BD Edited Successfully</div>
            </>
          );
          props.getUserList(0);
          props.closeModal();
        }
      } catch (err) {
        console.error(err);
      }
    } else if (displayToast) {
      props.toast.info(
        <>
          <div className="toast-heading">info</div>
          <div>No Changes Have Been Made</div>
        </>
      );
    }
  };
  const editInterviewerReqHandler = async (values) => {
    let displayToast = true;
    let payload = {
      ...props.editDetails,
      name: values.name,
      gender: values.gender,
      email: values.email,
      mobile: values.phone,
      domains: [...values.domain],
    };
    delete payload?.status;
    if (
      (props.editDetails?.status === "ACTIVE" && !toggle) ||
      (props.editDetails?.status !== "ACTIVE" && toggle)
    ) {
      try {
        let response = await enableDisableUser(
          toggle ? "activate" : "disable",
          props.editDetails?.id
        );

        if (response.data && displayToast) {
          displayToast = false;
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>Interviewer Edited Successfully</div>
            </>
          );
          props.getUserList(0);
          props.closeModal();
        }
        if (
          response &&
          response?.response &&
          response?.response?.data &&
          response?.response?.data?.statusCode === 400 &&
          displayToast
        ) {
          displayToast = false;
          props.toast.error(
            <>
              <div className="toast-heading">Error</div>
              <div>{response.response.data.response}</div>
            </>
          );
          setTimeout(() => {
            setToggle(true);
          }, 4000);
        }
        console.log(response);
      } catch (err) {
        console.error(err);
      }
    }
    if (
      !arraysAreEqual(values.domain, props.editDetails?.domains) ||
      values.name !== props.editDetails.name ||
      values.gender !== props.editDetails.gender
    ) {
      try {
        payload.adminId = user.uid;
        let response = await editInterviewerReq(payload);
        console.log(response);
        if (response.data.statusCode === 200 && displayToast) {
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>Interviewer Edited Successfully</div>
            </>
          );
          props.getUserList(0);
          props.closeModal();
        }
      } catch (err) {
        console.error(err);
      }
    } else if (displayToast) {
      props.toast.info(
        <>
          <div className="toast-heading">info</div>
          <div>No Changes Have Been Made</div>
        </>
      );
    }
  };
  const editInterviewCoordinatorReqHandler = async (values) => {
    let displayToast = true;
    let payload = {
      ...props.editDetails,
      name: values.name,
      gender: values.gender,
      email: values.email,
      mobile: values.phone,
    };
    delete payload?.status;
    if (
      (props.editDetails?.status === "ACTIVE" && !toggle) ||
      (props.editDetails?.status !== "ACTIVE" && toggle)
    ) {
      try {
        let response = await enableDisableUser(
          toggle ? "activate" : "disable",
          props.editDetails?.id
        );
        if (response.data.statusCode === 200 && displayToast) {
          displayToast = false;
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>Interview Coordinator Edited Successfully</div>
            </>
          );
          props.getUserList(0);
          props.closeModal();
        }
        console.log(response);
      } catch (err) {
        console.error(err);
      }
    }
    if (
      values.name !== props.editDetails.name ||
      values.gender !== props.editDetails.gender
    ) {
      try {
        payload.adminId = user.uid;
        let response = await editInterviewCoordinatorReq(payload, user.uid);
        console.log(response);
        if (response.data.statusCode === 200 && displayToast) {
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>Interview Coordinator Edited Successfully</div>
            </>
          );
          props.getUserList(0);
          props.closeModal();
        }
      } catch (err) {
        console.error(err);
      }
    } else if (displayToast) {
      props.toast.info(
        <>
          <div className="toast-heading">info</div>
          <div>No Changes Have Been Made</div>
        </>
      );
    }
  };

  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });
  let handleCompanyChange = (data) => {
    let list = [];
    data?.map((company) => {
      list.push(company.value);
    });
    formikDetails.setFieldValue("company", [...list]);
    setSelectedCompanies([...data]);
  };
  let handleDomainChange = (data) => {
    let list = [];
    data?.map((domain) => {
      list.push(domain.value);
    });
    formikDetails.setFieldValue("domain", [...list]);
    setSelectedDomains([...data]);
  };
  const fetchCompanies = async () => {
    let unremovableCmp = [];
    try {
      let response =
        props.editDetails && (await getdisabledCompReq(props.editDetails?.id));
      if (response?.data?.response?.unRemovable?.length) {
        unremovableCmp = [...response?.data?.response?.unRemovable];
      }
    } catch (err) {
      console.error("unremovable api error", err);
    }
    try {
      const response = await getAllCompany();
      const companies = response.data.response.map((company) => ({
        label: company,
        value: company,
        disabled: unremovableCmp.includes(company),
      }));
      setListOfCompanies(companies);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };
  const fetchDomains = async () => {
    try {
      const response = await getAllDomains();
      const domains = response.data.response.map((domain) => ({
        label: domain,
        value: domain,
      }));
      setListOfDomains(domains);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };
  useEffect(() => {
    if (props.usertype === "BD") {
      const companies = props.editDetails?.companyNames?.map((company) => {
        return {
          label: company,
          value: company,
        };
      });
      if (companies?.length > 0) {
        setSelectedCompanies([...companies]);
      }
    } else if (props.usertype === "interviewer") {
      const domains = props.editDetails?.domains?.map((domain) => {
        return {
          label: domain,
          value: domain,
        };
      });
      if (domains?.length > 0) {
        setSelectedDomains([...domains]);
      }
    }
    if (props.editDetails?.status) {
      setToggle(props.editDetails?.status === "ACTIVE" ? true : false);
    }
  }, [props.editDetails]);

  useEffect(() => {
    defaultCountry()
      .then((res) => {
        !props.editDetails && setPresentCountry(res.data.country);
      })
      .catch((err) => {
        console.error(err);
      });
    if (props.usertype === "BD") {
      fetchCompanies();
    } else if (props.usertype === "interviewer") {
      fetchDomains();
    }
  }, []);

  return (
    <Modal
      isOpen={openInterviewerModal}
      style={customStylesPage}
      className="p-px"
      ariaHideApp={false}
    >
      <div className="addInterviewerModal">
        <div className="flex justify-between create-modal-heading  p-2">
          <p>
            {props.usertype === "BD" &&
              (props.editDetails ? "Edit BD" : "Add New BD")}
            {props.usertype === "interviewer" &&
              (props.editDetails ? "Edit Interviewer" : "Add New Interviewer")}
            {props.usertype === "interviewCoordinator" &&
              (props.editDetails
                ? "Edit Interview Coordinator"
                : "Add New Interview Coordinator")}
          </p>
          <div>
            <span className="modal-close-icon">
              <button
                onClick={() => {
                  setOpenInterviewerModal(false);
                  props.closeModal(false);
                }}
                type="button"
                className="modal-close-btn p-3"
              >
                <HighlightOffIcon />
              </button>
            </span>
          </div>
        </div>
        <div className="formContent">
          <form onSubmit={formikDetails.handleSubmit}>
            <div className="form-field ">
              <p
                style={{ fontFamily: "Poppins-Regular" }}
                className="mb-0 "
                htmlFor="name"
              >
                <span className="text-red-500 Poppins-Regular~">*</span>Name
              </p>
              <TextField
                error={formikDetails.errors.name && formikDetails.touched.name}
                type="text"
                id="name"
                name="name"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    padding: "9.5px 14px",
                    height: "0.43em !important",
                  },
                }}
                autoComplete="off"
                placeholder="Enter your name"
                onBlur={formikDetails.handleBlur}
                onChange={formikDetails.handleChange}
                {...formikDetails.getFieldProps("name")}
                variant="outlined"
                fullWidth
                className={`${
                  formikDetails.errors.name && formikDetails.touched.name
                    ? "error-input"
                    : "form-field"
                }`}
              />
              {formikDetails.errors.name && formikDetails.touched.name && (
                <div className="error-message">{formikDetails.errors.name}</div>
              )}
            </div>

            <div className="form-field genderField my-4">
              <p
                style={{ fontFamily: "Poppins-Regular" }}
                className="mb-0"
                htmlFor="gender"
              >
                <span className="text-red-500  ">*</span>Gender
              </p>
              <TextField
                error={
                  formikDetails.errors.gender && formikDetails.touched.gender
                }
                select
                id="gender"
                name="gender"
                handleChange={formikDetails.handleChange}
                {...formikDetails.getFieldProps("gender")}
                variant="outlined"
                fullWidth
                className={`${
                  formikDetails.errors.gender && formikDetails.touched.gender
                    ? "error-input"
                    : ""
                }`}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    padding: "9.5px 14px",
                    height: "43px",
                  },
                }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value) =>
                    value === "" ? (
                      <p className="-mt-1 select-custom-input Poppins-Regular">
                        -- Select --
                      </p>
                    ) : (
                      <p className="-mt-1 select-custom-input Poppins-Regular">
                        {value}
                      </p>
                    ),
                }}
              >
                <MenuItem value="">Select Gender</MenuItem>
                <MenuItem value="MALE">MALE</MenuItem>
                <MenuItem value="FEMALE">FEMALE</MenuItem>
                <MenuItem value="OTHERS">OTHERS</MenuItem>
              </TextField>
              {formikDetails.errors.gender && formikDetails.touched.gender && (
                <div className="error-message">
                  {formikDetails.errors.gender}
                </div>
              )}
            </div>

            <div className="form-field my-4">
              <p
                style={{ fontFamily: "Poppins-Regular" }}
                className="mb-0"
                htmlFor="email"
              >
                <span className="text-red-500  ">*</span>Email
              </p>
              <TextField
                error={
                  formikDetails.errors.email && formikDetails.touched.email
                }
                type="email"
                id="email"
                name="email"
                disabled={
                  (props.usertype === "BD" && props.mode === "edit") ||
                  (props.usertype === "interviewer" && props.mode === "edit") ||
                  (props.usertype === "interviewCoordinator" &&
                    props.mode === "edit")
                }
                {...formikDetails.getFieldProps("email")}
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    padding: "9.5px 14px",
                    height: "0.43em !important",
                  },
                }}
                onChange={(event) => {
                  formikDetails.handleChange(event);
                  setAvailabilityStatusEmail("");
                }}
                onBlur={(event) => {
                  formikDetails.handleBlur(event);
                  if (
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                      event.target.value
                    )
                  ) {
                    checkAvailability(event.target.id, event.target.value);
                  }
                }}
                autoComplete="off"
                placeholder="Enter your email"
                className={`${
                  formikDetails.errors.email && formikDetails.touched.email
                    ? "error-input"
                    : ""
                }`}
              />
              {formikDetails.errors.email && formikDetails.touched.email && (
                <div className="error-message">
                  {formikDetails.errors.email}
                </div>
              )}
              <div className="text-red-500 error_text md:mt-1">
                {availabilityStatusEmail}
              </div>
            </div>

            <div className="form-field my-4 phoneNumberSection">
              <p
                style={{ fontFamily: "Poppins-Regular" }}
                className="create-labels mb-0"
              >
                <span className="text-red-500  ">*</span>Phone Number
              </p>
              <PhoneInput
                name="phone"
                id="phone"
                value={formikDetails.values.phone}
                onChange={(e) => {
                  formikDetails.setFieldValue("phone", e);
                  setValue(e);
                }}
                autoFormat={false}
                searchPlaceholder="Search..."
                searchNotFound="No Countries Found"
                specialLabel=""
                disabled={
                  (props.usertype === "BD" && props.mode === "edit") ||
                  (props.usertype === "interviewer" && props.mode === "edit") ||
                  (props.usertype === "interviewCoordinator" &&
                    props.mode === "edit")
                }
                defaultCountry={presentCountry}
                country={presentCountry && presentCountry.toLowerCase()}
                style={{
                  border: `${
                    error.phone || error.validPhone ? inputBorderErr : ""
                  }`,
                  borderRadius: "5px",
                  width: "497px",
                }}
                enableSearch
                international
                inputProps={{
                  style: {
                    height: "0.43em !important",
                  },
                }}
                countryCodeEditable={false}
                autoComplete="off"
                onBlur={(e) => {
                  handleOnBlur("phone");
                  checkAvailability("phone", e.target.value.replace(/\s/g, ""));
                }}
              />
              {(error.phone ||
                (formikDetails.errors.phone &&
                  formikDetails.touched.phone)) && (
                <div className="error-message">Phone number is required</div>
              )}
              {error.validPhone && !error.phone && (
                <div className="error-message">Invalid phone number</div>
              )}
              <div className="error-message">{availabilityStatusPhone}</div>
            </div>

            {props.usertype === "BD" ? (
              <div className="form-field my-4">
                <p
                  style={{ fontFamily: "Poppins-Regular" }}
                  className="mb-0"
                  htmlFor="domain"
                >
                  <span className="text-red-500  ">*</span>Company
                </p>
                <div className="input-ele">
                  <ReactSelectDropdown
                    options={listOfCompanies}
                    isSearchable
                    isMulti
                    onChange={handleCompanyChange}
                    value={selectedCompanies}
                  />
                </div>
                {formikDetails.errors.company &&
                formikDetails.touched.company ? (
                  <div className="error-message">
                    {formikDetails.errors.company}
                  </div>
                ) : null}
              </div>
            ) : props.usertype === "interviewer" ? (
              <div className="form-field my-4">
                <label htmlFor="domain">Domain</label>
                <div className="input-ele">
                  <ReactSelectDropdown
                    options={listOfDomains}
                    isSearchable
                    isMulti
                    onChange={handleDomainChange}
                    value={selectedDomains}
                  />
                </div>
                {formikDetails.errors.domain &&
                  formikDetails.touched.domain && (
                    <div className="error-message">
                      {formikDetails.errors.domain}
                    </div>
                  )}
              </div>
            ) : (
              ""
            )}
            {props.editDetails && (
              <div className="items-center flex">
                <span className="pr-4 create-labels">
                  {props.usertype === "interviewer"
                    ? "Disable Interviewer"
                    : props.usertype === "BD"
                    ? "Disable BD"
                    : "Disable Coordinator"}
                </span>
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={props.editDetails?.status === "PENDING"}
                        checked={toggle}
                        onChange={(e) => setToggle(e.target.checked)}
                      />
                    }
                  />
                  <span className="create-labels">
                    {props.usertype === "interviewer"
                      ? "Enable Interviewer"
                      : props.usertype === "BD"
                      ? "Enable BD"
                      : "Enable Coordinator"}
                  </span>
                </div>
              </div>
            )}

            {props.editDetails ? (
              <button className="submitBtn" type="submit">
                Update
              </button>
            ) : (
              <button
                // disabled={error.validPhone && !error.phone}
                disabled={isSubmitting || (error.validPhone && !error.phone)}
                type="submit"
                className="submitBtn"
              >
                Add
              </button>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AdminAddEditUser;
