import React, { useEffect, useState } from 'react'
import CategoriesItem from './CategoriesItem'
import "./CategoriesItem.scss"
import { getAllCategories } from '../../../../api/api-services';

function CategoriesList() {
  const [dropDownData, setDropDownData] = useState([]);
  const categoriesList = [
    {
      "type": "dev",
      "title": "Development & IT",
      "jobCount": "16 jobs"
    },
    {
      "type": "market",
      "title": "Marketing & Sales",
      "jobCount": "16 jobs"
    },
    // {
    //   "type": "QA",
    //   "title": "Marketing & Sales",
    //   "jobCount": "16 jobs"
    // },
    {
      "type": "services",
      "title": "Customer Service",
      "jobCount": "16 jobs"
    },
    {
      "type": "design",
      "title": "Design & Creative",
      "jobCount": "16 jobs"
    },
    {
      "type": "management",
      "title": "Product Management",
      "jobCount": "16 jobs"
    },

    // {
    //   "type": "writing",
    //   "title": "Writing & Translation",
    //   "jobCount": "16 jobs"
    // }
  ]
  const getIndustries = async () => {
    try {
        const res = await getAllCategories();
        if (res?.data) {
            setDropDownData(res.data.response)
        } else {
            console.log(res, "res")
        }
    } catch (e) {
        console.log("countires error", e);
    }
};

  let data = JSON.parse(JSON.stringify(categoriesList))

  console.log(data, "data")
  useEffect(() => {
    getIndustries();
  }, [])
  return (
    <div className='category_Home'>
      <CategoriesItem
        dropDownData={dropDownData}
        data={data}
      />
    </div>
  )
}

export default CategoriesList