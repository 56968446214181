import React, { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";
import { SelectDropdown } from "../../landing-page/common/select-dropdown";
import $ from "jquery";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  reScheduleInterviewReq,
  updateInterviewReq,
  nextRoundInterviewReq,
  scheduleOneMoreRoundInterviewReq,
} from "../../../api/api-services";
import MultiEmailInputField from "../MultiEmailInputField";
import TextField from "@mui/material/TextField";
import { validateFromAndToTime } from "../../../util/common-utils";

const UpdateInterview = (props) => {
  let user = JSON.parse(localStorage.getItem("across-geo-user"));
  const inputBorder = "1px solid #26428B80";
  const defaultSelectedStatus = {
    label: "Reschedule One more interview",
    value: "Reschedule One more interview",
  };
  const defaultSelectedStatusForClient = {
    label: "schedule one more interview",
    value: "schedule one more interview",
  };
  const [selectedInterviewStatusData, setSelectedInterviewStatusData] =
    useState([]);
  let [skills, setSkills] = useState([
    "HTML",
    "Java Script",
    "Boot Strap",
    "CSS",
  ]);
  const [interviewStatusData, setInterviewStatusData] = useState([
    { label: "Offer", value: "Offer" },
    { label: "Reschedule interview", value: "Reschedule interview" },
    { label: "Cancel interview", value: "Cancel interview" },
    { label: "Reject interview", value: "Reject interview" },
  ]);
  useEffect(() => {
    // Set initial validation schema and other logic here
    if (
      props.candidate.interview.hasRequestForNextRound == true &&
      (user.role !== "CLIENT" || user.role !== "BD")
    ) {
      handleStatusChange([defaultSelectedStatus]);
    }
    if (
      props.candidate.interview.hasRequestForNextRound == true &&  
      (user.role === "CLIENT" || user.role === "BD")
    ) {
      handleStatusChange([defaultSelectedStatusForClient]);
    }
  }, []);
  console.log(user.role ,props.candidate.interview.hasRequestForNextRound,"user.role user.role ")
  if (user.role === "CLIENT" || user.role === "BD") {
    // Find the index of "Reschedule interview" option in interviewStatusData
    const rescheduleIndex = interviewStatusData.findIndex(
      (status) => status.label === "Reschedule interview"
    );

    if (rescheduleIndex !== -1) {
      // Replace the option with defaultSelectedStatusForClient
      interviewStatusData[rescheduleIndex] = defaultSelectedStatusForClient;

      // Update the state with modified interviewStatusData
      setInterviewStatusData([...interviewStatusData]);
    }
  }

  const today = dayjs();
  const [dateValue, setDateValue] = useState("");
  const [selectedEmails, setSelectedEmails] = useState([]);
  let [interviewModedata, setInterviewModedata] = useState([
    { label: "OFFLINE", value: "OFFLINE" },
    { label: "ONLINE", value: "ONLINE" },
  ]);
  let [selectedMode, setSelectedMode] = useState([]);
  let [cancelCount, setCancelCount] = useState(0);
  let [rejectCount, setRejectCount] = useState(0);
  let initialValues = {
    interviewStatus: selectedInterviewStatusData[0]?.value
      ? selectedInterviewStatusData[0].value
      : "",
    reason: "",
    phone: "",
    date: "",
    mode: "",
    fromTime: "",
    participants: [],
    totime: "",
    linkorlocation: "",
  };
  let cancelorRejectScheema = {
    interviewStatus: yup.string().required("Status is required"),
    reason: yup.string().trim().required("Reason is required"),
  };
  let reScheduleScheema = {
    interviewStatus: yup.string().required("Status is required"),
    date: yup.string().required("Date is required"),
    mode: yup.string().required("Interview Mode is required"),
    fromTime: yup
      .string()
      .required("Please enter proper time")
      .test("am-pm", "Invalid time format. Please provide AM/PM", (value) => {
        if (!value) {
          return false; // Value is required
        }
        return /(AM|PM)$/i.test(value);
      })
      .test(
        "valid-time",
        "From Time must be earlier than To Time",
        function (value) {
          const toTime = this.parent.totime;
          return validateFromAndToTime(value, toTime);
        }
      )
      .test("past-time", "Past time not allowed", function (value) {
        const currentDate = dayjs().format("YYYY-MM-DD");
        const selectedDate = this.parent.date;
        if (selectedDate === currentDate) {
          const currentTime = dayjs().format("hh:mm A");
          return dayjs(value, "hh:mm A").isAfter(dayjs(currentTime, "hh:mm A"));
        }
        return true;
      }),
    totime: yup
      .string()
      .required("Please enter proper time")
      .test("am-pm", "Invalid time format. Please provide AM/PM", (value) => {
        if (!value) {
          return false; // Value is required
        }
        return /(AM|PM)$/i.test(value);
      })
      .test(
        "valid-time",
        "From Time must be earlier than To Time",
        function (value) {
          const fromTime = this.parent.fromTime;
          return validateFromAndToTime(fromTime, value);
        }
      )
      .test("past-time", "Past time not allowed", function (value) {
        const currentDate = dayjs().format("YYYY-MM-DD");
        const selectedDate = this.parent.date;
        if (selectedDate === currentDate) {
          const currentTime = dayjs().format("hh:mm A");
          return dayjs(value, "hh:mm A").isAfter(dayjs(currentTime, "hh:mm A"));
        }
        return true;
      }),
    participants: yup.array(),
    linkorlocation: yup.string().required("Required"),
  };
  let offerSchema = {
    interviewStatus: yup.string().required("Status is required"),
  };
  const [validationSchema, setValidationSchema] = useState(
    yup.object({
      ...offerSchema,
    })
  );
  const handleEmailChange = (data) => {
    setSelectedEmails([...data]);
    if (data.length > 0) {
      formikDetails.setFieldValue("participants", [...data], true);
    } else {
      formikDetails.setFieldValue("participants", [], true);
    }
  };
  const onSubmit = async (values) => {
    let interStatus = selectedInterviewStatusData[0]?.value;
    console.log({ interStatus });
    let response;
    let payload = {};
    if (
      interStatus === "Cancel interview" ||
      interStatus === "Reject interview"
    ) {
      payload.candidateID = props.candidate.id;
      payload.jobId = props.job.id;
      payload.bdId = user.uid;
      payload.feedback = values.reason;
      let status = interStatus === "Cancel interview" ? "cancel" : "reject";
      try {
        response = await updateInterviewReq(status, payload);
        if (response.status === 200 || response.statusCode === 200) {
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>
                {interStatus === "Cancel interview"
                  ? "Interview Cancelled"
                  : "Candidate Rejected"}{" "}
                successfully
              </div>
            </>
          );
          props.closeModal();
        }
      } catch (err) {
        console.error("cancel or reject api error");
      }
    } else if (interStatus === "Offer") {
      payload.candidateID = props.candidate.id;
      payload.jobId = props.job.id;
      payload.bdId = user.uid;
      let status = "offer";
      try {
        response = await updateInterviewReq(status, payload);
        if (response.status === 200 || response.statusCode === 200) {
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>Candidate Offered successfully</div>
            </>
          );
          props.closeModal();
        }
      } catch (err) {
        console.error("cancel or reject api error");
      }
    } else if (
      interStatus === "schedule one more interview" &&
      (user.role === "CLIENT" || user.role === "BD")
    ) {
      payload.candidateID = props.candidate.id;
      payload.jobPostId = props.job.id;

      try {
        response = await nextRoundInterviewReq(payload);
        console.log(response);
        if (response.status === 200 || response.statusCode === 200) {
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>Requested for one more round</div>
            </>
          );
          props.closeModal();
        }
      } catch (err) {
        console.error("cancel or reject api error");
      }
    } else if (interStatus === "Reschedule One more interview") {
      payload = {
        candidateID: props.candidate.id,
        date: values.date,
        interviewType: "EXTERNAL",
        startTime: values.fromTime,
        endTime: values.totime,
        schedulerId: user.uid,
        schedulerName: user.username,
        interviewMode: values.mode,
        jobPostId: props.job.id,
        interviewersId: props?.job?.clientId,
        participants: [props.clientData?.email, props.candidate?.email],
      };
      if (values?.participants?.length > 0) {
        values?.participants?.map((emailid) => {
          if (!payload?.participants?.includes(emailid)) {
            payload.participants.push(emailid);
          }
        });
      }
      if (values.mode === "ONLINE") {
        payload.meetingLink = values.linkorlocation;
      } else if (values.mode === "OFFLINE") {
        payload.locationDetails = values.linkorlocation;
      }
      // try {
      //   response = await scheduleOneMoreRoundInterviewReq(payload);
      //   console.log(response)
      //   if(response.response.status === 400 ){

      //     props.toast.error(
      //       <>
      //         <div className="toast-heading">Error</div>
      //         <div>Interviews already exists with given date and time</div>
      //       </>
      //     );

      //   }
      //   if (

      //     response.data.statusCode === 201 ||
      //     response.data.response === "Next Interview scheduled"
      //   ) {
      //     props.toast.success(
      //       <>
      //         <div className="toast-heading">Success</div>
      //         <div>Interview Rescheduled successfully</div>
      //       </>
      //     );
      //     props.closeModal();
      //   } else {
      //     props.toast.info(
      //       <>
      //         <div className="toast-heading">Info</div>
      //         <div>
      //           {response?.data?.response
      //             ?response?.data?.response
      //             : "Something went wrong"}
      //         </div>
      //       </>
      //     );
      //   }
      // } catch (err) {
      //   console.error("cancel or reject api error");
      // }
      try {
        response = await scheduleOneMoreRoundInterviewReq(payload);
        console.log(response);

        if (response.status === 201) {
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>Interview Rescheduled successfully</div>
            </>
          );
          props.closeModal(); // Close the modal on success
        } else {
          if (response.response.status === 400) {
           
            props.toast.info(
              <>
                <div className="toast-heading">Error</div>
                <div>{response?.response?.data?.response}</div>
              </>
            );
          }
        }
      } catch (err) {
        console.error("cancel or reject API error", err);
      }
    } else if (interStatus === "Reschedule interview") {
      payload = {
        candidateID: props.candidate.id,
        date: values.date,
        interviewType: "EXTERNAL",
        startTime: values.fromTime,
        endTime: values.totime,
        schedulerId: user.uid,
        schedulerName: user.username,
        interviewMode: values.mode,
        jobPostId: props.job.id,
        interviewersId: props?.job?.clientId,
        participants: [props.clientData?.email, props.candidate?.email],
      };
      if (values?.participants?.length > 0) {
        values?.participants?.map((emailid) => {
          if (!payload?.participants?.includes(emailid)) {
            payload.participants.push(emailid);
          }
        });
      }
      if (values.mode === "ONLINE") {
        payload.meetingLink = values.linkorlocation;
      } else if (values.mode === "OFFLINE") {
        payload.locationDetails = values.linkorlocation;
      }
      try {
        response = await reScheduleInterviewReq(payload);
        if (
          response.status === 200 ||
          response.statusCode === 201 ||
          response.response === "Interview scheduled successfully"
        ) {
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>Interview Rescheduled successfully</div>
            </>
          );
          props.closeModal();
        } else {
          props.toast.info(
            <>
              <div className="toast-heading">Info</div>
              <div>
                {response?.response?.data?.response
                  ? response?.response?.data?.response
                  : ""}
              </div>
            </>
          );
        }
        // if(response &&
        //   response?.response &&
        //   response?.response?.data &&
        //   response?.response?.data?.statusCode === 400 ){
        //  props.toast.info(
        //     <>
        //       <div className="toast-heading">Info</div>
        //       <div>
        //        {response?.response?.data?.response}
        //       </div>
        //     </>

        //   );
        // }
      } catch (err) {
        console.error("cancel or reject api error");
      }
    }
  };
  const formikDetails = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });
  const handleModeChange = (data) => {
    setSelectedMode([...data]);
    if (data.length > 0) {
      formikDetails.setFieldValue("mode", data?.[0].value, true);
      if (data?.[0].value === "ONLINE") {
        setValidationSchema(
          yup.object({
            ...reScheduleScheema,
            linkorlocation: yup
              .string()
              .matches(
                /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/i,
                "Enter proper link"
              )
              .required("Required"),
          })
        );
      } else if (data?.[0].value === "OFFLINE") {
        setValidationSchema(
          yup.object({
            ...reScheduleScheema,
          })
        );
      }
    } else {
      formikDetails.setFieldValue("mode", "", true);
    }
  };
  const handleStatusChange = (data) => {
    formikDetails.setFieldValue("interviewStatus", data[0]?.value);
    setSelectedInterviewStatusData([...data]);
    if (data.length > 0) {
      if (
        data[0].value === "Cancel interview" ||
        data[0].value === "Reject interview"
      ) {
        setValidationSchema(yup.object({ ...cancelorRejectScheema }));
        setRejectCount(0);
      } else if (data[0].value === "Offer") {
        setValidationSchema(yup.object({ ...offerSchema }));
      } else if (data[0].value === "Reschedule interview") {
        setValidationSchema(yup.object({ ...reScheduleScheema }));
        setRejectCount(0);
      } else if (data[0].value === "Reschedule One more interview") {
        setValidationSchema(yup.object({ ...reScheduleScheema }));
        setRejectCount(0);
      } else {
        setValidationSchema(yup.object({ ...offerSchema }));
      }
    }
  };
  const handleCount = (value) => {
    let charCount = value.length,
      current = $("#current");
    current.text(charCount);
  };
  const handleCountForCancelReject = (value) => {
    if (selectedInterviewStatusData[0]?.value === "Cancel interview") {
      setCancelCount(value.length);
      setRejectCount(0);
    } else {
      setRejectCount(value.length);
      setCancelCount(0);
    }
  };
  const fileHandler = (event) => {
    var reader = new FileReader();
    console.log(event.target.files);
  };
  return (
    <div className="update-interview-form">
      <form onSubmit={formikDetails.handleSubmit} className="update-form-tag">
        <div className="form-container">
          <div className="individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Interview Status</span>
            </div>
            {console.log(interviewStatusData,"interviewStatusData")}
            <div className="popins-medium update-interview-dropdown">
              <SelectDropdown
                data={interviewStatusData}
                onChange={handleStatusChange}
                selectedData={selectedInterviewStatusData}
                deselection={true}
                searchable={false}
                disabled={
                  props.candidate.interview.hasRequestForNextRound == true
                }
              />
            </div>
            {formikDetails.errors.interviewStatus &&
            formikDetails.touched.interviewStatus ? (
              <div className="text-red-500 error_text md:mt-1">
                {formikDetails.errors.interviewStatus}
              </div>
            ) : null}
          </div>
          {selectedInterviewStatusData[0]?.value === "Reschedule interview" ? (
            <>
              <div className="individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Date and Time</span>
                </div>
                <div className="popins-medium">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      minDate={today}
                      slotProps={{
                        textField: (props) => ({
                          error: false,
                          readOnly: true,
                          // label: position === "start" ? "From" : "To",
                        }),
                      }}
                      value={dateValue}
                      id="date"
                      name="date"
                      onChange={(newValue) => {
                        formikDetails.setFieldValue(
                          "date",
                          dayjs(newValue).format("YYYY-MM-DD")
                        );
                        setDateValue(newValue);
                      }}
                      format="DD-MM-YYYY"
                      // placeholder={<span className="date-placeholder">Select...</span>}
                      className="date-picker"
                    />
                  </LocalizationProvider>
                  {formikDetails.errors.date &&
                  formikDetails.touched.date &&
                  !formikDetails.values.date ? (
                    <div className="text-red-500 error_text md:mt-1">
                      {formikDetails.errors.date}
                    </div>
                  ) : null}
                </div>
                <div className="mt-2 time-label">
                  <span className="create-labels">From</span>
                  <span className="create-labels">To</span>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["MultiInputTimeRangeField"]}>
                    <MultiInputTimeRangeField
                      // disablePast={true}
                      slotProps={{
                        textField: (props) => ({
                          error: false,

                          // label: position === "start" ? "From" : "To",
                        }),
                      }}
                      onChange={(value) => {
                        if (
                          value[0] !== null &&
                          value[0]?.$d !== "Invalid Date"
                        ) {
                          formikDetails.setFieldValue(
                            "fromTime",
                            dayjs(value[0]).format("hh:mm:ss A"),
                            true
                          );
                        }
                        if (
                          value[1] !== null &&
                          value[1]?.$d !== "Invalid Date"
                        ) {
                          formikDetails.setFieldValue(
                            "totime",
                            dayjs(value[1]).format("hh:mm:ss A"),
                            true
                          );
                        }
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {/* {(formikDetails.errors.fromTime &&
                  formikDetails.touched.fromTime) ||
                (formikDetails.errors.totime &&
                  formikDetails.touched.totime) ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formikDetails.errors.totime || formikDetails.errors.totime}
                  </div>
                ) : null} */}
                {(formikDetails.errors.fromTime &&
                  formikDetails.touched.fromTime) ||
                (formikDetails.errors.totime &&
                  formikDetails.touched.totime) ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formikDetails.errors.fromTime ||
                      formikDetails.errors.totime}
                  </div>
                ) : null}
              </div>
              <div className="field-wrapper mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Interview Mode</span>
                </div>
                <div className="input-ele">
                  <SelectDropdown
                    data={interviewModedata}
                    onChange={handleModeChange}
                    selectedData={selectedMode}
                    deselection={true}
                    searchable={false}
                  />
                </div>
                {formikDetails.errors.mode &&
                formikDetails.touched.mode &&
                !formikDetails.values.mode ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formikDetails.errors.mode}
                  </div>
                ) : null}
              </div>
              <div className="field-wrapper mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Other Participants</span>
                </div>
                <div className="input-ele">
                  <MultiEmailInputField
                    handleEmailChange={handleEmailChange}
                    selectedEmails={selectedEmails}
                    fieldName={"participants"}
                  />
                </div>
              </div>
              {selectedMode?.[0]?.value === "ONLINE" ? (
                <div className="individual-field mt-4">
                  <div className="mb-2 label">
                    <span className="create-labels">Meeting Link</span>
                  </div>
                  <div className="popins-medium">
                    <input
                      className="input-field"
                      name="linkorlocation"
                      id="linkorlocation"
                      type={"text"}
                      onChange={formikDetails.handleChange}
                    />
                  </div>
                  {formikDetails.errors.linkorlocation &&
                  formikDetails.touched.linkorlocation ? (
                    <div className="text-red-500 error_text md:mt-1">
                      Link is Required
                    </div>
                  ) : null}
                </div>
              ) : (
                selectedMode?.[0]?.value === "OFFLINE" && (
                  <div className="individual-field mt-3">
                    <div className="mb-2 label">
                      <span className="create-labels">Location</span>
                    </div>
                    <div className="popins-medium">
                      <textarea
                        className="form-control shadow-none textarea_contact"
                        name="linkorlocation"
                        id="linkorlocation"
                        cols="40"
                        rows="5"
                        value={formikDetails.values.linkorlocation}
                        onChange={(e) => {
                          formikDetails.setFieldValue(
                            "linkorlocation",
                            e.target.value,
                            true
                          );
                        }}
                        style={{ border: inputBorder }}
                        //   onKeyDown={handleEnter}
                        maxLength="500"
                        onKeyUp={(e) => handleCount(e.target?.value)}
                      ></textarea>
                      <div className="d-flex mb-1 labelTitleDemo">
                        {formikDetails.errors.linkorlocation &&
                        formikDetails.touched.linkorlocation ? (
                          <span className="text-red-500 float-left error_text md:mt-1">
                            Location is Required
                          </span>
                        ) : null}
                        <span className="float-right ml-auto">
                          <span id="current">0</span>
                          <span id="maximum">&nbsp;/ 500</span>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              )}
            </>
          ) : selectedInterviewStatusData[0]?.value ===
              "Reschedule One more interview" && (user.role !== "CLIENT" || user.role !== "BD") ? (
            <>
              <div className="individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Previous Interview List</span>
                </div>
                <div className="input-ele">
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={`${new Date(
                      props.candidate.interview.date
                    ).toDateString()} ${
                      props.candidate.interview.startTime
                    } - ${props.candidate.interview.endTime}`}
                    inputProps={{
                      style: {
                        background: "#f7a64f",
                        color: "white",
                        // height: "0.43em !important",
                      },
                    }}
                    id="demo-helper-text-aligned"
                  />
                </div>
                <div className="mb-2 label">
                  <span className="create-labels">Date and Time</span>
                </div>
                <div className="popins-medium">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      minDate={dayjs(props.candidate.interview.date)}
                      slotProps={{
                        textField: (props) => ({
                          error: false,
                          readOnly: true,
                          // label: position === "start" ? "From" : "To",
                        }),
                      }}
                      value={dateValue}
                      id="date"
                      name="date"
                      onChange={(newValue) => {
                        formikDetails.setFieldValue(
                          "date",
                          dayjs(newValue).format("YYYY-MM-DD")
                        );
                        setDateValue(newValue);
                      }}
                      format="DD-MM-YYYY"
                      // placeholder={<span className="date-placeholder">Select...</span>}
                      className="date-picker"
                    />
                  </LocalizationProvider>
                  {formikDetails.errors.date &&
                  formikDetails.touched.date &&
                  !formikDetails.values.date ? (
                    <div className="text-red-500 error_text md:mt-1">
                      {formikDetails.errors.date}
                    </div>
                  ) : null}
                </div>
                <div className="mt-2 time-label">
                  <span className="create-labels">From</span>
                  <span className="create-labels">To</span>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["MultiInputTimeRangeField"]}>
                    <MultiInputTimeRangeField
                      // disablePast={true}
                      slotProps={{
                        textField: (props) => ({
                          error: false,

                          // label: position === "start" ? "From" : "To",
                        }),
                      }}
                      onChange={(value) => {
                        if (
                          value[0] !== null &&
                          value[0]?.$d !== "Invalid Date"
                        ) {
                          formikDetails.setFieldValue(
                            "fromTime",
                            dayjs(value[0]).format("hh:mm:ss A"),
                            true
                          );
                        }
                        if (
                          value[1] !== null &&
                          value[1]?.$d !== "Invalid Date"
                        ) {
                          formikDetails.setFieldValue(
                            "totime",
                            dayjs(value[1]).format("hh:mm:ss A"),
                            true
                          );
                        }
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {(formikDetails.errors.fromTime &&
                  formikDetails.touched.fromTime) ||
                (formikDetails.errors.totime &&
                  formikDetails.touched.totime) ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formikDetails.errors.totime || formikDetails.errors.totime}
                  </div>
                ) : null}
              </div>
              <div className="field-wrapper mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Interview Mode</span>
                </div>
                <div className="input-ele">
                  <SelectDropdown
                    data={interviewModedata}
                    onChange={handleModeChange}
                    selectedData={selectedMode}
                    deselection={true}
                    searchable={false}
                  />
                </div>
                {formikDetails.errors.mode &&
                formikDetails.touched.mode &&
                !formikDetails.values.mode ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formikDetails.errors.mode}
                  </div>
                ) : null}
              </div>
              <div className="field-wrapper mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Other Participants</span>
                </div>
                <div className="input-ele">
                  <MultiEmailInputField
                    handleEmailChange={handleEmailChange}
                    selectedEmails={selectedEmails}
                    fieldName={"participants"}
                  />
                </div>
              </div>
              {selectedMode?.[0]?.value === "ONLINE" ? (
                <div className="individual-field mt-4">
                  <div className="mb-2 label">
                    <span className="create-labels">Meeting Link</span>
                  </div>
                  <div className="popins-medium">
                    <input
                      className="input-field"
                      name="linkorlocation"
                      id="linkorlocation"
                      type={"text"}
                      onChange={formikDetails.handleChange}
                    />
                  </div>
                  {formikDetails.errors.linkorlocation &&
                  formikDetails.touched.linkorlocation ? (
                    <div className="text-red-500 error_text md:mt-1">
                      Link is Required
                    </div>
                  ) : null}
                </div>
              ) : (
                selectedMode?.[0]?.value === "OFFLINE" && (
                  <div className="individual-field mt-3">
                    <div className="mb-2 label">
                      <span className="create-labels">Location</span>
                    </div>
                    <div className="popins-medium">
                      <textarea
                        className="form-control shadow-none textarea_contact"
                        name="linkorlocation"
                        id="linkorlocation"
                        cols="40"
                        rows="5"
                        value={formikDetails.values.linkorlocation}
                        onChange={(e) => {
                          formikDetails.setFieldValue(
                            "linkorlocation",
                            e.target.value,
                            true
                          );
                        }}
                        style={{ border: inputBorder }}
                        //   onKeyDown={handleEnter}
                        maxLength="500"
                        onKeyUp={(e) => handleCount(e.target?.value)}
                      ></textarea>
                      <div className="d-flex mb-1 labelTitleDemo">
                        {formikDetails.errors.linkorlocation &&
                        formikDetails.touched.linkorlocation ? (
                          <span className="text-red-500 float-left error_text md:mt-1">
                            Location is Required
                          </span>
                        ) : null}
                        <span className="float-right ml-auto">
                          <span id="current">0</span>
                          <span id="maximum">&nbsp;/ 500</span>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              )}
            </>
          ) : selectedInterviewStatusData[0]?.value === "Cancel interview" ||
            selectedInterviewStatusData[0]?.value === "Reject interview" ? (
            <>
              <div className="individual-field mt-7">
                <div className="mb-2 label">
                  <span className="create-labels">
                    Reason for{" "}
                    {selectedInterviewStatusData[0]?.value ===
                    "Reject interview"
                      ? "Reject"
                      : "Cancel"}
                  </span>
                </div>
                <div className="popins-medium">
                  <textarea
                    className="form-control shadow-none textarea_contact"
                    name="reason"
                    cols="40"
                    rows="5"
                    value={formikDetails.values.reason}
                    onChange={(e) => {
                      formikDetails.handleChange(e);
                      handleCount(e.target.value);
                    }}
                    style={{
                      border: inputBorder,
                      resize: "none",
                      height: "10rem",
                    }}
                    onKeyDown={(e) => {
                      if (
                        selectedInterviewStatusData[0]?.value ===
                        "Cancel interview"
                      ) {
                        setCancelCount(formikDetails.values.reason.length);
                        setRejectCount(0);
                      } else {
                        setRejectCount(formikDetails.values.reason.length);
                        setCancelCount(0);
                      }
                    }}
                    maxLength="500"
                    onKeyUp={(e) => handleCountForCancelReject(e.target?.value)}
                  ></textarea>
                  <div className="mt-1 mr-1 text-sm text-gray-500 text-right">
                    {selectedInterviewStatusData[0]?.value ===
                    "Cancel interview"
                      ? cancelCount
                      : rejectCount}
                    /500
                  </div>
                </div>
                {formikDetails.errors.reason && formikDetails.touched.reason ? (
                  <div className="text-red-500 error_text md:mt-1">
                    Reason for{" "}
                    {selectedInterviewStatusData[0]?.value ===
                    "Reject interview"
                      ? "Reject"
                      : "Cancel"}{" "}
                    is required
                  </div>
                ) : null}
              </div>
            </>
          ) : selectedInterviewStatusData[0]?.value === "Interview taken" ? (
            <>
              <div className="individual-field mt-7">
                <div className="mb-2 label">
                  <span className="create-labels">Upload Video</span>
                </div>
                <div className="popins-medium">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={fileHandler}
                    accept=".mp4"
                    // disabled={
                    //   props.mode === "Edit User" ||
                    //   selectedPrivilege[0]?.value === "ACCOUNT-MANAGER"
                    // }
                    id="contained-button-file"
                  />
                  <label
                    htmlFor="contained-button-file"
                    className="drag-and-drop-zone flex items-center"
                  >
                    <div className="text-center text-container">
                      <p>Drag and Drop the video</p>
                      <p>or </p>
                      <p className="upload-btn">Upload</p>
                    </div>
                  </label>
                </div>
                {/* {formikDetails.errors.name && formikDetails.touched.name ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formikDetails.errors.name}
                  </div>
                ) : null} */}
              </div>
              <div className="individual-field mt-7">
                <div className="mb-2 label">
                  <span className="create-labels">Skill Set Rating</span>
                </div>
              </div>
              {skills.map((data) => {
                return (
                  <div className="flex rating-individual-field mt-2">
                    <div className="mb-2 label">
                      <span className="create-labels">{data}</span>
                    </div>
                    <div className="popins-medium">
                      <input className="rating-input-field" type={"text"} />
                    </div>
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
        <div className="fixed bottom-16 right-7">
          <div className="hire-now-btn">
            <button
              type="submit"
              className="datatrimmer"
              disabled={
                props.candidate.interview.hasRequestForNextRound == true &&
                (user.role === "CLIENT" || user.role === "BD" )
              }
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateInterview;
