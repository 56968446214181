import React, { useState, useEffect } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "./RegistrationContainer.scss";
import CandidateRegistrationForm from "./candidate-registration/CandidateRegistrationForm";
import EmployerRegistrationForm from "./employer-registration/EmployerRegistrationForm";
import ProfileIcon from "../../website-assets/profile.svg";
import { ReactComponent as EmpIcon } from "../../website-assets/employeerNew.svg";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";

const RegistrationContainer = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  let handelTabs = (tabName) => {
    setSelectedTab(tabName);
  };
  useEffect(() => {
    setSelectedTab(
      props.userInfo.mode === "StudentRegistration"
        ? 0
        : props.userInfo.mode === "ClientRegistration"
        ? 1
        : 0
    );
  }, [props.userInfo.mode]);
  console.log(props);
  return (
    <div className="registrationContainer">
      <Tabs selectedIndex={selectedTab} onSelect={handelTabs}>
        <div className="reg_pannelTabs my-2.5">
          <TabList
            style={{ padding: "5px 1px 5px 10px", gap: "10px" }}
            className="flex outline-none justify-center"
          >
            <Tab className={selectedTab === 0 ? "selectedTab" : ""}>
              <PersonIcon /> <span className="mx-2">Candidate</span>
            </Tab>
            <Tab
              className={
                selectedTab === 1 ? "selectedTab  mx-4 ml-2" : " mx-4 ml-2"
              }
            >
              <WorkIcon /> <span className="mx-2">Employer</span>
            </Tab>
          </TabList>
        </div>

        <TabPanel>
          <CandidateRegistrationForm
            handelTabs={props.handelTabs}
            setUserInfo={props.setUserInfo}
            handelOpenOpenOtpModal={props.handelOpenOpenOtpModal}
            handleRegistrationData={props.handleRegistrationData}
            // registrationData={props.registrationData}
            registrationData={
              props.userInfo.mode === "ClientRegistration"
                ? {}
                : props.registrationData
            }
            isNewUser={props?.isNewUser}
            newUserId={props?.newUserId}
          />
        </TabPanel>

        <TabPanel>
          <EmployerRegistrationForm
            handelTabs={props.handelTabs}
            setUserInfo={props.setUserInfo}
            handelOpenOpenOtpModal={props.handelOpenOpenOtpModal}
            handleRegistrationData={props.handleRegistrationData}
            registrationData={
              props.userInfo.mode === "StudentRegistration"
                ? {}
                : props.registrationData
            }
            isNewUser={props?.isNewUser}
            newUserId={props?.newUserId}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default RegistrationContainer;
