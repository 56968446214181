import React, { useState, useEffect, Fragment, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import cx from "classnames";
import { TextField } from "@mui/material";
import style from "../../../../landing-page/common/inputs/inputs.module.scss";
import { defaultCountry, existEmailPhoneCheck} from "../../../../../api/api-services";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/material.css";

import "react-phone-number-input/style.css";

import { Link, useNavigate } from "react-router-dom";
import {
 
  InputAdornment,
 
  Tooltip,
} from "@material-ui/core";

import VisibleEye from "../../../website-assets/visibleEyeNew.png"
import InvisibleEye from "../../../website-assets/invisibleEyeNew.png"

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "./CandidateRegistrationForm.scss"

const CandidateRegistrationForm = (props) => {
    const navigate = useNavigate();
  const { state } = useLocation();

  const EMAIL_PATTERN =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [emailError, setEmailError] = useState();
  const [companyData, setCompanyData] = useState([]);
  const [value, setValue] = useState();
  const [error, setError] = useState({ phone: false, validPhone: false });
  const [selectedCompanyData, setSelectedCompanyData] = useState([]);
  const inputBorder = "1px solid #26428B80";
  const inputBorderErr = "1px solid #ea0322";
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [presentCountry, setPresentCountry] = useState("");
  const [availabilityStatusEmail, setAvailabilityStatusEmail] = useState(null);
  const [availabilityStatusPhone, setAvailabilityStatusPhone] = useState(null);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickConfirmShowPassword = () => {
    setConfirmShowPassword(!confirmShowPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let initialValues = {
    name: props?.registrationData?.name ? props?.registrationData?.name : "",
    email: props?.registrationData?.email ? props?.registrationData?.email : "",

    mobile: props?.registrationData?.mobile
      ? props?.registrationData?.mobile
      : "",
    password: props?.registrationData?.password
      ? props?.registrationData?.password
      : "",
    confirmPassword: props?.registrationData?.confirmPassword
      ? props?.registrationData?.confirmPassword
      : "",
  };
  const onSubmit = async (values) => {
    console.log({values})
    // if(props?.registrationData?.mobile === values.mobile){
    //   toast.info(
    //     <>
    //       <div className="toast-heading">Success</div>
    //       <div>No changes were done to Phone number</div>
    //     </>
    //   );
    //   return;
    // }
    const lowercasedEmail = values?.email?.toLowerCase();
    const updatedValues = {
      ...values,
      mobile:`+${formikDetails.values.mobile.replace(/^\+/, "")}`,
      email: lowercasedEmail,
      ...(props.isNewUser && { reference: props.newUserId }),
    };
    await existEmailPhoneCheck(`+${formikDetails.values.mobile.replace(/^\+/, "")}`)
      .then((resp) => {
        console.log({ resp });
        resp.data.statusCode === 200 &&
        props.setUserInfo({
            mode: "StudentRegistration",
            mobileNumber: `+${formikDetails.values.mobile.replace(/^\+/, "")}`,
          });
        props.handelOpenOpenOtpModal(true)
          
        props.handleRegistrationData(updatedValues);
      })
      .catch((error) => {
        // Handle the error (e.g., display a message to the user)
        console.error("Availability check failed:", error);
      });
  };
  const validationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .min(3, "Minimum 3 characters")
      .max(25, "Maximum 25 characters")
      .matches(/^[A-Za-z ]*$/, "Name should be alphabetic")
      .required("Name is required"),
    email: yup
      .string()
      .trim()
      .required("Email id is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Enter valid email Id"),
    //   .test("Unique", "User already Exist!", (values) => {
    //     if (props.mode !== "Edit User") {
    //       return duplicatesCheck(props.tester, "", values, "email");
    //     } else {
    //       return true;
    //     }
    //   })
    mobile: yup.string().required("Valid Phone number is required"),
    password: yup
      .string()
      .matches(/^\S*$/, `Weak password, please match the password criteria`)
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,20}$",
        `Weak password, please match the password criteria`
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "Confirm password not matched with new password"
      )
      .required("Confirm password is required"),
  });

  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  function isEmailValid(email) {
    return EMAIL_PATTERN.test(email);
  }

  function checkValidEmail() {
    if (formikDetails.values.email) {
      isEmailValid(formikDetails.values.email);
      //    && checkIfEmailExists();
    } else {
      setEmailError("");
      formikDetails.setFieldValue("name", "");
      formikDetails.setFieldValue("phoneNumbers", "");
    }
  }
  function handleTrimmedBlur(event) {
    event.target.value = event.target.value.trim();
    formikDetails.handleChange(event);
    formikDetails.handleBlur(event);
  }

  const handleOnBlur = (id) => {
    console.log(formikDetails.values.mobile, "checl", value);
    if (!value) {
      if (!props?.registrationData?.mobile) {
        setError({ ...error, [id]: true });
      }
    } else if (
      !isValidPhoneNumber("+" + formikDetails.values.mobile?.toString())
    ) {
      setError({ ...error, [id]: false, validPhone: true });
    } else {
      setError({ ...error, [id]: false, validPhone: false });
    }
  };
  useEffect(() => {
    // Fetch the defaultCountry only if the mobile number is not already present in props
    if (!props?.registrationData?.mobile) {
      defaultCountry()
        .then((res) => {
          setPresentCountry(res.data.country);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [props?.registrationData?.mobile]);
  const checkAvailability = async (id, value) => {
    try {
      const response = await existEmailPhoneCheck(value);

      if (id === "email") {
        setAvailabilityStatusEmail(response?.response?.data?.response);
      } else if (id === "mobile") {
        setAvailabilityStatusPhone(response?.response?.data?.response);
      }
    } catch (error) {
      console.error("Error occurred while checking availability:", error);
    }
  };

  const STRONG_PASSWORD_PATTERNS = [
    {
      pattern: /^.{8,20}$/,
      message: "Should contain at least 8 characters and at most 20 characters",
    },
    {
      pattern: /.*[0-9].*/,
      message: "Should contain at least one digit",
    },
    {
      pattern: /.*[A-Z].*/,
      message: "Should contain at least one upper case alphabet",
    },
    {
      pattern: /.*[a-z].*/,
      message: "Should contain at least one lower case alphabet",
    },
    {
      pattern: /.*[!@#$%&*()+=^].*/,
      message:
        "Should contain at least one special character which includes !@#$%&*()+=^",
    },
    {
      pattern: /^\S*$/,
      message: "Should doesn't contain any white space",
    },
  ];
  const nameInputRef = useRef(null);

  useEffect(() => {
    // Set focus on the name field when the component mounts
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []); 
  const changeGreenBorderStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#007456"
      },
      "&:hover fieldset": {
        borderColor: "#007456"
      }
    }
  }
  return (
    <div className='candidateRegistrationForm  '>
         <div className="candidateContent">
          <form
            // onSubmit={formikDetails.handleSubmit}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent the default form submission
              }
            }}
          >
           
            <div className="required-fields-styles">
              <div className="formHeader">
                <div className=" individual-field">
                  <div className="label mb-2">
                    <span className="text-red-500 ">*</span>
                    <span className="create-labels mx-1">Name</span>
                  </div>
                  <div className="popins-medium">
                    <TextField
                      error={
                        formikDetails.errors.name && formikDetails.touched.name
                      }
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                       // change the border color from blue to green on focous
                       sx={changeGreenBorderStyle}
                      onClick={() => {}}
                      type="text"
                      name="name"
                      id="name"
                      inputRef={nameInputRef}
                      // autoFocus={!props?.registrationData?.mobile}
                      inputProps={{
                        className: cx(`!text-xs impFont_14px boxedInput `),
                        style: {
                        //   padding: "9.5px 14px",
                          height: "0.43em !important",
                        },
                      }}
                      autoComplete="off"
                      placeholder="Enter your name"
                      onBlur={handleTrimmedBlur}
                      onChange={formikDetails.handleChange}
                      value={formikDetails.values.name}
                    />
                  </div>
                  {formikDetails.errors.name && formikDetails.touched.name ? (
                    <div className="text-red-500 error_text ">
                      {formikDetails.errors.name}
                    </div>
                  ) : null}
                </div>
                <div className="form-group  phoneNumberSection  individual-field text-left">
                  <div className="label mb-2 ">
                    <span className="text-red-500 ">*</span>
                    <span className="create-labels mx-1">Phone Number</span>
                  </div>

                  <PhoneInput
                    type="text"
                    // placeholder="Enter phone number"
                    value={formikDetails.values.mobile}
                    searchPlaceholder="Search..."
                    searchNotFound="No Countries Found"
                    specialLabel=""
                    // autoFocus={ props?.registrationData?.mobile}
                    defaultCountry={
                      presentCountry && presentCountry.toLowerCase()
                    }
                    autoFormat={false}
                    enableAreaCodeStretch
                    country={presentCountry && presentCountry.toLowerCase()}
                    style={{
                      border: `${
                        error.mobile || error.validPhone ? inputBorderErr : ""
                      }`,
                      borderRadius: "5px",
                      width: "330px",
                    }}
                    enableSearch
                    international
                    inputProps={{
                      // className: cx(`!text-xs impFont_14px boxedInput `),
                      style: {
                        // padding: "9.5px 14px",
                        height: "0.43em !important",
                      },
                    }}
                    autoComplete="off"
                    onBlur={(e) => {
                      handleOnBlur("mobile");
                      console.log(e);
                      const phoneNumber = e.target.value.replace(/\s/g, "");
                      const formattedPhoneNumber = phoneNumber.startsWith("+91")
                        ? phoneNumber
                        : `+91${phoneNumber}`;
                      checkAvailability("mobile", formattedPhoneNumber);
                    }}
                    // onKeyUp={() => resetPhoneNumberFieldError(0)}
                    name="mobile"
                    id="mobile"
                    onChange={(e) => {
                      formikDetails.setFieldValue("mobile", e);
                      setValue(e);
                    }}
                    countryCodeEditable={false}
                  />
                  {(error.mobile ||
                    (formikDetails.errors.mobile &&
                      formikDetails.touched.mobile)) && (
                    <div className="error_text">Phone number is required</div>
                  )}
                  {error.validPhone && !error.phone && (
                    <div className="error_text">Invalid mobile number</div>
                  )}
                  <div className="text-red-500 error_text ">
                    {availabilityStatusPhone}
                  </div>
                </div>
              </div>
              <div className=" individual-field " style={{width:"89%"}}>
                <div className="label mb-2">
                  <span className="text-red-500 ">*</span>
                  <span className="create-labels mx-1">Email</span>
                </div>
                <div className="popins-medium" style={{ height: "45px",width:"330px"}}>
                  <div
                    className={cx(
                      "w-full user-email-input-tyle",
                      style["labeled-input"],
                      {
                        [style["number-input-control"]]:
                          props?.type === "number",
                      }
                    )}
                  >
                    <TextField
                      error={
                        formikDetails.errors.email &&
                        formikDetails.touched.email
                      }
                      fullWidth
                      hiddenLabel
                      style={{ height: "32px" }}
                      className={cx("text-xs ", style["form-control"])}
                      placeholder={"Enter your email"}
                       // change the border color from blue to green on focous
                       sx={changeGreenBorderStyle}
                      value={formikDetails.values.email.trim().toLowerCase()}
                      onBlur={(event) => {
                        formikDetails.handleBlur(event);
                        handleTrimmedBlur(event);
                        // checkValidEmail(event);
                        checkAvailability(event.target.id, event.target.value);
                      }}
                      onChange={(event) => {
                        formikDetails.handleChange(event);
                        setAvailabilityStatusEmail("");
                      }}
                      inputProps={{
                        className: cx(`!text-xs impFont_14px boxedInput `),
                        style: {
                          padding: "15px 14px",
                          height: "0.43em !important",
                          font:"normal normal normal 14px Inter",
                        },
                      }}
                      autoComplete="off"
                      name="email"
                      id="email"
                      onFocus={() => setEmailError("")}
                      FormHelperTextProps={{
                        className: "impFont_14px",
                      }}
                      onKeyDown={(evt) =>
                        ["Enter"].includes(evt.key) && evt.preventDefault()
                      }
                    />
                  </div>
                </div>
                {formikDetails.errors.email && formikDetails.touched.email ? (
                  <div className="text-red-500 error_text ">
                    {formikDetails.errors.email}
                  </div>
                ) : null}
                <div className="text-red-500 error_text ">
                  {availabilityStatusEmail}
                </div>
              </div>

              <div className="passwordContainer relative ">
                <div className="individual-field ">
                  <div className="label mb-2">
                    <span className="text-red-500 ">*</span>
                    <span className="create-labels mx-1">Password</span>
                  </div>
                  <TextField
                    name="password"
                    type={showPassword ? "text" : "password"}
                    error={
                      formikDetails.errors.password &&
                      formikDetails.touched.password
                    }
                    autoComplete="off"
                    placeholder="Enter your password"
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                     // change the border color from blue to green on focous
                     sx={changeGreenBorderStyle}
                    value={formikDetails.values.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            className=" cursor-pointer"
                          >
                            {!showPassword ? <img src={InvisibleEye} style={{height:"20px"}}/> : <img src={VisibleEye} style={{height:"20px"}}/>}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      <span className="error_text  flex justify-between -mt-1" style={{width:"300px",marginLeft:"-13px"}}>
                        {formikDetails.touched.password &&
                          formikDetails.errors.password}
                        {!formikDetails.errors.password &&
                        formikDetails.values.password ? (
                          <span style={{color:"#007456"}} className="success_validation_text">
                            Strong password, good
                          </span>
                        ) : null}
                        {STRONG_PASSWORD_PATTERNS.some(
                          ({ pattern }) =>
                            !pattern.test(formikDetails.values.password)
                        ) &&
                          (formikDetails.values.password ||
                            formikDetails.touched.password) && (
                            <Tooltip
                              data-html="true"
                              title={
                                <div>
                                  {STRONG_PASSWORD_PATTERNS.map(
                                    (passwordPattern) => {
                                      return (
                                        <Fragment key={passwordPattern.message}>
                                          {!passwordPattern.pattern.test(
                                            formikDetails.values.password
                                          ) && (
                                            <p
                                              style={{
                                                fontSize: "10px",
                                                fontFamily: "Poppins-Regular",
                                                margin: "1px",
                                              }}
                                            >
                                              {passwordPattern.message}
                                            </p>
                                          )}
                                        </Fragment>
                                      );
                                    }
                                  )}
                                </div>
                              }
                              placement="bottom"
                            >
                              <span
                              style={{left:"29px"}}
                                className={cx(
                                  "cursor-pointer relative password-info-icon",

                                  {
                                    "text-red-500 error_text":
                                      formikDetails.touched.password &&
                                      formikDetails.errors.password,
                                    "text-blue-500 ml-auto":
                                      !formikDetails.touched.password ||
                                      !formikDetails.errors.password,
                                  }
                                )}
                              >
                                <InfoOutlinedIcon />
                              </span>
                            </Tooltip>
                          )}
                      </span>
                    }
                  />

                  {/* {formikDetails.errors.password && formikDetails.touched.password ? (
                <div className="text-red-500 error_text ">
                  {formikDetails.errors.password}
                </div>
              ) : null} */}
                </div>

                <div className="individual-field  ">
                  <div className="label mb-2">
                    <span className="text-red-500 ">*</span>
                    <span className="create-labels mx-1">Confirm Password</span>
                  </div>
                  <TextField
                    name="confirmPassword"
                    type={confirmShowPassword ? "text" : "password"}
                    error={
                      formikDetails.errors.confirmPassword &&
                      formikDetails.touched.confirmPassword
                    }
                     // change the border color from blue to green on focous
                     sx={changeGreenBorderStyle}
                    autoComplete="off"
                    placeholder="Confirm your password"
                    onBlur={(e) => {
      formikDetails.handleBlur(e);
      // Check if passwords match
      const passwordsMatch = formikDetails.values.password === formikDetails.values.confirmPassword;
      setPasswordsMatch(passwordsMatch);
    }}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            onClick={handleClickConfirmShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            className=" cursor-pointer"
                          >
                            {!confirmShowPassword ? (
                              <img src={InvisibleEye} style={{height:"20px"}} />
                            ) : (
                                <img src={VisibleEye} style={{height:"20px"}}/>
                            )}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formikDetails.errors.confirmPassword &&
                  formikDetails.touched.confirmPassword ? (
                    <div className="text-red-500 error_text ">
                      {formikDetails.errors.confirmPassword}
                    </div>
                  ) : null}
                  {/* {passwordsMatch &&
    (formikDetails.touched.confirmPassword ||
      formikDetails.submitCount > 0) && (
    <div className="text-green-500 ml-1 success_validation_text">
      Password matched
    </div>
  )} */}
  {formikDetails.touched.confirmPassword &&
                  formikDetails.values.password.length > 7 &&
                  formikDetails.values.password ===
                    formikDetails.values.confirmPassword ? (
                    <div style={{color:"#007456"}} className=" ml-1 success_validation_text">
                      Password matched
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="search-footer ">
              {/* <button
                className="cancelBtn"
                onClick={() => {
                  state?.source === "backToHome"
                    ? navigate("/")
                    : navigate("/register");
                }}
              >
                Cancel
              </button> */}
              <button
                disabled={
                  availabilityStatusPhone ||
                  availabilityStatusEmail ||
                  (error.validPhone && !error.phone)
                }
                type="submit"
                className="submitBtn"
                name="button_1"
                onClick={(e) => {
                  e.preventDefault()
                  formikDetails.handleSubmit(e);
                }}
               
              >
                Request OTP
              </button>
            </div>
            <div className="form-heading1">
              <p className="mx-2">Already have an account?</p>
              <p className=" cursor-pointer text-blue-500 loginText" onClick={()=>{props.handelTabs(0)}}>Login</p>
            </div>
          </form>
        </div>

        <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        className={"tost-msg-stylinh"}
        draggable={false}
        theme="light"
      />
    </div>
  )
}

export default CandidateRegistrationForm