import React, { useState } from "react";
import "./CompanyLocation.scss";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import WorldMap from "../../../assets/wordMapbg.png";
import HomeAnimated from "../website-assets/homeAnimated.gif";
import GmailAnimated from "../website-assets/gmailAnimated.gif";
import mapLocation from "../../../assets/locationOnMap.svg";


const initialLocations = [
  {
    name: "United States of America",
    email: "contactus@acrossgeo.com",
    address:
      "99 South Almaden Blvd, Suite 600, San Jose, California, CA 95113.",
    uidName: "usa",
  },
  {
    name: "United Kingdom",
    email: "contactus@acrossgeo.com",
    address: "1 Elmfield Park, Bromley - BR1 1LU, United Kingdom.",
    uidName: "uk",
  },
  {
    name: "Canada",
    email: "contactus@acrossgeo.com",
    address:
      "120 Adelaide Street West, Suite 2500, Toronto, ON, M5H 1T1, Canada.",
    uidName: "canada",
  },
  {
    name: "India",
    email: "contactus@acrossgeo.com",
    address:
      "IndiQube South Mile building, Vijayarangam Layout, Jayanagar, Bengaluru, Karnataka, 560070, India.",
    uidName: "india",
  },
];

function CompanyLocation() {
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handlePinHover = (index) => {
    setSelectedLocation(index);
  };

  const handlePinLeave = () => {
    setSelectedLocation(null);
  };

  return (
    <div className="overflow-hidden company_Location">
      <div>
        <p className="companyLocationHead">OUR LOCATIONS</p>
        <p className="companyLocationSubHeader">Find us at our global hubs</p>
      </div>
      <div style={{ justifyContent: "center" }} className="flex">
        <div className="world-map">
          <img src={WorldMap} alt="World Map" />
          {initialLocations.map((location, index) => (
            <div
              key={index}
              className={`pin ${location.uidName.toLowerCase()}`}
              onMouseEnter={() => handlePinHover(index)}
              onMouseLeave={handlePinLeave}
            >
              <LocationOnIcon className="location_icon" />
              {selectedLocation === index && (
                <div className="infoOfLocation">
                  <p className="infoHead1">{location.name}</p>
                  <p className="infoMail1 flex">
                    <img
                      src={GmailAnimated}
                      className="mx-1"
                      style={{ width: "17.5px" }}
                    />{" "}
                    {location.email}
                  </p>
                  <p className="infoMail flex">
                    <img
                      src={HomeAnimated}
                      className="mx-1"
                      style={{ width: "19px" }}
                    />{" "}
                    {location.address}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CompanyLocation;
