import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./FindJobsByKeywordHome.scss";
import ReactTyped from "react-typed";
import SearchIcon from "../../../assets/searchIcon.svg";
import { useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";
import zoomIcon from "../../../assets/zoomicon.svg"
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import JobSearch from "../website-assets/jobSearchOne.svg"
import { getIndustriesListReq, getAllCategories } from "../../../api/api-services";
import { OutlinedInput } from "@material-ui/core";
import { MultiSelectDropdown } from "../../landing-page/common/select-dropdown";
import CategoryIcon from "../website-assets/categoryIcon.svg"
import locationicon from '../../../assets/my_location logo.svg'
import searchIcon from '../../../assets/searchIcon.svg'

const Counter = ({ initialValue, targetValue, label }) => {
  const [count, setCount] = useState(initialValue);


  useEffect(() => {
    const speed = 400; // Adjust the speed here
    const time = targetValue / speed;

    const interval = setInterval(() => {
      if (count < targetValue) {
        setCount(Math.ceil(count + time));
      } else {
        setCount(targetValue);
        clearInterval(interval);
      }
    }, 1);

    return () => clearInterval(interval);
  }, [count, targetValue]);


  return (
    <div className="counter">
      <div className="value">
        {count >= targetValue ? `${label}+` : count}
      </div>
    </div>
  );
};

function FindJobsByKeywordHome() {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [categoryValue, setCategoryValue] = useState([]);
  const [industryData, setIndustryData] = useState();

  const newArray = [];
  for (const key in industryData) {
      newArray.push({ "value": key, "label": key });
  }
  console.log(newArray, "industryData")
  

  // Event handler for input change
  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };


  const handleChange = (data) => {
    setCategoryValue([...data.length >=10? data.substring(0, 10) + "..." :data ]);
  };

  // Event handler for search button click
  const handleSearchClick = () => {
    // Check if there is a value in the search input
    if (searchValue.length >= 2) {
      navigate("/job-search", {
        state: {
          category: categoryValue,
          searchValue: searchValue
        }
      })
    }

    // Check if there is a selected value in the dropdown
    if (categoryValue.length > 0) {
      navigate("/job-search", {
        state: {
          category: categoryValue,
          searchValue: searchValue
        },
      })
    }
  };
  const MenuProps = {
    PaperProps: {
    },
  };




  const fetchCompanies = async () => {
    try {
      let response = await getAllCategories();
      let cityData = [];
      if (response?.data?.response?.length > 0) {
        response?.data?.response.forEach((data) => {
          cityData.push({ label: data, value: data});
        });
      }
      setIndustryData(response.data.response);
    } catch (err) {
      console.error(err, "All categories api error");
    }
  };
  useEffect(() => {
    fetchCompanies()
  }, [])

  return (
    <div className="findJobContainer">
      <Box sx={{ flexGrow: 1 }}>
        <div className="findJobContainer">
          <div className="row">
            <div className="col-12 col-lg-7">
              <div>
                <Fade left delay={0} duration={1000}>
                  <div className="findingJobContent">
                    <p className="findingJobHeaderContent">
                      Find world’s <br /> best{" "}
                      <ReactTyped
                        strings={["technical jobs", "non-technical jobs", "internships"]}
                        className="animatedText"
                        backSpeed={50}
                        backDelay={1000}
                        typeSpeed={100}
                        loop
                      />
                    </p>
                    <p className="subText">
                      Discover the jobs that best suit your profile
                    </p>
                    {/* <div className="search-container">
                      <div className="search-icon">
                        <img src={SearchIcon} alt="Search Icon" />
                      </div>
                      <input
                        type="text"
                        name="text"
                        placeholder="Job title"
                        value={searchValue}
                        onChange={handleInputChange}
                        className="search-input"
                      />
                      <div className="filterBox">
                        <MultiSelectDropdown
                          data={industryData}
                          handleSelectChange={handleChange}
                          selectedData={categoryValue}
                          placeholder={
                            <label className="flex">
                              <img src={CategoryIcon} alt="Category Icon" style={{ height: "22px", width: "22px" }} />
                              <label className="mx-2"> All Categories</label>
                            </label>
                          }
                        />
                      </div>
                      <div className="filter-icon"></div>
                      <button
                        type="submit"
                        onClick={handleSearchClick}
                        className="search-button"
                      >
                        Search
                      </button>
                    </div> */}

                    <div className="flex justify-itens-center">
                      <div className="row searchbarstyle">
                        <div className="jobtitlestyle flex col-md-6 col-sm-12 noBorder">
                          <div>
                          <img src={zoomIcon} alt="Search Icon"/>
                        </div>
                          <div>
                          <input
                            type="text"
                            name="text"
                            placeholder="Job title"
                            value={searchValue}
                            onChange={handleInputChange}
                            className="search-inputstyle"
                          />
                         </div>
                        </div>
                        <div className="categorysearchstyle col-md-4 col-sm-12 noBorder">
                          {console.log(categoryValue,"categoryValue")}
                          <MultiSelectDropdown
                            data={newArray}
                            handleSelectChange={handleChange}
                            selectedData={categoryValue}
                            placeholder={
                              <label className="flex">
                                <img src={CategoryIcon} alt="Category Icon"  />
                                <label className="categoryLabel"> All Categories</label>
                              </label>
                            }
                          />
                        </div>
                        <div className="searchbtnStyle1 col-md-2 col-sm-12">
                          <button
                            type="submit"
                            onClick={handleSearchClick}
                            className="search-button"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="searchFooter mt-3 flex">
                      <p className="flex">Popular Search:</p>{" "}
                      <p className="mx-1">Full Stack Developer, Business Analyst </p>
                    </div>
                    <div className="animatedCounter flex">
                      <Counter initialValue={0} targetValue={5000} label="5 K" /><br />
                      <div className="devider mx-3"></div>
                      <Counter initialValue={0} targetValue={35000} label="35 K" /><br />
                    </div>
                    <div className="animatedCounterTitles flex relative bottom-8">
                      <p>Jobs Submitted </p>
                      <p className=" relative left-8">Monthly User</p>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="flex justify-center align-middle">
                <Fade right delay={0} duration={1000}>
                  <div className="findingJobImageContent">
                    <img src={JobSearch} alt="Job Search" style={{ height: "484px", paddingTop: "30px" , paddingBottom:"25px"}} className="relative left-4" />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default FindJobsByKeywordHome;