import React, { useEffect, useState } from "react";
import "./NavigationHeader.scss";
import { useNavigate, useLocation, Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LoginSignUpModal from "../website-signup-home/LoginSignUpModal";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AnimatedBell from "../website-assets/animatedBell.png";
import { NavLink } from "react-router-dom";
import Button from '@mui/material/Button';
import codelogo from '../../../assets/VectorColor.svg'
import markrting from '../../../assets/Vector4.svg'
import customerlogo from '../../../assets/jhklcolor.svg'
import customerlogo1 from '../../../assets/vbnmColor.svg'
import designlogo from '../../../assets/Vector2.svg'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getAllCategories } from "../../../api/api-services";
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import AGLogo from '../website-assets/AGLogo.png'
import Itlogo from '../../../assets/categoryLogos/ItColoredLogo.svg'
import technologylogo from '../../../assets/categoryLogos/TechColoredLogo.svg'
import healthandcarelogo from '../../../assets/categoryLogos/healthCareColoredLogo.svg'
import transportRealestatelogo from '../../../assets/categoryLogos/TransportColoredLogo.svg'
import manifacturelogo from '../../../assets/categoryLogos/ManifactureColoredLogo.svg'
import bfsilogo from '../../../assets/categoryLogos/bfsiColoredLogo.svg'
import bmplogo from '../../../assets/categoryLogos/BpmColoredLogo.svg'
import retaillogo from '../../../assets/categoryLogos/retailColoredLogo.svg'
import professionalServicelogo from '../../../assets/categoryLogos/professionalCOloredLogo.svg'
import medialogo from '../../../assets/categoryLogos/mediaColoredLogo.svg'
import remotelogo from '../../../assets/categoryLogos/remoteColoredLogo.svg'
import mnclogo from '../../../assets/categoryLogos/MNCColoredLogo.svg'
import fresherlogo from '../../../assets/categoryLogos/fresherColorLogo.svg'
import supplyChainlogo from '../../../assets/categoryLogos/supplyColoredLogo.svg'
import analyticslogo from '../../../assets/categoryLogos/AnalyticsColoredLogo.svg'
import internlogo from '../../../assets/categoryLogos/InternshopColoredLogo.svg'
import engineerlogo from '../../../assets/categoryLogos/engineeringColoredLogo.svg'
import productManagelogo from '../../../assets/categoryLogos/professionalCOloredLogo.svg'
import bankinglogo from '../../../assets/categoryLogos/bankingColoredLogo.svg'
import dataSciencelogo from '../../../assets/categoryLogos/dataScienceColoredLogo.svg'
import { Box } from "@mui/system";

function NavigationHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [clickMode, setClickMode] = useState("");
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  let open = Boolean(anchorEl);
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleClick = (event) => {
    console.log(event, "event", open, "open", openMenu, "openMenu")
    setAnchorEl(event.currentTarget);
    if (!anchorEl || !anchorEl.contains(event.relatedTarget)) {
      open = false
    }
  };

  const handleClickMobile = (event) => {
    console.log("mobile click")
    setAnchorEl(event.currentTarget);
  };



  const onCategoriesHover = (event) => {

    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const onMenuHover = () => {
    setOpenMenu(true);
  };

  const onMenuHoverOut = (event) => {
    if (screenWidth <= 768) {
      console.log("coming in")
      setAnchorEl(null);
    }
    // Check if the mouse is not over both the button and the menu
    if (!anchorEl || !anchorEl.contains(event.relatedTarget)) {
      setOpenMenu(false);
    }
  };

  const handleSearchClick = (industry) => {
    console.log("event", industry, "industry")
    // Check if there is a selected value in the dropdown
    if (location.pathname === '/job-search') {
      window.location.reload();
      navigate("/job-search", {
        state: {
          category: [{ label: `${industry}`, value: `${industry}` }],
          searchValue: ""
        }
      }, { replace: true });
    } else {
      navigate("/job-search", {
        state: {
          category: [{ label: `${industry}`, value: `${industry}` }],
          searchValue: ""
        },
      })
    }


  };

  const getIndustries = async () => {
    try {
      const res = await getAllCategories();
      if (res?.data) {
        setDropDownData(res.data.response)
      } else {
        console.log(res, "res")
      }
    } catch (e) {
      console.log("countires error", e);
    }
  };
  useEffect(() => {
    getIndustries()
  }, [])

  const categoryCard = () => {
    const industryLogos = {
      "IT": Itlogo,
      "TECHNOLOGY": technologylogo,
      "HEALTHCARE & LIFESCIENCES": healthandcarelogo,
      "INFRASTRUCTURE, TRANSPORT & REALESTATE": transportRealestatelogo,
      "MANUFACTURING & PRODUCTION": manifacturelogo,
      "BFSI": bfsilogo,
      "BPM": bmplogo,
      "CONSUMER, RETAIL & HOSPITALITY": retaillogo,
      "PROFESSIONALSERVICES": professionalServicelogo,
      "MEDIA, ENTERTAINMENT & TELECOM": medialogo,
      "ENTERTAINMENT & TELECOM": medialogo,
      "REMOTE": remotelogo,
      "MNC": mnclogo,
      "FRESHER": fresherlogo,
      "SUPPLYCHAIN": supplyChainlogo,
      "ANALYTICS": analyticslogo,
      "INTERNSHIP": internlogo,
      "ENGINEERING": engineerlogo,
      "PROJECT MANAGEMENT": productManagelogo,
      "BANKING & FINANCE": bankinglogo,
      "HR": fresherlogo,
      "SALES":Itlogo,
      "DATASCIENCE": dataSciencelogo,
  };
  const selectLogo = (industry) => industryLogos[industry.toUpperCase()] || Itlogo;
    const newArray = [];
    const icons = [codelogo, markrting, customerlogo]
    for (const key in dropDownData) {
      if (dropDownData.hasOwnProperty(key)) {
        newArray.push({
          "industry": key,
          "count": dropDownData[key],
        });

      }
    }
    let num = screenWidth <= 768 ? 3 : 6;
    return newArray.slice(0, num).map((element, index) => {
      const containerId = index % 2 === 0 ? "two" : "one";
      function formatString(inputString) {
        let mod = inputString.replace(/_/g, ' ').replace(/\bAND\b/g, '&');
        return mod.length >= 5 ? mod.substring(0, 10) + "..." : mod;
      }

      function toCamelCase(inputString) {
        return inputString.replace(/\w\S*/g, (word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
      }
      console.log(toCamelCase(element.industry), "toCamelCase(element.industry)")
      if (screenWidth <= 768) {
        return (
          <div >
            <div id="one" className="flex">
              <span className='mIconbg1'><img className="ingALigenment" src={selectLogo(element.industry)}></img></span>
              <MenuItem onClick={(e) => {
                console.log(e, "event")
                handleSearchClick(element.industry)
              }} className="mItem">
                <div title={element.industry}>
                  <span className="menueiten">{toCamelCase(formatString(element.industry))}</span>
                  <p className="MsubCount">{element.count} Jobs</p>
                </div>
              </MenuItem>
            </div>
          </div>
        )
      } else return (
        <>
          {index % 2 === 0 ?
            <div style={{ paddingLeft: "8px" }} className="evenDiv col-6">
              <div id="one" className="flex">
                <span className='mIconbg1'><img className="ingALigenment" src={selectLogo(element.industry)}></img></span>
                <MenuItem onClick={(e) => {
                  console.log(e, "event")
                  handleSearchClick(element.industry)
                }} className="mItem">
                  <div title={element.industry}>
                    <span className="menueiten">{toCamelCase(formatString(element.industry))}</span>
                    <p className="MsubCount">{element.count} Jobs</p>
                  </div>
                </MenuItem>
              </div>
            </div>
            :
            <div style={{ paddingLeft: "0px" }} className="oddDiv col-6">
              <div id="two" className="flex">
                <span className='mIconbg1'><img className="ingALigenment" src={selectLogo(element.industry)}></img></span>
                <MenuItem onClick={(e) => {
                  console.log(e, "event")
                  handleSearchClick(element.industry)
                }} className="mItem">
                  <div title={element.industry}>
                    <span className="menueiten">{toCamelCase(formatString(element.industry))}</span>
                    <p className="MsubCount">{element.count} Jobs</p>
                  </div>
                </MenuItem>
              </div>
            </div>
          }
        </>
      )
    })
  }

  return (
    <div className="accGeoNavbar">
      <div className="container-fluid">
        <div className="row flex blackBar">
          {/* <div className="col-md-6 d-flex justify-content-center align-items-center ml-20">
          <div>
            <img src={AnimatedBell} style={{ height: "25px" }} alt="Bell" />
          </div>{" "}
          <div className="mx-1 subScribeText">
            Subscribe for job alerts by email!
          </div>
        </div> */}
          <div className="col-md-12 blackBarContent d-md-flex flex justify-content-end   md:gap-12  lg:gap-12  xl:gap-12" style={{ width: "95.7%" }}>
            <div className="p-1 cellDiv">
              <PhoneEnabledOutlinedIcon style={{ height: "14px", width: "14px" }} />
              <span className="mr-5 mx-2">(+91) 8296776506</span>
            </div>
            <div className="p-1 emailDiv ms-3">
              <EmailOutlinedIcon style={{ height: "14px", width: "14px" }} />
              <span className="mr-5 mx-2">{`info@acrossgeo.com`}</span>
            </div>
          </div>
        </div>
      </div>

      <nav className="">
        {/* 1st logo part  */}
        <div
          className="logo cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          <p className="navBarTitle">
            <img src={AGLogo} />
            {/* <span style={{ color: "#008f1b" }}>A</span>CROSS
            <span style={{ color: "#008f1b" }}>G</span>EO */}
          </p>

        </div>

        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons && !isOpenLoginModal ? "menu-link mobile-menu-link" : "menu-link"
          }
        >
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            {(screenWidth < 800) ?
              <>
                {console.log("coming in true")}
                <li className="categoryDropdown">
                  <div onMouseOver={() => {
                    document.getElementById('icon').classList.add('hover-color');
                  }}>
                    <Button
                      style={{ color: "#000000", font: "normal normal normal 16px Inter", textTransform: "none", padding: "0px" }}
                      id="basic-button"
                      className="btnafter"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClickMobile}
                    >
                      <span className="green-hover">Categories
                        <KeyboardArrowDownIcon id='icon' fontSize="18px" /></span>
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      className="dropdown"
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <div>
                        <p className="menueHeadMobile">Job Categories</p>
                      </div>
                      <div className="">
                        {categoryCard()}
                      </div>
                      {Object.keys(dropDownData).length &&
                        <div style={{ height: "31px" }}>
                          <span onClick={() => { navigate('/categories') }} className="viewAll1">View all Categories</span>
                        </div>
                      }
                    </Menu>
                  </div>
                  {/* <NavLink to="/categories">Categories</NavLink>
            <KeyboardArrowDownIcon className=" relative " fontSize="18px" /> */}
                </li>
              </>
              :
              <>
                {console.log("comming in false")}
                <li
                  className="categoryDropdown">
                  <div onMouseOver={() => {
                    document.getElementById('icon').classList.add('hover-color');
                  }}>
                    <Button
                      style={{ color: "#000000", font: "normal normal normal 16px Inter", textTransform: "none", padding: "0px" }}
                      id="basic-button"
                      className="btnafter"
                      aria-controls={openMenu ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? 'true' : undefined}
                      onMouseOver={onCategoriesHover}
                      onMouseOut={onMenuHoverOut}
                      onClick={(event) => {
                        if (screenWidth < 768) {
                          handleClick(event)
                        }
                      }}
                    >
                      <span className="green-hover">Categories
                        <KeyboardArrowDownIcon id='icon' fontSize="18px" /></span>
                    </Button>
                    <Popper
                      style={{ borderRadius: "10px", padding: "5px", width: "374px" }}
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      disableScrollLock={true}
                      onClose={onMenuHoverOut}
                      onMouseOut={onMenuHoverOut}
                      onMouseOver={onMenuHover}
                      className="dropdown"
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <Paper
                        style={{ left: "21% !important" }}>
                        <div>
                          <div>
                            <p className="menueHead">Job Categories</p>
                          </div >
                          <div className="w-96 flex justify-content-center aligen-item-center">
                            <div className="row">
                              {categoryCard()}
                            </div>
                          </div>
                          {Object.keys(dropDownData).length &&
                            <div style={{ height: "31px" }}>
                              <span onClick={() => { navigate('/categories') }} className="viewAll1">View all Categories</span>
                            </div>
                          }
                        </div>
                      </Paper>
                    </Popper>
                  </div>
                </li>
              </>
            }

            <li>
              <NavLink to="/job-search">Jobs</NavLink>
            </li>
            {/* <li>
              <NavLink to="/">Candidates</NavLink>
            </li> */}
            <li>
              <NavLink to="/about-us">About Us</NavLink>
            </li>
            <li>
              <NavLink to="/contactUs">Contact Us</NavLink>
            </li>

            <li className=" navBtns ">
              <li className="mr-2 mx-3 loginBtn cursor-pointer"
                onClick={() => {
                  setIsOpenLoginModal(true);
                  setClickMode("logIn");

                  const navElement = document.querySelector("nav");
                  if (navElement) {
                    navElement.style.zIndex = "0"; // Change this value based on your requirements
                  }
                }}>

                <label
                  htmlFor="close-btn"
                  className="cursor-pointer close-btnOne"
                >
                  Login
                </label>

              </li>
              <li>
                <button
                  onClick={() => {
                    setIsOpenLoginModal(true);
                    setClickMode("jobPost");
                    localStorage.setItem("loginModal", true)
                    const navElement = document.querySelector("nav");
                    if (navElement) {
                      navElement.style.zIndex = "0"; // Change this value based on your requirements
                    }
                  }}
                  className="postJobBtn"
                >
                  <label
                    htmlFor="close-btn"
                    className="cursor-pointer close-btnOne"
                  >
                    Post a Job
                  </label>
                </button>
              </li>
            </li>
          </ul>
        </div>

        {/* 3rd social media links */}
        <div className="social-media ">
          {/* hamburget menu start  */}
          <div className="hamburger-menu">
            <label
              htmlFor="menu-btn"
              className=" menu-btn"
              onClick={() => {
                setShowMediaIcons(!showMediaIcons);
                const navElement = document.querySelector("nav");
                if (navElement) {
                  navElement.style.zIndex = "1"; // Change this value based on your requirements
                }
              }}
            >
              <i className="fas fa-bars"></i>
            </label>
          </div>
        </div>
      </nav>

      {isOpenLoginModal && (
        <LoginSignUpModal
          clickMode={clickMode}
          setIsOpenLoginModal={setIsOpenLoginModal}
          setShowMediaIcons={setShowMediaIcons}
        />
      )}
    </div>
  );
}

export default NavigationHeader;
