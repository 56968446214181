import React from "react";

import { ReactComponent as LocationIcon } from "../../../assets/location.svg";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { useNavigate } from "react-router-dom";
import AdminContextMenuModal from "../../admin/AdminContextMenuModal";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import './BdJobCard.scss'
import { ReactComponent as EditIcon } from "../../../assets/editBlack.svg";


const BdJobCard = (props) => {
  
  const contextMenuData = [
    {
      option: "Details",
    },
  ];

  let user = JSON.parse(localStorage.getItem("across-geo-user"));
  let showEdit = (props.job.createdBy  === user.uid)
  const navigate = useNavigate();
  const handleShareProfile = () => {
    navigate("/bd/job-post/share-profile", {
      state: { requestedProfile: props.job },
    });
  };
  function contextMenuFunction(event, resObj) {
    props.handelMoreBtn(resObj, event.target.textContent);
    console.log(resObj, event.target.textContent);
  }
  console.log(props,"props from bd")
  return (
    <>
      <div
        onClick={() => {
          if (props.job.status !== "CANCEL") {
            handleShareProfile();
          }
        }}
        className={props.job.status === "CANCEL"?"job-card cursor-pointer  border border-gray-400 rounded-lg shadow-md border-opacity-0 transform opacity-50":"job-card cursor-pointer  border border-gray-400 rounded-lg shadow-md border-opacity-0 transform"}

        key={props.job.name + props.index}
      >
        <div className="leftCardItem grid grid-cols-3 gap-4 ">
          <div className="col-span-12 leftCardItemContent">
            <div className="label">Company Name</div>
            <div className="value">{props.job.companyName}</div>
          </div>
          <div className="col-span-12">
            <div className="label">Client Name</div>
            <div className="value">{props.job.clientName}</div>
          </div>
          {/* <div className="col-span-12 flex">
                      <div className="label"><RoomOutlinedIcon/></div>
                      <div className="value  mx-2">{props.job.location}</div>
                    </div> */}
        </div>
        <div className="job-placement grid grid-cols-9">
          <div className="col-span-3 pb-4 job-label pl-2">
            {props.job.jobHeadlines ? props.job.jobHeadlines : "--"}
          </div>
          <div className="col-span-2 pb-4 pl-2">
            <span className="job-label">Role: </span>
            <span>{props.job.roles ? props.job.roles : "--"}</span>
          </div>
          <div className="col-span-2 pb-4">
            <span>Openings: </span>
            <span className="job-label mr-2">
              {props.job.vacancyCount ? props.job.vacancyCount : "--"}
            </span>
          </div>
          <div className="col-span-2 pb-4 flex">
            <div>
              <span>Profiles shared: </span>
              <span className="job-label">{props.job.profilesShared}</span>
            </div>
            <div style={{ alignItems: "center", height: "26px" }} className="action-btns flex ">
              <span className="">
                <button className={"block"}>
                  <AdminContextMenuModal
                    contextMenuData={contextMenuData}
                    page={"jobCard"}
                    func={(event) => {
                      event.stopPropagation();
                      contextMenuFunction(event, props.job);
                    }}
                    // disable={props.job.status === "CANCEL"}
                    onMouseOutz
                  />
                </button>
              </span>
              {console.log(props.from ,"from:====")}
              {showEdit &&
              <Tooltip
                title={
                  props.job.profilesShared
                    ? "Job post cannot be edited as the Candidate profile already shared "
                    : props.job.status === "CANCEL"
                      ? "Cancelled job post cannot be edited"
                      : "Edit"
                }
                placement="top"
              >
                <IconButton
                    onClick={(e) => {
                      if (props.from === "sharedProfile") {
                        if (props.job.status !== "CANCEL") {
                          props.setEditJobPost(true)
                        }
                      }
                    e.stopPropagation();
                    if (!props.job.profilesShared && props.from !== "sharedProfile" && props.job.status !== "CANCEL") {
                      props.setEdirJobPost(true)
                      props.setSelectedJob(props.job)
                    }

                  }}
                  // disabled={props.job.profilesShared}
                  sx={
                    false
                      ? {
                        "&:hover": {
                          opacity: "0.4",
                          backgroundColor: "#FAF9F6",
                          boxShadow:
                            " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        },
                      }
                      : {
                        "&:hover": {
                          backgroundColor: "#FAF9F6",
                          boxShadow:
                            " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        },
                      }
                  }
                >
                  <button
                    className={`block ${props.job.profilesShared ? "opacity-50" : ""
                      }`}
                    disabled={props.job.profilesShared}
                  >
                    <EditIcon height="15px" width="15px" />
                  </button>
                </IconButton>
              </Tooltip>
               }

            </div>
          </div>
          <div className="col-span-3 pl-2 datatrimmer">
            <div className="job-label pb-2 ">Job description </div>
            <span title={props.job.jobDescription}>
              {props.job.jobDescription ? props.job.jobDescription : "--"}
            </span>
          </div>
          <div className="col-span-2 pl-2">
            <div className="job-label pb-2">Key Skills </div>
            <div className="skills-pill-Container">
              {props.job?.keySkills?.map((skill) => {
                return (
                  <div
                    key={skill}
                    className="skills-pill datatrimmer"
                    title={skill}
                  >
                    {console.log(props.job)}
                    {skill}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-span-2">
            <div className="job-label pb-2" title={props.job.location}>
              Work Location{" "}
            </div>
            <div className="location-with-action">
              <span className="flex items-center " title={props.job?.location}>
                <LocationIcon height="15px" width="15px" />{" "}
                {/* {props.job.location?props.job.location:"--"} */}
                {props.job?.location
                  ? props.job.location.length > 1
                    ? props.job.location.slice(0, 1).join(", ") + "..."
                    : props.job.location.join(", ")
                  : "--"}
              </span>
            </div>
          </div>
          {/* {props.showShareBtn === "true" && (
            <div className="col-span-2 shareProfileBtn my-2">
              <div className="label"></div>
              <button
                className=""
               
              >
                +Share Profile
              </button>
            </div>
          )} */}
          <h5  className= {props.from !== "sharedProfile" ? "col-span-8 lastDate" : "col-span-2 lastDateShared"} >
            Last date for sharing the profile : {props.job.dateForSharingProfiles}
          </h5>
        </div>
      </div>
    </>


  );
};

export default BdJobCard;
