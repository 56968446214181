import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { Breadcrumbs as MUIBreadcrumbs } from "@material-ui/core";
import {
  withRouter,
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import "./BreadCrumbs.scss";


const BreadCrumbs = (props) => {
  const navigate = useNavigate();
  let location = useLocation();
  const { pathname } = location;
  const pathnames = pathname.split("/").filter((x) => x);

  const handelNavigation = (namedata, navigationPath) => {
    navigate(navigationPath);
  };

  return (
    <MUIBreadcrumbs
      className="q-spiders-breadcrumbs flex items-center"
      separator="›"
      aria-label="breadcrumb"
    >
      {/* {pathnames.length > 0 ? <Typography /> : <Link /> } the same as next example */}
      <HomeIcon onClick={() => {
        let tempURL = JSON.parse(localStorage.getItem("across-geo-user")).role.toLowerCase();
        console.log("local",tempURL);
        navigate("/" + tempURL, { replace: true });
      }}
      />
      {pathnames.map((namedata, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLastLink = index === pathnames.length - 1;
        return isLastLink ? (
          <Typography className="selectedLink">{namedata}</Typography>
        ) : (
          <div
            to="#"
            className="cursor-pointer breadcrumbsclass"
            key={namedata}
            onClick={(e) => {
              handelNavigation(namedata, routeTo);
            }}
          >
            {namedata}
          </div>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default BreadCrumbs;
