import React from 'react'
import { useState ,useEffect} from "react";
import Modal from "react-modal";
import CandidatesDetailsModal from "../../common/candidate-details/CandidatesDetailsModal";
import ScheduleInterview from "./ScheduleInterview";
import "./InterviewModals.scss";
import UpdateInterview from "./UpdateInterview";
import InterviewCoOrdinateDetails from "./InterviewCoOrdinateDetails";
import {getCandidateDetailsReq} from "../../../api/api-services"
import VideoPreviewDetailsModal from './VideoPreviewDetailsModal';
import CancelIcon from '@mui/icons-material/Cancel';

function CandidateDetailsVideoModal(props) {
  console.log(props)
  const [openModal, setOpenModal] = useState(true);
  let [candidateDetailsData, setCandidateDetailsData] = useState({});

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "60%",
      transform: "translate(-50%, -50%)",
      width: "82%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
      outline: "none",
      //   borderTopLeftRadius: " 12px",
    },
  };
  const getCandidateDetails = async () => {
    
    try {
      const response = await getCandidateDetailsReq(props?.candidate?.id);
      console.log("candidate Details Data", response);
      if (response.data) {
        setCandidateDetailsData({ ...response.data.response });
       
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  
  useEffect(() => {
    getCandidateDetails();
  }, []);
  let closeInterviewModal = () => {
    props.gettableData(props.selectedTab, 0);
    setOpenModal(false);
    props.closeModal(false);
  };
  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      style={customStylesPage}
      className="p-px"
    >
      <div className="flex items-center rounded-tl-xl Interview-modal-heading items-center">
        <div className="header">Candidate Details</div>
        <button
          onClick={() => {
            setOpenModal(false);
            props.closeModal(false);
          }}
          type="button"
          className="modal-close-btn float-left ml-auto"
        >
          <CancelIcon />
        </button>
      </div>
      <div className="flex Interview-modal-body">
        <div className={ props.showBothVideo_Details === "Edit video"?"modal-candidate-details-container":"modal-candidate-details-container_fullSection"}>
          <InterviewCoOrdinateDetails
            candidateDetailsData={candidateDetailsData}
            candidate={props.candidate}
          />
          
        </div>
        {
          props.showBothVideo_Details === "Edit video" &&
        (  <div className="candidate-form">
        <VideoPreviewDetailsModal candidateDetailsData={candidateDetailsData}  candidate={props.candidate}/>

        
        </div>)
        }
       
      </div>
    </Modal>
  );
}

export default CandidateDetailsVideoModal;
