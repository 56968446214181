import React from "react";
import "./commonloader.scss";
export const CommonLoader = () => {
  return (
    <>
      <div className="base-h flex items-center justify-center">
        <div className="text-center font-bold align-middle">
           {/* <div class="loader"></div> */}
           <span class="spinner spinner-blue"></span>
        </div>
      </div>
    </>
  );
};
export default CommonLoader;
