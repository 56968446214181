import { useState, useEffect, useRef, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import "./AdminPageLeftMenu.scss";
import { useNavigate, useLocation } from "react-router-dom";
// import CasesBox from "../../assets/cases_box.svg";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BugReportIcon from "@mui/icons-material/BugReport";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { ReactComponent as ProgressIcon } from "../../assets/progress.svg";
import  ConfigIcon from "../../assets/configIcon.svg";
import SettingsIcon from '@mui/icons-material/Settings';
import Groups3Icon from '@mui/icons-material/Groups3';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import Diversity2Icon from '@mui/icons-material/Diversity2';


import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { Tooltip } from "@material-ui/core";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRight: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  zIndex:0,
  top: "61px",
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "55px",
  borderRight: "none",
  [theme.breakpoints.up("sm")]: {
    // width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
function AdminPageLeftMenu(props) {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  
  const [open, setOpen] = React.useState(true);
  const [configMenu, setConfigMenu] = useState(false);
  const [inconfigMenu, setInconfigMenu] = useState(false);
  let getSideMenuIcon = (type) => {
    if (type === "Candidates") {
      return <Diversity2Icon />;
    } else if (type === "Job Posts") {
      return <Diversity3Icon/> 
    }  else if (type === "Company") {
      return <Groups3Icon/> 
    } else if (type === "Users") {
      return <ProgressIcon/> 
    }else if (type === "Configuration") {
      return <SettingsIcon/>
    }
  };
  const [leftMenuItems, setLeftMenuItems] = useState([
    {
      name: "Job Posts",
      path: "/admin/jobposts",
      icon: getSideMenuIcon("Job Posts"),
    },
    {
      name: "Company",
      path: "/admin/company",
      icon: getSideMenuIcon("Company"),
    },
    
    {
      name: "Candidates",
      path: "/admin/candidates",
      icon: getSideMenuIcon("Candidates"),
    },
    {
      name: "Users",
      path: "/admin/users",
      icon: getSideMenuIcon("Users"),
    },
    {
      name: "Configuration",
      path: "/admin/configuration",
      icon: getSideMenuIcon("Configuration"),
    },
  ]);


  useEffect(() => {
    if (location.pathname.includes("/project/config")) {
      setInconfigMenu(true);
    } else {
      if (configMenu) {
        setConfigMenu(false);
      }
      setInconfigMenu(false);
    }
  }, [location.pathname]);

  

  let menuRef = useRef();
  useEffect(() => {
    let handeler = (event) => {
      if (menuRef.current && !menuRef?.current?.contains(event.target)) {
        setShowSideMenu(false);
        setConfigMenu(false);
        // setInconfigMenu(false);
        setShowConfigSubMenu(false);
      }
    };
    document.addEventListener("mousedown", handeler);
    return () => {
      document.removeEventListener("mouseleave", handeler);
    };
  }, []);


  const [showConfigSubMenu, setShowConfigSubMenu] = useState(false);

  const handleMouseLeaveConfig_sub = () => {
    setShowConfigSubMenu(false);
  };
  return (
    <div ref={menuRef}>
      <Box sx={{ display: "flex" }}>
        <Drawer variant="permanent" open={open}>
          <List>
            <div className="left-menu">
              {leftMenuItems.map((data) => {
                return (
                  <div className="my-6  flex allProjectDrawer">
                    <NavLink
                       className={({ isActive }) =>
    (isActive || (location.pathname === "/admin" && data.name === "Job Posts")) && !configMenu && open
      ? "active-drawer-close left-menu-items-select item-hover-effect -ml-1  focus:outline-none"
      : (isActive || (location.pathname === "/admin" && data.name === "Job Posts")) && !configMenu && !open
      ? "text-blue-700 -ml-1 active-drawer-close2 item-hover-effect  focus:outline-none"
      : "flex ml-3 left-menu-items-unselect  item-hover-effect focus:outline-none"
}
                      to={data.path}
                    >
                      {data.icon}
                      <Tooltip title={data.name}>
                        <div className="flex overview_icon datatrimmer">
                          <span className="ml-2">{open ? data.name : ""}</span>
                        </div>
                      </Tooltip>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </List>
          <Divider />
        </Drawer>
      </Box>
    </div>
  );
}

export default AdminPageLeftMenu;
