import React, { useEffect } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Switch, Tooltip } from "@mui/material";
import { ReactComponent as FilterIcon } from "../../assets/filterIcon.svg";
import { convertToDisplayName } from "../../util/common-utils";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function CandidateColumnOption(props) {
  const [pageIndexValue, setPageIndex] = React.useState(0);
  const [pageIndex, setPage] = React.useState(0);
  const [margin, setMargin] = React.useState(0);

  useEffect(() => {
    if (pageIndexValue > 550) {
      setPage(-250);
      setMargin(150);
    } else {
      setPage(100);
      setMargin();
    }
  }, [pageIndexValue]);
  return (
    <Menu as="div" className="relative inline-block text-left ">
      {({ open }) => (
        <>
          <div data-title="More" id="more">
            <Tooltip title="Hide & Show Columns" placement="top">
              <Menu.Button
                //  className="bg-gray-100 rounded-full items-center text-gray-400 hover:text-gray-600 focus:outline-none w-5 h-5 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100  focus:ring-indigo-500"
                onMouseOver={(e) => {
                  let mouse = e.clientY;
                  setPageIndex(mouse);
                }}
                className={`${props.btnClass ? props.btnClass : "mt-1"}`}
              >
                <span className="sr-only ">Open options</span>
                {/* <DotsVerticalIcon className="h-5 w-5 more_icon " aria-hidden="true" /> */}
                HideColumns
              </Menu.Button>
            </Tooltip>
          </div>
          <Transition
            // show={true}
            static
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              style={{
                top: `${pageIndex}%`,
                marginRight: `${margin}%`,
                width: "220px",
                maxHeight: "300px",
                overflowY: "auto",
              }}
              className="origin-top-right absolute right-0 mt-2 w-44 rounded-md  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none  z-10 w-48"
            >
              <div className="py-1" style={{ fontFamily: "Poppins-Medium" }}>
                {props.contextMenuData?.map((data, index) => {
                  return (
                    data.option !== "id" && data.option !== "rating" && data.option !== "shareable" && (
                      <Menu key={`context-menu-item-${index}`}>
                        {({ active }) => (
                          <div
                            // onClick={(e) => {
                            //   props.func(e, data.option);
                            // }}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm",
                              "hover:text-blue-700"
                            )}
                          >
                            {/* <img src={data.icon} height="20px" width="20px" className="float-left mr-3"></img> */}

                            <label className="cursor-pointer hide-menu-label flex items-center ">
                              <span className="field-label">{convertToDisplayName(data.option)}</span>
                              <Tooltip title={ props.hiddenColumnsData.includes(
                                    data.option
                                  ) ?"Click to Show":"Click to Hide"} placement="top">
                                <Switch
                                  defaultChecked={props.hiddenColumnsData.includes(
                                    data.option
                                  )}
                                  className="float-right"
                                  value={data.option}
                                  onChange={(e) => {
                                    props.func(e, data.option);
                                  }}
                                />
                              </Tooltip>
                            </label>
                          </div>
                        )}
                      </Menu>
                    )
                  );
                })}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

export default CandidateColumnOption;
