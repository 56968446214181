import React, { useState } from "react";
import { SelectDropdown } from "../../../landing-page/common/select-dropdown";
import cx from "classnames";
import { TextField } from "@mui/material";
import Select from "react-select";

const DiplomaPucIti = (props) => {
  // const [diplomaBoardOther, setDiplomaBoardOther] = useState(false);
  // const [itiBoardOther, setItiBoardOther] = useState(false);
  const handelCheckbox = (e) => {
    console.log(e.target.value)
    console.log(props.diplomoForm,"props.setDiplomoForm")
    if (e.target.checked) {
      props.handelPreDegreeCheckbox(
        e.target.checked,
        [...props.selectedPreDegree, e.target.value],
        e.target.value
      );
      props.setSelectedPreDegree(
        [...props.selectedPreDegree, e.target.value],
        e.target.value
      );
    } else {
      let data = [...props.selectedPreDegree];
      data.splice(data.indexOf(e.target.value), 1);
      props.handelPreDegreeCheckbox(
        e.target.checked,
        [...data],
        e.target.value
      );
      props.setSelectedPreDegree([...data]);
      if (props.diplomoForm && e.target.value === "iti") {
        props.selectedDropdownData.itiStream = props.selectedDropdownData.itiStream;
        props.selectedDropdownData.itiboardName = props.selectedDropdownData.itiboardName;
        props.selectedDropdownData.itiCollegeName = props.selectedDropdownData.itiCollegeName;
        props.selectedDropdownData.itiGradeType = props.selectedDropdownData.itiGradeType;
        props.selectedDropdownData.itiCourseType = props.selectedDropdownData.itiCourseType;
      } else {
        props.selectedDropdownData.itiStream = [];
        props.selectedDropdownData.itiboardName = [];
        props.selectedDropdownData.itiCollegeName = [];
        props.selectedDropdownData.itiGradeType = [];
        props.selectedDropdownData.itiCourseType = [];
      }
      if (props.diplomoForm && e.target.value === "twelfth") {
        props.selectedDropdownData.pucGradeType = props.selectedDropdownData.pucGradeType; 
      } else {
        props.selectedDropdownData.pucGradeType = [];
      }
      if (props.diplomoForm && e.target.value === "diploma") {
        props.selectedDropdownData.dpboardName = props.selectedDropdownData.dpboardName;
        props.selectedDropdownData.dpCollegeName = props.selectedDropdownData.dpCollegeName;
        props.selectedDropdownData.dpStream = props.selectedDropdownData.dpStream;
        props.selectedDropdownData.dpGradeType = props.selectedDropdownData.dpGradeType;
        props.selectedDropdownData.dpCourseType = props.selectedDropdownData.dpCourseType;
      } else {
        props.selectedDropdownData.dpboardName = [];
        props.selectedDropdownData.dpCollegeName = [];
        props.selectedDropdownData.dpStream = [];
        props.selectedDropdownData.dpGradeType = [];
        props.selectedDropdownData.dpCourseType = [];
        
      }
    }
  };
  return (
    <div className="pre-degree col-span-3 grid grid-cols-3">
      <div className="col-span-3 heading">Diploma . 12th {`(PUC)`} . ITI</div>
      <div className="flex preDegree-checkbox col-span-3">
        {" "}
        <input
          type="checkbox"
          id="diploma"
          name="diploma"
          value="diploma"
          onClick={(e) => {
            handelCheckbox(e);
          }}
          checked={props.selectedPreDegree.includes("diploma")}
        />
        <label for="diploma" className="each-checkbox">
          {" "}
          Diploma
        </label>
        <input
          type="checkbox"
          checked={props.selectedPreDegree.includes("twelfth")}
          id="twelfth"
          name="twelfth"
          value="twelfth"
          onClick={(e) => {
            handelCheckbox(e);
          }}
        />
        <label for="puc" className="each-checkbox">
          {" "}
          PUC
        </label>
        <input
          type="checkbox"
          checked={props.selectedPreDegree.includes("iti")}
          id="iti"
          name="iti"
          value="iti"
          onClick={(e) => {
            handelCheckbox(e);
          }}
        />
        <label for="iti" className="each-checkbox">
          {" "}
          ITI
        </label>{" "}
        {props.formik.errors?.preDegree && props.formik.touched?.preDegree ? (
          <div className="text-red-500 error_text md:mt-1">
            {props.formik.errors.preDegree}
          </div>
        ) : null}
      </div>
      <div className="course-container col-span-3">
        {props.selectedPreDegree.includes("diploma") && (
          <div className="pre-degree-course col-span-3 grid grid-cols-3">
            <div className="col-span-3 heading">Diploma</div>
            <div className="col-span-3 body grid grid-cols-3">
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Board</span>
                </div>
                <div className="input-ele">
                  <Select
                    dpName="dpboardName"
                    options={props.dropDownData.dpboardName}
                    onChange={(data) => {
                      props.handleDropdownChange([data], "dpboardName", [
                        "dpCollegeName",
                      ]);
                      if (data.value === "Others") {
                        // setDiplomaBoardOther(true);
                        props.diplomaBoardChange(data);
                      }
                      // else {
                      //   setDiplomaBoardOther(false);
                      // }
                    }}
                    placeholder="Select the board name"
                    onMenuOpen={props.diplomaBoardDataHandler}
                    // value={props.selectedDropdownData.dpboardName}
                    value={Array.isArray(props.selectedDropdownData.dpboardName) ? props.selectedDropdownData.dpboardName[0]?.value == ""? null : props.selectedDropdownData.dpboardName : null }
                    isSearchable={true}
                  />
                </div>
                {props.formik.errors?.dpboardName &&
                props.formik.touched?.dpboardName ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.dpboardName}
                  </div>
                ) : null}
              </div>
              {props.formik.values.dpboardName === "Others" && (
                <div className="mt-2 individual-field">
                  <div className="mb-2 label">
                    <span className="create-labels">Board Name</span>
                  </div>
                  <div className="popins-medium input-ele">
                    <TextField
                      error={
                        props.formik.errors.dpBoard &&
                        props.formik.touched.dpBoard
                      }
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={() => {}}
                      type="text"
                      name="dpBoard"
                      id="dpBoard"
                      inputProps={{
                        className: cx(
                          `!text-xs open-sans-regular boxedInput popins-medium`
                        ),
                        style: {
                          padding: "9.5px 14px",
                          height: "0.43em !important",
                          fontSize: "12px",
                          fontFamily: "Poppins-Medium",
                          color: "#525252",
                        },
                      }}
                      autoComplete="off"
                      placeholder="Enter the board name"
                      onBlur={props.formik.handleBlur}
                      onChange={props.formik.handleChange}
                      value={props.formik.values.dpBoard}
                    />
                  </div>
                  {props.formik.errors.dpBoard &&
                  props.formik.touched.dpBoard ? (
                    <div className="text-red-500 error_text md:mt-1">
                      {props.formik.errors.dpBoard}
                    </div>
                  ) : null}
                </div>
              )}
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">College Name</span>
                </div>
                {props.formik.values.dpboardName === "Others" ? (
                  <div className="popins-medium input-ele">
                    <TextField
                      error={
                        props.formik.errors.dpCollegeName &&
                        props.formik.touched.dpCollegeName
                      }
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={() => {}}
                      type="text"
                      name="dpCollegeName"
                      id="dpCollegeName"
                      inputProps={{
                        className: cx(
                          `!text-xs open-sans-regular boxedInput popins-medium`
                        ),
                        style: {
                          padding: "9.5px 14px",
                          height: "0.43em !important",
                          fontSize: "12px",
                          fontFamily: "Poppins-Medium",
                          color: "#525252",
                        },
                      }}
                      autoComplete="off"
                      placeholder="Enter the college name"
                      onBlur={props.formik.handleBlur}
                      onChange={props.formik.handleChange}
                      value={props.formik.values.dpCollegeName ?props.formik.values.dpCollegeName : "" }
                    />
                  </div>
                ) : (
                  <div className="input-ele">
                    <Select
                      dpName="dpCollegeName"
                      options={props.dropDownData.dpCollegeName}
                      onChange={(data) => {
                        props.handleDropdownChange([data], "dpCollegeName");
                      }}
                      placeholder="Select the college name"
                      onMenuOpen={props.diplomaCollegeDataHandler}
                        // value={props.selectedDropdownData.dpCollegeName}
                      value={Array.isArray(props.selectedDropdownData.dpCollegeName) ? props.selectedDropdownData.dpCollegeName[0]?.value == ""? null : props.selectedDropdownData.dpCollegeName : null }
                      isSearchable={true}
                    />
                  </div>
                )}
                {props.formik.errors?.dpCollegeName &&
                props.formik.touched?.dpCollegeName ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.dpCollegeName}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Specialization</span>
                </div>
                <div className="input-ele">
                  <Select
                    dpName="dpStream"
                    options={props.dropDownData.dpStream}
                    onChange={(data) => {
                      props.handleDropdownChange([data], "dpStream");
                    }}
                    placeholder="Select the stream"
                    onMenuOpen={props.diplomaStreamDataHandler}
                    // value={props.selectedDropdownData.dpStream}
                    value={Array.isArray(props.selectedDropdownData.dpStream) ? props.selectedDropdownData.dpStream[0]?.value == ""? null : props.selectedDropdownData.dpStream : null }
                    isSearchable={true}
                  />
                </div>
                {props.formik.errors?.dpStream &&
                props.formik.touched?.dpStream ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.dpStream}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Percentage / CGPA</span>
                </div>
                <div className="popins-medium input-ele grid grid-cols-4">
                  <Select
                    className="col-span-2"
                    options={props.dropDownData.dpGradeType}
                    onChange={(data) => {
                      props.handleDropdownChange([data], "dpGradeType");
                    }}
                    placeholder="Select"
                    value={props.selectedDropdownData.dpGradeType ? props.selectedDropdownData.dpGradeType :""}
                  />
                  <TextField
                    className="col-span-2"
                    error={
                      props.formik.errors.dpGrade &&
                      props.formik.touched.dpGrade
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => {}}
                    type="text"
                    name="dpGrade"
                    id="dpGrade"
                    inputProps={{
                      className: cx(
                        `!text-xs open-sans-regular boxedInput popins-medium`
                      ),
                      style: {
                        padding: "11px 14px",
                        height: "0.43em !important",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        color: "#525252",
                      },
                    }}
                    autoComplete="off"
                    placeholder={`Enter your ${props?.selectedDropdownData?.dpGradeType ? props?.selectedDropdownData?.dpGradeType[0]?.value : "Percentage"}`}
                    onBlur={props.formik.handleBlur}
                    onChange={props.formik.handleChange}
                    value={props.formik.values.dpGrade}
                    onKeyPress={(e) => {
                      // Check if the entered character is a number or a dot (for decimal input)
                      const validInput = /^[0-9.]$/.test(e.key);

                      if (!validInput) {
                        e.preventDefault(); // Prevent input of non-numeric characters
                      }
                    }}
                  />
                </div>
                {props.formik.errors?.dpGradeType &&
                props.formik.errors.dpGradeType &&
                props.formik.touched.dpGradeType ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.dpGradeType}
                  </div>
                ) : props.formik.errors.dpGrade &&
                  props.formik.touched.dpGrade ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.dpGrade}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Year of Joining</span>
                </div>
                <div className="popins-medium input-ele">
                  <TextField
                    error={
                      props.formik.errors.dpyearOfJoining &&
                      props.formik.touched.dpyearOfJoining
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => {}}
                    type="text"
                    name="dpyearOfJoining"
                    id="dpyearOfJoining"
                    inputProps={{
                      className: cx(
                        `!text-xs open-sans-regular boxedInput popins-medium`
                      ),
                      style: {
                        padding: "9.5px 14px",
                        height: "0.43em !important",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        color: "#525252",
                      },
                    }}
                    autoComplete="off"
                    placeholder="Enter your joining year"
                    onBlur={props.formik.handleBlur}
                    onChange={props.formik.handleChange}
                    value={props.formik.values.dpyearOfJoining}
                  />
                </div>
                {props.formik.errors.dpyearOfJoining &&
                props.formik.touched.dpyearOfJoining ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.dpyearOfJoining}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Year of Passing</span>
                </div>
                <div className="popins-medium input-ele">
                  <TextField
                    error={
                      props.formik.errors.dpyearOfPassing &&
                      props.formik.touched.dpyearOfPassing
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => {}}
                    type="text"
                    name="dpyearOfPassing"
                    id="dpyearOfPassing"
                    inputProps={{
                      className: cx(
                        `!text-xs open-sans-regular boxedInput popins-medium`
                      ),
                      style: {
                        padding: "9.5px 14px",
                        height: "0.43em !important",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        color: "#525252",
                      },
                    }}
                    autoComplete="off"
                    placeholder="Enter your passing year"
                    onBlur={props.formik.handleBlur}
                    onChange={props.formik.handleChange}
                    value={props.formik.values.dpyearOfPassing}
                  />
                </div>
                {props.formik.errors.dpyearOfPassing &&
                props.formik.touched.dpyearOfPassing ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.dpyearOfPassing}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Course Type</span>
                </div>
                <div className="input-ele">
                  <Select
                    options={props.dropDownData.dpCourseType}
                    onChange={(data) => {
                      props.handleDropdownChange([data], "dpCourseType");
                    }}
                    placeholder="Select the course type"
                    value={props.selectedDropdownData.dpCourseType}
                  />
                </div>
                {props.formik.errors?.dpCourseType &&
                props.formik.touched?.dpCourseType ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.dpCourseType}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
        {props.selectedPreDegree.includes("twelfth") && (
          <div className="pre-degree-course col-span-3 grid grid-cols-3">
            <div className="col-span-3 heading">PUC</div>
            <div className="col-span-3 body grid grid-cols-3">
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Percentage / CGPA</span>
                </div>
                <div className="popins-medium input-ele grid grid-cols-4">
                  <Select
                    className="col-span-2"
                    options={props.dropDownData.pucGradeType}
                    onChange={(data) => {
                      props.handleDropdownChange([data], "pucGradeType");
                    }}
                    placeholder="Select"
                    value={props.selectedDropdownData.pucGradeType ? props.selectedDropdownData.pucGradeType : ""}
                  />
                  <TextField
                    className="col-span-2"
                    error={
                      props.formik.errors.pucGrade &&
                      props.formik.touched.pucGrade
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => {}}
                    type="text"
                    name="pucGrade"
                    id="pucGrade"
                    inputProps={{
                      className: cx(
                        `!text-xs open-sans-regular boxedInput popins-medium`
                      ),
                      style: {
                        padding: "11px 14px",
                        height: "0.43em !important",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        color: "#525252",
                      },
                    }}
                    autoComplete="off"
                    placeholder={`Enter your ${props?.selectedDropdownData?.pucGradeType ? props?.selectedDropdownData?.pucGradeType[0]?.value : "Percentage"}`}
                    onBlur={props.formik.handleBlur}
                    onChange={props.formik.handleChange}
                    value={props.formik.values.pucGrade}
                    onKeyPress={(e) => {
                      // Check if the entered character is a number or a dot (for decimal input)
                      const validInput = /^[0-9.]$/.test(e.key);

                      if (!validInput) {
                        e.preventDefault(); // Prevent input of non-numeric characters
                      }
                    }}
                  />
                </div>
                {props.formik.errors?.pucGradeType &&
                props.formik.errors.pucGradeType &&
                props.formik.touched.pucGradeType ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.pucGradeType}
                  </div>
                ) : props.formik.errors.pucGrade &&
                  props.formik.touched.pucGrade ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.pucGrade}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Year of Joining</span>
                </div>
                <div className="popins-medium input-ele">
                  <TextField
                    error={
                      props.formik.errors.pucyearOfJoining &&
                      props.formik.touched.pucyearOfJoining
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => {}}
                    type="text"
                    name="pucyearOfJoining"
                    id="pucyearOfJoining"
                    inputProps={{
                      className: cx(
                        `!text-xs open-sans-regular boxedInput popins-medium`
                      ),
                      style: {
                        padding: "9.5px 14px",
                        height: "0.43em !important",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        color: "#525252",
                      },
                    }}
                    autoComplete="off"
                    placeholder="Enter your joining year"
                    onBlur={props.formik.handleBlur}
                    onChange={props.formik.handleChange}
                    value={props.formik.values.pucyearOfJoining}
                  />
                </div>
                {props.formik.errors.pucyearOfJoining &&
                props.formik.touched.pucyearOfJoining ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.pucyearOfJoining}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Year of Passing</span>
                </div>
                <div className="popins-medium input-ele">
                  <TextField
                    error={
                      props.formik.errors.pucyearOfPassing &&
                      props.formik.touched.pucyearOfPassing
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => {}}
                    type="text"
                    name="pucyearOfPassing"
                    id="pucyearOfPassing"
                    inputProps={{
                      className: cx(
                        `!text-xs open-sans-regular boxedInput popins-medium`
                      ),
                      style: {
                        padding: "9.5px 14px",
                        height: "0.43em !important",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        color: "#525252",
                      },
                    }}
                    autoComplete="off"
                    placeholder="Enter your passing year"
                    onBlur={props.formik.handleBlur}
                    onChange={props.formik.handleChange}
                    value={props.formik.values.pucyearOfPassing}
                  />
                </div>
                {props.formik.errors.pucyearOfPassing &&
                props.formik.touched.pucyearOfPassing ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.pucyearOfPassing}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
        {props.selectedPreDegree.includes("iti") && (
          <div className="pre-degree-course col-span-3 grid grid-cols-3">
            <div className="col-span-3 heading">ITI</div>
            <div className="col-span-3 body grid grid-cols-3">
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Board</span>
                </div>
                <div className="input-ele">
                  <Select
                    dpName="itiboardName"
                    options={props.dropDownData.itiboardName}
                    onChange={(data) => {
                      props.handleDropdownChange([data], "itiboardName", [
                        "itiCollegeName",
                      ]);
                      if (data.value === "Others") {
                        // setItiBoardOther(true);
                        props.itiBoardChange(data);
                      }
                      // else {
                      //   setItiBoardOther(false);
                      // }
                    }}
                    placeholder="Select the board name"
                    onMenuOpen={props.itiBoardDataHandler}
                    // value={props.selectedDropdownData.itiboardName}
                    value={Array.isArray(props.selectedDropdownData.itiboardName) ? props.selectedDropdownData.itiboardName[0]?.value == ""? null : props.selectedDropdownData.itiboardName : null }
                    isSearchable={true}
                  />
                </div>
                {props.formik.errors?.itiboardName &&
                props.formik.touched?.itiboardName ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.itiboardName}
                  </div>
                ) : null}
              </div>
              {props.formik.values.itiboardName === "Others" && (
                <div className="mt-2 individual-field">
                  <div className="mb-2 label">
                    <span className="create-labels">Board Name</span>
                  </div>
                  <div className="popins-medium input-ele">
                    <TextField
                      error={
                        props.formik.errors.itiBoard &&
                        props.formik.touched.itiBoard
                      }
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={() => {}}
                      type="text"
                      name="itiBoard"
                      id="itiBoard"
                      inputProps={{
                        className: cx(
                          `!text-xs open-sans-regular boxedInput popins-medium`
                        ),
                        style: {
                          padding: "9.5px 14px",
                          height: "0.43em !important",
                          fontSize: "12px",
                          fontFamily: "Poppins-Medium",
                          color: "#525252",
                        },
                      }}
                      autoComplete="off"
                      placeholder="Enter the board name"
                      onBlur={props.formik.handleBlur}
                      onChange={props.formik.handleChange}
                      value={props.formik.values.itiBoard}
                    />
                  </div>
                  {props.formik.errors.itiBoard &&
                  props.formik.touched.itiBoard ? (
                    <div className="text-red-500 error_text md:mt-1">
                      {props.formik.errors.itiBoard}
                    </div>
                  ) : null}
                </div>
              )}
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">College Name</span>
                </div>
                {props.formik.values.itiboardName === "Others" ? (
                  <div className="popins-medium input-ele">
                    <TextField
                      error={
                        props.formik.errors.itiCollegeName &&
                        props.formik.touched.itiCollegeName
                      }
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={() => {}}
                      type="text"
                      name="itiCollegeName"
                      id="itiCollegeName"
                      inputProps={{
                        className: cx(
                          `!text-xs open-sans-regular boxedInput popins-medium`
                        ),
                        style: {
                          padding: "9.5px 14px",
                          height: "0.43em !important",
                          fontSize: "12px",
                          fontFamily: "Poppins-Medium",
                          color: "#525252",
                        },
                      }}
                      autoComplete="off"
                      placeholder="Enter the college name"
                      onBlur={props.formik.handleBlur}
                      onChange={props.formik.handleChange}
                      value={props.formik.values.itiCollegeName}
                      // value={Array.isArray(props.selectedDropdownData.itiCollegeName) ? props.selectedDropdownData.itiCollegeName[0]?.value == ""? null : props.selectedDropdownData.itiCollegeName : null }
                    />
                  </div>
                ) : (
                  <div className="input-ele">
                    <Select
                      dpName="itiCollegeName"
                      options={props.dropDownData.itiCollegeName}
                      onChange={(data) => {
                        props.handleDropdownChange([data], "itiCollegeName");
                      }}
                      placeholder="Select the college name"
                      onMenuOpen={props.itiCollegeDataHandler}
                        // value={props.selectedDropdownData.itiCollegeName}
                      value={Array.isArray(props.selectedDropdownData.itiCollegeName) ? props.selectedDropdownData.itiCollegeName[0]?.value == ""? null : props.selectedDropdownData.itiCollegeName : null }
                      isSearchable={true}
                    />
                  </div>
                )}
                {props.formik.errors?.itiCollegeName &&
                props.formik.touched?.itiCollegeName ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.itiCollegeName}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Specialization</span>
                </div>
                <div className="input-ele">
                  <Select
                    dpName="itiStream"
                    options={props.dropDownData.itiStream}
                    onChange={(data) => {
                      props.handleDropdownChange([data], "itiStream");
                    }}
                    placeholder="Select the stream"
                    onMenuOpen={props.itiStreamDataHandler}
                    // value={props.selectedDropdownData.itiStream}
                    value={Array.isArray(props.selectedDropdownData.itiStream) ? props.selectedDropdownData.itiStream[0]?.value == ""? null : props.selectedDropdownData.itiStream : null }
                    isSearchable={true}
                  />
                </div>
                {props.formik.errors?.itiStream &&
                props.formik.touched?.itiStream ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.itiStream}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Percentage / CGPA</span>
                </div>
                <div className="popins-medium input-ele grid grid-cols-4">
                  <Select
                    className="col-span-2"
                    options={props.dropDownData.itiGradeType}
                    onChange={(data) => {
                      props.handleDropdownChange([data], "itiGradeType");
                    }}
                    placeholder="Select"
                    value={props.selectedDropdownData.itiGradeType}
                  />
                  <TextField
                    className="col-span-2"
                    error={
                      props.formik.errors.itiGrade &&
                      props.formik.touched.itiGrade
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => {}}
                    type="text"
                    name="itiGrade"
                    id="itiGrade"
                    inputProps={{
                      className: cx(
                        `!text-xs open-sans-regular boxedInput popins-medium`
                      ),
                      style: {
                        padding: "11px 14px",
                        height: "0.43em !important",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        color: "#525252",
                      },
                    }}
                    autoComplete="off"
                    placeholder={`Enter your ${props?.selectedDropdownData?.itiGradeType ? props?.selectedDropdownData?.itiGradeType[0]?.value : "Percentage"}`}
                    onBlur={props.formik.handleBlur}
                    onChange={props.formik.handleChange}
                    value={props.formik.values.itiGrade}
                    onKeyPress={(e) => {
                      // Check if the entered character is a number or a dot (for decimal input)
                      const validInput = /^[0-9.]$/.test(e.key);

                      if (!validInput) {
                        e.preventDefault(); // Prevent input of non-numeric characters
                      }
                    }}
                  />
                </div>
                {props.formik.errors?.itiGradeType &&
                props.formik.errors.itiGradeType &&
                props.formik.touched.itiGradeType ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.itiGradeType}
                  </div>
                ) : props.formik.errors.itiGrade &&
                  props.formik.touched.itiGrade ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.itiGrade}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Year of Joining</span>
                </div>
                <div className="popins-medium input-ele">
                  <TextField
                    error={
                      props.formik.errors.itiyearOfJoining &&
                      props.formik.touched.itiyearOfJoining
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => {}}
                    type="text"
                    name="itiyearOfJoining"
                    id="itiyearOfJoining"
                    inputProps={{
                      className: cx(
                        `!text-xs open-sans-regular boxedInput popins-medium`
                      ),
                      style: {
                        padding: "9.5px 14px",
                        height: "0.43em !important",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        color: "#525252",
                      },
                    }}
                    autoComplete="off"
                    placeholder="Enter your joining year"
                    onBlur={props.formik.handleBlur}
                    onChange={props.formik.handleChange}
                    value={props.formik.values.itiyearOfJoining}
                  />
                </div>
                {props.formik.errors.itiyearOfJoining &&
                props.formik.touched.itiyearOfJoining ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.itiyearOfJoining}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Year of Passing</span>
                </div>
                <div className="popins-medium input-ele">
                  <TextField
                    error={
                      props.formik.errors.itiyearOfPassing &&
                      props.formik.touched.itiyearOfPassing
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={() => {}}
                    type="text"
                    name="itiyearOfPassing"
                    id="itiyearOfPassing"
                    inputProps={{
                      className: cx(
                        `!text-xs open-sans-regular boxedInput popins-medium`
                      ),
                      style: {
                        padding: "9.5px 14px",
                        height: "0.43em !important",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                        color: "#525252",
                      },
                    }}
                    autoComplete="off"
                    placeholder="Enter your passing year"
                    onBlur={props.formik.handleBlur}
                    onChange={props.formik.handleChange}
                    value={props.formik.values.itiyearOfPassing}
                  />
                </div>
                {props.formik.errors.itiyearOfPassing &&
                props.formik.touched.itiyearOfPassing ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.itiyearOfPassing}
                  </div>
                ) : null}
              </div>
              <div className="mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Course Type</span>
                </div>
                <div className="input-ele">
                  <Select
                    options={props.dropDownData.itiCourseType}
                    onChange={(data) => {
                      props.handleDropdownChange([data], "itiCourseType");
                    }}
                    placeholder="Select the course type"
                    value={props.selectedDropdownData.itiCourseType}
                  />
                </div>
                {props.formik.errors?.itiCourseType &&
                props.formik.touched?.itiCourseType ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {props.formik.errors.itiCourseType}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiplomaPucIti;
