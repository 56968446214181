import React from "react";
import { useFormik } from "formik";
import cx from "classnames";
import { TextField } from "@mui/material";
import * as yup from "yup";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useState } from "react";
import { useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import style from "../../landing-page/common/inputs/inputs.module.scss";
import "../CandidateProgress.scss";
import { Checkbox } from "@material-ui/core";

import {
  defaultCountry,
  getAllCitiesReq,
  getAllCountriesReq,
  getAllStatesReq,
} from "../../../api/api-services";
import { MultiSelectDropdown } from "../../landing-page/common/select-dropdown";
import { TextareaAutosize } from "@material-ui/core";
import { stringToDropdownValue } from "../../../util/common-utils";
import Select from "react-select";

const PersonalDetailsForm = (props) => {
  const [value, setValue] = useState();
  const [enableCheckBox, setEnableCheckBox] = useState(false);
  const [presentCountry, setPresentCountry] = useState("");
  const [error, setError] = useState({ phoneNumber: false, validPhone: false });
  const inputBorder = "1px solid #26428B80";
  const inputBorderErr = "1px solid #ea0322";
  const EMAIL_PATTERN =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [emailError, setEmailError] = useState();
  const [dateValue, setDateValue] = useState("");
  const [sameAddressFlag, setSameAddressFlag] = useState(
    props.data.addressSame
  );
  const [descCount, setdescCount] = useState(0);
  const [descCount2, setdescCount2] = useState(0);
  const [dropDownData, setDropDownData] = useState({
    tcountry: [],
    tcity: [],
    tstate: [],
    pcountry: [],
    pcity: [],
    pstate: [],
    languages: [
      { label: "Mandarin Chinese", value: "Mandarin Chinese" },
      { label: "Spanish", value: "Spanish" },
      { label: "English", value: "English" },
      { label: "Hindi", value: "Hindi" },
      { label: "Arabic", value: "Arabic" },
      { label: "Bengali", value: "Bengali" },
      { label: "Portuguese", value: "Portuguese" },
      { label: "Russian", value: "Russian" },
      { label: "Japanese", value: "Japanese" },
      { label: "Punjabi", value: "Punjabi" },
      { label: "German", value: "German" },
      { label: "Javanese", value: "Javanese" },
      { label: "Wu Chinese", value: "Wu Chinese" },
      { label: "Malay", value: "Malay" },
      { label: "Telugu", value: "Telugu" },
      { label: "Vietnamese", value: "Vietnamese" },
      { label: "Korean", value: "Korean" },
      { label: "French", value: "French" },
      { label: "Marathi", value: "Marathi" },
      { label: "Tamil", value: "Tamil" },
      { label: "Urdu", value: "Urdu" },
      { label: "Turkish", value: "Turkish" },
      { label: "Italian", value: "Italian" },
      { label: "Yue Chinese (Cantonese)", value: "Yue Chinese (Cantonese)" },
      { label: "Thai", value: "Thai" },
      { label: "Gujarati", value: "Gujarati" },
      { label: "Jin Chinese", value: "Jin Chinese" },
      { label: "Kannada", value: "Kannada" },
      { label: "Malayalam", value: "Malayalam" },
      { label: "Persian", value: "Persian" },
      { label: "Polish", value: "Polish" },
      { label: "Bhojpuri", value: "Bhojpuri" },
      { label: "Hausa", value: "Hausa" },
      { label: "Eastern Min Chinese", value: "Eastern Min Chinese" },
      { label: "Hakka Chinese", value: "Hakka Chinese" },
      { label: "Western Punjabi", value: "Western Punjabi" },
      { label: "Burmese", value: "Burmese" },
      { label: "Gan Chinese", value: "Gan Chinese" },
      { label: "Maithili", value: "Maithili" },
      { label: "Sundanese", value: "Sundanese" },
      { label: "Romanian", value: "Romanian" },
      { label: "Igbo", value: "Igbo" },
      { label: "Awadhi", value: "Awadhi" },
      { label: "Chittagonian", value: "Chittagonian" },
      { label: "Magahi", value: "Magahi" },
      { label: "Greek", value: "Greek" },
      { label: "Chhattisgarhi", value: "Chhattisgarhi" },
      { label: "Kazakh", value: "Kazakh" },
      { label: "Deccan", value: "Deccan" },
      { label: "Hungarian", value: "Hungarian" },
      { label: "Tswana", value: "Tswana" },
      { label: "Marwari", value: "Marwari" },
      { label: "Sylheti", value: "Sylheti" },
      { label: "Zulu", value: "Zulu" },
      { label: "Czech", value: "Czech" },
      { label: "Somali", value: "Somali" },
      { label: "Haitian Creole", value: "Haitian Creole" },
      { label: "Min Bei Chinese", value: "Min Bei Chinese" },
      { label: "Nepali", value: "Nepali" },
      { label: "Xiang Chinese", value: "Xiang Chinese" },
      { label: "Sinhala", value: "Sinhala" },
      { label: "Bulgarian", value: "Bulgarian" },
      { label: "Dhundhari", value: "Dhundhari" },
      { label: "Haryanvi", value: "Haryanvi" },
      { label: "Serbo-Croatian", value: "Serbo-Croatian" },
      { label: "Kirundi", value: "Kirundi" },
      { label: "Tigrinya", value: "Tigrinya" },
      { label: "Kanuri", value: "Kanuri" },
      { label: "Sindhi", value: "Sindhi" },
      { label: "Lithuanian", value: "Lithuanian" },
      { label: "Danish", value: "Danish" },
      { label: "Saraiki", value: "Saraiki" },
      { label: "Nahuatl", value: "Nahuatl" },
      { label: "Manx", value: "Manx" },
    ],
  });
  const [selectedDropdownData, setSelectedDropdownData] = useState({
    currentcountry: [],
    currentcity: [],
    currentstate: [],
    languages: [],
  });
  const onSubmit = async (values) => {
    props.changeTab("educationDetails");
    console.log("personalDetails values", values);
    props.fetchDataFromForms(handlePersonalDataForm(values));
  };
  const showCheckBox = () => {
    if (
      (formik.values.currentcountry != null && formik.values.currentcountry != "") &&
      (formik.values.currentstate != null && formik.values.currentstate != "") &&
      (formik.values.currentcity !=  null && formik.values.currentcity != "") &&
      (formik.values.currentaddress != null && formik.values.currentaddress != "") &&
      (formik.values.currentpincode !== null && formik.values.currentpincode != "")
    ) {
      setEnableCheckBox(true);
    } else {
      setEnableCheckBox(false);
    }
  };
  const handleDropdownChange = (data, type, keysToBeCleared) => {
    let allSelectedData = { ...selectedDropdownData };
    if (type === "languages") {
      let langs = [];
      data?.map((val) => {
        langs.push(val.value);
      });
      allSelectedData[type] = data;
      formik.setFieldValue(type, [...langs]);
    } else {
      allSelectedData[type] = [...data];
      formik.setFieldValue(type, data?.[0]?.value);
    }
    keysToBeCleared?.forEach((element) => {
      formik.values[element] = "";
      allSelectedData[element] = [];
    });
    setSelectedDropdownData({ ...allSelectedData });
  };
  const validationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .required("Name is required")
      .min(3, "Minimum 3 characters")
      .matches(/^[A-Za-z ]*$/, "Name should be alphabetic"),
    emailId: yup
      .string()
      .trim()
      .required("Email id is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Enter valid email address"),
    phoneNumber: yup.string().required("Valid Phone number is required"),
    dob: yup.string().required("DOB is required"),
    gender: yup.string().required("Gender is required"),
    languages: yup.array().min(1, "Languages are required"),
    currentcountry: yup.string().required("Country is required"),
    currentstate: yup.string().required("State is required"),
    currentcity: yup.string().required("City is required"),
    currentaddress: yup.string().trim().required("Address is required"),
    currentpincode: yup
      .string()
      .required("Pincode is required")
      .matches(/^[0-9]{6}$/, "Pincode should be numeric and 6 digits")
      .test("not-all-zeros", 'Pincode cannot be "000000"', (value) => {
        return value !== "000000";
      }),
    permanentcountry: yup.string().required("Country is required"),
    permanentstate: yup.string().required("State is required"),
    permanentcity: yup.string().required("City is required"),
    permanentaddress: yup.string().trim().required("Address is required"),
    permanentpincode: yup
      .string()
      .required("Pincode is required")
      .matches(/^[0-9]{6}$/, "Pincode should be numeric and 6 digits")
      .test("not-all-zeros", 'Pincode cannot be "000000"', (value) => {
        return value !== "000000";
      }),
  });
  const handlePersonalDataForm = (val) => {
    let tempObj = {
      name: val.name,
      mobile: val.phoneNumber,
      email: val.emailId,
      dob: val.dob,
      gender: val.gender,
      addressSame: sameAddressFlag,
      currentAddress: {
        country: val.currentcountry,
        state: val.currentstate,
        city: val.currentcity,
        pinCode: val.currentpincode,
        description: val.currentaddress,
      },
      permanentAddress: {
        country: val.permanentcountry,
        state: val.permanentstate,
        city: val.permanentcity,
        pinCode: val.permanentpincode,
        description: val.permanentaddress,
      },
      languages: val.languages,
    };
    if (sameAddressFlag) {
      tempObj.permanentAddress = tempObj.currentAddress;
    }
    return { personalDetails: tempObj };
  };

  let initialValues = {};
  if (props?.data) {
    initialValues = {
      name: props.data.name,
      phoneNumber: props.data.mobile,
      emailId: props.data.email,
      dob: props.data.dob,
      gender: props.data.gender,
      languages: props.data?.languages ? props.data.languages : [],
      currentcountry: props.data.currentAddress.country,
      currentstate: props.data.currentAddress.state,
      currentcity: props.data.currentAddress.city,
      currentaddress: props.data.currentAddress.description
        ? props.data.currentAddress.description
        : "",
      currentpincode: props.data.currentAddress.pinCode,
      permanentcountry: props.data.permanentAddress.country,
      permanentstate: props.data.permanentAddress.state,
      permanentcity: props.data.permanentAddress.city,
      permanentaddress: props.data.permanentAddress.description
        ? props.data.permanentAddress.description
        : "",
      permanentpincode: props.data.permanentAddress.pinCode,
    };
  } else {
    initialValues = {
      name: "",
      phoneNumber: "",
      emailId: "",
      dob: "",
      gender: "",
      currentcountry: "",
      currentstate: "",
      currentcity: "",
      currentaddress: "",
      currentpincode: "",
      permanentcountry: "",
      permanentstate: "",
      permanentcity: "",
      permanentaddress: "",
      permanentpincode: "",
      languages: [],
    };
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const getCountries = async (key) => {
    try {

      const res = await getAllCountriesReq();
      console.log("All countires Data", res);
      if (res?.data) {
        let tempCountry = res.data.response.map((val) => {
          return stringToDropdownValue(val);
        });
        setDropDownData({
          ...dropDownData,
          [key]: tempCountry,
        });
      } else {
        setDropDownData({ ...dropDownData, [key]: [] });
      }
    } catch (e) {
      console.log("countires error", e);
    }
  };
  const getStates = async (key, country) => {
    try {
      const res = await getAllStatesReq(country);
      console.log("All states Data", res);
      if (res?.data) {
        let tempState = res.data.response.map((val) => {
          return stringToDropdownValue(val);
        });
        setDropDownData({ ...dropDownData, [key]: tempState });
      } else {
        setDropDownData({ ...dropDownData, [key]: [] });
      }
    } catch (e) {
      console.log("states error", e);
    }
  };
  const getCities = async (key, country, state) => {
    try {
      const res = await getAllCitiesReq(country, state);
      console.log("All cities Data", res);
      if (res?.data) {
        let tempCity = res.data.response.map((val) => {
          return stringToDropdownValue(val);
        });
        setDropDownData({ ...dropDownData, [key]: tempCity });
      } else {
        setDropDownData({ ...dropDownData, [key]: [] });
      }
    } catch (e) {
      console.log("cities error", e);
    }
  };
  const getCurrentCountryHandler = () => {
    if (formik.values.currentcountry?.value !== null) {
      getCountries("tcountry");
    }
  };
  const getCurrentStateHandler = () => {
    if (formik.values.currentcountry?.value !== null) {
      getStates("tstate", formik.values.currentcountry);
    }
  };
  const getCurrentCityHandler = () => {
    if (
      formik.values.currentcountry?.value !== null &&
      formik.values.currentstate?.value !== null
    ) {
      getCities(
        "tcity",
        formik.values.currentcountry,
        formik.values.currentstate
      );
    }
  };
  const getPermanentCountryHandler = () => {
    if (formik.values.permanentcountry?.value !== null) {
      getCountries("pcountry");
    }
  };
  const getPermanentStateHandler = () => {
    if (formik.values.permanentcountry?.value !== null) {
      getStates("pstate", formik.values.permanentcountry);
    }
  };
  const getPermanentCityHandler = () => {
    if (
      formik.values.permanentcountry?.value !== null &&
      formik.values.permanentstate?.value !== null
    ) {
      getCities(
        "pcity",
        formik.values.permanentcountry,
        formik.values.permanentstate
      );
    }
  };

  useEffect(() => {
    defaultCountry()
      .then((res) => {
        setPresentCountry(res.data?.country);
      })
      .catch((err) => {
        console.error(err);
      });
    if (props?.data) {
      let tempLanguages = [];
      props?.data.languages?.map((language) => {
        tempLanguages.push(stringToDropdownValue(language));
      });
      setSelectedDropdownData({
        ...selectedDropdownData,
        currentcountry: [stringToDropdownValue(props.data.currentAddress.country)],
        currentstate: [stringToDropdownValue(props.data.currentAddress.state)],
        currentcity: [stringToDropdownValue(props.data.currentAddress.city)],
        permanentcountry: [
          stringToDropdownValue(props.data.permanentAddress.country),
        ],
        permanentstate: [
          stringToDropdownValue(props.data.permanentAddress.state),
        ],
        permanentcity: [stringToDropdownValue(props.data.permanentAddress.city)],
        languages: tempLanguages,
      });

      let date = "";
      if (props.data.dob) {
        let dateArray = props.data.dob.split("-");
        date = `${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`;
      }
      setDateValue(date);
      showCheckBox();
      setdescCount(formik.values.currentaddress.length)
    } else {
      //future
    }
    
  }, []);
  const setPermanentAddressFields = (e) => {
    let allSelectedData = { ...selectedDropdownData };
    setSameAddressFlag(e.target.checked);
    if (e.target.checked) {
      allSelectedData["permanentcountry"] = allSelectedData["currentcountry"];
      allSelectedData["permanentstate"] = allSelectedData["currentstate"];
      allSelectedData["permanentcity"] = allSelectedData["currentcity"];
      formik.setValues({
        ...formik.values,
        permanentcountry: formik.values.currentcountry,
        permanentstate: formik.values.currentstate,
        permanentcity: formik.values.currentcity,
        permanentaddress: formik.values.currentaddress,
        permanentpincode: formik.values.currentpincode,
      });

    } else {
      allSelectedData["permanentcountry"] = [stringToDropdownValue("")];
      allSelectedData["permanentstate"] = [stringToDropdownValue("")];
      allSelectedData["permanentcity"] = [stringToDropdownValue("")];
      formik.setValues({
        ...formik.values,
        permanentcountry: "",
        permanentstate:"",
        permanentcity: "",
        permanentaddress:"",
        permanentpincode: "",
      });
      // formik.setFieldValue("permanentcountry", "");
      // formik.setFieldValue("permanentstate", "");
      // formik.setFieldValue("permanentcity", "");
      // formik.setFieldValue("permanentaddress", "");
      // formik.setFieldValue("permanentpincode", "");
    }
    setSelectedDropdownData({ ...allSelectedData });
  };
  return (
    <div className="personal-details-form ">
      <form className="details-form-tag" onSubmit={formik.handleSubmit}>
        <div className="details-form-body grid grid-cols-3">
          <div className="mt-2 individual-field ">
            <div className="mb-2 label">
              <span className="create-labels">Name</span>
            </div>
            <div className="popins-medium input-ele">
              <TextField
                error={formik.errors.name && formik.touched.name}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={() => {}}
                disabled={true}
                type="text"
                name="name"
                id="name"
                inputProps={{
                  className: cx(
                    `!text-xs open-sans-regular boxedInput popins-medium cursor-not-allowed`
                  ),
                  style: {
                    padding: "9.5px 14px",
                    height: "0.43em !important",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    color: "#525252",
                  },
                }}
                autoComplete="off"
                placeholder="Enter your name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </div>
            {formik.errors.name && formik.touched.name ? (
              <div className="text-red-500 error_text md:mt-1">
                {formik.errors.name}
              </div>
            ) : null}
          </div>
          <div className="form-group mt-2  individual-field text-left">
            <div className="mb-2 label ">
              <span className="create-labels">Mobile Number</span>
            </div>
            <div className="">
              <TextField
                error={formik.errors.phoneNumber && formik.touched.phoneNumber}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={() => {}}
                disabled={true}
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                inputProps={{
                  className: cx(
                    `!text-xs open-sans-regular boxedInput popins-medium cursor-not-allowed`
                  ),
                  style: {
                    padding: "9.5px 14px",
                    height: "0.43em !important",
                    fontSize: "12px",
                    fontFamily: "Poppins-Medium",
                    color: "#525252",
                  },
                }}
                autoComplete="off"
                placeholder="Enter your mobile number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
              />
            </div>
            {(error.phoneNumber ||
              (formik.errors.phoneNumber && formik.touched.phoneNumber)) && (
              <div className="error_text">Phone number is required</div>
            )}
            {error.validPhone && !error.phoneNumber && (
              <div className="error_text">Invalid phone number</div>
            )}
          </div>
          <div className="mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Email</span>
            </div>
            <div className="popins-medium">
              <div
                className={cx(
                  "w-full user-email-input-tyle",
                  style["labeled-input"],
                  {
                    [style["number-input-control"]]: props?.type === "number",
                  }
                )}
              >
                <TextField
                  error={formik.errors.emailId && formik.touched.emailId}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onClick={() => {}}
                  disabled={true}
                  type="text"
                  name="emailId"
                  id="emailId"
                  inputProps={{
                    className: cx(
                      `!text-xs open-sans-regular boxedInput popins-medium cursor-not-allowed`
                    ),
                    style: {
                      padding: "9.5px 14px",
                      height: "0.43em !important",
                      fontSize: "12px",
                      fontFamily: "Poppins-Medium",
                      color: "#525252",
                    },
                  }}
                  autoComplete="off"
                  placeholder="Enter your email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.emailId}
                />
              </div>
            </div>
            {formik.errors.emailId && formik.touched.emailId ? (
              <div className="text-red-500 error_text md:mt-1">
                {formik.errors.emailId}
              </div>
            ) : null}
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">DOB</span>
            </div>
            <div className="input-ele">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(new Date(dateValue))}
                  defaultValue={dayjs(new Date(dateValue))}
                  id="dob"
                  name="dob"
                  onChange={(newValue) => {
                    formik.setFieldValue(
                      "dob",
                      dayjs(newValue).format("DD-MM-YYYY")
                    );
                    setDateValue(newValue);
                  }}
                  format="DD-MM-YYYY"
                  className="date-picker"
                  disableFuture={true}
                  slotProps={{
                    textField: {
                      readOnly: true,
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
            {formik.errors.dob && formik.touched.dob ? (
              <div className="text-red-500 error_text md:mt-1">
                {formik.errors.dob}
              </div>
            ) : null}
          </div>
          <div className="mt-2 individual-field ">
            <div className="mb-2 label">
              <span className="create-labels">Gender</span>
            </div>
            <div className="popins-medium input-ele flex">
              <div
                style={{cursor:"pointer"}}
                onClick={() => {
                  formik.setFieldValue("gender", "MALE");
                }}
                className={`${
                  formik.values?.gender === "MALE" ? "selectedGender" : ""
                } genderField `}
              >
                Male
              </div>
              <div
                style={{cursor:"pointer"}}
                onClick={() => {
                  formik.setFieldValue("gender", "FEMALE");
                }}
                className={`${
                  formik.values.gender === "FEMALE" ? "selectedGender" : ""
                } genderField`}
              >
                Female
              </div>
              <div
                style={{cursor:"pointer"}}
                onClick={() => {
                  formik.setFieldValue("gender", "OTHERS");
                }}
                className={`${
                  formik.values.gender === "OTHERS" ? "selectedGender" : ""
                } genderField`}
              >
                Others
              </div>
            </div>
            {formik.errors.gender && formik.touched.gender ? (
              <div className="text-red-500 error_text md:mt-1">
                {formik.errors.gender}
              </div>
            ) : null}
          </div>
          <div className="field-wrapper mt-2 individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Known Languages</span>
            </div>
            <div className="input-ele">
              <Select
                options={dropDownData?.languages}
                value={selectedDropdownData?.languages}
                isSearchable={true}
                isMulti={true}
                placeholder="Select..."
                isClearable={false}
                className="my-select-container"
                onChange={(data) => {
                  handleDropdownChange(data, "languages");
                }}
              />
            </div>
            {formik.errors?.languages && formik.touched?.languages ? (
              <div className="text-red-500 error_text md:mt-1">
                {formik.errors.languages}
              </div>
            ) : null}
          </div>
          <div className="adress-section col-span-3 grid grid-cols-3">
            <div className="col-span-3 sub-heading">Current Address</div>
            <div className="field-wrapper mt-2 individual-field">
              <div className="mb-2 label">
                <span className="create-labels">Country</span>
              </div>
              <div className="input-ele">
                <Select
                  noOptionsMessage={() => {
                    return "No more options";
                  }}

                  options={dropDownData?.tcountry}
                  value={selectedDropdownData?.currentcountry[0]?.value===null?[]:selectedDropdownData?.currentcountry}
                  isSearchable={true}
                  onBlur={showCheckBox}
                  onMenuOpen={getCurrentCountryHandler}
                  placeholder="Select..."
                  onChange={(data) => {
                    showCheckBox();
                    handleDropdownChange([data], "currentcountry", [
                      "currentstate",
                      "currentcity",
                    ]);
                  }}
                />
              </div>
              {formik.errors?.currentcountry &&
              formik.touched?.currentcountry ? (
                <div className="text-red-500 error_text md:mt-1">
                  {formik.errors.currentcountry}
                </div>
              ) : null}
            </div>
            <div className="field-wrapper mt-2 individual-field">
              <div className="mb-2 label">
                <span className="create-labels">State</span>
              </div>
              <div className="input-ele">
                <Select
                  options={dropDownData?.tstate}
                  value={selectedDropdownData?.currentstate[0]?.value===null?[]:selectedDropdownData?.currentstate}
                  isSearchable={true}
                  onBlur={showCheckBox}
                  onMenuOpen={getCurrentStateHandler}
                  isDisabled={formik.values.currentcountry !== "" && false}
                  placeholder="Select..."
                  onChange={(data) => {
                    showCheckBox();
                    handleDropdownChange([data], "currentstate", [
                      "currentcity",
                    ]);
                  }}
                />
              </div>
              {formik.errors.currentstate && formik.touched.currentstate ? (
                <div className="text-red-500 error_text md:mt-1">
                  {formik.errors.currentstate}
                </div>
              ) : null}
            </div>
            <div className="field-wrapper mt-2 individual-field">
              <div className="mb-2 label">
                <span className="create-labels">City</span>
              </div>
              <div className="input-ele">
                <Select
                  options={dropDownData?.tcity}
                  value={selectedDropdownData?.currentcity[0]?.value===null?[]:selectedDropdownData?.currentcity}
                  isSearchable={true}
                  onMenuOpen={getCurrentCityHandler}
                  onBlur={showCheckBox}
                  placeholder="Select..."
                  isDisabled={
                    formik.values.currentcountry !== "" &&
                    formik.values.currentstate !== ""
                      ? false
                      : true
                  }
                  onChange={(data) => {
                    showCheckBox();
                    handleDropdownChange([data], "currentcity");
                  }}
                />
              </div>
              {formik.errors.currentcity && formik.touched.currentcity ? (
                <div className="text-red-500 error_text md:mt-1">
                  {formik.errors.currentcity}
                </div>
              ) : null}
            </div>
            <div className="msg-field col-span-2">
              <div className="form-group text-left mb-0">
                <div className="mb-2 label">
                  <span className="create-labels">Address</span>
                </div>
                <TextareaAutosize
                  className="form-control shadow-none input-ele textarea_contact"
                  name="currentaddress"
                  cols="40"
                  rows="5"
                  value={formik.values.currentaddress}
                  onKeyUp={(e) =>
                    setdescCount(formik.values.currentaddress.length)
                  }
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    showCheckBox();
                  }}
                  onChange={(e) => {
                    if (sameAddressFlag) {
                      formik.values.permanentaddress = e.target.value;
                      setdescCount(formik.values.currentaddress.length)
                    }
                    formik.handleChange(e);
                  }}
                  style={{
                    border: inputBorder,
                    resize: "none",
                    height: "8rem",
                  }}
                  maxLength="500"
                ></TextareaAutosize>
                <div className="mt-1 mr-1 text-sm text-gray-500 text-right">
                  {descCount}/500
                </div>
                {formik.errors.currentaddress &&
                formik.touched.currentaddress ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formik.errors.currentaddress}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mt-2 individual-field col-span-1">
              <div className="mb-2 label">
                <span className="create-labels">Pincode</span>
              </div>
              <div className="popins-medium input-ele">
                <TextField
                  error={
                    formik.errors.currentpincode &&
                    formik.touched.currentpincode
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onClick={() => {}}
                  type="text"
                  name="currentpincode"
                  id="currentpincode"
                  inputProps={{
                    className: cx(
                      `!text-xs open-sans-regular boxedInput popins-medium`
                    ),
                    style: {
                      padding: "9.5px 14px",
                      height: "0.43em !important",
                      fontSize: "12px",
                      fontFamily: "Poppins-Medium",
                      color: "#525252",
                    },
                  }}
                  autoComplete="off"
                  placeholder="Enter your pincode"
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    showCheckBox();
                  }}
                  onChange={(e) => {
                    // showCheckBox();
                    if (sameAddressFlag) {
                      formik.values.permanentpincode = e.target.value;
                    }
                    formik.handleChange(e);
                  }}
                  value={formik.values.currentpincode}
                />
              </div>
              {formik.errors.currentpincode && formik.touched.currentpincode ? (
                <div className="text-red-500 error_text md:mt-1">
                  {formik.errors.currentpincode}
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-span-3" title={`${!enableCheckBox ? 'Will apply only after filling current address' : ''}`}>
            <div>
              <Checkbox
                color="primary"
                value={value}
                checked={
                  formik.values.sameAddress
                    ? formik.values.sameAddress
                    : sameAddressFlag
                }
                disabled={!enableCheckBox}
                onChange={(e) => {
                  setPermanentAddressFields(e, value);
                }}
              />
              <span className={`${!enableCheckBox ? 'opacity-50' : ''}`}>Permanent Address is same as Current Address</span>
            </div>
          </div>
          <div className="adress-section col-span-3 grid grid-cols-3">
            <div className="col-span-3 sub-heading">Permanent Address</div>
            <div className="field-wrapper mt-2 individual-field">
              <div className="mb-2 label">
                <span className="create-labels">Country</span>
                {console.log(selectedDropdownData?.permanentcountry,"selectedDropdownData?.permanentcountry")}
              </div>
              <div className="input-ele">
                <Select
                  options={dropDownData?.pcountry}
                  //selectedDropdownData?.permanentcountry[0]?.value===null?[]:selectedDropdownData?.permanentcountry
                  value={
                    sameAddressFlag
                      ? Array.isArray(selectedDropdownData?.currentcountry) ? selectedDropdownData?.currentcountry[0]?.value===null?[]:selectedDropdownData?.currentcountry :null
                      : Array.isArray(selectedDropdownData?.permanentcountry) ?selectedDropdownData?.permanentcountry[0]?.value===null || selectedDropdownData?.permanentcountry[0]?.value=== ""?[]:selectedDropdownData?.permanentcountry : null
                  }
                  isSearchable={true}
                  onMenuOpen={getPermanentCountryHandler}
                  placeholder="Select..."
                  onChange={(data) => {
                    handleDropdownChange([data], "permanentcountry", [
                      "permanentstate",
                      "permanentcity",
                    ]);
                  }}
                  isDisabled={sameAddressFlag}
                />
              </div>
              {formik.errors?.permanentcountry &&
              formik.touched?.permanentcountry ? (
                <div className="text-red-500 error_text md:mt-1">
                  {formik.errors.permanentcountry}
                </div>
              ) : null}
            </div>
            <div className="field-wrapper mt-2 individual-field">
              <div className="mb-2 label">
                <span className="create-labels">State</span>
              </div>
              <div className="input-ele">
                <Select
                  options={dropDownData?.pstate}
                  value={
                    sameAddressFlag
                      ? Array.isArray(selectedDropdownData?.currentstate) ? selectedDropdownData?.currentstate[0]?.value===null?[]:selectedDropdownData?.currentstate :null
                      : Array.isArray(selectedDropdownData?.permanentstate) ? selectedDropdownData?.permanentstate[0]?.value===null || selectedDropdownData?.permanentstate[0]?.value=== ""?[]:selectedDropdownData?.permanentstate :null
                  }
                  isSearchable={true}
                  onMenuOpen={getPermanentStateHandler}
                  placeholder="Select..."
                  onChange={(data) => {
                    handleDropdownChange([data], "permanentstate", [
                      "permanentcity",
                    ]);
                  }}
                  isDisabled={sameAddressFlag}
                />
              </div>
              {formik.errors.permanentstate && formik.touched.permanentstate ? (
                <div className="text-red-500 error_text md:mt-1">
                  {formik.errors.permanentstate}
                </div>
              ) : null}
            </div>
            <div className="field-wrapper mt-2 individual-field">
              <div className="mb-2 label">
                <span className="create-labels">City</span>
              </div>
              <div className="input-ele">
                <Select
                  options={dropDownData?.pcity}
                  value={
                    sameAddressFlag
                      ? Array.isArray(selectedDropdownData?.currentcity) ? selectedDropdownData?.currentcity[0]?.value===null?[]:selectedDropdownData?.currentcity :null
                      : Array.isArray(selectedDropdownData?.permanentcity) ? selectedDropdownData?.permanentcity[0]?.value===null || selectedDropdownData?.permanentcity[0]?.value=== ""?[]:selectedDropdownData?.permanentcity :null
                  }
                  isSearchable={true}
                  onMenuOpen={getPermanentCityHandler}
                  placeholder="Select..."
                  onChange={(data) => {
                    handleDropdownChange([data], "permanentcity");
                  }}
                  isDisabled={sameAddressFlag}
                />
              </div>
              {formik.errors.permanentcity && formik.touched.permanentcity ? (
                <div className="text-red-500 error_text md:mt-1">
                  {formik.errors.permanentcity}
                </div>
              ) : null}
            </div>
            <div className="msg-field col-span-2">
              <div className="form-group text-left mb-0">
                <div className="mb-2 label">
                  <span className="create-labels">Address</span>
                </div>
                <TextareaAutosize
                  className={`${
                    sameAddressFlag ? "opacity-50" : ""
                  } form-control shadow-none input-ele textarea_contact`}
                  name="permanentaddress"
                  cols="40"
                  rows="5"
                  value={formik.values.permanentaddress}
                  onBlur={formik.handleBlur}
                  onKeyUp={(e) =>
                    setdescCount2(
                      sameAddressFlag
                        ? formik.values.currentaddress.length
                        : formik.values.permanentaddress.length
                    )
                  }
                  onChange={formik.handleChange}
                  style={{
                    border: inputBorder,
                    resize: "none",
                    height: "8rem",
                  }}
                  maxLength="500"
                  disabled={sameAddressFlag}
                ></TextareaAutosize>
                <div className="mt-1 mr-1 text-sm text-gray-500 text-right">
                  {sameAddressFlag ? descCount : descCount2}/500
                </div>
                {formik.errors.permanentaddress &&
                formik.touched.permanentaddress ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {sameAddressFlag
                      ? formik.errors.permanentAddress
                      : formik.errors.permanentaddress}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mt-2 individual-field col-span-1">
              <div className="mb-2 label">
                <span className="create-labels">Pincode</span>
              </div>
              <div className="popins-medium input-ele">
                <TextField
                  error={
                    formik.errors.permanentpincode &&
                    formik.touched.permanentpincode
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onClick={() => {}}
                  type="text"
                  name="permanentpincode"
                  id="permanentpincode"
                  inputProps={{
                    className: cx(
                      `!text-xs open-sans-regular boxedInput popins-medium`
                    ),
                    style: {
                      padding: "9.5px 14px",
                      height: "0.43em !important",
                      fontSize: "12px",
                      fontFamily: "Poppins-Medium",
                      color: "#525252",
                    },
                  }}
                  autoComplete="off"
                  placeholder="Enter your pincode"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={
                    formik.values.permanentpincode
                      ? formik.values.permanentpincode
                      : ""
                  }
                  disabled={sameAddressFlag}
                />
              </div>
              {formik.errors.permanentpincode &&
              formik.touched.permanentpincode ? (
                <div className="text-red-500 error_text md:mt-1">
                  {sameAddressFlag
                    ? formik.errors.currentpincode
                    : formik.errors.permanentpincode}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="details-form-footer">
          <button
            type="submit"
            className="datatrimmer submity-btn"
            onClick={() => {}}
            title={""}
          >
            Next
          </button>
          {props.completeProfileData ?
            <button
            className="cursor-not-allowed cancel-btn mr-4"
             disabled="true"
              style={{backgroundColor: "#f2f2f2"}}
             onClick={() => {
               props.closeScreen();
             }}
             title="Please enter the details"
           >
             Cancel
           </button>  :
             <button
             className="datatrimmer cancel-btn mr-4"
             onClick={() => {
               props.closeScreen();
             }}
             title="Cancel"
            >
             Cancel
           </button> 
         }
        </div>
      </form>
    </div>
  );
};

export default PersonalDetailsForm;
