import { BorderAll } from "@material-ui/icons";
import React ,{useState} from "react";
import Modal from "react-modal";
import CloseIcon from '@mui/icons-material/Close';

function ResumePreviewModal(props){
  console.log("ResumePreviewModal",props?.path+"/#toolbar=0&navpanes=0")
    const [openModal, setOpenModal] = useState(true);
    let path=props?.path+"#toolbar=0&navpanes=0"
    const customStylesPage = {
        overlay: {
          background: "rgba(90, 90, 90,0.5)",
        },
        content: {
          position: "fixed",
          top: "50%",
          left: "54%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          height: "93%",
          background: "#fbfcfd 0% 0% no-repeat padding-box",
          boxShadow: "0px 0px 11px #000000a1",
          borderTopLeftRadius: "4px",
          opacity: "1",
          outline: "none",
        },
      };
    return(
        <Modal
        isOpen={openModal}
        style={customStylesPage}
        >
            <div className="grid grid-cols-12">
                <div className="grid grid-cols-12 col-span-12">
                  <div className="col-span-11">Preview</div>
                  <div className="col-span-1 flex justify-end" onClick={()=>{props?.closeModal(false)}}>
                    <CloseIcon/>
                  </div>

                </div>
               <div className="col-span-12">
                <iframe src={path} width="770px" height="525px">

                </iframe>

               

               
               </div>
            </div>

        </Modal>

    )
}
export default ResumePreviewModal;