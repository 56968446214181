import React from "react";
import { ReactComponent as EducationIcon } from "../../../assets/education.svg";
import { ReactComponent as JobIcon } from "../../../assets/job.svg";
import { ReactComponent as LocationIcon } from "../../../assets/location.svg";
import { ReactComponent as LanguageIcon } from "../../../assets/language.svg";
import { ReactComponent as VideoIcon } from "../../../assets/video.svg";
import "./CandidateCard.scss";

const CandidateCard = (props) => {
  return (
    <div className={`${props.alignmentclass} card-design`}>
      <div className="flex items-center">
        <span className="profile-icon">
          {props.candidate.profile.slice(0, 2)}
        </span>
        <span className="profile-title">
          <div className="name">{props.candidate.profile}</div>
          <div className="rating">Rating {props.candidate.rating}</div>
        </span>
        <span className="experiance flex items-center">
          <JobIcon className="inline-block jobIcon" /> Fresher
        </span>
      </div>
      <div className="skills-edu">
        <span className="mr-10">
          <LanguageIcon className="inline-block mr-1 languageicon" />
          Speaks Fluent English
        </span>
        <span>
          <EducationIcon className="inline-block" height={"23px"} width={"23px"} /> Degree
        </span>
        <span>
          <VideoIcon className="inline-block ml-auto float-right"/>
        </span>
      </div>
      <div className="skills">Skills:{props.candidate.skills}</div>
      <div className="candidate-footer flex items-center">
        <span className="inline-block">
          <LocationIcon className="locationIcon" height={"23px"} />
        </span>
        <span className="location">{props.candidate.location}</span>
        <div className="hire-now-btn ml-auto">
          {!props.hideBtn && <button className="datatrimmer" title={props.btnName?props.btnName:"Hire Now"} onClick={() => {props.handleCandidateBtn(props.btnName?props.btnName:"Hire Now",props.candidate)}}>{props.btnName?props.btnName:"Hire Now"}</button>}
          {props.hideBtn && props.footerStatus ? props.footerStatus:null}
        </div>
      </div>
    </div>
  );
};

export default CandidateCard;
