import React, { useState, useRef, useEffect } from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import CrossIcon from "../../website-assets/crossIcon.png";
import { candidateSignUp, clientSignUp } from "../../../../api/api-services";
import { ToastContainer, toast } from "react-toastify";
import "./OtpVerificationForm.scss"
import GreenTickIcon from "../../website-assets/greenTickIcon.png"
import RedCrossIcon from "../../website-assets/redCrossIcon.png"



const REACT_APP_FIREBASE_APIKEY = "AIzaSyB_Dp4yXFfHxL19W_CEcl3XkDcuBrvYNyU";
const REACT_APP_FIREBASE_AUTHDOMAIN = "qspiders-hrm.firebaseapp.com";
const REACT_APP_FIREBASE_DATABASEURL =
  "https://qspiders-hrm-default-rtdb.firebaseio.com/";
const REACT_APP_FIREBASE_PROJECTID = "qspiders-hrm";
const REACT_APP_FIREBASE_STORAEBUCKET = "qspiders-hrm.appspot.com";
const REACT_APP_FIREBASE_MESSAINSENDERID = "137363204409";
const REACT_APP_MANAGEMENT_APPID = "1:137363204409:web:fe668c2114d87e301be0e8";
const REACT_APP_MANAGEMENT_MEASUREMENTID = "G-C87FGBT0X3";

const OtpVerificationForm = (props) => {
  
  
  const mobileNumber = props.userInfo?.mobileNumber || "";
  const signupMode = props.userInfo?.mode;
  console.log(props);
  const [otpValue, setOtpValue] = useState([]);
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const [showError, setShowError] = useState(false);
  const [resendToast, setResendToast] = useState(false);
  const [error, setError] = useState("");
  const firstInputRef = useRef(null);
  const [mobileNo, setMobileNo] = useState(mobileNumber);
  const [storeSuccessMsg, setStoreSuccessMsg] = useState("");
  const [storeErrorMsg, setStoreErrorMsg] = useState("");
 
  function getDecodedPhoneNumber(phoneNumber) {
    let decodedPhoneNumber = atob(phoneNumber).includes("+")
      ? atob(phoneNumber).slice(1)
      : atob(phoneNumber);
    return decodedPhoneNumber;
  }
  const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_APIKEY,
    authDomain: REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: REACT_APP_FIREBASE_DATABASEURL,
    projectId: REACT_APP_FIREBASE_PROJECTID,
    storageBucket: REACT_APP_FIREBASE_STORAEBUCKET,
    messagingSenderId: REACT_APP_FIREBASE_MESSAINSENDERID,
    appId: REACT_APP_MANAGEMENT_APPID,
    measurementId: REACT_APP_MANAGEMENT_MEASUREMENTID,
  };

  useEffect(() => {
    firebase.initializeApp(firebaseConfig);
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
    window.recaptchaVerifier.render();
    const search = window.location.search;
    // const userId = new URLSearchParams(search).get("userid");
    // const privilege = new URLSearchParams(search).get("privilege");
    // const token = new URLSearchParams(search).get("token");
    // const phone = getDecodedPhoneNumber(new URLSearchParams(search).get("phone"));
    //  const phone="918895889550"
    //   setMobileNo("+" + phone);
    // sessionStorage.setItem("userid", userId);
  }, []);

  function sendOTP() {
    const appVerifier = window.recaptchaVerifier;
  
    const settings = {
      codeLength: 4,
    };
  
    firebase
      .auth()
      .signInWithPhoneNumber(mobileNo, appVerifier, settings)
      .then((result) => {
        window.confirmationResult = result;
        console.log(result, "res");
        // Move the toast message here
        // toast.success(
        //   <>
        //     <div className="toast-heading">Success</div>
        //     <div>OTP sent successfully</div>
        //   </>
        // );
        setStoreSuccessMsg("OTP sent to the registered contact number successfully");
        setTimeout(() => {
          setStoreSuccessMsg(""); // Clear the message after 5 seconds
        }, 5000);
      })
      .catch((error) => {
        if (error) {
          setResendToast(false);
          setStoreErrorMsg("OTP quota exceeded. Try later");
          setTimeout(() => {
            setStoreErrorMsg(""); // Clear the message after 5 seconds
          }, 5000);
          // toast.error(
          //   <>
          //     <div className="toast-heading">Alert</div>
          //     <div>OTP quota exceeded. Try later</div>
          //   </>
          // );
        }
        console.error(error);
      });
  }
  
  useEffect(() => {
    if (mobileNo) {
      const search = window.location.search;
      // const userId = new URLSearchParams(search).get("userid");
      // sessionStorage.setItem("userid", userId);
      const phone = getDecodedPhoneNumber(
        new URLSearchParams(search).get("phone")
      );

      sendOTP();
    }
  }, [mobileNo]);

  useEffect(() => {
    firstInputRef.current.focus();
  }, []);

  function registerKeypress() {
    const inputs = document.querySelectorAll("#otp > *[id]");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener("keydown", function (event) {
        if (event.key === "Backspace") {
          let idx = event.currentTarget.id;
          idx = Number(idx.split("input")[1]);

          if (inputs[idx - 1] === undefined || inputs[idx - 1].value < 10) {
            if (idx - 1 !== 0) {
              inputs[idx - 2].focus();
              inputs[idx - 1].value = "";
              // inputs[i].value = "";
              otpValue[i] = inputs[i].value;
              setOtpValue(otpValue);

              event.preventDefault();
            } else if (idx - 1 == 0) {
              inputs[idx - 1].focus();
              inputs[idx - 1].value = "";
              // inputs[i].value = "";
              otpValue[i] = inputs[i].value;
              setOtpValue(otpValue);

              event.preventDefault();
            }
          } else if (inputs[i - 1] !== "" && i === inputs.length - 1) {
            inputs[i - 1].value = "";
            if (i !== 0) inputs[i - 1].focus();
          }
        } else {
          if (
            i === inputs.length - 1 &&
            inputs[i].value !== "" &&
            !event.shiftKey
          ) {
            return true;
          } else if (
            event.keyCode > 47 &&
            event.keyCode < 58 &&
            !event.shiftKey
          ) {
            inputs[i].value = event.key;
            otpValue[i] = inputs[i].value;
            setOtpValue(otpValue);

            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          } else if (
            (event.keyCode > 64 && event.keyCode < 91) ||
            event.keyCode == 190 ||
            event.keyCode == 188 ||
            event.keyCode == 191 ||
            event.keyCode == 186 ||
            event.keyCode == 222 ||
            event.keyCode == 219 ||
            event.keyCode == 221 ||
            event.keyCode == 220 ||
            event.keyCode == 189 ||
            event.keyCode == 187 ||
            event.keyCode == 219 ||
            event.keyCode == 221 ||
            event.keyCode == 192 ||
            event.keyCode == 32 ||
            event.shiftKey
          ) {
            event.preventDefault();
            return false;
          }
        }
      });
    }
  }

  registerKeypress();
    function handleKeyPress(event, index) {
        let key = event.keyCode || event.charCode;
    
        if (key === 8 || key === 46) return false;
        else {
          let data = [...otpValue];
    
          // Restrict the input to only one digit
          if (event.target.value.length > 1) {
            event.target.value = event.target.value.slice(0, 1);
          }
    
          data[index] = event.target.value;
    
          let ob = event.target;
    
          setOtpValue(data);
    
          if (ob.nextElementSibling !== null) {
            ob.nextElementSibling.focus();
          }
        }
      }
    
      console.log(signupMode, props.registrationData, props, "outside");
    
      async function handleRegistrationAndNavigation(signupMode, registrationData) {
        console.log(signupMode, props.registrationData, props);
        try {
          let response;
    
          if (signupMode === "StudentRegistration") {
            response = await candidateSignUp(registrationData);
          }
          //  else if (signupMode === "ClientRegistration") {
          //   response = await clientSignUp(registrationData);
          // }
          else if (signupMode === "ClientRegistration") {
            if (registrationData.companyName === "Others") {
              registrationData.companyName = registrationData.otherCompanyName;
            }
            delete registrationData.otherCompanyName;
    
            if (registrationData.jobTitle === "Others") {
              registrationData.jobTitle = registrationData.jobTitleOthers;
            }
            delete registrationData.jobTitleOthers;
    
            response = await clientSignUp(registrationData);
          }
          console.log(response, "response");
          if (response?.data?.statusCode === 201) {
            // toast.success(
            //   <>
            //     <div className="toast-heading">Success</div>
            //     <div>Account created successfully</div>
            //   </>
            // );
            setTimeout(() => {
            //   navigate("/login");
            props.setShouldSelectLoginTabInOtpPage(true)
            }, 3000);
            setStoreSuccessMsg("Account created successfully")
            
            
          } else {
            console.log(error,"res",response);
            // Show error message
            // toast.error(
            //   <>
            //     <div className="toast-heading">Alert</div>
            //     <div>{response?.response.data["Error 1"]}</div>
            //   </>
            // );
            setStoreErrorMsg(response?.response.data["Error 1"])
            setTimeout(() => {
              setStoreErrorMsg(""); // Clear the message after 5 seconds
            }, 5000);
          }
        } catch (error) {
          console.error(error);
        }
      }
      function verifyOTP(event) {
        if (createUpdateCalled) {
          setCreateUpdateCalled(false);
          let data = [...otpValue];
    
          const result = data.filter((word) => word !== "");
          const isEmpty = otpValue.some(
            (value) => value === undefined || value === ""
          );
    
          console.log(data);
          let a = document.getElementById("errorId");
          if (result.length === 0 || isEmpty || data.includes(undefined)) {
            setError("Verification code is required");
          } else if (result.length < 4) {
            setError("Please enter a valid 4-digit verification code");
          } else {
            a.innerHTML = "";
    
            // Get the entered OTP
            const enteredOTP = result.slice(0, 6).join("");
    
            setShowError(false);
            window.confirmationResult
              ?.confirm(enteredOTP)
              .then((result) => {
                // Correct OTP
                handleRegistrationAndNavigation(signupMode, props.registrationData);
                // activateEmailandlogin();
              })
              .catch((error) => {
                // Invalid OTP
                // toast.error(
                //   <>
                //     <div className="toast-heading">Alert</div>
                //     <div>Invalid OTP! Please enter a valid OTP.</div>
                //   </>
                // );
                setStoreErrorMsg("Invalid OTP! Please enter a valid OTP.")
                setTimeout(() => {
                  setStoreErrorMsg(""); // Clear the message after 5 seconds
                }, 5000);
              });
          }
        }
      }
  return (
    <div className="otpVerificationModal">
    <div className="successMsg ">
    {
      storeSuccessMsg &&  storeSuccessMsg?.length>1 ? <><div className="flex justify-center align-middle relative  mt-4"> {storeSuccessMsg && storeSuccessMsg?.length>1 &&  <img src={GreenTickIcon} style={{height:"30px"}}/>}</div>
  
  <p className="storeSuccessMsg">{storeSuccessMsg}</p></> :<><div className="flex justify-center align-middle relative  mt-4"> {storeErrorMsg && storeErrorMsg?.length>1 &&  <img src={RedCrossIcon} style={{height:"30px"}}/>}</div>
  
  <p className="storeErrorMsg">{storeErrorMsg}</p></>
    }
    

    </div>
    <div
                  className="cursor-pointer"
                  onClick={() => {
                    props.setIsOpenLoginModal(false);
                  }}
                >
                  <img
                    src={CrossIcon}
                    className="  top-7  absolute"
                    style={{ height: "20px",right:"31px" }}
                  />
                </div>
    <p className="otpHeader">Verification code</p>
    <p className="otpMsg">Please enter the code sent on Mobile number to ******{mobileNo.slice(-4)} if the number is incorrect  <br/> <span onClick={() => {
                
                props.handelOpenOpenOtpModal(false)
                }}>click here</span>  to change it</p>
    <div className='otpContainer'>
    <div className="contentSection">
          <div id="recaptcha-container" className=" hidden"></div>

          <div id="otpText" className="otpText mt-16">
            <div id="otp" className="otp_number_field">
              {[1, 2, 3, 4, 5, 6].map((item, i) => (
                <React.Fragment key={item} className="inputTag">
                  <input
                    ref={i === 0 ? firstInputRef : null}
                    id={`input${item}`}
                    autoComplete="off"
                    onChange={(e) => {
                      handleKeyPress(e, i);
                      e.target.value = e.target.value.replace(/\D/g, "");
                    }}
                    onKeyUp={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, "");
                    }}
                    className="m-2 border otpBox text-center"
                    type="number"
                    maxLength="1"
                    //                     onBlur={(e) => {
                    //   setCreateUpdateCalled(true);
                    //   const inputValue = e.target.value;
                    //   console.log(inputValue)
                    //   setError(inputValue !== "" ? "" : "Verification code is required");
                    // }}
                    onBlur={(e) => {
                      setCreateUpdateCalled(true);
                      const inputValue = e.target.value;
                      if (inputValue !== "") {
                        setError("");
                      }
                    }}
                  />
                </React.Fragment>
              ))}
            </div>
            {/* {showError && (
              <span className="verification_err_msg">{errorContent}</span>
            )} */}
            {error && <span className="verification_err_msg">{error}</span>}
            <span id="errorId" className="verification_err_msg"></span>
            <div className="mt-5 resendOtp relative ">
              <p
                
                
              >
               Haven't received your code yet?<span onClick={(e) => {
                  setResendToast(true)
                  e.preventDefault();
                  sendOTP();
                
                  const inputs = document.querySelectorAll("#otp > *[id]");
                  inputs.forEach((input) => {
                    input.value = ""; // Clear the input field value
                  });
                }} className=" mx-0.5 cursor-pointer"> Resend a new code</span>
              </p>
              <button type="button" onClick={verifyOTP}>
                Submit
              </button>
            </div>
           
            {/* <div className="changePh">
              <p
                onClick={() => {
                //   signupMode === "StudentRegistration"
                //      ?props.handelOpenOpenOtpModal(false)
                //     :props.handelTabs(0)
                props.handelOpenOpenOtpModal(false)
                }}
              >
                Change phone number
              </p>
            </div> */}
          </div>
        </div>

    </div>
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default OtpVerificationForm