import React, { useEffect, useState } from "react";
import "./InterviewModals.scss";
import InterviewListModal from "../interview-list-modal/InterviewListModal";
import { getCandidateInterviewReq, getClientInterviewReq } from "../../../api/api-services";

const InterviewCandidModals = ({ clientData, candidateData }) => {
  const [openInterviewListModal, setOpenInterviewListModal] = useState(false);
  const [interviewlist, setInterviewList] = useState([]);
  const genarateSkills = (skillset = []) => {
    let allSkills = "";
    skillset.map((skill, index) => {
      if (index === 0) {
        allSkills = skill.skillName;
      } else {
        allSkills = allSkills + "," + skill.skillName;
      }
    });
    return allSkills;
  };
  const closeModal = (data)=>{
    setOpenInterviewListModal(false);
  }
  const handelInterviewList = async(type) => {
    let response;
    if (type === "Client") {
      try{
        response = await getClientInterviewReq(clientData.id)
        if(response.data || response.status === 200 || response.data.response.length){
          let interviews =[];
          response.data.response.map(inter=>{
            if(inter.scheduledInterviews.length){
              interviews.push(...inter.scheduledInterviews)
            }
          })
          setInterviewList(interviews)
        }
      }catch(err){
        console.error(err,"client interview list api error");
      }
      setOpenInterviewListModal(true);
    } else if (type === "Candidate") {
      try{
        response = await getCandidateInterviewReq(candidateData.id)
        if(response.data || response.status === 200 || response.data.response.length){
          let interviews =[];
          response.data.response.map(inter=>{
            if(inter.scheduledInterviews.length){
              interviews.push(...inter.scheduledInterviews)
            }
          })
          setInterviewList(interviews)
        }
      }catch(err){
        console.error(err,"client interview list api error");
      }
      setOpenInterviewListModal(true);
    }
  };
  return (
    <>
      <div className="client-details">
        <div className="flex items-center profile-title-container">
          <span className="profile-icon">{clientData?.name?.slice(0, 2)}</span>
          <span className="profile-title">
            <div className="name">
              {clientData?.name}{" "}
              <span className="user-type-styles">- Employer</span>
            </div>
            {/* <div className="rating">Rating : 
            {candidateData?.rating}
            </div> */}
          </span>
          <button onClick={()=>{handelInterviewList("Client")}} className="ml-auto display-inter-list-btn">
            View Scheduled Interviews List
          </button>
        </div>
        <div className="client-details-field">
          <div className="">
            <span className="field-lable">Email </span>
            <span className="field-data">
              : {` `}
              {clientData?.email}
            </span>
          </div>
          <div className="">
            <span className="field-lable">Mobile </span>
            <span className="field-data">
              : {` `}
              {clientData?.mobile}
            </span>
          </div>
          <div className="">
            <span className="field-lable">Company </span>
            <span className="field-data">
              : {` `}
              {clientData?.companyName}
            </span>
          </div>
          <div className="">
            <span className="field-lable">JobTitle </span>
            <span className="field-data">
              : {` `}
              {clientData?.jobTitle}
            </span>
          </div>
        </div>
      </div>
      <div className="candidate-details">
        <div className="flex items-center profile-title-container">
          <span className="profile-icon">
            {candidateData?.personalDetails?.name?.slice(0, 2)}
          </span>
          <span className="profile-title">
            <div className="name">
              {candidateData?.personalDetails?.name}{" "}
              <span className="user-type-styles">- Jobseeker</span>
            </div>
            <div className="rating">Rating : {candidateData?.rating}</div>
          </span>
          <button onClick={()=>{handelInterviewList("Candidate")}} className="ml-auto display-inter-list-btn -mt-6">
            View Scheduled Interviews List
          </button>
        </div>
        <div className="candidate-details-fields">
          <div className="">
            <span className="field-lable">Email </span>
            <span className="field-data">
              : {` `}
              {candidateData?.personalDetails?.email}
            </span>
          </div>
          <div className="">
            <span className="field-lable">Mobile </span>
            <span className="field-data">
              : {` `}
              {candidateData?.personalDetails?.mobile}
            </span>
          </div>
          <div className="">
            <span className="field-lable">Highest Education </span>
            <span className="field-data">
              : {` `}
              {candidateData?.educationDetails?.highestEducation}
            </span>
          </div>
          <div className="">
            <span className="field-lable">Skill Set : </span>
            <span className="field-data">
              : {` `}
              {genarateSkills(candidateData?.technicalDetails?.skills)}
            </span>
          </div>
        </div>
        {openInterviewListModal && <InterviewListModal header={"Interview List"} closeModal={closeModal} tableSearch={true} interviewlist={interviewlist} />}
      </div>
    </>
  );
};

export default InterviewCandidModals;
