import React, { useState } from 'react'
import './AllCategories.scss'
import NavigationHeader from '../nav-section/NavigationHeader'
import FooterSectionHome from '../footer-section/FooterSectionHome'
import { useNavigate } from "react-router-dom";
import Itlogo from '../../../assets/categoryLogos/ItCategory.svg'
import technologylogo from '../../../assets/categoryLogos/technologylogo.svg'
import healthandcarelogo from '../../../assets/categoryLogos/health&care.svg'
import transportRealestatelogo from '../../../assets/categoryLogos/transportandrealestate.svg'
import manifacturelogo from '../../../assets/categoryLogos/manifacturelogo.svg'
import bfsilogo from '../../../assets/categoryLogos/bfsiLogo.svg'
import bmplogo from '../../../assets/categoryLogos/bmpLogo.svg'
import retaillogo from '../../../assets/categoryLogos/retailAndHospetality.svg'
import professionalServicelogo from '../../../assets/categoryLogos/professionalServicelogo.svg'
import medialogo from '../../../assets/categoryLogos/medialogo.svg'
import remotelogo from '../../../assets/categoryLogos/remoteLogo.svg'
import mnclogo from '../../../assets/categoryLogos/MNClogo.svg'
import fresherlogo from '../../../assets/categoryLogos/fresherlogo.svg'
import supplyChainlogo from '../../../assets/categoryLogos/supplyChain.svg'
import analyticslogo from '../../../assets/categoryLogos/analyticslogo.svg'
import internlogo from '../../../assets/categoryLogos/internLogo.svg'
import engineerlogo from '../../../assets/categoryLogos/engineerlogo.svg'
import productManagelogo from '../../../assets/categoryLogos/projectManagelogo.svg'
import bankinglogo from '../../../assets/categoryLogos/bankingLogo.svg'
import dataSciencelogo from '../../../assets/categoryLogos/dataSciencelogo.svg'



import { getAllCategories } from '../../../api/api-services'
import { useEffect } from 'react'


function AllCategories() {

    const [dropDownData, setDropDownData] = useState([])
    const [categoryValue, setCategoryValue] = useState([]);
    const navigate = useNavigate();
    const getIndustries = async () => {
        try {
            const res = await getAllCategories();
            if (res?.data) {
                setDropDownData(res.data.response)
            } else {
                console.log(res, "res")
            }
        } catch (e) {
            console.log("countires error", e);
        }
    };
    const categoryCard = () => {
        const newArray = [];
        for (const key in dropDownData) {
            if (dropDownData.hasOwnProperty(key)) {
                newArray.push({
                    "industry": key,
                    "count": dropDownData[key],
                });
            }
        }
        console.log(newArray, "newArraynewArray")

        const handleSearchClick = (event, industry) => {
            console.log("Clicked on industry:", industry);

            // Check if there is a selected value in the dropdown
            navigate("/job-search", {
                state: {
                    category: [{ label: `${industry}`, value: `${industry}` }],
                    searchValue: ""
                },
            });
        };

        function toCamelCase(inputString) {
            return inputString.replace(/\w\S*/g, (word) => {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            });
        }

        return newArray.map((element) => {
            const industryLogos = {
                "IT": Itlogo,
                "TECHNOLOGY": technologylogo,
                "HEALTHCARE & LIFESCIENCES": healthandcarelogo,
                "INFRASTRUCTURE, TRANSPORT & REALESTATE": transportRealestatelogo,
                "MANUFACTURING & PRODUCTION": manifacturelogo,
                "BFSI": bfsilogo,
                "BPM": bmplogo,
                "CONSUMER, RETAIL & HOSPITALITY": retaillogo,
                "PROFESSIONALSERVICES": professionalServicelogo,
                "MEDIA, ENTERTAINMENT & TELECOM": medialogo,
                "ENTERTAINMENT & TELECOM": medialogo,
                "REMOTE": remotelogo,
                "MNC": mnclogo,
                "FRESHER": fresherlogo,
                "SUPPLYCHAIN": supplyChainlogo,
                "ANALYTICS": analyticslogo,
                "INTERNSHIP": internlogo,
                "ENGINEERING": engineerlogo,
                "PROJECT MANAGEMENT": productManagelogo,
                "BANKING & FINANCE": bankinglogo,
                "HR": fresherlogo,
                "SALES":Itlogo,
                "DATASCIENCE": dataSciencelogo,
            };

            const selectLogo = (industry) => industryLogos[industry.toUpperCase()] || Itlogo;
            console.log(element.industry, "element.industry")
            function formatString(inputString) {
                return inputString.replace(/_/g, ' ').replace(/\bAND\b/g, '&');
            }
            return (
                // <div className='col-2 m-4'>
                //     <div className='categoryCard row' onClick={(e) => {
                //         console.log(e, "event")
                //         handleSearchClick(element.industry)
                //     }}
                //         style={{ marginLeft: "35px", marginRight: "-17px" }}>
                //         <div className='cardLogo col-5'>
                //             <>
                //                 <img style={{ marginTop: "11px", marginLeft: "1px" }} src={selectLogo(element.industry)}></img>
                //             </>
                //         </div>
                //         <div className='cardHeadder col-12'>
                //             <span>{formatString(element.industry)}</span>
                //         </div>
                //         <div className='cardSubTitle'>
                //             {element.count} jobs
                //         </div>
                //     </div>
                // </div>
                <div onClick={(e) => {
                    handleSearchClick(e, element.industry);
                }} className='m-4 cursor-pointer'>
                    <div className='catCardContainer'>
                        <div className='logocontainer' >
                            <div className='cardLogo'>
                                <img src={selectLogo(element.industry)}></img>
                            </div>
                        </div>
                        <div className='flex cardHeadder justify-content-center mt-3'>
                            <span className='cardTitle'>{toCamelCase(formatString(element.industry))}</span>
                        </div>
                        <span style={{ marginTop: "14px", paddingBottom: "10px" }} className='cardSubTitle'>
                            {element.count} jobs
                        </span>
                    </div>

                </div>
            )
        })
    }

    useEffect(() => {
        console.log("hello")
        getIndustries();
    }, [])


    return (
        <div>
            <div className='navbar-category'>
                <NavigationHeader />
            </div>
            <div className='headContainer'>
                <div className='aligen'>
                    <p className='subtitle'>
                        Job Categories
                    </p>
                    <p className='header'>
                        Find Jobs by Categories
                    </p>
                </div>
            </div>
            <div className='cardsholderDiv'>
                {categoryCard()}
            </div>

            <FooterSectionHome />
        </div>
    )
}

export default AllCategories