import { useState, useEffect, useRef, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import "./InterViewCoordinatorLeftMenu.scss";
import { useNavigate, useLocation } from "react-router-dom";
// import CasesBox from "../../assets/cases_box.svg";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BugReportIcon from "@mui/icons-material/BugReport";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { ReactComponent as Candidate } from "../../../assets/candidate.svg";
import { ReactComponent as Dashboard } from "../../../assets/dashboard.svg";
import { ReactComponent as ProgressIcon } from "../../../assets/progress.svg";
import { ReactComponent as ConfigIcon } from "../../../assets/configIcon.svg";
import  { ReactComponent as InterviewerIcon } from "../../../assets/interviewerIcon.svg"
import Diversity3Icon from '@mui/icons-material/Diversity3';

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { Tooltip } from "@material-ui/core";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRight: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  zIndex: 0,
  top: "61px",
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "55px",
  borderRight: "none",
  [theme.breakpoints.up("sm")]: {
    // width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
function InterViewCoordinatorLeftMenu(props) {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(true);
  const [configMenu, setConfigMenu] = useState(false);
  const [inconfigMenu, setInconfigMenu] = useState(false);
  let getSideMenuIcon = (type) => {
    if (type === "Candidates") {
      return <Candidate height={"23px"} width={"23px"} />;
    } 
    else if (type === "Job Post") {
      return <Diversity3Icon/> 
    } else if (type === "Configuration") {
      return <ConfigIcon height={"23px"} width={"23px"} />;
    } else if (type === "Dashboard") {
      return <Dashboard height={"23px"} width={"23px"} />;
    } else if (type === "Interviewer") {
      return <InterviewerIcon height={"23px"} width={"23px"} />;
    }
  };
  const [leftMenuItems, setLeftMenuItems] = useState([
    {
      name: "Dashboard",
      path: "/coordinator/dashboard",
      icon: getSideMenuIcon("Dashboard"),
    },
    {
      name: "Interviewer",
      path: "/coordinator/interviewer",
      icon: getSideMenuIcon("Interviewer"),
    },
    {
      name: "Candidates",
      path: "/coordinator/candidates",
      icon: getSideMenuIcon("Candidates"),
    },
    {
      name: "Job Posts",
      path: "/coordinator/job-post",
      icon: getSideMenuIcon("Job Post"),
    },
    {
      name: "Configuration",
      path: "/coordinator/configuration",
      icon: getSideMenuIcon("Configuration"),
    },
  ]);

  useEffect(() => {
    if (location.pathname.includes("/project/config")) {
      setInconfigMenu(true);
    } else {
      if (configMenu) {
        setConfigMenu(false);
      }
      setInconfigMenu(false);
    }
  }, [location.pathname]);

  let menuRef = useRef();
  useEffect(() => {
    let handeler = (event) => {
      if (menuRef.current && !menuRef?.current?.contains(event.target)) {
        setShowSideMenu(false);
        setConfigMenu(false);
        // setInconfigMenu(false);
        setShowConfigSubMenu(false);
      }
    };
    document.addEventListener("mousedown", handeler);
    return () => {
      document.removeEventListener("mouseleave", handeler);
    };
  }, []);

  const [showConfigSubMenu, setShowConfigSubMenu] = useState(false);

  const handleMouseLeaveConfig_sub = () => {
    setShowConfigSubMenu(false);
  };
  return (
    <div ref={menuRef}>
      <Box sx={{ display: "flex" }}>
        <Drawer variant="permanent" open={open}>
          <List>
            <div className="left-menu">
              {leftMenuItems.map((data, index) => {
                return (
                  <div
                    className="my-6  flex allProjectDrawer"
                    key={data.name + index}
                  >
                    <NavLink
                      className={({ isActive }) =>
                        (isActive ||
                          (location.pathname === "/coordinator" &&
                            data.name === "Interviewer")) &&
                        !configMenu &&
                        open
                          ? "active-drawer-close left-menu-items-select item-hover-effect -ml-1  focus:outline-none"
                          : (isActive ||
                              (location.pathname === "/coordinator" &&
                                data.name === "Interviewer")) &&
                            !configMenu &&
                            !open
                          ? "text-blue-700 -ml-1 active-drawer-close2 item-hover-effect  focus:outline-none"
                          : "flex ml-3 left-menu-items-unselect  item-hover-effect focus:outline-none"
                      }
                      to={data.path}
                    >
                      {data.icon}
                      <Tooltip title={data.name}>
                        <div className="flex overview_icon datatrimmer">
                          <span className="ml-2">{open ? data.name : ""}</span>
                        </div>
                      </Tooltip>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </List>
          <Divider />
        </Drawer>
      </Box>
    </div>
  );
}

export default InterViewCoordinatorLeftMenu;
