import React, { useEffect, useState } from "react";
import { useTable, useGlobalFilter } from "react-table";
import { Checkbox } from "@material-ui/core";
import { ReactComponent as FilterIcon } from "../../../assets/filterIcon.svg";
import { ReactComponent as GridIcon } from "../../../assets/gridIcon.svg";
import { ReactComponent as GridSelectedIcon } from "../../../assets/gridSelectedIcon.svg";
import { ReactComponent as ListIcon } from "../../../assets/listIcon.svg";
import { ReactComponent as ListSelectedIcon } from "../../../assets/listSelectedIcon.svg";
import { ReactComponent as EducationIcon } from "../../../assets/education.svg";
import { ReactComponent as VideoIcon } from "../../../assets/video.svg";
import "./InterviewerList.scss";
import CandidateCard from "../../common/candidate-card/CandidateCard";
import CandidateTable from "../../common/candidate-table/CandidateTable";
import InterviewListModal from "../modals/InterviewListModal";
import CandidateColumnOption from "../../common/CandidateColumnOption";
import {
  getAllClientCompany,
  getClientCompanyDetails,
  getActiveInterviewers,
  coordinatorInterviewerSearch,
} from "../../../api/api-services";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NoDataFound } from "../../../assets/noDataFound.svg";

const InterviewerList = (props) => {
  let [hireTableData, setHireTableData] = useState([]);
  let [openFilter, setOpenFilter] = useState(false);
  let [filterOptions, setFilterOptions] = useState([]);
  let [selectedFilters, setSelectedFilters] = useState({});
  let [listview, setListview] = useState(true);
  let [listModal, setListModal] = useState(false);
  const navigate = useNavigate();
  let [interviewerCount, setInterviewerCount] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  let actionele = (data) => {
    return (
      <div className="hire-now-btn">
        <VideoIcon className="inline-block mr-4" />
        <button
          className="datatrimmer"
          onClick={() => {
            handleBtnClick(data);
          }}
        >
          Interview List
        </button>
      </div>
    );
  };

  const fetchFilterOptions = () => {
    setFilterOptions([
      {
        type: "Location",
        values: ["Bangalore", "Mysore", "Shivamogga", "Goa", "Mangalore"],
      },
      {
        type: "Experiance",
        values: ["Fresher", "0 - 1 yrs", "1 - 3 yrs", "3 - 5 yrs", "5+ yrs"],
      },
    ]);
  };

  const handleFilterSelection = (e, type, value) => {
    let selectedData = { ...selectedFilters };
    if (e.target.checked) {
      if (selectedData?.[type]?.length > 0) {
        selectedData?.[type].push(value);
      } else {
        selectedData[type] = [value];
      }
    } else {
      if (selectedData?.[type]?.length > 0) {
        let index = selectedData?.[type].indexOf(value);
        selectedData?.[type].splice(index, 1);
      }
    }
    setSelectedFilters({ ...selectedData });
  };

  const closeModal = (val) => {
    if (listModal) {
      setListModal(false);
    }
  };
  const handleBtnClick = (data) => {
    setListModal(true);
  };

  let [hiddenColumnsData, setHiddenColumnsData] = useState([
    "email",
    "gender",
    "location",
    "highest_degree",
    "requested_clients",
    "shareable",
  ]);
  let handelColumnsDispaly = (e) => {
    let value = e.target.value;
    let data = [...hiddenColumnsData];
    if (data.includes(value) && !e.target.checked) {
      data.splice(data.indexOf(value), 1);
    } else if (e.target.checked) {
      data.push(value);
    }
    setHiddenColumnsData([...data]);
  };

  // useEffect(() => {
  //   let contextData = [];
  //   tableApiData?.columnDef.columns.map((data) => {
  //     contextData.push({
  //       option: data.columnName,
  //     });
  //   });
  //   setContextMenuData([...contextData]);
  //   setHireTableData([...list]);
  // }, []);
  const [isLoading, setIsLoading] = useState(true);
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let size = 10;

  const [listOfInterviewCoordinate, setListOfInterviewCoordinate] = useState(
    []
  );

  // let [tableApiData, setTableApiData] = useState({
  //   totalCount: 20,
  //   columnDef: {
  //     columns: [
  //       {
  //         columnName: "name",
  //         column_order: 1,
  //       },
  //       {
  //         columnName: "email",
  //         column_order: 2,
  //       },
  //       {
  //         columnName: "mobile",
  //         column_order: 3,
  //       },
  //       {
  //         columnName: "status",
  //         column_order: 4,
  //       },
  //     ],
  //   },
  //   content: [],
  //   lastPage: true,
  // });
  let [tableApiData, setTableApiData] = useState({
    columnDef: {
      columns: [],
    },
    content: [],
  });
  const fetchAllInterviewCoordinate = async (page) => {
    try {
      const response = await getActiveInterviewers(page, size);
      let tabledata = { ...tableApiData };
      setInterviewerCount(response.data.response.totalElements);
      setIsLoading(false);
      if (page === 0) {
        setListOfInterviewCoordinate([...response.data.response.content]);
      } else {
        setListOfInterviewCoordinate([
          ...listOfInterviewCoordinate,
          ...response.data.response.content,
        ]);
      }
      if (response?.data?.response?.last === false) {
        setpageNo(page + 1);
      }
      setLastpage(response.data.response.last);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchAllInterviewCoordinate(pageno);
    let contextData = [];
    tableApiData?.columnDef?.columns.map((data) => {
      contextData.push({
        option: data.columnName,
      });
    });
    setColumnData([...contextData]);
  }, []);

  const resetSearch = () => {
    setIsSearching(false);
    setpageNo(0);
    setLastpage(false);
    setHireTableData([]); // Clear existing search results
  };
  let handelSearch = async (e, page) => {
    try {
      setIsSearching(true); // Set the flag to indicate search
      setIsLoading(true);
      setSearchedText(e);
      const response = await coordinatorInterviewerSearch(e, page, size);
      let tabledata = { ...tableApiData };
      setInterviewerCount(response.data.response.totalElements);
      setIsLoading(false);
      if (page === 0) {
        setListOfInterviewCoordinate([...response.data.response.content]);
      } else {
        setListOfInterviewCoordinate([
          ...listOfInterviewCoordinate,
          ...response.data.response.content,
        ]);
      }
      if (response?.data?.response?.last === false) {
        setpageNo(page + 1);
      }
      setLastpage(response.data.response.last);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  // const handleScroll = (event) => {

  //   if (
  //     Math.ceil(event?.target?.scrollTop + event?.target?.clientHeight) ===
  //     event?.target?.scrollHeight
  //   ) {
  //     if (lastPage === false) {
  //       setIsLoading(true)
  //       fetchAllInterviewCoordinate(pageno)
  //     }
  //   }
  // };
  const handleScroll = (event) => {
    const target = event?.target;
    if (!target) return;

    const scrolledToBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >=
      target.scrollHeight - 1;

    if (scrolledToBottom && !lastPage && !isSearching) {
      setIsLoading(true);
      fetchAllInterviewCoordinate(pageno);
    } else if (scrolledToBottom && !lastPage && isSearching) {
      handelSearch(searchedText, pageno);
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Interviewer Name",
        accessor: "name",
        Cell: ({ value, row }) => (
          <div
            style={{ width: "200px" }} // Adjust the width as needed
            title={value}
          >
            {value?.length >= 22 ? value.substring(0, 22) + "..." : value}
          </div>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value, row }) => (
          <div
            onClick={(event) => {
              console.log(row);
            }}
            title={value}
            className="  datatrimmer"
            style={{ width: "200px" }} // Adjust the width as needed
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Phone Number",
        accessor: "mobile",
        Cell: ({ value, row }) => (
          <div
            onClick={(event) => {
              console.log(row);
            }}
            className=" "
            title={value}
            style={{ width: "150px" }} // Adjust the width as needed
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => (
          <div
            className=""
            title={value}
            style={{
              width: "120px",
              color: "#16A34A",
              fontFamily: "Poppins-Regular",
            }} // Adjust the width as needed
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Domain",
        accessor: "domains",
        Cell: ({ value }) => (
          <div
            className="datatrimmer  "
            style={{ width: "200px" }} // Adjust the width as needed
            title={value}
          >
            {value.join(", ")}
          </div>
        ),
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div
            style={{ width: "150px" }} // Adjust the width as needed
          >
            <button
              onClick={(event) => {
                navigate("/coordinator/interviewer/requestedprofile", {
                  state: { requestedProfile: row.original },
                });
                console.log(row.original);
              }}
              className="hover:text-orange-600 interviewListActionBtn flex"
            >
              <CalendarMonthIcon className="mx-1" />
              Interview List
            </button>
          </div>
        ),
      },
    ],
    []
  );

  // Create the table instance
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({ columns, data: listOfInterviewCoordinate }, useGlobalFilter);

  let [columnData, setColumnData] = useState([]);

  return (
    <div className="readyToHire readyToHire_coordinatorInterviewer  w-full home-component-display ml-4 mr-4 mt-2.5 overflow-auto">
      <div className="componentHeader">
        {props?.tabletitleRow && (
          <div className="tabletitle">
            <EducationIcon className="hidden" />
            <span>Interviewer List - {interviewerCount}</span>
          </div>
        )}
        {/* <div className="searchInput">
        <input
                    className="table-search-input"
                    onChange={handelSearch}
                    type="text"
                    // ref={cardSearchInput}
                    placeholder="Search"
                  />
        </div> */}
        <div className="clickables">
          {/* <CandidateColumnOption
            contextMenuData={columnData}
            func={(event) => {
              handelColumnsDispaly(event);
            }}
            hiddenColumnsData={hiddenColumnsData}
          /> */}

          {/* <button
            onClick={() => {
              if (!openFilter) {
                fetchFilterOptions();
              }
              setOpenFilter(!openFilter);
            }}
            className={`${openFilter ? "selected-btn" : null}`}
          >
            <span>
              <FilterIcon />
            </span>
            Filters
          </button> */}
          {listview && (
            <>
              {/* <ListSelectedIcon className="listIcon" /> */}
              {/* <GridIcon
                onClick={() => {
                  setListview(false);
                }}
                className="gridIcon"
              /> */}
            </>
          )}
          {!listview && (
            <>
              <ListIcon
                onClick={() => {
                  setListview(true);
                }}
                className="listIcon"
              />
              {/* <ListSelectedIcon className="listIcon" /> */}
              <GridSelectedIcon className="gridIcon" />
            </>
          )}
        </div>
        <input
          className="coordinate-list-search-input col-span-2 "
          onChange={(e) => {
            if (e.target.value.trim() === "") {
              resetSearch();
              fetchAllInterviewCoordinate(0);
            } else {
              handelSearch(e.target.value, 0);
            }
          }}
          type="text"
          placeholder="Search"
        />
      </div>
      <div className={`hireTable ${openFilter ? "table-with-filter" : null} `}>
        <div
          className="interviweListComponent overflow-y-auto "
          onScroll={handleScroll}
        >
          <table
            {...getTableProps()}
            className="clientTable"
            style={{ width: "81vw" }}
          >
            <thead
              onClick={() => {
                console.log(rows, rows.length);
              }}
            >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      style={{ fontSize: "18px" }}
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {listOfInterviewCoordinate.length === 0 ? (
              <div className="">
                <div className="">
                  {/* if any loader required give it here */}
                  {/* Loading... */}
                  <div className="noDataCompany">
                    <div className="no-data-availableIcon">
                      <NoDataFound />
                    </div>
                    <div className="no-data-availableText">
                      <p>Oops !!</p> <p>There are no data to be found yet</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <tbody {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  <>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell, index) => (
                            <td
                              className={
                                index > 0 && cell.column.Header !== "Action"
                                  ? "  cursor-default"
                                  : " cursor-default"
                              }
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <div className="noDataCompany">
                    <div className="no-data-availableIcon">
                      <NoDataFound />
                    </div>
                    <div className="no-data-availableText">
                      <p>Oops !!</p> <p>There are no data to be found yet</p>
                    </div>
                  </div>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
      {openFilter && (
        <div className="hire-filter">
          <div className="filter-header">
            <span>Filter By</span>
            <span className="apply-btn">
              <button
                onClick={() => {
                  setOpenFilter(!openFilter);
                }}
              >
                Apply
              </button>
            </span>
          </div>
          {filterOptions.map((data, index) => {
            return (
              <div className="individual-type">
                <div>{data.type}</div>
                {data.values.map((value) => {
                  return (
                    <div className="typeData">
                      <Checkbox
                        color="primary"
                        value={value}
                        // disableRipple
                        // disabled={
                        //   !props.data ||
                        //   (props.data && !props.data.length) ||
                        //   props.disableRootCheckbox
                        // }
                        checked={selectedFilters?.[data.type]?.includes(value)}
                        // indeterminate={isCheckAllIndeterminate}
                        onChange={(e) => {
                          handleFilterSelection(e, data.type, value);
                        }}
                        className="root-check-all"
                      />
                      <span>{value}</span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
      {listModal && (
        <InterviewListModal
          tableSearch={true}
          header={"Interview List"}
          candidatesData={hireTableData}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default InterviewerList;
