import React, { useEffect, useState } from "react";
import { useTable, useGlobalFilter } from "react-table";
import { Checkbox } from "@material-ui/core";
import { ReactComponent as FilterIcon } from "../../../assets/filterIcon.svg";
import { ReactComponent as GridIcon } from "../../../assets/gridIcon.svg";
import { ReactComponent as GridSelectedIcon } from "../../../assets/gridSelectedIcon.svg";
import { ReactComponent as ListIcon } from "../../../assets/listIcon.svg";
import { ReactComponent as ListSelectedIcon } from "../../../assets/listSelectedIcon.svg";
import { ReactComponent as EducationIcon } from "../../../assets/education.svg";
import CandidateColumnOption from "../../common/CandidateColumnOption";
import { ReactComponent as VideoIcon } from "../../../assets/video.svg";
import "./CandidateList.scss";
import CandidateCard from "../../common/candidate-card/CandidateCard";
import CandidateTable from "../../common/candidate-table/CandidateTable";
import InterviewModals from "../../interviewer/modals/InterviewModals";
import { getAllInterviewCandReq,interviewerSearch } from "../../../api/api-services";
import { ToastContainer,toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CandidateList = (props) => {
  let [hireTableData, setHireTableData] = useState([]);
  let [openFilter, setOpenFilter] = useState(false);
  let [filterOptions, setFilterOptions] = useState([]);
  let [selectedFilters, setSelectedFilters] = useState({});
  let [listview, setListview] = useState(true);
  let [updateInterviewModal, setUpdateInterviewModal] = useState(false);
  let [selectedCandidate, setSelectedCandidate] = useState({});
  const [allInterviewers, setAllInterviewers] = useState({});
  let [selectedTab, setSelectedtab] = useState("All Profiles");
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  const [contextMenuData, setContextMenuData] = useState([]);
  let [CandidatesCount, setCandidatesCount] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  let [tableApiData, setTableApiData] = useState({
    totalCount: 20,
    columnDef: {
      columns: [],
    },
    lastPage: true,
  });
  let [interviewModule, setInterviewModule] = useState(false);
  let [hiddenColumnsData, setHiddenColumnsData] = useState([
    "email",
    "gender",
    "location",
    "highest_degree",
    "requested_clients",
    "shareable",
    "internalInterviewInfo",
  ]);
  let size = 10;
  const fetchFilterOptions = () => {
    setFilterOptions([
      {
        type: "Location",
        values: ["Bangalore", "Mysore", "Shivamogga", "Goa", "Mangalore"],
      },
      {
        type: "Experiance",
        values: ["Fresher", "0 - 1 yrs", "1 - 3 yrs", "3 - 5 yrs", "5+ yrs"],
      },
    ]);
  };
  let handelColumnsDispaly = (e) => {
    let value = e.target.value;
    let data = [...hiddenColumnsData];
    if (data.includes(value) && !e.target.checked) {
      data.splice(data.indexOf(value), 1);
    } else if (e.target.checked) {
      data.push(value);
    }
    setHiddenColumnsData([...data]);
  };
  // const handlescroll = (event) => {
  //   if (
  //     Math.ceil(event?.target?.scrollTop + event?.target?.clientHeight) ===
  //     event?.target?.scrollHeight
  //   ) {
  //     if (lastPage === false) {
  //       getAllInterviewers(pageno);
  //     }
  //   }
  // };
  const handlescroll = (event) => {
    const target = event?.target;
    if (!target) return;
  
    const scrolledToBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >= target.scrollHeight - 1;
  
    if (scrolledToBottom && !lastPage &&  !isSearching) {
      getAllInterviewers(pageno);
    }else if(scrolledToBottom && !lastPage &&  isSearching){
      searchInterviewers(searchedText, pageno)
    }
  };


  let prepareHideDropDown = (response) => {
    let contextData = [];
    response.columnDef.columns.map((data) => {
      contextData.push({
        option: data.columnName,
      });
    });
    setContextMenuData([...contextData]);
  };
  const handleFilterSelection = (e, type, value) => {
    console.log(e.target.checked);
    let selectedData = { ...selectedFilters };
    if (e.target.checked) {
      if (selectedData?.[type]?.length > 0) {
        selectedData?.[type].push(value);
      } else {
        selectedData[type] = [value];
      }
    } else {
      if (selectedData?.[type]?.length > 0) {
        let index = selectedData?.[type].indexOf(value);
        selectedData?.[type].splice(index, 1);
      }
    }
    console.log(selectedData);
    setSelectedFilters({ ...selectedData });
  };

  const closeModal = (val) => {
    if (interviewModule) {
      setInterviewModule(false);
      getAllInterviewers(0);
    }
  };

  let getAllInterviewers = async (page) => {
    let uId = JSON.parse(localStorage.getItem("across-geo-user")).uid;
    const response = await getAllInterviewCandReq(uId, page, size);
    setCandidatesCount(response.data.response.totalElements)
    if (response.data) {
      setLastpage(response?.data?.response?.last);
      if (response?.data?.response?.last === false) {
        setpageNo(page + 1);
      }
      let tabledata = { ...tableApiData };
      if (page === 0) {
        tabledata = { ...response.data.response };
      } else {
        tabledata.content = tabledata.content.concat(
          response.data.response.content
        );
      }
      tabledata.columnDef.columns.concat({
        Header: "Interview Date",
        accessor: "interview", // Replace with the actual accessor for the interview object field
        width: "100px", // Adjust the width as needed
        Cell: (e) => {
          const interviewDate = new Date(e?.value?.date);
          const formattedDate = interviewDate.toLocaleString("default", {
            month: "short",
            day: "numeric",
          });

          return (
            <div className=" flex mt-3">
              {e?.value?.hasRequestForNextRound === false ? (
                <>
                  {formattedDate} |<p className="mx-1"> {e.value.startTime} </p>
                </>
              ) : (
                <p className="text-red-500">Requested for one more round</p>
              )}
            </div>
          );
        },
      });
      setAllInterviewers(response.data.response);
      setTableApiData({ ...tabledata });
    } else {
      console.log("error in fetch");
    }
    if (page === 0) {
      prepareHideDropDown(response.data.response);
    }
    setIsLoading(true);
  };
  console.log(tableApiData, "tabledata");
  useEffect(() => {
    setIsLoading(false);
    getAllInterviewers(0);
  }, []);

  let actionele = (data) => {
    console.log(data, "data");
    let interviewTalen = data.interview.internalInterviewTaken
    return (
      <div className="hire-now-btn flex">
        <button
          disabled={interviewTalen}
          className={interviewTalen?`datatrimmer pl-2 pr-2`:`hover:text-orange-600 interviewListActionBtn pl-2 pr-2`}
          onClick={() => {
            setSelectedCandidate({ ...data });
            setInterviewModule(true);
          }}
        >
          {interviewTalen?"Interview Taken":"Update Interview"}
        </button>
      </div>
    );
  };

  let searchInterviewers = async (e, page) => {
    let uId = JSON.parse(localStorage.getItem("across-geo-user")).uid;
    setIsSearching(true); // Set the flag to indicate search
    setIsLoading(true);
    setSearchedText(e);
    const response = await interviewerSearch(e, page, size,uId);
    setCandidatesCount(response.data.response.totalElements)
    if (response.data) {
      setLastpage(response?.data?.response?.last);
      if (response?.data?.response?.last === false) {
        setpageNo(page + 1);
      }
      let tabledata = { ...tableApiData };
      if (page === 0) {
        tabledata = { ...response.data.response };
      } else {
        tabledata.content = tabledata.content.concat(
          response.data.response.content
        );
      }
    
      setAllInterviewers(response.data.response);
      setTableApiData({ ...tabledata });
    } else {
      console.log("error in fetch");
    }
   
    setIsLoading(true);
  };
  const resetSearch = () => {
    setIsSearching(false);
    setpageNo(0);
    setLastpage(false);
  };
  return (
    <div className="client-interview-candidate mx-3 w-full home-component-display  mt-2.5 overflow-auto">
      <div className="componentHeader flex">
        <EducationIcon className="hidden" />
        <div>
        <span className="tabletitle">Candidates List - {CandidatesCount}</span>
        </div>
        <div className="clickables">
          <div className="clickables">
            <CandidateColumnOption
              contextMenuData={contextMenuData}
              func={(event) => {
                handelColumnsDispaly(event);
              }}
              hiddenColumnsData={hiddenColumnsData}
            />
          </div>
        </div>
      </div>
      <div className={`hireTable ${openFilter ? "table-with-filter" : null} `}>
      <input
          className="table-search-input-coordinator col-span-2 "
          onChange={(e) => {
            if (e.target.value.trim() === "") {
              resetSearch();
              getAllInterviewers( 0);
            } else {
              searchInterviewers(e.target.value, 0);
            }
          }}
          // ref={cardSearchInput}
          type="text"
          placeholder="Search"
        />
        {listview ? (
          <CandidateTable
         
            isLoading={isLoading}
            tableApiData={tableApiData}
            actionele={actionele}
            actionColumn={true}
            handlescroll={handlescroll}
            actionColumnWidth={"15%"}
            profileColumn={"name"}
            interviewerTable={"interviewerTable"}
            hiddenColumnsData={hiddenColumnsData}
            selectedTab={selectedTab}
          />
        ) : (
          <div className="card-container">
            {hireTableData.map((candidate, index) => {
              return (
                <div className="candidate-card">
                  <CandidateCard
                    alignmentclass={
                      index % 2 === 0 ? "align-card-right" : "align-card-left"
                    }
                    candidate={candidate}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      {openFilter && (
        <div className="hire-filter">
          <div className="filter-header">
            <span>Filter By</span>
            <span className="apply-btn">
              <button
                onClick={() => {
                  setOpenFilter(!openFilter);
                }}
              >
                Apply
              </button>
            </span>
          </div>
          {filterOptions.map((data, index) => {
            return (
              <div className="individual-type">
                <div>{data.type}</div>
                {data.values.map((value) => {
                  return (
                    <div className="typeData">
                      <Checkbox
                        color="primary"
                        value={value}
                        // disableRipple
                        // disabled={
                        //   !props.data ||
                        //   (props.data && !props.data.length) ||
                        //   props.disableRootCheckbox
                        // }
                        checked={selectedFilters?.[data.type]?.includes(value)}
                        // indeterminate={isCheckAllIndeterminate}
                        onChange={(e) => {
                          handleFilterSelection(e, data.type, value);
                        }}
                        className="root-check-all"
                      />
                      <span>{value}</span>
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div className="clickables">
            <CandidateColumnOption
              contextMenuData={contextMenuData}
              func={(event) => {
                handelColumnsDispaly(event);
              }}
              hiddenColumnsData={hiddenColumnsData}
            />
          </div>
        </div>
      )}
      {interviewModule && (
        <InterviewModals
        toast={toast}
          getAllInterviewers={getAllInterviewers}
          setInterviewModule={setInterviewModule}
          candidate={selectedCandidate}
          header={"Update Interview Details"}
          closeModal={closeModal}
        />
      )}
       <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        className={"tost-msg-stylinh"}
        draggable={false}
        theme="light"
      />
    </div>
  );
};

export default CandidateList;
