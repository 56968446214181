import React, { Fragment, useState ,useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  InputAdornment,
  Grid,
  Tooltip,
} from "@material-ui/core";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";

import "./SetPassword.scss";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import cx from "classnames";
import {
  resetPassword,
  resetPasswordForBD,
} from "../../../../api/api-services";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import CrossIcon from "../../website-assets/crossIcon.png";
import Modal from "react-modal";
import VisibleEye from "../../website-assets/visibleEyeNew.png";
import InvisibleEye from "../../website-assets/invisibleEyeNew.png";
import LeftArrow from "../../website-assets/leftArrow.png";
import GreenTickIcon from "../../website-assets/greenTickIcon.png";
import NavigationHeader from "../../nav-section/NavigationHeader";
import FindJobsByKeywordHome from "../../find-jobs/FindJobsByKeywordHome";
const customStylesPage = {
  overlay: {
    background: "rgba(90, 90, 90,0.5)",
  },
  content: {
    position: "fixed",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    borderRadius: "10px",
    opacity: "1",
    outline: "none",
    width: "400px",
    // height: "468px",
    height: "auto",
    background: " #FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 20px #00000029",
  },
};
const useStyles = makeStyles((theme) => ({
  form: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  field: {
    margin: theme.spacing(1),
    width: "100%",
    height: "65px",

    opacity: "1",
  },

  input: {
    backgroundColor: "#fff",
    "&:focused": {
      backgroundColor: "#fff",
    },
    "& .Mui-focused": {
      backgroundColor: "#fff",
    },
  },
}));

const initialValues = {
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object({
  password: Yup.string()
    .matches(/^\S*$/, `Weak password, please match the password criteria`)

    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,20}$/,
      "Weak password, please match the password criteria"
    )
    .required("New password is required"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password not matched with new password"
    ),
});

const STRONG_PASSWORD_PATTERNS = [
  {
    pattern: /^.{8,20}$/,
    message: "Should contains at least 8 characters and at most 20 characters",
  },
  {
    pattern: /.*[0-9].*/,
    message: "Should contains at least one digit",
  },
  {
    pattern: /.*[A-Z].*/,
    message: "Should contains at least one upper case alphabet",
  },
  {
    pattern: /.*[a-z].*/,
    message: "Should contains at least one lower case alphabet",
  },
  {
    pattern: /.*[!@#$%&*()+=^].*/,
    message:
      "Should contains at least one special character which includes !@#$%&*()+=^",
  },
  {
    pattern: /^\S*$/,
    message: "Should doesn't contain any white space",
  },
];
const SetPassword = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [isSuccessMsgShow, setIsSuccessMsgShow] = useState(false);
  const [storeSuccessMsgShow, setStoreSuccessMsgShow] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
 
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickConfirmShowPassword = () => {
    setConfirmShowPassword(!confirmShowPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    if (isSubmitting) {
      return; // Prevent multiple form submissions
    }
    try {
      setIsSubmitting(true);
      const { password } = values;
      const url = window.location.href;
      const token = url.substring(url.lastIndexOf("/") + 1);
      const urlParts = url.split("/");
      const isSetPasswordUrl = urlParts[urlParts.length - 2] === "set-password";
      const resetApiFunction = isSetPasswordUrl
        ? resetPasswordForBD
        : resetPassword;

      const response = await resetApiFunction(token, password);
      console.log(response?.data.statusCode, "resp");
      if (response?.data?.statusCode === 200) {
        setIsSuccessMsgShow(true);
        setStoreSuccessMsgShow(response?.data?.response?.response);
        setTimeout(() => {
          setStoreSuccessMsgShow(""); // Clear the message after 5 seconds
          setIsSuccessMsgShow(false);
          navigate("/");
        }, 4000);
        // toast.success(
        //   <>
        //     <div className="toast-heading">Success</div>
        //     <div>{response?.data?.response?.response}</div>
        //   </>
        // );
        // setTimeout(() => {
        //   navigate("/");
        // }, 5000);
      } else {
        toast.error(
          <>
            <div className="toast-heading">Alert</div>
            <div>Something went wrong</div>
          </>
        );
      }
      // Handle the response from the API as per your requirement
      console.log(response.data);
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error(error);
    } finally {
      setIsSubmitting(false); // Stop the loading state
    }
  };
  const url = window.location.href;

  const urlParts = url.split("/");
  const isSetPasswordUrl = urlParts[urlParts.length - 2] === "set-password";
  const [isModalOpen, setIsModalOpen] = useState(true); // Set modal state

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const changeGreenBorderStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#007456",
      },
      "&:hover fieldset": {
        borderColor: "#007456"
      }
    },
  };
  // useEffect(() => {
  //   if (isModalOpen) {
  //     // Disable scrolling when the modal is open
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  //   return () => {
  //     document.body.style.overflow = "auto";
  //   };
  // }, [isModalOpen]);
  return (
    <div className=" overflow-x-hidden">
      <div className="setPasswordContainer">
        <div className="navbarSection-stuReg">
          <NavigationHeader />
        </div>
        <FindJobsByKeywordHome />
        <Modal
          isOpen={isModalOpen}
          // onRequestClose={closeModal}
          contentLabel="Reset Password Modal"
          style={customStylesPage}
          className="ResetPasswordModal"
        >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="formContainerForSetPw">
                <div className="successMsg ">
                  {isSuccessMsgShow && (
                    <>
                      <div className="flex justify-center align-middle relative  mt-4">
                        {" "}
                        <img src={GreenTickIcon} style={{ height: "30px" }} />
                      </div>

                      <p className="storeSuccessMsg">{storeSuccessMsgShow}</p>
                    </>
                  )}
                </div>

                <div style={{height:"50px"}}>
                  {" "}
                  <p className="heading">
                    {isSetPasswordUrl ? "Set Password ?" : "Reset Password ?"}
                  </p>
                  <Link
                    to="/"
                    className="cursor-pointer flex justify-end relative "
                    style={{ bottom: "77px", left: "30px" }}
                  >
                    <img
                      src={CrossIcon}
                      className="  top-7  relative"
                      style={{ height: "20px", right: "31px" }}
                    />
                  </Link>
                </div>
                <div className="formContents">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, errors, touched }) => (
                      <Form className={classes.form}>
                        <span className="text-red-500 ">*</span>
                        <label className="my-2 formLabel mx-1">
                          New Password
                        </label>
                        <Field
                          name="password"
                          autoComplete="off"
                          placeholder="Enter new password"
                          as={TextField}
                          sx={changeGreenBorderStyle}
                          type={showPassword ? "text" : "password"}
                          variant="outlined"
                          className={classes.field}
                          inputProps={{
                            className: cx(`!text-xs impFont_14px boxedInput `),
                            style: {
                              //   padding: "9.5px 14px",
                              height: "0.43em !important",
                            },
                          }}
                          error={touched.password && Boolean(errors.password)}
                          helperText={
                            <div
                              className={cx("relative")}
                              style={{ width: " auto" }}
                            >
                              {touched.password && errors.password}
                              {values.password.length > 7 &&
                              values.password.length < 21 &&
                              values.password.match(
                                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,20}$/
                              ) &&
                              values.password.match(/^\S*$/) ? (
                                <p className="relative  strongPW">
                                  Strong password, good
                                </p>
                              ) : null}
                            </div>
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  className=" cursor-pointer"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {!showPassword ? (
                                    <img src={InvisibleEye} />
                                  ) : (
                                    <img src={VisibleEye} />
                                  )}
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <div className="infoErrorIcon_resetPw">
                          {STRONG_PASSWORD_PATTERNS.some(
                            ({ pattern }) => !pattern.test(values.password)
                          ) &&
                            (values.password || touched.password) && (
                              <Tooltip
                                data-html="true"
                                title={
                                  <div>
                                    {STRONG_PASSWORD_PATTERNS.map(
                                      (passwordPattern) => {
                                        return (
                                          <Fragment
                                            className="absolute"
                                            key={passwordPattern.message}
                                          >
                                            {!passwordPattern.pattern.test(
                                              values.password
                                            ) && (
                                              <p
                                                style={{
                                                  fontSize: "10px",
                                                  fontFamily: "Poppins-Regular",
                                                  margin: "1px",
                                                }}
                                              >
                                                {passwordPattern.message}
                                              </p>
                                            )}
                                          </Fragment>
                                        );
                                      }
                                    )}
                                  </div>
                                }
                                placement="bottom"
                              >
                                <span
                                  className={cx(
                                    "cursor-pointer ",
                                    "absolute",
                                    "mt-8",
                                    "-ml-10 password-info-icon",

                                    {
                                      "text-red-500":
                                        touched.password && errors.password,
                                      "text-blue-700":
                                        !touched.password || !errors.password,
                                    }
                                  )}
                                >
                                  <InfoOutlinedIcon />
                                </span>
                              </Tooltip>
                            )}
                        </div>

                        <span className="text-red-500 ">*</span>
                        <label className="my-2 formLabel mx-1">
                          Confirm password
                        </label>
                        <Field
                          name="confirmPassword"
                          autoComplete="off"
                          placeholder="Confirm your password"
                          as={TextField}
                          sx={changeGreenBorderStyle}
                          type={confirmShowPassword ? "text" : "password"}
                          variant="outlined"
                          className={classes.field}
                          error={
                            touched.confirmPassword &&
                            Boolean(errors.confirmPassword)
                          }
                          helperText={
                            <div className={cx("relative")}>
                              {touched.confirmPassword &&
                                errors.confirmPassword}
                              {touched.password &&
                              touched.confirmPassword &&
                              values.password.length > 7 &&
                              values.password === values.confirmPassword ? (
                                <div className="text-green-500 strongPW absolute">
                                  Password matched
                                </div>
                              ) : null}
                            </div>
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  onClick={handleClickConfirmShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  className=" cursor-pointer"
                                >
                                  {!confirmShowPassword ? (
                                    <img src={InvisibleEye} />
                                  ) : (
                                    <img src={VisibleEye} />
                                  )}
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />

                        <div className=" mt-2">
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className="pwBtn"
                          >
                            {isSubmitting ? "Sending..." : "Submit"}
                          </button>
                        </div>

                        <Link
                          to="/"
                          className="registerContainerForgetPw my-2 flex relative cursor-pointer"
                        >
                          <img src={LeftArrow} style={{ height: "25px" }} />
                          <p className="ml-2 labelPrev">Back to Login</p>
                        </Link>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Grid>
          </Grid>
        </Modal>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          className={"tost-msg-stylinh"}
          draggable={false}
          theme="light"
        />
      </div>
    </div>
  );
};

export default SetPassword;
