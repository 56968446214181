import React, { useState } from "react";
import Modal from "react-modal";
import "./InterviewListModal.scss";
import CandidatePreviewModal from "../../common/CandidatePreviewModal";
import { useGlobalFilter, useTable } from "react-table";
import { useRef } from "react";
import { useEffect } from "react";
import CancelIcon from '@mui/icons-material/Cancel';

function InterviewListModal(props) {
  const [openModal, setOpenModal] = useState(true);
  let [candidatePreviewModal, setCandidatePreviewModal] = useState(false);
  let [selectCandidate, setSelectedCandidate] = useState({});
  let [candidateData, setCandidateData] = useState([]);
  const cardSearchInput = useRef(null);
  let [candidateDetailsData, setCandidateDetailsData] = useState({});

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "60%",
      transform: "translate(-50%, -50%)",
      width: "82%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
      outline: "none",
      //   borderTopLeftRadius: " 12px",
    },
  };
  let handelProfileClick = (data) => {
    console.log(data, "----");
    setSelectedCandidate({ ...data });
    setCandidatePreviewModal(true);
  };
  let getStatusMethod = (status)=>{
    if(status === "Completed"){
        return "successStatus"
    }else if(status === "Sheduled"){
        return "sheduledStatus"
    }else if(status === "Cancelled"){
        return "cancelledStatus"
    }
  }
  let columns = React.useMemo(
    () => [
      {
        Header: "Profile",
        accessor: "profile",
        width: "25%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              handelProfileClick(e.cell.row.original);
            }}
            className="name"
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "Interview Date and Time",
        accessor: "interviewtime",
        width: "30%",
        Cell: (e) => (
          <div className="datatrimmer" title={e.value}>
            {e.value}
          </div>
        ),
      },
      {
        Header: "Interview Link",
        accessor: "interviewLink",
        width: "30%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
            }}
            className="mr-3"
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "Interview Status",
        accessor: "status",
        width: "15%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
            }}
            className={`${getStatusMethod(e.value)}`}
          >
            {e.value}
          </div>
        ),
      },
    ],
    []
  );
  let data = React.useMemo(() => candidateData, [candidateData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  let handelSearch = (e) => {
    setGlobalFilter(e.target.value);
  };
  let closeModal = (val) => {
    if (candidatePreviewModal) {
      setCandidatePreviewModal(false);
    }
  };
  let actionele = (data) => {
    return (
      <div className="">
        Completed
      </div>
    );
  };
  useEffect(() => {
    let data = [];
    if (props?.tableSearch && cardSearchInput?.current?.value) {
      cardSearchInput.current.value = "";
    }
    props.candidatesData.map((candidate) => {
      data.push({ ...candidate, action: actionele(candidate) });
    });
    setCandidateData([...data]);
  }, [props.candidatesData]);
  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      style={customStylesPage}
      className="p-px"
    >
      <div className="flex items-center rounded-tl-xl Interview-list-modal-heading items-center">
        <div className="header">{props.header}-{props.candidatesData.length}</div>
        <button
          onClick={() => {
            setOpenModal(false);
            props.closeModal(false);
          }}
          type="button"
          className="modal-close-btn float-left ml-auto"
        >
          <CancelIcon />
        </button>
      </div>
      <div className="Interview-list-modal-body">
      {props.tableSearch && (
        <input
          className="table-search-input"
          onChange={handelSearch}
          type="text"
          ref={cardSearchInput}
          placeholder="Search"
        />
      )}
      <table {...getTableProps()} className=" overflow-x-auto">
        <thead className="text-left  h-10">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  width={column.width}
                  {...column.getHeaderProps()}
                  className={` reportTableheader popins-semi-bold text-lg font-semibold ${
                    column.Header !== "Action" ? "text-left " : "text-center"
                  }
                    ${column.Header === "Profile" ? "profileHeader" : ""}`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {data.length === 0 ? (
          <div className="grid grid-cols-12 nodefect_container">
            <div className="col-span-12 flex justify-center">
              {/* <NoDefectIcon /> */} no data
            </div>
          </div>
        ) : (
          <tbody
            {...getTableBodyProps()}
            // className="border-t-2"
            //   onScroll={handlescroll}
          >
            {rows.length > 0 ? (
              <>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className={``} id="defectlog">
                      {row.cells.map((cell) => {
                        return (
                          <td
                            width={cell?.column?.width}
                            {...cell.getCellProps()}
                            className={`datatrimmer ${cell?.column?.Header?.toLowerCase()}`}
                          >
                            <div className="dataContainer flex items-center">
                              {cell?.column?.Header === "Profile" && (
                                <span className="profile-icon">
                                  {cell?.row?.original.profile.slice(0, 2)}
                                </span>
                              )}
                              <div>
                                {cell.render("Cell")}
                                {cell?.column?.Header === "Profile" && (
                                  <div className="rating">
                                    Rating {cell?.row?.original?.rating}
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                <div className="grid grid-cols-12  nodefect_container">
                  <div className="col-span-12 flex justify-center">
                    {/* <NoDefectIcon /> */}no list
                  </div>
                </div>
              </>
            )}
          </tbody>
        )}
      </table>
      {candidatePreviewModal && (
        <CandidatePreviewModal
          candidateDetailsData={candidateDetailsData}
          candidate={selectCandidate}
          closeModal={closeModal}
          header={"Candidate Preview"}
        />
      )}
      </div>
    </Modal>
  );
}

export default InterviewListModal;
