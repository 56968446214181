import React, { useState, useRef } from 'react';
import Modal from "react-modal";
import CancelIcon from '@mui/icons-material/Cancel';
import s3 from '../../candidate/aws-config';

const VideoPreviewDetailsModal = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [videoUrl, setVideoUrl] = useState(() => {
    return s3.getSignedUrl("getObject", {
      Bucket: process.env.REACT_APP_AWS_BUCKET,
      Key: props.candidate.interview.fullVideo,
    });
  });
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setVideoUrl(URL.createObjectURL(file)); // Generate a URL for the selected file
    }
  };

  const handleEditClick = () => {
    // Trigger the file input when "Edit" is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className='videoPreviewDetails'>
      <div className="grid grid-cols-12">
        <div className="grid grid-cols-12 col-span-12 video-preview-header">
          <div className="col-span-11 ml-3">Video Preview</div>
          
        </div>
        <div className="col-span-12  p-3">
          <video key={videoUrl} controls controlsList="nodownload">
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        {/* dont remove this code----Req clarification needed in future to replace the video */}
        {/* <button onClick={handleEditClick}>Edit</button>
        <input
          type="file"
          accept="video/*"
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={handleFileChange}
        /> */}
      </div>
      <div className="individual-field mt-7 skillSetHeader">
                <div className="mb-2 label">
                  <span className="create-labels">Skill Set Rating</span>
                </div>
              </div>
      <div className='rating-container'>
       
              {props.candidateDetailsData?.technicalDetails?.skills.map(
                (data) => {
                  return (
                    <div className="flex rating-individual-field mt-2">
                      <div className="mb-2 label">
                        <span className="create-labels">{data.skillName}</span>
                      </div>
                      <div className="popins-medium">
                        <input
                          value={data.rating + " out of 100"}
                          className="rating-input-field "
                          disabled
                          type={"text"}
                        />
                      </div>
                    </div>
                  );
                }
              )}
            
        </div>
    </div>
  );
};

export default VideoPreviewDetailsModal;
