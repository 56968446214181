import UnderDevelopmentImage from "../../assets/under-development.jpg";
function UnderDevelopment() {
  return (
    <>
      <div className="flex justify-center">
        <img src={UnderDevelopmentImage} alt="Under Development"></img>
      </div>
    </>
  );
}

export default UnderDevelopment;
