import React, { useRef, useState, useEffect } from "react";
import "./CustomerReviews.scss";
import Slider from "react-slick";
import AvtarTwo from "../../../assets/avtar2_testimonial.png";
import AvtarThree from "../../../assets/avtar3_testimonial.png";

function CustomerReviews() {
  const sliderRef = useRef(null);
  const [activeDot, setActiveDot] = useState(0);

  const reviewContent = [
    {
      name: "Santosh B ",
      designation: "Senior HR Manager",
      avatar: AvtarThree,
      // header: '“The support is awesome“',
      content:
        "This company's recruitment support blew us away! They were pro, attentive, and guided us every step of the way. They supported our hiring needs, filled our pipeline with quality candidates, and helped us hire fast",
    },
    {
      name: "Yashas H ",
      designation: "Assistant Manager",
      avatar: AvtarThree,
      // header: '"Excellent aesthetic design"',
      content:
        "Amazed by this platform's speed! Job seekers flooded in after posting, and I hired a top pick the next day. It's fast, smooth, and cost-effective. The proactive, helpful team made it a breeze - any question, instant answer.",
    },
    {
      name: "Mithun Kumar ",
      avatar: AvtarThree,
      designation: "Talent Acquisition Lead",
      // header: '"I recommend this agency"',
      content:
        "I am impressed with this hiring platform and its company. The user-friendly interface and powerful features streamlined our recruitment, providing access to a diverse pool of high-quality resources. The responsive and attentive support team ensured a seamless experience from start to finish",
    },
    {
      name: "Shruthi P ",
      avatar: AvtarTwo,
      designation: "Senior Recruiter ",
      // header: '“The support is awesome“',
      content:
        "AcrossGeo was a game-changer for our hiring! Their platform is intuitive and efficient, connecting us with a diverse pool of top talent. The support team was fantastic - responsive, attentive, and always there to guide us. We found our perfect candidate quickly, and the whole process was smooth and cost-effective. Hiring just got a whole lot easier, thanks to AcrossGeo!",
    },
    {
      name: "Sudharshan ",
      designation: "TA Lead",
      avatar: AvtarThree,
      // header: '"Excellent aesthetic design"',
      content:
        "As TA Lead, I'm always looking for ways to optimize our recruitment process. AcrossGeo has been a revelation! Their platform is a time-saver, effortlessly connecting us with qualified candidates. The quality of talent is top-notch, and their support team is a dream - proactive, insightful, and always on our side. AcrossGeo has significantly streamlined our hiring, making it faster, smoother, and more cost-effective.",
    },
    {
      name: "Abhishek Kumar ",
      avatar: AvtarThree,
      designation: "Lead – Recruitment & Operations",
      // header: '"I recommend this agency"',
      content:
        "As Lead - Recruitment & Operations, I can confidently say AcrossGeo has been a game-changer. Their platform streamlined our workflow, making finding top talent quick and painless. The intuitive interface and powerful search tools cut down our time-to-hire, and the quality of candidates is superb. Plus, the support team is a dream - proactive, helpful, and always up for optimizing our process. AcrossGeo saved us time, money, and resources, all while helping us build a stronger team.",
    },
  ];

  const calculateSlidesToShow = (windowWidth) => {
    if (windowWidth >= 2560) {
      return 5;
    } else if (windowWidth >= 1024) {
      return 3;
    } else if (windowWidth >= 600) {
      return 2;
    } else {
      return 1;
    }
  };

  // Add an event listener to handle window resize
  useEffect(() => {
    const handleResize = () => {
      const slidesToShow = calculateSlidesToShow(window.innerWidth);
      setActiveDot(0); // Reset the active dot when the window resizes
      setSlidesToShow(slidesToShow);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // In your component state, add a state for slidesToShow
  const [slidesToShow, setSlidesToShow] = useState(
    calculateSlidesToShow(window.innerWidth)
  );

  // Update the slidesToShow property in your Slider component
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    beforeChange: (current, next) => {
      setActiveDot(next);
    },
    appendDots: (dots) => (
      <div
        className="flex justify-center items-center appendDots"
        style={{ position: "relative", right: "2rem" }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        key={i}
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: activeDot === i ? "#000000" : "#aaa",
          margin: "0 5px",
          cursor: "pointer",
          transition: "all 0.3s",
          transform: activeDot === i ? "scale(1.5)" : "scale(1)",
        }}
      ></div>
    ),
  };

  return (
    <div className="CustomerReviewsAboutComponent">
      <h1 className="headerContent">What our customers says</h1>
      <p className="subHeader">Don't take our word for it, take theirs!</p>

      <div className="reviewContainer">
        <Slider {...sliderSettings} className="cardSection" ref={sliderRef}>
          {reviewContent.map((card) => (
            <div key={card.id} className="cardBody lg:w-1/3 md:w-1/2 sm:w-full">
              <div className="cardHeader flex ">
                <img src={card.avatar} />
                <div className="card-title my-2 mx-2">
                  <div className="subTitle">
                    <label>{card.name} </label>{" "}
                    {/* Add {card.date} if needed */}
                    <h5>{card.date}</h5>
                  </div>
                  <p className="companyInfo">
                    {/* {card.company} | */}
                    {card.designation}
                  </p>
                </div>
              </div>
              <div
                className="card-body "
                style={{
                  wordWrap: "breakWord",
                  wordBreak: "breakWord",
                  maxHeight: "312px", // Set your desired max height
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <div className="bodyHeaderContent">{card.headerContent} </div>
                <p>{card.content}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default CustomerReviews;
