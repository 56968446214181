import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { TextField, TextareaAutosize, MenuItem } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Formik, useFormik } from "formik";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
// import Select from './Select';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css"; import "react-phone-number-input/style.css";
import Select from "react-select";
import "./NewCompanyAdd.scss";
import { SelectDropdown } from "../../landing-page/common/select-dropdown";

import {
  addcompanylead,
  getAllCompany,
  getIndustriesListReq,
  getClientCompanyDetails,
  updateCompanylead,
  defaultCountry,
  existEmailPhoneCheck
} from "../../../api/api-services";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

const clintValidationSchema = Yup.object({
  spocName: Yup.string().trim().matches(/^.{3,}$/, "Name should be minimum 3 characters"),
  spocEmail: Yup
    .string()
    .trim()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Enter valid email Id"),
})
const validationSchema = Yup.object({
  companyName: Yup.string()
    .required("Company Name is required")
    .matches(
      /^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/,
      "Company Name should contain only alphabets and numericals and no leading spaces"
    )
    .min(3, "Company Name should be minimum 3 characters")
    .max(50, "Company Name should be maximum 50 characters"),
  overview: Yup.string()
    .required("Overview is required")
    .matches(/^(?!.*\s{2})/, "Consecutive Spaces or special characters are not allowed")
    .max(500, "Overview should be maximum 500 characters"),
  website: Yup.string()
    .required("Website is required")
    .matches(/^(?!.*\s{2})/, "Consecutive Spaces or special characters are not allowed"),
  industry: Yup.string().required("Industry is required"),
  companySize: Yup.string().required("Company Size is required").matches(/^(?!.*\s{2})/, "Company Size should not be blank"),
  headquarters: Yup.string().required("Headquarters is required").matches(/^(?!.*\s{2})/, "Headquarters should not be blank"),
  specialities: Yup.string().required("Specialities is required").matches(/^(?!.*\s{2})/, "Specialities should not be blank"),
  spocName: Yup.string().trim().matches(/^.{3,}$/, "Name should be minimum 3 characters"),
  spocEmail: Yup
    .string()
    .trim()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Enter valid email Id"),
  compName: Yup.string().matches(/^(?!.*\s{2})/, "name should not be blank"),

});

function NewCompanyAdd(props) {
  const [companyExistsError, setCompanyExistsError] = useState(null);
  const [editLeedModal, setEditLeedModal] = useState(false)
  const [manualError, setmanualError] = useState({
    spocName: false,
    spocPhone: false,
    spocEmail: false,
    jobTitle: false,
    location: false
  })
  let initValues = {
    companyName: "",
    overview: "",
    website: "",
    industry: "",
    companySize: "",
    headquarters: "",
    specialities: "",
    spocName: "",
    spocPhone: "",
    spocEmail: "",
    jobTitle: "",
    location: ""
  }
  const [newCompanyform, setNewCompanyform] = useState(props.editLeadModal ? true : false);
  const [value, setValue] = useState();
  const [companyDetail, setCompanyDetail] = useState([]);
  const [error, setError] = useState({ phone: false, validPhone: false });
  const [selected, setSelected] = useState(false)
  const [industryData, setIndustryData] = useState([]);
  const [added, setAdded] = useState(false);
  const [availabilityStatusEmail, setAvailabilityStatusEmail] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [selectedIndustryData, setSelectedIndustryData] = useState([]);
  const [selectedcompanyData, setSelectedCompanyData] = useState([]);
  const [presentCountry, setPresentCountry] = useState("");
  const companyNameRef = useRef();
  const [notChanged, setNotChanged] = useState(false)
  const [initialValues, setInitialValues] = useState({ ...initValues });
  // let hasError = false;
  const handleCompanyExistsError = (errorMessage) => {
    setCompanyExistsError(errorMessage);
    // Scroll to the Company Name text field when the error occurs
    if (companyNameRef.current) {
      companyNameRef.current.focus();
    }
  };
  const fetchCompanyDetails = async (companyName) => {
    try {
      const response = await getClientCompanyDetails(companyName);
      console.log(response?.data?.response);
      if (response?.data?.statusCode === 200) {
        let companyDetails = response?.data?.response;
        setCompanyDetail(companyDetails)
      }
      formikDetails.resetForm({
        companyName: companyDetail.name,
        companySize: companyDetail.no_of_employee,
        headquarters: companyDetail.headquarters,
        industry: companyDetail.industry,
        overview: companyDetail.description,
        specialities: companyDetail.specialities,
        website: companyDetail.website
      })
    } catch (error) {
      console.error("Error occurred while fetching company details:", error);
    }
  };
  useEffect(() => {
    if (props?.editValues) {
      setSelectedCompanyData({
        companyName:
          [{
            label: props.editValues?.company?.name,
            value: props.editValues?.company?.name,
          }]
      },);
      setSelectedIndustryData([{
        label: props.editValues?.company?.industry,
        value: props.editValues?.company?.industry,
      }]);
    }
  }, []);
  useEffect(() => {
    // Fetch the defaultCountry only if the mobile number is not already present in props
    if (!props?.editValues?.client?.mobile) {
      defaultCountry()
        .then((res) => {
          setPresentCountry(res.data.country);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [props?.registrationData?.mobile]);
  const cintData = (values) => {
    // values.spocPhone.includes('+') && values.spocPhone.substring(0,values.spocPhone.length );
    let payload;
    if ((values.spocName && values.spocPhone && values.spocEmail && values.jobTitle && values.location) === "" && selected) {
      payload = {
        userId: props.userid,
        id: props.editLeadModal && props.editValues.id,
        leadName: "test_6a94cfd2df4c-1",
        company: {
          name: companyDetail.name ? companyDetail.name : "compName",
          description: companyDetail.description ? companyDetail.description : "description",
          website: companyDetail.website ? companyDetail.website : "website",
          industry: companyDetail.industry ? companyDetail.industry : "industry",
          no_of_employee: companyDetail.no_of_employee ? companyDetail.no_of_employee : "0",
          headquarters: companyDetail.headquarters ? companyDetail.headquarters : "headquarters",
          specialities: companyDetail.specialities ? companyDetail.specialities : "specialities",
        },
      }
    } else if ((values.spocName && values.spocPhone && values.spocEmail && values.jobTitle && values.location) === "" && !selected) {
      payload = {
        userId: props.userid,
        id: props.editLeadModal && props.editValues.id,
        leadName: "test_6a94cfd2df4c-2",
        company: {
          name: values.companyName,
          description: values.overview,
          website: values.website,
          industry: values.industry,
          no_of_employee: values.companySize,
          headquarters: values.headquarters,
          specialities: values.specialities,
        }
      }
    } else {
      if (values.companyName && values.companySize && values.headquarters && values.industry && values.jobTitle && values.location && values.overview && values.specialities && values.spocEmail && values.spocName && values.spocPhone && values.website) {
        payload = {
          userId: props.userid,
          leadName: "test_6a94cfd2df4c-2",
          id: props.editLeadModal && props.editValues.id,
          company: {
            name: values.companyName,
            description: values.overview,
            website: values.website,
            industry: values.industry,
            no_of_employee: values.companySize,
            headquarters: values.headquarters,
            specialities: values.specialities,
          },
          client: {
            name: values.spocName,
            mobile: `+${values.spocPhone.replace(/^\+/, "")}`,
            email: values.spocEmail,
            jobTitle: values.jobTitle,
            companyName: values.companyName,
            password: "Password@123",
            location: values.location,
          },
        }
      } else {
        payload = {
          id: props.editLeadModal ? props.editValues.id : null,
          id: props.editLeadModal && props.editValues.id,
          userId: props.userid,
          leadName: "test_6a94cfd2df4c-2",
          company: {
            name: selected ? companyDetail.name : values.companyName,
            description: companyDetail.description ? companyDetail.description : "description",
            website: companyDetail.website ? companyDetail.website : "website",
            industry: companyDetail.industry ? companyDetail.industry : "industry",
            no_of_employee: companyDetail.no_of_employee ? companyDetail.no_of_employee : "0",
            headquarters: companyDetail.headquarters ? companyDetail.headquarters : "headquarters",
            specialities: companyDetail.specialities ? companyDetail.specialities : "specialities",
          },
          client: {
            name: values.spocName,
            mobile: `+${values.spocPhone.replace(/^\+/, "")}`,
            email: values.spocEmail,
            jobTitle: values.jobTitle,
            companyName: selected ? companyDetail.name : values.companyName,
            password: "Password@123",
            location: values.location,
          },
        }
      }

    }
    return payload
  };

  const addLead = async (values) => {
    // let clientError = false;
    // if ((values.jobTitle || values.location || values.spocName || values.spocEmail || values.spocPhone) !== "") {
    //   clientError = checkClintDetails(values);
    // } else if ((values.jobTitle && values.location && values.spocName && values.spocEmail && values.spocPhone) == "" && selected) {
    //   clientError = true;
    // } else if ((values.jobTitle && values.location && values.spocName && values.spocEmail && values.spocPhone) == "" && !selected) {
    //   clientError = true;
    // } else {
    //   clientError = false;
    // }
    try {
      const payload = cintData(values)
      // checktoCallApi();
      if (!availabilityStatusEmail) {
        const response = await addcompanylead(payload);
        if (response?.data?.statusCode === 200) {
          props.setNewCompanyForm(false);
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>New lead Added Successfully</div>
            </>
          )
          props.fetchLeadById()
          props.setNewCompanyForm(false);
        } else if (response?.data?.statusCode !== 200 && response.keys(response).length != 0) {
          props.toast.error(
            <>
              <div className="toast-heading">Success</div>
              <div>{response?.data?.message}</div>
            </>
          );
        }
      } else {
        console.log("coming here")
      }
      //   }

      // }

    } catch (error) {
      console.error("API error:", error);
    }
  }

  const editLead = async (values) => {
    let editInitialValues = { ...props.editValues.client, ...props.editValues.company }
    let checkToUpdate = (
      values.companyName === editInitialValues.companyName &&
      values.companySize === editInitialValues.no_of_employee &&
      values.headquarters === editInitialValues.headquarters &&
      values.industry === editInitialValues.industry &&
      values.jobTitle === editInitialValues.jobTitle &&
      values.location === editInitialValues.location &&
      values.overview === editInitialValues.description &&
      values.specialities === editInitialValues.specialities &&
      values.spocEmail === editInitialValues.email &&
      values.website === editInitialValues.website
    );
    console.log(checkToUpdate, "checkToupdate")
    try {
      console.log("Form Values:", props.allLeads, values);
      const payload = cintData(values)
      if (!availabilityStatusEmail) {
        console.log("Payload:", payload);
        const response = await updateCompanylead(payload);
        if (response.data.statusCode === 200) {
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>Lead Updated Successfully</div>
            </>
          );
          props.fetchLeadById(0)
          props.setEditLeadModal(false);
        } else {

          props.toast.console.error(); (
            <>
              <div className="toast-heading">Error</div>
              <div>{response.data}</div>
            </>
          );
          props.fetchLeadById()
          props.setEditLeadModal(false);
        }
      } else {
        console.log("coming here inEdit")
      }
    } catch (error) {
      console.error("API error:", error);
    }
  }
  console.log(notChanged, "notChanged")

  const formikDetails = useFormik({
    initialValues: !props.editLeadModal ? {
      companyName: "",
      overview: "",
      website: "",
      industry: "",
      companySize: "",
      headquarters: "",
      specialities: "",
      spocName: "",
      spocPhone: "",
      spocEmail: "",
      jobTitle: "",
      location: ""
    } : {
      companyName: props.editValues.company.name,
      overview: props.editValues.company.description,
      website: props.editValues.company.website,
      industry: props.editValues.company.industry,
      companySize: props.editValues.company.no_of_employee,
      headquarters: props.editValues.company.headquarters,
      specialities: props.editValues.company.specialities,
      spocName: props.editValues.client?.name ? props.editValues.client?.name : "",
      spocPhone: props.editValues.client?.mobile ? props.editValues.client?.mobile : "",
      spocEmail: props.editValues.client?.email ? props.editValues.client?.email : "",
      jobTitle: props.editValues.client?.jobTitle ? props.editValues.client?.jobTitle : "",
      location: props.editValues.client?.location ? props.editValues.client?.location : "",
    },
    validationSchema: !selected ? validationSchema : clintValidationSchema,
    onSubmit: !props.editLeadModal ? (values) => {
      addLead(values)
    } : (values) => {
      editLead(values)
    }
  });

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "70%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
      outline: "none",
      index: "9999",
    },
  };

  const handleIndustryChange = (data) => {
    formikDetails.setFieldValue("industry", data[0]?.value);
    setSelectedIndustryData([...data]);

  };

  const handleSelectIndustryDataBlur = (event) => {
    if (event.currentTarget.contains(event.relatedTarget)) {
      formikDetails.setFieldTouched("industry", true);
      if (formikDetails.touched.industry && !formikDetails.values.industry) {
        formikDetails.setFieldError("industry", "Industry is required");
      }
    }
  };
  // const handleSelectcompanyDataBlur = (event) => {
  //   if (event.currentTarget.contains(event.relatedTarget)) {
  //     formikDetails.setFieldTouched("companyName", false);
  //     if (formikDetails.touched.companyName || !formikDetails.values.companyName) {
  //       formikDetails.setFieldError("companyName", "Company Name is required");
  //     }
  //   }
  // };


  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const response = await getIndustriesListReq();
        const companies = response.data.response.map((company) => ({
          label: company,
          value: company,
        }));
        setIndustryData(companies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchIndustries();
    const fetchCompanies = async () => {
      try {
        const response = await getAllCompany();
        const companies = response.data.response.map((company) => ({
          label: company,
          value: company,
        }));
        setCompanyData(companies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, []);

  const [headquarterData, setHeadquarterData] = useState([
    { value: "Banglore", label: "Banglore" },
    { value: "Bhubaneswar", label: "Bhubaneswar" },
    { value: "Chennai", label: "Chennai" },
  ]);
  const [selectedHeadquarterData, setSelectedHeadquarterData] = useState([]);
  const handleHeadquarterChange = (data) => {
    formikDetails.setFieldValue("headquarters", data?.[0].value);

    setSelectedHeadquarterData([...data]);
  };
  const handleHeadquarterBlur = (event) => {
    if (event.currentTarget.contains(event.relatedTarget)) {
      formikDetails.setFieldTouched("headquarters", true);
      if (
        formikDetails.touched.headquarters &&
        !formikDetails.values.headquarters
      ) {
        formikDetails.setFieldError("headquarters", "Industry is required");
      }
    }
  };

  const checkAvailability = async (id, value) => {
    try {
      const response = await existEmailPhoneCheck(value);
      setAvailabilityStatusEmail(response?.response?.data?.response);
    } catch (error) {
      console.error("Error occurred while checking availability:", error);
    }
  };
  const handleOnBlur = (id) => {
    if (!formikDetails.values.spocPhone) {
      setError({ ...error, [id]: true });
    } else if (!isValidPhoneNumber("+" + value?.toString())) {
      setError({ ...error, [id]: false, validPhone: true });
    } else {
      setError({ ...error, [id]: false, validPhone: false });
    }
  };
  const handleDropdownChange = (data, type, keysToBeCleared) => {
    data[0].value && setSelected(true)
    console.log(data[0]?.value, "data[0]?.value", data)
    let allSelectedData = { ...selectedcompanyData };
    formikDetails.values.companyName = data[0]?.value;
    allSelectedData[type] = [...data];
    fetchCompanyDetails(data[0].value)
    setSelectedCompanyData({ ...allSelectedData });
  };

  const handleSpecialitiesBlur = (event) => {
    if (event.currentTarget.contains(event.relatedTarget)) {
      formikDetails.setFieldTouched("specialities", true);
      if (
        formikDetails.touched.specialities && !selected &&
        !formikDetails.values.specialities
      ) {
        formikDetails.setFieldError("specialities", "Industry is required");
      }
    }
  };
  function handleTrimmedBlur(event) {
    event.target.value = event.target.value.trim();
    formikDetails.handleChange(event);
    formikDetails.handleBlur(event);
  }
  return (
    <Modal
      isOpen={props.newCompanyForm || props.editLeadModal}
      style={customStylesPage}
      className="p-px"
      ariaHideApp={false}
    >
      <div>
        <form>
          <div className="addAdminModalContent">
            <div className="flex justify-between create-modal-heading p-2">
              <p onClick={() => { console.log(formikDetails, props.editValues,) }}>{props.editLeadModal ? "Edit Company" : "Add New Company"}</p>
              <div>
                <span className="modal-close-icon">
                  <button
                    onClick={() => {
                      if (props.editLeadModal) {
                        props.setEditLeadModal(false)
                      } else {
                        props.setNewCompanyForm(false);
                      }
                    }}
                    type="button"
                    className="modal-close-btn p-3"
                  >
                    <HighlightOffIcon />
                  </button>
                </span>
              </div>
            </div>

            <div className="formContent">
              <form>
                <div>
                  <div style={{ height: "570px" }} className="allContents">
                    <div className="form-field flex float-right">
                    </div>
                    <div className="form-field col-5 my-2 ">
                      <label htmlFor="companyName">Company Name</label><span className="text-red-500  ">*</span>
                      <div
                        className={`${(formikDetails.errors.companyName &&
                          formikDetails.touched.companyName) ||
                          (formikDetails.touched.companyName &&
                            !formikDetails.values.companyName)
                          ? "error-input"
                          : ""
                          }`}
                      // onBlur={handleSelectcompanyDataBlur}
                      >
                        {console.log(selectedcompanyData["companyName"], "selectedcompanyData.value")}
                        {!newCompanyform ?
                          <Select
                            noOptionsMessage={() => {
                              return "No more options";
                            }}
                            options={companyData}
                            value={selectedcompanyData["companyName"]?.value === "" ? null : selectedcompanyData["companyName"] ? selectedcompanyData["companyName"] : ""}
                            isSearchable={true}
                            onBlur={formikDetails.handleBlur}
                            placeholder="Select Company Name"
                            onChange={(data) => {
                              handleDropdownChange([data], "companyName", [
                                "companyName",
                                "companyName",
                              ]);
                            }}
                          />
                          // <SelectDropdown
                          //   dpName={"companyName"}
                          //   data={companyData}
                          //   onChange={(e) => { handleCompanyChange(e) }}
                          //   placeholder="Select a Company Name"
                          //   selectedData={selectedcompanyData}
                          //   searchable={true}
                          // />
                          : <TextField
                            id="companyName"
                            name="companyName"
                            value={formikDetails.values.companyName}
                            onChange={formikDetails.handleChange}
                            placeholder="Enter Company Name"
                            onBlur={formikDetails.handleBlur}
                            error={
                              formikDetails.touched.companyName &&
                              formikDetails.errors.companyName
                            }
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            inputProps={{
                              style: {
                                padding: "9.5px 14px",
                                height: "0.43em !important",
                              },
                            }}
                            style={{ width: "100%", marginTop: "1px" }}
                          />}
                      </div>
                      {formikDetails.touched.companyName &&
                        formikDetails.errors.companyName && (
                          <div className="error-message mt-1 ml-1">
                            {formikDetails.errors.companyName}
                          </div>
                        )}

                      <p
                        className="flex display-end float-right text-blue-800 cursor-pointer"
                        onClick={(e) => {
                          // if (props.editLeadModal) {
                          //   setEditLeedModal(true)
                          // }
                          setSelectedIndustryData([]);
                          setSelectedCompanyData([])
                          setSelected(false)
                          formikDetails.resetForm({
                            values: {
                              ...formikDetails.values,
                              overview: "",
                              industry: "",
                              companyName: "",
                              companySize: "",
                              headquarters: "",
                              specialities: "",
                              website: "",
                              jobTitle: formikDetails.values.jobTitle ? formikDetails.values.jobTitle : "",
                              location: formikDetails.values.location ? formikDetails.values.location : "",
                              spocEmail: formikDetails.values.spocEmail ? formikDetails.values.spocEmail : "",
                              spocName: formikDetails.values.spocName ? formikDetails.values.spocName : "",
                              spocPhone: formikDetails.values.spocPhone ? formikDetails.values.spocPhone : ""
                            },
                          }
                          );
                          newCompanyform ? setNewCompanyform(false) : setNewCompanyform(true);
                        }}
                      >
                        {newCompanyform ? "Select Company" : "+ New Company"}
                      </p>

                    </div>

                    <div className="Overview">
                      {newCompanyform &&
                        <>
                          <div className="col">
                            <div className="form-field">
                              <label className="create-labels">Overview</label><span className="text-red-500">*</span>
                              <TextField
                                multiline
                                maxRows={Infinity}
                                id="overview"
                                name="overview"
                                value={formikDetails.values.overview}
                                onChange={formikDetails.handleChange}
                                onBlur={formikDetails.handleBlur}
                                error={
                                  formikDetails.touched.overview &&
                                  formikDetails.errors.overview
                                }
                                placeholder="Type here..."
                                minRows={3}
                                style={{ width: "100%", marginBottom: "16px" }}
                              />
                              {formikDetails.errors.overview && !selected &&
                                formikDetails.touched.overview && (
                                  <div className="error-message -mt-3">
                                    {formikDetails.errors.overview}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="containersection">
                            <div className="row">
                              <div className="form-field my-2 col-4">
                                <label htmlFor="industry ">Industry</label><span className="text-red-500  ">*</span>
                                <div
                                  style={{ height: "45px" }}
                                  className={`${(formikDetails.errors.industry &&
                                    formikDetails.touched.industry) ||
                                    (formikDetails.touched.industry &&
                                      !formikDetails.values.industry)
                                    ? "error-input"
                                    : ""
                                    }`}
                                  onBlur={handleSelectIndustryDataBlur}
                                >
                                  {/* <Select
                                    dpName="industry"
                                    options={industryData}
                                    onChange={handleIndustryChange}
                                    placeholder="Select the industry"
                                    // onMenuOpen={props.streamDataHandler}
                                    // value={props.selectedDropdownData.dgStream}
                                    value={formikDetails.values.industry }
                                    isSearchable={true}
                                  /> */}
                                  {console.log(selectedIndustryData, "selectedIndustryData")}
                                  <SelectDropdown
                                    data={industryData}
                                    onChange={handleIndustryChange}
                                    selectedData={selectedIndustryData}
                                    searchable={false}
                                  />
                                </div>

                                {formikDetails.touched.industry &&
                                  formikDetails.errors.industry && (
                                    <div className="error-message mt-1 ml-1">
                                      {formikDetails.errors.industry}
                                    </div>
                                  )}
                              </div>
                              <div className="form-field my-2 col-4">
                                <label htmlFor="companySize">Company Size</label><span className="text-red-500  ">*</span>

                                <div
                                  className={`w-full  ${(formikDetails.errors.companySize &&
                                    formikDetails.touched.companySize) ||
                                    (formikDetails.touched.companySize && !selected &&
                                      !formikDetails.values.companySize)
                                    ? "error-input"
                                    : ""
                                    }`}
                                >
                                  <TextField
                                    id="companySize"
                                    name="companySize"
                                    value={formikDetails.values.companySize}
                                    onChange={formikDetails.handleChange}
                                    placeholder="Enter company size"
                                    onBlur={formikDetails.handleBlur}
                                    error={
                                      formikDetails.touched.companySize &&
                                      formikDetails.errors.companySize
                                    }
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    inputProps={{
                                      style: {
                                        padding: "9.5px 14px",
                                        height: "0.43em !important",
                                      },
                                    }}
                                    style={{ width: "100%", marginTop: "1px" }}
                                  />
                                </div>
                                {formikDetails.touched.companySize &&
                                  formikDetails.errors.companySize && (
                                    <div className="error-message ml-1">
                                      {formikDetails.errors.companySize}
                                    </div>
                                  )}
                              </div>
                              <div className="form-field my-2 col-4">
                                <label htmlFor="headquarters">Headquarters</label><span className="text-red-500  ">*</span>

                                <div
                                  className={`w-full  ${(formikDetails.errors.headquarters &&
                                    formikDetails.touched.headquarters) ||
                                    (formikDetails.touched.headquarters && !selected &&
                                      !formikDetails.values.headquarters)
                                    ? "error-input"
                                    : ""
                                    }`}
                                  onBlur={handleHeadquarterBlur}
                                >
                                  <TextField
                                    id="headquarters"
                                    name="headquarters"
                                    value={formikDetails.values.headquarters}
                                    onChange={formikDetails.handleChange}
                                    placeholder="Enter headquarters"
                                    onBlur={formikDetails.handleBlur}
                                    error={
                                      formikDetails.touched.headquarters &&
                                      formikDetails.errors.headquarters
                                    }
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    inputProps={{
                                      style: {
                                        padding: "9.5px 14px",
                                        height: "0.43em !important",
                                      },
                                    }}
                                    style={{ width: "100%", marginTop: "1px" }}
                                  />
                                </div>
                                {formikDetails.touched.headquarters &&
                                  formikDetails.errors.headquarters && (
                                    <div className="error-message   ml-1">
                                      {formikDetails.errors.headquarters}
                                    </div>
                                  )}
                              </div>
                              <div className="form-field my-2 col-4">
                                <label htmlFor="specialities">Specialities</label><span className="text-red-500  ">*</span>
                                <div
                                  className={`w-full  ${(formikDetails.errors.specialities &&
                                    formikDetails.touched.specialities) ||
                                    (formikDetails.touched.specialities &&
                                      !formikDetails.values.specialities)
                                    ? "error-input"
                                    : ""
                                    }`}
                                  onBlur={handleSpecialitiesBlur}
                                >
                                  <TextField
                                    id="specialities"
                                    name="specialities"
                                    value={formikDetails.values.specialities}
                                    onChange={formikDetails.handleChange}
                                    placeholder="Enter specialities"
                                    onBlur={formikDetails.handleBlur}
                                    error={
                                      formikDetails.touched.specialities &&
                                      formikDetails.errors.specialities
                                    }
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    inputProps={{
                                      style: {
                                        padding: "9.5px 14px",
                                        height: "0.43em !important",
                                      },
                                    }}
                                    style={{ width: "100%", marginTop: "1px" }}
                                  />
                                </div>
                                {formikDetails.touched.specialities &&
                                  formikDetails.errors.specialities && (
                                    <div className="error-message   ml-1">
                                      {formikDetails.errors.specialities}
                                    </div>
                                  )}
                              </div>
                              <div className="form-field my-2 col-4">
                                <label htmlFor="headquarters">Website</label><span className="text-red-500  ">*</span>

                                <div
                                  className={`w-full  ${(formikDetails.errors.website &&
                                    formikDetails.touched.website) ||
                                    (formikDetails.touched.website && !selected &&
                                      !formikDetails.values.website)
                                    ? "error-input"
                                    : ""
                                    }`}
                                  onBlur={handleHeadquarterBlur}
                                >
                                  <TextField
                                    id="website"
                                    name="website"
                                    value={formikDetails.values.website}
                                    onChange={formikDetails.handleChange}
                                    placeholder="Enter website"
                                    onBlur={formikDetails.handleBlur}
                                    error={
                                      formikDetails.touched.website &&
                                      formikDetails.errors.website
                                    }
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    inputProps={{
                                      style: {
                                        padding: "9.5px 14px",
                                        height: "0.43em !important",
                                      },
                                    }}
                                    style={{ width: "100%", marginTop: "1px" }}
                                  />
                                </div>
                                {formikDetails.touched.website &&
                                  formikDetails.errors.website && (
                                    <div className="error-message   ml-1">
                                      {formikDetails.errors.website}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                    <div style={{ width: "100% !important" }} className="border-solid containersection my-2">
                      <h5 style={{ position: "relative", top: "20px" }} className="text-zinc-950">Client Details</h5>
                      <div style={{ width: "100%", position: "relative", top: "20px" }} className="row">
                        <div className="form-field my-2 col-6 ">
                          <label className="create-labels">Name</label>
                          <TextField
                            autoComplete="off"
                            id="spocName"
                            name="spocName"
                            value={formikDetails.values.spocName}
                            onChange={formikDetails.handleChange}
                            placeholder="Enter Client Name"
                            onBlur={formikDetails.handleBlur}
                            error={
                              formikDetails.touched.spocName &&
                              formikDetails.errors.spocName
                            }
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            inputProps={{
                              style: {
                                padding: "9.5px 14px",
                                height: "0.43em !important",
                              },
                            }}
                            style={{ width: "100%", marginTop: "1px" }}
                          />
                          {formikDetails.touched.spocName &&
                            formikDetails.errors.spocName && (
                              <div className="error-message ml-1">
                                {formikDetails.errors.spocName}
                              </div>
                            )}
                        </div>
                        <div className="form-field my-2 col-6 ">
                          <label className="create-labels">Phone Number</label>
                          <PhoneInput
                            searchPlaceholder="Search..."
                            searchNotFound="No Countries Found"
                            specialLabel=""
                            defaultCountry={
                              presentCountry && presentCountry.toLowerCase()
                            }
                            autoFormat={false}
                            enableAreaCodeStretch
                            country={presentCountry && presentCountry.toLowerCase()}
                            style={{
                              borderRadius: "5px",
                              width: "100%", marginTop: "1px"
                            }}
                            countryCodeEditable={false}
                            id="spocPhone"
                            name="spocPhone"
                            value={formikDetails.values.spocPhone}
                            onChange={(e) => {
                              formikDetails.setFieldValue("spocPhone", e);
                              setValue(e);
                            }}
                            // onBlur={(e) => {
                            //   handleOnBlur("mobile");
                            //   console.log(e);
                            //   const phoneNumber = e.target.value.replace(/\s/g, "");
                            //   const formattedPhoneNumber = phoneNumber.startsWith("+91")
                            //     ? phoneNumber
                            //     : `+91${phoneNumber}`;
                            //   checkAvailability("mobile", formattedPhoneNumber);
                            // }}
                            placeholder="Enter Phone Number"
                            onBlur={(e) => {
                              formikDetails.handleBlur(e);
                              console.log(error)
                              handleOnBlur("phone");
                            }}
                            error={
                              formikDetails.touched.spocPhone &&
                              formikDetails.errors.spocPhone
                            }
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            inputProps={{
                              style: {
                                height: "41px",
                                width: "100%"
                              },
                            }}
                          />
                          {(manualError.spocName && props.editLeadModal) && (
                            <div className="error-message pt-1 ">
                              Phone Number is required
                            </div>
                          )}
                          {error.validPhone && !error.phone && (
                            <div className="error-message pt-1">Invalid phone number</div>
                          )}
                        </div>
                        <div className="form-field my-2 col-6 mb-0">
                          <label className="create-labels">Email</label>
                          <TextField
                            autoComplete="off"
                            id="spocEmail"
                            name="spocEmail"
                            value={formikDetails.values.spocEmail}
                            onChange={(e) => { formikDetails.handleChange(e); setAvailabilityStatusEmail(""); }}
                            onBlur={(event) => {
                              formikDetails.handleBlur(event);
                              handleTrimmedBlur(event);
                              // checkValidEmail(event);
                              checkAvailability(event.target.id, event.target.value);
                            }}
                            placeholder="Enter Email id"
                            // onBlur={formikDetails.handleBlur}
                            error={
                              formikDetails.touched.spocEmail &&
                              formikDetails.errors.spocEmail
                            }
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            inputProps={{
                              style: {
                                padding: "9.5px 14px",
                                height: "0.43em !important",
                              },
                            }}
                            style={{ width: "100%", marginTop: "1px" }}
                          />
                          {/* {(formikDetails.errors.spocEmail || formikDetails.touched.spocEmail) ? (
                            <div style={{ position: "relative" }} className="text-red-500 error_text md:mt-1">
                              {formikDetails.errors.spocEmail}
                            </div>
                          ) : (
                            <div style={{ position: "relative" }} className="text-red-500 error_text md:mt-1">
                              {availabilityStatusEmail}
                            </div>
                          )} */}
                          {formikDetails.errors.spocEmail ? (
                            <div style={{ position: "relative" }} className="text-red-500 error_text md:mt-1">
                              {formikDetails.errors.spocEmail}
                            </div>
                          ) : null}
                          <div style={{ position: "relative", }} className="text-red-500 error_text md:mt-1">
                            {availabilityStatusEmail}
                          </div>

                        </div>
                        <div className="form-field my-2 col-6 ">
                          <label className="create-labels">Job Title</label>
                          <TextField
                            autoComplete="off"
                            id="jobTitle"
                            name="jobTitle"
                            value={formikDetails.values.jobTitle}
                            onChange={formikDetails.handleChange}
                            placeholder="Enter Job Title name"
                            onBlur={formikDetails.handleBlur}
                            error={
                              formikDetails.touched.jobTitle &&
                              formikDetails.errors.jobTitle
                            }
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            inputProps={{
                              style: {
                                padding: "9.5px 14px",
                                height: "0.43em !important",
                              },
                            }}
                            style={{ width: "100%", marginTop: "1px" }}
                          />
                          {(manualError.spocName && props.editLeadModal) && (
                            <div className="error-message -mt-1.5">
                              Job Title is required
                            </div>
                          )}
                        </div>
                        {/* <div className="form-field my-2 col-6 ">
                          <label className="create-labels">Company Name</label>
                          <TextField
                            autoComplete="off"
                            id="compName"
                            name="companyName"
                            value={props.editLeadModal ? props.editValues.company.name : formikDetails.values.compName}
                            onChange={formikDetails.handleChange}
                            placeholder="Enter Company Name"
                            onBlur={formikDetails.handleBlur}
                            error={
                              formikDetails.touched.compName &&
                              formikDetails.errors.compName
                            }
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            inputProps={{
                              style: {
                                padding: "9.5px 14px",
                                height: "0.43em !important",
                              },
                            }}
                            style={{ width: "100%", marginTop: "1px" }}
                          />
                          {manualError.compName && (
                            <div className="error-message -mt-1.5">
                              Company name is required
                            </div>
                          )}
                        </div> */}
                        <div className="form-field my-2 col-6 ">
                          <label className="create-labels">Location</label>
                          <TextField
                            autoComplete="off"
                            id="location"
                            name="location"
                            value={formikDetails.values.location}
                            onChange={formikDetails.handleChange}
                            placeholder="Enter the location"
                            onBlur={formikDetails.handleBlur}
                            error={
                              formikDetails.touched.location &&
                              formikDetails.errors.location
                            }
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            inputProps={{
                              style: {
                                padding: "9.5px 14px",
                                height: "0.43em !important",
                              },
                            }}
                            style={{ width: "100%", marginTop: "1px" }}
                          />
                          {(manualError.spocName && props.editLeadModal) && (
                            <div className="error-message -mt-1.5">
                              location is required
                            </div>
                          )}

                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    formikDetails.handleSubmit();
                  }}
                  className="addCompButn"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {!props.editLeadModal ? "Add" : "Update"}
                </button>
              </form>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default NewCompanyAdd