import { useState,useEffect } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "react-modal";
import "./MapCompanyModal.scss";
import { SelectDropdown } from "../../../landing-page/common/select-dropdown";
import { useFormik } from "formik";
import * as Yup from "yup";
import InfoIcon from '@mui/icons-material/Info';
import {getAllCompany,mapCompany} from "../../../../api/api-services"




function MapCompanyModal(props) {
  const [openModal, setOpenModal] = useState(true);
  const [listOfCompanies, setListOfCompanies] = useState([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await getAllCompany();
        const companies = response.data.response.map((company) => ({
          label: company,
          value: company,
        }));
        setListOfCompanies(companies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "85%",
      transform: "translate(-50%, -50%)",
      width: "45%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
      outline: "none",
    },
  };

  const validationSchema = Yup.object().shape({
    selectedInterviewStatusData: Yup.array()
      .min(1, "Please select the company details")
      .required("Please select interview status"),
  });

  const formik = useFormik({
    initialValues: {
      selectedInterviewStatusData: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const { selectedInterviewStatusData } = values;
        // Assuming only one company is selected
        const companyName = selectedInterviewStatusData[0].value; 
        const response = await mapCompany(props.jobPostId, companyName);
        

    if (response.data.statusCode === 200) {
      props.toast.success(
        <>
          <div className="toast-heading">Success</div>
          <div>Mapped with {companyName} </div>
        </>
      );
     props.fetchNewJobPosts(0,10)
      props.closeMapCompanyModal(false);
    }
      } catch (error) {
        console.error("Error mapping company:", error);
      }
    },
  });

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    formik;

  const handleRoleChange = (data) => {
    formik.setFieldValue("selectedInterviewStatusData", data);
 
  };

  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      style={customStylesPage}
      className="p-px"
    >
      <div className="flex justify-between mapCompanymodal-heading">
        <p>Select Company</p>
        <div>
          <span className="modal-close-icon">
            <button
              onClick={() => {
                props.closeMapCompanyModal(false);
              }}
              type="button"
              className="modal-close-btn relative right-24 p-3"
            >
              <HighlightOffIcon />
            </button>
          </span>
        </div>
      </div>

      <form className="formComponent" onSubmit={handleSubmit}>
        <div className="px-2 content-form">
        <div className="flex p-1.5">
        <p className=""><InfoIcon style={{color:"blue"}}/></p>
        <p className="formInfo mx-2">Please pick the appropriate company before approving any candidates</p>

        </div>
          <div className="popins-medium ">
            <SelectDropdown
              data={listOfCompanies}
              onChange={handleRoleChange}
              selectedData={values.selectedInterviewStatusData}
              onBlur={() => formik.handleBlur("selectedInterviewStatusData")}
              error={
                formik.touched.selectedInterviewStatusData &&
                formik.errors.selectedInterviewStatusData
              }
              deselection={true}
              searchable={true}
            />

            {formik.touched.selectedInterviewStatusData &&
              formik.errors.selectedInterviewStatusData && (
                <div className="text-red-500 text-sm error_text md:mt-1">
                  {formik.errors.selectedInterviewStatusData}
                </div>
              )}
          </div>
        </div>

        
         
          <button className="map-button" type="submit">map</button>
       
      </form>
      
    </Modal>
  );
}

export default MapCompanyModal;
