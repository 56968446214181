import React, { useEffect, useState } from 'react'
import JobListCards from './job-list/JobListItem';
import Pagination from '@mui/material/Pagination';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import JobListHome from './job-list/JobListHome';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { getFilteredJobs } from '../../../../api/api-services';
import './JobDataSection.scss'

function JobDataSection(props) {
  const handleChangePage = (event, newPage) => {
    props.setPagination(newPage);
    props.setPage(newPage - 1)
  };

  return (
    <div>
      <div className='flex cursor-pointer d-block d-lg-none paddingresp2'>
        <div onClick={() => {
          props.setSideFilter(true)
        }}>
          <FilterAltOutlinedIcon />
          <span>Filter</span>
        </div>
      </div>
      <div>
        <div  className='flex jobcounts paddingresp'>
          <div>
            <p className='cardCount'>{`${props?.filteredJobs?.totalElements}`} jobs</p>
          </div>
          {/* <div className='flex'>
            <div className='p-1 '><GridViewOutlinedIcon fontSize='small' /></div>
            <div className='p-1'><ViewListOutlinedIcon fontSize='small' /></div>
            <p className='p-1 text'> Sort by Newest </p>
          </div> */}
        </div>
      </div>
      <JobListHome
        page={props.page}
        filteredData={props.filteredJobs}
      />
      <div className='pagination' style={{ marginLeft: "57px" }} >
        <Pagination style={{Color:"#007456"}} count={props.filteredJobs?.totalPages}  page={props.pagination} onChange={handleChangePage} />
      </div>
    </div>
  )
}

export default JobDataSection