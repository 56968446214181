// src/aws-config.js
import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

const s3 = new AWS.S3();

export default s3;