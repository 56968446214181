import React, { useEffect, useRef, useState } from "react";
import "./AdminInterviewer.scss";
import { useTable, useGlobalFilter } from "react-table";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import AdminContextMenuModal from "../AdminContextMenuModal";
import AdminAddEditUser from "../admin-modals/add-interviewer/AdminAddEditUser";
import { ReactComponent as NoDataFound } from "../../../assets/noDataFound.svg";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ReactComponent as FilterIcon } from "../../../assets/filterIcon.svg";
import { Checkbox } from "@material-ui/core";
import DeleteInterViewerModal from "../admin-modals/add-interviewer/DeleteInterViewerModal";
import CandidateTable from "../../common/candidate-table/CandidateTable";
import CandidateColumnOption from "../../common/CandidateColumnOption";
import BdList from "./admin-bd/BdList";
import UnderDevelopmentImage from "../../../assets/under-development.jpg";
import { getAllBd, getInterviewers } from "../../../api/api-services";
import InterviewerList from "./admin-bd/InterviewerList";
import InterviewersCoordinateList from "./admin-bd/InterviewersCoordinateList";
const AdminInterviewer = () => {
  const [addInterViewer, setaddInterViewer] = useState(false);
  const [editInterViewer, setEditInterViewer] = useState(false);
  const [editDetails, setEditDetails] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  let [filterOptions, setFilterOptions] = useState([]);
  let [selectedFilters, setSelectedFilters] = useState({});
  let [openFilter, setOpenFilter] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
 
 
  
  let size = 10;
  let [lastPage, setLastpage] = useState(false);
  let [pageno, setpageNo] = useState(0);
  const cardSearchInput = useRef(null);
  let [tableApiData, setTableApiData] = useState({
    totalCount: 20,
    columnDef: {
      columns: [
        {
          columnName: "interviewerName",
          column_order: 1,
        },
        {
          columnName: "email",
          column_order: 2,
        },
        {
          columnName: "phoneNumber",
          column_order: 3,
        },
        {
          columnName: "status",
          column_order: 4,
        },
      ],
    },
    content: [],
    lastPage: true,
  });
  let [columnData, setColumnData] = useState([]);
  let [hiddenColumnsData, setHiddenColumnsData] = useState([
    "email",
    "gender",    
    "location",
    "highest_degree",
    "requested_clients",
    "shareable",
  ]);

  function onEditProjectModalClose(status) {
    setEditInterViewer(status);
  }
  function onDeleteModalClose(status) {
    setDeleteModalOpen(status);
  }
  function onAddProjectModalClose(status) {
    setaddInterViewer(status);
  }
  const [bdDetailModule, setBdDetailModule] = useState(false)
  const [interviewerData, setInterviewerData] = useState([]);

  const contextMenuData = [
    {
      option: "Details",
    },
    {
      option: "Resend Activation Link",
    },
  ];
  function contextMenuFunction(event, resObj) {
    if (event.target.textContent === "Details") {
      console.log("contextMenuFunctionDetails", resObj, event);
    }
    if (event.target.textContent === "Resend Activation Link") {
      console.log("contextMenuFunctionResend", resObj, event);
    }
  }


  const [bdTotalCount, setBdTotalCount] = useState("");
  const handlebdTotalCount=(data)=>{
    setBdTotalCount(data)
  }
 
  const [InterviewersCount, setInterviewersCount] = useState("");
  const handleInterviewersCount=(data)=>{
    setInterviewersCount(data)
  }

  



  const [interviewersCoordinateCount, setInterviewersCoordinateCount] = useState("");
  const handleinterviewersCoordinateCount=(data)=>{
    setInterviewersCoordinateCount(data)
  }

 
  const handleDetail = () => {
    setBdDetailModule(true)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Interviewer Name",
        accessor: "interviewerName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
      },
      //   {
      //     Header: 'Status',
      //     accessor: 'status',
      //   },
      {
        Header: (
          <div>Status&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        ),
        accessor: "status",
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: (e) => (
          <>
            <button
              className=" hover:text-orange-600"
              onClick={() => {
                setEditInterViewer(true);
                setEditDetails(e.cell.row.original);
              }}
            >
              <ModeEditIcon />
            </button>
            <button
              className="mx-2 hover:text-orange-600"
              onClick={() => {
                setDeleteModalOpen(true);
                setEditDetails(e.cell.row.original);
              }}
            >
              <DeleteIcon />
            </button>
            <button className="hover:text-orange-600">
              <AdminContextMenuModal
                handleDetail={handleDetail}
                contextMenuData={contextMenuData}
                func={(event) => {
                  contextMenuFunction(event, e);
                }}
                onMouseOutz
              />
            </button>
          </>
        ),
      },
    ],
    []
  );

  // Create an instance of the table

  let data = React.useMemo(() => interviewerData, [interviewerData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  let handelSearch = (e) => {
    setGlobalFilter(e.target.value);
  };
  const fetchFilterOptions = () => {
    setFilterOptions([
      {
        type: "Location",
        values: ["Bangalore", "Mysore", "Shivamogga", "Goa", "Mangalore"],
      },
      {
        type: "Experiance",
        values: ["Fresher", "0 - 1 yrs", "1 - 3 yrs", "3 - 5 yrs", "5+ yrs"],
      },
    ]);
  };

  const handleFilterSelection = (e, type, value) => {
    let selectedData = { ...selectedFilters };
    if (e.target.checked) {
      if (selectedData?.[type]?.length > 0) {
        selectedData?.[type].push(value);
      } else {
        selectedData[type] = [value];
      }
    } else {
      if (selectedData?.[type]?.length > 0) {
        let index = selectedData?.[type].indexOf(value);
        selectedData?.[type].splice(index, 1);
      }
    }
    setSelectedFilters({ ...selectedData });
  };
  const handleTabSelect = (index) => {
    const tabNames = ["Interviewers", "Interviewers Coordinate", "BD"];
    const selectedTabName = tabNames[index];
    setSelectedTab(index);
  };
  let actionele = (data) => {
    return (
      <>
        <button
          className=" hover:text-orange-600"
          onClick={() => {
            setEditInterViewer(true);
            setEditDetails(data);
          }}
        >
          <ModeEditIcon />
        </button>
        <button
          className="mx-2 hover:text-orange-600"
          onClick={() => {
            setDeleteModalOpen(true);
            setEditDetails(data);
          }}
        >
          <DeleteIcon />
        </button>
        <button className="hover:text-orange-600">
          <AdminContextMenuModal
            contextMenuData={contextMenuData}
            func={(event) => {
              contextMenuFunction(event, data);
            }}
            // onMouseOutz
          />
        </button>
      </>
    );
  };
  useEffect(() => {
    let contextData = [];
    tableApiData?.columnDef?.columns?.map((data) => {
      contextData.push({
        option: data.columnName,
      });
    });
    setColumnData([...contextData]);
   
    
  }, []);

  return (
    <div className="AdminInterviewerSection mx-3 w-full home-component-display mt-2.5 overflow-auto">
      <div className="headerSection flex">
        <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
          <div className="pannelTabs">
            <TabList className="flex p-3.5  outline-none">
              <Tab className={selectedTab === 0 ? "selectedTab" : ""}>
                {/* Interview Coordinator {interviewersCoordinateCount ? "-" + interviewersCoordinateCount : ""} */}
                Interview Coordinator {interviewersCoordinateCount ? "-" + `${interviewersCoordinateCount}` : ""}
              </Tab>
              <Tab
                className={selectedTab === 1 ? "selectedTab  mx-4" : " mx-4"}
              >
                Interviewer {InterviewersCount ? "-" +` ${InterviewersCount}` : ""}
              </Tab>
              <Tab className={selectedTab === 2 ? "selectedTab" : ""}>BD {bdTotalCount ? "-" + `${bdTotalCount}` : ""}</Tab>
            </TabList>
          </div>

          <TabPanel>
          <InterviewersCoordinateList handleinterviewersCoordinateCount={handleinterviewersCoordinateCount}/>
          </TabPanel>

          <TabPanel>
          <InterviewerList handleInterviewersCount={handleInterviewersCount}/>
          </TabPanel>

          <TabPanel>
          <BdList handlebdTotalCount={handlebdTotalCount}/>
          </TabPanel>
          
        </Tabs>

      </div>
      {addInterViewer && (
        <AdminAddEditUser closeModal={onAddProjectModalClose} />
      )}
      {editInterViewer && (
        <AdminAddEditUser
          closeModal={onEditProjectModalClose}
          editDetails={editDetails}
        />
      )}
      {deleteModalOpen && (
        <DeleteInterViewerModal
          closeModal={onDeleteModalClose}
          editDetails={editDetails}
        />
      )}
    </div>
  );
};

export default AdminInterviewer;
