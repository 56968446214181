// src/VideoPlayer.js
import React from "react";
import s3 from "./aws-config";
import Modal from "react-modal";
import CancelIcon from '@mui/icons-material/Cancel';
import "./VideoPlayerModal.scss";

const VideoPlayerModal = ({ videoKey, closeModal }) => {
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      height: "70%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderTopLeftRadius: "4px",
      opacity: "1",
      outline: "none",
    },
  };
  const videoUrl = s3.getSignedUrl("getObject", {
    Bucket: process.env.REACT_APP_AWS_BUCKET,
    Key: videoKey, // The key of the video file in your S3 bucket
  });
  console.log("url :", videoUrl);

  return (
    <Modal isOpen={true} style={customStylesPage}>
      <div className="grid grid-cols-12">
        <div className="grid grid-cols-12 col-span-12 video-preview-header">
          <div className="col-span-11 ">Video Player</div>
          <div
            className="col-span-1 flex justify-end"
            onClick={() => {
              closeModal(false);
            }}
          >
            <CancelIcon />
          </div>
        </div>
        <div className="col-span-12 mt-3">
          <video controls controlsList="nodownload">
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </Modal>
  );
};

export default VideoPlayerModal;
