import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import CoordinatorJobCard from "./CoordinatorJobCard";
import CandidateColumnOption from "../../common/CandidateColumnOption";
import CandidateTable from "../../common/candidate-table/CandidateTable";
import InterviewModals from "../../common/interview-modals/InterviewModals";
import { Checkbox } from "@material-ui/core";
import { ReactComponent as FilterIcon } from "../../../assets/filterIcon.svg";
import {
  getAdminBdCandReq,
  getAllAdminBdCandReq,
  shareCandReq,
  AllProfileSearch,
  ByStateSearch,
  getIntrestedProfileReq,
  intrestedCandidateSearch
} from "../../../api/api-services";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AdminJobPostDetails from "./../../admin/admin-jobpost/AdminJobPostDetails";


const CoordinatorShareProfile = () => {
 
    const { state } = useLocation();
    const navigate = useNavigate();
    const { requestedProfile } = state;
    const [tabIndex, setTabIndex] = useState(0);
    const cardSearchInput = useRef(null);
    const [isSearching, setIsSearching] = useState(false);
    const [searchedText, setSearchedText] = useState("");
    let [pageno, setpageNo] = useState(0);
    let [lastPage, setLastpage] = useState(false);
    let size = 10;
    let user = JSON.parse(localStorage.getItem("across-geo-user"));
    const [contextMenuData, setContextMenuData] = useState([]);
    let [openFilter, setOpenFilter] = useState(false);
    let [selectCandidate, setSelectedCandidate] = useState({});
    let [selectedTab, setSelectedtab] = useState("Interested Profiles");
    let [isLoading, setIsLoading] = useState(false);
    let [postJobDetails, setPostDetails] = useState(false);
    const [selectedJob, setSelectedJob] = useState({});
    let [tableApiData, setTableApiData] = useState({
      columnDef: {
        columns: [],
      },
      content: [],
    });
    let [scheduleInterviewModal, setScheduleInterviewModal] = useState(false);
    // let [hiddenColumnsData, setHiddenColumnsData] = useState([
    //   "email",
    //   "gender",
    //   "location",
    //   "highest_degree",
    //   "requested_clients",
    //   "shareable",
    // ]);
  
    const [hiddenColumnsData, setHiddenColumnsData] = useState([]);
  
    useEffect(() => {
      if (selectedTab === "Interested Profiles") {
        setHiddenColumnsData([
          "internalInterviewTaken",
          "isPublished",
  
        ]);
      } else {
        setHiddenColumnsData([
          "email",
          "gender",
          "location",
          "highest_degree",
          "requested_clients",
          "shareable",
        ]);
      }
    }, [selectedTab]);
  
    let [selectedFilters, setSelectedFilters] = useState({});
    let [filterOptions, setFilterOptions] = useState([]);
    const fetchFilterOptions = () => {
      setFilterOptions([
        {
          type: "Location",
          values: ["Bangalore", "Mysore", "Shivamogga", "Goa", "Mangalore"],
        },
        {
          type: "Experiance",
          values: ["Fresher", "0 - 1 yrs", "1 - 3 yrs", "3 - 5 yrs", "5+ yrs"],
        },
      ]);
    };
    let handelColumnsDispaly = (e) => {
      let value = e.target.value;
      let data = [...hiddenColumnsData];
      if (data.includes(value) && !e.target.checked) {
        data.splice(data.indexOf(value), 1);
      } else if (e.target.checked) {
        data.push(value);
      }
      setHiddenColumnsData([...data]);
    };
    const [reRenderTable, setReRenderTable] = useState(true)
    let gettableData = async (tab, page) => {
      let payload = {
        jobPostId: requestedProfile.id,
      };
      let response;
      if (tab === "All Profiles") {
        payload.pageNo = page;
        payload.pageSize = size;
        payload.jobId = requestedProfile.id;
        delete payload.jobPostId;
        response = await getAllAdminBdCandReq(payload);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }
  
          setTableApiData(tabledata);
          setReRenderTable(true)
        }
      } else if (tab === "Interested Profiles") {
        payload.pageNo = page;
        payload.pageSize = size;
        payload.jobId = requestedProfile.id;
        delete payload.jobPostId;
        response = await getIntrestedProfileReq(requestedProfile.id, page, size);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }
  
          setTableApiData(tabledata);
          setReRenderTable(true)
        }
      } else if (tab === "Shared Profiles") {
        try {
          payload.pageNo = page;
          payload.pageSize = size;
          payload.candidateState = "SHARED";
          response = await getAdminBdCandReq(payload);
          if (response.data) {
            setLastpage(response?.data?.response?.last);
            if (response?.data?.response?.last === false) {
              setpageNo(page + 1);
            }
            let tabledata = { ...tableApiData };
            if (page === 0) {
              tabledata = response.data.response;
            } else {
              tabledata.content = tabledata.content.concat(
                response.data.response.content
              );
            }
  
            setTableApiData(tabledata);
          }
        } catch (err) {
          console.error(err, "shared dynamic table error");
        }
      } else if (tab === "Shortlisted Profiles") {
        try {
          payload.pageNo = page;
          payload.pageSize = size;
          payload.candidateState = "SHORTLISTED";
          response = await getAdminBdCandReq(payload);
          if (response.data) {
            setLastpage(response?.data?.response?.last);
            if (response?.data?.response?.last === false) {
              setpageNo(page + 1);
            }
            let tabledata = { ...tableApiData };
            if (page === 0) {
              tabledata = response.data.response;
            } else {
              tabledata.content = tabledata.content.concat(
                response.data.response.content
              );
            }
  
            setTableApiData(tabledata);
          }
        } catch (err) {
          console.error(err, "shared dynamic table error");
        }
      } else if (tab === "Scheduled Interviews") {
        try {
          payload.pageNo = page;
          payload.pageSize = size;
          payload.candidateState = "SCHEDULED";
          response = await getAdminBdCandReq(payload);
          if (response.data) {
            setLastpage(response?.data?.response?.last);
            if (response?.data?.response?.last === false) {
              setpageNo(page + 1);
            }
            let tabledata = { ...tableApiData };
            if (page === 0) {
              tabledata = response.data.response;
            } else {
              tabledata.content = tabledata.content.concat(
                response.data.response.content
              );
            }
  
            setTableApiData(tabledata);
          }
        } catch (err) {
          console.error(err, "shared dynamic table error");
        }
      } else if (tab === "Offered") {
        try {
          payload.pageNo = page;
          payload.pageSize = size;
          payload.candidateState = "OFFERED";
          response = await getAdminBdCandReq(payload);
          if (response.data) {
            setLastpage(response?.data?.response?.last);
            if (response?.data?.response?.last === false) {
              setpageNo(page + 1);
            }
            let tabledata = { ...tableApiData };
            if (page === 0) {
              tabledata = response.data.response;
            } else {
              tabledata.content = tabledata.content.concat(
                response.data.response.content
              );
            }
  
            setTableApiData(tabledata);
          }
        } catch (err) {
          console.error(err, "shared dynamic table error");
        }
      }
      if (page === 0) {
        prepareHideDropDown(response.data.response);
      }
      setIsLoading(true);
    };
    let prepareHideDropDown = (response) => {
      let contextData = [];
      response.columnDef.columns.map((data) => {
        contextData.push({
          option: data.columnName,
        });
      });
      setContextMenuData([...contextData]);
    };
    // const handlescroll = (event) => {
    //   if (
    //     Math.ceil(event?.target?.scrollTop + event?.target?.clientHeight) ===
    //     event?.target?.scrollHeight
    //   ) {
    //     if (lastPage === false) {
    //       gettableData(selectedTab, pageno);
    //     }
    //   }
    // };
    const handlescroll = (event) => {
      const target = event?.target;
      if (!target) return;
  
      const scrolledToBottom =
        Math.ceil(target.scrollTop + target.clientHeight) >=
        target.scrollHeight - 1;
  
      if (scrolledToBottom && !lastPage && !isSearching) {
        gettableData(selectedTab, pageno);
      } else if (scrolledToBottom && !lastPage && isSearching) {
        gettableDataSearched(searchedText, selectedTab, pageno);
      }
    };
    let handleTabs = (tab) => {
      if (tab !== selectedTab) {
        setpageNo(0);
        setLastpage(false);
        setIsLoading(true);
  
        if (tab === "Interested Profiles") {
  
          gettableData(tab, 0);
          setSelectedtab("Interested Profiles");
        } else if (tab === "All Profiles") {
          gettableData(tab, 0);
          setSelectedtab("All Profiles");
        } else if (tab === "Shared Profiles") {
          gettableData(tab, 0);
          setSelectedtab("Shared Profiles");
        } else if (tab === "Shortlisted Profiles") {
          gettableData(tab, 0);
          setSelectedtab("Shortlisted Profiles");
        } else if (tab === "Scheduled Interviews") {
          gettableData(tab, 0);
          setSelectedtab("Scheduled Interviews");
        } else if (tab === "Offered") {
          gettableData(tab, 0);
          setSelectedtab("Offered");
        }
        if (cardSearchInput?.current) {
          cardSearchInput.current.value = "";
        }
      }
    };
    const handleFilterSelection = (e, type, value) => {
      let selectedData = { ...selectedFilters };
      if (e.target.checked) {
        if (selectedData?.[type]?.length > 0) {
          selectedData?.[type].push(value);
        } else {
          selectedData[type] = [value];
        }
      } else {
        if (selectedData?.[type]?.length > 0) {
          let index = selectedData?.[type].indexOf(value);
          selectedData?.[type].splice(index, 1);
        }
      }
      setSelectedFilters({ ...selectedData });
    };
    let closeModal = (val) => {
      if (scheduleInterviewModal) {
        setScheduleInterviewModal(false);
      }
    };
    let handleCandidateBtn = async (data, type) => {
      let response;
      let payload = {};
      setSelectedCandidate({ ...data });
      if (type === "All Profiles") {
        payload.bdID = user.uid;
        payload.candidateID = data.id;
        payload.jobID = requestedProfile.id;
        try {
          response = await shareCandReq(payload);
          if (response.data) {
            toast.success(
              <>
                <div className="toast-heading">Success</div>
                <div>Candidate shared successfully</div>
              </>
            );
            setReRenderTable(false)
            gettableData(type, 0);
  
          }
        } catch (err) {
          console.error(err, "share api error");
        }
      } else if (type === "Shared Profiles") {
      } else if (type === "Shortlisted Profiles") {
        setScheduleInterviewModal(true);
      } else if (type === "Scheduled Interviews") {
        setScheduleInterviewModal(true);
      } else if (type === "Offered") {
      }
    };
    let actionele = (data) => {
      return (
        <div className="hire-now-btn">
          {selectedTab === "Offered" && (
            <span className="candidate-status">Offered</span>
          )}
          {(selectedTab === "Scheduled Interviews" ||
            selectedTab === "Shortlisted Profiles" ||
            selectedTab === "All Profiles" ||
            selectedTab === "Shared Profiles") && (
              <button
                className={`datatrimmer pl-2 pr-2 ${selectedTab === "Shared Profiles" || !data?.shareable
                    ? "opacity-50"
                    : ""
                  }`}
                onClick={() => {
                  handleCandidateBtn(data, selectedTab);
                }}
                disabled={selectedTab === "Shared Profiles" || !data?.shareable}
                title={
                  selectedTab === "Shortlisted Profiles"
                    ? "Schedule Interview"
                    : selectedTab === "Scheduled Interviews"
                      ? "Update Interview"
                      : selectedTab === "All Profiles" && !data?.shareable
                        ? `${data.name} has been rejected for this job post`
                        : selectedTab === "All Profiles"
                          ? "Share"
                          : selectedTab === "Shared Profiles"
                            ? "Shared"
                            : ""
                }
              >
                {selectedTab === "Shortlisted Profiles"
                  ? "Schedule Interview"
                  : selectedTab === "Scheduled Interviews"
                    ? "Update Interview"
                    : selectedTab === "All Profiles"
                      ? "Share"
                      : selectedTab === "Shared Profiles"
                        ? "Shared"
                        : ""}
              </button>
            )}
        </div>
      );
    };
    const resetSearch = () => {
      setIsSearching(false);
      setpageNo(0);
      setLastpage(false);
    };
    let gettableDataSearched = async (e, tab, page) => {
      let payload = {
        jobPostId: requestedProfile.id,
      };
      let response;
      if (tab === "Interested Profiles") {
  
        setIsSearching(true); // Set the flag to indicate search
        if (page === 0) {
          prepareHideDropDown(response.data.response);
        }
        let prepareHideDropDown = (response) => {
          let contextData = [];
          response.columnDef.columns.map((data) => {
            contextData.push({
              option: data.columnName,
            });
          });
          setContextMenuData([...contextData]);
        };
        setIsLoading(true);
        setSearchedText(e);
        response = await intrestedCandidateSearch(requestedProfile.id, page, size, e);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }
  
          setTableApiData(tabledata);
          setReRenderTable(true)
        }
      }
      else if (tab === "All Profiles") {
        payload.pageNo = page;
        payload.pageSize = size;
        payload.jobId = requestedProfile.id;
        delete payload.jobPostId;
        setIsSearching(true); // Set the flag to indicate search
        setIsLoading(true);
        setSearchedText(e);
        response = await AllProfileSearch(requestedProfile.id, e, page, size);
        if (response.data) {
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }
  
          setTableApiData(tabledata);
          setReRenderTable(true)
        }
      }
      else if (tab === "Shared Profiles") {
        try {
          payload.pageNo = page;
          payload.pageSize = size;
          payload.candidateState = "SHARED";
          response = await ByStateSearch(e, payload);
          setIsSearching(true); // Set the flag to indicate search
          setIsLoading(true);
          setSearchedText(e);
          if (response.data) {
            setLastpage(response?.data?.response?.last);
            if (response?.data?.response?.last === false) {
              setpageNo(page + 1);
            }
            let tabledata = { ...tableApiData };
            if (page === 0) {
              tabledata = response.data.response;
            } else {
              tabledata.content = tabledata.content.concat(
                response.data.response.content
              );
            }
  
            setTableApiData(tabledata);
          }
        } catch (err) {
          console.error(err, "shared dynamic table error");
        }
      }
      else if (tab === "Shortlisted Profiles") {
        try {
          payload.pageNo = page;
          payload.pageSize = size;
          payload.candidateState = "SHORTLISTED";
          response = await ByStateSearch(e, payload);
          setIsSearching(true); // Set the flag to indicate search
          setIsLoading(true);
          setSearchedText(e);
          if (response.data) {
            setLastpage(response?.data?.response?.last);
            if (response?.data?.response?.last === false) {
              setpageNo(page + 1);
            }
            let tabledata = { ...tableApiData };
            if (page === 0) {
              tabledata = response.data.response;
            } else {
              tabledata.content = tabledata.content.concat(
                response.data.response.content
              );
            }
  
            setTableApiData(tabledata);
          }
        } catch (err) {
          console.error(err, "shared dynamic table error");
        }
      }
      else if (tab === "Interview Scheduled") {
        try {
          payload.pageNo = page;
          payload.pageSize = size;
          payload.candidateState = "SCHEDULED";
          response = await ByStateSearch(e, payload);
          setIsSearching(true); // Set the flag to indicate search
          setIsLoading(true);
          setSearchedText(e);
          if (response.data) {
            setLastpage(response?.data?.response?.last);
            if (response?.data?.response?.last === false) {
              setpageNo(page + 1);
            }
            let tabledata = { ...tableApiData };
            if (page === 0) {
              tabledata = response.data.response;
            } else {
              tabledata.content = tabledata.content.concat(
                response.data.response.content
              );
            }
  
            setTableApiData(tabledata);
          }
        } catch (err) {
          console.error(err, "shared dynamic table error");
        }
      }
      else if (tab === "Offered") {
        try {
          payload.pageNo = page;
          payload.pageSize = size;
          payload.candidateState = "OFFERED";
          response = await ByStateSearch(e, payload);
          setIsSearching(true); // Set the flag to indicate search
          setIsLoading(true);
          setSearchedText(e);
          if (response.data) {
            setLastpage(response?.data?.response?.last);
            if (response?.data?.response?.last === false) {
              setpageNo(page + 1);
            }
            let tabledata = { ...tableApiData };
            if (page === 0) {
              tabledata = response.data.response;
            } else {
              tabledata.content = tabledata.content.concat(
                response.data.response.content
              );
            }
  
            setTableApiData(tabledata);
          }
        } catch (err) {
          console.error(err, "shared dynamic table error");
        }
      }
      if (page === 0) {
        prepareHideDropDown(response.data.response);
      }
      if (page === 0) {
        prepareHideDropDown(response.data.response);
      }
      let prepareHideDropDown = (response) => {
        let contextData = [];
        response.columnDef.columns.map((data) => {
          contextData.push({
            option: data.columnName,
          });
        });
        setContextMenuData([...contextData]);
      };
      setIsLoading(true);
    };
    useEffect(() => {
      setIsLoading(false);
      gettableData("Interested Profiles", 0);
  
    }, []);
    const handelMoreBtn = (data, type) => {
      setSelectedJob({ ...data });
      if (type === "Details") {
        setPostDetails(true);
      }
    };
    let closeModalDetails = (val) => {
      setPostDetails(false);
    };
    return (
      <>
        {postJobDetails ? (
          <AdminJobPostDetails
            jobModalHeight="355px"
            closeModal={closeModalDetails}
            selectedJob={selectedJob}
          />
        ) : (
          <div className="bdJobPostContainer">
            <div className="cnclBtnDiv">
              <button
                className="cancelBtn"
                onClick={() => {
                  navigate("/coordinator/job-post");
                }}
              >
                Back
              </button>
            </div>
            <div className="job-card-sharedProfile my-2">
              <CoordinatorJobCard
                handelMoreBtn={handelMoreBtn}
                job={requestedProfile}
                showShareBtn="false"
                from="sharedProfile"
              />
            </div>
  
            <div className="client-interview-candidate-section adminSharedProfile-section mx-3 w-full home-component-display mt-2.5 overflow-auto">
              <div className="componentHeader flex">
                <div
                  className={`${selectedTab === "Interested Profiles"
                      ? "selected-tabletitle text-lg"
                      : "tabletitle text-lg"
                    } cursor-pointer`}
                  onClick={() => {
                    handleTabs("Interested Profiles");
                  }}
                >
                  <span>Interested Profiles</span>
                </div>
                <div
                  className={`${selectedTab === "All Profiles"
                      ? "selected-tabletitle"
                      : "tabletitle"
                    } cursor-pointer`}
                  onClick={() => {
                    handleTabs("All Profiles");
                  }}
                >
                  <span>All Profiles</span>
                </div>
                <div
                  className={`${selectedTab === "Shared Profiles"
                      ? "selected-tabletitle"
                      : "tabletitle"
                    } cursor-pointer`}
                  onClick={() => {
                    handleTabs("Shared Profiles");
                  }}
                >
                  <span>Shared Profiles</span>
                </div>
                <div
                  className={`${selectedTab === "Shortlisted Profiles"
                      ? "selected-tabletitle"
                      : "tabletitle"
                    } cursor-pointer`}
                  onClick={() => {
                    handleTabs("Shortlisted Profiles");
                  }}
                >
                  <span>Shortlisted Profiles</span>
                </div>
                <div
                  className={`${selectedTab === "Scheduled Interviews"
                      ? "selected-tabletitle"
                      : "tabletitle"
                    } cursor-pointer`}
                  onClick={() => {
                    handleTabs("Scheduled Interviews");
                  }}
                >
                  <span>Scheduled Interviews</span>
                </div>
                <div
                  className={`${selectedTab === "Offered"
                      ? "selected-tabletitle"
                      : "tabletitle"
                    } cursor-pointer`}
                  onClick={() => {
                    handleTabs("Offered");
                  }}
                >
                  <span>Offered</span>
                </div>
                <div className="clickables">
                  <CandidateColumnOption
                    contextMenuData={contextMenuData}
                    func={(event) => {
                      handelColumnsDispaly(event);
                    }}
                    hiddenColumnsData={hiddenColumnsData}
                  />
                </div>
              </div>
              <div
                className={`hireTable ${openFilter ? "table-with-filter" : null
                  } `}
              >
                <input
                  className="table-search-input-coordinator col-span-2 "
                  onChange={(e) => {
                    if (e.target.value.trim() === "") {
                      resetSearch();
                      gettableData(selectedTab, pageno);
                    } else {
                      gettableDataSearched(e.target.value, selectedTab, 0);
                    }
                  }}
                  ref={cardSearchInput}
                  type="text"
                  placeholder="Search"
                />
                {reRenderTable && <CandidateTable
                  isLoading={isLoading}
                  tableApiData={tableApiData}
                  actionele={actionele}
                  actionColumn={selectedTab !== "Interested Profiles" && true}
                  handlescroll={handlescroll}
                  actionColumnWidth={"15%"}
                  profileColumn={"name"}
                  hiddenColumnsData={hiddenColumnsData}
                  selectedTab={selectedTab}
                  showVideo={selectedTab !== "Interested Profiles" && "both"}
                  tableType={"admin-table"}
                />}
              </div>
              {openFilter && (
                <div className="hire-filter">
                  <div className="filter-header">
                    <span>Filter By</span>
                    <span className="apply-btn">
                      <button
                        onClick={() => {
                          setOpenFilter(!openFilter);
                        }}
                      >
                        Apply
                      </button>
                    </span>
                  </div>
                  {filterOptions.map((data, index) => {
                    return (
                      <div className="individual-type">
                        <div>{data.type}</div>
                        {data.values.map((value) => {
                          return (
                            <div className="typeData">
                              <Checkbox
                                color="primary"
                                value={value}
                                checked={selectedFilters?.[data.type]?.includes(
                                  value
                                )}
                                onChange={(e) => {
                                  handleFilterSelection(e, data.type, value);
                                }}
                                className="root-check-all"
                              />
                              <span>{value}</span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {scheduleInterviewModal && (
              <InterviewModals
                candidate={selectCandidate}
                toast={toast}
                gettableData={gettableData}
                header={
                  selectedTab === "Shortlisted Profiles"
                    ? "Schedule Interview"
                    : "Update Interview Details"
                }
                job={requestedProfile}
                selectedTab={selectedTab}
                closeModal={closeModal}
                tableApiData={tableApiData}
              />
            )}
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              className={"tost-msg-stylinh"}
              draggable={false}
              theme="light"
            />
          </div>
        )}
      </>
    );
  
}

export default CoordinatorShareProfile