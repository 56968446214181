import React, { useRef, useState } from 'react'
import AccountCard from './AccountCard'
import "./AccountList.scss";
import { ToastContainer, toast } from "react-toastify";
import { getAllAccounts, getAllAccountsById } from '../../../api/api-services';
import { useEffect } from 'react';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ReactComponent as NoDataFound } from "../../../assets/noDataFound.svg";

import { useNavigate } from "react-router-dom";
import BdJobPostModal from '../BdJobPostModal';
function AccountList() {
  const [tabIndex, setTabIndex] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let [myAccountpageno, setmyAccountpageNo] = useState(0);
  let [myAccountCount, setMyAccountCount] = useState()
  let [allAccountCount, setAllAccountCount] = useState()
  let [pageno, setpageNo] = useState(0);
  const [ addJobPost, setAddJobPost] = useState(false)
  let [allAccounts, setallAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  let [accountsListById, setallAccountsListById] = useState([])
  let [allAccountsByid, setallAccountsByid] = useState([])
  let [allAccountsList, setallAccountsList] = useState([]);
  let [postJobDetails, setPostDetails] = useState(false);
  let [accountdetailsSection, setAccountdetailsSection] = useState(false);
  let userid = JSON.parse(localStorage.getItem("across-geo-user")).uid;
  const size = 10
  const navigate = useNavigate();
  let getAllAccountsList = async (pageno) => {
    try {
      let response = await getAllAccounts(pageno, size);
      console.log(response, "response")
      if (response.status === 200 || response?.data?.response || response?.data)
        setLastpage(response?.data?.response?.last);
      if (response?.data?.response?.last === false) {
        setpageNo(pageno + 1);
      }
      if (pageno === 0) {
        setallAccounts([...JSON.parse(JSON.stringify(response.data.response?.content))])
        setallAccountsList([...response?.data?.response?.content]);
        setAllAccountCount(response.data.response.totalElements);

      } else {
        setallAccounts([...allAccounts, ...JSON.parse(JSON.stringify(response.data.response?.content))])
        setallAccountsList([...allAccounts, ...response?.data?.response?.content]);
      }
    } catch (err) {
      console.error(err, "list error");
    }
  };
  let getAllAccountsListById = async (myAccountpageno) => {
    try {
      let response = await getAllAccountsById(userid, myAccountpageno, size);
      console.log(response, "response")
      setLastpage(response?.data?.response?.last);
      if (response?.data?.response?.last === false) {
        setmyAccountpageNo(myAccountpageno + 1);
      }
      setMyAccountCount(response.data.response.totalElements);
      if (myAccountpageno === 0) {
        setallAccountsListById([...response?.data?.response?.content]);
      } else {
        // setallAccountsByid([...allAccounts, ...JSON.parse(JSON.stringify(response.data.response?.content))])
        setallAccountsListById([...accountsListById, ...response?.data?.response?.content]);
      }
    } catch (err) {
      console.error(err, "list error");
    }
  };

  const handleScroll = (event) => {
    const target = event?.target;
    if (!target) return;

    const scrolledToBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >= target.scrollHeight - 1;

    // if (scrolledToBottom && !lastPage) {
    //   getAllAccountsList(pageno, size);
    // }
    if (tabIndex === 0 && scrolledToBottom && lastPage === false) {
      getAllAccountsListById(myAccountpageno)
    } else if (tabIndex === 1 && scrolledToBottom && !lastPage) {
      getAllAccountsList(pageno, size);
    }
  };
  // const handleScroll = (event) => {
  //   const target = event?.target;
  //   if (!target) return;

  //   const scrolledToBottom =
  //     Math.ceil(target.scrollTop + target.clientHeight) >= target.scrollHeight - 1;

  //   if (scrolledToBottom && !lastPage) {
  //     getAllAccounts(pageno, size);
  //   }
  // };
  useEffect(() => {
    if (tabIndex === 0) {
      getAllAccountsListById(myAccountpageno)
    }
  }, []);
  useEffect(() => {
    const savedTabIndex = localStorage.getItem("leadAccuntSelectedTab");
    if (savedTabIndex !== null) {
      setTabIndex(Number(savedTabIndex));
    }
    // else {
    //   // Default to 0 if nothing is saved in localStorage
    //   setTabIndex(0);
    // }
  }, []);


  useEffect(() => {
    // if (tabIndex === 0) {
    //   getAllAccountsListById(0);
    // }
    // else {
    //   getAllAccountsList(0)
    // }
  }, [tabIndex]);
  const adminJobPostContainerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (adminJobPostContainerRef.current && !adminJobPostContainerRef.current.contains(event.target)) {
        // Clicked outside the adminJobPostContainer
        localStorage.removeItem("leadAccuntSelectedTab");
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <>
      {addJobPost ? 
        <BdJobPostModal
        toast={toast}
        // selectedJob={selectedJob}
        // getJobList={getJobList}
        // mode={mode}
          closeModal={addJobPost}
          setAddJobPost ={setAddJobPost}
      />:
        <div ref={adminJobPostContainerRef} className="adminJobPostContainer mx-3 w-full home-component-display mt-2.5 overflow-x-hidden overflow-y-auto">
      <div className='headerContent'>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(index) => {
            setTabIndex(index);
            // setPostDetails(false);
            index === 0 ? getAllAccountsListById(0) : getAllAccountsList(0);
          }}
        >
          <div className='pannelTabs row flex'>
            <div className="col-6">
              <TabList className="flex p-3.5  outline-none">
                <Tab className={tabIndex === 0 ? "selectedTab" : ""}>
                  My Accounts{" "}{`${myAccountCount ? `-${myAccountCount}` : ""}`}
                </Tab>
                <Tab className={tabIndex === 1 ? "selectedTab ml-2" : "ml-2"}>
                  Total Accounts{" "}{`${allAccountCount ? `-${allAccountCount}` : ""}`}
                </Tab>
              </TabList>
            </div>
            <div className='col-6 '>
              <div className="searchComponent flex mb-2">
                <div className="searchInput">
                  <input
                    className="table-search-input"
                    // onChange={handelCardSearch}
                    // onChange={(e) => {
                    //   if (e.target.value.trim() === "") {
                    //     resetSearch();
                    //     getCoordinateList(0);
                    //   } else {
                    //     handelCardSearch(e.target.value, 0);
                    //   }
                    // }}
                    type="text"
                    // ref={cardSearchInput}
                    placeholder="Search"
                  />
                </div>
                <div
                  className="addBtn float-right"
                  onClick={() => {
                    setAddJobPost(true)
                  }}
                >
                  <button>
                    Post a Job
                  </button>
                </div>
              </div>
            </div>
          </div>
          <TabPanel>
            {accountsListById.length != 0 ? (
              <div
                className='overflow-y-auto'
                onScroll={handleScroll}>
                <div
                  style={{ maxHeight: "76vh" }}
                  className='overflow-y-auto'
                  onScroll={handleScroll}
                >
                  {
                    accountsListById.map((account) => {
                      return (
                        <div className='m-3' onClick={() => { setAccountdetailsSection(true) }}>
                          <AccountCard
                            tabIndex={tabIndex}
                            account={account}
                          />
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            ) : (
              <div
                className="job-card-container"
                onScroll={handleScroll}
              >
                <div className="no-data-page text-center flex items-center">
                  <div className="noDataCompany">
                    <div className="no-data-availableIcon">
                      <NoDataFound />
                    </div>
                    <div className="no-data-availableText">
                      <p>Oops !!</p>{" "}
                      <p>There are no data to be found yet</p>
                    </div>
                  </div>
                </div>

              </div>
            )}
          </TabPanel>
          <TabPanel>
            {allAccountsList.length != 0 ? (
              <div style={{ maxHeight: "70vh" }}
                className=' overflow-y-auto'
                onScroll={handleScroll}>
                <div
                  style={{ maxHeight: "70vh" }}
                  className='overflow-y-auto'
                  onScroll={handleScroll}
                >
                  {
                    allAccountsList.map((account) => {
                      return (
                        <div className='m-3'>
                          <AccountCard
                            tabIndex={tabIndex}
                            account={account}
                          />
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            ) : (
              <div
                className="job-card-container overflow-y-auto "
                onScroll={handleScroll}
              >
                {allAccountsList.length === 0 && !isLoading ? (
                  <>
                    <div className="no-data-availableIcon">
                      <NoDataFound />
                    </div>
                    <div className="no-data-availableText">
                      <p>Oops !!</p> <p>There are no data to be found yet</p>
                    </div>
                  </>
                ) : allAccountsList.length === 0 && !isLoading ? (
                  <div className="no-data-page text-center flex items-center">
                    <div className="noDataCompany">
                      <div className="no-data-availableIcon">
                        <NoDataFound />
                      </div>
                      <div className="no-data-availableText">
                        <p>Oops !!</p>{" "}
                        <p>There are no data to be found yet</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  allAccountsList.map((job, index) => (
                    <>
                    </>
                  ))
                )}
              </div>
            )}
          </TabPanel>
        </Tabs>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        className={"tost-msg-stylinh"}
        draggable={false}
        theme="light"
      />
    </div>
      }
    </>
  )
}

export default AccountList