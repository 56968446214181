

import React from "react";
import "./loaderfile.scss";
export const LoaderFile = () => {
  return (
    <>
      <div className="base-h flex items-center justify-center">
        <div className="text-center font-bold align-middle">
          <div className="lds-roller top-1/2">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};
