import React, { useEffect, useRef, useState, Fragment } from "react";
import { useTable, useGlobalFilter } from "react-table";
import "./CandidateTable.scss";
import CandidatePreviewModal from "../CandidatePreviewModal";
import { ReactComponent as NoDataFound } from "../../../assets/noDataFound.svg";
import ResumePreviewModal from "../../candidate/modals/preview-resume-modal";
import { DownloadCandidateResume } from "../../../api/api-services";
import {
  convertToDisplayName,
  convertToCamelCase,
} from "../../../util/common-utils";
import { ReactComponent as GreenTickIcon } from "../../../assets/green-tick.svg";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Fade from "@mui/material/Fade";
import VideoPlayerModal from "../../candidate/VideoPlayerModal";
import AdminContextMenuModal from "../../admin/AdminContextMenuModal";
import CandidateDetailsVideoModal from "../../interviewer-coordinator/modals/CandidateDetailsVideoModal";

const CandidateTable = (props) => {
  console.log(props);
  const navigate = useNavigate();
  let [candidateData, setCandidateData] = useState([]);
  const cardSearchInput = useRef(null);
  let [candidatePreviewModal, setCandidatePreviewModal] = useState(false);
  let [selectCandidate, setSelectedCandidate] = useState({});
  let [candidateDetailsData, setCandidateDetailsData] = useState({
    personalDetails: {},
    educationDetails: {},
    technicalDetails: {},
    workHistories: [],
  });
  let [profileName, setProfileName] = useState(
    props.profileColumn ? props.profileColumn : "profile"
  );
  let [columnsData, setColumnsData] = useState([]);
  let columns = React.useMemo(() => columnsData, [columnsData]);
  let [previewFlag, setPreviewFlag] = useState(false);
  let [videoPreviewFlag, setVideoPreviewFlag] = useState(false);
  let [videoKey, setVideoKey] = useState("");
  let [filePath, setFilePath] = useState();
  let data = React.useMemo(() => candidateData, [candidateData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  let handelSearch = (e) => {
    setGlobalFilter(e.target.value);
  };
  let closeModal = (val) => {
    if (candidatePreviewModal) {
      setCandidatePreviewModal(false);
    }
  };
  const getStatusClr = (data) => {
    if (data === "ACTIVE") {
      return "successStatus";
    } else if (data === "REJECTED" || data === "DISABLED") {
      return "cancelledStatus";
    }
  };
  let [openContextDetailsModal, setOpenContextDetailsModal] = useState(false);
  let [showBothVideo_Details, setShowBothVideo_Details] = useState("");
  let closeContextDetails = (val) => {
    if (openContextDetailsModal) {
      setOpenContextDetailsModal(false);
    }
  };
  let handleContextMenuBtn = (data) => {
    setSelectedCandidate({ ...data });
    setOpenContextDetailsModal(true);
  };
  const contextMenuData = [
    {
      option: "Edit video", //if u want to change anything in Edit video change in CandidateDetailsVideoModal also its interLinked
    },
    {
      option: "Details",
    },
  ];
  const contextMenuDetailsOnly = [
    {
      option: "Details",
    },
  ];

  function contextMenuFunction(event, resObj) {
    if (event.target.textContent === "Edit video") {
      handleContextMenuBtn(resObj);
      setShowBothVideo_Details(event.target.textContent);
    }
    if (event.target.textContent === "Details") {
      handleContextMenuBtn(resObj);
      setShowBothVideo_Details(event.target.textContent);
    }
  }
  let moreIconAction = (data) => {
    return (
      <>
        <AdminContextMenuModal
          contextMenuData={
            props.showMoreIconForDetails
              ? contextMenuDetailsOnly   
              // contextMenuData------------its having both details and edit video option..for future implementation
              : contextMenuDetailsOnly
          }
          bdData={data}
          func={(event) => {
            contextMenuFunction(event, data);
          }}
        />
      </>
    );
  };
  useEffect(() => {
    if (props.tableApiData?.columnDef?.columns?.length > 0) {
      let data = [];
      let columnData = [];
      if (props.tableSearch && cardSearchInput.current.value) {
        cardSearchInput.current.value = "";
      }
      props.tableApiData?.content?.map((candidate) => {
        const isInterviewTaken = candidate.interview?.internalInterviewTaken;
        const action = isInterviewTaken ? (
          <div className="flex  relative">
            <p
              className=" absolute top-1  right-32"
              style={{
                left:
                  JSON.parse(localStorage.getItem("across-geo-user")).role ===
                  "COORDINATOR"
                    ? ""
                    : "-1.5rem",
              }}
              title="Interview taken"
            >
              <GreenTickIcon height={"23px"} width={"23px"} />
            </p>
            {props.actionele && props.actionele(candidate)}
          </div>
        ) : JSON.parse(localStorage.getItem("across-geo-user")).role?.includes[
            ("INTERVIEWER", "COORDINATOR")
          ] ? (
          <div className="flex  relative left-5">
            {props.actionele && props.actionele(candidate)}
          </div>
        ) : (
          props.actionele && props.actionele(candidate)
        );

        data.push({ ...candidate, action });
      });
      props.tableApiData?.columnDef?.columns?.map((data) => {
        if (
          data?.columnName !== "id" &&
          data?.columnName !== "interview" &&
          data?.columnName !== "rating"
        ) {
          columnData.push({
            Header: data?.columnName,
            accessor: convertToCamelCase(data?.columnName),
            width: "80px",
            Cell: (e) => (
              <div
                title={e.value}
                onClick={(event) => {
                  event.preventDefault();
                  if (data.columnName === "name") {
                    if (
                      JSON.parse(localStorage.getItem("across-geo-user"))
                        .role === "COORDINATOR"
                    ) {
                      handleContextMenuBtn(e?.row?.original);
                    }
                  }
                }}
                className={`datatrimmer ${
                  props.statusClmnName === data?.columnName
                    ? getStatusClr(e.value)
                    : ""
                }`}
                //               className={`datatrimmer ${
                //   props.statusClmnName === data?.columnName
                //     ? getStatusClr(e.value)
                //     : ""
                // } ${data.columnName === "name" && JSON.parse(localStorage.getItem("across-geo-user")).role === "COORDINATOR" ? " cursor-pointer" : ""}`}
              >
                {data.columnName === "skills" ? (
                  <p className="datatrimmer mt-3">
                    {" "}
                    {e?.value?.length > 3
                      ? e?.value?.slice(0, 2)?.join(", ") + "..."
                      : e?.value?.join(", ")}
                  </p>
                ) : data.columnName === "resume" ? (
                  <button>
                    {e.value === null ? (
                      "-"
                    ) : (
                      <a
                        className={`${e.value !== null ? "underline" : ""}`}
                        onClick={() => FilePreview(e.value)}
                      >
                        view
                      </a>
                    )}
                  </button>
                ) : e.value !== undefined &&
                  e.value !== null &&
                  e.value !== "" ? (
                  e.value
                ) : (
                  "-"
                )}
              </div>
            ),
          });
        }
      });
      if (
        props.interviewerTable === "interviewerTable" ||
        props.selectedTab === "Scheduled Interviews"
      ) {
        if (props.interviewerTable) {
          columnData.push({
            Header: "Interview Link",

            // accessor:"interview", // Replace with the actual accessor for the interview object field
            accessor: (row) => row.interview,
            width: "100px", // Adjust the width as needed

            Cell: (e) => {
              return (
                <div className=" flex mt-0.5 datatrimmer ">
                  {e.value.internalInterviewTaken ? (
                    <button
                      title="Interview already taken"
                      disabled
                      className="text-gray-700"
                    >
                      {e?.value?.interviewLink ? e?.value?.interviewLink : "--"}
                      {console.log(e, "e?.value?.interviewLink")}
                    </button>
                  ) : (
                    <a
                      href={`https://${e?.value?.interviewLink}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {e?.value?.interviewLink}
                    </a>
                  )}
                </div>
              );
            },
          });
        }
        columnData.push({
          Header: "Interview Date",
          accessor: "interview", // Replace with the actual accessor for the interview object field
          width: "100px", // Adjust the width as needed
          Cell: (e) => {
            const interviewDate = new Date(e?.value?.date);
            const tempStartTime = new Date(e?.value?.startTime).toLocaleString(
              "default",
              {
                timeZoneName: "shortOffset",
              }
            );
            const formattedDate = interviewDate
              ? interviewDate.toLocaleString("default", {
                  month: "short",
                  day: "numeric",
                })
              : "--";
            return (
              <div
                className="  mt-3"
                style={{display:"inline-flex"}}
                onClick={() => {
                  console.log(e?.value?.startTime);
                }}
              >
                {e?.value?.hasRequestForNextRound === false ||
                props.interviewerTable === "interviewerTable" ? (
                  <>
                    {e?.value?.date ? formattedDate : "--"} |
                    {props.interviewerTable === "interviewerTable" ? (
                      <p className="mx-1">
                        {e.value.startTime
                          ? new Date(e.value.startTime)
                              .toISOString()
                              .substr(11, 5)
                          : "--"}
                      </p>
                    ) : (
                      <p className="mx-1">
                        {" "}
                        {e.value.startTime ? e.value.startTime : "--"}{" "}
                      </p>
                    )}
                  </>
                ) : (
                  <p className="text-red-500">Requested for one more round</p>
                )}
              </div>
            );
          },
        });
      }
      if (
        props.showVideo !== undefined &&
        (props.showVideo === "both" || props.showVideo === "preview")
      ) {
        columnData.push({
          Header: "Video",
          accessor: (row) => row,
          width: "100px", // Adjust the width as needed

          Cell: (e) => {
            const [anchorEl, setAnchorEl] = useState(null);
            const open = Boolean(anchorEl);
            let fullVideo = "",
              preview = "";
            const handleVideoClick = (event) => {
              setAnchorEl(event.currentTarget);
            };
            const handleClose = () => {
              setAnchorEl(null);
            };
            if (
              props.tableType === "admin-table" ||
              props.tableType === "client-table" ||
              props.tableType === "bde-table"
            ) {
              fullVideo = e.value.fullVideo;
              preview = e.value.preview;
            } else {
              fullVideo = e.value.interview.fullVideo;
              preview = e.value.interview.preview;
            }

            return fullVideo !== null || preview !== null ? (
              <div className="flex mt-2">
                <span style={{cursor:"pointer"}}>
                  <PlayCircleIcon id="fade-button" onClick={handleVideoClick} />
                </span>

                <Menu
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  {props.showVideo === "both" && fullVideo !== null && (
                    <MenuItem
                      onClick={() => {
                        showVideoPreview(fullVideo);
                        handleClose();
                      }}
                    >
                      Full video
                    </MenuItem>
                  )}
                  {preview !== null && (
                    <MenuItem
                      onClick={() => {
                        showVideoPreview(preview);
                        handleClose();
                      }}
                    >
                      Preview
                    </MenuItem>
                  )}
                </Menu>
              </div>
            ) : (
              <div>-</div>
            );
          },
        });
      }

      if (props.actionColumn) {
        columnData.push({
          Header: "action",
          accessor: "action",
          width: `${props.actionColumnWidth ? props.actionColumnWidth : "20%"}`,
          Cell: (e) => (
            <>
              <div
                // title={e.value}
                onClick={(event) => {
                  event.preventDefault();
                }}
                className=""
              >
                {e.value}
              </div>
              {JSON.parse(localStorage.getItem("across-geo-user")).role ===
                "COORDINATOR" && (
                  <div className=" mx-6 ">
                  <div className="mx-1 -mt-2">
                    {moreIconAction(e?.row?.original)}
                  </div>
                </div>
              )}
            </>
          ),
        });
      }
      setProfileName(props.profileColumn ? props.profileColumn : "profile");
      setColumnsData([...columnData]);
      setCandidateData([...data]);
    }
  }, [props.tableApiData]);

  useEffect(() => {
    allColumns?.map((column) => {
      if (props?.hiddenColumnsData?.includes(column.Header)) {
        column.toggleHidden(true);
      } else {
        column.toggleHidden(false);
      }
    });
  }, [columnsData, props.hiddenColumnsData]);

  useEffect(() => {
    if (!props.tableSearch) {
      setGlobalFilter(props.searchedValue ? props.searchedValue : "");
    }
  }, [props.searchedValue]);
  function previewFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var blobData = new Blob([blob], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blobData);
      setFilePath(blobURL);
      setPreviewFlag(true);
    }
  }
  const FilePreview = async (filename) => {
    console.log(filename.split("/"),"filename.split("/")")
    const resId = filename.split("/")[0];
    const reslen = filename.split("/").length -1
    const resName =  filename.split("/")[reslen];
    try {
      let response = await DownloadCandidateResume(resId, resName);
      console.log("DownloadCandidateResume", response);
      if (response.data.response !== "") {
        let requestURLArr = response?.request?.responseURL.split("/");
        console.log("requestURLArr", requestURLArr[requestURLArr.length - 1]);

        previewFileFromBlob(response?.data, filename);
      }
    } catch (error) {
      console.log("error");
    }
  };
  function closePreviewModal(state) {
    setPreviewFlag(state);
  }
  const showVideoPreview = (key) => {
    console.log("key", key);
    setVideoKey(key);
    setVideoPreviewFlag(true);
  };
  const closeVideoPreview = () => {
    setVideoPreviewFlag(false);
  };
  return (
    <div
      className="candidate-table"
      onScroll={(e) => {
        if (props.handlescroll) {
          props.handlescroll(e);
        }
      }}
    >
      {props.tableSearch && (
        <input
          className="table-search-input"
          onChange={handelSearch}
          type="text"
          ref={cardSearchInput}
          placeholder="Search"
        />
      )}
      {data.length === 0 || rows.length === 0 || !props?.isLoading ? (
        <>
          <div className="no-data-availableIcon">
            <NoDataFound />
          </div>
          <div className="no-data-availableText">
            <p>Oops !!</p> <p>There are no data to be found yet</p>
          </div>
        </>
      ) : (
        <table {...getTableProps()} className=" overflow-x-auto">
          <thead className="text-left  h-10">
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={`${headerGroup.headers.length > 5 ? "flex" : ""}`}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className={` reportTableheader datatrimmer popins-semi-bold ${
                      headerGroup.headers.length > 5 ? "flex items-center" : ""
                    } pr-2 text-lg columnwidth  font-semibold text-center
                    ${
                      column.Header.toLowerCase() === profileName
                        ? "profileHeader text-left"
                        : ""
                    }`}
                  >
                    <div
                      className={`datatrimmer ${
                        column.Header.toLowerCase() === profileName
                          ? "text-left"
                          : ""
                      }`}
                      title={column.render("Header")}
                    >
                      {convertToDisplayName(column.render("Header"))}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            // className="border-t-2"
            //   onScroll={handlescroll}
          >
            {rows.length > 0 && props?.isLoading ? (
              <>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={`${row.cells.length > 5 ? "flex" : ""}`}
                      id="defectlog"
                    >
                      {row.cells.map((cell, cellindex) => {
                        if (
                          props?.hiddenColumnsData &&  props?.hiddenColumnsData.includes(cell.column.Header)
                        ) {
                          // Don't render the cell for hidden columns
                          return null;
                        }
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`text-center columnwidth ${
                              cell?.column?.Header?.toLowerCase() ===
                              profileName.toLowerCase()
                                ? "profile"
                                : `${cell?.column?.Header} ${
                                    row.cells.length > 5
                                      ? "flex items-center"
                                      : ""
                                  }`
                            }  ${
                              cell?.column?.Header !== "action" &&
                              cell?.column?.Header !== "Interview Date"
                                ? "datatrimmer"
                                : ""
                            }`}
                          >
                            {cell?.column?.Header.toLowerCase() ===
                              profileName.toLowerCase() && (
                              <div className="dataContainer flex">
                                <span className="profile-icon">
                                  {cell?.row?.original?.[profileName]?.slice(
                                    0,
                                    2
                                  )}
                                </span>
                                <div className="profile-with-rating mt-3 datatrimmer">
                                  {cell.render("Cell")}
                                  {cell?.column?.Header.toLowerCase() ===
                                    profileName.toLowerCase() &&
                                    !props.hideRating && (
                                      <div className="rating -mt-0.5">
                                        Rating {cell?.row?.original?.rating}
                                      </div>
                                    )}
                                </div>
                              </div>
                            )}
                            {cell?.column?.Header.toLowerCase() !==
                              profileName.toLowerCase() && cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                <div className="grid grid-cols-12  nodefect_container">
                  <div className="col-span-12 flex justify-center">
                    {/* <NoDefectIcon /> */}no list
                  </div>
                </div>
              </>
            )}
          </tbody>
        </table>
      )}
      {candidatePreviewModal && (
        <CandidatePreviewModal
          candidateDetailsData={candidateDetailsData}
          candidate={selectCandidate}
          closeModal={closeModal}
          header={"Candidate Preview"}
        />
      )}
      {previewFlag && (
        <ResumePreviewModal path={filePath} closeModal={closePreviewModal} />
      )}
      {videoPreviewFlag && (
        <VideoPlayerModal videoKey={videoKey} closeModal={closeVideoPreview} />
      )}
      {openContextDetailsModal && (
        <CandidateDetailsVideoModal
          candidate={selectCandidate}
          showBothVideo_Details={showBothVideo_Details}
          closeModal={closeContextDetails}
        />
      )}
    </div>
  );
};

export default CandidateTable;
