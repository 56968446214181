import CustomerReviews from "./customer-review/CustomerReviews";
import EmpCandCard from "./emp-cand-registerCards/EmpCandCard";
import FAQHome from "./faq-section/FAQHome";
import FooterSectionHome from "./footer-section/FooterSectionHome";
import LatestJobCards from "./latest-jobs/LatestJobCards";
import NavigationHeader from "./nav-section/NavigationHeader";
import HowCanHelp from "./how-can-we-help/HowCanHelp";
import "./WebsitePage.scss";
import CategoriesList from "./find-categories/categories-list/CategoriesList";
import FindJobsByKeywordHome from "./find-jobs/FindJobsByKeywordHome";
import { getIndustriesListReq } from "../../api/api-services";
import { useEffect, useState } from "react";

const WebsitePage = () => {
  const [industryData, setIndustryData] = useState();
  const fetchCompanies = async () => {
    try {
      const response = await getIndustriesListReq();
      console.log(response.data.response,"response.data.response")
      const companies = response.data.response.map((company) => ({
        label: company,
        value: company,
      }));
      setIndustryData(response.data.response);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  useEffect(() => {
    // fetchCompanies()
  }, [])
  // const aboutUsRef = useRef(null);
  return (
    <div className="">
      <NavigationHeader/>
      <div className="webPageContainer">
        <FindJobsByKeywordHome
          industryData={industryData}
        />
        <EmpCandCard />
        {/* <CategoriesList /> */}
        <LatestJobCards />
        <HowCanHelp />
        <CustomerReviews />
        <div className="faqmain">
        <FAQHome />
        </div>
        <FooterSectionHome />
      </div>
    </div>
  );
};

export default WebsitePage;
