
import React, { useState, useEffect } from "react";
import "./DemoAdminJobPost.scss";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AdminJobPostCard from "./AdminJobPostCard";
import { getNewJobPost, getOldJobPost } from "../../../api/api-services";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { ReactComponent as NoDataFound } from "../../../assets/noDataFound.svg";
import AdminJobPostDetails from "./AdminJobPostDetails";
import JobDetails from "../../client/modals/JobDetails";
import JobPostCard from "../../common/job-card/JobPostCard";

const DemoAdminJobPost = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [newJobList, setNewJobList] = useState([]);
  const [newJobListCount, setNewJobListCount] = useState();
  const [oldJobListCount, setOldJobListCount] = useState();
  const [oldJobList, setOldJobList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shouldSelectOldClientsTabParam = queryParams.get(
    "shouldSelectOldClientsTab"
  );
  const [shouldSelectOldClientsTab, setShouldSelectOldClientsTab] = useState(
    shouldSelectOldClientsTabParam === "true" // Convert the string to a boolean value
  );
  let [postJobDetails, setPostDetails] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let [oldJobpageno, setOldJobpageno] = useState(0);
  let [oldlastPage, setOldLastpage] = useState(false);
  let size = 10;

  const fetchNewJobPosts = async (page) => {
    try {
      const response = await getNewJobPost(page, size);
      // if (response?.data?.response?.last === false) {
      //   setpageNo(page + 1);
      //   setLastpage(response?.data?.response?.last);
      // }
      // setNewJobList((prevList) => [...prevList, ...response.data.response.content]);
      setIsLoading(false);
      if (page === 0) {
        setNewJobList([...response.data.response.content]);
      } else {
        setNewJobList([...newJobList, ...response.data.response.content]);
      }
      setpageNo(page + 1);
      setLastpage(response.data.response.last);
      setNewJobListCount(response.data.response.totalElements);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const fetchOldJobPosts = async (page) => {
    try {
      const response = await getOldJobPost(page, size);
      setOldJobListCount(response.data.response.totalElements);
      setOldJobList((prevList) => [
        ...prevList,
        ...response.data.response.content,
      ]);
      setIsLoading(false);
      setOldJobpageno(page + 1);
      setOldLastpage(response.data.response.last);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const fetchJobPosts = (pageno, oldJobpageno) => {
    setIsLoading(true);

    if (tabIndex === 0) {
      fetchNewJobPosts(pageno);
    } else if (tabIndex === 1) {
      fetchOldJobPosts(oldJobpageno);
    }
  };

  // useEffect(() => {
  //   fetchJobPosts(pageno, oldJobpageno);
  // }, [tabIndex]);
  useEffect(() => {
    if (tabIndex === 0) {
      fetchNewJobPosts(pageno);
    }
  }, []);
  const [hasFetchedOldJobPosts, setHasFetchedOldJobPosts] = useState(false);

  useEffect(() => {
    if (!hasFetchedOldJobPosts) {
      setHasFetchedOldJobPosts(true);
      fetchOldJobPosts(oldJobpageno);
    }
  }, [hasFetchedOldJobPosts]);
  console.log(tabIndex);
  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;

    if (scrollTop + clientHeight >= scrollHeight - 20) {
      if (tabIndex === 0 && lastPage === false && !isLoading) {
        setIsLoading(true); // Set isLoading to prevent concurrent API calls

        fetchNewJobPosts(pageno);
      } else if (tabIndex === 1 && !oldlastPage && !isLoading) {
        setIsLoading(true); // Set isLoading to prevent concurrent API calls
        fetchOldJobPosts(oldJobpageno);
      }
    }
  };
  // Function to filter job posts based on search term
  const filterJobPosts = (jobList) => {
    return jobList.filter((job) => {
      let tempLoc;
      if (job?.location) {
        tempLoc = job.location.map((loc) => loc.toLowerCase());
      }
      const jobHeadlines = job.jobHeadlines.toLowerCase();
      const roles = job.roles.toLowerCase();
      const keySkills = job.keySkills.join().toLowerCase();
      const location = tempLoc;
      const industry = job.industry.toLowerCase();
      const functionalArea = job.functionalArea.toLowerCase();
      const companyName = job?.companyName?.toLowerCase();
      const clientName = job?.clientName?.toLowerCase();

      return (
        jobHeadlines.includes(searchTerm.toLowerCase()) ||
        roles.includes(searchTerm.toLowerCase()) ||
        keySkills.includes(searchTerm.toLowerCase()) ||
        location.includes(searchTerm.toLowerCase()) ||
        industry.includes(searchTerm.toLowerCase()) ||
        functionalArea.includes(searchTerm.toLowerCase()) ||
        companyName?.includes(searchTerm.toLowerCase()) ||
        clientName?.includes(searchTerm.toLowerCase())
      );
    });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    if (shouldSelectOldClientsTab) {
      setTabIndex(1); // Set the tab index to 1 to select the "Old Clients Request" tab
    }
  }, [shouldSelectOldClientsTab]);
  const handelMoreBtn = (data, type) => {
    setSelectedJob({ ...data });
    if (type === "Details") {
      setPostDetails(true);
    }
  };
  let closeModal = (val) => {
    setPostDetails(false);
  };
  return (
    <div className="demoAdminJobPostContainer mx-3  mt-0.5 w-full home-component-display mt-2.5 overflow-x-hidden overflow-y-auto">
      <div className="headerContent">
        <div className=" flex" style={{ height: "50px" }}>
          {/* <p>Job Post Lists - {tabIndex === 0 ? newJobListCount :oldJobListCount}</p> */}
          <p>
            Job Post Lists -{" "}
            {tabIndex === 0
              ? searchTerm
                ? filterJobPosts(newJobList).length
                : newJobListCount
              : searchTerm
              ? filterJobPosts(oldJobList).length
              : oldJobListCount}
          </p>
          <input
            type="text"
            className="admin-jobPost-search-input"
            placeholder="Search job posts"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className="tabContents my-2">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => {
              setTabIndex(index);
              setSearchTerm("");
              setPostDetails(false);
            }}
          >
            <div className="pannelTabs">
              <TabList className="flex outline-none" style={{ height: "44px" }}>
                <Tab className={tabIndex === 0 ? "selectedTab" : ""}>
                  New Clients Request
                </Tab>
                <Tab className={tabIndex === 1 ? "selectedTab" : ""}>
                  Old Clients Request
                </Tab>
              </TabList>
            </div>

            <TabPanel>
              {postJobDetails ? (
                <JobDetails
                  jobModalHeight="355px"
                  closeModal={closeModal}
                  selectedJob={selectedJob}
                />
              ) : (
                <div
                  className="job-card-container overflow-y-auto "
                  onScroll={handleScroll}
                >
                  {newJobList.length === 0 && !isLoading ? (
                    <>
                      <div className="no-data-availableIcon">
                        <NoDataFound />
                      </div>
                      <div className="no-data-availableText">
                        <p>Oops !!</p> <p>There are no data to be found yet</p>
                      </div>
                    </>
                  ) : filterJobPosts(newJobList).length === 0 && !isLoading ? (
                    <div className="no-data-page text-center flex items-center">
                      <div className="noDataCompany">
                        <div className="no-data-availableIcon">
                          <NoDataFound />
                        </div>
                        <div className="no-data-availableText">
                          <p>Oops !!</p>{" "}
                          <p>There are no data to be found yet</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    filterJobPosts(newJobList).map((job, index) => (
                      <>
                        <JobPostCard
                          job={job}
                          index={index}
                          tabIndex={tabIndex}
                          toast={toast}
                          fetchNewJobPosts={fetchNewJobPosts}
                          handelMoreBtn={handelMoreBtn}
                        />
                      </>
                    ))
                  )}
                </div>
              )}
            </TabPanel>
            <TabPanel>
              {postJobDetails ? (
                <JobDetails
                  jobModalHeight="355px"
                  closeModal={closeModal}
                  selectedJob={selectedJob}
                />
              ) : (
                <div
                  className="job-card-container overflow-y-auto"
                  onScroll={handleScroll}
                >
                  {oldJobList.length === 0 && !isLoading ? (
                    <>
                      <div className="no-data-availableIcon">
                        <NoDataFound />
                      </div>
                      <div className="no-data-availableText">
                        <p>Oops !!</p> <p>There are no data to be found yet</p>
                      </div>
                    </>
                  ) : filterJobPosts(oldJobList).length === 0 && !isLoading ? (
                    <div className="no-data-page text-center flex items-center">
                      <div className="noDataCompany">
                        <div className="no-data-availableIcon">
                          <NoDataFound />
                        </div>
                        <div className="no-data-availableText">
                          <p>Oops !!</p>{" "}
                          <p>There are no data to be found yet</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    filterJobPosts(oldJobList).map((job, index) => (
                      <JobPostCard
                        job={job}
                        index={index}
                        tabIndex={tabIndex}
                        showShareBtn="true"
                        handelMoreBtn={handelMoreBtn}
                      />
                    ))
                  )}
                </div>
              )}
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        className={"tost-msg-stylinh"}
        draggable={false}
        theme="light"
      />
    </div>
  );
};

export default DemoAdminJobPost;
