import React, { useEffect, useState } from "react";
import "./UpdateProfile.scss";
import PersonalDetailsForm from "./PersonalDetailsForm";
import EducationDetailsForm from "./EducationDetailsForm";
import TechnicalDetailsForm from "./TechnicalDetailsForm";
import WorkHistoryForm from "./WorkHistoryForm";
import { editCandidateDetails } from "../../../api/api-services";
import { ToastContainer, toast } from "react-toastify";


let reqPayload = {};
function UpdateProfile(props) {
  const [openModal, setOpenModal] = useState(true);
  const [diplomoForm,setDiplomoForm] = useState(false)
  const [activatedTabs, setActivatedtabs] = useState({
    educationDetails: false,
    technicalDetails: false,
    personalDetails: false,
    workDetails: false,
  });
  const [selectedTab, setSelectedTab] = useState("personalDetails");
  const [candidateData, setCandidateData] = useState(JSON.parse(JSON.stringify(props.candidateDetailsData)));
  const [completeProfileData, setCompleteProfileData] = useState(props.completeProfileData);
  let tempUid = JSON.parse(localStorage.getItem("across-geo-user")).uid;
  let handelTabs = (tabName) => {
    setSelectedTab(tabName);
  };
  const editUserDetails = async(payload) => {

    let res = await editCandidateDetails(payload);
    if(res.status === 200){
      // props.getCandidateDetails();
      toast.success(
        <>
          <div className="toast-heading">Success</div>
          <div>{props.completeProfileData ? "Profile Added Successfully":"Profile Updated Successfully"}</div>
        </>
      );
      setTimeout(() => {
       window.location.reload();
      }, 2000); 
    }
  }
  let fetchDataFromForms = (data, submitFlag) =>{
    if(Object.keys(data)[0] === 'educationDetails')
    {
      if(candidateData?.educationDetails?.diploma?.boardName === "Others") {
      candidateData.educationDetails.diploma.board = data.educationDetails.diploma.board;
      }
      if(candidateData?.educationDetails?.degree?.university === "Others") {
        candidateData.educationDetails.degree.universityName = data.educationDetails.degree.universityName;
      }
      if(candidateData?.educationDetails?.degree?.title === "Others") {
        candidateData.educationDetails.degree.titleName = data.educationDetails.degree.titleName;
      }
      if(candidateData?.educationDetails?.masterDegree?.university === "Others") {
        candidateData.educationDetails.masterDegree.universityName = data.educationDetails.masterDegree.universityName;
      }
      if(candidateData?.educationDetails?.masterDegree?.title === "Others") {
        candidateData.educationDetails.masterDegree.titleName = data.educationDetails.masterDegree.titleName;
        }
      if(candidateData?.educationDetails?.iti?.boardName === "Others") {
        candidateData.educationDetails.iti.board = data.educationDetails.iti.board;
      }
    }
    setCandidateData({...candidateData,...data})
    if(submitFlag){
      let payload = {...candidateData,...data};
      payload.id = tempUid;
      if(payload?.educationDetails?.diploma?.boardName === "Others") {
        payload.educationDetails.diploma.boardName = payload.educationDetails.diploma.board;
        delete payload.educationDetails.diploma.board;
      }
      if(payload?.educationDetails?.masterDegree?.university === "Others") {
        payload.educationDetails.masterDegree.university = payload.educationDetails.masterDegree.universityName;
        delete payload.educationDetails.masterDegree.universityName;
      }
      if(payload?.educationDetails?.masterDegree?.title === "Others") {
        payload.educationDetails.masterDegree.title = payload.educationDetails.masterDegree.titleName;
        delete payload.educationDetails.masterDegree.titleName;
      }
      if(payload?.educationDetails?.degree?.university === "Others") {
        payload.educationDetails.degree.university = payload.educationDetails.degree.universityName;
        delete payload.educationDetails.degree.universityName;
      }
      if(payload?.educationDetails?.degree?.title === "Others") {
        payload.educationDetails.degree.title = payload.educationDetails.degree.titleName;
        delete payload.educationDetails.degree.titleName;
      }
      if(payload?.educationDetails?.iti?.boardName === "Others") {
        payload.educationDetails.iti.boardName = payload.educationDetails.iti.board;
        delete payload.educationDetails.iti.board;
      }
      if (payload?.workHistories) {
        payload?.workHistories.map(companies => {
          if (companies?.company === "others") {
            companies.company = companies?.companyName;
            delete companies?.companyName
          }
        })
      }
      if (payload.educationDetails.highestEducation === "SSLC (10th)") {
        payload.educationDetails?.twelfth  && delete payload.educationDetails.twelfth;
        payload.educationDetails?.iti && delete payload.educationDetails.iti;
        payload.educationDetails?.diploma && delete payload.educationDetails.diploma;
      }
      editUserDetails(payload);
    }
  }
  const candidateDataRequestHandler = (tabName, data) => {
    reqPayload[tabName] = data;
  };
  const closeForm = () => {
    reqPayload = {};
    props.closeScreen();
  };
  return (
    <div className="update-profile-modal flex">
      <div className="update-profile-img"></div>
      <div className="update-profile-form">
        <div className="profile-header">Profile Update</div>
        <div className="flex col-span-4 grid grid-cols-4">
          <div
            onClick={() => {
              // handelTabs("personalDetails");
            }}
            className={`${
              selectedTab === "personalDetails"
                ? "selected-tab-elements"
                : "tab-elements"
            } flex items-center`}
          >
            {/* <input type="radio" name="detailsTab" id="eduinfo" /> */}
            <span className="sub-heading">Personal Details</span>
          </div>
          <div
            onClick={() => {
              // handelTabs("educationDetails");
            }}
            className={`${
              selectedTab === "educationDetails"
                ? "selected-tab-elements"
                : "tab-elements"
            } flex items-center`}
          >
            {/* <input type="radio" name="detailsTab" id="personalinfo" /> */}
            <span className="sub-heading">Education Details</span>
          </div>
          <div
            onClick={() => {
              // handelTabs("workDetails");
            }}
            className={`${
              selectedTab === "workDetails"
                ? "selected-tab-elements"
                : "tab-elements"
            } flex items-center`}
          >
            {/* <input type="radio" name="detailsTab" id="techinfo" /> */}
            <span className="sub-heading">Work Details</span>
          </div>
          <div
            onClick={() => {
              // handelTabs("technicalDetails");
            }}
            className={`${
              selectedTab === "technicalDetails"
                ? "selected-tab-elements"
                : "tab-elements"
            } flex items-center`}
          >
            {/* <input type="radio" name="detailsTab" id="techinfo" /> */}
            <span className="sub-heading">Technical Details</span>
          </div>
        </div>
        {selectedTab === "personalDetails" ? (
          <PersonalDetailsForm
          completeProfileData={completeProfileData}
            changeTab={handelTabs}
            fetchDataFromForms={fetchDataFromForms}
            data={candidateData?.personalDetails}
            sameAddress={candidateData.addressSame}
            closeScreen={closeForm}            
          />
        ) : selectedTab === "educationDetails" ? (
            <EducationDetailsForm
            setDiplomoForm={setDiplomoForm}
            diplomoForm ={diplomoForm}
            completeProfileData={completeProfileData}
            changeTab={handelTabs}
            fetchDataFromForms={fetchDataFromForms}
            data={JSON.parse(JSON.stringify(candidateData?.educationDetails))}
            closeScreen={closeForm}
          />
        ) : selectedTab === "technicalDetails" ? (
            <TechnicalDetailsForm
            completeProfileData={completeProfileData}
            changeTab={handelTabs}
            fetchDataFromForms={fetchDataFromForms}
            data={candidateData?.technicalDetails}
            closeScreen={closeForm}
          />
        ) : (
            <WorkHistoryForm
            setDiplomoForm={setDiplomoForm}
            diplomoForm ={diplomoForm}
            completeProfileData={completeProfileData}
            changeTab={handelTabs}
            fetchDataFromForms={fetchDataFromForms}
            candidateData={candidateData}
            apiData={props.candidateDetailsData}
            closeScreen={closeForm}
          />
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default UpdateProfile;
