import { Tooltip } from "@material-ui/core";
import dayjs from "dayjs";
function logger(...args) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV !== "production") {
    console.log(args);
  }
}
function duplicatesCheck(list, existingValue, value, key) {
  let duplicateExist = false;
  if (value !== undefined && existingValue !== value) {
    for (var i = 0; i < list.length; i++) {
      if (value === list[i][key]) {
        duplicateExist = true;
      }
    }
  }
  return !duplicateExist;
}
function isValidDateFormatYYYYMMDD(inputDate) {
  var date_regex = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;
  return date_regex.test(inputDate);
}
function convertDate(inputFormat) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(inputFormat);
  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join("-");
}
function validateEmail(email) {
  var email_regex = /[A-Za-z0-9]+@[a-zA-Z]+\.[A-Za-z]{2,3}/;
  return email?.length > 0 && email_regex.test(email);
}
function validatePhone(phone) {
  var phone_regex = /^[0-9]{10}$/;
  // var phone_regex = /^[6-9]{1}[0-9]{9}$/;
  return phone?.toString().length > 0 && phone_regex.test(phone);
}
function validateName(name) {
  var name_regex = /^[a-zA-Z. ]+$/;
  return name?.length > 2 && name_regex.test(name);
}
//2022-08-05T00:00:00
// function convertToAPIDate(cDate) {
//   return dateFormat(cDate, "yyyy-mm-dd") + "T00:00:00";
// }
function getLabeledValue(value) {
  if (value?.length > 25) {
    return (
      <Tooltip title={value} placement="top">
        <span> {value.slice(0, 23)}... </span>
      </Tooltip>
    );
  } else {
    return <span> {value}</span>;
  }
}
function stringToDropdownValue(str) {
  return {
    value: str,
    label: str,
  };
}
function dropdownLabelFinder (str, arr){
  let obj = arr.find(o => o.value === str);
  return obj;
}
function formatSytemTimeToHumanFormat(timeStr) {
  if (timeStr !== undefined && timeStr !== "") {
    let tempString = timeStr.split("T")[1].split(".")[0];
    console.log("string", tempString);
    return formatTime(tempString);
  }
}
function formatTime(timeString) {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : "  PM");
}
function convertToDisplayName(inputString) {
  // Split the input string into words based on underscores
  const words = inputString.split('_');

  // Capitalize the first letter of each word and join them with a space
  const displayName = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

  return displayName;
}
function convertToCamelCase(inputString) {
  const words = inputString.split('_');
  
  // Convert the first word to lowercase and capitalize the rest
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word;
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  
  const camelCaseString = camelCaseWords.join('');
  return camelCaseString;
}
const validateFromAndToTime = (fromTime, toTime) => {
  if (fromTime && toTime) {
    const format = "hh:mm A";
    const from = dayjs(fromTime, format);
    const to = dayjs(toTime, format);
    if (!from.isValid() || !to.isValid()) {
      return false;
    }
    return from.isBefore(to);
  }
  return true;
};
export {
  logger,
  duplicatesCheck,
  isValidDateFormatYYYYMMDD,
  convertDate,
  validateEmail,
  validatePhone,
  validateName,
  // convertToAPIDate,
  getLabeledValue,
  stringToDropdownValue,
  formatTime,
  formatSytemTimeToHumanFormat,
  dropdownLabelFinder,
  convertToDisplayName,
  convertToCamelCase,
  validateFromAndToTime,
};
