import React, { useState, useEffect, Fragment, useRef } from "react";
import { useFormik, Field } from "formik";
import * as yup from "yup";
import cx from "classnames";
import { TextField } from "@mui/material";

import style from "../../../../landing-page/common/inputs/inputs.module.scss";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import "react-phone-number-input/style.css";

import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  IconButton,
  InputAdornment,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";


import {
    defaultCountry,
  existEmailPhoneCheck,
  getRegisteredCompanyList,
} from "../../../../../api/api-services";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "./EmployerRegistrationForm.scss"
import VisibleEye from "../../../website-assets/visibleEyeNew.png"
import InvisibleEye from "../../../website-assets/invisibleEyeNew.png"

const EmployerRegistrationForm = (props) => {
    const navigate = useNavigate();
    const EMAIL_PATTERN =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [emailError, setEmailError] = useState();
    const { state } = useLocation();
    const [value, setValue] = useState();
    const [error, setError] = useState({ phone: false, validPhone: false });
    const [selectedCompanyData, setSelectedCompanyData] = useState([]);
    const [selectedJobTitleData, setSelectedJobTitleData] = useState([]);
    const inputBorder = "1px solid #26428B80";
    const inputBorderErr = "1px solid #ea0322";
    const [showPassword, setShowPassword] = useState(true);
    const [confirmShowPassword, setConfirmShowPassword] = useState(true);
    const [presentCountry, setPresentCountry] = useState("");
    const [availabilityStatusEmail, setAvailabilityStatusEmail] = useState(null);
    const [availabilityStatusPhone, setAvailabilityStatusPhone] = useState(null);
    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
    const handleClickConfirmShowPassword = () => {
      setConfirmShowPassword(!confirmShowPassword);
    };
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
  
    let initialValues = {
      name: props?.registrationData?.name ? props?.registrationData?.name : "",
      email: props?.registrationData?.email ? props?.registrationData?.email : "",
      jobTitle: props?.registrationData?.jobTitle
        ? props?.registrationData?.jobTitle
        : "",
      companyName: props?.registrationData?.companyName
        ? props?.registrationData?.companyName
        : "",
      otherCompanyName: props?.registrationData?.otherCompanyName
        ? props?.registrationData?.otherCompanyName
        : "",
      location: props?.registrationData?.location
        ? props?.registrationData?.location
        : "",
      mobile: props?.registrationData?.mobile
        ? props?.registrationData?.mobile
        : "",
      password: props?.registrationData?.password
        ? props?.registrationData?.password
        : "",
      confirmPassword: props?.registrationData?.confirmPassword
        ? props?.registrationData?.confirmPassword
        : "",
      jobTitleOthers: props?.registrationData?.jobTitleOthers
        ? props?.registrationData?.jobTitleOthers
        : "",
    };
    const onSubmit = async (values) => {
      // if(props?.registrationData?.mobile === values.mobile){
      //   toast.info(
      //     <>
      //       <div className="toast-heading">Success</div>
      //       <div>No changes were done to Phone number</div>
      //     </>
      //   );
      //   return;
      // }
  
      const lowercasedEmail = values?.email?.toLowerCase();
      const updatedValues = {
        ...values,
        mobile: `+${formikDetails.values.mobile.replace(/^\+/, "")}`,
        email: lowercasedEmail,
        
      };
      console.log(updatedValues);
      // navigate("/otp-verification", {
      //   state: {
      //     mobileNumber: `+${formikDetails.values.mobile.replace(/^\+/, "")}`,
      //     mode: "ClientRegistration",
      //   },
      // });
      // props.handleRegistrationData(updatedValues);
      await existEmailPhoneCheck(`+${formikDetails.values.mobile.replace(/^\+/, "")}`)
        .then((resp) => {
          console.log({ resp });
          resp.data.statusCode === 200 &&
          props.setUserInfo({
            mode: "ClientRegistration",
            mobileNumber: `+${formikDetails.values.mobile.replace(/^\+/, "")}`,
          });
        props.handelOpenOpenOtpModal(true)
          
       
          props.handleRegistrationData(updatedValues);
        })
        .catch((error) => {
          // Handle the error (e.g., display a message to the user)
          console.error("Availability check failed:", error);
        });
    };
    const validationSchema = yup.object({
      name: yup
        .string()
        .trim()
        .min(3, "Minimum 3 characters")
        .max(25, "Maximum 25 characters")
        .matches(/^[A-Za-z ]*$/, "Name should be alphabetic")
        .required("Name is required"),
      email: yup
        .string()
        .trim()
        .required("Email id is required")
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Enter valid email Id"),
      jobTitle: yup.string().required("Job title is required"),
      companyName: yup.string().required("Company name is required"),
      mobile: yup.string().required("Valid Phone number is required"),
      password: yup
        .string()
        .matches(/^\S*$/, `Weak password, please match the password criteria`)
        .matches(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,20}$",
          `Weak password, please match the password criteria`
        )
        .required("Password is required"),
      confirmPassword: yup
        .string()
        .oneOf(
          [yup.ref("password"), null],
          "Confirm password not matched with new password"
        )
        .required("Confirm password is required"),
      otherCompanyName: yup.string().when("companyName", {
        is: (value) => value === "Others",
        then: () =>
          yup
            .string()
            .required("Others Company Name is required")
            .min(2, "Minimum 2 characters")
            .matches(/^[A-Za-z0-9 ]*$/, "Special characters not allowed"),
      }),
      location: yup.string().when("companyName", {
        is: (value) => value === "Others",
        then: () => yup.string().required("Location is required"),
      }),
  
      jobTitleOthers: yup
        .string()
        .min(2, "Minimum 2 characters")
        .max(50, "Maximum 50 characters")
        .when("jobTitle", {
          is: (value) => value === "Others",
          then: () =>
            yup
              .string()
              .required("Job title others is required")
              .matches(
                /^[A-Za-z0-9 ]*$/,
                "Job title others can not have special characters"
              ),
        }),
    });
  
    const formikDetails = useFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });
  
    function isEmailValid(email) {
      return EMAIL_PATTERN.test(email);
    }
  
    function checkValidEmail() {
      if (formikDetails.values.email) {
        isEmailValid(formikDetails.values.email);
        //    && checkIfEmailExists();
      } else {
        setEmailError("");
        formikDetails.setFieldValue("name", "");
        formikDetails.setFieldValue("phoneNumbers", "");
      }
    }
    function handleTrimmedBlur(event) {
      event.target.value = event.target.value.trim();
      formikDetails.handleChange(event);
      formikDetails.handleBlur(event);
    }
    const jobTitles = [
      { value: "engineer", label: "Engineer" },
      { value: "developer", label: "Developer" },
      { value: "designer", label: "Designer" },
    ];
    const [jobTitleData, setJobTitleData] = useState([
      { label: "Analyst", value: "Analyst" },
      { label: "Automation Architect", value: "Automation Architect" },
      { label: "CEO", value: "CEO" },
      { label: "CTO", value: "CTO" },
      { label: "Consultant", value: "Consultant" },
      { label: "Director", value: "Director" },
      { label: "DevOps Engineer", value: "DevOps Engineer" },
      { label: "Engineering Manager", value: "Engineering Manager" },
      { label: "Manager", value: "Manager" },
      { label: "QA Engineer", value: "QA Engineer" },
      { label: "QA Lead", value: "QA Lead" },
      { label: "Service Provider", value: "Service Provider" },
      { label: "Software Engineer", value: "Software Engineer" },
      { label: "VP", value: "VP" },
      { label: "Others", value: "Others" },
    ]);
    const handlejobTitleChange = (data) => {
      // formikDetails.setFieldValue("jobTitle", data?.[0].value);
  
      // setSelectedJobTitleData([...data]);
  
      formikDetails.setFieldValue("jobTitle", data?.value);
  
      setSelectedJobTitleData(data);
    };
  
    const [companyData, setCompanyData] = useState([]);
  
    useEffect(() => {
      const fetchCompanies = async () => {
        try {
          const response = await getRegisteredCompanyList();
          const companies = response.data.response.map((company) => ({
            label: company,
            value: company,
          }));
          setCompanyData([...companies, { label: "Others", value: "Others" }]);
        } catch (error) {
          console.error("Error fetching companies:", error);
        }
      };
  
      fetchCompanies();
    }, []);
  
    // let handlecompanyChange = (data) => {
    //   let list = [];
    //   data?.map((company) => {
    //     list.push(company.value);
    //   });
    //   formikDetails.setFieldValue("companyName", [...list]);
    //   setSelectedCompanyData([...data]);
    // };
  
    const handleCompanyChange = (data) => {
      // formikDetails.setFieldValue("companyName", data?.[0].value);
      // setSelectedCompanyData([...data]);
      formikDetails.setFieldValue("companyName", data?.value);
      setSelectedCompanyData(data);
    };
  
    const handleOnBlur = (id) => {
      console.log(formikDetails.values.mobile, "checl", value);
      if (!value) {
        if (!props?.registrationData?.mobile) {
          setError({ ...error, [id]: true });
        }
      } else if (
        !isValidPhoneNumber("+" + formikDetails.values.mobile?.toString())
      ) {
        setError({ ...error, [id]: false, validPhone: true });
      } else {
        setError({ ...error, [id]: false, validPhone: false });
      }
    };
    useEffect(() => {
      // Fetch the defaultCountry only if the mobile number is not already present in props
      if (!props?.registrationData?.mobile) {
        defaultCountry()
          .then((res) => {
            setPresentCountry(res.data.country);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }, [props?.registrationData?.mobile]);
  
    const handleBlur = () => {
      if (formikDetails.touched.jobTitle && !formikDetails.values.jobTitle) {
        formikDetails.setFieldError("jobTitle", "Job title is required");
      }
    };
  
    const handleSelectBlur = (event) => {
      if (event.currentTarget.contains(event.relatedTarget)) {
        formikDetails.setFieldTouched("jobTitle", true);
        handleBlur();
      }
    };
    const handleSelectBlurCompany = (event) => {
      if (event.currentTarget.contains(event.relatedTarget)) {
        formikDetails.setFieldTouched("companyName", true);
        
      }
    };
  
    const checkAvailability = async (id, value) => {
      try {
        const response = await existEmailPhoneCheck(value);
        if (id === "email") {
          setAvailabilityStatusEmail(response?.response?.data?.response);
        } else if (id === "mobile") {
          setAvailabilityStatusPhone(response?.response?.data?.response);
        }
      } catch (error) {
        console.error("Error occurred while checking availability:", error);
      }
    };
    const STRONG_PASSWORD_PATTERNS = [
      {
        pattern: /^.{8,20}$/,
        message: "Should contain at least 8 characters and at most 20 characters",
      },
      {
        pattern: /.*[0-9].*/,
        message: "Should contain at least one digit",
      },
      {
        pattern: /.*[A-Z].*/,
        message: "Should contain at least one upper case alphabet",
      },
      {
        pattern: /.*[a-z].*/,
        message: "Should contain at least one lower case alphabet",
      },
      {
        pattern: /.*[!@#$%&*()+=^].*/,
        message:
          "Should contain at least one special character which includes !@#$%&*()+=^",
      },
      {
        pattern: /^\S*$/,
        message: "Should doesn't contain any white space",
      },
    ];
    useEffect(() => {
      const defaultJobTitle = props?.registrationData?.jobTitle;
      if (defaultJobTitle) {
        const defaultOption = jobTitleData.find(
          (option) => option.value === defaultJobTitle
        );
        if (defaultOption) {
          setSelectedJobTitleData([defaultOption]);
        }
      }
    }, [props?.registrationData?.jobTitle, jobTitleData]);
  
    useEffect(() => {
      const defaultJobTitle = props?.registrationData?.companyName;
      if (defaultJobTitle) {
        const defaultOption = companyData.find(
          (option) => option.value === defaultJobTitle
        );
        if (defaultOption) {
          setSelectedCompanyData([defaultOption]);
        }
      }
    }, [props?.registrationData?.companyName, companyData]);
    const nameInputRef = useRef(null);

  useEffect(() => {
    // Set focus on the name field when the component mounts
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []); 
  const changeGreenBorderStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#007456"
      },
      "&:hover fieldset": {
        borderColor: "#007456"
      }
    }
  }
  // const customStyles = {
   
  //   control: (base) => ({
  //     ...base,
  //     cursor: "pointer", // Set the cursor to pointer for the control
  //   }),
  //   option: (base) => ({
  //     ...base,
  //     cursor: "pointer", // Set the cursor to pointer for the options
  //   }),
  //   control: (provided, state) => ({
  //     ...provided,
  //     borderColor: state.isFocused ? "#007456" : provided.borderColor, // Change border color to green when focused
  //     boxShadow: "none", // Remove the default box shadow

  //     borderStyle: "solid",
  //     "&:hover": {
  //       borderColor: "#007456", // Change border color to green on hover

  //     },
  //   }),
  // };
  const customStyles = {
    control: (base) => ({
      ...base,
      cursor: "pointer", // Set the cursor to pointer for the control
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      cursor: "pointer", // Set the cursor to pointer for the options
      backgroundColor: isFocused ? "#8bca84" : isSelected ? "#8bca84" : base.backgroundColor, // Change background color on hover and when selected
      color: isFocused ? "#fff" : isSelected ? "#fff" : base.color, // Change text color for better visibility on hover and when selected
      ":hover": {
        color: "#fff", // Change text color to white on hover
      },
      ":active": {
        backgroundColor: "#8bca84", // Change background color when the option is active (clicked)
      },
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#007456" : provided.borderColor, // Change border color to green when focused
      boxShadow: "none", // Remove the default box shadow
      borderStyle: "solid",
      "&:hover": {
        borderColor: "#007456", // Change border color to green on hover
      },
    }),
    singleValue: (base, { isFocused }) => ({
      ...base,
      color: isFocused ? "#fff" : base.color, // Change text color when selected or on hover
    }),
  };
  return (
    <div className='employerRegistrationForm'>
        <div className="employeeContent">
          <form
            // onSubmit={formikDetails.handleSubmit}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent the default form submission
              }
            }}
            
          >
           

            <div className="required-fields-styles">
              <div className="formHeader">
                <div className=" individual-field">
                  <div className="label mb-2">
                    <span className="text-red-500 ">*</span>
                    <span className="create-labels mx-1">Name</span>
                  </div>
                  <div className="popins-medium">
                    <TextField
                      error={
                        formikDetails.errors.name && formikDetails.touched.name
                      }
                      inputRef={nameInputRef}
                      // autoFocus={!props?.registrationData?.mobile}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={changeGreenBorderStyle}
                      onClick={() => {}}
                      type="text"
                      name="name"
                      id="name"
                     
                      inputProps={{
                        className: cx(` impFont_14px boxedInput `),
                        style: {
                          padding: "9.5px 14px",
                          height: "0.43em !important",
                         fontSize: "14px"  
                        },
                      }}
                      
                      autoComplete="off"
                      placeholder="Enter your name"
                      onBlur={handleTrimmedBlur}
                      onChange={formikDetails.handleChange}
                      value={formikDetails.values.name}
                    />
                  </div>
                  {formikDetails.errors.name && formikDetails.touched.name ? (
                    <div className="text-red-500 error_text ">
                      {formikDetails.errors.name}
                    </div>
                  ) : null}
                </div>
                <div className="form-group  phoneNumberSection individual-field text-left">
                  <div className="label mb-2">
                    <span className="text-red-500 ">*</span>
                    <span className="create-labels mx-1">Phone Number</span>
                  </div>

                  <PhoneInput
                    type="text"
                    // placeholder="Enter phone number"
                    value={formikDetails.values.mobile}
                    searchPlaceholder="Search..."
                    searchNotFound="No Countries Found"
                    specialLabel=""
                    autoFormat={false}
                    defaultCountry={
                      presentCountry && presentCountry.toLowerCase()
                    }
                    enableAreaCodeStretch
                    country={presentCountry && presentCountry.toLowerCase()}
                    name="mobile"
                    id="mobile"
                    style={{
                      border: `${
                        error.mobile || error.validPhone ? inputBorderErr : ""
                      }`,
                      borderRadius: "5px",
                      width: "330px",
                    }}
                    enableSearch
                    international
                    inputProps={{
                      // className: cx(`!text-xs impFont_14px boxedInput `),
                      style: {
                        // padding: "9.5px 14px",
                        height: "0.43em !important",
                      },
                    }}
                    autoComplete="off"
                    onBlur={(e) => {
                      handleOnBlur("mobile");
                      const phoneNumber = e.target.value.replace(/\s/g, "");
                      const formattedPhoneNumber = phoneNumber.startsWith("+91")
                        ? phoneNumber
                        : `+91${phoneNumber}`;
                      checkAvailability("mobile", formattedPhoneNumber);
                    }}
                    // onKeyUp={() => resetPhoneNumberFieldError(0)}
                    onChange={(e) => {
                      console.log(e);
                      formikDetails.setFieldValue("mobile", e);
                      setValue(e);
                    }}
                    countryCodeEditable={false}
                  />
                  {(error.mobile ||
                    (formikDetails.errors.mobile &&
                      formikDetails.touched.mobile)) && (
                    <div className="error_text">Phone number is required</div>
                  )}
                  {error.validPhone && !error.phone && (
                    <div className="error_text">Invalid mobile number</div>
                  )}
                  <div className="text-red-500 error_text ">
                    {availabilityStatusPhone}
                  </div>
                </div>
              </div>
              <div className="formHeader">
                <div className=" individual-field">
                  <div className="label mb-2">
                    <span className="text-red-500 ">*</span>
                    <span className="create-labels mx-1">Email</span>
                  </div>
                  <div className="popins-medium" style={{ height: "45px" }}>
                    <div
                      className={cx(
                        "w-full user-email-input-tyle",
                        style["labeled-input"],
                        {
                          [style["number-input-control"]]:
                            props?.type === "number",
                        }
                      )}
                    >
                      <TextField
                        error={
                          formikDetails.errors.email &&
                          formikDetails.touched.email
                        }
                        fullWidth
                        sx={changeGreenBorderStyle}
                        hiddenLabel
                        style={{ height: "32px" }}
                        className={cx(
                          "text-xs open-sans-regular",
                          style["form-control"]
                        )}
                        placeholder={"Enter your email"}
                        value={formikDetails.values.email.trim().toLowerCase()}
                        onBlur={(event) => {
                          formikDetails.handleBlur(event);
                          handleTrimmedBlur(event);
                          // checkValidEmail(event);
                          checkAvailability(
                            event.target.id,
                            event.target.value
                          );
                        }}
                        onChange={(event) => {
                          formikDetails.handleChange(event);
                          setAvailabilityStatusEmail("");
                        }}
                        autoComplete="off"
                        name="email"
                        id="email"
                        onFocus={() => setEmailError("")}
                        inputProps={{
                          className: cx(`!text-xs impFont_14px boxedInput `),
                          style: {
                            padding: "15px 14px",
                            height: "0.43em !important",
                            fontFamily: "Poppins-Regular",
                          },
                        }}
                        FormHelperTextProps={{
                          className: "impFont_14px",
                        }}
                        onKeyDown={(evt) =>
                          ["Enter"].includes(evt.key) && evt.preventDefault()
                        }
                      />
                    </div>
                  </div>
                  {formikDetails.errors.email && formikDetails.touched.email ? (
                    <div className="text-red-500 error_text relative ">
                      {formikDetails.errors.email}
                    </div>
                  ) : null}
                  <div className="text-red-500 error_text ">
                    {availabilityStatusEmail}
                  </div>
                </div>
                <div className="form-group  individual-field text-left">
                  <div className="label mb-2">
                    <span className="text-red-500 ">*</span>
                    <span className="create-labels mx-1">Job Title</span>
                  </div>

                  <div className="popins-medium jobTitleDiv" style={{ height: "45px" }}>
                    <div
                      className={`w-full clientReg-Select user-email-input-tyle ${
                        (formikDetails.errors.jobTitle &&
                          formikDetails.touched.jobTitle) ||
                        (formikDetails.touched.jobTitle &&
                          !formikDetails.values.jobTitle)
                          ? "error-input"
                          : ""
                      }`}
                      onBlur={handleSelectBlur}
                      id="search_dropdown_emp"
                    >
                      <Select
                        options={jobTitleData}
                        onChange={handlejobTitleChange}
                        value={selectedJobTitleData}
                        isClearable={false}
                        isSearchable={true}
                        menuShouldScrollIntoView={false}
                        className="my-select-container-clientReg  cursor-pointer"
                        styles={customStyles} 
                        placeholder="Select job title... "
                      />
                    </div>
                   
                  </div>
                  {formikDetails.errors.jobTitle &&
                      formikDetails.touched.jobTitle && (
                        <div className="text-red-500  error_text   ">
                          {formikDetails.errors.jobTitle}
                        </div>
                      )}
                </div>
              </div>
              {formikDetails.values.jobTitle === "Others" && (
                <div className=" individual-field" style={{width:"362px"}}>
                  <div className="label mb-2">
                    <span className="text-red-500 ">*</span>
                    <span className="create-labels mx-1">Other Job Title</span>
                  </div>
                  <div className="popins-medium">
                    <TextField
                      error={
                        formikDetails.errors.jobTitleOthers &&
                        formikDetails.touched.jobTitleOthers
                      }
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={changeGreenBorderStyle}
                      onClick={() => {}}
                      type="text"
                      name="jobTitleOthers"
                      id="jobTitleOthers"
                      inputProps={{
                        className: cx(` impFont_14px boxedInput `),
                        style: {
                          padding: "14px 14px",
                          height: "15px",
                          fontSize: "14px" 
                        },
                      }}
                      autoComplete="off"
                      placeholder="Enter your job title"
                      onBlur={formikDetails.handleBlur}
                      onChange={formikDetails.handleChange}
                      value={formikDetails.values.jobTitleOthers}
                    />
                  </div>
                  {formikDetails.errors.jobTitleOthers &&
                  formikDetails.touched.jobTitleOthers ? (
                    <div className="text-red-500 error_text ">
                      {formikDetails.errors.jobTitleOthers}
                    </div>
                  ) : null}
                </div>
              )}
            
              <div className="individual-field  companyName">
                <div className="label mb-2">
                  <span className="text-red-500 ">*</span>
                  <span className="create-labels mx-1">Company Name</span>
                </div>

                <div className="popins-medium" style={{ height: "43px",width:"330px" }}>
                  <div
                    className={`w-full clientReg-Select companyNameDiv user-email-input-tyle  ${
                      (formikDetails.errors.companyName &&
                        formikDetails.touched.companyName) ||
                      (formikDetails.touched.companyName &&
                        !formikDetails.values.companyName)
                        ? "error-input"
                        : ""
                    }`}
                    onBlur={handleSelectBlurCompany}
                    id="search_dropdown_emp"
                  >
                    <Select
                      options={companyData}
                      onChange={handleCompanyChange}
                      value={selectedCompanyData}
                      // handleSelectChange={handlecompanyChange}
                      onBlur={() => formikDetails.handleBlur("companyName")}
                      searchable={true}
                      backspaceDelete={true}
                      isClearable={false}
                      isSearchable={true}
                      
                      // styles={{
                      //   // Define custom styles for the dropdown option
                      //   option: (provided) => ({
                      //     ...provided,
                      //     cursor: "pointer", // Add cursor pointer style
                      //   }),
                      //   // Define custom styles for the placeholder
                      //   placeholder: (base) => ({
                      //     ...base,
                      //     fontSize: "14px", // Adjust the font size as needed
                      //     fontFamily: "Inter",
                      //     fontWeight:"400",
                      //       letterSpacing:"0.5px",
                      //     color: "#bcbcbc",
                      //   }),
                      // }}
                      inputProps={{
                      
                        className: cx(`!text-xs impFont_14px boxedInput `),
                        style: {
                          padding: "14px 14px",
                          height: "0.43em !important",
                        },
                      }}
                      className="my-select-container-clientReg cursor-pointer"
                      placeholder="Select company name... "
                      // Add a custom class
                        styles={customStyles} // Apply the custom styles
                      
                    />
                  </div>
                  
                </div>
                {formikDetails.errors.companyName &&
                    formikDetails.touched.companyName && (
                      <div className="text-red-500 error_text  mt-1">
                        {formikDetails.errors.companyName}
                      </div>
                    )}
              </div>

              {formikDetails.values.companyName === "Others" && (
                <div className=" " style={{width:"362px"}}>
                  <div className="field-wrapper individual-field">
                    <div className="label mb-2">
                      <span className="text-red-500 ">*</span>
                      <span className="create-labels mx-1">Other Company</span>
                    </div>
                    <div className="popins-medium">
                      <TextField
                        id="otherCompanyName"
                        name="otherCompanyName"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={changeGreenBorderStyle}
                        inputProps={{
                          className: cx(` impFont_14px boxedInput `),
                          style: {
                            padding: "15px 14px",
                            height: "15px",
                            fontFamily: "Poppins-Regular",
                            fontSize: "14px" 
                          },
                        }}
                        FormHelperTextProps={{
                          className: "impFont_14px",
                        }}
                        autoComplete="off"
                        placeholder="Enter your company name"
                        onBlur={formikDetails.handleBlur}
                        value={formikDetails.values.otherCompanyName}
                        onChange={formikDetails.handleChange}
                        error={
                          formikDetails.touched.otherCompanyName &&
                          Boolean(formikDetails.errors.otherCompanyName)
                        }
                      />
                    </div>
                    {formikDetails.errors.otherCompanyName &&
                    formikDetails.touched.otherCompanyName ? (
                      <div className="text-red-500 error_text ">
                        {formikDetails.errors.otherCompanyName}
                      </div>
                    ) : null}
                  </div>

                  <div className="field-wrapper mt-2 individual-field">
                    <div className="label  mb-2 mx-1">
                      <span className="text-red-500 ">*</span>
                      <span className="create-labels">Location</span>
                    </div>
                    <div className="popins-medium">
                      <TextField
                        id="location"
                        name="location"
                        fullWidth
                        sx={changeGreenBorderStyle}
                        inputProps={{
                          className: cx(` impFont_14px boxedInput `),
                          style: {
                            padding: "15px 14px",
                            height: "15px",
                            fontFamily: "Poppins-Regular",
                            fontSize: "14px" 
                          },
                        }}
                        FormHelperTextProps={{
                          className: "impFont_14px",
                        }}
                        autoComplete="off"
                        placeholder="Enter your location"
                        onBlur={formikDetails.handleBlur}
                        value={formikDetails.values.location}
                        onChange={formikDetails.handleChange}
                        error={
                          formikDetails.touched.location &&
                          Boolean(formikDetails.errors.location)
                        }
                      />
                    </div>
                    {formikDetails.errors.location &&
                    formikDetails.touched.location ? (
                      <div className="text-red-500 error_text ">
                        {formikDetails.errors.location}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}

              <div className="passwordContainer">
                <div className="individual-field ">
                  <div className="label mb-2">
                    <span className="text-red-500 ">*</span>
                    <span className="create-labels mx-1">Password</span>
                  </div>
                  <TextField
                    name="password"
                    type={!showPassword ? "text" : "password"}
                    error={
                      formikDetails.errors.password &&
                      formikDetails.touched.password
                    }
                    sx={changeGreenBorderStyle}
                    autoComplete="new-password"
                    placeholder="Enter your password"
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.password}
                    
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            className=" cursor-pointer"
                          >
                            {showPassword ? <img src={InvisibleEye} style={{height:"20px"}}/> : <img src={VisibleEye} style={{height:"20px"}}/>}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      <span 
                        className=" error-msg  flex justify-between w-max text-red-500 error_text"
                        style={{ width: "302px" , fontStyle:"Poppins-Medium",marginLeft:"-13px" }}
                      >
                        {formikDetails.touched.password &&
                          formikDetails.errors.password}
                        {!formikDetails.errors.password &&
                        formikDetails.values.password ? (
                          <span
                            className="text-green-500 relative success_validation_text  w-max"
                            style={{ width: "max-content" }}
                          >
                            Strong password, good
                          </span>
                        ) : null}
                        {STRONG_PASSWORD_PATTERNS.some(
                          ({ pattern }) =>
                            !pattern.test(formikDetails.values.password)
                        ) &&
                          (formikDetails.values.password ||
                            formikDetails.touched.password) && (
                            <Tooltip
                              data-html="true"
                              title={
                                <div>
                                  {STRONG_PASSWORD_PATTERNS.map(
                                    (passwordPattern) => {
                                      return (
                                        <Fragment
                                          className="absolute"
                                          key={passwordPattern.message}
                                        >
                                          {!passwordPattern.pattern.test(
                                            formikDetails.values.password
                                          ) && (
                                            <p
                                              style={{
                                                fontSize: "10px",
                                                fontFamily: "Poppins-Regular",
                                                margin: "1px",
                                              }}
                                            >
                                              {passwordPattern.message}
                                            </p>
                                          )}
                                        </Fragment>
                                      );
                                    }
                                  )}
                                </div>
                              }
                              placement="bottom"
                            >
                              <span
                              style={{left:"28px"}}
                                className={cx(
                                  "cursor-pointer relative left-4 password-info-icon",

                                  {
                                    "error-msg text-red-500 error_text":
                                      formikDetails.touched.password &&
                                      formikDetails.errors.password,
                                    "text-blue-500 ml-auto":
                                      !formikDetails.touched.password ||
                                      !formikDetails.errors.password,
                                  }
                                )}
                              >
                                <InfoOutlinedIcon />
                              </span>
                            </Tooltip>
                          )}
                      </span>
                    }
                  />
                </div>

                <div className="individual-field ">
                  <div className="label mb-2">
                    <span className="text-red-500 ">*</span>
                    <span className="create-labels mx-1">Confirm Password</span>
                  </div>
                  <TextField
                    name="confirmPassword"
                    type={!confirmShowPassword ? "text" : "password"}
                    error={
                      formikDetails.errors.confirmPassword &&
                      formikDetails.touched.confirmPassword
                    }
                    sx={changeGreenBorderStyle}
                    autoComplete="off"
                    placeholder="Confirm your password"
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.confirmPassword}
                   
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            onClick={handleClickConfirmShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            className=" cursor-pointer"
                          >
                            {confirmShowPassword ? <img src={InvisibleEye} style={{height:"20px"}}/> : <img src={VisibleEye} style={{height:"20px"}}/>}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formikDetails.errors.confirmPassword &&
                  formikDetails.touched.confirmPassword ? (
                    <div className="text-red-500 error_text ">
                      {formikDetails.errors.confirmPassword}
                    </div>
                  ) : null}
                  {formikDetails.touched.confirmPassword &&
                  formikDetails.values.password.length > 7 &&
                  formikDetails.values.password ===
                    formikDetails.values.confirmPassword ? (
                    <div className="text-green-500  success_validation_text absolute">
                      Password matched
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="search-footer">
              {/* <button
                className="cancelBtn"
                onClick={() => {
                //   state?.source === "backToHome"
                //     ? navigate("/")
                //     : navigate("/register");
                }}
              >
                Cancel
              </button> */}
              <button
                type="submit"
                className="submitBtn"
                name="button_1"
                onClick={formikDetails.handleSubmit}
                disabled={
                  availabilityStatusPhone ||
                  availabilityStatusEmail ||
                  (error.validPhone && !error.phone)
                }
              >
                Request OTP
              </button>
            </div>
            <div className="form-heading1">
              <p className="mx-2">Already have an account?</p>
              <p className=" cursor-pointer loginText" onClick={()=>{props.handelTabs(0)}}>Login</p>
            </div>
          </form>
        </div>
        <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        className={"tost-msg-stylinh"}
        draggable={false}
        theme="light"
      />
    </div>
  )
}

export default EmployerRegistrationForm