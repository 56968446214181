import React, { useState, useEffect } from 'react'
import "./CategoriesItem.scss"
import Slider from "react-slick";
import CodeIcon from '../../../../assets/Vector.svg';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useNavigate } from "react-router-dom";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';

import Leaf from "../../website-assets/leaf.svg";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { getAllCategories } from '../../../../api/api-services';

import Itlogo from '../../../../assets/categoryLogos//ItCategory.svg'
import technologylogo from '../../../../assets/categoryLogos//technologylogo.svg'
import healthandcarelogo from '../../../../assets/categoryLogos//health&care.svg'
import transportRealestatelogo from '../../../../assets/categoryLogos//transportandrealestate.svg'
import manifacturelogo from '../../../../assets/categoryLogos//manifacturelogo.svg'
import bfsilogo from '../../../../assets/categoryLogos//bfsiLogo.svg'
import bmplogo from '../../../../assets/categoryLogos//bmpLogo.svg'
import retaillogo from '../../../../assets/categoryLogos//retailAndHospetality.svg'
import professionalServicelogo from '../../../../assets/categoryLogos//professionalServicelogo.svg'
import medialogo from '../../../../assets/categoryLogos//medialogo.svg'
import remotelogo from '../../../../assets/categoryLogos//remoteLogo.svg'
import mnclogo from '../../../../assets/categoryLogos//MNClogo.svg'
import fresherlogo from '../../../../assets/categoryLogos//fresherlogo.svg'
import supplyChainlogo from '../../../../assets/categoryLogos//supplyChain.svg'
import analyticslogo from '../../../../assets/categoryLogos//analyticslogo.svg'
import internlogo from '../../../../assets/categoryLogos//internLogo.svg'
import engineerlogo from '../../../../assets/categoryLogos//engineerlogo.svg'
import productManagelogo from '../../../../assets/categoryLogos//projectManagelogo.svg'
import bankinglogo from '../../../../assets/categoryLogos//bankingLogo.svg'
import dataSciencelogo from '../../../../assets/categoryLogos//dataSciencelogo.svg'


function CategoriesItem(props) {
  // const sliderSettings = {
  //   dots: true,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 2,
  //   autoplay: false,
  //   autoplaySpeed: 5000,
  //   pauseOnHover: true,
  //   appendDots: (dots) => (
  //     <div style={{ bottom: "-40px" }}>
  //       <ul style={{ margin: "0px" }}> {dots} </ul>
  //     </div>
  //   ),
  //   customPaging: (i) => (
  //     <div
  //       style={{
  //         width: "10px",
  //         height: "10px",
  //         borderRadius: "50%",
  //         background: "#aaa", // Adjust the color as needed
  //         margin: "0 5px",
  //         cursor: "pointer",
  //       }}
  //     ></div>
  //   ),
  // };
  const [activeDot, setActiveDot] = useState(0);
  const [dropDownData, setDropDownData] = useState([]);
  const navigate = useNavigate();
  const calculateSlidesToShow = (windowWidth) => {
    if (windowWidth >= 2560) {
      return 5;
    } else if (windowWidth >= 1024) {
      return 4;
    } else if (windowWidth >= 600) {
      return 2;
    } else {
      return 1;
    }
  };

  // Add an event listener to handle window resize
  useEffect(() => {
    const handleResize = () => {
      const slidesToShow = calculateSlidesToShow(window.innerWidth);
      setActiveDot(0); // Reset the active dot when the window resizes
      setSlidesToShow(slidesToShow);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // In your component state, add a state for slidesToShow
  const [slidesToShow, setSlidesToShow] = useState(calculateSlidesToShow(window.innerWidth));

  // Update the slidesToShow property in your Slider component
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    beforeChange: (current, next) => {
      setActiveDot(next);
    },
    appendDots: (dots) => (
      <div >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        key={i}
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: activeDot === i ? "#000000" : "#aaa",
          margin: "0 5px",
          cursor: "pointer",
          transition: "all 0.3s",
          transform: activeDot === i ? "scale(1.5)" : "scale(1)",
        }}
      ></div>
    ),
  };
  // const sliderSettings = {
  //   dots: true,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   autoplay: false,
  //   autoplaySpeed: 5000,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: true
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ],
  //   pauseOnHover: true,
  //   beforeChange: (current, next) => {
  //     setActiveDot(next);
  //   },
  //   appendDots: (dots) => (
  //     <div style={{ bottom: "-40px" }}>
  //       <ul style={{ margin: "0px" }}> {dots} </ul>
  //     </div>
  //   ),
  //   customPaging: (i) => (
  //     <div
  //       key={i}
  //       style={{
  //         width: "10px",
  //         height: "10px",
  //         borderRadius: "50%",
  //         background: activeDot === i ? "#000000" : "#aaa",
  //         margin: "0 5px",
  //         cursor: "pointer",
  //         transition: "all 0.3s",
  //         transform: activeDot === i ? "scale(1.5)" : "scale(1)",
  //       }}
  //     ></div>
  //   ),
  // };
  const handleSearchClick = (industry) => {
    console.log("event", industry, "industry")
    // Check if there is a selected value in the dropdown
    navigate("/job-search", {
      state: {
        category: [{ label: `${industry}`, value: `${industry}` }],
        searchValue: ""
      },
    })

  };
  const getIndustries = async () => {
    try {
      const res = await getAllCategories();
      if (res?.data) {
        setDropDownData(res.data.response)
      } else {
        console.log(res, "res")
      }
    } catch (e) {
      console.log("countires error", e);
    }
  };
  useEffect(() => {
    getIndustries()
  }, [])

  const categoryCard = () => {
    const newArray = [];
    for (const key in dropDownData) {
      if (dropDownData.hasOwnProperty(key)) {
        newArray.push({
          "industry": key,
          "count": props.dropDownData[key]
        });
      }
    }
    console.log(newArray, "newArraynewArray")
    return newArray.map((element) => {
      const industryLogos = {
        "IT": Itlogo,
        "TECHNOLOGY": technologylogo,
        "HEALTHCARE & LIFESCIENCES": healthandcarelogo,
        "INFRASTRUCTURE, TRANSPORT & REALESTATE": transportRealestatelogo,
        "MANUFACTURING & PRODUCTION": manifacturelogo,
        "BFSI": bfsilogo,
        "BPM": bmplogo,
        "CONSUMER, RETAIL & HOSPITALITY": retaillogo,
        "PROFESSIONALSERVICES": professionalServicelogo,
        "MEDIA, ENTERTAINMENT & TELECOM": medialogo,
        "ENTERTAINMENT & TELECOM": medialogo,
        "REMOTE": remotelogo,
        "MNC": mnclogo,
        "FRESHER": fresherlogo,
        "SUPPLYCHAIN": supplyChainlogo,
        "ANALYTICS": analyticslogo,
        "INTERNSHIP": internlogo,
        "ENGINEERING": engineerlogo,
        "PROJECT MANAGEMENT": productManagelogo,
        "BANKING & FINANCE": bankinglogo,
        "HR": fresherlogo,
        "SALES":Itlogo,
        "DATASCIENCE": dataSciencelogo,
    };

    const selectLogo = (industry) => industryLogos[industry.toUpperCase()] || Itlogo;
      function formatString(inputString) {
        return inputString.replace(/_/g, ' ').replace(/\bAND\b/g, '&');
      }

      function toCamelCase(inputString) {
        return inputString.replace(/\w\S*/g, (word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
      }
      return (
     
        <div className='col-2 m-5'>
          <div className='categoryCard row' onClick={(e) => {
            console.log(e, "event")
            handleSearchClick(element.industry)
          }}
          style={{ marginLeft: "35px", marginRight: "-17px" }}>
            <div className='cardLogo col-5'>
              <>
                <img style={{ marginTop: "11px", marginLeft: "1px" }} src={selectLogo(element.industry)}></img>
              </>
            </div>
            <div className='cardHeadder col-12'>
              <span>{toCamelCase(formatString(element.industry))}</span>
            </div>
            <div className='cardSubTitle'>
              {element.count} jobs
            </div>
          </div>
        </div>
      )
    })
  }


  return (
    <div className="m-5 mt-0 p-4 ml-2 row">
      <div>

        <div style={{ justifyContent: "space-between" }} className='flex category_headerContent'>
          <div>
            <p className='categoryHeader'>Popular category</p>
            {/* <span className='jobUpdates'> 2020 jobs live – 293 added today.</span> */}
          </div>
          {console.log(Object.keys(props.dropDownData).length, "props.dropDownData.length")}
          {Object.keys(props.dropDownData).length > 4 && (
            <div onClick={() => {
              navigate("/categories");
            }} className=' flex justify-center items-center'><span onClick={() => {
              navigate("/categories");
            }} className='viewAll cursor-pointer viewallMedia'> View all categories</span>
            </div>
          )}
        </div>
      </div>
      <div style={{ margin: "3px" }} className='sliderContainer'>
        <Slider {...sliderSettings} className="cardSection">
          {
            categoryCard()
          }

          {/* {props.data?.length != 0 && (
            props.data.map((jonInfo) => {
              let iconRender = (title) => {
                if (title === "Development & IT") {
                  return (<CodeIcon fontSize='medium' className='iconClass' />)
                } else if (title === "Marketing & Sales") {
                  return (<MyLocationIcon fontSize='medium' className='iconClass' />)
                } else if (title === "Customer Service") {
                  return (<HeadsetMicIcon fontSize='medium' className='iconClass' />)
                } else if (title === "Design & Creative") {
                  return (<ColorLensOutlinedIcon fontSize='medium' className='iconClass' />)
                } else {
                  return (<PermIdentityIcon fontSize='medium' className='iconClass' />)
                }
              }
              return (
                <div className='col-2 m-5'>
                  <div className='categoryCard row' style={{ marginLeft: "35px", marginRight: "-17px" }}>
                    <div className='cardLogo col-5'>
                      <>
                        {iconRender(jonInfo.title)}
                      </>
                    </div>
                    <div className='cardHeadder col-12'>
                      <span>{jonInfo.industry}</span>
                    </div>
                    <div className='cardSubTitle'>
                      {jonInfo.count}
                    </div>
                  </div>
                </div>
              )
            })
          )} */}
        </Slider>
      </div>
    </div>
  )
}

export default CategoriesItem