import React,{useEffect} from 'react'
import NavigationHeader from '../nav-section/NavigationHeader'
import FooterSectionHome from '../footer-section/FooterSectionHome'
import InfoPage from './InfoPage'
import Analytics from './Analytics'
import CustomerReviews from './CustomerReviews'
import CompanyLocation from './CompanyLocation'
import WorkDetails from './WorkDetails'
import "./InfoPage.scss"

function AboutUsHome() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top on component mount
    
        const handleRefresh = () => {
          window.scrollTo(0, 0); // Scroll to the top on page refresh
        };
    
        window.addEventListener("beforeunload", handleRefresh);
    
        return () => {
          window.removeEventListener("beforeunload", handleRefresh);
        };
      }, []);
    return (
        <div className='aboutUsComponent'>
         <div className="navbarSection-aboutUs">
        <NavigationHeader />
      </div>
           <div >
           <InfoPage />
            <Analytics />
            <WorkDetails/>
            <CustomerReviews />
            <CompanyLocation/>
            <FooterSectionHome />
           </div>
            
        </div>
    )
}

export default AboutUsHome