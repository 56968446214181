import React,{useState} from "react";
import "./JobListItem.scss";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { useNavigate } from "react-router-dom";
import LoginSignUpModal from "../../../website-signup-home/LoginSignUpModal";
import rupeeLogo from "../../../../../assets/rupeeIcon.svg"

function JobListItem(props) {
  console.log(props.details, "asd")
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const navigate = useNavigate();
  const empType =
    props?.details?.employmentType &&
    props?.details?.employmentType.replace(/_/g, " ").toLowerCase();
    function toCamelCase(inputString) {
      return inputString.replace(/\w\S*/g, (word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });
    }
  const salary =
    props?.details?.salaryRange &&
    `${props?.details?.salaryRange.min} - ${props?.details?.salaryRange.max}/Year`;
  const salaryfn = () => {
    return (
      <>
        <img src={rupeeLogo}></img> <span>{props?.details?.salaryRange.min}</span> -  <img src={rupeeLogo}></img> <span>{props?.details?.salaryRange.max}</span>
      </>
    )
  } 
  return (
    <div
      className="localcardContainer cursor-pointer widthresp"
      onClick={(e) => {
        if (!e.target.classList.contains("apply-now-button") && !isOpenLoginModal) {
          navigate(`/job-details/${props.details.id}`, {
            state: {
              
              getSemilarJobPost: props.filteredData.content,
            },
          });
        }
      }}
    >
      <div className="d-block d-lg-flex">
        <div className="companyIcon col-sm-12">
          {props.details.companyName.slice(0, 2)}
        </div>
        <div className="jobDetails row-sm-12">
          <p className="cardHead1">
            {props.details.jobHeadlines.length > 50
              ? `${props.details.jobHeadlines.slice(0, 50)}...`
              : props.details.jobHeadlines}
          </p>
          <div className="companyInfo">
            <span className="text">by</span>{" "}
            <span className="compName">{`${props?.details?.companyName}`}</span>{" "}
            <span className="text">in</span>{" "}
            <span className="jobType">{`${props.details.functionalArea}`}</span>
          </div>
        </div>
       
          <button
            className=" apply-now-button"
            onClick={(e) => {
                      setIsOpenLoginModal(true);
                      
                    }}
          >
            Apply 
          </button>
        
      </div>
      <div className="d-block d-md-flex capsuleContainer row">
        <div className="flex col-6">
          <div className="jobCapsule">
            <p className="capsuleone">{`${toCamelCase(empType)}`}</p>
          </div>
          <div className="jobCapsule ">
            <p className="capsulegreenone">
              <span className="capLogo">
                <RoomOutlinedIcon style={{height:"16px",width:"14px"}} />
              </span>
              {props?.details?.location[0]}
            </p>
          </div>
          {console.log(salary,"salarysalarysalary")}
          <div className="jobCapsule">
            <p className="salaryCapsule">{salary}</p>
          </div>
        </div>
        <div className="daysCount col-md-6 ">
          {props.details.postedAgo === 0 ? (
            <p>
              <span className="text1">Posted today </span>
            </p>
          ) : (
            <p>
              Posted{" "}
              <span className="text1">{`${props.details.postedAgo}`}</span> days
              ago
            </p>
          )}
        </div>
      </div>
      <div className="display-div">
        <div className="row">
          <div className="jobCapsule col-12">
            <p className="capsuleone">{`${empType}`}</p>
          </div>
          <div className="jobCapsule col-12">
            <p className="capsulegreenone">
              <span className="capLogo">
                <RoomOutlinedIcon fontSize="small" />
              </span>
              {props?.details?.location[0]}
            </p>
          </div>
          <div className="jobCapsule col-12">
            <p className="salaryCapsule">{salary}</p>
          </div>
        </div>
      </div>
      <div className="display-div daysCount">
        {props.details.postedAgo === 0 ? (
          <p>
            <span className="text1">Posted today </span>
          </p>
        ) : (
          <p>
            Posted <span className="text1">{`${props.details.postedAgo}`}</span>{" "}
            days ago
          </p>
        )}
      </div>
      {isOpenLoginModal && (
        <LoginSignUpModal
          clickMode={"logIn"}
          setIsOpenLoginModal={setIsOpenLoginModal}
          setShowMediaIcons={setIsOpenLoginModal}
          isNewUser={true}
          newUserId={props.details.id}
        />
      )}
    </div>
  );
}

export default JobListItem;
