import React, { useEffect, useRef, useState } from "react";
import { Checkbox } from "@material-ui/core";
import { ReactComponent as EducationIcon } from "../../assets/education.svg";
import "./InterviewCandidate.scss";

import CandidateTable from "../common/candidate-table/CandidateTable";
import InterviewModals from "./modals/InterviewModals.jsx";
import {
  getAllCandidates,
  getAllCandidatesByStatus,
  coordinatorAllProfileCandidateSearch,
  coordinatorCandidateByStateSearch,
} from "../../api/api-services";
import CandidateColumnOption from "../common/CandidateColumnOption";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InterviewCandidate = (props) => {
  let [hireTableData, setHireTableData] = useState([]);
  let [openFilter, setOpenFilter] = useState(false);
  let [filterOptions, setFilterOptions] = useState([]);
  let [selectedFilters, setSelectedFilters] = useState({});
  let [selectedTab, setSelectedtab] = useState("All Profiles");
  let [candidateCardsData, setCandidateCardsData] = useState([]);
  const cardSearchInput = useRef(null);
  let [scheduleInterviewModal, setScheduleInterviewModal] = useState(false);
  let [selectCandidate, setSelectedCandidate] = useState({});
  let [pageno, setpageNo] = useState(0);
  let [lastPage, setLastpage] = useState(false);
  let size = 10;
  let [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [contextMenuData, setContextMenuData] = useState([]);
  const [allProfileHyphen, setallProfileHyphen] = useState("-");
  const [scheduledHyphen, setScheduledeHyphen] = useState("-");
  const [completedHyphen, setCompletedHyphen] = useState("-");
  let [tableApiData, setTableApiData] = useState({
    columnDef: {
      columns: [],
    },
    content: [],
  });
  let [hiddenColumnsData, setHiddenColumnsData] = useState([
    "email",
    "gender",
    "location",
    "highest_degree",
    "requested_clients",
    "shareable",
  ]);
  const [allProfileCount, setAllProfileCount] = useState("");
  const [scheduledCount, setscheduledCount] = useState("");
  const [completedCount, setcompletedCount] = useState("");
  const fetchFilterOptions = () => {
    setFilterOptions([
      {
        type: "Location",
        values: ["Bangalore", "Mysore", "Shivamogga", "Goa", "Mangalore"],
      },
      {
        type: "Experiance",
        values: ["Fresher", "0 - 1 yrs", "1 - 3 yrs", "3 - 5 yrs", "5+ yrs"],
      },
    ]);
  };

  const handleFilterSelection = (e, type, value) => {
    console.log(e.target.checked);
    let selectedData = { ...selectedFilters };
    if (e.target.checked) {
      if (selectedData?.[type]?.length > 0) {
        selectedData?.[type].push(value);
      } else {
        selectedData[type] = [value];
      }
    } else {
      if (selectedData?.[type]?.length > 0) {
        let index = selectedData?.[type].indexOf(value);
        selectedData?.[type].splice(index, 1);
      }
    }
    console.log(selectedData);
    setSelectedFilters({ ...selectedData });
  };

  let handleCandidateBtn = (func, data) => {
    console.log(func, data);
    setSelectedCandidate({ ...data });
    setScheduleInterviewModal(true);
  };

  let handelColumnsDispaly = (e) => {
    let value = e.target.value;
    let data = [...hiddenColumnsData];
    if (data.includes(value) && !e.target.checked) {
      data.splice(data.indexOf(value), 1);
    } else if (e.target.checked) {
      data.push(value);
    }
    setHiddenColumnsData([...data]);
  };

  let requestedProfile = {};

  let gettableData = async (tab, page) => {
    let payload = {
      jobPostId: requestedProfile.id,
    };
    let response;
    if (tab === "All Profiles") {
      response = await getAllCandidates(page, size);
      if (response.data) {
        setLastpage(response?.data?.response?.last);
        setAllProfileCount(response?.data?.response?.totalElements);
        if (response?.data?.response?.last === false) {
          setpageNo(page + 1);
        }
        let tabledata = { ...tableApiData };
        if (page === 0) {
          tabledata = response.data.response;
        } else {
          tabledata.content = tabledata.content.concat(
            response.data.response.content
          );
        }

        setTableApiData(tabledata);
      }
    } else if (tab === "Interview scheduled profiles ") {
      try {
        response = await getAllCandidatesByStatus("SCHEDULED", page, size);
        if (response.data) {
          setscheduledCount(response?.data?.response?.totalElements);
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    } else if (tab === "Interview Completed Profiles") {
      try {
        response = await getAllCandidatesByStatus("COMPLETED", page, size);
        if (response.data) {
          setcompletedCount(response?.data?.response?.totalElements);
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    }
    if (page === 0) {
      prepareHideDropDown(response.data.response);
    }
    setIsLoading(true);
  };
  let prepareHideDropDown = (response) => {
    let contextData = [];
    response.columnDef.columns.map((data) => {
      contextData.push({
        option: data.columnName,
      });
    });
    setContextMenuData([...contextData]);
  };
  const [restrictMultipleApiCalls, setRestrictMultipleApiCalls] =
    useState(false);
  // const handlescroll = (event) => {
  //   setRestrictMultipleApiCalls(false);
  //   if (
  //     Math.ceil(event?.target?.scrollTop + event?.target?.clientHeight) ===
  //     event?.target?.scrollHeight
  //   ) {
  //     if (lastPage === false) {
  //       gettableData(selectedTab, pageno);
  //       setRestrictMultipleApiCalls(true);
  //     } else {
  //       // setRestrictMultipleApiCalls(true);
  //     }
  //   }
  // };

  const handlescroll = (event) => {
    const target = event?.target;
    if (!target) return;
    setRestrictMultipleApiCalls(false);
    const scrolledToBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >=
      target.scrollHeight - 1;

    if (scrolledToBottom && !lastPage && !isSearching) {
      gettableData(selectedTab, pageno);
      setRestrictMultipleApiCalls(true);
    } else if (scrolledToBottom && !lastPage && isSearching) {
      gettableDataSearched(searchedText, selectedTab, pageno);
    } else {
      // setRestrictMultipleApiCalls(true);
    }
  };

  //here restricted api call in x- direction but this is having some glitch..not working in heigher resolution
  // const handlescroll = (event) => {
  //   const { scrollTop, scrollLeft } = event.target;

  //   if (scrollTop > 0 || scrollLeft > 0) {
  //     // User is scrolling in either the Y or X axis
  //     setRestrictMultipleApiCalls(false);

  //     if (scrollTop + event.target.clientHeight === event.target.scrollHeight) {
  //       // Scrolled to the bottom in the Y-axis
  //       if (lastPage === false) {
  //         gettableData(selectedTab, pageno);
  //         // setRestrictMultipleApiCalls(true);
  //       } else {
  //         setRestrictMultipleApiCalls(false);
  //       }
  //     }

  //     // Handle horizontal scrolling if needed
  //     if (scrollLeft + event.target.clientWidth === event.target.scrollWidth) {
  //       // Scrolled to the right in the X-axis
  //       // Handle X-axis scrolling here if necessary
  //       // alert("x")
  //     }
  //   }
  // };

  useEffect(() => {
    setIsLoading(false);
    gettableData("All Profiles", 0);
    setScheduledeHyphen(" ");setCompletedHyphen(" ")
  }, []);
  useEffect(() => {
    setRestrictMultipleApiCalls(false);
  }, [selectedTab, isLoading]);
  let handleTabs = (tab) => {
    if (tab !== selectedTab) {
      setpageNo(0);
      setLastpage(false);
      setIsLoading(true);

      if (tab === "All Profiles") {
        !restrictMultipleApiCalls && gettableData(tab, 0);
        setscheduledCount("");setcompletedCount("");setallProfileHyphen("-");setScheduledeHyphen("");setCompletedHyphen("");
        setSelectedtab("All Profiles");
      } else if (tab === "Interview scheduled profiles ") {
        !restrictMultipleApiCalls && gettableData(tab, 0);
        setAllProfileCount("");setcompletedCount("");setScheduledeHyphen("-");setallProfileHyphen("");setCompletedHyphen("");
        setSelectedtab("Interview scheduled profiles ");
      } else if (tab === "Interview Completed Profiles") {
        !restrictMultipleApiCalls && gettableData(tab, 0);
        setSelectedtab("Interview Completed Profiles");
        setscheduledCount("");setAllProfileCount("");setallProfileHyphen("");setScheduledeHyphen("");setCompletedHyphen("-")
      }
      // Clear the search input value when changing tabs
      if (cardSearchInput?.current) {
        cardSearchInput.current.value = "";
      }
    }
  };

  let handelCardSearch = (e) => {
    console.log(e.target.value);
    let fieldValue = e.target.value.toLowerCase();
    let candidateData = [];
    hireTableData.map((candidate) => {
      if (
        candidate.profile.toLowerCase().includes(fieldValue) ||
        candidate.skills.toLowerCase().includes(fieldValue) ||
        candidate.location.toLowerCase().includes(fieldValue) ||
        candidate.education.toLowerCase().includes(fieldValue)
      ) {
        candidateData.push({ ...candidate });
      }
    });
    setCandidateCardsData([...candidateData]);
  };

  let getCandidateStatus = (data) => {
    if (selectedTab === "Interview Completed Profiles") {
      return <span className="candidate-status">Interview taken</span>;
    } else if (selectedTab === "Interview scheduled profiles ") {
      return <span className="candidate-status">Request Pending</span>;
    }
  };
  let closeModal = (val) => {
    if (scheduleInterviewModal) {
      setScheduleInterviewModal(false);
    }
  };
  let actionele = (data) => {
    if (selectedTab === "All Profiles") {
      return (
        <button
          className="interviewListActionBtn   flex"
          onClick={() => {
            handleCandidateBtn("schedule", data);
          }}
        >
          <p className="datatrimmer">Schedule Interview</p>
        </button>
      );
    } else if (selectedTab === "Interview scheduled profiles ") {
      return (
        <button
          className=" interviewListActionBtn -ml-4 flex"
          onClick={() => {
            handleCandidateBtn("update", data);
          }}
        >
          <p className="datatrimmer">Update Interview</p>
        </button>
      );
    } else if (selectedTab === "Interview Completed Profiles") {
      return (
        <p className="candidate-status datatrimmer relative top-1  ">
          {" "}
          Completed{" "}
        </p>
      );
    }
  };

  const resetSearch = () => {
    setIsSearching(false);
    setpageNo(0);
    setLastpage(false);
  };
  let gettableDataSearched = async (e, tab, page) => {
    let response;
    if (tab === "All Profiles") {
      setIsSearching(true); // Set the flag to indicate search
      setIsLoading(true);
      setSearchedText(e);
      response = await coordinatorAllProfileCandidateSearch(e, page, size);
      if (response.data) {
        setLastpage(response?.data?.response?.last);
        setAllProfileCount(response?.data?.response?.totalElements);
        if (response?.data?.response?.last === false) {
          setpageNo(page + 1);
        }
        let tabledata = { ...tableApiData };
        if (page === 0) {
          tabledata = response.data.response;
        } else {
          tabledata.content = tabledata.content.concat(
            response.data.response.content
          );
        }

        setTableApiData(tabledata);
      }
    } else if (tab === "Interview scheduled profiles ") {
      try {
        setIsSearching(true); // Set the flag to indicate search
        setIsLoading(true);
        setSearchedText(e);
        response = await coordinatorCandidateByStateSearch(
          e,
          page,
          size,
          "SCHEDULED"
        );
        if (response.data) {
          setscheduledCount(response?.data?.response?.totalElements);
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    } else if (tab === "Interview Completed Profiles") {
      try {
        setIsSearching(true); // Set the flag to indicate search
        setIsLoading(true);
        setSearchedText(e);
        response = await coordinatorCandidateByStateSearch(
          e,
          page,
          size,
          "COMPLETED"
        );
        if (response.data) {
          setcompletedCount(response?.data?.response?.totalElements);
          setLastpage(response?.data?.response?.last);
          if (response?.data?.response?.last === false) {
            setpageNo(page + 1);
          }
          let tabledata = { ...tableApiData };
          if (page === 0) {
            tabledata = response.data.response;
          } else {
            tabledata.content = tabledata.content.concat(
              response.data.response.content
            );
          }

          setTableApiData(tabledata);
        }
      } catch (err) {
        console.error(err, "shared dynamic table error");
      }
    }
    if (page === 0) {
      prepareHideDropDown(response.data.response);
    }
    setIsLoading(true);
  };

  return (
    <div className="interview-candidate-section mx-3 w-full home-component-display mt-2.5 overflow-auto">
      <div className="componentHeader flex">
        <EducationIcon className="hidden" />
        <div
          className={`${
            selectedTab === "All Profiles"
              ? "selected-tabletitle"
              : "tabletitle"
          } cursor-pointer`}
          onClick={() => {
            handleTabs("All Profiles");
          }}
        >
          <span>All Profiles { allProfileCount !== undefined  ? allProfileHyphen + allProfileCount : "" }</span>
        </div>
        <div
          className={`${
            selectedTab === "Interview scheduled profiles "
              ? "selected-tabletitle"
              : "tabletitle"
          } cursor-pointer`}
          onClick={() => {
            handleTabs("Interview scheduled profiles ");
          }}
        >
          <span>
            Interview scheduled profiles {" "}
            {scheduledCount !== undefined ? (scheduledCount !== undefined ? scheduledHyphen:"" ) + scheduledCount : ""}
          </span>
        </div>
        <div
          className={`${
            selectedTab === "Interview Completed Profiles"
              ? "selected-tabletitle"
              : "tabletitle"
          } cursor-pointer`}
          onClick={() => {
            handleTabs("Interview Completed Profiles");
          }}
        >
          <span>
            Interview Completed Profiles{" "}
            {completedCount !== undefined ? completedHyphen + completedCount : ""}
          </span>
        </div>
        <div className="clickables">
          <div className="clickables">
            <CandidateColumnOption
              contextMenuData={contextMenuData}
              func={(event) => {
                handelColumnsDispaly(event);
              }}
              hiddenColumnsData={hiddenColumnsData}
            />
          </div>
        </div>
      </div>

      <div className={`hireTable ${openFilter ? "table-with-filter" : null} `}>
        <input
          className="table-search-input-coordinator col-span-2 "
          onChange={(e) => {
            if (e.target.value.trim() === "") {
              resetSearch();
              gettableData(selectedTab, pageno);
            } else {
              gettableDataSearched(e.target.value, selectedTab, 0);
            }
          }}
          ref={cardSearchInput}
          type="text"
          placeholder="Search"
        />
        <CandidateTable
          isLoading={isLoading}
          tableApiData={tableApiData}
          actionele={actionele}
          actionColumn={true}
          handlescroll={handlescroll}
          actionColumnWidth={"15%"}
          profileColumn={"name"}
          hiddenColumnsData={hiddenColumnsData}
          hideRating={false}
          selectedTab={selectedTab}
          interviewerTable={
            selectedTab === "Interview scheduled profiles "
              ? "interviewerTable"
              : ""
          }
          showVideo={
            selectedTab === "Interview Completed Profiles" ? "both" : ""
          }
          showMoreIconForDetails={
            selectedTab === "Interview Completed Profiles"
          }
        />
      </div>
      {openFilter && (
        <div className="hire-filter">
          <div className="filter-header">
            <span>Filter By</span>
            <span className="apply-btn">
              <button
                onClick={() => {
                  setOpenFilter(!openFilter);
                }}
              >
                Apply
              </button>
            </span>
          </div>
          {filterOptions.map((data, index) => {
            return (
              <div className="individual-type">
                <div>{data.type}</div>
                {data.values.map((value) => {
                  return (
                    <div className="typeData">
                      <Checkbox
                        color="primary"
                        value={value}
                        checked={selectedFilters?.[data.type]?.includes(value)}
                        onChange={(e) => {
                          handleFilterSelection(e, data.type, value);
                        }}
                        className="root-check-all"
                      />
                      <span>{value}</span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
      {scheduleInterviewModal && (
        <InterviewModals
          candidate={selectCandidate}
          header={
            selectedTab === "All Profiles"
              ? "Schedule Interview"
              : "Update Interview Details"
          }
          selectedTab={selectedTab}
          closeModal={closeModal}
          job={requestedProfile}
          gettableData={gettableData}
          toast={toast}
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        className={"tost-msg-stylinh"}
        draggable={false}
        theme="light"
      />
    </div>
  );
};

export default InterviewCandidate;
