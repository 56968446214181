import React, { useState ,useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, InputAdornment, Grid } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import ForgotPasswordBG from "../../assets/forgotPwBG.png";
import "./ForgotPassword.scss";
import { Link } from "react-router-dom";
import { forgotPassword, existEmailPhoneCheck } from "../../api/api-services";
import { ToastContainer, toast } from "react-toastify";
import FooterSection from "../landing-page/footer-section/FooterSection";
import NavBarComponent from "../landing-page/NavBarComponent";

const useStyles = makeStyles((theme) => ({
  form: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  field: {
    margin: theme.spacing(1),
    width: "100%",
    height: "58px",

    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // border:' 1px solid #DADADA',
    opacity: "1",
  },

  input: {
    backgroundColor: "#fff",
    "&:focused": {
      backgroundColor: "#fff",
    },
    "& .Mui-focused": {
      backgroundColor: "#fff",
    },
  },
}));

const initialValues = {
  emailId: "",
};

const validationSchema = Yup.object({
  emailId: Yup.string()
    .matches(/[A-Za-z0-9]+@[a-zA-Z]+\.[A-Za-z]{2,3}/, "Enter valid email Id")
    .email("Enter valid email Id")
    .required("Email id is required"),
});

const ForgotPassword = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [availabilityStatusEmail, setAvailabilityStatusEmail] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const checkAvailability = async (id, value) => {
    try {
      const response = await existEmailPhoneCheck(value);

      if (id === "emailId") {
        setAvailabilityStatusEmail(response?.data?.response);
      }
    } catch (error) {
      console.error("Error occurred while checking availability:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on component mount

    const handleRefresh = () => {
      window.scrollTo(0, 0); // Scroll to the top on page refresh
    };

    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, []);

  const handleSubmit = async (values) => {
    if (isSubmitting) {
      return; // Prevent multiple form submissions
    }
    try {
      setIsSubmitting(true); 
      const { emailId } = values;
      const response = await forgotPassword(emailId);
      console.log(response);
      if (response?.data?.statusCode === 200) {
        toast.success(
          <>
            <div className="toast-heading">Success</div>
            <div>{response?.data?.response}</div>
          </>
        );
      }
      // else{
      //   toast.error(
      //     <>
      //       <div className="toast-heading">Alert</div>
      //       <div>{response?.response?.data?.response}</div>

      //     </>
      //   );
      // }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false); // Stop the loading state
    }
  };

  return (
   <div className="forgotPass overflow-x-hidden">
    <div className="forgotPasswordContainer">
     <div className="navbarSection-stuReg">
        <NavBarComponent />
      </div>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          <div className="formContainer">
            <p className="heading">forgot Password ?</p>
            <div className="formContents-forgotPW">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form className={classes.form}>
                  <span className="text-red-500 ">*</span>
                    <label className="mt-2 formLabel ml-0.5">Email</label>
                    <Field
                      name="emailId"
                      id="emailId"
                      placeholder="Enter your email"
                      as={TextField}
                      variant="outlined"
                      className={classes.field}
                      error={touched.emailId && Boolean(errors.emailId)}
                      // helperText={
                      //   touched.emailId && errors.emailId ? (
                      //     errors.emailId
                      //   ) : (
                      //     <p className="text-red-500 error_text md:mt-1 absolute -ml-2">
                      //       {availabilityStatusEmail}
                      //     </p>
                      //   )
                      // }
                      onBlur={(event) => {
                        checkAvailability(event.target.id, event.target.value);
                      }}
                      onChange={(event) => {
                        setFieldValue("emailId", event.target.value);
                        setAvailabilityStatusEmail("");
                      }}
                      autoComplete="off"
                      inputProps={{
                        className: classes.input,
                      }}
                    />
                    
                     { touched.emailId && errors.emailId ? (
                         <p className=" error_text md:mt-1 absolute ">{ errors.emailId}</p>
                        ) : (
                          <p className=" error_text md:mt-1 absolute ">
                            {availabilityStatusEmail}
                          </p>
                        )}
                    <div className="logInBtnContainer mt-4">
                      <Button
                         disabled={isSubmitting || availabilityStatusEmail}
                        type="submit"
                        className="logInBtn"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="registerContainerForgetPw my-2 ">
              <p className="ml-8">Back to ?</p>
              <Link to="/login" className="backToLogin mx-2">
                Signin
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
      
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        className={"tost-msg-stylinh"}
        draggable={false}
        theme="light"
      />
    </div>
    <FooterSection/>
   </div>
  );
};

export default ForgotPassword;
