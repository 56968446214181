import React, { useState } from "react";
import Modal from "react-modal";
import "./InterviewListModal.scss";
import { useGlobalFilter, useTable } from "react-table";
import { ReactComponent as NoDataFound } from "../../../assets/noDataFound.svg";
import { useRef } from "react";
import dayjs from "dayjs";
import { useEffect } from "react";
import { formatSytemTimeToHumanFormat } from "../../../util/common-utils";
import CancelIcon from '@mui/icons-material/Cancel';

function InterviewListModal({
  interviewlist,
  tableSearch,
  header,
  closeModal,
}) {
  const [openModal, setOpenModal] = useState(true);
  let [candidatePreviewModal, setCandidatePreviewModal] = useState(false);
  let [candidateData, setCandidateData] = useState([]);
  const cardSearchInput = useRef(null);

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "59%",
      transform: "translate(-50%, -50%)",
      width: "82%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
      outline: "none",
      //   borderTopLeftRadius: " 12px",
    },
  };
  let getStatusclass = (status) => {
    if (status === "SCHEDULED") {
      return "successStatus";
    } else if (status === "REJECTED" || status === "CANCELED") {
      return "cancelledStatus";
    }
  };
  let columns = React.useMemo(
    () => [
      {
        Header: "Interview Date",
        accessor: "date",
        width: "19%",
        Cell: (e) => (
          <div className="datatrimmer" title={e.value}>
            {dayjs(e.value).format("DD-MM-YYYY")}
          </div>
        ),
      },
      {
        Header: "Interview Time",
        accessor: "startTime",
        width: "27%",
        Cell: (e) => (
          <div className="datatrimmer" title={e.value}>
            {formatSytemTimeToHumanFormat(e.value)} - 
            {formatSytemTimeToHumanFormat(e?.cell?.row?.original?.endTime)}
          </div>
        ),
      },
      {
        Header: "Interview Mode",
        accessor: "interviewMode",
        width: "27%",
        Cell: (e) => (
          <div className="datatrimmer" title={e.value}>
            {e.value}
          </div>
        ),
      },
      {
        Header: "Interview Status",
        accessor: "interviewStatus",
        width: "27%",
        Cell: (e) => (
          <div className="datatrimmer" title={e.value}>
            {e.value}
          </div>
        ),
      },
    ],
    []
  );
  let data = React.useMemo(() => candidateData, [candidateData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  let handelSearch = (e) => {
    setGlobalFilter(e.target.value);
  };
  useEffect(() => {
    let data = [];
    if (tableSearch && cardSearchInput?.current?.value) {
      cardSearchInput.current.value = "";
    }
    if (interviewlist.length) {
      data = [...interviewlist];
    }
    setCandidateData([...data]);
  }, [interviewlist]);
  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      style={customStylesPage}
      className="p-px"
    >
      <div className="flex items-center rounded-tl-xl Interview-list-common-modal-heading items-center">
        <div className="header">
          {header}-{interviewlist.length}
        </div>
        <button
          onClick={() => {
            setOpenModal(false);
            closeModal(false);
          }}
          type="button"
          className="modal-close-btn float-left ml-auto"
        >
          <CancelIcon />
        </button>
      </div>
      <div className="Interview-list-common-modal-body">
        {tableSearch && (
          <input
            className="table-search-input"
            onChange={handelSearch}
            type="text"
            ref={cardSearchInput}
            placeholder="Search"
          />
        )}
        <div className="table-container">
          {data.length === 0 || rows.length === 0 ? (
            <>
              <div className="no-data-availableIcon">
                <NoDataFound />
              </div>
              <div className="no-data-availableText">
                <p>Oops !!</p> <p>There are no data to be found yet</p>
              </div>
            </>
          ) : (
            <table {...getTableProps()} className=" overflow-x-auto">
              <thead className="text-left  h-10">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        width={column.width}
                        {...column.getHeaderProps()}
                        className={` reportTableheader popins-semi-bold text-lg font-semibold ${
                          column.Header === "Interview Date"
                            ? "pl-4"
                            : "text-center"
                        }`}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className={``} id="defectlog">
                      {row.cells.map((cell) => {
                        return (
                          <td
                            width={cell?.column?.width}
                            {...cell.getCellProps()}
                            className={`datatrimmer ${cell?.column?.Header?.toLowerCase()}
                            ${
                              cell?.column.Header === "Interview Date"
                                ? "pl-4"
                                : "text-center"
                            }
                            ${
                              cell?.column.Header === "Interview Status"
                                ? getStatusclass(
                                    cell?.row?.original?.interviewStatus
                                  )
                                : ""
                            }`}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default InterviewListModal;
