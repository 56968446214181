import React, { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";
import { SelectDropdown } from "../../landing-page/common/select-dropdown";
import $ from "jquery";
import { useFormik } from "formik";
import * as yup from "yup";
import VideoPreviewModal from "./VideoPreviewModal";
import "./UpdateInterview.scss";
import {
  ReScheduleInterviewForCoordinate,
  cancelInterviewForCoordinate,
  getInterviewersAsPerDomain,
  videoUpload,
  completeProfileAfterVideoUpload,
  getAssignedInterviewerDetails,
} from "../../../api/api-services";

import TextField from "@mui/material/TextField";
import { validateFromAndToTime } from "../../../util/common-utils";
import MultiEmailInputField from "../../common/MultiEmailInputField";
import CommonLoader from "../../common/CommonLoader/commonloader";
import { LoaderFile } from "../loader/LoaderFile";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AssignedInterviewerModal from "./AssignedInterviewerModal";

const UpdateInterview = (props) => {
  console.log(props);
  let user = JSON.parse(localStorage.getItem("across-geo-user"));
  const inputBorder = "1px solid #26428B80";

  const [selectedInterviewStatusData, setSelectedInterviewStatusData] =
    useState([]);

  const interviewStatusPopulateData =
    props?.candidate?.interview?.internalInterviewTaken === false
      ? [
        { label: "Reschedule interview", value: "Reschedule interview" },
        { label: "Cancel interview", value: "Cancel interview" },
      ]
      : [{ label: "Interview completed", value: "Interview completed" }];
  const [interviewStatusData, setInterviewStatusData] = useState(
    interviewStatusPopulateData
  );
  const [showLoader, setShowLoader] = useState(false);

  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  // const [videoSource, setVideoSource] = useState(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [uploadedVideoName, setUploadedVideoName] = useState("");
  const today = dayjs();
  const [dateValue, setDateValue] = useState("");
  const [selectedEmails, setSelectedEmails] = useState([]);
  let [interviewModedata, setInterviewModedata] = useState([
    { label: "OFFLINE", value: "OFFLINE" },
    { label: "ONLINE", value: "ONLINE" },
  ]);
  let [selectedMode, setSelectedMode] = useState([]);
  let [cancelCount, setCancelCount] = useState(0);
  let [rejectCount, setRejectCount] = useState(0);
  const [startTrim, setStartTrim] = useState(null);
  const [endTrim, setEndTrim] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let initialValues = {
    interviewStatus: selectedInterviewStatusData[0]?.value
      ? selectedInterviewStatusData[0].value
      : "",
    interviewer: "",
    reason: "",
    phone: "",
    date: "",
    // mode: "",
    fromTime: "",
    participants: [],
    totime: "",
    linkorlocation: "",
  };
  let cancelorRejectScheema = {
    interviewStatus: yup.string().required("Status is required"),
    reason: yup.string().trim().required("Reason is required"),
  };
  let reScheduleScheema = {
    interviewStatus: yup.string().required("Status is required"),
    interviewer: yup.string().required("Interviewer data is required"),
    date: yup.string().required("Date is required"),
    // mode: yup.string().required("Interview Mode is required"),
    fromTime: yup
      .string()
      // .required("Please enter proper time")
      .required("From To time is required ")
      .test("am-pm", "Invalid time format. Please provide AM/PM", (value) => {
        if (!value) {
          return false; // Value is required
        }
        return /(AM|PM)$/i.test(value);
      })
      .test(
        "valid-time",
        "From Time must be earlier than To Time",
        function (value) {
          const toTime = this.parent.totime;
          return validateFromAndToTime(value, toTime);
        }
      )
      .test("past-time", "Past time not allowed", function (value) {
        const currentDate = dayjs().format("YYYY-MM-DD");
        const selectedDate = this.parent.date;
        if (selectedDate === currentDate) {
          const currentTime = dayjs().format("hh:mm A");
          return dayjs(value, "hh:mm A").isAfter(dayjs(currentTime, "hh:mm A"));
        }
        return true;
      }),
    totime: yup
      .string()
      // .required("Please enter proper time")
      .required("From To time is required ")
      .test("am-pm", "Invalid time format. Please provide AM/PM", (value) => {
        if (!value) {
          return false; // Value is required
        }
        return /(AM|PM)$/i.test(value);
      })
      .test(
        "valid-time",
        "From Time must be earlier than To Time",
        function (value) {
          const fromTime = this.parent.fromTime;
          return validateFromAndToTime(fromTime, value);
        }
      )
      .test("past-time", "Past time not allowed", function (value) {
        const currentDate = dayjs().format("YYYY-MM-DD");
        const selectedDate = this.parent.date;
        if (selectedDate === currentDate) {
          const currentTime = dayjs().format("hh:mm A");
          return dayjs(value, "hh:mm A").isAfter(dayjs(currentTime, "hh:mm A"));
        }
        return true;
      }),
    participants: yup.array(),
    linkorlocation: yup.string().required("Required"),
  };
  let offerSchema = {
    interviewStatus: yup.string().required("Status is required"),
  };
  const [validationSchema, setValidationSchema] = useState(
    yup.object({
      ...offerSchema,
    })
  );
  const handleEmailChange = (data) => {
    setSelectedEmails([...data]);
    if (data.length > 0) {
      formikDetails.setFieldValue("participants", [...data], true);
    } else {
      formikDetails.setFieldValue("participants", [], true);
    }
  };
  useEffect(() => {
    const fetchDomain = async () => {
      try {
        const response =
          !props?.candidate?.interview?.internalInterviewTaken &&
          (await getInterviewersAsPerDomain(
            props?.candidateDetailsData.technicalDetails.jobTitle
          ));
        const jobTitle = response.data.response.map((value) => ({
          label: value.name,
          value: value.name,
          id: value.id,
          emailID: value.email,
        }));
        setInterviewerData([...jobTitle]);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchDomain();
  }, [props?.candidateDetailsData]);

  const [showVideoErrorValidation, setShowVideoErrorValidation] =
    useState(false);
  const [showTrimmedVideoErrorValidation, setShowTrimmedVideoErrorValidation] =
    useState(false);

  const uploadVideo = async (id, file) => {
    try {
      const formData = new FormData();
      // formData.append("file", file);
      formData.append("fullVideo", file);
      // formData.append("preview", file);
      setShowLoader(true);
      const response =
        !disableButton &&
        (await videoUpload(
          id,
          formData,
          parseInt(startTrim),
          parseInt(endTrim)
        ));
      console.log(response, "uploadVideo", file);
      !file && setShowVideoErrorValidation(true);
      if (response.data && response.data.statusCode === 200) {
        // Extract the upload key from the response URL
        const uploadKey = response.data.response.split("/").pop();

        // Call the completeInterview function with the uploadKey
        await completeInterview(props.candidate.id, uploadKey);
        setShowVideoErrorValidation(false);
      }
      if (response == undefined) {
        console.error("Failed to upload video");
      }
    } catch (error) {
      console.error("Error showLoader video:", error);
    } finally {
      setShowLoader(false);
    }
  };

  // Function to complete the interview using the upload key
  const completeInterview = async (candidateId, uploadKey) => {
    try {
      const response = await completeProfileAfterVideoUpload(
        candidateId,
        uploadKey
      );

      if (response.data.statusCode === 200) {
        props.toast.success(
          <>
            <div className="toast-heading">Success</div>
            <div>Interview completed successfully</div>
          </>
        );

        props.closeModal();
      } else {
        console.log("Failed to complete interview");
      }
    } catch (error) {
      console.error("Error completing interview:", error);
    }
  };

  const onSubmit = async (values) => {
    let interStatus = selectedInterviewStatusData[0]?.value;
    console.log({ interStatus });
    let response;
    let payload = {};
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    if (interStatus === "Cancel interview") {
      try {
        response = await cancelInterviewForCoordinate(
          props.candidate.id,
          values.reason
        );
        if (response.status === 200 || response.statusCode === 200) {
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>
                Candidate{" "}
                {interStatus === "Cancel interview" ? "Cancelled" : "Rejected"}{" "}
                successfully
              </div>
            </>
          );
          props.closeModal();
          setIsSubmitting(false);
        } else {
          setIsSubmitting(true);
        }
      } catch (err) {
        console.error("cancel or reject api error");
      }
    } else if (interStatus === "Reschedule interview") {
      const payloadEmails = [
        ...selectedEmails,
        props?.candidateDetailsData.personalDetails.email,
        selectedInterviewerData[0].emailID,
      ];
      payload = {
        candidateID: props.candidate.id,
        date: values.date,
        interviewType: "INTERNAL",
        startTime: values.fromTime,
        endTime: values.totime,
        schedulerId: user.uid,
        schedulerName: user.username,
        meetingLink: values.linkorlocation,
        participants: payloadEmails,
        interviewMode: "ONLINE",
        interviewersId: selectedInterviewerId,
        // locationDetails
      };
      // if (values?.participants?.length > 0) {
      //   values?.participants?.map((emailid) => {
      //     if (!payload?.participants?.includes(emailid)) {
      //       payload.participants.push(emailid);
      //     }
      //   });
      // }
      console.log({ payload });
      try {
        response = await ReScheduleInterviewForCoordinate(payload);
        console.log({ response });
        if (
          response.status === 200 ||
          response.statusCode === 201 ||
          response.response === "Interview scheduled successfully"
        ) {
          props.toast.success(
            <>
              <div className="toast-heading">Success</div>
              <div>Interview Rescheduled successfully</div>
            </>
          );
          props.closeModal();
          setIsSubmitting(true);
        } else {
          setIsSubmitting(false);
          props.toast.info(
            <>
              <div className="toast-heading">Info</div>
              <div>
                {response?.response?.data?.response
                  ? response?.response?.data?.response
                  : ""}
              </div>
            </>
          );
        }
      } catch (err) {
        console.error("cancel or reject api error");
      }
    } else if (interStatus === "Interview completed") {
      try {
        if (startTrim !== null && endTrim !== null) {
          const uploadKey = await uploadVideo(
            props.candidate.id,
            uploadedVideo
          );
        }
      } catch (error) {
        // Handle any errors that occur during API calls
        console.error("API error:", error);
        // You can display an error message to the user here
      }
    }
  };
  const formikDetails = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });
  const handleModeChange = (data) => {
    setSelectedMode([...data]);
    if (data.length > 0) {
      formikDetails.setFieldValue("mode", data?.[0].value, true);
      if (data?.[0].value === "ONLINE") {
        setValidationSchema(
          yup.object({
            ...reScheduleScheema,
            linkorlocation: yup
              .string()
              .matches(
                /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/i,
                "Enter proper link"
              )
              .required("Required"),
          })
        );
      } else if (data?.[0].value === "OFFLINE") {
        setValidationSchema(
          yup.object({
            ...reScheduleScheema,
          })
        );
      }
    } else {
      formikDetails.setFieldValue("mode", "", true);
    }
  };
  const handleStatusChange = (data) => {
    formikDetails.setFieldValue("interviewStatus", data[0]?.value);
    setSelectedInterviewStatusData([...data]);
    if (data.length > 0) {
      if (
        data[0].value === "Cancel interview" ||
        data[0].value === "Reject interview"
      ) {
        setValidationSchema(yup.object({ ...cancelorRejectScheema }));
        setRejectCount(0);
      } else if (data[0].value === "Offer") {
        setValidationSchema(yup.object({ ...offerSchema }));
      } else if (data[0].value === "Reschedule interview") {
        setValidationSchema(yup.object({ ...reScheduleScheema }));
        setRejectCount(0);
      } else if (data[0].value === "Reschedule One more interview") {
        setValidationSchema(yup.object({ ...reScheduleScheema }));
        setRejectCount(0);
      } else {
        setValidationSchema(yup.object({ ...offerSchema }));
      }
    }
  };
  const handleCount = (value) => {
    let charCount = value.length,
      current = $("#current");
    current.text(charCount);
  };
  const handleCountForCancelReject = (value) => {
    if (selectedInterviewStatusData[0]?.value === "Cancel interview") {
      setCancelCount(value.length);
      setRejectCount(0);
    } else {
      setRejectCount(value.length);
      setCancelCount(0);
    }
  };
  const [interviewerData, setInterviewerData] = useState([]);
  const [selectedInterviewerId, setSelectedInterviewerId] = useState("");
  const [selectedInterviewerData, setSelectedInterviewerData] = useState([]);
  const handlejobTitleChange = (data) => {
    formikDetails.setFieldValue("interviewer", data?.[0].value);
    setSelectedInterviewerId(data?.[0]?.id);
    setSelectedInterviewerData([...data]);
  };
  const handleBlur = () => {
    if (
      formikDetails.touched.interviewer &&
      !formikDetails.values.interviewer
    ) {
      formikDetails.setFieldError(
        "interviewer",
        "Interviewer data is required"
      );
    }
  };
  const handleSelectBlur = (event) => {
    if (event.currentTarget.contains(event.relatedTarget)) {
      formikDetails.setFieldTouched("interviewer", true);
      handleBlur();
    }
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.round(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };
  const videoURLHandler = (file) => {
    const videoURL = URL.createObjectURL(file);
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      const durationInSeconds = video.duration;
      console.log("duration in seconds", durationInSeconds);
      const formattedDuration = formatTime(durationInSeconds);
      console.log("formatted duration", formattedDuration);
      // Assuming you have a function to display the duration on the page
      // displayDuration(formattedDuration);
      setVideoDuration(durationInSeconds);
    };

    video.src = videoURL;

    setUploadedVideoName(file.name);
    setUploadedVideo(file);

    setVideoFile(videoURL);
  };

  const fileHandler = (event) => {
    const file = event.target.files[0];

    if (file) {
      videoURLHandler(file);
      setUploadedVideoName(file.name);
      setUploadedVideo(file);
      setPreviewVisible(false); // Hide the preview initially
      setDisableButton(false);
      setShowVideoErrorValidation(false);
      setStartTrim(null);
      setEndTrim(null);
    }
  };
  const handlePreviewClick = () => {
    setPreviewModalOpen(true);
  };

  const handleTrim = (start, end) => {
    setShowTrimmedVideoErrorValidation(false);
    setStartTrim(start);
    setEndTrim(end);
    console.log("start" + start, "end" + end);
    // setPreviewModalOpen(false);
  };
  const [draggingOver, setDraggingOver] = useState(false);

  // Function to handle the drop event
  const handleDrop = (event) => {
    event.preventDefault(); // Prevent the default behavior
    setDraggingOver(false); // Reset the dragging over state
    const file = event.dataTransfer.files[0]; // Get the dropped file
    setUploadedVideo(file); // Set the uploaded video
    videoURLHandler(file);
    setDisableButton(false);
    setShowVideoErrorValidation(false);
    setStartTrim(null);
    setEndTrim(null);
  };
  const [openAssignedInterviewModal, setOpenAssignedInterviewModal] =
    useState(false);
  const handleListOfInterViewers = () => {
    setOpenAssignedInterviewModal(true);
    assignedInterViewerList();
  };
  const CloseListOfInterViewers = () => {
    setOpenAssignedInterviewModal(false);
  };
  let [assignedInterViewerData, setAssignedInterViewerData] = useState({});
  let assignedInterViewerList = async () => {
    let response;

    try {
      response = await getAssignedInterviewerDetails(selectedInterviewerId);

      if (response) {
        setAssignedInterViewerData(response.data.response);
      }
    } catch (err) {
      console.error(err, "shared dynamic table error");
    }
  };
  return (
    <div className="update-interview-form">
      <form onSubmit={formikDetails.handleSubmit} className="update-form-tag">
        <div className="form-container">
          <div className="individual-field">
            <div className="mb-2 label">
              <span className="create-labels">Interview Status</span>
            </div>
            <div className="popins-medium update-interview-dropdown">
              <SelectDropdown
                data={interviewStatusData}
                onChange={handleStatusChange}
                selectedData={selectedInterviewStatusData}
                deselection={true}
                searchable={false}
              // disabled={props.candidate.interview.hasRequestForNextRound == true}
              />
            </div>
            {formikDetails.errors.interviewStatus &&
              formikDetails.touched.interviewStatus ? (
              <div className="text-red-500 error_text md:mt-1">
                {formikDetails.errors.interviewStatus}
              </div>
            ) : null}
          </div>
          {selectedInterviewStatusData[0]?.value === "Reschedule interview" ? (
            <>
              <div className="form-group mt-3  individual-field text-left">
                <div className="mb-2 label">
                  <span className="text-red-500 ">*</span>
                  <span
                    className="create-labels mx-1"
                    onClick={() => {
                      console.log(selectedInterviewerData);
                    }}
                  >
                    Interviewer
                  </span>
                </div>

                <div className="popins-medium" style={{ height: "80px" }}>
                  <div
                    className={`w-full popins-medium update-interview-dropdown user-email-input-tyle ${(formikDetails.errors.interviewer &&
                        formikDetails.touched.interviewer) ||
                        (formikDetails.touched.interviewer &&
                          !formikDetails.values.interviewer)
                        ? "error-input"
                        : ""
                      }`}
                    onBlur={handleSelectBlur}
                  >
                    <SelectDropdown
                      data={interviewerData}
                      onChange={handlejobTitleChange}
                      selectedData={selectedInterviewerData}
                      backspaceDelete={true}
                      searchable={true}
                      inputProps={{
                        style: {
                          padding: "14px 14px",
                          minHeight: "53px !important",
                        },
                      }}
                    />
                  </div>
                  {formikDetails.errors.interviewer &&
                    formikDetails.touched.interviewer && (
                      <div className="text-red-500 error_text md:mt-1 ml-1">
                        {formikDetails.errors.interviewer}
                      </div>
                    )}
                </div>
              </div>
              {selectedInterviewerId && (
                <p
                  onClick={handleListOfInterViewers}
                  className="viewInterviewList cursor-pointer"
                >
                  view interview scheduled list
                </p>
              )}
              <div className="individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Date and Time</span>
                </div>
                <div className="popins-medium">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      minDate={today}
                      slotProps={{
                        textField: (props) => ({
                          error: false,
                          readOnly: true,
                          // label: position === "start" ? "From" : "To",
                        }),
                      }}
                      value={dateValue}
                      id="date"
                      name="date"
                      onChange={(newValue) => {
                        formikDetails.setFieldValue(
                          "date",
                          dayjs(newValue).format("YYYY-MM-DD")
                        );
                        setDateValue(newValue);
                      }}
                      format="DD-MM-YYYY"
                      // placeholder={<span className="date-placeholder">Select...</span>}
                      className="date-picker"
                    />
                  </LocalizationProvider>
                  {formikDetails.errors.date &&
                    formikDetails.touched.date &&
                    !formikDetails.values.date ? (
                    <div className="text-red-500 error_text md:mt-1">
                      {formikDetails.errors.date}
                    </div>
                  ) : null}
                </div>
                <div className="mt-2 time-label">
                  <span className="create-labels">From</span>
                  <span className="create-labels">To</span>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["MultiInputTimeRangeField"]}>
                    <MultiInputTimeRangeField
                      // disablePast={true}
                      slotProps={{
                        textField: (props) => ({
                          error: false,

                          // label: position === "start" ? "From" : "To",
                        }),
                      }}
                      onChange={(value) => {
                        if (
                          value[0] !== null &&
                          value[0]?.$d !== "Invalid Date"
                        ) {
                          formikDetails.setFieldValue(
                            "fromTime",
                            dayjs(value[0]).format("hh:mm:ss A"),
                            true
                          );
                        }
                        if (
                          value[1] !== null &&
                          value[1]?.$d !== "Invalid Date"
                        ) {
                          formikDetails.setFieldValue(
                            "totime",
                            dayjs(value[1]).format("hh:mm:ss A"),
                            true
                          );
                        }
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {/* {(formikDetails.errors.fromTime &&
                  formikDetails.touched.fromTime) ||
                (formikDetails.errors.totime &&
                  formikDetails.touched.totime) ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formikDetails.errors.totime || formikDetails.errors.totime}
                  </div>
                ) : null} */}
                {(formikDetails.errors.fromTime &&
                  formikDetails.touched.fromTime) ||
                  (formikDetails.errors.totime &&
                    formikDetails.touched.totime) ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formikDetails.errors.fromTime ||
                      formikDetails.errors.totime}
                  </div>
                ) : null}
              </div>
              {/* <div className="field-wrapper mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Interview Mode</span>
                </div>
                <div className="input-ele">
                  <SelectDropdown
                    data={interviewModedata}
                    onChange={handleModeChange}
                    selectedData={selectedMode}
                    deselection={true}
                    searchable={false}
                  />
                </div>
                {formikDetails.errors.mode &&
                formikDetails.touched.mode &&
                !formikDetails.values.mode ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formikDetails.errors.mode}
                  </div>
                ) : null}
              </div> */}
              <div className="field-wrapper mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Other Participants</span>
                </div>
                <div className="input-ele">
                  <MultiEmailInputField
                    handleEmailChange={handleEmailChange}
                    selectedEmails={selectedEmails}
                    fieldName={"participants"}
                  />
                </div>
              </div>

              <div className="individual-field mt-4">
                <div className="mb-2 label">
                  <span className="create-labels">Meeting Link</span>
                </div>
                <div className="popins-medium">
                  <input
                    className="input-field"
                    name="linkorlocation"
                    id="linkorlocation"
                    type={"text"}
                    onChange={formikDetails.handleChange}
                  />
                </div>
                {formikDetails.errors.linkorlocation &&
                  formikDetails.touched.linkorlocation ? (
                  <div className="text-red-500 error_text md:mt-1">
                    Link is Required
                  </div>
                ) : null}
              </div>
            </>
          ) : selectedInterviewStatusData[0]?.value ===
            "Reschedule One more interview" && user.role !== "CLIENT" ? (
            <>
              <div className="individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Previous Interview List</span>
                </div>
                <div className="input-ele">
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // value={`${new Date(props.candidate.interview.date).toDateString()} ${props.candidate.interview.startTime} - ${props.candidate.interview.endTime}`}
                    inputProps={{
                      style: {
                        background: "#f7a64f",
                        color: "white",
                        // height: "0.43em !important",
                      },
                    }}
                    id="demo-helper-text-aligned"
                  />
                </div>
                <div className="mb-2 label">
                  <span className="create-labels">Date and Time</span>
                </div>
                <div className="popins-medium">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      //  minDate={dayjs(props.candidate.interview.date)}
                      slotProps={{
                        textField: (props) => ({
                          error: false,
                          readOnly: true,
                          // label: position === "start" ? "From" : "To",
                        }),
                      }}
                      value={dateValue}
                      id="date"
                      name="date"
                      onChange={(newValue) => {
                        formikDetails.setFieldValue(
                          "date",
                          dayjs(newValue).format("YYYY-MM-DD")
                        );
                        setDateValue(newValue);
                      }}
                      format="DD-MM-YYYY"
                      // placeholder={<span className="date-placeholder">Select...</span>}
                      className="date-picker"
                    />
                  </LocalizationProvider>
                  {formikDetails.errors.date &&
                    formikDetails.touched.date &&
                    !formikDetails.values.date ? (
                    <div className="text-red-500 error_text md:mt-1">
                      {formikDetails.errors.date}
                    </div>
                  ) : null}
                </div>
                <div className="mt-2 time-label">
                  <span className="create-labels">From</span>
                  <span className="create-labels">To</span>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["MultiInputTimeRangeField"]}>
                    <MultiInputTimeRangeField
                      // disablePast={true}
                      slotProps={{
                        textField: (props) => ({
                          error: false,

                          // label: position === "start" ? "From" : "To",
                        }),
                      }}
                      onChange={(value) => {
                        if (
                          value[0] !== null &&
                          value[0]?.$d !== "Invalid Date"
                        ) {
                          formikDetails.setFieldValue(
                            "fromTime",
                            dayjs(value[0]).format("hh:mm:ss A"),
                            true
                          );
                        }
                        if (
                          value[1] !== null &&
                          value[1]?.$d !== "Invalid Date"
                        ) {
                          formikDetails.setFieldValue(
                            "totime",
                            dayjs(value[1]).format("hh:mm:ss A"),
                            true
                          );
                        }
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {(formikDetails.errors.fromTime &&
                  formikDetails.touched.fromTime) ||
                  (formikDetails.errors.totime &&
                    formikDetails.touched.totime) ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formikDetails.errors.totime || formikDetails.errors.totime}
                  </div>
                ) : null}
              </div>
              <div className="field-wrapper mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Interview Mode</span>
                </div>
                <div className="input-ele">
                  <SelectDropdown
                    data={interviewModedata}
                    onChange={handleModeChange}
                    selectedData={selectedMode}
                    deselection={true}
                    searchable={false}
                  />
                </div>
                {formikDetails.errors.mode &&
                  formikDetails.touched.mode &&
                  !formikDetails.values.mode ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formikDetails.errors.mode}
                  </div>
                ) : null}
              </div>
              <div className="field-wrapper mt-2 individual-field">
                <div className="mb-2 label">
                  <span className="create-labels">Other Participants</span>
                </div>
                <div className="input-ele">
                  <MultiEmailInputField
                    handleEmailChange={handleEmailChange}
                    selectedEmails={selectedEmails}
                    fieldName={"participants"}
                  />
                </div>
              </div>
              {selectedMode?.[0]?.value === "ONLINE" ? (
                <div className="individual-field mt-4">
                  <div className="mb-2 label">
                    <span className="create-labels">Meeting Link</span>
                  </div>
                  <div className="popins-medium">
                    <input
                      className="input-field"
                      name="linkorlocation"
                      id="linkorlocation"
                      type={"text"}
                      onChange={formikDetails.handleChange}
                    />
                  </div>
                  {formikDetails.errors.linkorlocation &&
                    formikDetails.touched.linkorlocation ? (
                    <div className="text-red-500 error_text md:mt-1">
                      Link is Required
                    </div>
                  ) : null}
                </div>
              ) : (
                selectedMode?.[0]?.value === "OFFLINE" && (
                  <div className="individual-field mt-3">
                    <div className="mb-2 label">
                      <span className="create-labels">Location</span>
                    </div>
                    <div className="popins-medium">
                      <textarea
                        className="form-control shadow-none textarea_contact"
                        name="linkorlocation"
                        id="linkorlocation"
                        cols="40"
                        rows="5"
                        value={formikDetails.values.linkorlocation}
                        onChange={(e) => {
                          formikDetails.setFieldValue(
                            "linkorlocation",
                            e.target.value,
                            true
                          );
                        }}
                        style={{ border: inputBorder }}
                        //   onKeyDown={handleEnter}
                        maxLength="500"
                        onKeyUp={(e) => handleCount(e.target?.value)}
                      ></textarea>
                      <div className="d-flex mb-1 labelTitleDemo">
                        {formikDetails.errors.linkorlocation &&
                          formikDetails.touched.linkorlocation ? (
                          <span className="text-red-500 float-left error_text md:mt-1">
                            Location is Required
                          </span>
                        ) : null}
                        <span className="float-right ml-auto">
                          <span id="current">0</span>
                          <span id="maximum">&nbsp;/ 500</span>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              )}
            </>
          ) : selectedInterviewStatusData[0]?.value === "Cancel interview" ||
            selectedInterviewStatusData[0]?.value === "Reject interview" ? (
            <>
              <div className="individual-field mt-7">
                <div className="mb-2 label">
                  <span className="create-labels">
                    Reason for{" "}
                    {selectedInterviewStatusData[0]?.value ===
                      "Reject interview"
                      ? "Reject"
                      : "Cancel"}
                  </span>
                </div>
                <div className="popins-medium">
                  <textarea
                    className="form-control shadow-none textarea_contact"
                    name="reason"
                    cols="40"
                    rows="5"
                    value={formikDetails.values.reason}
                    onChange={formikDetails.handleChange}
                    style={{
                      border: inputBorder,
                      resize: "none",
                      height: "10rem",
                    }}
                    onKeyDown={(e) => {
                      if (
                        selectedInterviewStatusData[0]?.value ===
                        "Cancel interview"
                      ) {
                        setCancelCount(formikDetails.values.reason.length);
                        setRejectCount(0);
                      } else {
                        setRejectCount(formikDetails.values.reason.length);
                        setCancelCount(0);
                      }
                    }}
                    maxLength="500"
                    onKeyUp={(e) => handleCountForCancelReject(e.target?.value)}
                  ></textarea>
                  <div className="mt-1 mr-1 text-sm text-gray-500 text-right">
                    {selectedInterviewStatusData[0]?.value ===
                      "Cancel interview"
                      ? cancelCount
                      : rejectCount}
                    /500
                  </div>
                </div>
                {formikDetails.errors.reason && formikDetails.touched.reason ? (
                  <div className="text-red-500 error_text md:mt-1">
                    Reason for{" "}
                    {selectedInterviewStatusData[0]?.value ===
                      "Reject interview"
                      ? "Reject"
                      : "Cancel"}{" "}
                    is required
                  </div>
                ) : null}
              </div>
            </>
          ) : selectedInterviewStatusData[0]?.value ===
            "Interview completed" ? (
            <>
              <div className="individual-field mt-7">
                <div className="mb-2 label">
                  <span className="create-labels">Upload Video</span>
                </div>
                <div
                  className={`popins-medium ${draggingOver ? "dragging-over" : ""
                    }`}
                  onDragOver={(e) => {
                    e.preventDefault();
                    setDraggingOver(true); // Set dragging over state
                  }}
                  onDragLeave={() => setDraggingOver(false)} // Reset dragging over state
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={fileHandler}
                    accept=".mp4"
                    // disabled={
                    //   props.mode === "Edit User" ||
                    //   selectedPrivilege[0]?.value === "ACCOUNT-MANAGER"
                    // }
                    id="contained-button-file"
                    disabled={showLoader}
                  />
                  <label
                    htmlFor="contained-button-file"
                    className="drag-and-drop-zone flex items-center"
                  >
                    {draggingOver || uploadedVideo ? (
                      <div className="text-center text-container">
                        <p>
                          {uploadedVideo && !draggingOver
                            ? uploadedVideo.name
                            : "Release to Upload"}
                        </p>
                        <p className="upload-btn">Replace</p>
                      </div>
                    ) : (
                      <div className="text-center text-container">
                        <p>Drag and Drop the video</p>
                        <p>or</p>
                        <p className="upload-btn">Upload</p>
                      </div>
                    )}
                  </label>
                </div>
                {showVideoErrorValidation ? (
                  <p className="text-red-500 error_text">
                    Video file is required
                  </p>
                ) : (
                  ""
                )}
              </div>
              {uploadedVideo && (
                <div className="individual-field mt-7">
                  <div className="mb-2 label">
                    <span className="create-labels">Preview Video</span>
                  </div>
                  <div className="popins-medium">
                    {isPreviewModalOpen ? (
                      <VideoPreviewModal
                        open={isPreviewModalOpen}
                        onClose={() => setPreviewModalOpen(false)}
                        videoFile={uploadedVideo}
                        onTrim={handleTrim}
                        videoDuration={videoDuration}
                      />
                    ) : startTrim !== null && endTrim !== null ? (
                      <>
                        <div>
                          <span className="mr-6">Trimmed_video.mp4</span>
                          <span className="mr-6" style={{ fontSize: "14px" }}>
                            <span style={{ color: "black" }}>{"From: "}</span>
                            <span>{formatTime(startTrim)}</span>
                            <span style={{ color: "black" }}>{" - To: "}</span>
                            <span>{formatTime(endTrim)}</span>
                          </span>
                          <button
                            className="set-preview-button"
                            onClick={handlePreviewClick}
                            disabled={showLoader}
                          >
                            <BorderColorIcon />
                          </button>
                        </div>
                      </>
                    ) : (
                      <button
                        className="set-preview-button"
                        onClick={handlePreviewClick}
                      >
                        *Set Preview
                      </button>
                    )}
                  </div>
                  {showTrimmedVideoErrorValidation ? (
                    <p className="text-red-500 error_text ml-2">
                      Trimmed Video file is required
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {showLoader && <LoaderFile />}

              <div className="individual-field mt-7">
                <div className="mb-2 label">
                  <span className="create-labels">Skill Set Rating</span>
                </div>
              </div>
              <div
                className="skillList overflow-auto"
                style={{ maxHeight: "150px", height: "140px", width: "32vw" }}
              >
                {props?.candidateDetailsData?.technicalDetails?.skills.map(
                  (data) => {
                    return (
                      <div className="flex rating-individual-field mt-2">
                        <div className="mb-2 label">
                          <span className="create-labels">
                            {data.skillName}
                          </span>
                        </div>
                        <div className="popins-medium">
                          <input
                            value={data.rating + " out of 10"}
                            className="rating-input-field "
                            disabled
                            type={"text"}
                          />
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </>
          ) : null}
        </div>
        <div className="fixed bottom-16 right-7 btnCoOrdinateContainer">
          <div className="hire-now-btn">
            <button
              type="submit"
              onClick={() => {
                if (!uploadedVideo) {
                  setShowVideoErrorValidation(true);
                }
                if (uploadedVideo && startTrim == null && endTrim == null) {
                  setShowTrimmedVideoErrorValidation(true);
                } else {
                  setShowTrimmedVideoErrorValidation(false);
                }
              }}
              disabled={showLoader || isSubmitting}
              className="datatrimmer updateBtn"
            >
              Update
            </button>
          </div>
        </div>
      </form>
      {openAssignedInterviewModal && (
        <AssignedInterviewerModal
          selectedInterviewerId={selectedInterviewerId}
          assignedInterViewerData={assignedInterViewerData}
          closeModal={CloseListOfInterViewers}
        />
      )}
    </div>
  );
};

export default UpdateInterview;
