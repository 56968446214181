import { Routes, Route } from "react-router-dom";
import BreadCrumbs from "../../common/BreadCrumbs";
import PageHeader from "./CandidatePageHeader";
import PageLeftMenu from "./CandidatePageLeftMenu";
import CandidateProgress from "../CandidateProgress";
import UnderDevelopment from "../../common/UnderDevelopment";

function CandidateHome() {
  return (
    <div>
      {/* <ProgressContainer> */}

      <div className="">
        <PageHeader />
      </div>
      <div className="flex">
        <div>
          <PageLeftMenu />
        </div>
        <div className="w-full home-component-display overflow-auto">
          {/* <BreadCrumbs /> */}
          <Routes>
            <Route
              path="/"
              element={<CandidateProgress tabletitleRow={true} />}
            />
            <Route
              path="/progress"
              element={<CandidateProgress tabletitleRow={true} />}
            />
            <Route path="/configuration" element={<UnderDevelopment />} />
          </Routes>
        </div>
      </div>
      {/* </ProgressContainer> */}
    </div>
  );
}

export default CandidateHome;
