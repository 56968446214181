import React, { useState, useRef, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import "./VideoPreviewModal.scss";
import MultiRangeSlider from "../multi-range-slider/MultiRangeSlider";

const VideoPreviewModal = ({
  open,
  onClose,
  videoFile,
  onTrim,
  videoSource,
  videoDuration,
  setVideo,
}) => {
  const videoRef = useRef(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [trimPreviewVisible, setTrimPreviewVisible] = useState(false);
  const [isPlayingInRange, setIsPlayingInRange] = useState(false);
  const [trimmedBlobFile, setTrimmedBlobFile] = useState(null);

  const handleTrim = async () => {
    const video = videoRef.current;

    if (!video) {
      return;
    }
    // Create a blob URL for the trimmed video
    const trimmedData = videoFile.slice(start, end);
    const trimmedBlob = new Blob([trimmedData], { type: "video/mp4" });
    const trimmedVideoFile = new File([trimmedBlob], "trimmed_video", {
      type: "video/mp4",
    });
    setTrimmedBlobFile(trimmedVideoFile);
  };
  const cancelTrim = () => {
    setStart(0);
    setEnd(videoDuration);
    setTrimmedBlobFile(null);
    setIsPlayingInRange(true);
  };

  // const playVideoInRange = (start, end) => {
  //   if (videoRef.current) {
  //     videoRef.current.currentTime = start;
  //     videoRef.current.play();
  //     // setIsPlayingInRange(true);

  //     // Pause the video when it reaches the specified end time
  //     videoRef.current.addEventListener("timeupdate", function () {
  //       if (videoRef.current.currentTime >= end) {
  //         videoRef.current.pause();
  //         // setIsPlayingInRange(false);
  //       }
  //     });
  //   }
  // };
  const playVideoInRange = (start, newEnd) => {
    if (videoRef.current) {
      videoRef.current.currentTime = start;
      function timeUpdateHandler() {
        if (videoRef.current.currentTime >= newEnd) {
          videoRef.current.pause();
          videoRef.current.removeEventListener("timeupdate", timeUpdateHandler); // Remove the listener once it's done
        }
      }
      videoRef.current.addEventListener("timeupdate", timeUpdateHandler);
      videoRef.current.play();
    }
  };

  const handleTimeChange = () => {
    if (!isPlayingInRange) {
      // Prevent changing the video time outside the allowed range
      videoRef.current.currentTime = 0;
    }
  };
  useEffect(() => {
    //
  }, [isPlayingInRange]);
  return (
    <Modal open={open} onClose={onClose}>
      <div className="video-preview-modal">
        <div className="modal-header">
          <span>Trim Preview Section</span>
          <IconButton onClick={onClose} className="modal-close-icon">
            <CancelIcon />
          </IconButton>
        </div>
        <div className="video-container">
          <video
            ref={videoRef}
            controls
            controlsList="nodownload"
            // onTimeUpdate={handleTimeChange}
            // onSeeking={handleTimeChange}
          >
            <source src={URL.createObjectURL(videoFile)} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <MultiRangeSlider
          min={0}
          max={videoDuration}
          onChange={({ min, max }) => {
            console.log(`min = ${min}, max = ${max}`);
            setStart(min);
            setEnd(max);
          }}
        />
        <div></div>
        <div className="button-container">
          {trimmedBlobFile === null ? (
            <button className="upload-button" onClick={handleTrim}>
              Trim
            </button>
          ) : (
            <>
              <button
                className="upload-button"
                onClick={() => {
                  // setVideo(trimmedBlobFile);
                  onTrim(start, end);
                  onClose();
                }}
              >
                Upload
              </button>
              <button
                className="upload-button mr-2"
                onClick={() => playVideoInRange(start, end)}
              >
                Play
              </button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default VideoPreviewModal;
